import { createAction } from 'redux-actions';
import _ from 'lodash';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createRequestSaga, showError, showSuccess } from '@weave/alert-system';
import { MailboxGreeting } from '@weave/schema-gen-ts/dist/schemas/phone/voicemail-config/v2/voicemail_config.pb';

import {
  Greeting,
  Mailbox,
  RequestListMailboxesAction,
  RequestUpdateMailboxAction,
  VoicemailBoxesActions,
  VoicemailBoxesActionTypes,
  VoiceMailboxesState,
} from './voice-mailboxes.types';
import { ResolvedPromise } from '../../store/store-utils';
import { CustomAxios, getResponseData } from '../../axios';
import { Store } from '../../store/store.model';
import { REACT_APP_API_URL } from '../../../config/app';

export const clearGreetings = createAction(VoicemailBoxesActionTypes.ClearGreetings);
export const deleteGreeting = createAction(VoicemailBoxesActionTypes.DeleteGreeting);
export const deleteGreetingSuccess = createAction(
  VoicemailBoxesActionTypes.DeleteGreetingSuccess
);
export const deleteGreetingFailure = createAction(
  VoicemailBoxesActionTypes.DeleteGreetingFailure
);
export const getGreetingMaps = createAction(VoicemailBoxesActionTypes.GetGreetingMaps);
export const getGreetingMapsSuccess = createAction(
  VoicemailBoxesActionTypes.GetGreetingMapsSuccess
);
export const getGreetingMapsFailure = createAction(
  VoicemailBoxesActionTypes.GetGreetingMapsFailure
);
export const getMailboxesSuccess = createAction(
  VoicemailBoxesActionTypes.GetMailboxesSuccess
);
export const getMailboxesFailure = createAction(
  VoicemailBoxesActionTypes.GetMailboxesFailure
);
export const getGreetingsForMailbox = createAction(
  VoicemailBoxesActionTypes.GetGreetingsForMailbox
);
export const getGreetingsForMailboxSuccess = createAction(
  VoicemailBoxesActionTypes.GetGreetingsForMailboxSuccess
);
export const getGreetingsForMailboxFailure = createAction(
  VoicemailBoxesActionTypes.GetGreetingsForMailboxFailure
);
export const updateActiveMailboxId = createAction(
  VoicemailBoxesActionTypes.UpdateActiveMailboxId
);
export const updateGreetings = createAction(VoicemailBoxesActionTypes.UpdateGreetings);
export const updateGreetingSuccess = createAction(
  VoicemailBoxesActionTypes.UpdateGreetingSuccess
);
export const updateGreetingFailure = createAction(
  VoicemailBoxesActionTypes.UpdateGreetingFailure
);
export const getGreetingsForAllMailboxes = createAction(
  VoicemailBoxesActionTypes.GetGreetingsForAllMailboxes
);
export const getGreetingsForAllMailboxesSuccess = createAction(
  VoicemailBoxesActionTypes.GetGreetingsForAllMailboxesSuccess
);
export const getGreetingsForAllMailboxesFailure = createAction(
  VoicemailBoxesActionTypes.GetGreetingsForAllMailboxesFailure
);
export const uploadGreeting = createAction(VoicemailBoxesActionTypes.UploadGreeting);
export const uploadGreetingSuccess = createAction(
  VoicemailBoxesActionTypes.UploadGreetingSuccess
);
export const uploadGreetingFailure = createAction(
  VoicemailBoxesActionTypes.UploadGreetingFailure
);
export const uploadGreetingConversion = createAction(
  VoicemailBoxesActionTypes.UploadGreetingConversion
);
export const uploadGreetingConversionSuccess = createAction(
  VoicemailBoxesActionTypes.UploadGreetingConversionSuccess
);
export const uploadGreetingConversionFailure = createAction(
  VoicemailBoxesActionTypes.UploadGreetingConversionFailure
);

export const requestListMailboxes = (): VoicemailBoxesActions => ({
  type: VoicemailBoxesActionTypes.RequestListMailboxes,
  payload: undefined,
});
export const requestUpdateMailbox = (mailbox: Mailbox): VoicemailBoxesActions => ({
  type: VoicemailBoxesActionTypes.RequestUpdateMailbox,
  payload: mailbox,
});

export const updateMailbox = (mailbox: Mailbox): VoicemailBoxesActions => ({
  type: VoicemailBoxesActionTypes.UpdateMailbox,
  payload: mailbox,
});

export const api = {
  listMailboxes: (): Promise<Mailbox[]> =>
    CustomAxios.get(`support/v1/voicemail/mailboxes`).then(getResponseData),
  updateMailbox: (id: string, mailbox: Partial<Mailbox>): Promise<Mailbox> =>
    CustomAxios.put(`support/v1/voicemail/mailbox/${id}`, mailbox).then(getResponseData),
  getAdminMailBoxes: (): Promise<Mailbox[]> =>
    CustomAxios.get(`portal/v1/voicemail/mailboxes`).then(getResponseData),
};

const requestListMailboxesSaga = createRequestSaga<RequestListMailboxesAction>({
  key: VoicemailBoxesActionTypes.RequestListMailboxes,
  displayErrors: true,
  onError: (err) => `Failed to retrieve mailboxes: ${err.message}`,
  saga: function* () {
    yield call(handleGetMailboxes);
  },
});

const handleGetMailboxes = function* () {
  try {
    let mailboxes: ResolvedPromise<ReturnType<typeof api.listMailboxes>> = yield call(
      api.listMailboxes
    );
    mailboxes = _.orderBy(mailboxes, ['number'], ['desc']);
    yield put(getMailboxesSuccess(mailboxes || []));
  } catch (error: any) {
    console.error(error);
    yield put(showError('Failed to get mailboxes. Please refresh the page.'));
    yield put(getMailboxesFailure());
  }
};

const requestUpdateMailboxSaga = createRequestSaga<RequestUpdateMailboxAction>({
  key: VoicemailBoxesActionTypes.RequestUpdateMailbox,
  displayErrors: true,
  onError: (err) => `Failed to update mailbox: ${err.message}`,
  saga: function* ({ payload }) {
    yield call(api.updateMailbox, payload.mailboxID, payload);
    yield put(updateMailbox(payload));
  },
});

const handleDeleteGreeting = function* (action) {
  try {
    const greetingId = action.payload;

    yield call(CustomAxios.delete, `support/v1/voicemail/greeting/${greetingId}`);
    yield put(showSuccess('Greeting deleted successfully!'));
    yield put(deleteGreetingSuccess(greetingId));
  } catch {
    yield put(showError('Failed to delete greeting. Please refresh the page.'));
    yield put(deleteGreetingFailure());
  }
};

const handleGetGreetingsForMailbox = function* (action) {
  try {
    const resp = yield call(
      CustomAxios.get,
      `/phone/voicemail-config/v2/${action.payload}/greetings`
    );

    const greetings = resp.data.greetings ?? [];

    yield put(getGreetingsForMailboxSuccess({ greetings: greetings }));
  } catch {
    yield put(
      showError('Failed to get greetings for this mailbox. Please refresh the page.')
    );
    yield put(getGreetingsForMailboxFailure());
  }
};

const handleGetGreetingMaps = function* (action) {
  const { activeMailboxId, isGeneral } = action.payload;

  try {
    const response = yield call(
      CustomAxios.get,
      `support/v1/phoneschedules/greetingmap/mailbox/${activeMailboxId}?isGeneral=${isGeneral}`
    );

    const greetingMaps = _.get(response, 'data.data', []);

    yield put(getGreetingMapsSuccess({ greetingMaps }));
  } catch {
    yield put(
      showError('Failed to get greeting map for this mailbox. Please refresh the page.')
    );
    yield put(getGreetingMapsFailure());
  }
};

export const handleGetGreetingsForAllMailboxes = function* () {
  yield call(handleGetMailboxes);
  //TODO: figure this out.
  //When I use the selector selectVoicemailBoxes it throws a
  //'cannot read property 'REQUEST_LIST_MAILBOXES' of undefined'
  //I think there is a cyclical dependency but I can't find it
  const mailboxes: VoiceMailboxesState['mailboxes'] = yield select(
    (store: Store) => store.voiceMailboxes?.mailboxes
  );

  try {
    const responses = yield all(
      mailboxes.map((mailbox) =>
        call(
          CustomAxios.get,
          `support/v1/voicemail/mailbox/${mailbox.mailboxID}/greetings`
        )
      )
    );

    const greetings = responses.reduce((acc, resp) => {
      return resp.data.data ? [...acc, ...resp.data.data] : acc;
    }, []);

    yield put(getGreetingsForAllMailboxesSuccess({ greetings }));
  } catch {
    yield put(getGreetingsForAllMailboxesFailure());
  }
};

const handleUpdateGreetings = function* (action) {
  for (let i = 0; i < action.payload.length; i++) {
    const greeting = action.payload[i];
    const { greetingName, greetingNumber, greetingId, mailboxId } = greeting;
    const body = {
      greeting_name: greetingName,
      greeting_number: greetingNumber,
      mailbox_id: mailboxId,
    };

    if (!greetingName) {
      yield put(showError(`Enter greeting names to reassign media.`));
      return yield put(updateGreetingFailure());
    }

    try {
      yield call(CustomAxios.put, `support/v1/voicemail/greeting/${greetingId}`, body);

      yield put(updateGreetingSuccess({ greetingName, greetingId, greetingNumber }));
      yield put(showSuccess(`${greetingName} greeting updated successfully!`));
    } catch {
      yield put(
        showError(
          `${greetingName} greeting failed to update. Please refresh the page and try again.`
        )
      );
      yield put(updateGreetingFailure());
    }
  }
};

const createGreeting = (data, useExisting?: boolean) =>
  CustomAxios.post(
    `support/v1/voicemail/greeting${useExisting ? '?useExisting=yes' : ''}`,
    data
  ).then(getResponseData);

const handleUploadGreeting = function* (action) {
  try {
    const data = new FormData();

    data.append('media-file', action.payload.greetingFile);
    data.append('greetingName', action.payload.greetingName);
    data.append('mailboxID', action.payload.mailboxId);

    const response = yield call(createGreeting, data);
    const greeting = {
      greetingId: response.greetingID,
      greetingName: response.greetingName,
      greetingNumber: response.greetingNumber,
      mediaFilePath: response.filePath,
      mailboxId: response.mailboxID,
      mediaId: response.mediaID,
      updatedAt: new Date(),
    };

    yield put(uploadGreetingSuccess(greeting));
    yield put(showSuccess('Greeting uploaded successfully!'));
  } catch {
    yield put(showError('Failed to upload greeting. Please refresh the page.'));
    yield put(uploadGreetingFailure());
  }
};
/**
 * Converts the response from the API to the schema version of the Greeting object. This
 * is necessary because the API response does not match the schema version. Since we are
 * in the middle of migrating our endpoints to schema, we need to do this conversion.
 */
const convertToSchema = (greeting): Greeting => ({
  greetingId: greeting.GreetingID,
  mailboxId: greeting.MailboxID,
  mediaId: greeting.MediaID,
  greetingName: greeting.GreetingName,
  greetingNumber: greeting.GreetingNumber,
  playLength: greeting.PlayLength,
  markedActive: greeting.MarkedActive,
  markedDeleted: greeting.MarkedDeleted,
  deletedAt: greeting.DeletedAt,
  updatedAt: greeting.UpdatedAt,
  tenantId: '', // tenantId is new to the schema version
});

const handleUploadGreetingConversion = function* (action) {
  try {
    const data = {
      mediaID: action.payload.mediaId,
      mailboxID: action.payload.mailboxId,
    };

    const resp = yield call(createGreeting, data, true);
    const greeting = convertToSchema(resp);

    yield put(uploadGreetingConversionSuccess(greeting));
    yield put(showSuccess('Greeting uploaded successfully!'));
  } catch {
    yield put(showError('Failed to upload greeting. Please refresh the page.'));
    yield put(uploadGreetingConversionFailure());
  }
};

export const voiceMailboxesSaga = function* () {
  yield all([
    takeLatest(deleteGreeting.toString(), handleDeleteGreeting),
    takeLatest(getGreetingsForMailbox.toString(), handleGetGreetingsForMailbox),
    takeLatest(getGreetingMaps.toString(), handleGetGreetingMaps),
    takeEvery(updateGreetings.toString(), handleUpdateGreetings),
    takeLatest(getGreetingsForAllMailboxes.toString(), handleGetGreetingsForAllMailboxes),
    takeLatest(uploadGreeting.toString(), handleUploadGreeting),
    takeLatest(uploadGreetingConversion.toString(), handleUploadGreetingConversion),

    takeLatest(VoicemailBoxesActionTypes.RequestListMailboxes, requestListMailboxesSaga),
    takeLatest(VoicemailBoxesActionTypes.RequestUpdateMailbox, requestUpdateMailboxSaga),
  ]);
};
