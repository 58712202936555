interface portingValidationErrorCodes {
  min_error_code: number;
  max_error_code: number;
}

interface portingValidationErrorCodesData {
  porting_information_error: portingValidationErrorCodes;
  phone_information_error: portingValidationErrorCodes;
  office_information_error: portingValidationErrorCodes;
  document_error: portingValidationErrorCodes;
}

export const portingValidationError: portingValidationErrorCodesData = {
  porting_information_error: { min_error_code: 1000, max_error_code: 1009 },
  phone_information_error: { min_error_code: 1020, max_error_code: 1029 },
  office_information_error: { min_error_code: 1030, max_error_code: 1039 },
  document_error: { min_error_code: 1040, max_error_code: 1059 },
};
