import React, { useCallback } from 'react';
import { ProhibitedFlags } from '../customization-page-v2.types';
import { useCustomizationProhibitedFlagsUpdater } from '../providers';

export const useManageProhibitedCustomizationFlags = () => {
  const updateCustomizationProhibitedFlags = useCustomizationProhibitedFlagsUpdater();

  const manageProhibitedCustomizationFlags = useCallback(
    (prohibitedCustomizationFlags: ProhibitedFlags[]) => {
      if (!!prohibitedCustomizationFlags.length) {
        updateCustomizationProhibitedFlags(prohibitedCustomizationFlags);
      } else {
        updateCustomizationProhibitedFlags([]);
      }
    },
    [updateCustomizationProhibitedFlags]
  );

  return { manageProhibitedCustomizationFlags };
};
