import React, { useState } from 'react';
import { css } from '@emotion/core';
import {
  Heading,
  MarkIcon,
  Modal,
  SpinningLoader,
  Text,
  useModalControl,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';

import {
  getHandoffTypeConstData,
  getMetricResults,
  getUniqueDependentKeysForMetrics,
} from './handoff-metrics-helpers';
import { useDependentMetricData } from './use-dependent-metric-data';
import { MetricResult, Result } from './handoff-metrics.types';
import { MetricResultsModal } from '../modals/metric-results-modal';

export type SubsectionState = {
  name: string;
  metricResults: MetricResult[];
};

type HandoffMetricsProps = {
  handoffTypeData: ReturnType<typeof getHandoffTypeConstData>;
  dependentData: ReturnType<typeof useDependentMetricData>;
};

export const HandoffMetrics = ({
  handoffTypeData,
  dependentData,
}: HandoffMetricsProps) => {
  const [selectedSubsection, setSelectedSubsection] = useState<SubsectionState>();
  const { modalProps, triggerProps } = useModalControl();

  return (
    <>
      <div>
        {handoffTypeData.sections?.map((section) => (
          <React.Fragment key={section.name}>
            <Heading level={2}>{section.name}</Heading>

            {section.subsections.map((subsection) => {
              const subsectionDependencyKeys = getUniqueDependentKeysForMetrics(
                subsection.metrics
              );
              const isDataLoading = subsectionDependencyKeys.some(
                (key) => dependentData[key].isLoading
              );

              const metricResults = getMetricResults(subsection.metrics, dependentData);

              const allMetricsPassed = metricResults.every(
                (metric) => metric.result === Result.Pass
              );

              return (
                <div key={subsection.name}>
                  <span
                    css={css`
                      display: inline-flex;
                      cursor: pointer;
                    `}
                    onClick={() => {
                      setSelectedSubsection({
                        name: subsection.name,
                        metricResults,
                      });
                      triggerProps.onClick();
                    }}
                  >
                    {isDataLoading ? (
                      <SpinningLoader size="small" />
                    ) : (
                      <MarkIcon
                        css={[
                          allMetricsPassed
                            ? css`
                                color: ${theme.colors.success};
                              `
                            : css`
                                color: ${theme.colors.disabled};
                              `,
                          css`
                            margin-right: 20px;
                          `,
                        ]}
                      />
                    )}
                    <Text>{subsection.name}</Text>
                  </span>
                </div>
              );
            })}
            <hr />
          </React.Fragment>
        ))}
      </div>

      <Modal {...modalProps} maxWidth={625}>
        <MetricResultsModal subsection={selectedSubsection} />
      </Modal>
    </>
  );
};
