import { css } from '@emotion/core';
import {
  EditIcon,
  PrimaryButton,
  TextField,
  useForm,
  Text,
  FormFieldActionTypes,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { PortGetData, ValidationData } from 'apis/porting/porting.types';
import { cloneDeep } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { portingCardStyles } from './porting-card.styles';
import { stateCodes } from './state-codes';
import { isAlphaNumericValue } from '../utils/porting-utils';
import {
  CountryCodes,
  countryOptions,
} from '../../generic/address-autocomplete/country-codes';

interface Props {
  port: PortGetData;
  officeInfoEdit: boolean;
  setOfficeInfoEdit: React.Dispatch<React.SetStateAction<boolean>>;
  handleDrawerToggle: (drawer: string, toggleState: boolean) => void;
  setPortingDetails: (port: PortGetData) => void;
  updatePortingDetails: (port) => void;
  officeInformationError?: ValidationData[];
}

export const PortingCardOfficeInfo = (props: Props) => {
  const {
    port,
    officeInfoEdit,
    setOfficeInfoEdit,
    handleDrawerToggle,
    setPortingDetails,
    updatePortingDetails,
    officeInformationError,
  } = props;

  const [invalidState, setInvalidState] = useState<boolean>(false);

  const portingRequest = port.porting_requests[0];
  const {
    getFieldProps,
    values: fields,
    formProps,
    seedValues,
    isComplete,
  } = useForm({
    fields: {
      businessName: {
        type: 'text',
        value: port.company_name,
        required: true,
      },
      officeEmail: {
        type: 'email',
        value: port?.office_email,
        required: true,
      },
      authorizedUserFirstName: {
        type: 'text',
        value: port.authorized_user_first_name,
        required: true,
      },
      authorizedUserLastName: {
        type: 'text',
        value: port.authorized_user_last_name,
      },
      accountNumber: {
        type: 'text',
        value: port.phone_service_account_number,
        required: true,
      },
      pin: {
        type: 'text',
        value: port.account_pin,
      },
      houseNumber: {
        type: 'text',
        value: port.service_house_number,
        required: true,
      },
      street: {
        type: 'text',
        value: port.service_street1,
      },
      street2: {
        type: 'text',
        value: port.service_street2,
      },
      city: {
        type: 'text',
        value: port.service_city,
        required: true,
      },
      zip: {
        type: 'text',
        value: port.service_zip,
        required: true,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Blur) {
        switch (action.payload.name) {
          case 'accountNumber': {
            if (typeof state.accountNumber.value === 'string') {
              if (!isAlphaNumericValue(state.accountNumber.value)) {
                return {
                  ...state,
                  accountNumber: {
                    ...state.accountNumber,
                    value: state.accountNumber.value.replace(/[^a-zA-Z0-9]/g, ''),
                  },
                };
              }
            }
          }
        }
      }
      return state;
    },
  });

  const getDefaultSelectedState = (serviceState) => {
    if (serviceState) {
      for (const state of stateCodes) {
        if (state.code.toLowerCase() === serviceState.toLowerCase()) {
          return [state];
        }
      }
    }
    return [];
  };

  const handleUpdatePortingDetails = () => {
    const portCopy = cloneDeep(port);
    portCopy.company_name = fields.businessName ?? '';
    portCopy.office_email = fields.officeEmail ?? '';
    portCopy.authorized_user_first_name = fields.authorizedUserFirstName ?? '';
    portCopy.authorized_user_last_name = fields.authorizedUserLastName ?? '';
    portCopy.phone_service_account_number = fields.accountNumber ?? '';
    portCopy.account_pin = fields.pin ?? '';
    portCopy.service_house_number = fields.houseNumber ?? '';
    portCopy.service_street1 = fields.street ?? '';
    portCopy.service_street2 = fields.street2 ?? '';
    portCopy.service_zip = fields.zip ?? '';
    portCopy.service_city = fields.city ?? '';
    updatePortingDetails(portCopy);
  };

  const getCountryName = (countryName) => {
    if (countryName.toUpperCase() === CountryCodes.Canada) {
      return countryOptions[0].label;
    }
    if (countryName.toUpperCase() === CountryCodes.USA) {
      return countryOptions[1].label;
    }
    return 'No Country found';
  };

  return (
    <div>
      <form {...formProps}>
        <div>
          <div className={portingCardStyles.portingInfoContainer}>
            <div className={portingCardStyles.title}>Office Information</div>
            {!portingRequest.port_order_number && (
              <div>
                <EditIcon
                  css={css`
                    color: ${!officeInfoEdit && theme.colors.weaveBlue};
                  `}
                  onClick={() => handleDrawerToggle('office', officeInfoEdit)}
                />
              </div>
            )}
          </div>
          {officeInformationError?.map((data) => (
            <Text color="error" key={data.id}>
              {data.reason}
            </Text>
          ))}
          <div className={portingCardStyles.officeCont}>
            {!officeInfoEdit && (
              <>
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={css`
                      width: 150px;
                      margin-bottom: 10px;
                    `}
                  >
                    Business Name
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 200px;
                    `}
                  >
                    {port.company_name}
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={css`
                      width: 150px;
                      margin-bottom: 10px;
                    `}
                  >
                    Account Number
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 200px;
                    `}
                  >
                    {port.phone_service_account_number}
                  </div>
                  <div
                    css={css`
                      width: 150px;
                    `}
                  >
                    PIN
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 150px;
                    `}
                  >
                    {port.account_pin || 'N/A'}
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={css`
                      width: 150px;
                      margin-bottom: 10px;
                    `}
                  >
                    Authorized User
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 200px;
                    `}
                  >
                    {port.authorized_user_first_name} {port.authorized_user_last_name}
                  </div>
                  <div
                    css={css`
                      width: 150px;
                    `}
                  >
                    Office Email
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 150px;
                    `}
                  >
                    {port?.office_email}
                  </div>
                </div>

                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={css`
                      width: 150px;
                      margin-bottom: 10px;
                    `}
                  >
                    Service Address
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 200px;
                    `}
                  >
                    <div>
                      {port.service_house_number} {port.service_street1}{' '}
                      {port.service_street2}
                    </div>
                    <div>
                      {port.service_city}, {port.service_state} {port.service_zip}
                    </div>
                  </div>

                  <div
                    css={css`
                      width: 150px;
                    `}
                  >
                    Country
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 150px;
                    `}
                  >
                    {port?.service_country && getCountryName(port.service_country)}
                  </div>
                </div>
              </>
            )}
            {officeInfoEdit && (
              <>
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={css`
                      width: 250px;
                      margin-bottom: 10px;
                    `}
                  >
                    Account Information
                  </div>
                  <div>
                    <div className={portingCardStyles.streetContainer}>
                      <div>
                        <TextField
                          {...getFieldProps('businessName')}
                          label="Business Name"
                          name="businessName"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('officeEmail')}
                          label="Office Email"
                          name="officeEmail"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>

                    <div className={portingCardStyles.streetContainer}>
                      <div>
                        <TextField
                          {...getFieldProps('authorizedUserFirstName')}
                          label="Authorized User First Name"
                          name="authorizedUserFirstName"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('authorizedUserLastName')}
                          label="Authorized User Last Name"
                          name="authorizedUserLastName"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>

                    <div className={portingCardStyles.streetContainer}>
                      <div>
                        <TextField
                          {...getFieldProps('accountNumber')}
                          label="Account Number"
                          name="accountNumber"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('pin')}
                          label="PIN"
                          name="pin"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    margin-top: 30px;
                  `}
                >
                  <div
                    css={css`
                      width: 250px;
                      margin-bottom: 10px;
                    `}
                  >
                    Service Address
                  </div>
                  <div>
                    <div className={portingCardStyles.streetContainer}>
                      <div>
                        <TextField
                          {...getFieldProps('houseNumber')}
                          label="House Number"
                          name="houseNumber"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('street')}
                          label="Street"
                          name="street"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>

                    <div className={portingCardStyles.streetContainer}>
                      <div>
                        <TextField
                          {...getFieldProps('street2')}
                          label="Apt/Ste #"
                          name="street2"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('city')}
                          label="City"
                          name="city"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>

                    <div className={portingCardStyles.typeaheadCont}>
                      <div
                        css={css`
                          width: 230px;
                        `}
                      >
                        <Typeahead
                          className={portingCardStyles.typeaheadStyles}
                          options={stateCodes}
                          id={port.id}
                          defaultSelected={getDefaultSelectedState(port.service_state)}
                          placeholder="State"
                          highlightOnlyResult
                          isInvalid={invalidState}
                          onChange={(selected) => {
                            const portCopy = cloneDeep(port);
                            const state = selected[0];
                            if (state) {
                              portCopy.service_state = state.code;
                              setInvalidState(false);
                              setPortingDetails(portCopy);
                            }
                          }}
                          onInputChange={(text) => {
                            const state = stateCodes.find(
                              (element) =>
                                text.trim().toLowerCase() === element.label.toLowerCase()
                            );
                            const portCopy = cloneDeep(port);
                            if (state) {
                              portCopy.service_state = state.code;
                              setInvalidState(false);
                              setPortingDetails(portCopy);
                            } else {
                              setInvalidState(true);
                            }
                          }}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('zip')}
                          label="Zip Code"
                          name="zip"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={portingCardStyles.saveOfficeCont}>
                  <PrimaryButton
                    className={portingCardStyles.saveOffice}
                    color="blue"
                    size="large"
                    onClick={() => {
                      setOfficeInfoEdit(false);
                      handleUpdatePortingDetails();
                    }}
                    disabled={!isComplete}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
