import { StatusMapping } from '../../../../../../../../models/writebacks.model';
import { LocationSettingsModel } from '../../../../../../../../redux/actions/auto-msg-queue/auto-msg.types';
import { DependencyDataKeys, Result } from '../handoff-metrics.types';
import { HandoffTypeMetric } from '../handoff.constants';

export const APPOINTMENT_WRITEBACKS_PASS_VALUE = 5;
export const UPSELLS_APPOINTMENT_WRITEBACKS_PASS_VALUE = 2;
const CONFIRMATION_MAPPING_PASS_VALUE = 'is set';
const SYNCAPP_COUNT_PASS_VALUE = 1;
const SYNCAPP_HEALTH_PASS_VALUE = 'No "Dead" sync apps';

const isAppointmentWritebacksEnabled = (settings: LocationSettingsModel[]) =>
  settings.some(
    (setting) =>
      setting.key === 'allow_appointment_writebacks' && setting.value === 'true'
  );

const isOneStatusMarkedAsConfirmed = (statusMappings: StatusMapping[]) =>
  statusMappings?.some((setting) => setting?.Mapping?.Confirmed?.length > 0);

///////////////////////////////////// METRICS ////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////

// APPOINTMENT WRITEBACKS METRIC
export const appointmentWritebacksMetric = (
  writebacksValue: number
): HandoffTypeMetric => ({
  testCriteriaFn: function () {
    return `${writebacksValue} appointment auto confirmations`;
  },
  dependencyDataKeys: [
    DependencyDataKeys.AutoMsgLocationSettings,
    DependencyDataKeys.AppointmentWritebacks,
  ],
  expectedValue: writebacksValue,
  actualValueFn: (dependentData) => {
    const locationSettingsData =
      dependentData[DependencyDataKeys.AutoMsgLocationSettings];
    let err = locationSettingsData?.error;
    if (err) return err.message;

    const locationSettings = locationSettingsData?.data;
    if (!locationSettings) return 'no auto-messaging location settings data';

    const writebacksEnabled = isAppointmentWritebacksEnabled(locationSettings);
    if (!writebacksEnabled)
      return 'Appointment writebacks are not enabled for this location';

    const appointmentWritebacksData =
      dependentData[DependencyDataKeys.AppointmentWritebacks];
    err = appointmentWritebacksData?.error;
    if (err) return err.message;

    const appointmentWritebacks = appointmentWritebacksData?.data;
    if (!appointmentWritebacks) return 'no appointment writebacks data';

    return appointmentWritebacks.length;
  },
  resultFn: function (dependentData) {
    const locationSettingsData =
      dependentData[DependencyDataKeys.AutoMsgLocationSettings];
    let err = locationSettingsData?.error;
    if (err || !locationSettingsData?.data) return Result.Error;

    const appointmentWritebacksData =
      dependentData[DependencyDataKeys.AppointmentWritebacks];
    err = appointmentWritebacksData?.error;
    if (err || !appointmentWritebacksData) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);

    if (typeof actualValue !== 'number') return Result.Fail;

    if (actualValue >= +this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    'PMS doesn’t provide confirmation writeback capabilities',
    `New Integration, minimal data`,
    `Office just turned on reminders`,
  ],
});

// WRITEBACK CONFIRMATION MAPPING METRIC
export const writebackConfirmationMappingMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Confirmation Mapping must be: ${this.expectedValue}`;
  },
  dependencyDataKeys: [
    DependencyDataKeys.AutoMsgLocationSettings,
    DependencyDataKeys.WritebacksStatusMappings,
  ],
  expectedValue: CONFIRMATION_MAPPING_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const locationSettingsData =
      dependentData[DependencyDataKeys.AutoMsgLocationSettings];
    let err = locationSettingsData?.error;
    if (err) return err.message;

    const locationSettings = locationSettingsData?.data;
    if (!locationSettings) return 'no auto-messaging location settings data';

    const writebacksEnabled = isAppointmentWritebacksEnabled(locationSettings);
    if (!writebacksEnabled)
      return 'Appointment writebacks are not enabled for this location';

    const writebacksStatusMappingsData =
      dependentData[DependencyDataKeys.WritebacksStatusMappings];
    err = writebacksStatusMappingsData?.error;
    if (err) return err.message;

    const writebacksStatusMappings = writebacksStatusMappingsData?.data;
    if (!writebacksStatusMappings) return 'no writebacks status mapping settings data';

    const writebacksMappingIsSet = isOneStatusMarkedAsConfirmed(writebacksStatusMappings);

    if (writebacksMappingIsSet) return CONFIRMATION_MAPPING_PASS_VALUE;
    return 'not set';
  },
  resultFn: function (dependentData) {
    const locationSettingsData =
      dependentData[DependencyDataKeys.AutoMsgLocationSettings];
    let err = locationSettingsData?.error;
    if (err || !locationSettingsData?.data) return Result.Error;

    const writebacksStatusMappingsData =
      dependentData[DependencyDataKeys.WritebacksStatusMappings];
    err = writebacksStatusMappingsData?.error;
    if (err || !writebacksStatusMappingsData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [`PMS doesn't provide confirmation write-back capabilities`],
};

// APPOINTMENT CONFIRMATION MAPPING METRIC
export const appointmentConfirmationMappingMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `One status must be marked as "Confirmed" in Appointment Status Mapping`;
  },
  dependencyDataKeys: [DependencyDataKeys.AppointmentsStatusMappings],
  expectedValue: CONFIRMATION_MAPPING_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const appointmentsStatusMappingsData =
      dependentData[DependencyDataKeys.AppointmentsStatusMappings];
    const err = appointmentsStatusMappingsData?.error;
    if (err) return err.message;

    const appointmentsStatusMappings = appointmentsStatusMappingsData?.data;
    if (!appointmentsStatusMappings) return 'no writebacks status mapping settings data';

    const writebacksMappingIsSet = isOneStatusMarkedAsConfirmed(
      appointmentsStatusMappings
    );

    if (writebacksMappingIsSet) return CONFIRMATION_MAPPING_PASS_VALUE;
    return 'not set';
  },
  resultFn: function (dependentData) {
    const appointmentsStatusMappingsData =
      dependentData[DependencyDataKeys.AppointmentsStatusMappings];
    const err = appointmentsStatusMappingsData?.error;
    if (err || !appointmentsStatusMappingsData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: ['Status mapping “set” but not pulling in WAM (officemate?)'],
};

// SYNCAPP COUNT METRIC
export const syncappCountMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have at least ${this.expectedValue} sync app`;
  },
  dependencyDataKeys: [DependencyDataKeys.SyncappHealth],
  expectedValue: SYNCAPP_COUNT_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const syncappHealthData = dependentData[DependencyDataKeys.SyncappHealth];
    const err = syncappHealthData?.error;
    if (err) return err.message;

    const syncappHealth = syncappHealthData?.data;
    if (!syncappHealth) return 'no data';

    return syncappHealth.SyncApps?.length || 0;
  },
  resultFn: function (dependentData) {
    const syncappHealthData = dependentData[DependencyDataKeys.SyncappHealth];
    const err = syncappHealthData?.error;
    if (err || !syncappHealthData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
};

// SYNCAPP HEALTH METRIC
export const syncappHealthMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `No PMS sync app health should be "Dead"`;
  },
  dependencyDataKeys: [DependencyDataKeys.SyncappHealth],
  expectedValue: SYNCAPP_HEALTH_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const syncappHealthData = dependentData[DependencyDataKeys.SyncappHealth];
    const err = syncappHealthData?.error;
    if (err) return err.message;

    const syncApps = syncappHealthData?.data?.SyncApps;
    if (!syncApps) return 'no data';

    const badSyncApps: string[] = [];

    for (const syncApp of syncApps) {
      const health = syncApp.SyncAppHealth;
      if (health !== 'Healthy' && health !== 'Unhealthy') {
        badSyncApps.push(
          `Practice Management System: "${syncApp.PracticeManagementSystem} is ${health}`
        );
      }
    }

    if (badSyncApps.length) return badSyncApps.join('\n');
    return SYNCAPP_HEALTH_PASS_VALUE;
  },
  resultFn: function (dependentData) {
    const syncappHealthData = dependentData[DependencyDataKeys.SyncappHealth];
    const err = syncappHealthData?.error;
    if (err || !syncappHealthData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: ['Office will verify on their own'],
};
