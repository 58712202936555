import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SETTINGS } from '../../../constants/paths';
import { BillingDashboard } from './billing-dashboard';

export const BillingRoutes = (props) => {
  return (
    <Switch>
      <Route exact={true} path={SETTINGS.billing} component={BillingDashboard} />
    </Switch>
  );
};
