import { IconProps } from '@weave/design-system';
import { FC } from 'react';

export interface LocationHealth {
  LocationHealth: string;
  SyncApps: SyncAppInterface[];
}

export interface SyncAppInterface {
  LocationID: string;
  SourceID: string;
  PracticeManagementSystem: string;
  SyncAppHealth: string;
  SourceName: string;
  Reasons: any[];
  UpdatedReasons: { [key: string]: any };
}

export enum SyncTabs {
  Terminal = 'Terminal',
  SyncStatus = 'SyncStatus',
  LastSyncInfo = 'LastSyncInfo',
}

export interface SyncTabInterface {
  syncTab: SyncTabs;
  icon: FC<IconProps>;
  label: string;
}

export interface DeleteSourcePayload {
  sourceId: string;
}
export interface DeleteSourceSuccessPayload {
  sourceId: string;
}
export interface GetSyncAppInfoPayload {
  sourceId: string;
  rpcMethod: string;
  rpcParams: any;
  id?: string;
  syncAppInfoKey?: string;
}

export interface AddTerminalTextPayload {
  sourceId: string;
  terminalText: string;
}

interface TerminalProps {
  addToTerminalText: (payload: AddTerminalTextPayload) => void;
  clearTerminalText: (value: string) => void;
  deleteSourceFunc: (payload: DeleteSourcePayload) => void;
  getSyncAppInfo: (payload: GetSyncAppInfoPayload) => void;
  externalWindowDoc?: Document;
}

export interface TerminalTabProps extends TerminalProps {
  sourceId: string;
  loading: boolean;
  slug: string;
  connectionId: string;
  socketOpen: boolean;
  getConnectionId: () => void;
  terminalMessages: string[];
  terminalPermissions: {
    [key: string]: boolean;
  };
}

export interface SyncAppCardProps extends TerminalProps {
  syncApp: SyncAppInterface;
  syncAppsInfo: {
    [key: string]: any;
  };
  managePermission: boolean;
  manageAdvancedPermission: boolean;
  installAdvancedPermission: boolean;
  getMultipleSyncAppInfo: (payload: GetSyncAppInfoPayload[]) => void;
  popoutSyncApp: (val: SyncAppInterface) => void;
}

export enum ConfirmModalType {
  Purge = 'Purge',
  Terminate = 'Terminate',
  Restart = 'Restart',
  DeleteSource = 'Delete',
}

export interface SyncAppButtonInterface {
  title: string;
  rpcMethod?: string;
  rpcParams?: any;
  id?: string;
  confirmModalType?: ConfirmModalType;
  showButton?: boolean;
}

export enum ConnectionState {
  Connected = 'Connected',
  Disconnected = 'Disconnected',
}

export type SyncappWriteback = {
  createdAt: string;
  locationId: string;
  outcome: string;
  objectId?: string;
  outcomeMessage: string;
  rpcMethod: string;
  sourceId: string;
};
