import jwtDecode from 'jwt-decode';
import { setLoading, unsetLoading } from '@weave/alert-system';
import { AxiosRequestConfig } from 'axios';
import {
  AuthedUser,
  AuthStorage,
  DecodedAccessToken,
  JwtPayload,
  UserAuthenticatedParams,
} from './auth.types';
import { axiosInstance, CustomAxios, getResponseData } from '../../axios';
import * as authActions from '../../actions/auth/auth.action';

export const mockAuthedUser = (props: Partial<AuthedUser> = {}): AuthedUser => ({
  ACLS: {},
  type: '',
  username: '',
  user_id: '',
  ...props,
});

export const handleLocalStorage = {
  get: (item: string): string | undefined => {
    return window.localStorage.getItem(item) || '';
  },
  create: (item: string, payload: string): string => {
    window.localStorage.setItem(item, payload);
    return payload;
  },
  delete: (key: string | string[]) => {
    if (typeof key === 'string') {
      localStorage.removeItem(key);
      return;
    }
    key.forEach((k) => {
      localStorage.removeItem(k);
    });
  },
};

/* ~~~~~~~~~~~~~~ Auth Flow Code ~~~~~~~~~~~~~~ */

export const isTokenExpired = (
  token: DecodedAccessToken | JwtPayload | undefined
): boolean => {
  if (!token) return false;
  const tokenExpirationTime = token.exp * 1000;
  return tokenExpirationTime < new Date().getTime();
};

export let authInterceptor;
let refreshLoading = false;
let refreshPromise = Promise.resolve('promise');

const handleTokenRefresh = async (): Promise<string | undefined> => {
  if (refreshLoading) return refreshPromise;
  refreshLoading = true;
  refreshPromise = CustomAxios.post(`/support/token/refresh`, {}).then((res) => {
    setTimeout(() => {
      refreshLoading = false;
    }, 5000);
    return getResponseData(res);
  });
  return refreshPromise;
};

const userAuthenticated = async ({
  dispatch,
  signOut,
  alerts,
  encodedWeaveToken,
  decodedWeaveToken,
  config,
  oktaAccessToken,
  redirectPath,
}: UserAuthenticatedParams): Promise<boolean | AxiosRequestConfig> => {
  if (isTokenExpired(decodedWeaveToken)) {
    return handleTokenRefresh()
      .then((fresh_token) => {
        if (fresh_token) {
          CustomAxios.resetAuth(fresh_token, oktaAccessToken);
          if (config) {
            dispatch(authActions.updateAuthToken({ token: fresh_token }));
            config.headers.Authorization = `Bearer ${fresh_token}`;
          } else {
            dispatch(
              authActions.signIn({
                token: fresh_token,
                redirectPath: redirectPath || '/',
                oktaAccessToken: oktaAccessToken,
              })
            );
            dispatch(unsetLoading('SIGN_IN'));
          }
        }
        return config || true;
      })
      .catch((err: any) => {
        alerts.error(
          err?.message || 'There was a problem refreshing token; please Sign back in. '
        );
        signOut();
        return config || false;
      });
  }

  if (!config) {
    dispatch(
      authActions.signIn({
        token: encodedWeaveToken,
        redirectPath: redirectPath || '/',
        oktaAccessToken,
      })
    );
    dispatch(unsetLoading('SIGN_IN'));
  }

  return config || true;
};

export const setUpAuthInterceptor = (dispatch, signOut, alerts) => {
  authInterceptor = axiosInstance.interceptors.request.use(async (config) => {
    const oktaAccessToken = axiosInstance.__wvaTokens?.okta_access_token;
    const decodedWeaveToken = axiosInstance.__wvaTokens?.weave_token;
    const encodedWeaveToken = axiosInstance.__wvaTokens?.encoded_weave_token;
    const noCheckNeeded =
      config.url === '/support/token/refresh' ||
      !decodedWeaveToken ||
      !encodedWeaveToken ||
      config.url?.includes('featureflags');

    if (noCheckNeeded) return config;

    const isAuthenticated = (await userAuthenticated({
      dispatch,
      signOut,
      alerts,
      encodedWeaveToken,
      decodedWeaveToken,
      config,
      oktaAccessToken,
    })) as AxiosRequestConfig;

    return isAuthenticated;
  });
};

export const handleAuthFromLocalStorage = async (
  dispatch,
  signOut,
  alerts
): Promise<boolean> => {
  const decodedOktaAccessToken = handleLocalStorage.get(AuthStorage.okta_access_token);
  const encodedWeaveToken = handleLocalStorage.get(AuthStorage.weave_token);
  const redirectPath = handleLocalStorage.get(AuthStorage.redirect_path);
  let oktaAccessToken: undefined | DecodedAccessToken;

  if (!encodedWeaveToken) return false;

  const decodedWeaveToken = jwtDecode(encodedWeaveToken);

  if (decodedOktaAccessToken) {
    oktaAccessToken = jwtDecode(decodedOktaAccessToken);
  }

  dispatch(setLoading('SIGN_IN'));

  /* If the screen has been reloaded we want to set the interceptor back up */
  if (!CustomAxios.headerExists('Authorization')) {
    CustomAxios.resetAuth(encodedWeaveToken, oktaAccessToken, decodedWeaveToken);
    setUpAuthInterceptor(dispatch, signOut, alerts);
  }

  const isAuthenticated = (await userAuthenticated({
    dispatch,
    signOut,
    alerts,
    encodedWeaveToken,
    decodedWeaveToken,
    oktaAccessToken,
    redirectPath,
  })) as boolean;

  return isAuthenticated;
};
