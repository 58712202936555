import React from 'react';
import { ModalControlModalProps, ConfirmationModal } from '@weave/design-system';

interface Props extends ModalControlModalProps {
  onConfirm: () => void;
}

export const ProvisionConfirmationModal = ({ onConfirm, ...modalProps }: Props) => {
  return (
    <ConfirmationModal
      {...modalProps}
      maxWidth={550}
      onConfirm={onConfirm}
      title="Are You Sure?"
      message="Once you save and provision locations you won't be able to make any changes and all un-provisioned locations will provision as organized."
      confirmLabel="Yes, Save and Provision"
      cancelLabel="Go Back"
    />
  );
};
