import { useQueryWithSetter } from 'apis/helpers/useQueryWithSetter';
import { UseQueryOptions } from 'react-query';
import { CustomAxios, getResponseData } from 'redux/axios/axios';
import {
  DeunifyLocationRequest,
  DeunifyPreviewResponse,
  DeunifyStatusResponse,
} from './deunify.types';

export const deunifyApi = {
  deunifyLocation: (renumber: DeunifyLocationRequest) =>
    CustomAxios.put(`phone-exp/unify/v1/deunify-location`, renumber),
  deunifyMedia: () => CustomAxios.put(`phone-exp/unify/v1/deunify-media`),
  preview: () =>
    CustomAxios.get(`phone-exp/unify/v1/deunify-preview`).then<DeunifyPreviewResponse>(
      getResponseData
    ),
  deunifyCommit: () => CustomAxios.put(`phone-exp/unify/v1/deunify-commit`),
  revertDeunifyCommit: () => CustomAxios.put(`phone-exp/unify/v1/revert-deunify-commit`),
  deunifyStatus: () =>
    CustomAxios.get(`phone-exp/unify/v1/deunify-status`).then<DeunifyStatusResponse>(
      getResponseData
    ),
};

export const useGetDeunifyPreview = (
  options: UseQueryOptions<DeunifyPreviewResponse> = {}
) => {
  return useQueryWithSetter<DeunifyPreviewResponse>(
    'getDeunifyPreview',
    async () => {
      return await deunifyApi.preview();
    },
    options
  );
};

export const useGetDeunifyStatus = (
  options: UseQueryOptions<DeunifyStatusResponse> = {}
) => {
  return useQueryWithSetter<DeunifyStatusResponse>(
    'getDeunifyStatus',
    async () => {
      return await deunifyApi.deunifyStatus();
    },
    options
  );
};
