import React from 'react';
import { css } from '@emotion/core';
import { useTable, usePagination } from 'react-table-7';
import { theme } from '@weave/theme-original';
import { PricingModel } from '../../../models/pricing.model';
import { usePricingColumns } from './pricing-columns';
import {
  DetailedTablePagination,
  RowsPerPageOption,
} from '../../shared/table-pagination/table-pagination';

type PricingHistoryLogProps = { pricings: PricingModel[] };

const cpDisabledStyle = css`
  color: ${theme.colors.gray500};
`;

export const PricingHistoryLog = ({ pricings }: PricingHistoryLogProps) => {
  const columns = usePricingColumns();
  const {
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
    headers,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    pageCount,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: pricings,
      pageCount: 10,
    },
    usePagination
  );

  return (
    <>
      <div
        css={css`
          display: inline-block;
        `}
      >
        <div
          css={css`
            overflow-x: auto;
            max-width: ${theme.spacing(175)};
          `}
        >
          <table
            {...getTableProps()}
            css={css`
        font-size: ${theme.spacing(1)}
        text-align: left;
        border-radius: 10px;
        overflow: hidden;
        width: fit-content;
        box-shadow: ${theme.shadows.heavy};
        margin-bottom: 20px;

        td,
        th {
          padding: 18px 20px;
          white-space: nowrap;
        }

        th {
          color: white;
          background-color: ${theme.colors.weaveBlue};
        }

        tr:nth-child(even) {
          background-color: #f5f5f5;
        }

        td:first-child {
          color: ${theme.colors.gray500};
          font-weight: 500;
        }

        td:last-child {
          text-align: center;
        }

        td:not(:first-child) {
          border-left: 1px solid rgb(236, 236, 236);
        }
      `}
          >
            <thead>
              <tr>
                {headers.map((header) => (
                  <th {...header.getHeaderProps()} key={header.id}>
                    {header.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={row.id}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          css={cell.value === 'N/A' && cpDisabledStyle}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {pageCount > 1 && (
          <DetailedTablePagination
            onNextClick={() => nextPage()}
            onPrevClick={() => previousPage()}
            onNumRowsChange={setPageSize}
            disablePrevious={!canPreviousPage}
            disableNext={!canNextPage}
            currentPage={pageIndex + 1}
            rowsPerPage={pageSize as RowsPerPageOption}
          />
        )}
      </div>
    </>
  );
};
