import React from 'react';
import { css as emotionCss } from '@emotion/core';
import { WeaveTheme } from '@weave/theme-original';
import {
  Slider,
  Rail,
  Handles,
  Tracks,
  Ticks,
  SliderModeValue,
} from 'react-compound-slider';

import { SliderHandle } from './slider-handle';

type CallLegSliderProps = {
  disableRingStartEdit?: boolean;
  maxRingEnd: number; // seconds
  onChange: (values: readonly number[]) => void;
  ringStart: number; // seconds
  ringEnd: number; // seconds
};

export const CallLegSlider = ({
  disableRingStartEdit,
  maxRingEnd,
  onChange,
  ringStart,
  ringEnd,
}: CallLegSliderProps) => {
  const domain: number[] = [0, maxRingEnd];
  const values = disableRingStartEdit ? [ringEnd] : [ringStart, ringEnd];

  /**
   * When only one handle is present, this makes sure that the ring duration has a minimum of 5 seconds
   */
  const onUpdate = (oldValues: SliderModeValue[], newValues: SliderModeValue[]) => {
    if (newValues[0].val < 5) {
      return oldValues;
    }

    return newValues;
  };

  return (
    <Slider
      mode={disableRingStartEdit ? onUpdate : 2}
      step={6}
      domain={domain}
      rootStyle={{ position: 'relative' }}
      onChange={onChange}
      values={values}
    >
      <Rail>
        {({ getRailProps }) => (
          <div
            css={(theme: WeaveTheme) => emotionCss`
                position: absolute;
                width: 100%;
                height: 4px;
                border-radius: 4px;
                cursor: pointer;
                background-color: ${theme.colors.gray[400]};
              `}
            {...getRailProps()}
          />
        )}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <>
            {handles.map(({ percent, value, id }) => (
              <SliderHandle
                key={id}
                id={id}
                percent={percent}
                value={value}
                minValue={domain[0]}
                maxValue={domain[1]}
                getHandleProps={getHandleProps}
              />
            ))}
          </>
        )}
      </Handles>
      <Tracks left={values.length === 1} right={false}>
        {({ tracks, getTrackProps }) => (
          <>
            {tracks.map(({ id, source, target }) => (
              <div
                key={id}
                className="track"
                css={(theme: WeaveTheme) => emotionCss`
                    position: absolute;
                    height: 4px;
                    z-index: 1;
                    background-color: ${theme.colors.weaveBlue};
                    border-radius: 7px;
                    cursor: pointer;
                    left: ${source.percent}%;
                    width: ${target.percent - source.percent}%;
                  `}
                {...getTrackProps()}
              />
            ))}
          </>
        )}
      </Tracks>
      <Ticks values={domain}>
        {({ ticks }) => (
          <>
            {ticks.map((tick) => (
              <div
                key={tick.id}
                css={(theme: WeaveTheme) => emotionCss`
                    color: ${theme.colors.gray[500]};
                    position: absolute;
                    margin-top: 16px;
                    font-size: 14px;
                    text-align: center;
                    margin-left: ${tick.value > 9 ? '-8px' : '-4px'};
                    left: ${tick.percent}%;
                  `}
              >
                {tick.value}s
              </div>
            ))}
          </>
        )}
      </Ticks>
    </Slider>
  );
};
