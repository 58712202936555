import React, { useEffect } from 'react';
import { Modal, useModalLoadingState } from '@weave/design-system';
import { useAlert } from '@weave/alert-system';
import { useLocationsWithFlag } from './feature-flags.api';
import { Text } from '@weave/design-system';

interface Props {
  flagName: string;
  close: () => void;
  show: boolean;
}

type ModalBodyProps = {
  flagName: string;
  onClose: () => void;
};

const ModalBody = ({ flagName, onClose }: ModalBodyProps) => {
  const {
    data: onLocations,
    isLoading: onLoading,
    error: onError,
  } = useLocationsWithFlag({
    flagName,
    value: true,
  });
  const {
    data: offLocations,
    isLoading: offLoading,
    error: offError,
  } = useLocationsWithFlag({
    flagName,
    value: false,
  });

  const loading = onLoading || offLoading;

  const modalState = useModalLoadingState();
  const alert = useAlert();

  useEffect(() => {
    modalState.setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (onError || offError) {
      alert.error('Error getting locations for flag');
    }
  }, [onError, offError]);

  return (
    <>
      <Modal.Header>Locations Enabled</Modal.Header>
      <Modal.Body>
        <Text>Count On: {onLocations?.locationIds?.length.toLocaleString() ?? 0}</Text>
        <Text>Count Off: {offLocations?.locationIds?.length.toLocaleString() ?? 0}</Text>
        <Modal.Actions
          onPrimaryClick={() => {
            modalState.setLoading(true);

            new Promise((resolve) => {
              const csvData = 'Location ID,Value';

              const csvOn = ((onLocations?.locationIds as string[]) ?? []).reduce(
                (acc, locationId) => {
                  return `${acc}\n${locationId},true`;
                },
                csvData
              );

              const csvOnOff = ((offLocations?.locationIds as string[]) ?? []).reduce(
                (acc, locationId) => {
                  return `${acc}\n${locationId},false`;
                },
                csvOn
              );

              const element = document.createElement('a');
              element.setAttribute(
                'href',
                'data:text/csv;charset=utf-8,' + encodeURIComponent(csvOnOff)
              );
              element.setAttribute(
                'download',
                `featureFlag-${flagName}-locations-set.csv`
              );
              element.style.display = 'none';
              document.body.append(element);
              element.click();
              document.body.removeChild(element);

              modalState.setLoading(false);
              resolve(true);
            });
          }}
          primaryLabel="Create CSV"
          secondaryLabel="Cancel"
          onSecondaryClick={() => onClose()}
        />
      </Modal.Body>
    </>
  );
};

export const FeatureFlagLocationsModal = ({ flagName, close, show }: Props) => {
  return (
    <Modal
      show={show}
      onClose={() => {
        close();
      }}
    >
      <ModalBody flagName={flagName} onClose={() => close()} />
    </Modal>
  );
};
