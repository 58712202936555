import { SerializedStyles, css } from '@emotion/core';
import { SpinningLoader } from '@weave/design-system';
import React from 'react';

interface SpinnerLoaderProps {
  css?: SerializedStyles;
}

export const CenteredSpinningLoader = ({ css }: SpinnerLoaderProps) => (
  <div css={[css, loadingSpinnerContainer]}>
    <SpinningLoader />
  </div>
);

const loadingSpinnerContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
`;
