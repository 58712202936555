import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getCustomizationsFlagsByLocationIdURL,
  selectOfficeConfigurations,
} from '../../../../../../../redux/actions/customization/customization.action';
import { selectCurrentLocation } from '../../../../../../../redux/actions/location';
import {
  getHandoffTypeConstData,
  getUniqueDependentDataKeys,
} from './handoff-metrics-helpers';
import { AllHandoffTypes } from './handoff.constants';
import { useDependentMetricData } from './use-dependent-metric-data';
import { useQuery } from 'react-query';
import { CustomAxios } from '../../../../../../../redux/axios';

import { CustomizationFlagsLocationFeaturesResponse } from '../../../../../../../redux/actions/customization/customization.types';
import { GetSalesforceBundleNameResponse } from '@weave/schema-gen-ts/dist/schemas/package-service/v1/package.pb';
import { DependencyDataKeys } from './handoff-metrics.types';

export const useHandoffMetrics = (handoffType: AllHandoffTypes) => {
  const currentLocation = useSelector(selectCurrentLocation);

  const {
    data: customizationFeatures,
    isError,
    error,
  } = useQuery(
    [currentLocation?.LocationID, 'locationCustomizationFeatures'],
    () => {
      const customizationFlagsLocationFeaturesURL = getCustomizationsFlagsByLocationIdURL(
        currentLocation?.LocationID
      );
      return CustomAxios.get<CustomizationFlagsLocationFeaturesResponse>(
        customizationFlagsLocationFeaturesURL
      ).then((response) => response?.data?.features ?? []);
    },
    {
      enabled: !!currentLocation?.LocationID,
      retry: false,
      // Switching between tabs or handoff types would not recall the endpoint
      // even though the data was now stale, of all the options, this is the
      // only option that refetches the data
      refetchOnMount: 'always',
    }
  );

  const officeConfigurations = useSelector(selectOfficeConfigurations);

  const dependentData = useDependentMetricData();

  const salesforceBundleData = dependentData[DependencyDataKeys.SalesforceBundleSettings];

  // Get the handoff data from the const file filtered depending on certain office
  //configurations (such as isSoftwareOnly).
  const handoffTypeData = useMemo(
    () =>
      getHandoffTypeConstData(
        handoffType,
        officeConfigurations,
        currentLocation,
        customizationFeatures ?? [],
        salesforceBundleData ?? {}
      ),
    [
      handoffType,
      officeConfigurations,
      currentLocation?.LocationID,
      customizationFeatures,
      salesforceBundleData,
    ]
  );

  // Get the array of all unique keys for the dependent data required by all metrics for
  // the filtered handoff data according to the handoffType.
  const dependentDataKeys = useMemo(
    () => getUniqueDependentDataKeys(handoffTypeData),
    [handoffTypeData]
  );

  useEffect(() => {
    if (isError) console.error(error);
  }, [isError]);

  useEffect(() => {
    fetchDependentData();
  }, [dependentDataKeys]);

  /**
   * Calls the refetch useQuery function for all of the dependent data required for the
   * handoff metrics.
   *
   * @returns
   */
  const fetchDependentData = () => {
    if (!dependentDataKeys) return;
    dependentDataKeys.forEach((key) => dependentData[key].refetch?.());
  };

  return {
    handoffTypeData,
    dependentData,
    refetch: fetchDependentData,
  };
};
