export const tagOptionsConverter = (options: string[][]) => {
  const tags: string[] = [];
  options.forEach((optionSet) => {
    const spaced = optionSet.join(' ');
    const snaked = optionSet.join('_');
    const kabobed = optionSet.join('-');
    tags.push(
      snaked.toLowerCase(),
      spaced.toLowerCase(),
      spaced,
      snaked,
      kabobed,
      kabobed.toLowerCase()
    );
  });
  return tags.map((tag) => `{${tag}}`);
};

// Customer Name
const firstNameKeys = tagOptionsConverter([['First', 'Name']]);
const firstNamesKeys = firstNameKeys.map((tag) => `[${tag}]`);
const lastNameKeys = tagOptionsConverter([['Last', 'Name']]);
const preferredNameKeys = tagOptionsConverter([
  ['Preferred', 'Name'],
  ['Best', 'Name'],
]);
const preferredNamesKeys = preferredNameKeys.map((tag) => `[${tag}]`);
const petNameKeys = tagOptionsConverter([['Pet', 'Name']]);
const petsNameKeys = petNameKeys.map((tag) => `[${tag}]`);

// Practice Info
const practiceNameKeys = tagOptionsConverter([
  ['Practice', 'Name'],
  ['Company', 'Name'],
  ['Location', 'Name'],
]);
const practicePhoneKeys = tagOptionsConverter([
  ['Practice', 'Phone'],
  ['Practice', 'Info'],
]);
const serviceProviderKeys = tagOptionsConverter([['Service', 'Provider']]);
const locationKeys = tagOptionsConverter([['Location']]);

// Date/Time
const dateKeys = tagOptionsConverter([
  ['Date'],
  ['Appt', 'Cal'],
  ['Appointment', 'Date'],
]);
const dateTimeKeys = tagOptionsConverter([
  ['Date', 'and', 'Time'],
  ['Date', '&', 'Time'],
  ['Appt', 'Datetime'],
  ['Appt', 'Date', 'Time'],
  ['Appointment', 'Datetime'],
  ['Datetime'],
  ['Date', 'Time'],
  ['Appointment'],
  ['Appt', 'Datetime', 'No', 'Tz'],
]);
const dateTimeWithTimezoneKeys = tagOptionsConverter([
  ['Date', 'and', 'Time', 'With', 'Timezone'],
]);
const dateTimeEarlyKeys = tagOptionsConverter([
  ['Date', 'and', 'Time', 'Early'],
  ['Date', 'and', 'Time', 'Minus', '15'],
]);
const dayKeys = tagOptionsConverter([
  ['Day'],
  ['Appt', 'Weekday'],
  ['Appointment', 'Weekday'],
  ['Weekday'],
]);
const dayOfMonthKeys = tagOptionsConverter([
  ['Day', 'of', 'Month'],
  ['Appt', 'Day', 'Ordinal'],
  ['Appointment', 'Day', 'Ordinal'],
  ['Day', 'Ordinal'],
]);
const daysOverdueKeys = tagOptionsConverter([['Days', 'Overdue']]);
const monthKeys = tagOptionsConverter([
  ['Month'],
  ['Appt', 'Month'],
  ['Appointment', 'Month'],
]);
const monthDayKeys = tagOptionsConverter([['Month', 'and', 'Day']]);
const timeKeys = tagOptionsConverter([
  ['Time'],
  ['Appt', 'Time'],
  ['Appointment', 'Time'],
  ['Appt', 'Time', 'No', 'Tz'],
]);
const timesKeys = timeKeys.map((tag) => `[${tag}]`);
const timeEarlyKeys = tagOptionsConverter([
  ['Time', 'Early'],
  ['Time', 'Minus', '15'],
]);
const timeWithTimezoneKeys = tagOptionsConverter([
  ['Time', 'with', 'Timezone'],
  ['Time', '+', 'Timezone'],
  ['Time', 'Plus', 'Timezone'],
  ['Time', 'and', 'Timezone'],
  ['Time', '&', 'Timezone'],
]);

// Other Types
const wellnessFormKeys = tagOptionsConverter([
  ['Wellness', 'Form'],
  ['Wellness', 'Forms'],
]);
const vaccineKey = tagOptionsConverter([['Vaccine']]);
const scheduleLinkKeys = tagOptionsConverter([['Schedule', 'Link']]);
const orderDatetimeKeys = tagOptionsConverter([
  ['Order', 'Datetime'],
  ['Order', 'Date', 'Time'],
]);
const orderTypeKeys = tagOptionsConverter([['Order', 'Type']]);
const orderTypeTitleKeys = tagOptionsConverter([['Order', 'Type', 'Title']]);
const onlineSchedulingKeys = tagOptionsConverter([['Online', 'Scheduling']]);
const documentKeys = tagOptionsConverter([['Document'], ['Documents']]);
const patientSalutationKeys = tagOptionsConverter([
  ['Patient', 'Salutation'],
  ['Patient', 'Title'],
  ['Title'],
  ['Salutation'],
  ['Prefix'],
]);
const paymentLinkKeys = tagOptionsConverter([
  ['Payment', 'Link'],
  ['Payment', 'URL'],
]);
const birthdayKeys = tagOptionsConverter([['Birthday'], ['Birthdate']]);
const collectionAmountKeys = tagOptionsConverter([
  ['Collection', 'Amount'],
  ['Collections', 'Amount'],
  ['Amount', 'Owed'],
  ['Amount', 'Due'],
]);
const bnplLinkKeys = tagOptionsConverter([
  ['Buy', 'Now', 'Pay', 'Later'],
  ['BNPL'],
  ['Buy', 'Now', 'Pay', 'Later', 'Link'],
  ['Buy', 'Now', 'Pay', 'Later', 'URL'],
  ['Buy', 'Now', 'Pay', 'Later', 'Payment', 'Link'],
  ['Buy', 'Now', 'Pay', 'Later', 'Payment', 'URL'],
  ['BNPL', 'Link'],
  ['BNPL', 'URL'],
  ['BNPL', 'Payment', 'Link'],
  ['BNPL', 'Payment', 'URL'],
]);
const recallUrlKeys = tagOptionsConverter([['Recall', 'Href', 'Url']]);
const externalUrlKeys = tagOptionsConverter([
  ['Url', 'External', 'Url'],
  ['URL', 'External', 'Url'],
  ['Url', 'External', 'Url'],
  ['URL', 'External', 'URL'],
]);
const locationIdKeys = tagOptionsConverter([
  ['Url', 'Location', 'Id'],
  ['URL', 'Location', 'Id'],
  ['Url', 'Location', 'ID'],
  ['URL', 'Location', 'ID'],
]);
const nonceKeys = tagOptionsConverter([['nonce']]);
const openingDateTimeKeys = tagOptionsConverter([
  ['Opening', 'Datetime'],
  ['Opening', 'Date', 'Time'],
]);
const openingsDateTimeKeys = tagOptionsConverter([
  ['Openings', 'Datetime'],
  ['Openings', 'Date', 'Time'],
]);

interface TagVariationsList {
  [key: string]: string[];
}

export const tagVariationsList: TagVariationsList = {
  '{appt_datetime}': dateTimeKeys,
  '{birthday}': birthdayKeys,
  '{bnpl_payment_link}': bnplLinkKeys,
  '{collection_amount}': collectionAmountKeys,
  '{date}': dateKeys,
  '{date_and_time_early}': dateTimeEarlyKeys,
  '{date_and_time_with_timezone}': dateTimeWithTimezoneKeys,
  '{day}': dayKeys,
  '{day_of_month}': dayOfMonthKeys,
  '{days_overdue}': daysOverdueKeys,
  '{document}': documentKeys,
  '{first_name}': firstNameKeys,
  '[{first_name}]': firstNamesKeys,
  '{last_name}': lastNameKeys,
  '{location}': locationKeys,
  '{month}': monthKeys,
  '{month_and_day}': monthDayKeys,
  '{nonce}': nonceKeys,
  '{online_scheduling}': onlineSchedulingKeys,
  '{opening_datetime}': openingDateTimeKeys,
  '{openings_datetime}': openingsDateTimeKeys,
  '{order_datetime}': orderDatetimeKeys,
  '{order_type}': orderTypeKeys,
  '{order_type_title}': orderTypeTitleKeys,
  '{payment_link}': paymentLinkKeys,
  '{patient_salutation}': patientSalutationKeys,
  '{pet_name}': petNameKeys,
  '[{pet_name}]': petsNameKeys,
  '{practice_name}': practiceNameKeys,
  '{practice_phone}': practicePhoneKeys,
  '{preferred_name}': preferredNameKeys,
  '[{preferred_name}]': preferredNamesKeys,
  '{recall_href_url}': recallUrlKeys,
  '{schedule_link}': scheduleLinkKeys,
  '{service_provider}': serviceProviderKeys,
  '{time}': timeKeys,
  '[{time}]': timesKeys,
  '{time_early}': timeEarlyKeys,
  '{time_with_timezone}': timeWithTimezoneKeys,
  '{url_external_url}': externalUrlKeys,
  '{url_location_id}': locationIdKeys,
  '{vaccine}': vaccineKey,
  '{wellness_form}': wellnessFormKeys,
};

export const replaceTag = (tag: string): string => {
  const replacementTag = Object.keys(tagVariationsList).find((key) =>
    tagVariationsList[key].includes(tag)
  );
  return replacementTag || '{undefined}';
};

export const standardizeTemplateTextTags = (text: string): string => {
  const regex = new RegExp(/\{(.*?)\}/gm);
  const standardizedTemplateText = text.replace(regex, (match) => {
    const defaultTag: string = replaceTag(match);
    return defaultTag;
  });
  return standardizedTemplateText;
};
