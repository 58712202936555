import React from 'react';
import { Modal, Heading, ErrorIconSmall, Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import _ from 'lodash';
import { LoaDetails } from 'apis/porting/porting.types';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

interface Props {
  showLoaDiffModal: boolean;
  closeLoaDiffModal: (boolean) => void;
  initialData?: LoaDetails;
  finalData?: LoaDetails;
}

function DiffViewer({ initialData, finalData }) {
  const newStyles = {
    variables: {
      light: {
        codeFoldGutterBackground: '#6F767E',
        codeFoldBackground: '#E2E4E5',
      },
    },
  };
  return (
    <ReactDiffViewer
      oldValue={JSON.stringify(initialData, undefined, 4)}
      newValue={JSON.stringify(finalData, undefined, 4)}
      splitView={true}
      compareMethod={DiffMethod.WORDS}
      styles={newStyles}
      leftTitle={<Text css={{ textAlign: 'center' }}>Old data</Text>}
      rightTitle={<Text css={{ textAlign: 'center' }}>New data</Text>}
    />
  );
}

export const DiffModalComponent = ({
  showLoaDiffModal,
  closeLoaDiffModal,
  initialData,
  finalData,
}: Props) => {
  return (
    <Modal
      css={css`
        padding: ${theme.spacing(2)};
        max-height: 300px;
        overflow-y: scroll;
      `}
      maxWidth={1200}
      show={showLoaDiffModal}
      onClose={() => closeLoaDiffModal(false)}
    >
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <Heading>LOA Diff Viewer</Heading>
        <ErrorIconSmall
          css={{ cursor: 'pointer', paddingTop: '${theme.spacing(2)}' }}
          onClick={() => closeLoaDiffModal(true)}
        />
      </div>
      {_.isEqual(initialData, finalData) ? (
        <Text css={{ textAlign: 'center' }}>No Difference found</Text>
      ) : (
        <DiffViewer initialData={initialData} finalData={finalData} />
      )}
    </Modal>
  );
};
