import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { ErrorBadgeIcon, Text, TextButton } from '@weave/design-system';

interface Props {
  show?: boolean;
  disableRetry: boolean;
  onRetry: () => void;
}

export const ErrorBanner = ({ onRetry, disableRetry, show = false }: Props) => {
  if (!show) {
    return null;
  }

  return (
    <div css={containerStyle}>
      <ErrorBadgeIcon color="error" />
      <div css={{ flexGrow: 1 }}>
        <Text weight="bold" css={{ margin: 0 }}>
          Provisioning Error
        </Text>
        <Text size="medium" css={{ margin: 0 }}>
          There was an issue provisioning the location(s) below. Try again to resolve.
        </Text>
      </div>
      <TextButton css={buttonStyle} onClick={onRetry} disabled={disableRetry}>
        Retry Provisioning
      </TextButton>
    </div>
  );
};

const containerStyle = css({
  padding: theme.spacing(2),
  backgroundColor: theme.colors.critical5,
  borderRadius: theme.borderRadius.small,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
});

const buttonStyle = css({
  '&&': {
    fontWeight: theme.font.weight.semibold,
    color: theme.colors.primary50,
  },
});
