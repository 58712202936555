import { useState } from 'react';
import { HandoffSnapshot } from '../../../handoff-hub.types';
import { HandoffTypes } from './handoff-metrics/handoff.constants';

const initialState: Partial<HandoffSnapshot> = {
  pointOfContact: '',
  reasonForPurchase: '',
  customizations: '',
  customizationsNotes: '',
  routerType: '',
  routerMakeAndModel: '',
  networkDecision: '',
  billingNotes: '',
  officeExperience: '',
  faxPortSubmitted: '',
  disclaimerTypeSent: '',
  handoffSubmittedBy: '',
  submittedAt: null,
  handoffName: '',
  handoffType: '' as HandoffTypes,
  id: undefined,
  metrics: [],
};

export const useSnapshotState = (initialSnapshot?: HandoffSnapshot) => {
  const [state, setState] = useState<Partial<HandoffSnapshot>>(() => {
    const initialValues = initialSnapshot ?? initialState;

    // Default the handoff type to Onboarding Handoff if not set.
    if (!initialValues.handoffType) {
      initialValues.handoffType = HandoffTypes.OnboardingHandoff;
    }

    return initialValues;
  });

  return { snapshot: state, setSnapshot: setState };
};
