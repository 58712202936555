import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from 'react-query';

import { Loader } from '../../shared/loader/loader.component';
import { selectCurrentLocation } from '../../../redux/actions/location';
import { formatDateFromUnixTimestamp } from '../../../helpers/utils';
import { Table } from 'reactstrap';
import { PortingCard } from '../porting-card/porting-card.component';
import { SuccessSVG } from './success-svg';
import { PhoneNumberLookup } from './phone-number-lookup';
import { portingHubStyles } from './porting-hub.styles';
import moment from 'moment';
import { PrimaryButton, SecondaryButton, Modal, Text } from '@weave/design-system';
import { PortOrder } from '../port-order/port-order.component';
import { SalesforcePortOrderForm } from './salesforce-port-order-form.component';
import { SearchPortingData } from './search-porting-data.component';
import { theme } from '@weave/theme-original';
import {
  PortingData,
  PortingRequest,
} from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import {
  PortStatus,
  PortingType,
} from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';
import { Page } from '../../shared';
import { portingSchemaApi } from '../../../apis/porting/porting.api';
import { useAlert } from '@weave/alert-system';
import { useDebugMode } from '../../debug/use-debug-mode';
import { usePortOrderStatus } from '../hooks/use-port-order-status';
import { useHistory } from 'react-router-dom';
import { ONBOARDING, injectParams } from '../../../constants/paths';
import { getPortOrderType, getStatusForPorting } from '../utils/porting-utils';
import { PortOut } from '../port-out/port-out';

export const PortingHub = () => {
  const [showPortOrderForm, setShowPortOrderForm] = useState<boolean>(false);
  const [searchPortResults, setSearchPortResults] = useState<PortingData[]>([]);
  const [portOrderDetails, setPortOrderDetails] = useState<PortingData | null>(null);

  const {
    portOrderStatus: currentPortOrderStatus,
    isLoading: isPortOrderStatusLoading,
    getPortOrderStatus,
    clearPortOrderStatus,
  } = usePortOrderStatus();

  const { isDebugModeEnabled } = useDebugMode();

  const alert = useAlert();
  const currentLocation = useSelector(selectCurrentLocation);

  const {
    data: portOrders,
    refetch: refetchPortingData,
    isLoading: isLoadingPortingData,
  } = useQuery({
    queryKey: [currentLocation?.LocationID, 'get-porting-data-by-location-ids'],
    queryFn: async () =>
      portingSchemaApi.getPortingDataByLocationIds(currentLocation?.LocationID),
    select: (data) => {
      const portingData = data.portingData ?? [];

      if (isDebugModeEnabled) {
        // Do not filter the data if debug mode is enabled.
        return portingData;
      } else {
        return portingData?.filter(({ portingRequests }) =>
          portingRequests?.every(
            (request) => request.portingStatus !== PortStatus.PORT_STATUS_DRAFT_INCOMPLETE
          )
        );
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!currentLocation?.LocationID,
  });

  const { mutateAsync: updatePortingData, isLoading: isLoadingUpdatePortingData } =
    useMutation({
      mutationFn: portingSchemaApi.updatePortingDataById,
      mutationKey: ['update-porting-data', currentLocation?.LocationID],
      retry: false,
      onError: () => {
        const errorMessage = 'Error updating porting data';
        alert.error(errorMessage);
      },
      onSuccess: (data) => {
        setPortOrderDetails(data?.portingData ?? null);
      },
    });

  const getStatusColor = (portingRequest: PortingRequest) => {
    const status = portingRequest?.portingStatus;
    if (status) {
      if (
        [
          PortStatus.PORT_STATUS_ACCEPTED,
          PortStatus.PORT_STATUS_FOC,
          PortStatus.PORT_STATUS_COMPLETED,
        ].includes(status)
      ) {
        return '#2BD53C';
      } else if (
        [
          PortStatus.PORT_STATUS_EXCEPTION,
          PortStatus.PORT_STATUS_REJECTED,
          PortStatus.PORT_STATUS_VALIDATION_FAILED,
          PortStatus.PORT_STATUS_INVALID_TFNS,
        ].includes(status)
      ) {
        return '#FF6B59';
      }
    }
    return '#FFB92E';
  };

  const showReviewButtonStatus = [
    PortStatus.PORT_STATUS_UNSPECIFIED,
    PortStatus.PORT_STATUS_DRAFT,
    PortStatus.PORT_STATUS_VALIDATION_FAILED,
  ];

  const handlePortOrderBackClick = (): void => {
    setPortOrderDetails(null);
    refetchPortingData();
    setShowPortOrderForm(false);
  };

  const handleCreatePortOrder = (portingData) => {
    setShowPortOrderForm(false);
    setPortOrderDetails(portingData);
  };

  const handleCheckStatus = (portOrder: PortingData) => {
    getPortOrderStatus(portOrder.id ?? '');
  };

  const clearPortingStatus = () => {
    clearPortOrderStatus();
  };

  const history = useHistory();
  const goToV1 = () => {
    history.push(injectParams(ONBOARDING.porting, { id: currentLocation?.LocationID }));
  };

  useEffect(() => {
    setSearchPortResults(portOrders ?? []);
  }, [portOrders]);

  if (!currentLocation) {
    return null;
  }
  if (isLoadingPortingData) {
    return (
      <div className={portingHubStyles.loading}>
        <Loader />
      </div>
    );
  }
  if (portOrderDetails) {
    return (
      <PortingCard
        port={portOrderDetails}
        back={handlePortOrderBackClick}
        currentLocation={currentLocation}
        updatePortingData={updatePortingData}
        isLoadingUpdatePortingData={isLoadingUpdatePortingData}
        setPortingDetails={(portData) => setPortOrderDetails(portData ?? null)}
        getStatusColor={getStatusColor}
      />
    );
  }

  return showPortOrderForm ? (
    <PortOrder
      currentLocation={currentLocation}
      back={handlePortOrderBackClick}
      handleCreatePortOrder={handleCreatePortOrder}
      setShowPortOrderForm={(show) => setShowPortOrderForm(show)}
    />
  ) : (
    <Page
      title={`Port Orders`}
      headerActions={
        <div className={portingHubStyles.portContainerButtons}>
          <div className={portingHubStyles.newPortOrder}>
            <PrimaryButton color="blue" onClick={() => setShowPortOrderForm(true)}>
              New Port Order
            </PrimaryButton>
          </div>
          <div className={portingHubStyles.portRefresh}>
            <PrimaryButton color="blue" onClick={() => refetchPortingData()}>
              Refresh
            </PrimaryButton>
          </div>
          {isDebugModeEnabled && (
            <div className={portingHubStyles.portRefresh}>
              <PrimaryButton color="blue" onClick={goToV1}>
                Go To V1
              </PrimaryButton>
            </div>
          )}
        </div>
      }
      subtitle="View all port orders for this location below"
    >
      <div className={portingHubStyles.portContainer}>
        <SalesforcePortOrderForm getPortingData={refetchPortingData} />
        <SearchPortingData
          portOrders={portOrders ?? []}
          setSearchPortResult={setSearchPortResults}
        />
      </div>
      <div
        css={css`
          margin-right: 20px;
        `}
      >
        {/*// @ts-ignore */}
        <Table borderless="true" className={portingHubStyles.tableView}>
          <thead>
            <tr className={portingHubStyles.tableHeadRow}>
              <th
                css={css`
                  width: 375px;
                `}
              >
                Port Order Number
              </th>
              <th>Created Date</th>
              <th>Port Type</th>
              <th>Requested Date</th>
              <th>FOC Date</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>

          <tbody className={portingHubStyles.tableBody}>
            {searchPortResults?.map((port, index) => {
              const portingRequest = port?.portingRequests?.[0];
              if (!portingRequest) {
                return null;
              }

              return (
                <tr key={index}>
                  {/* Port Order Number */}
                  <td
                    css={css`
                      width: 375px;
                      margin-top: ${theme.spacing(2)};
                    `}
                  >
                    <div
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {portingRequest.portOrderNumber}
                    </div>
                  </td>

                  {/* Created Date */}
                  <td>
                    <div
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {portingRequest.createdAt &&
                        moment(portingRequest.createdAt).utc().format('MM/DD/YY')}
                    </div>
                  </td>

                  {/* Port Type */}
                  <td>
                    <div
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {getPortOrderType(port)}
                    </div>
                  </td>

                  {/* Requested Date */}
                  <td>
                    <div
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {portingRequest.requestedFirmOrderCommitmentDate
                        ? moment(portingRequest.requestedFirmOrderCommitmentDate)
                            .utc()
                            .format('MM/DD/YY')
                        : ''}
                    </div>
                  </td>

                  {/*FOC Date */}
                  <td>
                    <div
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {portingRequest.firmOrderCommitmentDate
                        ? moment(portingRequest.firmOrderCommitmentDate)
                            .utc()
                            .format('MM/DD/YY')
                        : ''}
                    </div>
                  </td>

                  {/* Status */}
                  <td className={portingHubStyles.dotColumn}>
                    <div
                      css={(theme) => css`
                        width: 8px;
                        height: 8px;
                        margin: ${theme.spacing(1)};
                        border: 1px solid ${getStatusColor(portingRequest)};
                        background-color: ${getStatusColor(portingRequest)};
                        border-radius: 50%;
                      `}
                    />
                    <p
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {getStatusForPorting(port)}
                    </p>
                  </td>

                  {/* Actions */}
                  <td>
                    <div className={portingHubStyles.tableActions}>
                      {portingRequest.portingStatus &&
                      !showReviewButtonStatus.includes(portingRequest.portingStatus) ? (
                        <>
                          {portingRequest.portingStatus !==
                            PortStatus.PORT_STATUS_CANCELLED && (
                            <div className={portingHubStyles.portContainerButtons}>
                              <div className={portingHubStyles.newPortOrder}>
                                <PrimaryButton
                                  color="blue"
                                  onClick={() => handleCheckStatus(port)}
                                >
                                  Check Status
                                </PrimaryButton>
                              </div>
                            </div>
                          )}
                          <div
                            className={portingHubStyles.portRefresh}
                            css={css`
                              text-align: center;
                              cursor: pointer;
                              margin-top: ${theme.spacing(2)};
                            `}
                            onClick={() => {
                              setPortOrderDetails(port);
                            }}
                          >
                            View Info
                          </div>
                        </>
                      ) : (
                        <div className={portingHubStyles.portContainerButtons}>
                          <div className={portingHubStyles.newPortOrder}>
                            <SecondaryButton
                              className={portingHubStyles.reviewButton}
                              color="gray"
                              onClick={() => {
                                setPortOrderDetails(port);
                              }}
                            >
                              Review
                            </SecondaryButton>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {!searchPortResults.length && (
        <Text
          css={css`
            text-align: center;
          `}
        >
          No Result Found
        </Text>
      )}

      {portOrders?.length === 0 && (
        <div
          css={css`
            text-align: center;
            margin-bottom: 14px;
          `}
        >
          There are no active ports for this location.
        </div>
      )}
      <PhoneNumberLookup />
      <PortOut />
      <Modal
        onClose={clearPortingStatus}
        css={(theme) =>
          css`
            padding: ${theme.spacing(2)};
          `
        }
        show={
          isPortOrderStatusLoading ||
          (!isPortOrderStatusLoading && !!currentPortOrderStatus) ||
          false
        }
      >
        {isPortOrderStatusLoading ? (
          <div>
            <div className={portingHubStyles.loading}>
              <Loader size="large" />
            </div>
            <div className={portingHubStyles.pingingBandwidth}>
              Pinging {currentPortOrderStatus?.provider ?? 'Provider'}...
            </div>
          </div>
        ) : (
          <div>
            <div className={portingHubStyles.loading}>
              <SuccessSVG />
            </div>
            <div className={portingHubStyles.statusComplete}>Status Check Complete!</div>
            {currentPortOrderStatus && (
              <div className={portingHubStyles.progress}>
                {currentPortOrderStatus.progressStatus}
              </div>
            )}

            {currentPortOrderStatus && (
              <div className={portingHubStyles.portStatusStyle}>
                {currentPortOrderStatus.orderHistories &&
                  currentPortOrderStatus.orderHistories.map((orderHistory, index) => {
                    if (
                      index ===
                        (currentPortOrderStatus.orderHistories?.length ?? 0) - 1 ||
                      index === (currentPortOrderStatus.orderHistories?.length ?? 0) - 2
                    ) {
                      return (
                        <div
                          key={index}
                          css={css`
                            margin-bottom: 10px;
                          `}
                        >
                          <span
                            css={css`
                              font-weight: bold;
                            `}
                          >
                            {formatDateFromUnixTimestamp(
                              orderHistory.orderDate ?? '',
                              'MM/dd/yy'
                            )}
                            :
                          </span>{' '}
                          {orderHistory.note}
                        </div>
                      );
                    }
                    return null;
                  })}

                {currentPortOrderStatus.orderNotes &&
                  currentPortOrderStatus.orderNotes.map((orderNote, index) => {
                    if (
                      index === (currentPortOrderStatus.orderNotes?.length ?? 0) - 1 ||
                      index === (currentPortOrderStatus.orderNotes?.length ?? 0) - 2
                    ) {
                      return (
                        <div
                          key={index}
                          css={css`
                            margin-bottom: 10px;
                          `}
                        >
                          <span
                            css={css`
                              font-weight: bold;
                            `}
                          >
                            {formatDateFromUnixTimestamp(
                              orderNote.lastDateModifier ?? '',
                              'MM/dd/yy'
                            )}
                            :
                          </span>{' '}
                          {orderNote.description}
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            )}
            <PrimaryButton
              className={portingHubStyles.gotItButton}
              color="blue"
              size="large"
              onClick={() => {
                clearPortingStatus();
              }}
            >
              Got it
            </PrimaryButton>
          </div>
        )}
      </Modal>
    </Page>
  );
};
