import { CustomAxios, getResponseData } from 'redux/axios';
import { WamSyncStatusResponse } from './wam-sync.types';

const getSyncStatusByLocationSlug = async (
  locationSlug: string
): Promise<WamSyncStatusResponse> => {
  const response = await CustomAxios.get<WamSyncStatusResponse>(
    `/package-service/v1/wam-sync/sync-status/wam/${locationSlug}`
  );
  return getResponseData<WamSyncStatusResponse>(response);
};

export const WamSyncSchemaAPI = {
  getSyncStatusByLocationSlug,
};
