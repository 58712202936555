import { HandoffTypeMetric } from '../handoff.constants';
import { DependencyDataKeys, Result } from '../handoff-metrics.types';

export const HAS_ONLINE_SCHEDULE_HEALTH = 'True';
export const HAS_APPOINTMENT_TYPES_HEALTH = 'Healthy';
export const HAS_CUSTOM_FIELDS_HEALTH = 'Healthy';
export const HAS_CUSTOMIZATION_SCHEDULING_HEALTH = 'Healthy';
export const HAS_EXCEPTIONS_HEALTH = 'Healthy';
export const HAS_OFFICE_HOURS_HEALTH = 'Healthy';
export const HAS_PROVIDERS_HEALTH = 'Healthy';
export const HAS_WRITEBACK_HEALTH = 'Healthy';
export const ONLINE_SCHEDULING_FEATURE_ENABLED = 'Enabled';

const onlineSchedulingExceptions: string[] = [
  'Office still working with IT on settings and widget.',
  'Office is on Unify',
  'Office is not interested or planning on using this feature.',
];

export const onlineSchedulingHealthMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Online Scheduling should be enabled and working`;
  },
  dependencyDataKeys: [DependencyDataKeys.OnlineSchedulingHealth],
  expectedValue: HAS_ONLINE_SCHEDULE_HEALTH,
  actualValueFn: (dependentData) => {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return err.message;

    const scheduleHealth = onlineSchedulingData?.data;
    if (!scheduleHealth) return 'No data';

    if (scheduleHealth.healthy) {
      return HAS_ONLINE_SCHEDULE_HEALTH;
    }

    return 'False';
  },
  resultFn: function (dependentData) {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return Result.Error;
    const actualValue = this.actualValueFn(dependentData);

    if (actualValue === this.expectedValue) return Result.Pass;
    return Result.Error;
  },
  exceptions: onlineSchedulingExceptions,
};

export const onlineSchedulingStepsAppointmentMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Online Scheduling Appointment Types`;
  },
  dependencyDataKeys: [DependencyDataKeys.OnlineSchedulingHealth],
  expectedValue: HAS_APPOINTMENT_TYPES_HEALTH,
  actualValueFn: (dependentData) => {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return err.message;

    const scheduleSteps = onlineSchedulingData?.data?.steps;
    if (!scheduleSteps) return 'No data';

    if (scheduleSteps.appointmentTypes.healthy) return HAS_APPOINTMENT_TYPES_HEALTH;

    return 'Unhealthy';
  },
  resultFn: function (dependentData) {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);

    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Error;
  },
  exceptions: onlineSchedulingExceptions,
};

export const onlineSchedulingStepsCustomFieldsMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Online Scheduling Custom Fields`;
  },
  dependencyDataKeys: [DependencyDataKeys.OnlineSchedulingHealth],
  expectedValue: HAS_CUSTOM_FIELDS_HEALTH,
  actualValueFn: (dependentData) => {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return err.message;

    const scheduleSteps = onlineSchedulingData?.data?.steps;
    if (!scheduleSteps) return 'No data';

    if (scheduleSteps.customFields.healthy) return HAS_CUSTOM_FIELDS_HEALTH;

    return 'Unhealthy';
  },
  resultFn: function (dependentData) {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);

    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Error;
  },
  exceptions: onlineSchedulingExceptions,
};

export const onlineSchedulingStepsCustomizationMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Online Scheduling Customization`;
  },
  dependencyDataKeys: [DependencyDataKeys.OnlineSchedulingHealth],
  expectedValue: HAS_CUSTOMIZATION_SCHEDULING_HEALTH,
  actualValueFn: (dependentData) => {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return err.message;

    const scheduleSteps = onlineSchedulingData?.data?.steps;
    if (!scheduleSteps) return 'No data';

    if (scheduleSteps.customizationOnlineScheduling.healthy)
      return HAS_CUSTOMIZATION_SCHEDULING_HEALTH;

    return 'Unhealthy';
  },
  resultFn: function (dependentData) {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);

    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Error;
  },
  exceptions: onlineSchedulingExceptions,
};

export const onlineSchedulingStepsOfficeHoursMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Online Scheduling Office Hours`;
  },
  dependencyDataKeys: [DependencyDataKeys.OnlineSchedulingHealth],
  expectedValue: HAS_OFFICE_HOURS_HEALTH,
  actualValueFn: (dependentData) => {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return err.message;

    const scheduleSteps = onlineSchedulingData?.data?.steps;
    if (!scheduleSteps) return 'No data';

    if (scheduleSteps.officeHours.healthy) return HAS_OFFICE_HOURS_HEALTH;

    return 'Unhealthy';
  },
  resultFn: function (dependentData) {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);

    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Error;
  },
  exceptions: onlineSchedulingExceptions,
};

export const onlineSchedulingStepsProvidersMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Online Scheduling Providers`;
  },
  dependencyDataKeys: [DependencyDataKeys.OnlineSchedulingHealth],
  expectedValue: HAS_PROVIDERS_HEALTH,
  actualValueFn: (dependentData) => {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return err.message;

    const scheduleSteps = onlineSchedulingData?.data?.steps;
    if (!scheduleSteps) return 'No data';

    if (scheduleSteps.providers.healthy) return HAS_PROVIDERS_HEALTH;

    return 'Unhealthy';
  },
  resultFn: function (dependentData) {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);

    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Error;
  },
  exceptions: onlineSchedulingExceptions,
};

export const onlineSchedulingStepsExceptionsMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Online Scheduling Exceptions`;
  },
  dependencyDataKeys: [DependencyDataKeys.OnlineSchedulingHealth],
  expectedValue: HAS_EXCEPTIONS_HEALTH,
  actualValueFn: (dependentData) => {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return err.message;

    const scheduleSteps = onlineSchedulingData?.data?.steps;
    if (!scheduleSteps) return 'No data';

    if (scheduleSteps.exceptions.healthy) return HAS_EXCEPTIONS_HEALTH;

    return 'Unhealthy';
  },
  resultFn: function (dependentData) {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);

    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Error;
  },
  exceptions: onlineSchedulingExceptions,
};

export const onlineSchedulingStepsWritebackMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Online Scheduling Writeback`;
  },
  dependencyDataKeys: [DependencyDataKeys.OnlineSchedulingHealth],
  expectedValue: HAS_WRITEBACK_HEALTH,
  actualValueFn: (dependentData) => {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return err.message;

    const scheduleSteps = onlineSchedulingData?.data?.steps;
    if (!scheduleSteps) return 'No data';

    if (scheduleSteps.writeback.healthy) return HAS_WRITEBACK_HEALTH;

    return 'Unhealthy';
  },
  resultFn: function (dependentData) {
    const onlineSchedulingData = dependentData[DependencyDataKeys.OnlineSchedulingHealth];
    const err = onlineSchedulingData?.error;
    if (err) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);

    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Error;
  },
  exceptions: onlineSchedulingExceptions,
};
export const customizationsFeaturesMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Online Scheduling Writeback`;
  },
  dependencyDataKeys: [DependencyDataKeys.CustomizationFlags],
  expectedValue: ONLINE_SCHEDULING_FEATURE_ENABLED,
  actualValueFn: (dependentData) => {
    const customizationFeaturesData =
      dependentData[DependencyDataKeys.CustomizationFlags];

    const err = customizationFeaturesData?.error;
    if (err) return err.message;

    const features = customizationFeaturesData?.data?.data?.features;
    if (!features) return 'No data';

    const onlineSchedulingFeatureFlag = features.find(
      (feature) => feature.featureEnum === 'ONLINE_SCHEDULING'
    );

    if (onlineSchedulingFeatureFlag?.state === 'ACTIVE')
      return ONLINE_SCHEDULING_FEATURE_ENABLED;

    return onlineSchedulingFeatureFlag?.state;
  },
  resultFn: function (dependentData) {
    const customizationFeaturesData =
      dependentData[DependencyDataKeys.CustomizationFlags];
    const err = customizationFeaturesData?.error;
    if (err) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);

    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Error;
  },
};
