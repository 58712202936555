import React from 'react';
import {
  FormRow,
  OptionSwitchField,
  Text,
  TextareaField,
  useForm,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';

import { HandoffSnapshot } from '../../../../handoff-hub.types';
import { FormComponentProps } from '../handoff-metrics/handoff.constants';
import { FooterButtons } from './footer-buttons';

export const UpsellForm = ({
  state,
  disableButtons,
  onBackClick,
  onSaveAndClose,
  onSubmitClick,
}: FormComponentProps) => {
  const { formProps, getFieldProps, isComplete, values } = useForm({
    fields: {
      officeExperience: { type: 'text', value: state.officeExperience, required: true },
    },
    onSubmit: () => {
      onSubmitClick(getHandoffData());
    },
  });

  const getHandoffData = (): Partial<HandoffSnapshot> => ({
    officeExperience: values.officeExperience ?? '',
  });

  const handleBackButtonClick = () => {
    onBackClick(getHandoffData());
  };

  const handleSaveAndCloseClick = () => {
    onSaveAndClose(getHandoffData());
  };

  const disableSubmit = !isComplete;

  return (
    <>
      <form {...formProps}>
        <div>
          <div>
            <Text css={titles} weight="bold">
              Location Information
            </Text>
            <Text color="light">
              Fill out the information below to add to your handoff note
            </Text>
          </div>

          <div css={formContainer}>
            <Text>Please include any helpful notes and details below</Text>
            <FormRow>
              <TextareaField
                {...getFieldProps('officeExperience')}
                label="Placeholder text"
              />
            </FormRow>
          </div>
        </div>

        <FooterButtons
          disableSubmitButton={disableSubmit}
          disableMutateButtons={disableButtons}
          onBackButtonClick={handleBackButtonClick}
          onSaveAndCloseClick={handleSaveAndCloseClick}
        />
      </form>
    </>
  );
};

export const titles = css`
  font-size: ${theme.fontSize(16)};
`;

export const contentContainer = css`
  display: flex;
  flex-direction: column;
`;

export const formContainer = css`
  padding-left: ${theme.spacing(1)};
`;
