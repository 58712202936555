import React from 'react';
import { useHoverLabel, XIcon } from '@weave/design-system';

type Props = {
  errorMessage: string;
};

export const IntakeFormHover = ({ errorMessage }: Props) => {
  const { HoverLabel, labelProps, triggerProps } = useHoverLabel({ placement: 'top' });

  return (
    <span {...triggerProps}>
      <XIcon></XIcon>
      <HoverLabel {...labelProps}>
        {errorMessage ? errorMessage : 'Error in provisioning'}
      </HoverLabel>
    </span>
  );
};
