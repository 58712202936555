import { css } from '@emotion/core';
import { TextLink, Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';

type DebugModeRibbonProps = {
  onTurnOffClick: () => void;
};

export const DebugModeRibbon = ({ onTurnOffClick }: DebugModeRibbonProps) => {
  return (
    <div
      css={css({
        textAlign: 'center',
        position: 'fixed',
        zIndex: 1000,
        width: '100%',
      })}
    >
      <div css={{ backgroundColor: theme.colors.strawberry, opacity: 0.5 }}>
        <Text as="span" color="white" size="small">
          You are in Debug Mode :
        </Text>

        <TextLink
          size="small"
          css={{
            marginLeft: theme.spacing(1),
            color: theme.colors.white,
            fontWeight: theme.font.weight.bold,
          }}
          onClick={onTurnOffClick}
        >
          Turn Off
        </TextLink>
      </div>
    </div>
  );
};
