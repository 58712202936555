import { UseQueryOptions, useQuery } from 'react-query';
import { getLocationHierarchyBySlug } from './api';
import { GetHierarchyBySlugResponse } from '@weave/schema-gen-ts/dist/schemas/location/v1/provision/location_provision_service.pb';

export const useGetLocationHierarchyBySlugQuery = (
  slug: string,
  options?: UseQueryOptions<GetHierarchyBySlugResponse>
) => {
  return useQuery({
    cacheTime: 0,
    retry: false,
    queryKey: ['get-location-hierarchy-by-slug', slug],
    queryFn: () => getLocationHierarchyBySlug({ slug }),
    refetchOnWindowFocus: false,
    ...options,
    enabled: !!slug,
  });
};
