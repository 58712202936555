import React from 'react';
import { css } from 'emotion';
import { styles, Text } from '@weave/design-system';
import { formatDate } from '../../table-configs';
import { theme } from '@weave/theme-original';

interface Props {
  smsData: smsData;
}

export interface smsData {
  autogeneratedBy?: string;
  body: string;
  createdBy?: string;
  createdAt: string;
  departmentId?: string;
  direction: string;
  id: string;
  labels?: {};
  locationId: string;
  locationPhone: string;
  personId?: string;
  personPhone: string;
  relatedIds?: {};
  status: string;
  statusDetails: string;
  updatedAt: string;
  providerMsgId?: string;
  numMedia?: number;
  threadId?: string;
}

const flexContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  padding: 0 ${theme.spacing(2)};
`;

const flexSplit = css`
  width: 40%;

  p:last-child {
    margin-bottom: 0;
  }
`;

const spanStyle = css`
  width: 125px;
  font-weight: bold !important;
  display: inline-block;
  text-align: right;
  margin-right: ${theme.spacing(1)};
  margin-bottom: 0;
  white-space: normal;
  vertical-align: top;
`;

const bodyContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const bodyStyle = css`
  white-space: normal;
  width: calc(100% - 125px);
  ${styles.multilineTruncate(10)}
`;

export const SmsHistoryItem = ({ smsData }: Props) => {
  const { id, personId, status, autogeneratedBy, body, createdAt, createdBy, direction } =
    smsData;

  const outbound = 'DIRECTION_OUTBOUND';
  const smsDirection = direction === outbound ? 'Outbound' : 'Inbound';
  const userId = createdBy ? createdBy : direction === outbound ? 'Autogenerated' : 'N/A';
  const formattedDate = formatDate(createdAt);
  const messageType = autogeneratedBy ? 'Auto Message' : 'Manual Message';

  return (
    <div className={flexContainer}>
      <div className={flexSplit}>
        <Text>
          <span className={spanStyle}>SMS ID </span>
          {id}
        </Text>
        <Text>
          <span className={spanStyle}>Person ID </span>
          {personId || 'N/A'}
        </Text>
        <Text>
          <span className={spanStyle}>Office User ID </span>
          {userId}
        </Text>
        <Text>
          <span className={spanStyle}>Sent At </span>
          {formattedDate}
        </Text>
        <Text>
          <span className={spanStyle}>Direction </span>
          {smsDirection}
        </Text>
        <Text>
          <span className={spanStyle}>Status </span>
          {status}
        </Text>
        <Text>
          <span className={spanStyle}>Type </span>
          {messageType}
        </Text>
      </div>
      <div className={flexSplit}>
        <div className={bodyContainerStyle}>
          <Text className={spanStyle}>Body </Text>
          <Text className={bodyStyle}>{body}</Text>
        </div>
      </div>
    </div>
  );
};
