import format from 'date-fns/format';

export const LocationUUIDRegExp =
  /location\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;
export const dateFormat = 'll';
export const dateTimeFormat = 'MMM D, YYYY h:mm a';
export const fnsDateTimeFormat = 'MMM d, yyyy h:mm a';
export const dateTimeZoneFormat = 'MM/DD/YY h:mma z';
export const dateTimeDateFns = 'MM/dd/yyyy h:mm:ssa';
export const dayOfWeekDateFns = 'ddd MM/dd';
export const fnsDatePickerFieldFormat = 'MM/dd/yyyy';
export const fnsTimeFieldFormat = 'hh:mm a';
export const dateWithoutTimeFormat = 'MMM d, yyyy';

/* Formats a phone number into a more readable format
 * fancy returns a number formatted like, (000) 000-0000, instead of 000-000-0000
 * blank means to return nothing, instead of 'Unknown'
 */
export function formatPhoneNumber(tel: string, fancy = true, blank = true): string {
  if (!tel || typeof tel === 'undefined' || tel.length === 0) {
    return blank ? '' : 'Unknown';
  }

  const value = tel.toString().trim().replace(/^\+/, '').replace(/-/g, '');

  if (value.match(/[^0-9]/)) {
    return tel;
  }

  let country: string;
  let city;
  let phone;

  switch (value.length) {
    case 7: // ####### -> ###-####
      country = '1';
      city = '';
      phone = tel;
      break;
    case 10: // +1PPP####### -> C (PPP) ###-####
      country = '1';
      city = value.slice(0, 3);
      phone = value.slice(3);
      break;

    case 11: // +CCPPP####### -> CC (PP) ###-####
      country = value[0];
      city = value.slice(1, 4);
      phone = value.slice(4);
      break;

    case 12: // +CCCPP####### -> CCC (PP) ###-####
      country = value.slice(0, 3);
      city = value.slice(3, 5);
      phone = value.slice(5);
      break;

    default:
      return tel;
  }

  if (country === '1') {
    country = '';
  }

  // Add separator to last 7-digits of number
  phone = phone.slice(0, 3) + '-' + phone.slice(3);

  if (fancy) {
    if (city.length > 0) {
      return (country + ' (' + city + ') ' + phone).trim();
    } else {
      return phone.trim();
    }
  } else {
    if (city.length > 0) {
      return country.length > 0
        ? (country + '-' + city + '-' + phone).trim()
        : (city + '-' + phone).trim();
    } else {
      return phone.trim();
    }
  }
}

export function formatDuration(ms) {
  let h, m, s;
  s = Math.floor(ms / 1000);
  m = Math.floor(s / 60);
  s = s % 60;
  h = Math.floor(m / 60);
  m = m % 60;
  h = h % 24;
  if (m < 10) {
    m = `0${m}`;
  }
  if (s < 10) {
    s = `0${s}`;
  }
  return `${h}:${m}:${s}`;
}

export function formatDateFromHoursAndMinutesOnly(hours?: number, minutes?: number) {
  return new Date(0, 0, 0, hours || 0, minutes || 0);
}

export function formatTimeFromHoursAndMinutesOnly(hours: number, minutes: number) {
  return format(formatDateFromHoursAndMinutesOnly(hours, minutes), fnsTimeFieldFormat);
}

export function stripNonNumericCharacters(value: string) {
  return value.replace(/\D/g, '');
}

export function formatDateFromUnixTimestamp(
  seconds: string | number,
  formatString: string = fnsDateTimeFormat
) {
  return format(new Date(seconds), formatString);
}

export function hasLocationUUID(searchString: string): boolean {
  return searchString.search(LocationUUIDRegExp) >= 0;
}

export function getLocationUUID(searchString: string): string {
  const matches = searchString.match(LocationUUIDRegExp);
  return matches && matches[0] ? matches[0].replace('location/', '') : '';
}

export function replaceLocationUUID(searchString: string, newUUID: string): string {
  return searchString.replace(LocationUUIDRegExp, `location/${newUUID}`);
}

export function processArrayBuffer(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  // Convert the int array to a binary string
  // We loop through the bytes, instead of using the Array in an apply(), since
  // String.fromCharCode().apply() hits a maximum size when the bytelength
  // is more than 65536 (which is the limit for apply()).
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return binary;
}

export function arrayBufferToBase64(buffer) {
  // Convert to base64
  return window.btoa(processArrayBuffer(buffer));
}

export function getFileExtensionFromFileName(fileName: string) {
  if (!fileName) return '';

  const dotIndex = fileName.lastIndexOf('.');

  if (dotIndex < 0 || dotIndex === fileName.length - 1) {
    return '';
  }

  return fileName.substring(dotIndex + 1);
}

export const parseIfJson = (value) => {
  let result = value;
  try {
    result = JSON.parse(value);
  } catch {
    // Continue regardless of the error.
  }
  return result;
};

export const copyStyles = (sourceDoc, targetDoc) => {
  Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
    const css = styleSheet as CSSStyleSheet;
    if (css && css.cssRules && css.cssRules.length > 0) {
      const newStyleEl = sourceDoc.createElement('style');

      Array.from(css.cssRules).forEach((cssRule) => {
        // write the text of each rule into the body of the style element
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    } else if (css.href) {
      // for <link> elements loading CSS from a URL
      const newLinkEl = sourceDoc.createElement('link');

      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = css.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
};

type Enum = { [s: number]: string };

/**
 * This method is meant to future proof against any changes to how data is returned when it's backed by an enum and may either contain a string or number value
 * @param compareVal The value in question which may be a string or a number
 * @param enumVal The js enum value which is a number
 * @param enumDefinition The js enum definition
 * @returns
 */
export const enumEquals = <
  EDefinition extends Enum,
  EValue extends EDefinition[keyof EDefinition]
>(
  compareVal: string | number,
  enumVal: EValue,
  enumDefinition: EDefinition
): boolean => {
  if (compareVal === enumVal) {
    // Should cover number <> (enum-val)number
    return true;
  }
  const keys = Object.keys(enumDefinition).filter((v) => isNaN(Number(v)));
  if (typeof compareVal === 'string' && keys.includes(compareVal)) {
    // The compareVal is a string value that exists in the enumDefinition
    return enumDefinition[compareVal as keyof EDefinition] === enumVal; // Now see if it's equal
  }
  return false;
};
