import { PhoneNumber, PhoneNumberModel } from './phone-numbers.types';
import { mockPhoneNumbers } from './phone-numbers.mocks';
import { useQueryWithSetter } from '../helpers/useQueryWithSetter';

import MockSorage from '../helpers/mockStorage';
import { CustomAxios, getResponseData } from 'redux/axios';

//Todo: Delete this whole folder I think
const mockStorage = new MockSorage<PhoneNumber>('PhoneNumbers', mockPhoneNumbers(), 'id');

const api = {
  listPhoneNumbers: (): Promise<PhoneNumber[]> => mockStorage.getAll(),
  getPhoneNumbers: (): Promise<PhoneNumberModel[]> =>
    CustomAxios.get(`desktop/v1/phones/sms/numbers`).then(getResponseData),
};

export function usePhoneNumberList(options?: any) {
  return useQueryWithSetter('phoneNumberList', api.listPhoneNumbers, options);
}

export const useQueryGetPhoneNumbers = () => {
  return useQueryWithSetter('getPhoneNumbers', api.getPhoneNumbers);
};

export default api;
