import React, { useCallback } from 'react';
import { useIsCountryCodeVerifiedUpdater } from '../providers';

export const useManageCountryCodeVerified = () => {
  const updateIsCountryCodeVerified = useIsCountryCodeVerifiedUpdater();

  const manageCountryCodeVerified = useCallback(
    (isCountryCodeVerified: boolean | null) => {
      updateIsCountryCodeVerified(isCountryCodeVerified ?? null);
    },
    [updateIsCountryCodeVerified]
  );

  return { manageCountryCodeVerified };
};
