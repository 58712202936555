import { upperFirst } from 'lodash';
import { JobTitlesModel, RolesModel } from '../../redux/actions/users/user-roles.action';

const MobileAccessId = 12;

export type FormattedOptions = {
  ID: number;
  value: string;
  label: string;
  Type: string;
  Name: string;
};

export const highlightInvitedUserRow = (state, rowInfo) => {
  if (rowInfo) {
    return {
      style: {
        background: rowInfo.row.Status === 'Invited' ? 'rgba(0,106,133,.1)' : '',
      },
    };
  }
  return {};
};

export const rolesDisplay = (roles: RolesModel[]) => {
  if (roles.length > 1) return 'Multiple';
  return roles[0]?.Name;
};

export const transformedRoles = (roles): RolesModel[] => {
  if (!roles) {
    return [];
  }

  return roles.map((value) => {
    return {
      value: value.Name,
      label: upperFirst(value.Name),
      Type: value.Type,
      ID: value.ID,
      Name: value.Name,
    };
  });
};

export const formatJobTitles = (availableOptions) => {
  return availableOptions.map((value) => {
    return {
      value: value.Name,
      label: upperFirst(value.Name),
      ID: value.TypeID,
      Type: value.Type,
      Name: value.Name,
    };
  });
};

// Transform the available roles to what the multi-selct plugin accepts
export const formatAvailableOptions = (availableOptions) => {
  // The mobileAccessId 12 is "restrict mobile access" meaning:
  // if they have the role they won't be able to access mobile access.
  // if they don't have the role they CAN access the Weave mobile app.

  if (!availableOptions.length) {
    return [];
  }

  return availableOptions
    .filter((value) => ![value.ID, value.TypeID].includes(MobileAccessId))
    .map((value) => {
      return {
        value: value.Name,
        label: upperFirst(value.Name),
        ID: value.ID || value.TypeID,
        Type: value.Type,
        Name: value.Name,
      };
    });
};

export const hasMobileAccess = (roles: RolesModel[] = []) => {
  return !roles.some((val) => val.ID === MobileAccessId);
};

export const removeDenyMobileAccessRole = (roles: RolesModel[]): RolesModel[] => {
  /* give mobile access by removing the 'Deny Mobile Access' job role */
  return roles.filter((val) => val.ID !== MobileAccessId);
};

export const addDenyMobileAccessRole = (roles: RolesModel[]): RolesModel[] => {
  /* remove mobile access by adding the 'Deny Mobile Access' job role */
  return [...roles, { ID: MobileAccessId, Name: 'Deny Mobile Access', Type: 'practice' }];
};

/* Have to Convert the string version of options back to formatted version */
export const handleFormatSelectedOptions = (
  selectedOptions: string[],
  originalOptions: RolesModel[] | JobTitlesModel[]
): FormattedOptions[] => {
  return formatAvailableOptions(originalOptions).filter((option) =>
    selectedOptions.includes(option.Name)
  );
};
