import React, { useState } from 'react';
import { SpinningLoader, PrimaryButton, Text, TextLink } from '@weave/design-system';
import { useQuery } from 'react-query';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { IntakeFormCardComponent } from './intake-form-card/intake-form-card.component';
import { useSelector } from 'react-redux';
import {
  selectCurrentLocationId,
  selectCurrentLocationParentId,
} from '../../../redux/actions/location';
import { onboardingIntakeFormApi } from '../onboarding.api';
import { useAlert } from '@weave/alert-system';
import { useHistory } from 'react-router-dom';
import { OnboardingLocationStatus } from './api';

export const IntakeFormComponent = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const parentLocationId = useSelector(selectCurrentLocationParentId);
  const [refetchNotifier, setRefetchNotifier] = useState(false);
  const alertRefresh = useAlert();
  const history = useHistory();

  const provisionData = useQuery(
    [locationId, 'provisionerData'],
    async () => {
      return onboardingIntakeFormApi.getProvisionerStatus(locationId);
    },
    {
      select: (data: OnboardingLocationStatus) => data.onboardingLocationStatus,
      retry: false,
      onError: (err) => {
        console.error('PROVISION STATUS:', err);
      },
    }
  );

  const getLocations = useQuery(
    [locationId, 'locations'],
    async () => {
      return onboardingIntakeFormApi.getLocations(locationId);
    },
    {
      select: (data) => data,
      retry: false,
      onError: (err) => console.error('LOCATIONS CHECK:', err),
    }
  );

  const handleRedirectToParentAccount = () => {
    history.push(`/location/${parentLocationId}/onboarding`);
  };

  const handleRefreshData = () => {
    provisionData.refetch();
    setRefetchNotifier(!refetchNotifier);
    alertRefresh.success('Refreshing data');
  };

  const isParentOrSingleLocation = getLocations?.data?.ParentID === null;

  if (!provisionData.data?.length) {
    return <Text css={errorStateMessage}>There are no locations to provision</Text>;
  }

  if (provisionData.isLoading || getLocations.isLoading) {
    return <SpinningLoader css={loaderStyles} />;
  }

  if (getLocations.isError || provisionData.isError) {
    return (
      <Text css={errorStateMessage}>
        There was an error retrieving this locations data
      </Text>
    );
  }

  if (!isParentOrSingleLocation) {
    return (
      <Text css={bannerStyle}>
        You need to go to the parent account page in order to send the intake form and
        view data.{' '}
        <TextLink css={textLinkStyles} onClick={handleRedirectToParentAccount}>
          Click here
        </TextLink>{' '}
        to go to the parent account.
      </Text>
    );
  }

  if (provisionData.isSuccess) {
    return (
      <>
        <div css={mainContainer}>
          <div css={titleContainer}>
            <Text css={title} weight="bold">
              Onboarding Intake Form
            </Text>
            <PrimaryButton
              size="large"
              css={refreshButton}
              onClick={handleRefreshData}
              data-testid="refresh-intake-data"
            >
              Refresh
            </PrimaryButton>
          </div>
          {provisionData.data.map((locationData, index) => (
            <IntakeFormCardComponent
              key={index}
              provisionData={provisionData.data}
              locationData={locationData}
              locationId={locationId}
              refreshData={handleRefreshData}
              refetchEmailData={refetchNotifier}
            />
          ))}
        </div>
      </>
    );
  }
  return <SpinningLoader css={loaderStyles} />;
};

export const mainContainer = css`
  padding: ${theme.spacing(4)} ${theme.spacing(3)};
`;

export const titleContainer = css`
  display: flex;
  justify-content: space-between;
  border-bottom: solid #d8d8d8;
  padding-bottom: 12px;
  margin-bottom: ${theme.spacing(5)};
`;

export const refreshButton = css`
  max-width: 119px;
`;

export const title = css`
  font-size: ${theme.fontSize(24)};
`;

export const bannerStyle = css`
  background: #fff1d6;
  padding: ${theme.spacing(2)};
  border-radius: 10px;
  font-size: ${theme.fontSize(16)};
  max-width: 90%;
`;

export const textLinkStyles = css`
  color: #146ef5;
`;

export const loaderStyles = css`
  display: flex;
  margin: 0 auto;
`;

export const errorStateMessage = css`
  text-align: center;
`;
