import {
  Rule,
  AutoMessageType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/auto-rules/v1/models.pb.js';

import { SmsModel } from '../../../../../../../../models/sms-history.model';
import { VerticalEnum } from '../../../../../../../../models/vertical-enum';
import { NotificationsSettings } from '../../../../../../../../redux/actions/notifications-settings/notification-settings.types';
import { DependencyDataKeys, Result } from '../handoff-metrics.types';
import { HandoffTypeMetric } from '../handoff.constants';
import { isCustomizationFeatureEnabled } from '../handoff-metrics-helpers';

export const INBOUND_SMS_PASS_VALUE = 1;
export const OUTBOUND_SMS_PASS_VALUE = 1;
export const USERS_PASS_VALUE = 2;
export const RECALL_REMINDERS_PASS_VALUE = 1;
export const APPOINTMENT_REMINDERS_PASS_VALUE = 2;
export const EYEWEAR_REMINDERS_PASS_VALUE = 1;

export const getSmsCounts = (smsData: SmsModel[]) => {
  let [inbound, outbound, total] = [0, 0, 0];
  if (!smsData) return { inbound, outbound, total };

  for (const msg of smsData) {
    if (!msg.autogeneratedBy) {
      total++;
      if (msg.direction === 'DIRECTION_OUTBOUND') {
        outbound++;
      } else if (msg.direction === 'DIRECTION_INBOUND') {
        inbound++;
      }
    }
  }

  return { inbound, outbound, total };
};

/**
 * Returns the count of enabled auto messaging rules of specific types.
 *
 * @param autoMessagingRules
 * @param autoMessageType
 * @returns
 */
const getEnabledAutoMessagingRulesCount = (
  autoMessagingRules: Rule[],
  autoMessageTypes: AutoMessageType_Slug[]
) => {
  if (!autoMessagingRules.length) return 0;

  let count = autoMessagingRules.reduce((acc, messagingRule) => {
    const autoMessageType = messagingRule.autoMessageType?.slug;
    if (!autoMessageType) return acc;

    if (autoMessageTypes.includes(autoMessageType) && messagingRule.enabled) return ++acc;
    return acc;
  }, 0);

  return count;
};

///////////////////////////////////// METRICS ////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
// INBOUND MESSAGES METRIC
export const inboundMessagesMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have received at least ${this.expectedValue} inbound message(s) in the last week`;
  },
  dependencyDataKeys: [DependencyDataKeys.Sms],
  expectedValue: INBOUND_SMS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const smsData = dependentData[DependencyDataKeys.Sms];
    const err = smsData?.error;
    if (err) return err.message;

    const sms = smsData?.data;
    if (!sms) return 'no data';

    const smsCounts = getSmsCounts(sms);

    return smsCounts.inbound;
  },
  resultFn: function (dependentData) {
    const smsData = dependentData[DependencyDataKeys.Sms];
    const err = smsData?.error;
    if (err || !smsData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
};

// OUTBOUND MESSAGES METRIC
export const outboundMessagesMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have sent at least ${this.expectedValue} outbound message(s) in the last week`;
  },
  dependencyDataKeys: [DependencyDataKeys.Sms],
  expectedValue: OUTBOUND_SMS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const smsData = dependentData[DependencyDataKeys.Sms];
    const err = smsData?.error;
    if (err) return err.message;

    const sms = smsData?.data;
    if (!sms) return 'no data';

    const smsCounts = getSmsCounts(sms);

    return smsCounts.outbound;
  },
  resultFn: function (dependentData) {
    const smsData = dependentData[DependencyDataKeys.Sms];
    const err = smsData?.error;
    if (err || !smsData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
};

// USERS ADDED METRIC
export const usersAddedMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have at least ${this.expectedValue} users`;
  },
  dependencyDataKeys: [DependencyDataKeys.Users],
  expectedValue: USERS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const usersData = dependentData[DependencyDataKeys.Users];
    const err = usersData?.error;
    if (err) return err.message;

    const users = usersData?.data;
    if (!users) return 'no data';

    return users.length;
  },
  resultFn: function (dependentData) {
    const usersData = dependentData[DependencyDataKeys.Users];
    const err = usersData?.error;
    if (err || !usersData?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    'Office only wants 1 user in Weave',
    'Office plans to add more users but has not yet',
  ],
};

// RECALL REMINDER METRIC
export const recallReminderMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have at least ${this.expectedValue} recall reminder(s) turned on`;
  },
  dependencyDataKeys: [DependencyDataKeys.MessagingRules],
  expectedValue: RECALL_REMINDERS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const messagingRulesQuery = dependentData[DependencyDataKeys.MessagingRules];
    const err = messagingRulesQuery?.error;
    if (err) return err.message;

    const autoMessagingRules = messagingRulesQuery?.data;
    if (!autoMessagingRules) return 'no data';

    return getEnabledAutoMessagingRulesCount(autoMessagingRules, [
      AutoMessageType_Slug.RECALL_REMINDER,
      AutoMessageType_Slug.RECALL_REMINDER_PET,
    ]);
  },
  resultFn: function (dependentData) {
    const messagingRulesQuery = dependentData[DependencyDataKeys.MessagingRules];
    const err = messagingRulesQuery?.error;
    if (err || !messagingRulesQuery?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  shouldSkip: ({ customizationFeatures }) => {
    return !isCustomizationFeatureEnabled(
      'Recall Reminders - automated',
      customizationFeatures
    );
  },
  exceptions: [
    'PM does not integrate with recall',
    'Office has not turned reminders on',
    'Still migrating from the old reminder system',
    'Office still fine-tuning reminders',
  ],
};

// APPOINTMENT REMINDERS METRIC
export const appointmentRemindersMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have at least ${this.expectedValue} appointment reminder(s) turned on`;
  },
  dependencyDataKeys: [DependencyDataKeys.MessagingRules],
  expectedValue: APPOINTMENT_REMINDERS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const messagingRulesQuery = dependentData[DependencyDataKeys.MessagingRules];
    const err = messagingRulesQuery?.error;
    if (err) return err.message;

    const autoMessagingRules = messagingRulesQuery?.data;
    if (!autoMessagingRules) return 'no data';

    return getEnabledAutoMessagingRulesCount(autoMessagingRules, [
      AutoMessageType_Slug.APPOINTMENT_REMINDER,
      AutoMessageType_Slug.APPOINTMENT_REMINDER_PET,
    ]);
  },
  resultFn: function (dependentData) {
    const messagingRulesQuery = dependentData[DependencyDataKeys.MessagingRules];
    const err = messagingRulesQuery?.error;
    if (err || !messagingRulesQuery?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    'Using another service/in contract',
    'Want to wait',
    'Office just turned on reminders',
    'Office is waiting to turn on',
  ],
};

// EYEWEAR REMINDER METRIC
export const eyewearReminderMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Must have at least ${this.expectedValue} eyewear reminder(s) turned on`;
  },
  dependencyDataKeys: [DependencyDataKeys.MessagingRules],
  expectedValue: EYEWEAR_REMINDERS_PASS_VALUE,
  actualValueFn: (dependentData) => {
    const messagingRulesQuery = dependentData[DependencyDataKeys.MessagingRules];
    const err = messagingRulesQuery?.error;
    if (err) return err.message;

    const autoMessagingRules = messagingRulesQuery?.data;
    if (!autoMessagingRules) return 'no data';

    return getEnabledAutoMessagingRulesCount(autoMessagingRules, [
      AutoMessageType_Slug.ORDER_READY,
    ]);
  },
  resultFn: function (dependentData) {
    const messagingRulesQuery = dependentData[DependencyDataKeys.MessagingRules];
    const err = messagingRulesQuery?.error;
    if (err || !messagingRulesQuery?.data) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  // This metric should be skipped if the current location is not an
  // optometry vertical location.
  shouldSkip: ({ currentLocation }) => {
    const isOptometry = currentLocation.VerticalID === VerticalEnum.Optometry;
    return !isOptometry;
  },
  exceptions: ['Office currently using other software'],
};
