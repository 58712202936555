import { TextLink, Text, Heading } from '@weave/design-system';
import React from 'react';
import { GetInvoiceDetailOrPdfResponse } from '../../billing.types';
import {
  dateContainer,
  header,
  headerContent,
  logo,
  textColor,
} from './invoice-details-styles';
import { ReactComponent as WeaveLogo } from '../../../../../images/weave-logo.svg';
import { formatSubscriberInvoiceDateForPrint } from '../../billing.helpers';

type Props = {
  invoiceDetails?: GetInvoiceDetailOrPdfResponse['invoice'];
};

export const InvoiceDetailsTemplateHeader = ({ invoiceDetails }: Props) => {
  return (
    <div css={header}>
      <WeaveLogo css={logo} />
      <div css={headerContent}>
        <Heading as="span" css={textColor}>
          Need Training?
        </Heading>
        <Text css={textColor}>
          Become a Weave Expert <br />
          at{' '}
          <TextLink to="www.weavehelp.com" weight="bold" css={textColor}>
            www.weavehelp.com
          </TextLink>
        </Text>
      </div>
      <div css={dateContainer}>
        <Text as="span" css={textColor}>
          Invoice:
          <br />
          {invoiceDetails?.invoice?.id}
        </Text>
        {invoiceDetails?.invoice?.createTime && (
          <Text css={textColor}>
            Date: <br />
            {formatSubscriberInvoiceDateForPrint(invoiceDetails?.invoice?.createTime)}
          </Text>
        )}
      </div>
    </div>
  );
};
