import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { Modal, Info, Heading } from '@weave/design-system';
import {
  CallSettingsSaveModel,
  ForwardingNumber,
  ForwardingStatus,
  SMSNumber,
} from '../../models';
import { ToggleSwitch } from '../../../../shared/toggle-switch/toggle-switch.component';

interface Props {
  activeSmsNumber: SMSNumber;
  callRecordingStatus: boolean;
  forwardingNumbers: ForwardingNumber[];
  forwardingStatus: ForwardingStatus;
  locationId: string;
  modalOpen: boolean;
  smsNumberList: SMSNumber[];

  addForwardingNumber: (ForwardingNumber) => void;
  updateForwardingNumber: (ForwardingNumber) => void;
  deleteForwardingNumber: (ForwardingNumber) => void;
  handleCloseModal: () => void;
  updateCallSettings: (settings: CallSettingsSaveModel) => void;
}

interface State {
  activeSmsNumber: SMSNumber;
  callRecordingStatus: boolean;
  forwardingStatus: ForwardingStatus;
  modalOpen?: boolean;
  smsNumberList: SMSNumber[];
}

export const CallSettingsEdit = (props: Props) => {
  const { activeSmsNumber, callRecordingStatus, forwardingStatus, smsNumberList } = props;

  const [state, setState] = React.useState<State>({
    activeSmsNumber,
    callRecordingStatus,
    forwardingStatus,
    smsNumberList,
  });

  React.useEffect(() => {
    setState({
      ...state,
      activeSmsNumber,
      callRecordingStatus,
      smsNumberList,
    });
  }, [activeSmsNumber, callRecordingStatus, forwardingStatus, smsNumberList]);

  const handleSmsChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const target = event.target as HTMLSelectElement;
    const value: SMSNumber =
      state.smsNumberList.find(({ ID }) => ID === target.value) || new SMSNumber();
    setState({
      ...state,
      activeSmsNumber: value,
    });
  };

  const saveAll = () => {
    const objectToSave: CallSettingsSaveModel = {};

    if (state.callRecordingStatus !== props.callRecordingStatus) {
      objectToSave.callRecordingStatus = state.callRecordingStatus;
    }

    if (state.forwardingStatus !== props.forwardingStatus) {
      objectToSave.forwardingStatus = {
        id: state.forwardingStatus.id || props.forwardingStatus.id,
        enabled: state.forwardingStatus.enabled,
      };
    }

    if (state.activeSmsNumber !== props.activeSmsNumber) {
      objectToSave.smsNumber = {
        ...state.activeSmsNumber,
        Active: true,
      };
    }
    props.updateCallSettings(objectToSave);
    props.handleCloseModal();
  };

  const toggleCallForwarding = () => {
    setState({
      ...state,
      forwardingStatus: {
        ...state.forwardingStatus,
        enabled: !state.forwardingStatus.enabled,
      },
    });
  };

  const selectForwardingNumber = (numberId: string) => {
    const forwardingNumber = props.forwardingNumbers.find(({ id }) => id === numberId);
    setState({
      ...state,
      forwardingStatus: {
        ...state.forwardingStatus,
        ...forwardingNumber,
      },
    });
  };

  const toggleCallRecording = () => {
    setState({
      ...state,
      callRecordingStatus: !state.callRecordingStatus,
    });
  };

  return (
    <Modal maxWidth={1000} show={props.modalOpen} onClose={props.handleCloseModal}>
      <Modal.Header>Edit Call Settings</Modal.Header>
      <Modal.Body>
        <div
          className="edit-call-settings"
          css={css`
            .settings-container {
              border: 1px solid #e4e5e5;
              border-radius: 4px;
              margin-bottom: 10px;
              padding: 10px;
            }

            .settings-title {
              display: flex;

              button {
                margin-left: ${theme.spacing(1)};
                opacity: 0.4;
                transition: 0.15s opacity;

                &:hover {
                  opacity: 0.8;
                  transition: 0.15s opacity;
                }
              }
            }

            .settings-subtitle {
              display: block;
              opacity: 0.85;
            }

            .forwarding-numbers-table input[type='radio'] {
              height: 15px;
              margin: auto;
              width: 15px;
            }

            .sms-select {
              background-color: #fff;
              background-size: 8px 10px;
              border: 1px solid rgba(0, 0, 0, 0.15);
              border-radius: 0.25rem;
              color: #464a4c;
              display: inline-block;
              height: 30px;
              line-height: 30px;
              margin: 5px 0;
              max-width: 100%;
              min-width: 60%;
              vertical-align: middle;
            }
          `}
        >
          <div className="settings-container">
            <Heading level={2} className="settings-title">
              Texting Number
              <Info className="settings-subtitle">
                This number is used for inbound and outbound text messages.
              </Info>
            </Heading>
            <select
              className="sms-select"
              value={state.activeSmsNumber.ID}
              onChange={handleSmsChange}
            >
              {state.smsNumberList.map((forwardingNumber: SMSNumber) => {
                return (
                  <option key={forwardingNumber.ID} value={forwardingNumber.ID}>
                    {SMSNumber.formattedDisplay(forwardingNumber)}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="settings-container">
            <Heading level={2} className="settings-title">
              Call Recording
              <Info className="settings-subtitle">
                To activate Call Recording, toggle the switch on, and press 'Save'.
              </Info>
            </Heading>
            <ToggleSwitch
              label="Call Recording"
              on={state.callRecordingStatus}
              onChange={toggleCallRecording}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions
        onSecondaryClick={props.handleCloseModal}
        secondaryLabel="Cancel"
        onPrimaryClick={saveAll}
        primaryLabel="Save"
      />
    </Modal>
  );
};
