import { css } from '@emotion/core';
import { ButtonBar, PrimaryButton, SecondaryButton } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import React from 'react';
import { useCustomizationFlagV2Store } from '../providers/customization-flag-editable-provider';

export const SaveCustomizationButtons = () => {
  const { saveCustomizationFlags: onSave, cancelSavingCustomizationFlags: onCancel } =
    useCustomizationFlagV2Store();
  return (
    <ButtonBar style={{ padding: 0 }}>
      <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
      <PrimaryButton style={{ width: '100%' }} onClick={onSave}>
        Save Changes
      </PrimaryButton>
    </ButtonBar>
  );
};
