import { CustomAxios, getResponseData } from 'redux/axios';
import { BnplResponse, VerticalResponse } from './bnpl.types';

export const bnplApi = {
  baseUrl: '/sunbit',
  async merchantStatus(paymentsURL?: string) {
    const response = await CustomAxios.get(`${paymentsURL}${this.baseUrl}/merchant`);
    return getResponseData<BnplResponse>(response);
  },
  async onboardMerchant(payload: any, paymentsURL?: string) {
    const response = await CustomAxios.post(
      `${paymentsURL}${this.baseUrl}/merchant`,
      payload
    );
    return getResponseData<BnplResponse>(response);
  },
  async getVerticals(paymentsURL?: string) {
    const response = await CustomAxios.get(`${paymentsURL}${this.baseUrl}/vertical`);
    return getResponseData<VerticalResponse>(response).vertical;
  },
};
