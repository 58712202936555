import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { theme } from '@weave/theme-original';
import { getVerticalList } from 'models/vertical-enum';
import {
  CustomizationFlagStatesV2,
  LocationFeatureV2,
} from 'models/location-feature.model';
import { CenteredSpinningLoader } from 'components/shared';
import { selectFeatures } from 'redux/actions/customization/customization.action';
import { useCustomizationFlagV2Store } from '../providers/customization-flag-editable-provider';
import {
  Heading,
  IconButton,
  Modal,
  OptionSwitchField,
  Text,
  XIcon,
  useForm,
} from '@weave/design-system';
import {
  BundlePackagesEnum,
  CustomizationFlag,
  IntegrationPackagesEnum,
  CustomizationFlagsResponse,
} from '../customization-page-v2.types';

import {
  getBundlePackageName,
  getCustomizationFlagByBundlesRequest,
  getCustomizationFlagStateColor,
  getIntegrationPackageName,
  getProductPackageBundleName,
} from '../customization-page.utils';
import {
  useManageCountryCodeVerified,
  useManageProhibitedCustomizationFlags,
} from '../hooks';
import {
  useCustomizationBundleFlagUpdater,
  useTableFilterValueUpdater,
} from '../providers';
import { CustomizationFlagSoftwareOnlyOption } from './customization-flag-software-only-option';

export const CustomizationFlagEditModal = () => {
  const [isSoftwareOnlyBundle, setIsSoftwareOnlyBundle] = useState<boolean>(false);
  const updateTableFilterValue = useTableFilterValueUpdater();
  const customizationFlags = useSelector(selectFeatures);
  const updateBundleCustomizationFlags = useCustomizationBundleFlagUpdater();
  const { manageProhibitedCustomizationFlags } = useManageProhibitedCustomizationFlags();
  const { manageCountryCodeVerified } = useManageCountryCodeVerified();
  const verticalList = getVerticalList();

  const {
    verticalId,
    customizationFlagEditModalProps,
    getCustomizationsFlagsByBundles,
    setTableData,
    toggleCustomizationFlag,
    isGetCustomizationsFlagsByBundlesLoading,
  } = useCustomizationFlagV2Store();

  const { modalProps } = customizationFlagEditModalProps;

  const { formProps, getFieldProps, seedValues, values, isComplete } = useForm({
    fields: {
      integrationPackage: { type: 'optionswitch', required: true, value: '' },
      bundlePackage: { type: 'optionswitch', required: true, value: '' },
      vertical: {
        type: 'dropdown',
        value: verticalId?.toString() ?? '',
      },
    },
  });

  const verticalName = verticalList.find(
    (verticalDetails) => verticalDetails.key === verticalId
  )?.value;

  const getPackageBundle = () => {
    const integrationPackage = getIntegrationPackageName(
      (values.integrationPackage ?? '') as IntegrationPackagesEnum
    );
    const bundlePackage = getBundlePackageName(
      (values.bundlePackage ?? '') as BundlePackagesEnum
    );
    if (integrationPackage && bundlePackage) {
      return getProductPackageBundleName(
        integrationPackage,
        bundlePackage,
        isSoftwareOnlyBundle
      );
    }

    return '';
  };

  const updateCustomizationFlagDetails = (
    customizationFlag: LocationFeatureV2,
    flagState?: CustomizationFlagStatesV2
  ) => {
    const state = flagState ?? CustomizationFlagStatesV2.ACTIVE;
    const stateColor = getCustomizationFlagStateColor(state);
    toggleCustomizationFlag(customizationFlag, {
      key: state,
      label: state.toUpperCase(),
      color: stateColor,
      isFreeTrial: false,
    });
  };

  const getUpdatedCustomizationFlagStateToHide = (
    customizationFlag: LocationFeatureV2
  ): LocationFeatureV2 => {
    toggleCustomizationFlag(customizationFlag, {
      key: CustomizationFlagStatesV2.HIDE,
      label: 'Hide',
      color: 'error',
    });
    return {
      ...customizationFlag,
      state: CustomizationFlagStatesV2.HIDE,
    };
  };

  const getUpdatedCustomizationFlagStateToActive = (
    customizationFlag: LocationFeatureV2,
    newState: CustomizationFlagStatesV2
  ): LocationFeatureV2 => {
    updateCustomizationFlagDetails(customizationFlag, newState);
    const updatedState = newState ?? CustomizationFlagStatesV2.ACTIVE;

    return {
      ...customizationFlag,
      state: updatedState,
    };
  };

  const manageCustomizationFlagsMapping = (
    customizationFlagsByBundles: CustomizationFlag[]
  ) => {
    const tableData: LocationFeatureV2[] = customizationFlags.map((customizationFlag) => {
      const customizationFlagByBundle = customizationFlagsByBundles?.find(
        (flag) => flag.id === customizationFlag.id
      );

      if (customizationFlagByBundle?.state === CustomizationFlagStatesV2.ACTIVE) {
        return getUpdatedCustomizationFlagStateToActive(
          customizationFlag,
          CustomizationFlagStatesV2.ACTIVE
        );
      }
      return getUpdatedCustomizationFlagStateToHide(customizationFlag);
    });
    setTableData(tableData);
    modalProps.onClose();
  };

  const resetFilter = () => {
    updateTableFilterValue('');
    updateBundleCustomizationFlags([]);
  };

  const handleApplyChanges = () => {
    const packageBundle = getPackageBundle();
    if (packageBundle) {
      const request = getCustomizationFlagByBundlesRequest(packageBundle, verticalId);

      getCustomizationsFlagsByBundles(request).then(
        (customizationFlagByBundlesResponse: CustomizationFlagsResponse) => {
          if (customizationFlagByBundlesResponse) {
            resetFilter();
            const { activeFlags, prohibitedFlags, countryCodeVerified } =
              customizationFlagByBundlesResponse;
            manageCustomizationFlagsMapping(activeFlags ?? []);
            manageProhibitedCustomizationFlags(prohibitedFlags ?? []);
            manageCountryCodeVerified(!!countryCodeVerified);
          }
        }
      );
    }
  };

  const handleSoftwareOnlyOptionSelection = () =>
    setIsSoftwareOnlyBundle((isSoftwareOnly) => !isSoftwareOnly);

  useEffect(() => {
    seedValues({
      ...values,
      integrationPackage: '',
      bundlePackage: '',
    });
  }, []);

  return (
    <Modal {...modalProps} maxWidth={500}>
      <div css={modalHeaderStyles}>
        <Heading level={2} css={textBottomSpacing(0)}>
          Edit and Update Customizations
        </Heading>
        <IconButton onClick={modalProps.onClose} label="Close Modal">
          <XIcon />
        </IconButton>
      </div>
      <Modal.Body>
        {isGetCustomizationsFlagsByBundlesLoading ? (
          <CenteredSpinningLoader />
        ) : (
          <form {...formProps}>
            <Text size="small" css={subtitleStyles}>
              Select the bundles below and click Review Changes.
            </Text>
            <Heading level={3} css={textBottomSpacing(3)}>
              Weave Packages
            </Heading>
            <Text weight="bold" css={textBottomSpacing(0.5)}>
              Select Integration
            </Text>
            <Text size="small" color="light" css={textBottomSpacing(1)}>
              Choose one
            </Text>
            <OptionSwitchField
              {...getFieldProps('integrationPackage')}
              css={switchOptionContainerStyles}
            >
              <OptionSwitchField.Option value={IntegrationPackagesEnum.CORE}>
                Core
              </OptionSwitchField.Option>
              <OptionSwitchField.Option value={IntegrationPackagesEnum.PLUS}>
                Plus
              </OptionSwitchField.Option>
            </OptionSwitchField>
            <Text weight="bold" css={textBottomSpacing(0.5)}>
              Select Bundles
            </Text>
            <Text size="small" color="light" css={textBottomSpacing(1)}>
              Choose one of the bundles below
            </Text>
            <OptionSwitchField
              {...getFieldProps('bundlePackage')}
              css={switchOptionContainerStyles}
            >
              <OptionSwitchField.Option value={BundlePackagesEnum.ULTIMATE}>
                Ultimate
              </OptionSwitchField.Option>
              <OptionSwitchField.Option value={BundlePackagesEnum.ELITE}>
                Elite
              </OptionSwitchField.Option>
              <OptionSwitchField.Option value={BundlePackagesEnum.PRO}>
                Pro
              </OptionSwitchField.Option>
              <OptionSwitchField.Option value={BundlePackagesEnum.ESSENTIALS}>
                Essentials
              </OptionSwitchField.Option>
            </OptionSwitchField>
            <div
              css={css`
                margin-bottom: ${theme.spacing(2.5)};
              `}
            >
              <Text size="medium" weight="bold" css={textBottomSpacing(1.5)}>
                Optional
              </Text>
              <CustomizationFlagSoftwareOnlyOption
                onClick={handleSoftwareOnlyOptionSelection}
                isSelected={isSoftwareOnlyBundle}
              />
            </div>

            <Text weight="bold" css={textBottomSpacing(1)}>
              Vertical
            </Text>
            <Text>{verticalName}</Text>
          </form>
        )}
      </Modal.Body>
      <Modal.Actions
        primaryLabel="Review Changes"
        secondaryLabel="Cancel"
        onPrimaryClick={handleApplyChanges}
        onSecondaryClick={modalProps.onClose}
        disablePrimary={!isComplete || isGetCustomizationsFlagsByBundlesLoading}
        disableSecondary={isGetCustomizationsFlagsByBundlesLoading}
      ></Modal.Actions>
    </Modal>
  );
};

const subtitleStyles = css`
  margin-bottom: ${theme.spacing(3)};
`;

const textBottomSpacing = (spacing: number) => css`
  margin: 0;
  margin-bottom: ${theme.spacing(spacing)};
`;

const switchOptionContainerStyles = css`
  margin-bottom: ${theme.spacing(2.5)};
`;

const modalHeaderStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(0, 3, 0, 3)};
`;
