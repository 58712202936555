import React from 'react';
import { MarkIcon, Text } from '@weave/design-system';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { ExceptionDropdownItem } from './exception-dropdown-item';
import { MetricResult } from '../../handoff-metrics/handoff-metrics.types';
import { SelectedExceptionsMap } from './exceptions-modal';
import { getConcatenatedMetricName } from '../../handoff-metrics/handoff-metrics-helpers';

type Props = {
  failedMetrics: { metricResult: MetricResult; exceptions: string[] }[];
  failedSubsectionName: string;
  onExceptionSelected: (concatenatedName: string, exception: string) => void;
};

export const ExceptionItem = ({
  failedMetrics,
  failedSubsectionName,
  onExceptionSelected,
}: Props) => {
  return (
    <div>
      <div css={modalExceptionHeader}>
        <MarkIcon css={iconStyles} />
        <Text css={subsectionNameStyle}>{failedSubsectionName}</Text>
      </div>
      <div css={modalExceptionsStyle}>
        {failedMetrics.map((failedMetric, index) => {
          const concatenatedName = getConcatenatedMetricName(failedMetric.metricResult);

          return (
            <div css={criteriaList} key={index}>
              <Text>{failedMetric.metricResult.testCriteria}</Text>
              <ExceptionDropdownItem
                exceptions={failedMetric.exceptions}
                onSelectedException={(value) =>
                  onExceptionSelected(concatenatedName, value)
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const modalExceptionHeader = css`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(2)};

  svg {
    margin-right: ${theme.spacing(2)};
  }
`;

const modalExceptionsStyle = css`
  display: flex;
  flex-direction: column;
  padding-left: ${theme.spacing(5)};
`;

const subsectionNameStyle = css`
  font-weight: 600;
  margin-bottom: 0;
`;

const criteriaList = css`
  display: flex;
  justify-content: space-between;
`;

const iconStyles = css`
  color: ${theme.colors.gray400};
  height: 16px;
`;
