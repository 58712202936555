import { createContext, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { DebugModeRibbon } from './debug-mode-ribbon';

const ENABLE_DEBUG_MODE_HOT_KEY = 'ctrl+shift+d';

export const DebugModeContext = createContext({ isDebugModeEnabled: false });

export const DebugModeProvider = ({ children }: { children: React.ReactNode }) => {
  const [isDebugModeEnabled, setIsDebugModeEnabled] = useState(false);

  useHotkeys(ENABLE_DEBUG_MODE_HOT_KEY, () => {
    setIsDebugModeEnabled((prevIsDebugModeEnabled) => !prevIsDebugModeEnabled);
  });

  const handleTurnOffClick = () => {
    setIsDebugModeEnabled(false);
  };

  return (
    <DebugModeContext.Provider value={{ isDebugModeEnabled }}>
      {isDebugModeEnabled && <DebugModeRibbon onTurnOffClick={handleTurnOffClick} />}

      {children}
    </DebugModeContext.Provider>
  );
};
