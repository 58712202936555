import React from 'react';
import { Field, Form, FormikProps } from 'formik';
import { css } from 'emotion';
import { getVerticalList } from '../../../models/vertical-enum';
import { InfoTooltip } from '../../shared/info-tooltip/info-tooltip.component';
import { Loader } from '../../shared/loader/loader.component';
import {
  LocationCreationResponseModel,
  ProvisionLocationResponseDTO,
} from '../../../redux/actions/location';
import { LocationAlert } from './location-alert.component';
import {
  columnWidth,
  creationStatusButton,
  creationStatusContainer,
  creationStatusListItem,
  flex,
  formGroup,
  loader,
  locationForm,
  marginRight5,
  validationError,
} from './location-form.styles';
import './location-form.component.css';
import { ButtonBar, Modal, PrimaryButton, SecondaryButton } from '@weave/design-system';

export interface LocationFormProps {
  creationStatus: ProvisionLocationResponseDTO;
  // creationStatus: LocationCreationResponseModel;
  loading: boolean;
  onClose: () => void;
  resetLocationCreationStatus: () => void;
  setCurrentLocationId: (locationId: string) => void;
}

export const LocationDemoForm = (props: LocationFormProps & FormikProps<any>) => {
  const goToLocation = () => {
    const locationId = props.creationStatus.locationId;
    props.resetLocationCreationStatus();
    props.setCurrentLocationId(locationId);
  };

  if (props.loading) {
    return (
      <div className={loader}>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <div className="location-form">
        <Form className={flex}>
          <div className={columnWidth}>
            <div css={formGroup}>
              <div className={flex}>
                <label className={marginRight5} htmlFor="Name">
                  Location Name
                </label>
                <InfoTooltip
                  body={
                    <div>
                      This field will create: <div>WAM: Location Name</div>{' '}
                      <div>Phone Data: Tenant Name</div>
                    </div>
                  }
                  tooltipPlacement="right"
                />
              </div>
              <Field type="text" id="Name" name="Name" className="form-control" />
              {props.touched.Name && props.errors.Name && (
                <div css={validationError}>{props.errors.Name}</div>
              )}
            </div>

            <div css={formGroup}>
              <label htmlFor="Slug">Slug</label>
              <Field type="text" id="Slug" name="Slug" className="form-control" />
              {props.touched.Slug && props.errors.Slug && (
                <div css={validationError}>{props.errors.Slug}</div>
              )}
            </div>

            <div css={formGroup}>
              <label htmlFor="caller_number">Outbound Caller Number</label>
              <Field
                type="text"
                id="caller_number"
                name="caller_number"
                className="form-control"
              />
              {props.touched.caller_number && props.errors.caller_number && (
                <div css={validationError}>{props.errors.caller_number}</div>
              )}
            </div>

            <div css={formGroup}>
              <label htmlFor="Email">Office Email</label>
              <Field type="email" id="Email" name="Email" className="form-control" />
              {props.touched.Email && props.errors.Email && (
                <div css={validationError}>{props.errors.Email}</div>
              )}
            </div>

            <div css={formGroup}>
              <label htmlFor="VerticalID">Vertical</label>
              <Field
                component="select"
                id="VerticalID"
                name="VerticalID"
                className="form-control"
              >
                {getVerticalList().map((vertical) => (
                  <option value={vertical.key} key={vertical.key}>
                    {vertical.value}
                  </option>
                ))}
              </Field>
            </div>
            <div
              className={css`
                text-align: center;
              `}
            >
              <ButtonBar>
                <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                <PrimaryButton type="submit" disabled={props.loading}>
                  Create
                </PrimaryButton>
              </ButtonBar>
            </div>
          </div>
        </Form>
      </div>
      <Modal
        onClose={() => {
          goToLocation();
        }}
        show={props.loading || !!props.creationStatus}
      >
        <Modal.Header>Creation Status</Modal.Header>
        <Modal.Body>
          {props.loading && (
            <div className={loader}>
              <Loader />
            </div>
          )}
          {props.creationStatus && (
            <div className={creationStatusContainer}>
              <div className={creationStatusListItem}>
                {props.creationStatus.locationCreatedSuccessfully ? (
                  <LocationAlert color="success">
                    Location created successfully.
                  </LocationAlert>
                ) : (
                  <LocationAlert color="danger">Location was not created.</LocationAlert>
                )}
              </div>
              <div className={creationStatusListItem}>
                {props.creationStatus.phoneDataTenantCreatedSuccessfully ? (
                  <LocationAlert color="success">
                    Phone tenant created successfully.
                  </LocationAlert>
                ) : (
                  <LocationAlert color="danger">
                    Phone tenant was not created.
                  </LocationAlert>
                )}
              </div>
              <div className={creationStatusListItem}>
                {props.creationStatus.customizationFlagsSetSuccessfully ? (
                  <LocationAlert color="success">
                    Customization flags setup successfully.
                  </LocationAlert>
                ) : (
                  <LocationAlert color="danger">
                    Customization flags were not setup.
                  </LocationAlert>
                )}
              </div>

              {props?.creationStatus?.locationProvisionError?.length >= 1 ? (
                <div className={creationStatusListItem}>
                  <LocationAlert color="danger">Provisioning error.</LocationAlert>
                </div>
              ) : (
                <></>
              )}
              <PrimaryButton
                className={creationStatusButton}
                onClick={() => {
                  goToLocation();
                }}
              >
                Okay
              </PrimaryButton>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
