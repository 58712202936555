import { CustomAxios, getResponseData } from '../../redux/axios';
import { CompleteIntakeFormRequestData, ModulesTasksResponse } from './onboarding.types';
import { ProvisionerContact } from './intake-form-hub/api';
import { CSMKickoffCallMetrics } from './handoff-hub/handoff-hub.types';
import { mockPromise } from '../../tools/mock-promise';
import { mockCSMData } from './intake-form-hub/onboarding-mock';
import { GetLatestOnboardingProgressByLocationIDResponse } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-progress/onboarding_progress.pb';
import { OnboardingEvent } from '@weave/schema-gen-ts/dist/shared/insys/activation/onboarding.pb';

// new schema urls
const intakeFormBaseUrl = 'insys/intake-form/v1';
const onboardingTasksBaseUrl = 'insys/onboarding/v1/onboarding-tasks';
const onboardingProgressBaseUrl = 'insys/onboarding/v1/onboarding-progress';
const onboardingHistoryBaseUrl = `insys/onboarding/v1/onboarding_history`;

export const getOnboardingIntakeForm = async () =>
  CustomAxios.get(`${intakeFormBaseUrl}/intake-form`).then(getResponseData);

export const completeOnboardingIntakeForm = (data: CompleteIntakeFormRequestData) =>
  CustomAxios.patch(`${intakeFormBaseUrl}/complete-form`, data).then(getResponseData);

export const uploadPhoneBill = (data: FormData) =>
  CustomAxios.post(`${intakeFormBaseUrl}/phone-bill-images`, data).then(getResponseData);

export const getVerifiedAddress = (query: string) =>
  CustomAxios.get(`${intakeFormBaseUrl}/address/verify?${query}`).then(getResponseData);

export const getOperationsStatus = () =>
  CustomAxios.get(`${intakeFormBaseUrl}/operation-status`).then(getResponseData);

export const getProvisionerStatus = (locationId: string) =>
  CustomAxios.get(`location/v1/provision/status/${locationId}`).then(getResponseData);

export const getEmailStatusLogs = (userContactEmail) =>
  CustomAxios.get(`${intakeFormBaseUrl}/email-status-logs`, {
    params: { onboardingContactEmail: userContactEmail },
  }).then(getResponseData);

export const sendWelcomeCallEmail = (data: ProvisionerContact) =>
  CustomAxios.post(`${intakeFormBaseUrl}/onboard`, data).then(getResponseData);

export const getLocations = (locationID: string) =>
  CustomAxios.get(`/support/v1/locations/${locationID}`).then(getResponseData);

export const getCsmKickoffHandoff = (start_date: string, end_date: string) => {
  return CustomAxios.get(
    `/insys/onboarding/v1/handoff-hub/csm-kickoff-call-report?start_date=${start_date}&end_date=${end_date}`
  ).then<CSMKickoffCallMetrics>(getResponseData);
};

export const getModulesTasks = async () => {
  return CustomAxios.get(
    `${onboardingTasksBaseUrl}/modules-tasks`
  ).then<ModulesTasksResponse>(getResponseData);
};

export const getLatestOnboardingProgressStatusByLocationId = (locationId: string) => {
  return CustomAxios.get(
    `${onboardingProgressBaseUrl}/onboarding-progress/status?locationId=${locationId}`
  ).then<GetLatestOnboardingProgressByLocationIDResponse>(getResponseData);
};

interface PublishOnboardingEventRequest {
  slug: string;
  locationId: string;
  onboardingEvent: OnboardingEvent;
}

export const publishOnboardingEvent = async (
  req: PublishOnboardingEventRequest
): Promise<void> => {
  await CustomAxios.post(`${onboardingHistoryBaseUrl}/publish`, req).catch(console.error);
};

export const onboardingIntakeFormApi = {
  getOnboardingIntakeForm,
  completeOnboardingIntakeForm,
  getLocations,
  uploadPhoneBill,
  getVerifiedAddress,
  getOperationsStatus,
  getProvisionerStatus,
  getEmailStatusLogs,
  sendWelcomeCallEmail,
  getCsmKickoffHandoff,
};

export const onboardingTasksApi = {
  getModulesTasks,
};

export const onboardingProgressApi = {
  getLatestOnboardingProgressStatusByLocationId,
};

export const onboardingHistoryApi = {
  publishOnboardingEvent,
};
