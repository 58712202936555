import { CustomAxios } from 'redux/axios';
import { GetTrialHistoryRequest, GetTrialHistoryResponse } from './types';

export const fetchTrialHistory = (req: GetTrialHistoryRequest) => {
  return CustomAxios.get<GetTrialHistoryResponse>(
    `/platform-location-feature/v1/trial/${req.locationId}/history`,
    {
      headers: {
        'Location-Id': req.locationId,
      },
    }
  );
};
