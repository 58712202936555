import {
  GetLocationRegistrationStatusDetailsRequest,
  GetLocationRegistrationStatusDetailsResponse,
  GetLocationRegistrationStatusRequest,
  GetLocationRegistrationStatusResponse,
  GetLocationTCRBrandRequest,
  GetTCRBrandLockStatusRequest,
  RegisterPhoneNumberRequest,
  RegisterPhoneNumberResponse,
} from '@weave/schema-gen-ts/dist/schemas/tendlc/coordinator/v1/coordinator_service.pb';
import { LocationTCRBrand } from '@weave/schema-gen-ts/dist/schemas/tendlc/shared/v1/shared.pb';
import { GetTCRBrandLockStatusResponse } from 'components/location-sidebar-container/location-sidebar.types';
import { CustomAxios, getResponseData } from 'redux/axios';

export const getLocationTCRBrand = async (req: GetLocationTCRBrandRequest) => {
  const response = await CustomAxios.get<LocationTCRBrand>(
    `/tendlc/coordinator/v1/location-tcr-brand`,
    {
      params: req,
    }
  );

  return getResponseData<LocationTCRBrand>(response);
};

export const getTCRBrandLockStatus = async (req: GetTCRBrandLockStatusRequest) => {
  const response = await CustomAxios.get('tendlc/coordinator/v1/tcr-brand/lock-status', {
    params: req,
  });

  return getResponseData<GetTCRBrandLockStatusResponse>(response);
};

export type RegisterPhoneNumberArgs = Required<Omit<RegisterPhoneNumberRequest, 'brand'>>;

export const registerPhoneNumber = async (
  req: RegisterPhoneNumberArgs
): Promise<RegisterPhoneNumberResponse> => {
  const response = await CustomAxios.post<RegisterPhoneNumberResponse>(
    '/tendlc/coordinator/v1/tcr-phone-number',
    req
  );

  return getResponseData<RegisterPhoneNumberResponse>(response);
};

export const getLocationRegistrationStatusDetails = async (
  req: GetLocationRegistrationStatusDetailsRequest
) => {
  const response = await CustomAxios.get<GetLocationRegistrationStatusDetailsResponse>(
    `/tendlc/coordinator/v1/location-registration-status-details`,
    {
      params: req,
    }
  );

  return getResponseData<GetLocationRegistrationStatusDetailsResponse>(response);
};

export const getLocationRegistrationStatus = async (
  req: GetLocationRegistrationStatusRequest
) => {
  const response = await CustomAxios.get<GetLocationRegistrationStatusResponse>(
    '/tendlc/coordinator/v1/location-registration-status',
    { params: req }
  );

  return getResponseData<GetLocationRegistrationStatusResponse>(response);
};
