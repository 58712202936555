import React, { useState } from 'react';
import { css } from '@emotion/core';
import {
  Text,
  Modal,
  DropdownField,
  PlusIcon,
  ModalControlModalProps,
  useControlledField,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { DEFAULT_ASSIGN_LOCATIONS_DROPDOWN_OPTIONS } from './constants';

interface Props extends ModalControlModalProps {
  onSubmit: (selectedValue: string) => void;
  dropdownOptions: string[];
}

export const AssignLocationModal = ({
  onSubmit,
  dropdownOptions,
  ...modalProps
}: Props) => {
  const [dropdownValue, setDropdownValue] = useState('');
  const dropdownField = useControlledField({
    type: 'dropdown',
    value: dropdownValue,
    onChange: (value) => setDropdownValue(value),
  });

  const handleSubmit = () => {
    onSubmit(dropdownValue);
    modalProps.onClose();
    setDropdownValue('');
  };

  return (
    <>
      <Modal {...modalProps} maxWidth={550}>
        <Modal.Header textAlign="center">Assign Locations</Modal.Header>
        <Modal.Body>
          <Text textAlign="center" css={{ margin: theme.spacing(2, 2, 3, 2) }}>
            Select to assign the locations as part of a current or new location group or
            to group them as single locations.
          </Text>
          <DropdownField
            {...dropdownField}
            placeholder="Select Option"
            name="location"
            label=""
          >
            {DEFAULT_ASSIGN_LOCATIONS_DROPDOWN_OPTIONS.map(({ label, value }) => (
              <DropdownField.Option value={value}>
                <PlusIcon size={20} css={{ marginRight: theme.spacing(1) }} />
                <Text as="span" weight="semibold">
                  {label}
                </Text>
              </DropdownField.Option>
            ))}
            {dropdownOptions.map((text) => (
              <DropdownField.Option
                value={text}
                css={{ '&&': { paddingLeft: theme.spacing(6) } }}
              >
                {text}
              </DropdownField.Option>
            ))}
          </DropdownField>
        </Modal.Body>
        <Modal.Actions
          css={modalActionsStyle}
          onSecondaryClick={modalProps.onClose}
          onPrimaryClick={handleSubmit}
          disablePrimary={!dropdownValue}
        />
      </Modal>
    </>
  );
};

const modalActionsStyle = css({
  '&&': {
    paddingTop: theme.spacing(9),
    justifyContent: 'center',
    button: { minWidth: 100 },
  },
});
