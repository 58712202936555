import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import { useRequestState } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import {
  ContentLoader,
  DropdownField,
  EmailField,
  FormRow,
  Modal,
  PhoneField,
  SwitchField,
  Text,
  TextField,
  useForm,
} from '@weave/design-system';
import { useDispatch, useSelector } from 'react-redux';
import {
  LocationModel,
  LocationsActionTypes,
  LocationTypes,
  requestUpdateLocation,
  selectCurrentLocation,
} from '../../../redux/actions/location';
import { Timezones } from '../../../models/timezones';
import { getVerticalList } from '../../../models/vertical-enum';
import { useLocationSettingsResource } from 'components/settings/use-location-settings.hook';

type Props = {
  onClose: () => void;
};

const fieldNames = {
  Name: 'Name',
  Email: 'Email',
  Phone: 'Phone',
  Timezone: 'Timezone',
  Type: 'Type',
  VerticalID: 'VerticalID',
  Active: 'Active',
} as const;

export const LocationEdit = (props: Props) => {
  const dispatch = useDispatch();
  const location = useSelector(selectCurrentLocation);
  const { loading } = useRequestState(LocationsActionTypes.RequestUpdateLocation);
  const { findSetting, updateSetting } = useLocationSettingsResource('email');

  const emailSettings = findSetting('automated');

  const { formProps, getFieldProps, isComplete, seedValues, values } = useForm({
    fields: {
      [fieldNames.Name]: { type: 'text', required: true },
      [fieldNames.Email]: { type: 'email' },
      [fieldNames.Phone]: { type: 'phone' },
      [fieldNames.Timezone]: { type: 'dropdown' },
      [fieldNames.Type]: { type: 'dropdown' },
      [fieldNames.VerticalID]: { type: 'dropdown' },
      [fieldNames.Active]: { type: 'switch' },
    },
  });

  useEffect(() => {
    seedValues({
      [fieldNames.Name]: location.Name ?? '',
      [fieldNames.Email]: emailSettings?.value ?? '',
      [fieldNames.Phone]: location.Phone ?? '',
      [fieldNames.Timezone]: location.Timezone ?? '',
      [fieldNames.Type]: (location.Type ?? '').toString(),
      [fieldNames.VerticalID]: location.VerticalID ? `${location.VerticalID}` : '',
      [fieldNames.Active]: location.Active ?? false,
    });
  }, [location, emailSettings]);

  const handleSubmit = () => {
    if (isComplete) {
      const fieldValues = {
        ...values,
        [fieldNames.VerticalID]: Number(values[fieldNames.VerticalID]),
      } as Partial<LocationModel>;
      updateSetting({
        set: 'email',
        key: 'automated',
        value: fieldValues.Email || location.Email,
      });
      dispatch(requestUpdateLocation(location.LocationID, fieldValues, props.onClose));
    }
  };

  return (
    <>
      <ContentLoader show={loading} />
      <Modal.Header>Edit Location</Modal.Header>
      <Modal.Body>
        <form
          {...formProps}
          css={(theme: WeaveTheme) =>
            css`
              margin-top: ${theme.spacing(2)};
            `
          }
        >
          <FormRow>
            <TextField
              {...getFieldProps(fieldNames.Name)}
              label="Location Name"
              helperText="This field will update WAM Location Name and Phone Data Tenant Name"
            />
          </FormRow>
          <FormRow>
            <EmailField {...getFieldProps(fieldNames.Email)} label="Office Email" />
          </FormRow>
          <FormRow>
            <PhoneField
              {...getFieldProps(fieldNames.Phone)}
              label="Outbound Caller Number"
              helperText="This field will update WAM Phone Number and Phone Data Caller Number"
            />
          </FormRow>
          <FormRow>
            <DropdownField
              {...getFieldProps(fieldNames.Timezone)}
              label="Timezone"
              helperText="This field will update WAM Timezone and Phone Data Timezone"
            >
              {Timezones.map((timezone) => (
                <DropdownField.Option
                  key={timezone.ID}
                  value={timezone.Value}
                  searchValue={timezone.Value}
                >
                  {timezone.Value}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
          <FormRow>
            <DropdownField {...getFieldProps(fieldNames.Type)} label="Type">
              <DropdownField.Option value="Regular">Regular</DropdownField.Option>
              <DropdownField.Option value="Demo">Demo</DropdownField.Option>
              <DropdownField.Option value="Dev">Dev</DropdownField.Option>
            </DropdownField>
          </FormRow>
          <FormRow>
            <DropdownField {...getFieldProps(fieldNames.VerticalID)} label="Vertical">
              {getVerticalList().map((vertical) => (
                <DropdownField.Option
                  key={vertical.key}
                  value={`${vertical.key}`}
                  searchValue={vertical.value}
                >
                  {vertical.value}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
          <FormRow>
            <SwitchField {...getFieldProps(fieldNames.Active)} label="Active Status" />
          </FormRow>
          {!values.Active && (
            <FormRow>
              <Text color="error">
                <Text as="span" weight="bold">
                  WARNING:
                </Text>{' '}
                Marking this office inactive will prevent all users from using this
                location. Please be sure this effect is desirable before saving.
              </Text>
            </FormRow>
          )}
        </form>
      </Modal.Body>
      <Modal.Actions
        onSecondaryClick={props.onClose}
        onPrimaryClick={handleSubmit}
        disablePrimary={!isComplete}
      />
    </>
  );
};
