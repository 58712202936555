import { Schedule } from '../../../../../../../phones/office-hours/legacy/interface';

export const NEW_OFFICE_HOURS_SCHEDULE: Schedule[][] = [
  [
    {
      dayOfWeek: 1,
      startTime: '07:30:00',
      endTime: '17:30:00',
    },
    {
      dayOfWeek: 2,
      startTime: '07:30:00',
      endTime: '17:30:00',
    },
    {
      dayOfWeek: 3,
      startTime: '07:30:00',
      endTime: '17:30:00',
    },
    {
      dayOfWeek: 4,
      startTime: '07:30:00',
      endTime: '17:30:00',
    },
    {
      dayOfWeek: 5,
      startTime: '07:30:00',
      endTime: '17:30:00',
    },
  ],
  [
    {
      dayOfWeek: 0,
      startTime: '00:00:00',
      endTime: '23:59:59',
    },
    {
      dayOfWeek: 1,
      startTime: '00:00:00',
      endTime: '23:59:59',
    },
    {
      dayOfWeek: 2,
      startTime: '00:00:00',
      endTime: '23:59:59',
    },
    {
      dayOfWeek: 3,
      startTime: '00:00:00',
      endTime: '23:59:59',
    },
    {
      dayOfWeek: 4,
      startTime: '00:00:00',
      endTime: '23:59:59',
    },
    {
      dayOfWeek: 5,
      startTime: '00:00:00',
      endTime: '23:59:59',
    },
    {
      dayOfWeek: 6,
      startTime: '00:00:00',
      endTime: '23:59:59',
    },
  ],
  [
    {
      dayOfWeek: 0,
      startTime: '00:00:00',
      endTime: '23:59:00',
    },
    {
      dayOfWeek: 6,
      startTime: '00:00:00',
      endTime: '23:59:00',
    },
  ],
];
