import React, { Dispatch, SetStateAction, useState } from 'react';
import { ProhibitedFlags } from '../customization-page-v2.types';

export const CustomizationProhibitedFlagsContext = React.createContext<ProhibitedFlags[]>(
  []
);

export const CustomizationProhibitedFlagsUpdaterContext = React.createContext<Dispatch<
  SetStateAction<ProhibitedFlags[]>
> | null>(null);

export const CustomizationProhibitedFlagsProvider = ({ children }) => {
  const [customizationProhibitedFlags, setCustomizationProhibitedFlags] = useState<
    ProhibitedFlags[]
  >([]);

  return (
    <CustomizationProhibitedFlagsUpdaterContext.Provider
      value={setCustomizationProhibitedFlags}
    >
      <CustomizationProhibitedFlagsContext.Provider value={customizationProhibitedFlags}>
        {children}
      </CustomizationProhibitedFlagsContext.Provider>
    </CustomizationProhibitedFlagsUpdaterContext.Provider>
  );
};

export const useCustomizationProhibitedFlags = () =>
  React.useContext(CustomizationProhibitedFlagsContext);

export const useCustomizationProhibitedFlagsUpdater = () => {
  const setCustomizationProhibitedFlags = React.useContext(
    CustomizationProhibitedFlagsUpdaterContext
  );

  const updateCustomizationProhibitedFlags = React.useCallback(
    (customizationProhibitedFlags: ProhibitedFlags[]) => {
      if (setCustomizationProhibitedFlags) {
        setCustomizationProhibitedFlags(customizationProhibitedFlags);
      }
    },
    [setCustomizationProhibitedFlags]
  );

  return updateCustomizationProhibitedFlags;
};
