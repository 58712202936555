import { useQuery, useQueryClient } from 'react-query';
import { CustomAxios } from 'redux/axios';
import { isDevEnv } from '../../config/app';

export type SearchUserByIdResponse = {
  FirstName: string;
  LastName: string;
  MobileNumber: string;
  MobileNumberVerifiedAt: string;
  Status: string;
  Type: string;
  UserID: string;
  Username: string;
};

export const searchUserById = (userId: string) => {
  // This is a hack because the API endpoint requires a valid location ID
  // The API can actually search cross locations so these are hardcoded for
  // now to make this work. A better solution would be to fix the API endpoint
  // not to require a location id for this request.
  const locationID = isDevEnv()
    ? // mobiledev
      'cb936c1e-529e-4f7d-84e1-ee4b9f67e8c8'
    : // witest
      'a8da7d2a-f875-40fe-adce-9cb9c41acd45';

  return CustomAxios.get<{ data: SearchUserByIdResponse }>(
    `/portal/v1/users/search/${userId}`,
    {
      headers: {
        'Location-Id': locationID,
      },
    }
  ).then((res) => res?.data?.data);
};

export type SearchUserByEmailResponse = {
  FirstName: string;
  LastName: string;
  Type: string;
  UserID: string;
  Username: string;
}[];

export const searchUserByEmail = (email: string) => {
  return CustomAxios.get<{ data: SearchUserByEmailResponse }>(
    `/support/v1/users/?search=${email}&limit=10`
  ).then((res) => res?.data?.data);
};

type UserLocation = {
  ID: string;
  Roles: [{ ID: number; Name: string; Type: string }];
};

type GetUsersLocationResponse = {
  FirstName: string;
  LastName: string;
  Locations: UserLocation[];
  Type: string;
  UserID: string;
  Username: string;
};

const getUserLocations = (userId: string) => {
  return CustomAxios.get<{ data: GetUsersLocationResponse }>(
    `/support/v1/users/locations/${userId}`
  ).then((res) => res.data?.data);
};

export const useGetUserLocationData = (userId: string) => {
  return useQuery(['userLocations', userId], () => getUserLocations(userId), {
    enabled: !!userId,
  });
};

type GetLocationsResponse = {
  Active: boolean;
  BannerMessages: unknown[];
  Email: string;
  LocationID: string;
  Name: string;
  ParentID: null;
  PhoneTenantID: null;
  SalesforceID: string;
  Slug: string;
  Timezone: string;
  Type: string;
  Vertical: string;
  VerticalID: number;
};

const getLocation = (locationId: string) => {
  return CustomAxios.get<{ data: GetLocationsResponse }>(
    `/support/v1/locations/${locationId}`
  ).then((res) => res.data?.data);
};

export const useGetLocation = (locationId: string) =>
  useQuery(['locations', locationId], () => getLocation(locationId), {
    enabled: !!locationId,
  });
