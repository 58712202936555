import { useMemo, useState } from 'react';
import { css } from '@emotion/core';
import { SortingValue, Table, TableColumnConfig } from '@weave/design-system';
import { ProvisioningStatus } from '@weave/schema-gen-ts/dist/schemas/location/v1/provision/location_provision_service.pb';

import { LocationGroupChildInfo } from '../../../apis/location-provision/types';
import { ProvisionStatusChip } from './provision-status-chip';
import { LocationName } from './location-name';
import { IntakeFormLink } from '../intake-form-link';

interface Props {
  locations: LocationGroupChildInfo[];
  isSingleLocationTable?: boolean;
  onSelect: (slugList: string[]) => void;
}

const getTableColumns = (isSingleLocationTable: boolean) => {
  const columns: TableColumnConfig<LocationGroupChildInfo>[] = [
    {
      Header: 'Location Name',
      accessor: (location) => location,
      id: 'name',
      cellRenderer: (location: LocationGroupChildInfo) => (
        <LocationName
          name={location.name ?? ''}
          validationError={location.preProvisionValidationError}
          isProcessing={!!location.provisionerAuditId}
        />
      ),
    },
    {
      Header: 'Slug',
      accessor: 'slug',
      id: 'slug',
      maxWidth: 130,
    },
    {
      Header: 'Provisioned',
      accessor: (location) => location,
      id: 'provisionStatus',
      maxWidth: 135,
      cellRenderer: (location: LocationGroupChildInfo) => (
        <ProvisionStatusChip
          status={location.provisionStatus}
          tooltipText={location.provisioningError}
        />
      ),
    },
  ];

  if (isSingleLocationTable) {
    columns.push({
      Header: '',
      accessor: (location) => location.locationId,
      id: 'intake-form-link',
      maxWidth: 50,
      cellRenderer: (locationId) =>
        !!locationId && <IntakeFormLink locationId={locationId} iconOnly iconSize={16} />,
      // Note: Direct use of this field shows type error so using spread operator here
      ...{ disableSortBy: true },
    });
  }

  return columns;
};

export const LocationsTable = ({
  isSingleLocationTable = false,
  locations,
  onSelect,
}: Props) => {
  const [columnSortingValues, setColumnSortingValues] = useState<SortingValue<string>[]>(
    []
  );
  const columns = useMemo(
    () => getTableColumns(isSingleLocationTable),
    [isSingleLocationTable]
  );

  const sortedLocations = useMemo<LocationGroupChildInfo[]>(() => {
    const { value: sortOrder, id: sortColumn } = columnSortingValues[0] ?? {};
    if (!sortOrder || !sortColumn) return locations;

    const order = sortOrder === 'asc' ? 1 : -1;
    // languageLocales is set to undefined because the application has no dependency on language locales
    const languageLocales = undefined;
    return [...locations].sort(
      (a, b) =>
        order *
        a[sortColumn].localeCompare(b[sortColumn], languageLocales, {
          numeric: true,
          sensitivity: 'base',
        })
    );
  }, [locations, columnSortingValues]);

  return (
    <Table
      hasResponsiveColWidths
      colConfig={columns}
      data={sortedLocations}
      emptyStateConfig={{ type: 'generic' }}
      isSelectable
      disableMultiSort
      onSortChange={setColumnSortingValues}
      rowSelectionConfig={{
        hideBulkSelection: true,
        onSelectionToggle: (_, data) => {
          onSelect(data.map((row) => row.slug));
        },
        isRowDisabled: (cell: LocationGroupChildInfo) =>
          !!cell.preProvisionValidationError ||
          !!cell.isInactive ||
          cell.provisionStatus !== ProvisioningStatus.PROVISION_NO,
      }}
      tableStyle={css({
        '[role="rowgroup"]:last-of-type': {
          maxHeight: 500,
        },
      })}
    />
  );
};
