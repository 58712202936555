import { Action, handleActions } from 'redux-actions';
import { get, find, isEmpty, cloneDeep } from 'lodash';

import {
  clearGreetings,
  deleteGreeting,
  deleteGreetingFailure,
  deleteGreetingSuccess,
  getGreetingsForAllMailboxes,
  getGreetingsForAllMailboxesFailure,
  getGreetingsForAllMailboxesSuccess,
  getGreetingsForMailbox,
  getGreetingsForMailboxFailure,
  getGreetingsForMailboxSuccess,
  getGreetingMaps,
  getGreetingMapsFailure,
  getGreetingMapsSuccess,
  getMailboxesFailure,
  getMailboxesSuccess,
  updateActiveMailboxId,
  updateGreetingFailure,
  updateGreetings,
  updateGreetingSuccess,
  uploadGreeting,
  uploadGreetingFailure,
  uploadGreetingSuccess,
  uploadGreetingConversion,
  uploadGreetingConversionFailure,
  uploadGreetingConversionSuccess,
} from './voice-mailboxes.action';
import {
  Greeting,
  GreetingMap,
  VoiceMailboxesState,
  Mailbox,
  VoicemailBoxesActionTypes,
  VoicemailBoxesActions,
} from './voice-mailboxes.types';
import { Reducer } from 'redux';
import { reduceReducers } from '../../store/store-utils';

export const initialVoicemailBoxesState: VoiceMailboxesState = {
  activeMailboxId: '',
  greetings: [],
  greetingMaps: [],
  loadingGreetings: false,
  loadingGreetingMaps: false,
  mailboxes: [],
  updatingGreetings: false,
  allMailboxGreetings: [],
  allMailboxGreetingsLoading: false,
};

interface UpdateGreetingPayload {
  greetingId: string;
  greetingName: string;
  greetingNumber: number;
}

export const newVoicemailBoxesReducer: Reducer<
  VoiceMailboxesState,
  VoicemailBoxesActions
> = (state = initialVoicemailBoxesState, action) => {
  switch (action.type) {
    case VoicemailBoxesActionTypes.UpdateMailbox: {
      const mailboxes = state.mailboxes.map((mailbox) =>
        mailbox.mailboxID === action.payload.mailboxID
          ? { ...mailbox, ...action.payload }
          : mailbox
      );
      return {
        ...state,
        mailboxes,
      };
    }
    default:
      return state;
  }
};

export const oldVoiceMailboxesReducer = handleActions<VoiceMailboxesState, any>(
  {
    [clearGreetings.toString()]: (state) => ({
      ...state,
      greetings: [],
      loadingGreetings: false,
      updatingGreetings: false,
      allMailboxGreetingsLoading: false,
      loadingGreetingsMap: false,
      greetingsMaps: [],
    }),
    [deleteGreeting.toString()]: (state) => ({ ...state, loadingGreetings: true }),
    [deleteGreetingSuccess.toString()]: (state, action: Action<string>) => ({
      ...state,
      greetings: state.greetings.filter((greeting) => {
        return greeting.greetingId !== action.payload;
      }),
      loadingGreetings: false,
    }),
    [deleteGreetingFailure.toString()]: (state) => ({
      ...state,
      loadingGreetings: false,
    }),
    [getMailboxesSuccess.toString()]: (state, action: Action<Mailbox[]>) => {
      const mailboxes: Mailbox[] = action.payload || [];
      const generalMailbox = find(mailboxes, { number: '9000' });

      return {
        ...state,
        activeMailboxId: get(
          generalMailbox,
          'mailboxID',
          get(mailboxes, '[0].mailboxID', '')
        ),
        mailboxes,
      };
    },
    [getMailboxesFailure.toString()]: (state) => ({
      ...state,
      mailboxes: [],
    }),
    [getGreetingsForMailbox.toString()]: (state) => ({
      ...state,
      greetings: [],
      loadingGreetings: true,
    }),
    [getGreetingsForMailboxSuccess.toString()]: (
      state,
      action: Action<{ greetings: Greeting[] }>
    ) => ({
      ...state,
      greetings: (action.payload && action.payload.greetings) || [],
      loadingGreetings: false,
    }),
    [getGreetingsForMailboxFailure.toString()]: (state) => ({
      ...state,
      greetings: [],
      loadingGreetings: false,
    }),
    [getGreetingMaps.toString()]: (state) => ({
      ...state,
      greetingMaps: [],
      loadingGreetingMaps: true,
    }),
    [getGreetingMapsSuccess.toString()]: (
      state,
      action: Action<{ greetingMaps: GreetingMap[] }>
    ) => ({
      ...state,
      greetingMaps: (action.payload && action.payload.greetingMaps) || [],
      loadingGreetingMaps: false,
    }),
    [getGreetingMapsFailure.toString()]: (state) => ({
      ...state,
      greetingMaps: [],
      loadingGreetingMaps: false,
    }),
    [updateActiveMailboxId.toString()]: (state, action: Action<string>) => ({
      ...state,
      activeMailboxId: action.payload || '',
    }),
    [updateGreetings.toString()]: (state) => ({ ...state, updatingGreetings: true }),
    [updateGreetingSuccess.toString()]: (
      state,
      action: Action<UpdateGreetingPayload>
    ) => {
      const oldGreetings = cloneDeep(state.greetings);
      const greetingToUpdate =
        find(oldGreetings, {
          GreetingID: (action.payload && action.payload.greetingId) || '',
        }) || {};

      if (!isEmpty(greetingToUpdate)) {
        greetingToUpdate['GreetingName'] =
          (action.payload && action.payload.greetingName) || '';
        greetingToUpdate['GreetingNumber'] =
          (action.payload && action.payload.greetingNumber) || '';
      }

      return {
        ...state,
        updatingGreetings: false,
        greetings: oldGreetings,
      };
    },
    [updateGreetingFailure.toString()]: (state) => ({
      ...state,
      updatingGreetings: false,
    }),
    [getGreetingsForAllMailboxes.toString()]: (state) => ({
      ...state,
      allMailboxGreetings: [],
      allMailboxGreetingsLoading: true,
    }),
    [getGreetingsForAllMailboxesSuccess.toString()]: (
      state,
      action: Action<{ greetings: Greeting[] }>
    ) => ({
      ...state,
      allMailboxGreetings: (action.payload && action.payload.greetings) || [],
      allMailboxGreetingsLoading: false,
    }),
    [getGreetingsForAllMailboxesFailure.toString()]: (state) => ({
      ...state,
      allMailboxGreetings: [],
      allMailboxGreetingsLoading: false,
    }),
    [uploadGreeting.toString()]: (state) => ({ ...state, loadingGreetings: true }),
    [uploadGreetingSuccess.toString()]: (state, action: Action<Greeting>) => {
      return {
        ...state,
        greetings: action.payload
          ? [action.payload, ...state.greetings]
          : [...state.greetings],
        loadingGreetings: false,
      };
    },
    [uploadGreetingFailure.toString()]: (state) => ({
      ...state,
      loadingGreetings: false,
    }),
    [uploadGreetingConversion.toString()]: (state) => ({
      ...state,
      loadingGreetings: true,
    }),
    [uploadGreetingConversionSuccess.toString()]: (state, action: Action<Greeting>) => ({
      ...state,
      greetings: action.payload
        ? [action.payload, ...state.greetings]
        : [...state.greetings],
      loadingGreetings: false,
    }),
    [uploadGreetingConversionFailure.toString()]: (state) => ({
      ...state,
      loadingGreetings: false,
    }),
  },
  initialVoicemailBoxesState
);

export const voiceMailboxesReducer = reduceReducers<
  VoiceMailboxesState,
  VoicemailBoxesActions
>(newVoicemailBoxesReducer, oldVoiceMailboxesReducer);
