import { OfficeConfigurations } from '../../../../../../../redux/actions/customization/customization.action';
import { LocationModel } from '../../../../../../../redux/actions/location';
import { DependencyDataKeys, MetricResult, Result } from './handoff-metrics.types';
import {
  AllHandoffTypes,
  HandoffType,
  HandoffTypeMetric,
  HandoffTypes,
  handoffTypesData,
  SubsectionData,
} from './handoff.constants';
import { useDependentMetricData } from './use-dependent-metric-data';
import {
  CustomizationFlagStatesV2,
  LocationFeatureV2,
} from '../../../../../../../models/location-feature.model';
import { GetSalesforceBundleNameResponse } from '@weave/schema-gen-ts/dist/schemas/package-service/v1/package.pb';

/**
 * Helper function that will return the appropriate handoff type meta data defined in the
 * const file. It will filter out any sections, subsections and metrics that should be
 * skipped (according to the value returned by the shouldSkip functions).
 *
 * @param handoffType
 * @param officeConfigurations
 * @param currentLocation
 * @param customizationFeatures
 * @param salesforceBundleData
 * @returns
 */
export const getHandoffTypeConstData = (
  handoffType: AllHandoffTypes,
  officeConfigurations: OfficeConfigurations,
  currentLocation: LocationModel,
  customizationFeatures: LocationFeatureV2[],
  salesforceBundleData: GetSalesforceBundleNameResponse
) => {
  const data = handoffTypesData[handoffType];

  if (!data) {
    console.error(`There is no handoff const data for handoffType: ${handoffType}`);
  }
  // Filter out any of the sections that should be skipped.
  const filteredSections = data.sections.reduce((acc, section) => {
    // Skip this section if shouldSkip returns true.
    if (
      section.shouldSkip?.({
        officeConfigurations,
        currentLocation,
        customizationFeatures,
        salesforceBundleData,
      })
    )
      return acc;

    // Filter out any of the section's subsections that should be skipped.
    const filteredSubsections = section.subsections.reduce(
      (subsectionsAcc, subsection) => {
        // Skip this subsection if shouldSkip returns true.
        if (
          subsection.shouldSkip?.({
            officeConfigurations,
            currentLocation,
            customizationFeatures,
          })
        )
          return subsectionsAcc;

        // Filter out any metrics for this subsection that should be skipped.
        const filteredMetrics = subsection.metrics.reduce((metricsAcc, metric) => {
          // Skip this metric if shouldSkip returns true.
          if (
            metric.shouldSkip?.({
              officeConfigurations,
              currentLocation,
              customizationFeatures,
            })
          )
            return metricsAcc;

          return [...metricsAcc, metric];
        }, [] as HandoffTypeMetric[]);

        return [...subsectionsAcc, { ...subsection, metrics: filteredMetrics }];
      },
      [] as SubsectionData[]
    );

    return [
      ...acc,
      {
        ...section,
        subsections: filteredSubsections,
      },
    ];
  }, [] as HandoffType['sections']);

  return {
    ...data,
    sections: filteredSections,
  };
};

/**
 * Returns all unique dependent data keys given an array of HandoffTypeMetric objects.
 * This will return the dependent data keys for a specific metric.
 *
 * @param metrics
 * @returns
 */
export const getUniqueDependentKeysForMetrics = (metrics: HandoffTypeMetric[]) => {
  const uniqueKeys = new Set<DependencyDataKeys>();

  metrics.forEach((metric) =>
    metric.dependencyDataKeys.forEach((key) => uniqueKeys.add(key))
  );

  return Array.from(uniqueKeys);
};

export const isCustomizationFeatureEnabled = (
  name: string,
  features: LocationFeatureV2[]
) => {
  return features?.some(
    (feature) =>
      feature.name === name && feature.state === CustomizationFlagStatesV2.ACTIVE
  );
};

/**
 * Returns all unique dependent data keys given a handoffType object. This will return
 * the dependent data keys for all metrics of all subsections of all sections of a certain
 * handoff type.
 *
 * @param handoffTypeData
 * @returns
 */
export const getUniqueDependentDataKeys = (handoffTypeData: HandoffType) => {
  const uniqueKeys = new Set<DependencyDataKeys>();

  handoffTypeData.sections.forEach((section) =>
    section.subsections.forEach((subsection) =>
      getUniqueDependentKeysForMetrics(subsection.metrics).forEach((key) =>
        uniqueKeys.add(key)
      )
    )
  );

  return Array.from(uniqueKeys);
};

/**
 * Helper function that will return the test results for each metric given an array of
 * metric objects defined in the const file.
 *
 * @param metrics
 * @param dependentData
 * @param sectionName
 * @param subsectionName
 * @returns
 */
export const getMetricResults = (
  metrics: HandoffTypeMetric[],
  dependentData: ReturnType<typeof useDependentMetricData>,
  sectionName?: string,
  subsectionName?: string
): MetricResult[] =>
  metrics.map((metric) => {
    // Get only the data required for this metric.
    const dependentDataForCurrMetric = metric.dependencyDataKeys.reduce((acc, curr) => {
      return { ...acc, [curr]: dependentData[curr] };
    }, {});

    return {
      testCriteria: metric.testCriteriaFn(),
      expectedValue: String(metric.expectedValue),
      actualValue: String(metric.actualValueFn(dependentDataForCurrMetric)),
      result: metric.resultFn(dependentDataForCurrMetric),
      section: sectionName ?? '',
      subsection: subsectionName ?? '',
      exception: '',
    };
  });

export const getAllMetricResults = (
  handoffTypeData: HandoffType,
  dependentData: ReturnType<typeof useDependentMetricData>
) =>
  handoffTypeData.sections.reduce((sectionAcc: MetricResult[], section) => {
    return [
      ...sectionAcc,
      ...section.subsections.reduce((subsectionAcc: MetricResult[], subsection) => {
        return [
          ...subsectionAcc,
          ...getMetricResults(
            subsection.metrics,
            dependentData,
            section.name,
            subsection.name
          ),
        ];
      }, []),
    ];
  }, []);

/**
 * Given an object of MetricResult type, will return the corresponding const data object
 * of type HandoffTypeMetric from the provided handoffTypeData.
 *
 * @param metricResult MetricResult
 * @param handoffTypeData HandoffType
 * @returns
 */
export const getMetricConstDataByMetricResult = (
  metricResult: MetricResult,
  handoffTypeData: HandoffType
) =>
  handoffTypeData.sections
    .find((section) => section.name === metricResult.section)
    ?.subsections.find((subsection) => subsection.name === metricResult.subsection)
    ?.metrics.find((metric) => metric.testCriteriaFn() === metricResult.testCriteria);

export type GroupedFailedMetrics = {
  withExceptions: { metricResult: MetricResult; exceptions: string[] }[];
  withNoExceptions: MetricResult[];
};

export const groupFailedMetrics = (
  metricResults: MetricResult[],
  handoffTypeData: HandoffType
) => {
  // Group any failed metrics by those that have exceptions available and those that
  // do not.
  const groupedFailedMetrics = metricResults.reduce(
    (acc: GroupedFailedMetrics, metricResult) => {
      if (metricResult.result === Result.Pass) return acc;

      const handoffTypeMetric = getMetricConstDataByMetricResult(
        metricResult,
        handoffTypeData
      );

      const exceptions = handoffTypeMetric?.exceptions ?? [];

      const key = exceptions?.length ? 'withExceptions' : 'withNoExceptions';

      if (key === 'withExceptions') {
        return {
          ...acc,
          withExceptions: [...acc.withExceptions, { metricResult, exceptions }],
        };
      } else {
        return {
          ...acc,
          withNoExceptions: [...acc.withNoExceptions, metricResult],
        };
      }
    },
    {
      withExceptions: [],
      withNoExceptions: [],
    }
  );

  return groupedFailedMetrics;
};

export const getConcatenatedMetricName = (metricResult: MetricResult) =>
  `${metricResult.section}|${metricResult.subsection}|${metricResult.testCriteria}`;
