import { Alert, SpinningLoader } from '@weave/design-system';
import { useGetHandoffHubSnapshotByIdQuery } from '../../handoff-hub.queries';
import { useParams } from 'react-router-dom';
import { DeprecatedHandoffTypes } from './edit-handoff/handoff-metrics/handoff.constants';
import { SnapshotViewComponent } from './view-handoff/snapshot-view.component';
import { ONBOARDING, injectParams } from '../../../../../constants/paths';
import { Page } from '../../../../../components/shared';
import { AddEditHandoff } from './edit-handoff/add-edit-handoff';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../../../redux/actions/location';

const ViewHandoffPage: FC = ({ children }) => {
  const { id: locationId } = useParams<{ id: string }>();
  const title = 'View Handoff';
  const handoffHubUrl = injectParams(ONBOARDING.handoff, { id: locationId });
  return (
    <Page
      title={title}
      breadcrumbs={[
        { url: handoffHubUrl, title: 'Handoff Hub' },
        { url: '', title: title },
      ]}
    >
      {children}
    </Page>
  );
};

export const HandoffHubInfo = () => {
  const { snapshotId } = useParams<{ id: string; snapshotId: string }>();
  const locationId = useSelector(selectCurrentLocationId);

  const {
    data: snapshotInfo,
    isError,
    isLoading,
  } = useGetHandoffHubSnapshotByIdQuery(snapshotId);

  if (isLoading || !locationId) {
    return (
      <ViewHandoffPage>
        <SpinningLoader className="loader" size="medium" />
      </ViewHandoffPage>
    );
  }
  if (isError) {
    return (
      <ViewHandoffPage>
        <Alert type="error">
          There was an error fetching the handoff notes. Please try again.
        </Alert>
      </ViewHandoffPage>
    );
  }
  if (!snapshotInfo) {
    return (
      <ViewHandoffPage>
        <Alert type="error">Handoff details not found</Alert>
      </ViewHandoffPage>
    );
  }

  const isEditable = snapshotInfo.submittedAt === null;
  const isDeprecated = Object.values(DeprecatedHandoffTypes).includes(
    snapshotInfo.handoffType as DeprecatedHandoffTypes
  );

  if (isEditable && !isDeprecated) {
    return <AddEditHandoff handoffSnapshot={snapshotInfo} />;
  }

  return (
    <ViewHandoffPage>
      {isEditable && isDeprecated && (
        <Alert type="warning">
          This handoff type has been deprecated and can no longer be edited.
        </Alert>
      )}
      <SnapshotViewComponent snapshotData={snapshotInfo} />
    </ViewHandoffPage>
  );
};
