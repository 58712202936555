import { ToggleState } from 'components/shared/multi-state-toggle/multi-state-toggle.component';
import { CustomizationFlagStatesV2 } from 'models/location-feature.model';
import { SalesforceAccountProductBundle } from './customization-page-v2.types';

export const LocationFeatureStates: ToggleState[] = [
  { key: CustomizationFlagStatesV2.ACTIVE, label: 'Show', color: 'success' },
  { key: CustomizationFlagStatesV2.HIDE, label: 'Hide', color: 'error' },
  { key: CustomizationFlagStatesV2.PROMOTE, label: 'Promote', color: 'light' },
];

export const weaveInsuranceVerificationFlag = ['Weave Verify'];

export const routerPromptVerbiages = {
  title: 'Are you sure?',
  secondaryButtonLabel: 'Leave without saving',
  primaryButtonLabel: 'Save changes',
  promptMessage:
    'You have unsaved changes. Are you sure you want to leave this page? Unsaved changes will be lost.',
  confirmationMessage:
    'It looks like you have been editing something. If you leave before saving, your changes will be lost.',
};

export const weaveProductBundles = [
  {
    name: 'Weave Plus Ultimate',
    value: SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_ULTIMATE,
  },
  {
    name: 'Weave Plus Elite',
    value: SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_ELITE,
  },
  {
    name: 'Weave Plus Pro',
    value: SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_PRO,
  },
  {
    name: 'Weave Plus Essentials',
    value: SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_PLUS_ESSENTIALS,
  },
  {
    name: 'Weave Core Ultimate',
    value: SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_ULTIMATE,
  },
  {
    name: 'Weave Core Elite',
    value: SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_ELITE,
  },
  {
    name: 'Weave Core Pro',
    value: SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_PRO,
  },
  {
    name: 'Weave Core Essentials',
    value: SalesforceAccountProductBundle.PRODUCT_BUNDLE_WEAVE_CORE_ESSENTIALS,
  },
];
