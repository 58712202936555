import { useHoverLabel, IconButton, CopyIcon } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import React, { useEffect } from 'react';

import { useClipboard } from './use-clipboard';

type ConditionalProp = string | React.ReactNode;

type CopyContentsButtonPropsString<T extends ConditionalProp> = {
  children: T;
  label?: string;
  beforeChildren?: boolean;
};

type CopyContentsButtonPropsNode<T extends ConditionalProp> = {
  children: T;
  label?: string;
  copyText: string;
  beforeChildren?: boolean;
};

type Props<T extends ConditionalProp> = T extends string
  ? CopyContentsButtonPropsString<T>
  : CopyContentsButtonPropsNode<T>;

export const CopyToClipboardButton = <T extends ConditionalProp>(props: Props<T>) => {
  const { children, label, beforeChildren } = props;

  const { copy, copied, clearState } = useClipboard();
  const { triggerProps, labelProps, HoverLabel } = useHoverLabel({
    placement: 'top',
  });

  const copyText =
    typeof children === 'string'
      ? children
      : (props as CopyContentsButtonPropsNode<T>).copyText;

  useEffect(() => {
    if (labelProps['aria-hidden']) {
      clearState();
    }
  }, [labelProps['aria-hidden']]);

  return (
    <>
      {!beforeChildren && children}
      {children && copyText && (
        <>
          <IconButton
            label={label ?? (copied ? 'Copied' : 'Copy')}
            {...triggerProps}
            onClick={() => copy(copyText)}
            style={{
              display: 'inline-block',
              marginLeft: theme.spacing(1),
            }}
          >
            <CopyIcon />
          </IconButton>
          {<HoverLabel {...labelProps}>{copied ? 'Copied' : 'Copy'}</HoverLabel>}
        </>
      )}
      {beforeChildren && children}
    </>
  );
};
