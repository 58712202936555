import { TerminalIcon, SyncIcon, UpdateIcon } from '@weave/design-system';
import {
  ConfirmModalType,
  SyncAppButtonInterface,
  SyncTabInterface,
  SyncTabs,
} from '../sync-app.types';

export const syncTabs: SyncTabInterface[] = [
  {
    syncTab: SyncTabs.Terminal,
    icon: TerminalIcon,
    label: 'Terminal',
  },
  {
    syncTab: SyncTabs.SyncStatus,
    icon: SyncIcon,
    label: 'Sync Status',
  },
  {
    syncTab: SyncTabs.LastSyncInfo,
    icon: UpdateIcon,
    label: 'Last Sync Info',
  },
];

export const getMultipleSyncAppInfoPayload = (sourceId) => [
  {
    sourceId,
    rpcMethod: 'OS.GetOSInformation',
    rpcParams: [],
    syncAppInfoKey: 'osInfo',
  },
  {
    sourceId,
    rpcMethod: 'RPCAdapter.Version',
    rpcParams: [],
    syncAppInfoKey: 'versionInfo',
  },
  {
    sourceId,
    rpcMethod: 'RPCAdapter.AdapterInformation',
    rpcParams: [],
    syncAppInfoKey: 'adapterInfo',
  },
  {
    sourceId,
    rpcMethod: 'RPCAdapter.IntegrationInformation',
    rpcParams: [],
    syncAppInfoKey: 'integrationInfo',
  },
];

export const getTerminalButtons = ({
  managePermission,
  installAdvancedPermission,
  manageAdvancedPermission,
}: {
  [key: string]: boolean;
}): SyncAppButtonInterface[] => {
  return [
    {
      title: 'Ping',
      rpcMethod: 'RPCAdapter.Ping',
    },
    {
      title: 'Sync Patients',
      rpcMethod: 'RPCAdapter.SyncPatients',
    },
    {
      title: 'Sync Appts',
      rpcMethod: 'RPCAdapter.SyncAppointments',
    },
    {
      title: 'Recall Types',
      rpcMethod: 'RPCAdapter.GetRecallTypes',
    },
    {
      title: 'Reload Types',
      rpcMethod: 'RPCAdapter.PushTypes',
    },
    {
      title: 'Compatible Integrations',
      rpcMethod: 'RPCAdapter.CompatibleAdapters',
    },
    {
      title: 'Send Diffs',
      rpcMethod: 'RPCAdapter.SendDiffs',
    },
    {
      title: 'Writeback Diagnostic',
      rpcMethod: 'RPCAdapter.Diagnose',
      rpcParams: ['Writeback'],
    },
    {
      title: 'Reload Cloud Settings',
      rpcMethod: 'RPCAdapter.ReloadCloudSettings',
      showButton: managePermission,
    },
    {
      title: 'Update',
      rpcMethod: 'RPCAdapter.Update',
      showButton: managePermission,
    },
    {
      title: 'Integration Info',
      rpcMethod: 'RPCAdapter.AdapterInformation',
      showButton: managePermission,
    },
    {
      title: 'Settings File',
      rpcMethod: 'RPCAdapter.GetSettingsFile',
      showButton: managePermission,
    },
    {
      title: 'Restart',
      confirmModalType: ConfirmModalType.Restart,
      showButton: managePermission,
    },
    {
      title: 'Terminate',
      confirmModalType: ConfirmModalType.Terminate,
      showButton: installAdvancedPermission,
    },
    {
      title: 'Purge',
      confirmModalType: ConfirmModalType.Purge,
      showButton: manageAdvancedPermission,
    },
  ];
};
