import React from 'react';
import { css } from '@emotion/core';
import { CurrentPricing } from './current-pricing.component';
import { PricingHistoryLog } from './pricing-history-log.component';
import {
  Heading,
  PrimaryButton,
  EditSimpleIcon,
  useModalControl,
  SpinningLoader,
} from '@weave/design-system';
import { PaymentPricingEdit } from './payment-pricing-edit.component';
import PlanTypeChip from './plan-type-chip';
import { usePricingsState } from '../hooks/use-pricings-state';
import { getPricingName, standardPricing } from './pricing-helpers';

export const PaymentPricingDisplay = () => {
  const { triggerProps, modalProps, closeModal } = useModalControl();

  const { list: pricings, loading: pricingsLoading } = usePricingsState();
  const latestPricing = pricings[0];
  const isCardPresent =
    latestPricing &&
    !!(latestPricing.cardPresent?.rate || latestPricing.cardPresent?.transactionCost);

  return (
    <>
      <Heading
        css={css`
          margin-top: 24px;
          margin-bottom: 24px;
        `}
        level={1}
      >
        Plan & Pricing
      </Heading>
      {pricingsLoading && (
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <SpinningLoader />
        </div>
      )}
      {latestPricing && !pricingsLoading && (
        <>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <Heading
              css={css`
                margin: 0;
              `}
              level={2}
            >
              Merchant Plan: <PlanTypeChip planName={getPricingName(latestPricing)} />
            </Heading>
            <div>
              <PrimaryButton title="Edit pricing" {...triggerProps}>
                Edit <EditSimpleIcon size={14}></EditSimpleIcon>
              </PrimaryButton>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              align-items: center;
            `}
          >
            <Heading
              css={css`
                margin: 0;
              `}
              level={2}
            >
              Current Pricing
            </Heading>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
            `}
          >
            {!!latestPricing.cardPresent && isCardPresent && (
              <CurrentPricing
                title="Card Present (CP)"
                processingRate={latestPricing.cardPresent?.rate}
                transactionCost={latestPricing.cardPresent?.transactionCost}
                date={latestPricing.createdAt}
              />
            )}
            <CurrentPricing
              title="Card Not Present (CNP)"
              processingRate={latestPricing.cardNotPresent.rate}
              transactionCost={latestPricing.cardNotPresent.transactionCost}
              date={latestPricing.createdAt}
            />
            <CurrentPricing
              title="Mobile Tap to Pay (MTTP)"
              processingRate={
                latestPricing.mobileTapToPay?.rate ?? standardPricing.mobileTapToPay.rate
              }
              transactionCost={
                latestPricing.mobileTapToPay?.transactionCost ??
                standardPricing.mobileTapToPay.transactionCost
              }
              date={latestPricing.createdAt}
            />
            <CurrentPricing
              title="ACH"
              processingRate={latestPricing.ach?.rate ?? standardPricing.ach.rate}
              transactionCost={
                latestPricing.ach?.transactionCost ?? standardPricing.ach.transactionCost
              }
              date={latestPricing.createdAt}
            />
          </div>

          <Heading
            css={css`
              margin-top: 20px;
            `}
            level={2}
          >
            Pricing History
          </Heading>
          <PricingHistoryLog pricings={pricings} />
        </>
      )}
      {!latestPricing && !pricingsLoading && (
        <div
          css={css`
            display: inline-block;
          `}
        >
          <PrimaryButton title="Create pricing" {...triggerProps}>
            Create New Pricing <EditSimpleIcon size={14}></EditSimpleIcon>
          </PrimaryButton>
        </div>
      )}
      {!pricingsLoading && (
        <PaymentPricingEdit
          pricing={latestPricing}
          modalProps={modalProps}
          closeModal={closeModal}
          editMode={!!latestPricing}
        />
      )}
    </>
  );
};
