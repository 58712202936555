import { Breadcrumb } from '@weave/design-system';
import { theme } from '@weave/theme';
import { useHistory } from 'react-router-dom';
import { PROVISION_LOCATIONS, injectParams } from '../../constants/paths';

export const PageBreadcrumb = () => {
  const history = useHistory();
  const goToSearchSlugPage = () => {
    history.push(
      injectParams(PROVISION_LOCATIONS, {
        slug: '',
      })
    );
  };
  return (
    <Breadcrumb css={{ marginBottom: theme.spacing(2), p: { color: 'inherit' } }}>
      <Breadcrumb.Path label="Search Slug" onClick={goToSearchSlugPage} />
      <Breadcrumb.Path label="Provisioning and Location Hierarchy" />
    </Breadcrumb>
  );
};
