import React, { useState } from 'react';
import { CheckIcon, Tabs } from '@weave/design-system';
import { IntakeFormHover } from '../intake-form-card/intake-form-hover';
import { format } from 'date-fns';
import { IntakeFormEmailHistoryListComponent } from '../intake-form-email-history-card/intake-form-email-history-list.component';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { LocationStatus, ProvisionerData } from '../api';

interface State {
  activeTabIndex: number;
}

type Props = {
  data: ProvisionerData;
  emailContact: string;
  locationId: string;
  refetchData: boolean;
};

export const IntakeFormTableComponent = ({
  data,
  emailContact,
  locationId,
  refetchData,
}: Props) => {
  const [tabState, setTabState] = useState<State>({ activeTabIndex: 1 });

  const handleTabChange = (activeTabIndex) => {
    setTabState({ activeTabIndex });
  };

  return (
    <>
      <Tabs
        initialTab={tabState.activeTabIndex ? 'locations' : 'email history'}
        onChange={handleTabChange}
      >
        <Tabs.Bar>
          <Tabs.Tab id="locations" controls="locations-panel">
            Locations
          </Tabs.Tab>
          <Tabs.Tab id="email history" controls="email-panel">
            Email History
          </Tabs.Tab>
        </Tabs.Bar>
        <Tabs.Panel id="locations-panel" controller="locations">
          <table css={tableStyles}>
            <thead css={tableHeadStyles}>
              <tr>
                <th>Locations</th>
                <th>Provisioned</th>
                <th>Last Sent</th>
                <th>Form Started At</th>
              </tr>
            </thead>
            {data.locations.map((locationInfo, index) => (
              <tbody key={index} css={tabWidth}>
                <tr>
                  <td data-testid="send-welcome-call-td">{locationInfo.name}</td>
                  <td>
                    {locationInfo.provisionerError ? (
                      <IntakeFormHover errorMessage={locationInfo.provisionerError} />
                    ) : (
                      <CheckIcon></CheckIcon>
                    )}
                  </td>
                  <td>
                    {locationInfo.intakeFormLastSentAt
                      ? format(
                          new Date(locationInfo.intakeFormLastSentAt),
                          'MM/dd/yyyy h:mma '
                        )
                      : '- -'}
                  </td>
                  <td>
                    {locationInfo.intakeFormStartedAt
                      ? format(
                          new Date(locationInfo.intakeFormStartedAt),
                          'MM/dd/yyyy h:mma'
                        )
                      : '- -'}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </Tabs.Panel>
        <Tabs.Panel id="email-panel" controller="email history">
          <IntakeFormEmailHistoryListComponent
            emailContact={emailContact}
            locationId={locationId}
            refetchData={refetchData}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

const tableStyles = css`
  width: 100%;
  margin-top: 24px;

  th {
    padding-bottom: ${theme.spacing(2)};
    padding-left: ${theme.spacing(3)};
  }
  td {
    padding: ${theme.spacing(1, 0, 2, 3)};
    padding-left: ${theme.spacing(3)};
  }

  th:not(:first-child),
  td:not(:first-child) {
    padding-left: ${theme.spacing(5)};
  }

  th:last-child,
  td:last-child {
    padding-right: ${theme.spacing(3)};
  }
`;

const tableHeadStyles = css`
  border-bottom: 1px solid ${theme.colors.gray400};
`;

const tabWidth = css`
  width: 100%;
`;
