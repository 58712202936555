import React, { useEffect, useMemo, useState } from 'react';
import { Text } from '@weave/design-system';
import { Button } from '@weave/platform-ui-components';
import { TableStyles } from '../../../styles/table-style';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import ReactTable from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DataCenterMoverContainer } from './data-center-mover/data-center-mover.container';
import { Page } from '../../shared';
import { prettifyTimestamp } from '../../../helpers/time';
import {
  requestRebootDevices,
  requestListDevices,
  requestDisableAllDevices,
  requestEnableAllDevices,
  selectDevicesUpdating,
  selectDevicesArray,
  requestDeleteSoftphone,
} from '../../../redux/actions/devices';
import { selectCurrentLocationId } from '../../../redux/actions/location';
import { Store } from '../../../redux/store/store.model';
import { selectHasWeaveAcl } from '../../../redux/actions/auth/auth.selectors';
import { ConfirmationModal } from '../../shared/confirmation-modal/confirmation-modal.component';
import PhoneWarningImg from '../../../images/phone-warning.png';
import { CoreACLs } from '../../../redux/actions/auth/auth.types';

export const Devices = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sipStatusModalOpen, setSipStatusModalOpen] = useState<boolean>(false);
  const [openDestroySoftphoneModal, setOpenDestroySoftphoneModal] =
    useState<boolean>(false);
  const [deviceID, setDeviceId] = useState<string>('');
  const { canEditSipProfiles, locationId } = useSelector((store: Store) => ({
    canEditSipProfiles: selectHasWeaveAcl(store, CoreACLs.SIPSTATUSWRITE),
    locationId: selectCurrentLocationId(store),
  }));

  useEffect(() => {
    dispatch(requestListDevices());
  }, [locationId]);
  const { loading: loadingDevices, devices } = useSelector(selectDevicesArray);
  const { loading: updating } = useSelector(selectDevicesUpdating);

  const filteredDevices = devices.filter(
    (device) => device.type === 'desk_phone' || device.type === 'softphone'
  );
  const devicesEnabled = filteredDevices.some((device) => !device.sipProfile?.disabled);
  const deviceIds = filteredDevices.map((device) => device.ID);

  const confirmedSoftphone = useMemo(() => {
    return filteredDevices.find((el) => el.ID === deviceID);
  }, [deviceID, filteredDevices]);

  return (
    <Page
      title="Devices"
      loading={loadingDevices || updating}
      headerActions={
        <div
          css={css`
            display: inline-block;
          `}
        >
          <DataCenterMoverContainer />
          <Button
            onClick={() => dispatch(requestRebootDevices(deviceIds))}
            css={css`
              display: inline-block !important;
              margin: 0 8px;
            `}
          >
            Resync All
          </Button>
          <Button
            css={css`
              display: inline-block !important;
            `}
            disabled={loadingDevices}
            onClick={() => dispatch(requestListDevices())}
          >
            Refresh Data
          </Button>
        </div>
      }
    >
      <ConfirmationModal
        title={`Are you sure you want to ${
          devicesEnabled ? 'disable' : 'enable'
        } all devices for this location?`}
        isOpen={sipStatusModalOpen}
        onRequestClose={() => setSipStatusModalOpen(false)}
        onConfirmClick={() =>
          dispatch(
            devicesEnabled ? requestDisableAllDevices() : requestEnableAllDevices()
          )
        }
        confirmBtnTitle={`${devicesEnabled ? 'Disable' : 'Enable'} Devices`}
        image={
          <img
            src={PhoneWarningImg}
            css={css`
              height: 100px;
              margin-bottom: 32px;
            `}
            alt="Warning"
          />
        }
        note={
          devicesEnabled
            ? 'Only disable if fraud has been detected. Doing this will disable all SIPs, kill all current calls, and turn off outbound calls, except for 911 and Weave Support. It will automatically resync the phone, updating the line keys to call Weave.'
            : 'Enabling will turn on all SIPs and allow outbound calls again. Only do this if all fraud threats have been addressed.'
        }
      />
      <ConfirmationModal
        isOpen={openDestroySoftphoneModal}
        onRequestClose={() => setOpenDestroySoftphoneModal(false)}
        onConfirmClick={() => {
          dispatch(requestDeleteSoftphone(confirmedSoftphone?.ID ?? ''));
          setDeviceId('');
          setOpenDestroySoftphoneModal(false);
        }}
        confirmBtnColor="red"
        confirmBtnTitle="Destroy"
        title={`Are you sure you want to destroy ${
          confirmedSoftphone?.name ?? 'this softphone'
        }?`}
      />
      <Text size="medium">Total: {filteredDevices.length}</Text>
      <ReactTable
        columns={[
          {
            Header: 'Name',
            id: 'name',
            accessor: (device) => device.sipProfile?.name ?? '-',
          },
          {
            Header: 'Agent',
            id: 'agent',
            accessor: (device) =>
              device.type === 'softphone'
                ? `Weave Softphone ${
                    !!device.registration ? device.registration?.user_agent : ''
                  }`
                : device.registration?.user_agent ?? '-',
            minWidth: 275,
          },
          {
            Header: 'Region',
            id: 'region',
            accessor: (device) => device.registration?.region ?? '-',
            minWidth: 75,
          },
          {
            Header: 'External IP',
            id: 'externalIP',
            accessor: (device) => device.registration?.addr ?? '-',
            minWidth: 330,
          },
          {
            Header: 'Last Connected',
            id: 'lastConnected',
            minWidth: 160,
            accessor: (device) =>
              prettifyTimestamp(device.registration?.modified ?? '') || '-',
          },
          {
            Header: 'Registered',
            id: 'registered',
            width: 100,
            accessor: (device) => {
              return (
                <span
                  css={css`
                    border-radius: 50%;
                    height: 8px;
                    width: 8px;
                    background-color: ${device.isRegistered
                      ? theme.colors.success
                      : theme.colors.error};
                  `}
                />
              );
            },
          },
          {
            Header: 'SIP Enabled',
            id: 'sipEnabled',
            width: 100,
            accessor: (device) => {
              return (
                <span
                  css={css`
                    border-radius: 50%;
                    height: 8px;
                    width: 8px;
                    background-color: ${device.sipProfile?.disabled
                      ? theme.colors.error
                      : theme.colors.success};
                  `}
                />
              );
            },
          },
          {
            Header: 'Actions',
            id: 'actions',
            width: 140,
            accessor: (device) => {
              if (device.type === 'softphone') {
                return (
                  <Button
                    color="red"
                    size="small"
                    onClick={() => {
                      setDeviceId(device.ID);
                      setOpenDestroySoftphoneModal(true);
                    }}
                  >
                    Destroy
                  </Button>
                );
              }

              if (!device.isRegistered) {
                return '';
              }

              return (
                <Button
                  fill="outline"
                  size="small"
                  css={css`
                    display: inline-block !important;
                    width: auto;
                  `}
                  onClick={() => dispatch(requestRebootDevices([device.ID]))}
                >
                  Resync Device
                </Button>
              );
            },
          },
        ]}
        data={filteredDevices}
        showPagination={false}
        loading={false}
        pageSize={filteredDevices.length || 5}
        defaultSorted={[{ id: 'name', desc: false }]}
        css={[
          TableStyles,
          css`
            margin: 24px 0;

            .rt-th {
              padding-left: 0 !important;

              .rt-resizable-header-content {
                padding-left: 0 !important;
              }
            }

            .rt-tr-group {
              cursor: pointer;

              :hover {
                background: ${theme.colors.gray100};
              }
            }
          `,
        ]}
        getTdProps={(state, row, column) => {
          if (row?.original?.ID) {
            return {
              onClick: (e) => {
                if (column.id === 'actions') {
                  // Don't redirect on action button clicks
                  return;
                }

                // Redirect to the device page on row clicks
                history.push(
                  `/location/${locationId}/phones/devices/${row?.original?.ID}`
                );
              },
            };
          }

          return {};
        }}
      />
    </Page>
  );
};
