import React, { useEffect } from 'react';
import { DropdownField, useFormField } from '@weave/design-system';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

type Props = {
  exceptions: string[];
  onSelectedException: (value: string) => void;
};

export const ExceptionDropdownItem = ({ exceptions, onSelectedException }: Props) => {
  const fieldProps = useFormField({
    type: 'dropdown',
    required: true,
  });

  useEffect(() => {
    if (fieldProps.value) {
      onSelectedException(fieldProps.value);
    }
  }, [fieldProps.value]);

  const isErrorDisplayed = fieldProps.touched && fieldProps.error;
  return (
    <div css={{ paddingBottom: theme.spacing(isErrorDisplayed ? 0 : 3.5) }}>
      <DropdownField
        {...fieldProps}
        label="Exception"
        name="exception"
        css={{ width: 350 }}
      >
        {exceptions.map((exception, index) => (
          <DropdownField.Option value={exception} key={index} css={optionStyle}>
            {exception}
          </DropdownField.Option>
        ))}
      </DropdownField>
    </div>
  );
};

const optionStyle = css`
  select-option-text {
    word-break: break-word !important;
  }
`;
