import { css } from '@emotion/core';
import { Heading } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { OnboardingTask } from '../onboarding.types';

type IntakeFormStatusProps = {
  taskData?: OnboardingTask[];
};

export const TasksInfoTable = ({ taskData }: IntakeFormStatusProps) => {
  return (
    <section css={sectionStyles}>
      <Heading level={2}>Task Status</Heading>
      <table css={tableStyles}>
        <thead>
          <tr>
            <th>Id</th>
            <th>Task Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {taskData?.map(({ id, taskName, status }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{taskName}</td>
              <td>{status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

const sectionStyles = css`
  margin-bottom: ${theme.spacing(5)};
`;

const tableStyles = css`
  width: 100%;
  tr {
    border: 1px ${theme.colors.gray300} solid;
  }
  tr:nth-child(even) {
    background-color: ${theme.colors.gray300};
  }
  td {
    word-wrap: break-word;
  }
`;
