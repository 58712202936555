export enum DevicesActionTypes {
  RequestList = 'REQUEST_LIST_DEVICES',
  RequestGet = 'REQUEST_GET_DEVICE',
  RequestUpdate = 'REQUEST_UPDATE_DEVICES',
  RequestDelete = 'REQUEST_DELETE_DEVICES',
  RequestDeleteSoftphone = 'REQUEST_DELETE_SOFTPHONE',
  RequestCreate = 'REQUEST_CREATE_DEVICES',
  RequestDisable = 'REQUEST_DISABLE_DEVICE',
  RequestDisableAll = 'REQUEST_DISABLE_ALL_DEVICES',
  RequestEnable = 'REQUEST_ENABLE_DEVICE',
  RequestEnableAll = 'REQUEST_ENABLE_ALL_DEVICES',
  Add = 'ADD_DEVICE',
  Remove = 'REMOVE_DEVICE',
  Update = 'UPDATE_DEVICE',
  UpdateMultiple = 'UPDATE_DEVICES',
  Reboot = 'REBOOT_DEVICE',
  RebootAll = 'REBOOT_ALL_DEVICES',
}

export type SipProfile = {
  ID: string;
  disabled: boolean;
  name: string;
  voicemailBoxID: string | null;
  parkRingbackEnabled: boolean;
  parkRingbackLag: number;
  internalCallerName: string;
  internalCallerNumber: string;
  externalCallerName: string;
  externalCallerNumber: string;
  username: string;
};

export interface DeviceModelNoId {
  name: string;
  isRegistered: boolean;
  macAddress: string;
  signalingProtocol: string;
  locationID: string;
  expires: number;
  type: 'desk_phone' | 'mobile_app' | 'softphone';
  unique_key: string;
  contactDirectoryID: string;
  callWaitingIndicatorDisabled: boolean;
  moveable: boolean;
  pagingEnabled: boolean;
  messageWaitingIndicatorID: string | null;
  clearLocalSettings: boolean;
  headsetMode: string;
  make: string;
  adminUsername: string;
  adminPassword: string;
  gigabitEnabled: boolean;
  sipProfile?: SipProfile;
  registration?: {
    addr: string;
    aor: string;
    callid: string;
    cseq: number;
    expires: number;
    methods: number;
    modified: number;
    path: string;
    q: number;
    received: string;
    socket: string;
    user_agent: string;
    region: string;
  };
  labels?: DeviceLabelsDataModel;
}

export type PartialDevice = { ID: string } & Partial<DeviceModelNoId>;

export interface DeviceModel extends DeviceModelNoId {
  ID: string;
}

export interface DevicePendingChangesModel {
  callWaitingIndicatorDisabled?: DeviceModel['callWaitingIndicatorDisabled'];
  clearLocalSettings?: DeviceModel['clearLocalSettings'];
  contactDirectoryID?: DeviceModel['contactDirectoryID'] | null;
  messageWaitingIndicatorID?: DeviceModel['messageWaitingIndicatorID'] | null;
  signalingProtocol?: DeviceModel['signalingProtocol'];
  sipProfile?: {
    externalCallerName?: SipProfile['externalCallerName'];
    externalCallerNumber?: SipProfile['externalCallerNumber'];
    internalCallerName?: SipProfile['internalCallerName'];
    internalCallerNumber?: SipProfile['internalCallerNumber'];
    name?: SipProfile['name'];
    parkRingbackEnabled?: SipProfile['parkRingbackEnabled'];
    parkRingbackLag?: SipProfile['parkRingbackLag'];
    voicemailBoxID?: SipProfile['voicemailBoxID'] | null;
  };
}

export interface DeviceOptionModel {
  label: string;
  value: string | null;
}
export interface DeviceLabelsDataModel {
  id: number;
  name: string;
  value: string;
}
