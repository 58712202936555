import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLocation } from 'redux/actions/location';
import { Page } from 'components/shared';
import { useCustomizationFlagV2Store } from './providers';
import { CustomizationPageTable } from './customization-page-table/customization-page-table';
import { CustomizationFlagRouterPrompt } from './components/customization-flag-router-prompt';
import { CustomizationPageHeaderAction } from './components/customization-page-header-action';
import {
  useManageCountryCodeVerified,
  useManageProhibitedCustomizationFlags,
} from './hooks';
import { CustomizationFlagNoEditAccessAlert } from './components/customization-flag-no-edit-access-alert';

const PAGE_TITLE = 'Customizations';

export const CustomizationPageContent = () => {
  const locationDetails = useSelector(selectCurrentLocation);
  const { canEdit } = useCustomizationFlagV2Store();
  const { manageProhibitedCustomizationFlags } = useManageProhibitedCustomizationFlags();
  const { manageCountryCodeVerified } = useManageCountryCodeVerified();

  useEffect(() => {
    // reset the prohibited and countryCode context when location ID gets changed
    if (locationDetails?.LocationID) {
      manageProhibitedCustomizationFlags([]);
      manageCountryCodeVerified(null);
    }
  }, [locationDetails?.LocationID]);

  return (
    <>
      <CustomizationFlagRouterPrompt />
      {!canEdit && <CustomizationFlagNoEditAccessAlert />}
      <Page title={PAGE_TITLE} headerActions={<CustomizationPageHeaderAction />}>
        <hr />
        <CustomizationPageTable />
      </Page>
    </>
  );
};
