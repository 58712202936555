import { AudioScrubber, SpinningLoader, Text } from '@weave/design-system';
import { useQuery } from 'react-query';
import { store } from '../../../../redux/store/store';
import { selectAuthToken } from '../../../../redux/actions/auth/auth.selectors';

export const CachedAudioScrubber = ({
  onPlay,
  customWidth,
  filePath,
  mediaId,
  singlePlayer,
  tabIndex = 0,
}: {
  onPlay?: () => void;
  customWidth?: number;
  filePath: string;
  mediaId: string;
  singlePlayer?: boolean;
  tabIndex?: number;
}) => {
  const state: any = store.getState();
  const weaveToken = selectAuthToken(state);

  /**
   * Why fetch the resource instead of loading it directly into the audio component?
   *
   * Doing this allows us to cache the resource, so that the audio component doesn't re-fetch it every time it's rendered.
   */
  const { data, isLoading, isError } = useQuery({
    queryKey: ['cached-audio', mediaId],
    queryFn: async () => {
      const url = new URL(filePath);

      const response = await fetch(
        filePath,
        /**
         * We're moving away from using the token in the URL with new endpoints.
         * If the URL already has a token, don't add another one.
         */
        url.searchParams.has('token')
          ? {}
          : { headers: { Authorization: `Bearer ${weaveToken}` } }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch audio');
      }
      return response.blob().then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        return objectURL;
      });
    },
    enabled: !!mediaId,
    staleTime: Infinity,
  });

  if (isError) {
    return <Text color="error">{'Error loading audio'}</Text>;
  }

  if (isLoading) {
    return <SpinningLoader size="small" />;
  }

  return data ? (
    <AudioScrubber
      // autoSize
      onPlay={onPlay}
      singlePlayer={singlePlayer}
      src={data}
      css={{ maxWidth: `${customWidth}px` ?? '100%' }}
      // tabIndex={tabIndex}
    />
  ) : null;
};
