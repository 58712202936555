import axios from 'axios';
import { OktaAuth } from '@okta/okta-auth-js';

import { CustomAxios, getResponseData } from '../../axios';
import { SignInUser } from './auth.types';
import {
  REACT_APP_API_URL,
  REACT_APP_OKTA_ISSUER,
  REACT_APP_OKTA_CLIENT_ID,
} from '../../../config/app';
import {
  AuthService,
  ExchangeTokenResponse,
} from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';

const SIGN_IN_OKTA_URL = '/auth/oktaexchange';
const SIGN_IN_GOOGLE_URL = `/support/login`;
const OKTA_URL = `${REACT_APP_OKTA_ISSUER}/api/v1/sessions/me`;
const contentType = { 'Content-Type': 'application/json' };
const issuerHint = 'weaveworkforce';

const APIfetchNoAuth = (): ((url: string, reqInit: RequestInit) => Promise<Response>) => {
  return (url: string, reqInit: RequestInit) => {
    const API_URL = REACT_APP_API_URL.endsWith('/')
      ? REACT_APP_API_URL.slice(0, -1)
      : REACT_APP_API_URL;
    return fetch(`${API_URL}${url}`, reqInit).then((res) => res.json());
  };
};

export const AuthApi = {
  POST: {
    authenticateOkta: (token: string) => {
      return AuthService.ExchangeToken(
        APIfetchNoAuth() as (
          url: string,
          reqInit: RequestInit
        ) => Promise<ExchangeTokenResponse>,
        {
          tokenId: token,
          expiration: '240',
          source: REACT_APP_OKTA_CLIENT_ID,
        }
      ).then((res) => {
        return res.token;
      });
    },
    authenticateGoogle: (credentials: SignInUser) =>
      CustomAxios.post(SIGN_IN_GOOGLE_URL, { ...credentials }).then(getResponseData),
  },
  GET: {
    session: () =>
      axios
        .get(OKTA_URL, {
          withCredentials: true,
          headers: contentType,
        })
        .then((res) => res.data.status === 'ACTIVE'),

    oktaToken: (oktaAuth: OktaAuth) =>
      oktaAuth.token
        .getWithoutPrompt()
        .then<{ id_token?: string; email?: string; access_token?: string }>(
          ({ tokens }) => ({
            email: tokens.idToken?.claims.email,
            id_token: tokens.idToken?.idToken,
            access_token: tokens.accessToken?.accessToken,
          })
        ),

    oktaTokenAlternative: (oktaAuth: OktaAuth) =>
      oktaAuth.token
        .parseFromUrl()
        .then<{ id_token?: string; email?: string; access_token?: string }>(
          ({ tokens }) => ({
            email: tokens.idToken?.claims.email,
            id_token: tokens.idToken?.idToken,
            access_token: tokens.accessToken?.accessToken,
          })
        ),
  },
  DELETE: {
    session: () =>
      axios
        .delete(OKTA_URL, {
          withCredentials: true,
          headers: contentType,
        })
        .then((res) => res),
  },
};
