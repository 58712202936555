import { useDependentMetricData } from './use-dependent-metric-data';
import {
  inboundCallMetric,
  officeHoursMetric,
  outboundCallsMetric,
  phonesRegisteredMetric,
  totalCallsMetric,
  voicemailGreetingsMetric,
} from './metrics/phones';
import {
  appointmentRemindersMetric,
  eyewearReminderMetric,
  inboundMessagesMetric,
  outboundMessagesMetric,
  recallReminderMetric,
  usersAddedMetric,
} from './metrics/software';
import { DependencyDataKeys, Result } from './handoff-metrics.types';
import {
  APPOINTMENT_WRITEBACKS_PASS_VALUE,
  appointmentConfirmationMappingMetric,
  appointmentWritebacksMetric,
  syncappCountMetric,
  syncappHealthMetric,
  UPSELLS_APPOINTMENT_WRITEBACKS_PASS_VALUE,
  writebackConfirmationMappingMetric,
} from './metrics/data-sync';
import { OfficeConfigurations } from '../../../../../../../redux/actions/customization/customization.action';
import {
  faxNumberSubmittedPortMetric,
  faxReceivedMetric,
  missedCallSmsMetric,
  reviewsSettingsMetric,
} from './metrics/premium-features';
import { LocationModel } from '../../../../../../../redux/actions/location';
import { LocationInformationForm } from '../forms/location-information-form';
import { UpsellForm } from '../forms/upsell-form';
import { HandoffSnapshot } from '../../../../handoff-hub.types';
import {
  digitalFormsCountMetric,
  digitalFormsDigitizedFormMetric,
} from './metrics/digital-forms';
import {
  onlineSchedulingHealthMetric,
  onlineSchedulingStepsAppointmentMetric,
  onlineSchedulingStepsCustomFieldsMetric,
  onlineSchedulingStepsCustomizationMetric,
  onlineSchedulingStepsExceptionsMetric,
  onlineSchedulingStepsOfficeHoursMetric,
  onlineSchedulingStepsProvidersMetric,
  onlineSchedulingStepsWritebackMetric,
  customizationsFeaturesMetric,
} from './metrics/online-scheduling';

import { LocationFeatureV2 } from '../../../../../../../models/location-feature.model';
import { isCustomizationFeatureEnabled } from './handoff-metrics-helpers';
import { tenDlcStatusMetric } from './metrics/ten-dlc';
import {
  fullPaymentsWithTerminalMetric,
  paymentsStripeAccountMetric,
} from './metrics/payments';
import { csvUploadMetric } from './metrics/csv-upload';
import { GetSalesforceBundleNameResponse } from '@weave/schema-gen-ts/dist/schemas/package-service/v1/package.pb';

// IMPORTANT: These enum values should not be changed once they have been released to prod
// since there can potentially be handoff drafts that depend on these types.
export enum HandoffTypes {
  OnboardingHandoff = 'onboarding-handoff-v2',
  UpsellCoreToPlus = 'upsell-core-to-plus',
  UpsellAddingPhoneService = 'upsell-adding-phone-service',
  UpsellDigitalForms = 'upsell-digital-forms',
  UpsellOnlineScheduling = 'upsell-online-scheduling',
  UpsellElitePlusBundle = 'upsell-elite-plus-bundle',
  UpsellEliteCoreBundle = 'upsell-elite-core-bundle',
  UpsellProPlusBundle = 'upsell-pro-plus-bundle',
  UpsellProCoreBundle = 'upsell-pro-core-bundle',
  UpsellEssentialsPlusBundle = 'upsell-essentials-plus-bundle',
  UpsellEssentialsCoreBundle = 'upsell-essentials-core-bundle',
}

export enum DeprecatedHandoffTypes {
  OnboardingHandoffV1 = 'onboarding-handoff',
  UpsellPhonesAddonV1 = 'upsell-phones-addon',
  OldHubHandoff = 'Onboarding Handoff - (Old Hub)',
  UpsellPremiumFeatures = 'upsell-premium-features',
  UpsellAcquisition = 'upsell-acquisition',
  UpsellRetention = 'upsell-retention',
  SmartUpsell = 'smart-onboarding-handoff',
}

export type AllHandoffTypes = HandoffTypes | DeprecatedHandoffTypes;

export const handoffTypesDisplayNamesMap: Record<AllHandoffTypes, string> = {
  [HandoffTypes.OnboardingHandoff]: 'Onboarding Handoff',
  [HandoffTypes.UpsellCoreToPlus]: 'Upsell - Core to Plus',
  [HandoffTypes.UpsellAddingPhoneService]: 'Upsell - Adding Phone Service',
  [HandoffTypes.UpsellDigitalForms]: 'Upsell - Digital Forms',
  [HandoffTypes.UpsellOnlineScheduling]: 'Upsell - Online Scheduling',
  [HandoffTypes.UpsellElitePlusBundle]: 'Upsell - Elite Plus Bundle',
  [HandoffTypes.UpsellEliteCoreBundle]: 'Upsell - Elite Core Bundle',
  [HandoffTypes.UpsellProPlusBundle]: 'Upsell - Pro Plus Bundle',
  [HandoffTypes.UpsellProCoreBundle]: 'Upsell - Pro Core Bundle',
  [HandoffTypes.UpsellEssentialsPlusBundle]: 'Upsell - Essential Plus Bundle',
  [HandoffTypes.UpsellEssentialsCoreBundle]: 'Upsell - Essential Core Bundle',

  // Deprecated
  [DeprecatedHandoffTypes.OnboardingHandoffV1]: 'Onboarding Handoff V1',
  [DeprecatedHandoffTypes.UpsellPhonesAddonV1]: 'Upsell Phones Addon V1',
  [DeprecatedHandoffTypes.UpsellPremiumFeatures]: 'upsell-premium-features',
  [DeprecatedHandoffTypes.SmartUpsell]: 'smart-onboarding-handoff',
  [DeprecatedHandoffTypes.UpsellRetention]: 'Upsell - Retention',
  [DeprecatedHandoffTypes.UpsellAcquisition]: 'Upsell - Acquisition',
  [DeprecatedHandoffTypes.OldHubHandoff]: 'Onboarding Handoff - (Old Hub)',
};

export const handoffTypesDisplayValuesMap: Record<AllHandoffTypes, string> = {
  [HandoffTypes.OnboardingHandoff]: 'Onboarding Handoff',
  [HandoffTypes.UpsellCoreToPlus]: 'Upsell - Core to Plus',
  [HandoffTypes.UpsellAddingPhoneService]: 'Upsell - Adding Phone Service',
  [HandoffTypes.UpsellDigitalForms]: 'Upsell - Digital Forms',
  [HandoffTypes.UpsellOnlineScheduling]: 'Upsell - Online Scheduling',
  [HandoffTypes.UpsellElitePlusBundle]: 'Upsell - Elite Plus Bundle',
  [HandoffTypes.UpsellEliteCoreBundle]: 'Upsell - Elite Core Bundle',
  [HandoffTypes.UpsellProPlusBundle]: 'Upsell - Pro Plus Bundle',
  [HandoffTypes.UpsellProCoreBundle]: 'Upsell - Pro Core Bundle',
  [HandoffTypes.UpsellEssentialsPlusBundle]: 'Upsell - Essential Plus Bundle',
  [HandoffTypes.UpsellEssentialsCoreBundle]: 'Upsell - Essential Core Bundle',

  // Deprecated
  [DeprecatedHandoffTypes.OnboardingHandoffV1]: 'Onboarding Handoff V1',
  [DeprecatedHandoffTypes.UpsellPhonesAddonV1]: 'Upsell Phones Addon V1',
  [DeprecatedHandoffTypes.UpsellPremiumFeatures]: 'upsell-premium-features',
  [DeprecatedHandoffTypes.SmartUpsell]: 'smart-onboarding-handoff',
  [DeprecatedHandoffTypes.UpsellRetention]: 'Upsell - Retention',
  [DeprecatedHandoffTypes.UpsellAcquisition]: 'Upsell - Acquisition',
  [DeprecatedHandoffTypes.OldHubHandoff]: 'Onboarding Handoff - (Old Hub)',
};

export type HandoffTypeMetric = {
  testCriteriaFn: () => string;
  dependencyDataKeys: DependencyDataKeys[];
  expectedValue: number | string;
  resultFn: (dependentData: Partial<ReturnType<typeof useDependentMetricData>>) => Result;
  actualValueFn: (
    dependentData: Partial<ReturnType<typeof useDependentMetricData>>
  ) => string | number;
  // If true, this metric should be skipped and not included in the overall metric results.
  shouldSkip?: (locationData: {
    officeConfigurations: OfficeConfigurations;
    currentLocation: LocationModel;
    customizationFeatures: LocationFeatureV2[];
  }) => boolean;
  exceptions?: string[];
};

export type SubsectionData = {
  name: string;
  shouldSkip?: (locationData: {
    officeConfigurations: OfficeConfigurations;
    currentLocation: LocationModel;
    customizationFeatures: LocationFeatureV2[];
  }) => boolean;
  metrics: HandoffTypeMetric[];
};

export type HandoffType = {
  sections: {
    name: string;
    shouldSkip?: (locationData: {
      officeConfigurations: OfficeConfigurations;
      currentLocation: LocationModel;
      customizationFeatures: LocationFeatureV2[];
      salesforceBundleData: GetSalesforceBundleNameResponse;
    }) => boolean;
    subsections: SubsectionData[];
  }[];
  FormComponent: (props: FormComponentProps) => JSX.Element;
};

// The props to be used for all form components. We define it here and use it in the
// HandoffType type definition so that all FormComponent components use the same props
// type for the sake of uniformity.
export type FormComponentProps = {
  state: Partial<HandoffSnapshot>;
  disableButtons: boolean;
  onBackClick: (updates: Partial<HandoffSnapshot>) => void;
  onSaveAndClose: (updates: Partial<HandoffSnapshot>) => void;
  onSubmitClick: (updates: Partial<HandoffSnapshot>) => void;
};

type HandoffTypesData = {
  [key in HandoffTypes]: HandoffType;
};

export const handoffTypesData: HandoffTypesData = {
  // ONBOARDING HANDOFF TYPE
  [HandoffTypes.OnboardingHandoff]: {
    FormComponent: LocationInformationForm,
    sections: [
      {
        name: 'Phones',
        // This section should be skipped if isSoftwareOnly
        shouldSkip: ({ officeConfigurations }) => officeConfigurations.isSoftwareOnly,
        subsections: [
          {
            name: 'Calls Working',
            metrics: [inboundCallMetric, outboundCallsMetric, totalCallsMetric],
          },
          {
            name: 'Phones Customized',
            metrics: [voicemailGreetingsMetric, officeHoursMetric],
          },
          {
            name: 'Phones Registered',
            metrics: [phonesRegisteredMetric],
          },
        ],
      },
      {
        name: 'Software',
        // This section should be skipped if isPhonesOnly
        shouldSkip: ({ officeConfigurations }) => officeConfigurations.isPhonesOnly,
        subsections: [
          {
            name: 'Manual Messaging',
            metrics: [inboundMessagesMetric, outboundMessagesMetric],
          },
          {
            name: 'New Users Added',
            metrics: [usersAddedMetric],
          },
          {
            name: 'Auto Messaging On',
            // This subsection should only show if location isIntegrated
            shouldSkip: ({ officeConfigurations, customizationFeatures }) =>
              !officeConfigurations.isIntegrated ||
              !isCustomizationFeatureEnabled(
                'Online Schedule Appointment Writebacks',
                customizationFeatures
              ),
            metrics: [
              recallReminderMetric,
              appointmentRemindersMetric,
              eyewearReminderMetric,
            ],
          },
        ],
      },
      {
        // This section should be skipped if isNotIntegrated
        name: 'Data Sync',
        shouldSkip: ({ officeConfigurations }) => !officeConfigurations.isIntegrated,
        subsections: [
          {
            name: 'Data Sync Installed',
            metrics: [syncappCountMetric, syncappHealthMetric],
          },
          {
            name: 'Writebacks',
            shouldSkip: ({ customizationFeatures }) => {
              return (
                !isCustomizationFeatureEnabled(
                  'Online Schedule Appointment Writebacks',
                  customizationFeatures
                ) &&
                !isCustomizationFeatureEnabled(
                  'Appt Confirmations - writebacks',
                  customizationFeatures
                )
              );
            },
            metrics: [
              appointmentWritebacksMetric(APPOINTMENT_WRITEBACKS_PASS_VALUE),
              writebackConfirmationMappingMetric,
            ],
          },
          {
            name: 'Status Mapping Set',
            metrics: [appointmentConfirmationMappingMetric],
          },
        ],
      },
      {
        name: '10 DLC',
        subsections: [
          {
            name: '10 DLC Status',
            metrics: [tenDlcStatusMetric],
          },
        ],
      },

      {
        name: 'Payments',
        shouldSkip: ({ customizationFeatures }) => {
          return !isCustomizationFeatureEnabled('Weave Pay', customizationFeatures);
        },
        subsections: [
          {
            name: 'Stripe account set up',
            metrics: [paymentsStripeAccountMetric],
          },
          {
            name: 'Payments with terminal',
            shouldSkip: ({ customizationFeatures }) => {
              return !isCustomizationFeatureEnabled(
                'Payment Terminals',
                customizationFeatures
              );
            },
            metrics: [fullPaymentsWithTerminalMetric],
          },
        ],
      },
      {
        name: 'Digital Forms',
        shouldSkip: ({ customizationFeatures }) => {
          return !isCustomizationFeatureEnabled('Forms', customizationFeatures);
        },
        subsections: [
          {
            name: 'Digital forms submitted',
            metrics: [digitalFormsDigitizedFormMetric],
          },
          {
            name: 'Form created',
            metrics: [digitalFormsCountMetric],
          },
        ],
      },

      {
        name: 'Online Schedule',
        shouldSkip: ({ customizationFeatures }) => {
          return !isCustomizationFeatureEnabled(
            'Online Scheduling',
            customizationFeatures
          );
        },
        subsections: [
          {
            name: 'Online Schedule Health',
            metrics: [onlineSchedulingHealthMetric],
          },
        ],
      },
      {
        name: 'CSV Upload',
        shouldSkip: ({ salesforceBundleData }) => {
          return (
            salesforceBundleData.bundleDisplayName !== 'Weave Core' &&
            salesforceBundleData.bundleDisplayName !== 'Weave Core Elite' &&
            salesforceBundleData.bundleDisplayName !== 'Weave Core Pro'
          );
        },
        subsections: [
          {
            name: 'CSV Uploaded',
            metrics: [csvUploadMetric],
          },
        ],
      },
      {
        name: 'Additional Features',
        // This section should be skipped if not hasPremiumFeatures
        shouldSkip: ({ officeConfigurations }) =>
          !officeConfigurations.hasPremiumFeatures,
        subsections: [
          {
            name: 'Reviews Enabled',
            shouldSkip: ({ customizationFeatures }) => {
              return !isCustomizationFeatureEnabled(
                'Reviews Home Icon',
                customizationFeatures
              );
            },
            metrics: [reviewsSettingsMetric],
          },
          {
            name: 'Missed Call SMS',
            // This subsection should be skipped if isSoftwareOnly
            shouldSkip: ({ officeConfigurations, customizationFeatures }) => {
              return (
                officeConfigurations.isSoftwareOnly ||
                !isCustomizationFeatureEnabled('Missed call text', customizationFeatures)
              );
            },
            metrics: [missedCallSmsMetric],
          },
          {
            name: 'Fax',
            shouldSkip: ({ customizationFeatures }) => {
              return !isCustomizationFeatureEnabled('Fax', customizationFeatures);
            },
            metrics: [faxNumberSubmittedPortMetric, faxReceivedMetric],
          },
        ],
      },
    ],
  },

  // UPSELL ELITE PLUS BUNDLE
  [HandoffTypes.UpsellElitePlusBundle]: {
    FormComponent: UpsellForm,
    sections: [
      {
        name: 'Digital Forms',
        subsections: [
          {
            name: 'Digital forms submitted',
            metrics: [digitalFormsDigitizedFormMetric],
          },
          {
            name: 'Form created',
            metrics: [digitalFormsCountMetric],
          },
        ],
      },
      {
        name: 'Online Scheduling',
        subsections: [
          {
            name: 'Online Schedule Health',
            metrics: [onlineSchedulingHealthMetric],
          },
          {
            name: 'Online Scheduling Custimzation Flag',
            metrics: [customizationsFeaturesMetric],
          },
        ],
      },
      {
        name: 'Data Sync',
        subsections: [
          {
            name: 'Data Sync Installed',
            metrics: [syncappCountMetric, syncappHealthMetric],
          },
          {
            name: 'Writebacks',
            metrics: [
              appointmentWritebacksMetric(UPSELLS_APPOINTMENT_WRITEBACKS_PASS_VALUE),
              writebackConfirmationMappingMetric,
            ],
          },
          {
            name: 'Status Mapping Set',
            metrics: [appointmentConfirmationMappingMetric],
          },
          {
            name: 'Auto Messaging On',
            metrics: [recallReminderMetric, appointmentRemindersMetric],
          },
        ],
      },
    ],
  },

  // UPSELL - ELITE CORE BUNDLE
  [HandoffTypes.UpsellEliteCoreBundle]: {
    FormComponent: UpsellForm,
    sections: [
      {
        name: 'Software',
        subsections: [
          {
            name: 'Manual Messaging',
            metrics: [inboundMessagesMetric, outboundMessagesMetric],
          },
          {
            name: 'New Users Added',
            metrics: [usersAddedMetric],
          },
          {
            name: 'Reviews Enabled',
            shouldSkip: ({ customizationFeatures }) => {
              return !isCustomizationFeatureEnabled(
                'Reviews Home Icon',
                customizationFeatures
              );
            },
            metrics: [reviewsSettingsMetric],
          },
          {
            name: 'Missed Call SMS',
            // This subsection should be skipped if isSoftwareOnly
            shouldSkip: ({ officeConfigurations, customizationFeatures }) => {
              return (
                officeConfigurations.isSoftwareOnly ||
                !isCustomizationFeatureEnabled('Missed call text', customizationFeatures)
              );
            },
            metrics: [missedCallSmsMetric],
          },
        ],
      },
      {
        name: 'Data Sync',
        subsections: [
          {
            name: 'Data Sync Installed',
            metrics: [syncappCountMetric, syncappHealthMetric],
          },
          {
            name: 'Writebacks',
            metrics: [
              appointmentWritebacksMetric(UPSELLS_APPOINTMENT_WRITEBACKS_PASS_VALUE),
              writebackConfirmationMappingMetric,
            ],
          },
          {
            name: 'Status Mapping Set',
            metrics: [appointmentConfirmationMappingMetric],
          },
          {
            name: 'Auto Messaging On',
            metrics: [recallReminderMetric, appointmentRemindersMetric],
          },
        ],
      },
    ],
  },

  // UPSELL - PRO PLUS BUNDLE

  [HandoffTypes.UpsellProPlusBundle]: {
    FormComponent: UpsellForm,
    sections: [
      {
        name: 'Software',
        subsections: [
          {
            name: 'Manual Messaging',
            metrics: [inboundMessagesMetric, outboundMessagesMetric],
          },
          {
            name: 'Reviews Enabled',
            shouldSkip: ({ customizationFeatures }) => {
              return !isCustomizationFeatureEnabled(
                'Reviews Home Icon',
                customizationFeatures
              );
            },
            metrics: [reviewsSettingsMetric],
          },
          {
            name: 'Missed Call SMS',
            // This subsection should be skipped if isSoftwareOnly
            shouldSkip: ({ officeConfigurations, customizationFeatures }) => {
              return (
                officeConfigurations.isSoftwareOnly ||
                !isCustomizationFeatureEnabled('Missed call text', customizationFeatures)
              );
            },
            metrics: [missedCallSmsMetric],
          },
        ],
      },
    ],
  },

  // Upsell - Pro Core Bundle
  [HandoffTypes.UpsellProCoreBundle]: {
    FormComponent: UpsellForm,
    sections: [
      {
        name: 'Software',
        subsections: [
          {
            name: 'Manual Messaging',
            metrics: [inboundMessagesMetric, outboundMessagesMetric],
          },
          {
            name: 'New Users Added',
            metrics: [usersAddedMetric],
          },
          {
            name: 'Reviews Enabled',
            shouldSkip: ({ customizationFeatures }) => {
              return !isCustomizationFeatureEnabled(
                'Reviews Home Icon',
                customizationFeatures
              );
            },
            metrics: [reviewsSettingsMetric],
          },
          {
            name: 'Missed Call SMS',
            // This subsection should be skipped if isSoftwareOnly
            shouldSkip: ({ officeConfigurations, customizationFeatures }) => {
              return (
                officeConfigurations.isSoftwareOnly ||
                !isCustomizationFeatureEnabled('Missed call text', customizationFeatures)
              );
            },
            metrics: [missedCallSmsMetric],
          },
        ],
      },
    ],
  },

  // Upsell - Essentials Plus Bundle
  [HandoffTypes.UpsellEssentialsPlusBundle]: {
    FormComponent: UpsellForm,
    sections: [
      {
        name: 'Software',
        subsections: [
          {
            name: 'Manual Messaging',
            metrics: [inboundMessagesMetric, outboundMessagesMetric],
          },
          {
            name: 'New Users Added',
            metrics: [usersAddedMetric],
          },
          {
            name: 'Reviews Enabled',
            shouldSkip: ({ customizationFeatures }) => {
              return !isCustomizationFeatureEnabled(
                'Reviews Home Icon',
                customizationFeatures
              );
            },
            metrics: [reviewsSettingsMetric],
          },
          {
            name: 'Missed Call SMS',
            // This subsection should be skipped if isSoftwareOnly
            shouldSkip: ({ officeConfigurations, customizationFeatures }) => {
              return (
                officeConfigurations.isSoftwareOnly ||
                !isCustomizationFeatureEnabled('Missed call text', customizationFeatures)
              );
            },
            metrics: [missedCallSmsMetric],
          },
        ],
      },
      {
        name: 'Data Sync',
        subsections: [
          {
            name: 'Data Sync Installed',
            metrics: [syncappCountMetric, syncappHealthMetric],
          },
          {
            name: 'Writebacks',
            metrics: [
              appointmentWritebacksMetric(UPSELLS_APPOINTMENT_WRITEBACKS_PASS_VALUE),
              writebackConfirmationMappingMetric,
            ],
          },
          {
            name: 'Status Mapping Set',
            metrics: [appointmentConfirmationMappingMetric],
          },
          {
            name: 'Auto Messaging On',
            metrics: [recallReminderMetric, appointmentRemindersMetric],
          },
        ],
      },
    ],
  },

  // Upsell - Essentials Core Bundle

  [HandoffTypes.UpsellEssentialsCoreBundle]: {
    FormComponent: UpsellForm,
    sections: [],
  },

  // UPSELL - CORE TO PLUS HANDOFF TYPE
  [HandoffTypes.UpsellCoreToPlus]: {
    FormComponent: UpsellForm,
    sections: [
      {
        name: 'Data Sync',
        subsections: [
          {
            name: 'Data Sync Installed',
            metrics: [syncappCountMetric, syncappHealthMetric],
          },
          {
            name: 'Writebacks',
            metrics: [
              appointmentWritebacksMetric(UPSELLS_APPOINTMENT_WRITEBACKS_PASS_VALUE),
              writebackConfirmationMappingMetric,
            ],
          },
          {
            name: 'Status Mapping Set',
            metrics: [appointmentConfirmationMappingMetric],
          },
        ],
      },
      {
        name: 'Software',
        subsections: [
          {
            name: 'Auto Messaging On',
            metrics: [
              recallReminderMetric,
              appointmentRemindersMetric,
              eyewearReminderMetric,
            ],
          },
        ],
      },
    ],
  },

  // UPSELL - PHONES ADDON HANDOFF TYPE
  [HandoffTypes.UpsellAddingPhoneService]: {
    FormComponent: UpsellForm,
    sections: [
      {
        name: 'Phones',
        subsections: [
          {
            name: 'Calls Working',
            metrics: [inboundCallMetric, outboundCallsMetric, totalCallsMetric],
          },
          {
            name: 'Phones Customized',
            metrics: [voicemailGreetingsMetric, officeHoursMetric],
          },
          {
            name: 'Phones Registered',
            metrics: [phonesRegisteredMetric],
          },
        ],
      },
    ],
  },

  // UPSELL - DIGITAL FORMS TYPE
  [HandoffTypes.UpsellDigitalForms]: {
    FormComponent: UpsellForm,
    sections: [
      {
        name: 'Digital Forms',
        subsections: [
          {
            name: 'Digital forms submitted',
            metrics: [digitalFormsDigitizedFormMetric],
          },
          {
            name: 'Form created',
            metrics: [digitalFormsCountMetric],
          },
        ],
      },
    ],
  },
  // UPSELL - ONLINE SCHEDULING
  [HandoffTypes.UpsellOnlineScheduling]: {
    FormComponent: UpsellForm,
    sections: [
      {
        name: 'Online Schedule',
        subsections: [
          {
            name: 'Online Schedule Health',
            metrics: [onlineSchedulingHealthMetric],
          },
          {
            name: 'Online Schedule Steps',
            metrics: [
              onlineSchedulingStepsAppointmentMetric,
              onlineSchedulingStepsCustomFieldsMetric,
              onlineSchedulingStepsCustomizationMetric,
              onlineSchedulingStepsExceptionsMetric,
              onlineSchedulingStepsOfficeHoursMetric,
              onlineSchedulingStepsProvidersMetric,
              onlineSchedulingStepsWritebackMetric,
            ],
          },
        ],
      },
    ],
  },
};
