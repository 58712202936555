import { UsageType } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/phone_number.pb';
import { AxiosError } from 'axios';
import { formatPhoneFaxNumber, formatPhoneNumber } from 'helpers/format-phone';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
import { getPhoneNumbers, portingSchemaApi, uploadPhoneFile } from './porting.api';
import {
  CancelPortInOrderRequest,
  ClonePortingDataRequest,
  ClonePortingDataResponse,
  CreateSalesforcePortOrderRequest,
  CreateSalesforcePortOrderResponse,
  DeletePortOrderDocumentRequest,
  DeletePortOrderDocumentResponse,
  ExtendedRelationalPhoneNumber,
  GetAvailableFOCDatesRequest,
  GetAvailableFOCDatesResponse,
  GetPortingValidationsByPortingDataIDRequest,
  GetPortingValidationsByPortingDataIDResponse,
  ListPhoneNumbersRequest,
  ListPhoneNumbersResponse,
  NumberType,
  MediaType as OldMediaType,
  PortingMediaType,
  UpdatePortOrderDocumentResponse,
  UploadPortOrderDocumentResponse,
} from './porting.types';

const PortingQueryKeys = {
  baseQueryKey: ['porting'],
  getPhoneNumbers: (request: ListPhoneNumbersRequest) => [
    ...PortingQueryKeys.baseQueryKey,
    'get-phone-numbers',
    ...(request.locationIds || []),
  ],
  getAvailableFOCDates: (request: GetAvailableFOCDatesRequest) => [
    ...PortingQueryKeys.baseQueryKey,
    'get-available-foc-dates',
    request.portingDataId,
    request.provider,
  ],
  getPortingValidationData: (request: GetPortingValidationsByPortingDataIDRequest) => [
    ...PortingQueryKeys.baseQueryKey,
    request.portingDataId,
  ],
};

const useGetPhoneNumbers = (
  request: ListPhoneNumbersRequest,
  options?: UseQueryOptions<
    ListPhoneNumbersResponse,
    unknown,
    ExtendedRelationalPhoneNumber[]
  >
) => {
  return useQuery({
    queryKey: PortingQueryKeys.getPhoneNumbers(request),
    queryFn: async () => (await getPhoneNumbers(request)).data,
    retry: false,
    ...options,
    enabled: !!request.locationIds?.length && (options?.enabled ?? true),
    select: (data) => {
      return (data.phoneNumbers ?? [])?.map((phoneNumberData) => {
        const phoneNumber =
          phoneNumberData?.number?.phoneNumber?.phoneNumber?.nationalNumber?.toString() ??
          '';
        const type = phoneNumberData?.number?.faxProvider?.id
          ? NumberType.FAX
          : NumberType.PHONE;

        const usageType =
          phoneNumberData?.number?.phoneNumber?.usageType ||
          UsageType.USAGE_TYPE_UNSPECIFIED;

        const formattedPhoneNumber =
          type === NumberType.FAX
            ? formatPhoneFaxNumber(phoneNumber)
            : formatPhoneNumber(phoneNumber);
        return {
          ...phoneNumberData,
          formattedPhoneNumber,
          phoneNumber,
          type,
          usageType,
        };
      });
    },
  });
};

const useGetAvailableFOCDates = (
  request: GetAvailableFOCDatesRequest,
  options?: UseQueryOptions<
    GetAvailableFOCDatesResponse,
    AxiosError<any>,
    GetAvailableFOCDatesResponse['focDates']
  >
) => {
  return useQuery({
    queryKey: PortingQueryKeys.getAvailableFOCDates(request),
    queryFn: () => portingSchemaApi.fetchAvailableFOCDates(request),
    retry: false,
    ...options,
    enabled: !!request.portingDataId && !!request.provider && (options?.enabled ?? true),
    select: (data) => data?.focDates ?? [],
  });
};

const useGetPortingValidationData = (
  request: GetPortingValidationsByPortingDataIDRequest,
  options?: UseQueryOptions<
    GetPortingValidationsByPortingDataIDResponse,
    AxiosError<any>,
    GetPortingValidationsByPortingDataIDResponse['portingValidations']
  >
) => {
  return useQuery({
    queryKey: PortingQueryKeys.getPortingValidationData(request),
    queryFn: () => portingSchemaApi.fetchPortingValidationData(request),
    retry: false,
    ...options,
    enabled: !!request.portingDataId && (options?.enabled ?? true),
    select: (data) => data?.portingValidations ?? [],
  });
};

const useCreateSFPortOrder = (
  options?: UseMutationOptions<
    CreateSalesforcePortOrderResponse,
    AxiosError<any>,
    CreateSalesforcePortOrderRequest
  >
) => {
  return useMutation({
    mutationFn: portingSchemaApi.createSFPortOrder,
    ...options,
  });
};

const useCancelPortOrder = (
  options?: UseMutationOptions<unknown, AxiosError<any>, CancelPortInOrderRequest>
) => {
  return useMutation({
    mutationFn: portingSchemaApi.cancelPortInOrder,
    ...options,
  });
};

const useClonePortOrder = (
  options?: UseMutationOptions<
    ClonePortingDataResponse,
    AxiosError<any>,
    ClonePortingDataRequest
  >
) => {
  return useMutation({
    mutationFn: portingSchemaApi.clonePortInData,
    ...options,
  });
};

type UploadPortDocumentRequest = {
  file: File;
  type: PortingMediaType | OldMediaType;
  portId: string;
};

const useUploadPortDocument = (
  options?: UseMutationOptions<
    UploadPortOrderDocumentResponse,
    AxiosError<any>,
    UploadPortDocumentRequest
  >
) => {
  return useMutation({
    mutationFn: async (request: UploadPortDocumentRequest) => {
      const formData = new FormData();
      formData.append('file', request.file);
      formData.append('type', request.type);
      const mediaData = await uploadPhoneFile(formData);
      return portingSchemaApi.uploadPortOrderDocument({
        contentType: request.file.type,
        mediaData: {
          portingDataId: request.portId,
          mediaId: mediaData.ID,
          fileName: mediaData.Name,
          mediaType: request.type,
        },
      });
    },
    ...options,
  });
};

const useUpdatePortDocument = (
  options?: UseMutationOptions<
    UpdatePortOrderDocumentResponse,
    AxiosError<any>,
    UploadPortDocumentRequest
  >
) => {
  return useMutation({
    mutationFn: async (request: UploadPortDocumentRequest) => {
      const formData = new FormData();
      formData.append('file', request.file);
      formData.append('type', request.type);
      const mediaData = await uploadPhoneFile(formData);
      return portingSchemaApi.updatePortOrderDocument({
        contentType: request.file.type,
        mediaData: {
          portingDataId: request.portId,
          mediaId: mediaData.ID,
          fileName: mediaData.Name,
          mediaType: request.type,
        },
      });
    },
    ...options,
  });
};

const useDeletePortDocument = (
  options?: UseMutationOptions<
    DeletePortOrderDocumentResponse,
    AxiosError<any>,
    DeletePortOrderDocumentRequest
  >
) => {
  return useMutation({
    mutationFn: portingSchemaApi.deletePortOrderDocument,
    ...options,
  });
};

export const PortingQueries = {
  PortingQueryKeys,
  useCancelPortOrder,
  useClonePortOrder,
  useCreateSFPortOrder,
  useGetAvailableFOCDates,
  useGetPhoneNumbers,
  useGetPortingValidationData,
  useUploadPortDocument,
  useDeletePortDocument,
  useUpdatePortDocument,
};
