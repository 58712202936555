import { useAlert } from '@weave/alert-system';
import { PrimaryButton, SearchField, Text, useForm } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { Page } from 'components/shared';
import React from 'react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDebouncedValue } from './use-debounced-value';
import { UserLookupDetails } from './user-lookup-details';
import { UserLookupErrors } from './user-lookup-errors';
import {
  searchUserByEmail,
  SearchUserByEmailResponse,
  searchUserById,
  SearchUserByIdResponse,
} from './user-search.api';

type UserLookUpPageProps = {};

const uuidRegEx =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const isValidUUID = (text: string) => uuidRegEx.test(text);
const isValidSearch = (input: string) => {
  if (!input) return false;
  if (isValidUUID(input)) return true;
  if (input.length > 3) return true;
  return false;
};

export const UserLookUpPage = (props: UserLookUpPageProps) => {
  const alert = useAlert();
  const queryParams = new URLSearchParams(window.location.search);

  const { formProps, getFieldProps, values } = useForm({
    fields: {
      search: {
        value: queryParams.get('search') || '',
        type: 'text',
        validator: (props) =>
          isValidSearch(props.value) ? '' : 'Must be a User ID or User Email',
      },
    },
    onSubmit: () => {
      req.refetch();
    },
  });

  const searchTerm = useDebouncedValue(values.search || '', 300);

  const req = useQuery<SearchUserByIdResponse | SearchUserByEmailResponse>(
    ['searchUserById', searchTerm],
    () => {
      const search = values.search || '';

      if (isValidUUID(search)) {
        return searchUserById(search);
      }

      return searchUserByEmail(search);
    },
    {
      enabled: isValidSearch(searchTerm),
      retry: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: 1000 * 60,
      onError: () => {
        alert.error('Error looking up user');
      },
    }
  );

  useEffect(() => {
    if (values.search && queryParams.get('search') !== values.search) {
      const newUrl =
        window.location.origin + window.location.pathname + `?search=${values.search}`;

      window.history.replaceState(null, '', newUrl);
      req.refetch();
    }
  }, [values.search]);

  return (
    <Page title="User Lookup">
      <form {...formProps}>
        <Text
          weight="bold"
          as="label"
          //@ts-ignore - text props don't adapt to as
          htmlFor="user-look-up-search-field"
        >
          Enter User ID or Email:
        </Text>
        <span
          css={{
            display: 'flex',
            'div:first-of-type': {
              flex: 1,
            },
          }}
        >
          <SearchField {...getFieldProps('search')} aria-label="Search User" />
          <PrimaryButton
            id="user-look-up-search-field"
            type="submit"
            disabled={req.isLoading}
            style={{ width: 'auto', marginLeft: theme.spacing(2) }}
          >
            Search
          </PrimaryButton>
        </span>
      </form>
      {!req.isError && req.data && (
        <section
          css={(theme) => ({
            margin: theme.spacing(2),
          })}
        >
          {Array.isArray(req.data) ? (
            <>
              <Text>Found ({req.data.length}) results:</Text>
              {req.data.map((value) => (
                <UserLookupDetails key={value.UserID} userData={value} />
              ))}
            </>
          ) : (
            <UserLookupDetails userData={req.data} />
          )}
        </section>
      )}
      {req.isError && (
        <UserLookupErrors
          error={req.error}
          css={(theme) => ({
            margin: theme.spacing(2),
          })}
        />
      )}
    </Page>
  );
};
