import React from 'react';
import { Route } from 'react-router-dom';
import { HistoryListContainer } from './history-list.container';
import { CallRecordsContainer } from './call-records-container/call-records.container';
import { PatientDetailsContainer } from './patient-details-container/patient-details.container';
import { SmsDetailsContainer } from './sms-details-container/sms-details.container';
import { HISTORY } from '../../constants/paths';
import { CrawlSwitch } from '../../routers/crawl-switch';
import { MigratedAutoMessageQueue } from './auto-message-queue/migrated-auto-message-queue.component';

export const HistoryPage = () => (
  <CrawlSwitch>
    <Route path={HISTORY.callRecords} component={CallRecordsContainer} />
    <Route path={HISTORY.patientDetails} component={PatientDetailsContainer} />
    <Route path={HISTORY.smsDetails} component={SmsDetailsContainer} />
    <Route path={HISTORY.autoMsgQueue} component={MigratedAutoMessageQueue} />
    <Route path={HISTORY.historyList} component={HistoryListContainer} />
  </CrawlSwitch>
);
