import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import {
  contactInfo,
  formContainer,
} from '../intake-form-card/intake-form-card.component';
import { ContentLoader, Text } from '@weave/design-system';
import { useQuery } from 'react-query';
import { onboardingIntakeFormApi } from '../../onboarding.api';

type Props = {
  emailContact: string;
  locationId: string;
  refetchData: boolean;
};

export const IntakeFormEmailHistoryListComponent = ({
  emailContact,
  locationId,
  refetchData,
}: Props) => {
  const emailStatusLogsData = useQuery(
    [locationId, emailContact, 'emailStatusLogsData'],
    async () => {
      return onboardingIntakeFormApi.getEmailStatusLogs(emailContact);
    },
    {
      select: (data) => data,
    }
  );

  useEffect(() => {
    emailStatusLogsData.refetch();
  }, [refetchData]);
  const emailLogs = emailStatusLogsData.data?.emailStatusLog
    ?.split('^')
    .filter((log) => log.length > 0)
    .reverse();

  if (emailStatusLogsData.isSuccess) {
    return (
      <section css={formContainer}>
        <>
          <div css={emailContainers}>
            <div css={contactInfo}>
              <Text weight="bold">Onboarding Email History</Text>
            </div>
            {emailLogs === undefined && (
              <Text
                css={css`
                  text-align: center;
                `}
              >
                There is no email history
              </Text>
            )}
            <ul>
              {emailLogs?.map((email, index) => (
                <li key={index}>{email}</li>
              ))}
            </ul>
          </div>
        </>
      </section>
    );
  }
  return <ContentLoader show={emailStatusLogsData.isLoading} />;
};

const emailContainers = css`
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-height: 450px;
  overflow: scroll;
`;
