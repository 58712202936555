import {
  ListFlagsResponse,
  ListLocationsWithFlagRequest,
  ListLocationsWithFlagResponse,
} from '@weave/schema-gen-ts/dist/schemas/feature-flags/feature_flags_service_api.pb';
import { useQuery } from 'react-query';
import { CustomAxios } from 'redux/axios';

export type FeatureFlag = Exclude<ListFlagsResponse['flags'], undefined>[number];

export const listFeatureFlags = () =>
  CustomAxios.get<ListFlagsResponse>('feature-flags/v1/flags').then((resp) => resp.data);

export const listLocationsWithFlag = (
  opts: Pick<ListLocationsWithFlagRequest, 'flagName' | 'value'>
) => {
  const { flagName, value } = opts;
  if (!flagName) throw new Error('flagName is required');

  return CustomAxios.get<ListLocationsWithFlagResponse>(
    `feature-flags/v1/locations/flags/${flagName}?value=${value}`
  ).then((resp) => resp.data);
};

const MIN = 60 * 1000;
export const useFeatureFlagList = () => {
  return useQuery(['feature-flags'], listFeatureFlags, {
    staleTime: 5 * MIN,
    cacheTime: Infinity,
  });
};

export const useLocationsWithFlag = (opts: ListLocationsWithFlagRequest) => {
  return useQuery(['feature-flag-locations', opts], () => listLocationsWithFlag(opts), {
    staleTime: 5 * MIN,
    cacheTime: 10 * MIN,
    enabled: !!opts.flagName,
  });
};
