import { createAction } from 'redux-actions';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { showError, showSuccess } from '@weave/alert-system';
import { handlePhonesLogic } from './phones/phones.action';
import { handleDataSyncLogic } from './data-sync/data-sync.action';
import { handleSoftwareLogic } from './software/software.action';
import { handlePremiumFeaturesLogic } from './premium-features/premium-features.action';
import { FactorStatus, FactorTest } from './handoff-hub.reducer';
import { Categories } from '../../../components/handoff-hub-container/handoff-hub.types';
import { selectCurrentLocation } from '../location/current-location';
import { getLocalStorageKey } from '../../../components/handoff-hub-container/handoff-hub/handoff-helpers';
import { handoffHubApi } from '../../../components/onboarding/handoff-hub/handoff-hub-snapshot.api';
import { HandoffSnapshot } from '../../../components/onboarding/handoff-hub/handoff-hub.types';
import { HandoffTypes } from '../../../components/onboarding/handoff-hub/components/handoff-hub/edit-handoff/handoff-metrics/handoff.constants';

export const getHandoffHubData = createAction('GET_HANDOFF_HUB_DATA');

export const getHandoffNotesDataSuccess = createAction('GET_HANDOFF_NOTES_DATA_SUCCESS');
export const getHandoffNotesDataFailure = createAction('GET_HANDOFF_NOTES_DATA_FAILURE');

export const saveCSATData = createAction('SAVE_CSAT_DATA');

export const saveHandoffNotesSnapshot = createAction('SAVE_HANDOFF_NOTES_SNAPSHOT');
export const saveHandoffNotesSnapshotSuccess = createAction(
  'SAVE_HANDOFF_NOTES_SNAPSHOT_SUCCESS'
);
export const saveHandoffNotesSnapshotFailure = createAction(
  'SAVE_HANDOFF_NOTES_SNAPSHOT_FAILURE'
);

export const factorLogic = createAction<any>('FACTOR_LOGIC');
export const factorLogicSuccess = createAction<any>('FACTOR_LOGIC_SUCCESS');
export const factorLogicFailure = createAction<any>('FACTOR_LOGIC_FAILURE');

export const submitHandoffData = createAction('SUBMIT_HANDOFF_DATA');
export const submitHandoffDataSuccess = createAction('SUBMIT_HANDOFF_DATA_SUCCESS');
export const submitHandoffDataFailure = createAction('SUBMIT_HANDOFF_DATA_FAILURE');

/**
 * Helper method used in the handoff-hub action-helper files.
 */
export const getFactorTestResults = (
  currentFactorStatus: FactorTest['status'],
  name: FactorTest['name'],
  actual: FactorTest['actual'],
  passTest: () => boolean
): [FactorTest, FactorStatus] => {
  let newFactorStatus: FactorStatus = 'pass';
  let testStatus: FactorStatus = 'fail';

  if (passTest()) {
    testStatus = 'pass';
  }

  if (testStatus === 'fail' || currentFactorStatus === 'fail') {
    newFactorStatus = 'fail';
  }

  return [{ status: testStatus, name, actual }, newFactorStatus];
};

export const handleGetHandoffHubData = function* (action) {
  yield all([
    call(handlePhonesLogic),
    call(handleDataSyncLogic),
    call(handleSoftwareLogic),
    call(handlePremiumFeaturesLogic),
    call(handleGetHandoffNotesData),
  ]);
};

export const handleGetHandoffNotesData = function* () {
  try {
    const currentLocation: ReturnType<typeof selectCurrentLocation> = yield select(
      selectCurrentLocation
    );
    const snapshots: HandoffSnapshot[] = yield call(handoffHubApi.getHandoffHubSnapshots);

    // Snapshots data is returned in descending order so we reverse the array first to
    // traverse it in ascending order to find the oldest onboarding type handoff.
    let currSnapshot = snapshots
      ?.reverse()
      .find((snapshot) => snapshot.handoffType === HandoffTypes.OnboardingHandoff);

    if (!currSnapshot) {
      // If no handoff snapshot was retrieved from the backend, then check if there is
      // handoff data stored in localStorage.
      const snapshotFromLocalStorage = window.localStorage.getItem(
        getLocalStorageKey(currentLocation.LocationID, 'regular')
      );

      if (snapshotFromLocalStorage) {
        // If snapshot was found in localStorage for this location, store that in state.
        const parsedSnapshot = JSON.parse(snapshotFromLocalStorage);
        yield put(getHandoffNotesDataSuccess(parsedSnapshot));

        return;
      }
    }

    // The new endpoints are returning data with slightly different property names so
    // this is to map the data so that it is in the format expected by the current
    // components. NOTE: This is a temporary
    // solution since this will all be replaced in the upcoming v2 of this feature.
    const transformedSnapshot = {
      id: currSnapshot?.id,
      billing_notes: currSnapshot?.billingNotes,
      csat_recipient_user_email: null,
      csat_sent_at: null,
      customization_setup: currSnapshot?.customizationsNotes,
      customizations: currSnapshot?.customizations,
      handed_off_at: currSnapshot?.submittedAt,
      network_decision: currSnapshot?.networkDecision,
      disclaimer_type_sent: currSnapshot?.disclaimerTypeSent,
      notes: currSnapshot?.officeExperience,
      onboarders_location_id: '',
      point_of_contact_email: currSnapshot?.pointOfContact,
      reason_for_purchase: currSnapshot?.reasonForPurchase,
      router_make_and_model: currSnapshot?.routerMakeAndModel,
      router_type: currSnapshot?.routerType,
      handoffSubmittedBy: currSnapshot?.handoffSubmittedBy,
      metrics: currSnapshot?.metrics,
    };

    yield put(getHandoffNotesDataSuccess(transformedSnapshot));
  } catch {
    // no handoffsnapshot saved yet. Use a default object
    const defaultHandoffSnapshot = {
      billing_notes: null,
      csat_recipient_user_email: null,
      csat_sent_at: null,
      customization_setup: null,
      customizations: null,
      handed_off_at: null,
      network_decision: null,
      disclaimer_type_sent: null,
      notes: null,
      onboarders_location_id: '',
      point_of_contact_email: null,
      reason_for_purchase: null,
      router_make_and_model: null,
      router_type: null,
    };
    yield put(getHandoffNotesDataFailure(defaultHandoffSnapshot));
  }
};

const prepareMetricData = (categories: Categories) => {
  const metrics = Object.entries(categories)
    .map(([categoryName, category]) =>
      Object.entries(category.factors).map(([factorName, factor]: [string, any]) =>
        factor.tests.map((test) => ({
          section: categoryName,
          subsection: factorName,
          actualValue: String(test.actual),
          expectedValue: '',
          testCriteria: test.name,
          result: test.status,
          exception: '',
        }))
      )
    )
    .flat(2);

  return metrics;
};

// Called when the "Handoff" button is clicked in the handoff hub.
export const handleSubmitHandoffData = function* (action) {
  try {
    const currentUser = yield select((state: any) => state.auth.user);
    const metrics = prepareMetricData(action.payload.categories);
    const locationInfo = action.payload.locationInformation;
    let snapshotId = action.payload.currentSnapshotId;

    const payload: any = {
      pointOfContact: locationInfo.pointOfContact,
      reasonForPurchase: locationInfo.purchaseReason,
      customizations: locationInfo.hasCustomizations,
      customizationsNotes: locationInfo.customSetupNotes,
      routerType: locationInfo.routerType,
      routerMakeAndModel: locationInfo.routerInfo,
      networkDecision: locationInfo.networkNotes,
      billingNotes: locationInfo.billingNotes,
      officeExperience: locationInfo.officeExperience,
      disclaimerTypeSent: locationInfo.disclaimerType,
      handoffSubmittedBy: currentUser.username,
      submittedAt: new Date().toISOString(),
      metrics,
      handoffType: HandoffTypes.OnboardingHandoff,
      handoffName: 'Onboarding Handoff - (Old Hub)',
    };

    if (!snapshotId) {
      // If this snapshot has not been created yet on the backend then create it first.
      const newSnapshot = yield call(handoffHubApi.createHandoffHubSnapShot);
      snapshotId = newSnapshot.id;
    }

    yield call(handoffHubApi.updateHandoffSnapshot, payload, snapshotId);

    yield put(showSuccess('Handoff submitted successfully'));
    yield put(submitHandoffDataSuccess());
    yield put(getHandoffHubData());

    // Clear any localStorage handoff data for this location.
    const currentLocation: ReturnType<typeof selectCurrentLocation> = yield select(
      selectCurrentLocation
    );
    window.localStorage.removeItem(
      getLocalStorageKey(currentLocation.LocationID, 'regular')
    );
  } catch (error: any) {
    console.error('Error submitting handoff: ', error);
    yield put(submitHandoffDataFailure(error));
  }
};

export const getHandoffHubDataSaga = function* () {
  yield all([
    takeEvery(getHandoffHubData.toString(), handleGetHandoffHubData),
    takeLatest(submitHandoffData.toString(), handleSubmitHandoffData),
  ]);
};
