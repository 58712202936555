import { useSelector } from 'react-redux';
import { selectCurrentLocation } from 'redux/actions/location';

export const useLocationIdWithOverride = (locationId: string | undefined) => {
  const location = useSelector(selectCurrentLocation);
  const currentLocationId = location?.LocationID;
  const resolvedLocationId = locationId || currentLocationId;

  return resolvedLocationId;
};
