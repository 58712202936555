import { Text, ExternalLinkIcon } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { Page } from 'components/shared';
import { REACT_APP_API_URL } from 'config/app';
import { css } from 'emotion';
import { useSelector } from 'react-redux';
import { selectCurrentLocation } from '../../../redux/actions/location';

export const MigratedAutoMessageQueue = () => {
  const locationId = useSelector(selectCurrentLocation)?.LocationID ?? 'portal';
  return (
    <Page title="Auto Message Queue">
      <Text>Auto Message Queue has been migrated to the Weave Portal.</Text>
      <a
        className={css`
          color: ${theme.colors.weaveBlue};
          font-weight: bold;
          margin-top: 16px;

          svg {
            margin-left: 8px;
          }
        `}
        href={`https://app.${
          REACT_APP_API_URL.includes('dev') ? 'weavedev.net' : 'getweave.com'
        }/${locationId}/messages/message-queue`}
        rel="noopener noreferrer"
        target="_blank"
      >
        Open Message Queue in Weave Portal
        <ExternalLinkIcon />
      </a>
    </Page>
  );
};
