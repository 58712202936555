import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { useAlert } from '@weave/alert-system';
import { ContentLoader, useModalControl } from '@weave/design-system';

import { ProvisionLocationsHeader } from './provision-locations-header';
import { ProvisionSearch } from './provision-search';
import { LocationGroupCards } from './location-group-cards';
import { PageBreadcrumb } from './page-breadcrumb';
import { AssignLocationsSection } from './assign-locations-section';
import {
  getLocationStatusMapFromResponse,
  getLocationInfoMapByProvisionerIdFromResponse,
  prepareProvisionLocationRequest,
} from './helpers';
import { ProvisionConfirmationModal } from './provision-confirmation-modal';
import { ValidationErrorBanner } from './validation-error-banner';
import { ErrorBanner } from './error-banner';
import {
  locationProvisionAPI,
  locationProvisionQueries,
} from '../../apis/location-provision';
import { useProvisionLocationGroupList } from './hooks';

export const ProvisionLocationsPage = () => {
  const alerts = useAlert();
  const { slug } = useParams<{ slug: string }>();
  const [isSaving, setIsSaving] = useState(false);
  const {
    groupList: locationGroups,
    groupNames,
    isSaveButtonDisabled,
    isRetryDisabled,
    isShowErrorBadge,
    isShowValidationFailureBadge,
    isAssignLocationButtonDisabled,
    updateGroupListLocationInfoByProvisionerId,
    resetProvisionerIdFromLocationInfo,
    updateGroupListLocationInfoBySlug,
    setLocationGroupsFromResponse,
    updateLocationGroupName,
    assignLocationToGroup,
  } = useProvisionLocationGroupList();
  const [hasSelection, setHasSelection] = useState(false);
  const selectedItemsRef = useRef<Map<string, string[]>>(new Map());
  const [provisionerAuditIds, setProvisionerAuditIds] = useState<string[]>([]);
  const saveConfirmationModalControl = useModalControl();

  const locationHierarchyQuery =
    locationProvisionQueries.useGetLocationHierarchyBySlugQuery(slug);

  useEffect(() => {
    if (locationHierarchyQuery.isError) {
      alerts.error('An error occurred while fetching locations');
      return;
    }

    setLocationGroupsFromResponse(locationHierarchyQuery.data);
  }, [locationHierarchyQuery.data, locationHierarchyQuery.isError]);

  useEffect(() => {
    if (!provisionerAuditIds.length || !locationGroups.length) return;

    let currentProvisionerIds = [...provisionerAuditIds];
    let successCount = 0;
    let failCount = 0;
    const TIMEOUT = 3000;

    // Keeping max success call to 100 to have max wait time to 5 mins (3Sec Timeout * 100)
    const MAX_SUCCESS_COUNT = 100;
    const MAX_FAIL_COUNT = 3;

    const getProvisioningStatus = () => {
      locationProvisionAPI
        .getProvisioningStatus({ provisionerAuditIds: currentProvisionerIds })
        .then((res) => {
          const locationInfoMap = getLocationInfoMapByProvisionerIdFromResponse(res);
          const processedProvisionerIds = [...locationInfoMap.keys()];

          updateGroupListLocationInfoByProvisionerId(locationInfoMap);

          currentProvisionerIds = currentProvisionerIds.filter(
            (id) => !processedProvisionerIds.includes(id)
          );
          successCount++;
          failCount = 0;
        })
        .catch(() => {
          failCount++;
        })
        .finally(() => {
          if (!currentProvisionerIds.length) {
            setProvisionerAuditIds([]);
          } else if (failCount > MAX_FAIL_COUNT || successCount > MAX_SUCCESS_COUNT) {
            alerts.error('Max attempts reach for fetching provisioning statuses.');
            setProvisionerAuditIds([]);
            resetProvisionerIdFromLocationInfo();
          } else {
            setTimeout(getProvisioningStatus, TIMEOUT);
          }
        });
    };
    getProvisioningStatus();
  }, [provisionerAuditIds]);

  const handleLocationGroupNameChange = (
    oldName: string,
    newName: string,
    successCallback: () => void
  ) => {
    const isDuplicateName = locationGroups.some(
      (locationGroup) => locationGroup.name === newName
    );
    if (isDuplicateName) {
      alerts.error('Duplicate location group name');
      return;
    }

    updateLocationGroupName(oldName, newName);
    successCallback();
  };

  const handleSelect = (parentName: string, childSlugList: string[]) => {
    selectedItemsRef.current.set(parentName, childSlugList);
    const hasSelection = Array.from(selectedItemsRef.current.values()).some(
      (item) => item.length > 0
    );
    setHasSelection(hasSelection);
  };

  const handleAssignLocation = (selectedOption: string) => {
    assignLocationToGroup(selectedOption, selectedItemsRef.current);
    setHasSelection(false);
    selectedItemsRef.current = new Map();
  };

  const provisionLocationGroups = async (isRetrying = false) => {
    try {
      setIsSaving(true);
      const req = prepareProvisionLocationRequest(locationGroups, isRetrying);
      const res = await locationProvisionAPI.provisionLocations(req);
      const { locationInfoMap, provisionerAuditIds } =
        getLocationStatusMapFromResponse(res);

      updateGroupListLocationInfoBySlug(locationInfoMap);
      setProvisionerAuditIds(provisionerAuditIds);

      alerts.success(
        'Success!! Your accounts has been sent for provision and will be completed soon. Check the Provisioned column for updates.'
      );
    } catch (error) {
      alerts.error({
        message:
          'Something went wrong while provisioning locations. Please try again later.',
      });
    }
    setIsSaving(false);
  };

  const handleSaveConfirmClick = () => {
    saveConfirmationModalControl.closeModal();
    provisionLocationGroups();
  };

  const handleRetryProvisioning = () => {
    provisionLocationGroups(true);
  };

  const hasLocationGroups = locationGroups.length > 0;
  const headerDescription = hasLocationGroups
    ? 'Search your location to create new hierarchies or move locations to operate as a single location below. Clicking "Save and Provision" will provision accounts as organized below.'
    : 'Search a slug to see all the provisioning and location hierarchies associated with the account.';

  const noDataAvailable =
    !!slug && !locationHierarchyQuery.isLoading && !hasLocationGroups;

  return (
    <section css={containerStyle}>
      <ContentLoader show={locationHierarchyQuery.isLoading || isSaving} />
      {hasLocationGroups && <PageBreadcrumb />}
      <ProvisionLocationsHeader
        isSaveButtonVisible={hasLocationGroups}
        isSaveDisabled={isSaveButtonDisabled}
        descriptionText={headerDescription}
        onSaveClick={saveConfirmationModalControl.openModal}
      />
      {!hasLocationGroups ? (
        <ProvisionSearch slug={slug} noDataAvailable={noDataAvailable} />
      ) : (
        <div css={cardsContainerStyle}>
          <ValidationErrorBanner show={isShowValidationFailureBadge} />
          <ErrorBanner
            show={isShowErrorBadge}
            disableRetry={isRetryDisabled}
            onRetry={handleRetryProvisioning}
          />
          <AssignLocationsSection
            slug={slug}
            disabled={isAssignLocationButtonDisabled || !hasSelection}
            dropdownOptions={groupNames}
            onAssignLocation={handleAssignLocation}
          />
          <LocationGroupCards
            list={locationGroups}
            onSelect={handleSelect}
            onGroupNameChange={handleLocationGroupNameChange}
          />
        </div>
      )}
      <ProvisionConfirmationModal
        {...saveConfirmationModalControl.modalProps}
        onConfirm={handleSaveConfirmClick}
      />
    </section>
  );
};

const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(5),
});

const cardsContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
});
