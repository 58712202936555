import React from 'react';
import { useSelector } from 'react-redux';
import { Page } from '../../shared/page/page.component';
import { phoneMediaSelector } from '../../../redux/actions';
import { Text, TextLink } from '@weave/design-system';

export const HoldMusic = () => {
  const { locationId } = useSelector(phoneMediaSelector);

  return (
    <Page
      title="Hold Music"
      subtitle={
        <Text color="light">
          Hold music is now only managed in{' '}
          <TextLink
            href={`https://app.getweave.com/admin/${locationId}/phone-system/hold-music`}
            target="_blank"
            rel="noopener"
          >
            Admin Portal
          </TextLink>
          .
        </Text>
      }
    ></Page>
  );
};
