import { handleActions } from 'redux-actions';
import {
  downloadBill,
  downloadBillDone,
  downloadLOA,
  downloadLOADone,
  getPortingData,
  getPortingDataFailure,
  getPortingDataSuccess,
  getPortingStatus,
  getPortingStatusFailure,
  getPortingStatusSuccess,
  clearPortingStatus,
  submitPorting,
  submitPortingSuccess,
  submitPortingFailure,
  setPortingDetails,
  updatePortingData,
  updatePortingDataSuccess,
  updatePortingDataFailure,
  clearSubmitPortingDetails,
  checkPortability,
  checkPortabilitySuccess,
  checkPortabilityFailure,
  resetPortabilityCheck,
  submitSplitPortingRequests,
  submitSplitPortingRequestsSuccess,
  submitSplitPortingRequestsFailure,
  deletePortingRequest,
  deletePortingRequestSuccess,
  deletePortingRequestFailure,
  submitPortingFailureWithoutErrorMessage,
} from './porting.action';

export type PortingState = {
  ports: any[];
  loading: boolean;
  downloadLOALoading: boolean;
  downloadBillLoading: boolean;
  currentPortStatus: any;
  portStatusLoading: boolean;
  submitPortingLoading: boolean;
  portingDetails: any;
  updatePortingDetailsLoading: boolean;
  submitPortingDetails: any;
  splitPortingRequest: number;
  splitPortingRequests: any[];
  splitPortingRequestsResults: any[];
  deletePortingRequestLoading: boolean;
};

const initialState = {
  ports: [],
  loading: false,
  downloadLOALoading: false,
  downloadBillLoading: false,
  currentPortStatus: null,
  portStatusLoading: false,
  submitPortingLoading: false,
  portingDetails: null,
  updatePortingDetailsLoading: false,
  submitPortingDetails: null,
  splitPortingRequest: -1,
  splitPortingRequests: [],
  splitPortingRequestsResults: [],
  deletePortingRequestLoading: false,
};

export const portingReducer = handleActions(
  {
    [checkPortability.toString()]: (state, action) =>
      Object.assign({}, state, {
        submitPortingLoading: true,
        splitPortingRequest: -1,
        splitPortingRequests: [],
      }),
    [checkPortabilitySuccess.toString()]: (state, action: any) =>
      Object.assign({}, state, {
        submitPortingLoading: false,
        splitPortingRequest: action.payload.length,
        splitPortingRequests: action.payload,
      }),
    [checkPortabilityFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        submitPortingLoading: false,
        splitPortingRequest: -1,
        splitPortingRequests: [],
      }),

    [submitSplitPortingRequests.toString()]: (state, action) =>
      Object.assign({}, state, {
        submitPortingLoading: true,
        splitPortingRequest: -1,
        splitPortingRequestsResults: [],
      }),
    [submitSplitPortingRequestsSuccess.toString()]: (state, action: any) =>
      Object.assign({}, state, {
        submitPortingLoading: false,
        splitPortingRequests: [],
        splitPortingRequestsResults: action.payload,
      }),
    [submitSplitPortingRequestsFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        submitPortingLoading: false,
        splitPortingRequests: [],
        splitPortingRequestsResults: [
          {
            status: {
              has_error: true,
              errors: [
                { description: 'Network request failed. Simply try resubmitting.' },
              ],
            },
            port: null,
          },
        ],
      }),

    [resetPortabilityCheck.toString()]: (state, action) =>
      Object.assign({}, state, {
        splitPortingRequest: -1,
        splitPortingRequests: [],
        splitPortingRequestsResults: [],
      }),

    [updatePortingData.toString()]: (state, action) =>
      Object.assign({}, state, {
        updatePortingDetailsLoading: true,
        portingDetails: null,
      }),
    [updatePortingDataSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        portingDetails: action.payload,
        updatePortingDetailsLoading: false,
      }),
    [updatePortingDataFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        updatePortingDetailsLoading: false,
        portingDetails: null,
      }),

    [deletePortingRequest.toString()]: (state, action) =>
      Object.assign({}, state, {
        deletePortingRequestLoading: true,
      }),
    [deletePortingRequestSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        deletePortingRequestLoading: false,
      }),
    [deletePortingRequestFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        deletePortingRequestLoading: false,
      }),

    [setPortingDetails.toString()]: (state, action) =>
      Object.assign({}, state, { portingDetails: action.payload }),
    [getPortingData.toString()]: (state, action) =>
      Object.assign({}, state, { loading: true }),
    [getPortingDataSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { ports: action.payload, loading: false }),
    [getPortingDataFailure.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, ports: [] }),
    [downloadLOA.toString()]: (state, action) =>
      Object.assign({}, state, { downloadLOALoading: true }),
    [downloadLOADone.toString()]: (state, action) =>
      Object.assign({}, state, { downloadLOALoading: false }),
    [downloadBill.toString()]: (state, action) =>
      Object.assign({}, state, { downloadBillLoading: true }),
    [downloadBillDone.toString()]: (state, action) =>
      Object.assign({}, state, { downloadBillLoading: false }),
    [getPortingStatus.toString()]: (state, action) =>
      Object.assign({}, state, { currentPortStatus: null, portStatusLoading: true }),
    [getPortingStatusSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        currentPortStatus: action.payload,
        portStatusLoading: false,
      }),
    [getPortingStatusFailure.toString()]: (state, action) =>
      Object.assign({}, state, { portStatusLoading: false, currentPortStatus: null }),
    [clearPortingStatus.toString()]: (state, action) =>
      Object.assign({}, state, { portStatusLoading: false, currentPortStatus: null }),
    [submitPorting.toString()]: (state, action) =>
      Object.assign({}, state, {
        submitPortingLoading: true,
        submitPortingDetails: null,
      }),
    [submitPortingSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        submitPortingLoading: false,
        submitPortingDetails: action.payload,
      }),
    [clearSubmitPortingDetails.toString()]: (state, action) =>
      Object.assign({}, state, {
        submitPortingDetails: null,
      }),
    [submitPortingFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        submitPortingLoading: false,
        submitPortingDetails: {
          has_error: true,
          errors: [{ description: 'Network request failed. Simply try resubmitting.' }],
        },
      }),
    [submitPortingFailureWithoutErrorMessage.toString()]: (state, action) =>
      Object.assign({}, state, {
        submitPortingLoading: false,
      }),
  },
  initialState
);
