import { css } from '@emotion/core';
import { PrimaryButton, SecondaryButton } from '@weave/design-system';
import { theme } from '@weave/theme-original';

type FooterButtonsProps = {
  disableSubmitButton: boolean;
  disableMutateButtons?: boolean;
  onBackButtonClick: () => void;
  onSaveAndCloseClick: () => void;
};

export const FooterButtons = ({
  disableSubmitButton,
  disableMutateButtons,
  onBackButtonClick,
  onSaveAndCloseClick,
}: FooterButtonsProps) => {
  return (
    <div css={buttonBar}>
      <SecondaryButton
        css={backButton}
        onClick={onBackButtonClick}
        disabled={disableMutateButtons}
      >
        Back
      </SecondaryButton>
      <SecondaryButton
        css={saveButton}
        onClick={onSaveAndCloseClick}
        disabled={disableMutateButtons}
      >
        Save and Close
      </SecondaryButton>
      <PrimaryButton
        css={submitButton}
        type="submit"
        disabled={disableSubmitButton || disableMutateButtons}
      >
        Submit Handoff Note
      </PrimaryButton>
    </div>
  );
};

const buttonBar = css`
  display: flex;
  justify-content: flex-end;
`;

const backButton = css`
  max-width: 100px;
`;

const saveButton = css`
  max-width: 200px;
  margin: ${theme.spacing(0, 1)};
`;

const submitButton = css`
  max-width: 215px;
`;
