import { Heading } from '@weave/design-system';
import { theme } from '@weave/theme';
import React, { FC } from 'react';
import { PortOutTable } from './port-out-table';
import { useSelector } from 'react-redux';
import { selectCurrentLocation } from 'redux/actions/location/current-location';
import { useDownloadCsrTemplate } from '../hooks/use-download-csr-template';

export const PortOut: FC = () => {
  const currentLocation = useSelector(selectCurrentLocation);
  const { downloadCsrTemplate } = useDownloadCsrTemplate();

  return (
    <section css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      <Heading level={1}>Port Out</Heading>
      <PortOutTable
        onTemplateDownload={downloadCsrTemplate}
        locationId={currentLocation.LocationID}
      />
    </section>
  );
};
