import React, { useState } from 'react';
import { css } from '@emotion/core';
import {
  IconButton,
  Modal,
  TextButton,
  TrashIcon,
  Text,
  useModalControl,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import ReactTable from 'react-table';
import format from 'date-fns/format';

import { HandoffSnapshot } from '../../handoff-hub.types';
import { TableStyles } from '../../../../../styles/table-style';
import { handoffTypesDisplayNamesMap } from './edit-handoff/handoff-metrics/handoff.constants';

type Props = {
  snapshots?: HandoffSnapshot[];
  onViewOrEditClick: (snapshotId: string) => void;
  onDeleteClick: (snapshotId: string) => void;
};

export const HandoffTable = ({
  snapshots = [],
  onViewOrEditClick,
  onDeleteClick,
}: Props) => {
  const [snapshotToDelete, setSnapshotToDelete] = useState<HandoffSnapshot>();

  const deleteHandoffSnapshotModalControl = useModalControl();

  return (
    <>
      <ReactTable
        columns={[
          {
            Header: 'Handoff Name',
            id: 'handoffName',
            accessor: (data) => (
              <Text css={{ whiteSpace: 'pre-wrap' }}>
                {data.handoffName.length > 0 ? data.handoffName : data.handoffType}
              </Text>
            ),
          },
          {
            Header: 'Type',
            id: 'handoffType',
            accessor: (data) => (
              <Text>{handoffTypesDisplayNamesMap[data.handoffType]}</Text>
            ),
          },
          {
            Header: 'Date Submitted',
            id: 'submittedAt',
            accessor: (data) => (
              <Text>
                {data.submittedAt
                  ? format(new Date(data.submittedAt), 'MM/dd/yy')
                  : 'Draft'}
              </Text>
            ),
          },
          {
            id: 'action',
            accessor: (data) => (
              <div css={{ display: 'flex' }}>
                {!data.submittedAt && (
                  <IconButton
                    label="Delete"
                    onClick={() => {
                      setSnapshotToDelete(data);
                      deleteHandoffSnapshotModalControl.openModal();
                    }}
                  >
                    <TrashIcon />
                  </IconButton>
                )}
                <TextButton
                  css={buttonStyles}
                  onClick={() => onViewOrEditClick(data.id!)}
                >
                  {data.submittedAt ? 'View' : 'Edit'}
                </TextButton>
              </div>
            ),
          },
        ]}
        data={snapshots}
        showPagination={true}
        css={[TableStyles, actionCell]}
      />

      <Modal {...deleteHandoffSnapshotModalControl.modalProps} maxWidth={730}>
        <Modal.Header>Delete Handoff Draft</Modal.Header>
        <Modal.Body>
          <Text textAlign="center" color="error">
            Would you like to delete the following handoff draft?
          </Text>
          <Text textAlign="center">
            Handoff Name: {snapshotToDelete?.handoffName || snapshotToDelete?.handoffType}
          </Text>
        </Modal.Body>
        <Modal.Actions
          onPrimaryClick={() => {
            onDeleteClick(snapshotToDelete?.id ?? '');
            deleteHandoffSnapshotModalControl.closeModal();
          }}
          primaryLabel="Okay"
          onSecondaryClick={deleteHandoffSnapshotModalControl.closeModal}
        />
      </Modal>
    </>
  );
};

const actionCell = css`
  .rt-td:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

const buttonStyles = css`
  color: ${theme.colors.weaveBlue} !important;
`;
