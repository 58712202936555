import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { WeaveTheme } from '@weave/theme-original';
import {
  Heading,
  IconButton,
  Modal,
  PlusIcon,
  Text,
  useModalControl,
} from '@weave/design-system';

import {
  selectCurrentLocationId,
  selectCurrentLocationParentId,
} from '../../../redux/actions/location';
import { getLocationSettings } from '../../../redux/actions/location/location-settings/location-settings.action';
import {
  clearSyncAppState,
  getSyncAppHealth,
  selectLocationHealth,
  selectSidebarSyncApps,
  selectSyncAppReports,
  selectSyncAppsDOVStatus,
} from '../../../redux/actions/sync-app';

import { SyncAppCreate } from './sync-app-create/sync-app-create.component';
import { SyncAppHealth } from './sync-app-health/sync-app-health.component';
import { SyncAppReport } from './sync-app-report.component';
import {
  iconButtonSvgPrimary,
  labelFlexRight,
  labelInfoDiv,
  sectionTitle,
} from '../location-sidebar.styles';

export const getTextColor = (type: string) => {
  const COLOR_MAP = {
    Healthy: 'success',
    VERIFIED: 'success',
    Unhealthy: 'warn',
    LOCKED: 'warn',
    Dead: 'error',
    NOT_VERIFIED: 'error',
    default: 'default',
  };
  return COLOR_MAP[type] || COLOR_MAP.default;
};

export const SidebarSyncApp = () => {
  const locationHealth = useSelector(selectLocationHealth);
  const namedSyncApps = orderBy(useSelector(selectSidebarSyncApps), ['SourceName']);
  const syncAppsDOVStatus = useSelector(selectSyncAppsDOVStatus);
  const locationId = useSelector(selectCurrentLocationId);
  const locationParentId = useSelector(selectCurrentLocationParentId);
  const syncAppsReports = useSelector(selectSyncAppReports);
  const dispatch = useDispatch();

  const { modalProps, triggerProps } = useModalControl();

  useEffect(() => {
    if (locationId) {
      dispatch(getSyncAppHealth());
      dispatch(getLocationSettings(locationId));
    }
  }, [locationId, getSyncAppHealth, getLocationSettings]);

  const closeModal = () => {
    dispatch(clearSyncAppState());
    dispatch(getSyncAppHealth());
    modalProps.onClose();
  };

  return (
    <>
      <div css={(theme: WeaveTheme) => labelInfoDiv(theme, 0)}>
        <Heading level={2}>Data Sources</Heading>
        {!locationParentId && (
          <IconButton
            label="Create Sync App"
            onClick={triggerProps.onClick}
            css={iconButtonSvgPrimary}
          >
            <PlusIcon />
          </IconButton>
        )}
      </div>

      <div css={(theme: WeaveTheme) => labelInfoDiv(theme, 0)}>
        <Text as="span" size="medium" color="light">
          Location Health
        </Text>
        <div css={labelFlexRight}>
          <Text as="span" size="medium" color={getTextColor(locationHealth)}>
            {locationHealth}
          </Text>
        </div>
      </div>
      <hr />
      {namedSyncApps.map((syncApp, index) => {
        return (
          <SyncAppHealth
            key={index}
            syncApp={syncApp}
            dovStatus={syncAppsDOVStatus[syncApp.SourceID] ?? ''}
          />
        );
      })}

      {Object.keys(syncAppsReports).length > 0 && (
        <Heading level={2} css={sectionTitle}>
          Sync App Health 2.0
        </Heading>
      )}
      {Object.entries(syncAppsReports).map(([key, reports]) => (
        <SyncAppReport key={key} reports={reports} />
      ))}

      <Modal {...modalProps} maxWidth={480}>
        <SyncAppCreate closeModal={closeModal} />
      </Modal>
    </>
  );
};
