import React, { FC } from 'react';
import { css } from 'emotion';
import { Text } from '@weave/design-system';
import type { ElementType } from 'react';

interface Props {
  name: string;
  value: any;
  as?: ElementType;
}

export const DeviceRowReadOnly: FC<Props> = ({ name, value, as }) => {
  return (
    <li
      className={css`
        margin-top: 24px;
      `}
    >
      <Text
        size="medium"
        weight="bold"
        className={css`
          margin-bottom: 8px;
        `}
      >
        {name}
      </Text>
      <Text as={as} size="medium">
        {typeof value === 'boolean' ? `${value}` : value ?? '-'}
      </Text>
    </li>
  );
};
