import { CustomAxios, getResponseData } from '../../redux/axios';

import {
  GetHierarchyBySlugRequest,
  GetHierarchyBySlugResponse,
  AccountProvisionRequest,
  AccountProvisionResponse,
  GetLocationsProvisioningStatusRequest,
  GetLocationsProvisioningStatusResponse,
} from '@weave/schema-gen-ts/dist/schemas/location/v1/provision/location_provision_service.pb';

export const getLocationHierarchyBySlug = async (req: GetHierarchyBySlugRequest) => {
  const response = await CustomAxios.get<GetHierarchyBySlugResponse>(
    '/location/v1/provision/flexible/hierarchy',
    {
      params: req,
    }
  );
  return getResponseData<GetHierarchyBySlugResponse>(response);
};

export const provisionLocations = async (
  req: AccountProvisionRequest
): Promise<AccountProvisionResponse> => {
  const response = await CustomAxios.post<AccountProvisionResponse>(
    '/location/v1/provision/flexible/provision',
    req
  );

  return getResponseData<AccountProvisionResponse>(response);
};

export const getProvisioningStatus = async (
  req: GetLocationsProvisioningStatusRequest
) => {
  const url = `/location/v1/provision/flexible/provisioner/status?provisionerAuditIds=${req.provisionerAuditIds.join(
    '&provisionerAuditIds='
  )}`;
  const response = await CustomAxios.get<GetLocationsProvisioningStatusResponse>(url);
  return getResponseData<GetLocationsProvisioningStatusResponse>(response);
};
