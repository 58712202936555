export enum BnplStatus {
  Created = 'CREATED',
  LocationDetailsAdded = 'LOCATION_DETAILS_ADDED',
  ContactDetailsAdded = 'CONTACT_DETAILS_ADDED',
  BankInformationAdded = 'BANK_INFORMATION_ADDED',
  Submitted = 'SUBMITTED',
  LegalDetailsAdded = 'LEGAL_DETAILS_ADDED',
  Activated = 'ACTIVATED',
  Declined = 'DECLINED',
}

export type BnplResponse = {
  status: BnplStatus;
  creationDate: string;
  location: string;
  url: string;
};

export type VerticalResponse = {
  vertical: string[];
};
