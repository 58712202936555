export interface SendBefore {
  type: string;
  value: number;
}

export interface Template {
  type: string;
  text: string;
  multi_template_text?: string;
}

export interface Delivery {
  method: 'sms' | 'email';
  time: string; // example: 06:30AM
}

export interface AdvancedOptions {
  [key: string]: string[];
}

export interface Templates {
  title: string;
  text: string;
  original: string;
  language: string;
}

export interface ReminderTemplates {
  id: string;
  templates: Templates[];
}

export interface NotificationTemplates {
  email: {
    appointments?: Object;
    birthdays?: Object;
    orders?: Object;
    recals?: Object;
    reviews?: Object;
  };
  sms: {
    appointments?: Object;
    birthdays?: Object;
    orders?: Object;
    recals?: Object;
    reviews?: Object;
  };
  loading: boolean;
  error: boolean;
}

export enum NotificationTypes {
  appointment = 'appointment',
  birthday = 'birthday',
  recall = 'recall',
  review = 'review',
  order = 'order',
  missedcall = 'missedcall',
  'save-the-date' = 'save-the-date',
  'schedule-reactivation' = 'schedule-reactivation',
}

export interface CreatedAt {
  seconds: number;
}

export interface ModifiedAt {
  seconds: number;
}

export type OptionTypeKeys =
  | 'practitioner'
  | 'appointment-type'
  | 'location'
  | 'operatory'
  | 'recall-type';

interface ExcludeOptions {
  excludeOptions: boolean;
}

export interface OptionTypeInfo {
  'appointment-type': ExcludeOptions;
  'recall-type': ExcludeOptions;
  practitioner: ExcludeOptions;
  location: ExcludeOptions;
  operatory: ExcludeOptions;
}

export interface AdvancedOption {
  key: OptionTypeKeys | string;
  value: string;
  display_name: string;
  source_id?: string;
}

export interface NotificationSettingsDetails {
  id: string;
  name: string;
  type: NotificationTypes;
  sub_type?: string;
  send_before: SendBefore;
  template: Template;
  always_send: boolean;
  delivery: Delivery;
  enabled: boolean;
  advanced_options?: AdvancedOption[];
  optionTypeInfo: OptionTypeInfo;
  created_at?: CreatedAt;
  modified_at?: ModifiedAt;
}

export interface NotificationSettings {
  recall: NotificationSettingsDetails[];
}

export interface NotificationsState {
  templates: NotificationTemplates;
  notificationsSettings: NotificationSettings;
  advancedOptions: AdvancedOptions;
  error?: Error | string;
  loading: boolean;
}
