import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { KeyNames, SearchField, useFormField } from '@weave/design-system';
import { PortGetData } from 'apis/porting/porting.types';
import { css } from '@emotion/core';

interface Props {
  ports: any[];
  setSearchPortResult: Dispatch<SetStateAction<PortGetData[]>>;
}

export const SearchPortingData = (props: Props) => {
  const { ports, setSearchPortResult } = props;

  const searchFieldProps = useFormField({ type: 'text', value: '' });

  useEffect(() => {
    if (!searchFieldProps.value.length) {
      setSearchPortResult(ports);
    }
  }, [searchFieldProps]);

  const getSearchData = () => {
    let filteredPortList: PortGetData[] = [];
    if (searchFieldProps?.value?.length >= 5) {
      ports?.filter((data) => {
        if (data.id.includes(searchFieldProps.value)) {
          filteredPortList.push(data);
          return;
        }

        if (
          data?.porting_requests?.filter(
            (portingRequest) =>
              portingRequest?.phone_number?.includes(searchFieldProps.value) ||
              portingRequest?.port_order_number?.includes(searchFieldProps.value)
          ).length > 0
        ) {
          filteredPortList.push(data);
        }
      });
    }
    setSearchPortResult(filteredPortList);
    if (!searchFieldProps.value.length) {
      setSearchPortResult(ports);
    }
  };

  searchFieldProps.onBlur = () => {
    getSearchData();
  };

  const handleSearchOnKeyUp = (e) => {
    if (e.key === KeyNames.Enter) {
      getSearchData();
    }
  };

  return (
    <div
      css={css`
        margin-right: 20px;
        width: 250px;
      `}
    >
      <SearchField
        name="search"
        label="Search"
        {...searchFieldProps}
        onKeyUp={handleSearchOnKeyUp}
        helperText="Please enter atleast 5 digit to search"
      />
    </div>
  );
};
