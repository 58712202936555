import { FC, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/core';
import {
  ContentLoader,
  Heading,
  Modal,
  ModalControlModalProps,
  NakedButton,
  Text,
  useAlert,
} from '@weave/design-system';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { theme } from '@weave/theme-original';
import { LocationFeatureV2 } from 'models/location-feature.model';
import { useSelector } from 'react-redux';
import { selectCurrentLocationSlug } from '../../../../redux/actions/location/current-location';
import {
  useFetchSFAccountBundle,
  useGetQuotaList,
} from '../../../customization-page-container/customization-page.queries';
import { getQuotaAmountText } from '../customization-page.utils';

export interface EditCustomizationQuotaModalProps extends ModalControlModalProps {
  feature: LocationFeatureV2 | null;
  onQuotaUpdate: (quota: number) => void;
}

export const EditCustomizationQuotaModal: FC<EditCustomizationQuotaModalProps> = ({
  feature,
  onQuotaUpdate,
  ...modalProps
}) => {
  const [selectedAddOnQuota, setSelectedAddOnQuota] = useState<number>(0);
  const [bundledQuota, setBundledQuota] = useState<number>(0);
  const [addOnQuota, setAddOnQuota] = useState<number>(0);

  const locationSlug = useSelector(selectCurrentLocationSlug);
  const alerts = useAlert();

  const featureEnum = feature?.featureEnum;
  const totalQuota = Number(feature?.quota) || 0;

  const { data: quotaList = [], isLoading: isQuotaLoading } = useGetQuotaList(
    featureEnum === Feature.EMAIL_MARKETING ? 'EMAIL-MARKETING' : 'BULK-TEXTING',
    {
      enabled: modalProps.show,
      onError: () => {
        alerts.error('Failed to fetch quota list');
      },
    }
  );

  const { data: bundleQuotaResponse, isLoading: isAccountBundleLoading } =
    useFetchSFAccountBundle(
      {
        slug: locationSlug,
      },
      {
        enabled: modalProps.show,
      }
    );

  const handleSubmitClick = () => {
    !!selectedAddOnQuota && onQuotaUpdate(selectedAddOnQuota + bundledQuota);
    modalProps.onClose();
  };

  const featureSFQUota = useMemo(() => {
    if (isAccountBundleLoading) return 0;

    switch (featureEnum) {
      case Feature.EMAIL_MARKETING:
        return bundleQuotaResponse?.emailMarketingQuota || 0;
      case Feature.BULK_MESSAGING:
        return bundleQuotaResponse?.bulkTextingQuota || 0;

      default:
        return 0;
    }
  }, [featureEnum, bundleQuotaResponse, isAccountBundleLoading]);

  useEffect(() => {
    if (isAccountBundleLoading || !modalProps.show) return;

    const addOnQuota = totalQuota - featureSFQUota;
    setBundledQuota(featureSFQUota);
    setAddOnQuota(addOnQuota);
    setSelectedAddOnQuota(addOnQuota);
  }, [featureSFQUota, totalQuota, isAccountBundleLoading, modalProps.show]);

  useEffect(() => {
    if (!modalProps.show) {
      setBundledQuota(0);
      setAddOnQuota(0);
      setSelectedAddOnQuota(0);
    }
  }, [modalProps.show]);

  return (
    <Modal {...modalProps} maxWidth={600}>
      <ContentLoader show={isAccountBundleLoading || isQuotaLoading} />
      <Modal.Header onClose={modalProps.onClose}>
        {feature?.name ?? ''} Allowance
      </Modal.Header>
      <Modal.Body css={modalBodyStyles}>
        {!!bundledQuota && (
          <Text size="large">
            {getQuotaAmountText(bundledQuota, feature?.featureEnum)} are included in your
            current bundle.
          </Text>
        )}
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <Heading level={3} css={{ marginBottom: theme.spacing(0.25) }}>
            Additional Allowance
          </Heading>
          <Text color="light" size="medium" css={{ margin: 0 }}>
            Choose one
          </Text>
        </div>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: theme.spacing(1),
          }}
        >
          {feature?.featureEnum &&
            quotaList.map((quota, index) => (
              <QuotaOption
                key={index}
                quota={quota.upperBound}
                selectedQuota={selectedAddOnQuota}
                featureEnum={feature?.featureEnum}
                onSelect={setSelectedAddOnQuota}
              />
            ))}
        </div>
        {!!selectedAddOnQuota && (
          <Text size="large" weight="bold" css={{ margin: theme.spacing(3, 0, 0, 0) }}>
            Your total allotment is{' '}
            {getQuotaAmountText(bundledQuota + selectedAddOnQuota, feature?.featureEnum)}{' '}
            per month.
          </Text>
        )}
      </Modal.Body>
      <Modal.Actions
        disablePrimary={!selectedAddOnQuota || selectedAddOnQuota === addOnQuota}
        primaryLabel="Apply Changes"
        onPrimaryClick={handleSubmitClick}
        secondaryLabel="Cancel"
        onSecondaryClick={modalProps.onClose}
      />
    </Modal>
  );
};

interface QuotaOptionProps {
  quota: number;
  selectedQuota?: number;
  featureEnum?: Feature;
  onSelect: (quota: number) => void;
}

const QuotaOption: FC<QuotaOptionProps> = ({
  quota,
  selectedQuota,
  featureEnum,
  onSelect,
}) => {
  const isSelected = selectedQuota === quota;
  return (
    <NakedButton onClick={() => onSelect(quota)} css={buttonStyles}>
      <Text
        color={isSelected ? 'white' : 'default'}
        weight={isSelected ? 'bold' : 'regular'}
        css={[quotaOptionStyles, isSelected && selectedOptionStyles]}
      >
        {getQuotaAmountText(quota, featureEnum)}
      </Text>
    </NakedButton>
  );
};

const modalBodyStyles = css({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(3, 0, 2, 0),
  gap: theme.spacing(1),
});

const buttonStyles = css({
  ':focus': {
    outline: 'none',
  },
});

const quotaOptionStyles = css({
  padding: theme.spacing(1, 2),
  margin: 0,
  borderRadius: theme.borderRadius.small,
  border: `1px solid ${theme.colors.gray400}`,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.colors.gray200,
  },
});

const selectedOptionStyles = css({
  backgroundColor: theme.colors.weaveBlue,
  ':hover': {
    backgroundColor: theme.colors.hoverBlue,
  },
});
