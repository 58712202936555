import { handleActions } from 'redux-actions';

import {
  submitHandoffData,
  submitHandoffDataSuccess,
  submitHandoffDataFailure,
  factorLogic,
  factorLogicFailure,
  factorLogicSuccess,
  getHandoffNotesDataSuccess,
  getHandoffNotesDataFailure,
} from './handoff-hub.action';

export type FactorLogicResult = {
  status: FactorStatus;
  tests: FactorTest[];
};

export type FactorStatus = 'pass' | 'fail' | 'off' | '';

export type FactorTest = {
  name: string;
  status: FactorStatus;
  actual: string | number;
};

export interface Factor {
  status: FactorStatus;
  loading: boolean;
  tests: FactorTest[];
}

interface FactorParam {
  status?: FactorStatus;
  loading?: boolean;
  tests?: FactorTest[];
}
export type CategoryName =
  | 'phonesCategory'
  | 'dataSyncCategory'
  | 'softwareCategory'
  | 'premiumFeaturesCategory';

export type FactorName =
  | 'inboundOutboundCalls'
  | 'phonesRegistered'
  | 'phonesCustomized'
  | 'dataSyncInstalled'
  | 'writebacks'
  | 'statusMappingSet'
  | 'autoMessagingOn'
  | 'manualMessaging'
  | 'newUsersAdded'
  | 'missedCallSMS'
  | 'reviewsEnabled';

export interface HandoffHubState {
  snapshot: any;
  submitting: boolean;
  submitError: any;
  phonesCategory: {
    inboundOutboundCalls: Factor;
    phonesRegistered: Factor;
    phonesCustomized: Factor;
  };
  dataSyncCategory: {
    dataSyncInstalled: Factor;
    writebacks: Factor;
    statusMappingSet: Factor;
  };
  softwareCategory: {
    autoMessagingOn: Factor;
    manualMessaging: Factor;
    newUsersAdded: Factor;
  };
  premiumFeaturesCategory: {
    reviewsEnabled: Factor;
    missedCallSMS: Factor;
  };
}

export interface HandoffHubAction {
  payload: {
    category: CategoryName;
    factorName: FactorName;
    status?: FactorStatus;
    messages?: string[];
    loading?: boolean;
  };
}

const defaultState: HandoffHubState = {
  snapshot: {},
  submitting: false,
  submitError: undefined,
  phonesCategory: {
    inboundOutboundCalls: { status: '', loading: false, tests: [] },
    phonesRegistered: { status: '', loading: false, tests: [] },
    phonesCustomized: { status: '', loading: false, tests: [] },
  },
  dataSyncCategory: {
    dataSyncInstalled: { status: '', loading: false, tests: [] },
    writebacks: { status: '', loading: false, tests: [] },
    statusMappingSet: { status: '', loading: false, tests: [] },
  },
  softwareCategory: {
    autoMessagingOn: { status: '', loading: false, tests: [] },
    manualMessaging: { status: '', loading: false, tests: [] },
    newUsersAdded: { status: '', loading: false, tests: [] },
  },
  premiumFeaturesCategory: {
    reviewsEnabled: { status: '', loading: false, tests: [] },
    missedCallSMS: { status: '', loading: false, tests: [] },
  },
};

const setStateFactor = (state: any, action: HandoffHubAction, obj: FactorParam) => {
  const { category, factorName } = action.payload;
  return {
    ...state,
    [category]: {
      ...state[category],
      [factorName]: {
        ...state[category][factorName],
        ...obj,
      },
    },
  };
};

export const reducer = handleActions(
  {
    [submitHandoffData.toString()]: (state: any, action: any) => ({
      ...state,
      submitting: true,
      submitError: undefined,
    }),
    [submitHandoffDataSuccess.toString()]: (state: any, action: any) => ({
      ...state,
      submitting: false,
      submitError: undefined,
    }),
    [submitHandoffDataFailure.toString()]: (state: any, action: any) => ({
      ...state,
      submitting: false,
      submitError: action.payload,
    }),
    [getHandoffNotesDataSuccess.toString()]: (state: any, action: any) => ({
      ...state,
      snapshot: action.payload,
    }),
    [getHandoffNotesDataFailure.toString()]: (state: any, action: any) => ({
      ...state,
      snapshot: action.payload,
    }),
    [factorLogic.toString()]: (state: any, action: any) =>
      setStateFactor(state, action, { loading: true }),
    [factorLogicFailure.toString()]: (state: any, action: any) =>
      setStateFactor(state, action, {
        status: '',
        tests: action.payload.tests || [],
        loading: false,
      }),
    [factorLogicSuccess.toString()]: (state: any, action: any) =>
      setStateFactor(state, action, {
        status: action.payload.status,
        tests: action.payload.tests,
        loading: false,
      }),
  },
  defaultState
);
