import { createSelector } from 'reselect';
import {
  LocationTypes,
  selectCurrentLocation,
  selectCurrentLocationId,
} from 'redux/actions/location';
import { Store } from 'redux/store/store.model';
import { useSelector } from 'react-redux';

const selectIsDev = createSelector(
  (state: Store) => selectCurrentLocation(state)?.Type === LocationTypes.Dev,
  (isDevLocation) => isDevLocation
);

const devURL = 'app2.weavedev.net';
const prodURL = 'app2.getweave.com';

export const useConnectStripeLink = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const isDev = useSelector(selectIsDev);
  const connectLink = `https://${
    isDev ? devURL : prodURL
  }/admin/${locationId}/payments/settings`;

  return { connectLink };
};
