import { createAction, handleActions } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage } from '../../../axios';
import { LocationSearchModel } from '../../../../models/location-search.model';
import { showError } from '@weave/alert-system';
import { Store } from '../../../store/store.model';

export const locationSearch = createAction<string>('LOCATION_SEARCH');
export const locationSearchSuccess = createAction<LocationSearchModel[]>(
  'LOCATION_SEARCH_SUCCESS'
);
export const locationSearchFailure = createAction('LOCATION_SEARCH_FAILURE');

export const userLocationsSearch = createAction<string>('USER_LOCATIONS_SEARCH');
export const userLocationsSearchSuccess = createAction('USER_LOCATIONS_SEARCH_SUCCESS');
export const userLocationsSearchFailure = createAction('USER_LOCATIONS_SEARCH_FAILURE');

export const locationChildrenSearch = createAction<string>('LOCATION_CHILDREN_SEARCH');
export const locationChildrenSearchSuccess = createAction(
  'LOCATION_CHILDREN_SEARCH_SUCCESS'
);
export const locationChildrenSearchFailure = createAction(
  'LOCATION_CHILDREN_SEARCH_FAILURE'
);
export const clearLocationChildren = createAction('CLEAR_LOCATION_CHILDREN');

export const handleLocationSearch = function* (action) {
  try {
    const query = action.payload;
    const response = yield call(CustomAxios.get, `/support/v1/locations/search/${query}`);
    yield put(locationSearchSuccess(response.data.data));
  } catch (error: any) {
    yield put(locationSearchFailure());
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleUserLocationsSearch = function* (action) {
  try {
    const userId = action.payload;
    const response = yield call(CustomAxios.get, `/support/v1/users/locations/${userId}`);

    const locations = response.data.data.Locations;
    const username = response.data.data.Username;

    const responses = yield all(
      locations.map((location) =>
        call(CustomAxios.get, `/support/v1/locations/${location.ID}`)
      )
    );

    const userLocations = responses.reduce((acc, resp) => {
      return [...acc, { ...resp.data.data, Username: username }];
    }, []);
    yield put(userLocationsSearchSuccess(userLocations));
  } catch (error: any) {
    yield put(userLocationsSearchFailure());
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleLocationChildrenSearch = function* (action) {
  try {
    const locationId = action.payload;
    const response = yield call(
      CustomAxios.get,
      `/support/v1/locations/${locationId}/children`
    );
    yield put(locationChildrenSearchSuccess(response.data.data));
  } catch (error: any) {
    yield put(locationChildrenSearchFailure());
    yield put(showError(getErrorMessage(error)));
  }
};

export const locationSearchSaga = function* () {
  yield all([
    takeLatest(locationSearch.toString(), handleLocationSearch),
    takeLatest(userLocationsSearch.toString(), handleUserLocationsSearch),
    takeLatest(locationChildrenSearch.toString(), handleLocationChildrenSearch),
  ]);
};

const defaultState = {
  loading: false,
  locations: [],
  userLocations: [],
  locationChildren: [],
};

export type LocationSearchState = {
  loading: boolean;
  locations?: LocationSearchModel[];
  userLocations: any[]; // todo: figure out how this should be shaped
  locationChildren: {
    LocationID: string;
    Name: string;
    Slug: string;
    PhoneTenantID: string;
  }[]; //these is the only property being used from what I can tell. Maybe it's a LocationModel?
};

export const locationSearchReducer = handleActions(
  {
    [locationSearch.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [locationSearchSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        locations: action.payload,
        loading: false,
      }),
    [locationSearchFailure.toString()]: (state) =>
      Object.assign({}, state, { loading: false }),

    [userLocationsSearch.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [userLocationsSearchSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        userLocations: action.payload,
        loading: false,
      }),
    [userLocationsSearchFailure.toString()]: (state) =>
      Object.assign({}, state, { loading: false }),

    [locationChildrenSearch.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [locationChildrenSearchSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        locationChildren: action.payload,
        loading: false,
      }),
    [locationChildrenSearchFailure.toString()]: (state) =>
      Object.assign({}, state, { loading: false }),
    [clearLocationChildren.toString()]: (state) =>
      Object.assign({}, state, { loading: false, locationChildren: [] }),
  },
  defaultState
);

export const selectLocationChildren = (state: Store) => {
  return state.locationSearch?.locationChildren ?? [];
};
