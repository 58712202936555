import { createRequestSaga, showError, showSuccess } from '@weave/alert-system';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { getErrorMessage } from '../../../axios';
import { addNewUser } from '../../users';
import { VerticalEnum } from '../../../../models/vertical-enum';
import {
  createDemoUserFromLocation,
  CreateLocationDTO,
  LocationActions,
  LocationModel,
  LocationModelWithPhoneData,
  LocationsActionTypes,
  LocationTypes,
  OfficeInfo,
  RemoveLocationAction,
  RequestCreateLocationAction,
  RequestSetLocationParentAction,
  RequestSetPhoneTenantID,
  RequestUpdateLocationAction,
} from './locations.types';
import {
  createLocationFailure,
  createLocationLoading,
  createLocationSuccess,
  ProvisionLocationRequestDTO,
  ProvisionLocationResponseDTO,
} from '../location-status';
import { selectLocation } from './locations.selectors';
import { selectCurrentLocationId, unsetCurrentLocationId } from '../current-location';
import { history } from '../../../store/history';
import { clearCachedActions } from '../../cached-actions';
import { ResolvedPromise } from '../../../store/store-utils';
import * as locationApi from './api';

export const requestCreateLocation = (
  location: ProvisionLocationRequestDTO,
  createPhoneTenant: boolean
): RequestCreateLocationAction => ({
  type: LocationsActionTypes.RequestCreateLocation,
  payload: {
    location,
    createPhoneTenant,
  },
});
export const requestUpdateLocation = (
  locationId: string,
  location: Partial<LocationModel>,
  onFinish?: () => void
): RequestUpdateLocationAction => ({
  type: LocationsActionTypes.RequestUpdateLocation,
  payload: { locationId, location, onFinish },
});
export const requestSetLocationParent = (
  locationId: string,
  parentLocationId: string
): RequestSetLocationParentAction => ({
  type: LocationsActionTypes.RequestSetLocationParent,
  payload: { locationId, parentLocationId },
});
export const requestSetPhoneTenantID = (
  phoneTenanatId: string
): RequestSetPhoneTenantID => ({
  type: LocationsActionTypes.RequestSetPhoneTenantID,
  payload: { phoneTenanatId },
});
export const addLocations = (locations: LocationModel[]): LocationActions => ({
  type: LocationsActionTypes.AddLocations,
  payload: locations,
});
export const updateLocation = (locationId: string, location: Partial<LocationModel>) => ({
  type: LocationsActionTypes.UpdateLocation,
  payload: { locationId, location },
});
export const removeLocation = (locationId: string): LocationActions => ({
  type: LocationsActionTypes.RemoveLocation,
  payload: locationId,
});

export const handleCreateLocation = createRequestSaga<RequestCreateLocationAction>({
  key: LocationsActionTypes.RequestCreateLocation,
  displayErrors: true,
  onError: (error) => `Location Creation Failure: ${error}`,
  saga: function* (action) {
    try {
      yield put(createLocationLoading());
      const { location: uncreatedLocation, createPhoneTenant } = action.payload;
      uncreatedLocation.provisionedFrom = 'WAM';
      let dto: typeof uncreatedLocation & ProvisionLocationResponseDTO;
      if (createPhoneTenant) {
        const provisionResponse: ResolvedPromise<
          ReturnType<typeof locationApi.newProvisionLocation>
        > = yield call(locationApi.newProvisionLocation, uncreatedLocation);
        dto = { ...uncreatedLocation, ...provisionResponse };
        yield put(createLocationSuccess(provisionResponse));
        if (dto.locationType === LocationTypes.Demo && dto.salesforceProducts) {
          const user = createDemoUserFromLocation(dto);
          yield put(addNewUser(user));
        }
      } else {
        const locationId: ResolvedPromise<ReturnType<typeof locationApi.createLocation>> =
          yield call(locationApi.createLocation, uncreatedLocation);

        const newCreationResponse: ProvisionLocationResponseDTO = {
          locationCreatedSuccessfully: true,
          phoneDataTenantCreatedSuccessfully: true,
          customizationFlagsSetSuccessfully: true,
          salesforceUpdatedSuccessfully: true,
          locationId: locationId,
          tenantId: '',
          locationProvisionError: '',
        };

        dto = { ...uncreatedLocation, ...newCreationResponse };
        yield put(createLocationSuccess(newCreationResponse));
      }
    } catch (error: any) {
      yield put(createLocationFailure());

      const errorRes = error.response.data;
      const errorHandling = errorRes.match(/.*"cause":"(?<cause>.*?)"/)[1];

      throw errorRes ? errorHandling : error;
    }
  },
});

export const handleUpdateLocation = createRequestSaga<RequestUpdateLocationAction>({
  key: LocationsActionTypes.RequestUpdateLocation,
  displayErrors: true,
  onError: (error) => getErrorMessage(error) || 'Failed to update location',
  saga: function* ({ payload }) {
    const { locationId, location: changes, onFinish } = payload;
    const prevLocation: ReturnType<ReturnType<typeof selectLocation>> = yield select(
      selectLocation(locationId)
    );
    if (!prevLocation) {
      yield put(showError(`Location ${locationId} not found`));
    }
    const location = {
      ...prevLocation,
      ...changes,
      ...(changes.VerticalID && { Vertical: VerticalEnum[changes.VerticalID] }),
    };
    yield call(locationApi.updateLocation, locationId, location);

    const officeInfo: OfficeInfo = {
      businessName: location.Name,
      businessPhone: location.Phone,
      businessEmail: location.Email,
      timezone: location.Timezone,
      industry: VerticalEnum[location.VerticalID] ?? 'Unknown',
    };

    yield put(updateLocation(locationId, location));
    yield call(locationApi.updateOfficeInfo, officeInfo);
    yield put(showSuccess('Location Updated Successfully'));
    if (onFinish) {
      onFinish();
    }
  },
});

export const handleSetLocationParent = function* (
  action: RequestSetLocationParentAction
) {
  try {
    const { locationId, parentLocationId } = action.payload;
    yield call(locationApi.setLocationParent, locationId, parentLocationId);

    yield put(updateLocation(locationId, { ParentID: parentLocationId }));
    yield put(showSuccess('Location Parentage Updated Successfully'));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleSetPhoneTenantId = function* (action: RequestSetPhoneTenantID) {
  try {
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    const { phoneTenanatId } = action.payload;
    yield call(locationApi.setPhoneTenantId, locationId, phoneTenanatId);
    yield put(updateLocation(locationId, { PhoneTenantID: phoneTenanatId }));
    yield put(showSuccess('Phone tenant id updated Successfully'));
  } catch (error: any) {
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleRemoveLocation = function* (action: RemoveLocationAction) {
  const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
    selectCurrentLocationId
  );
  // Todo: eventually we may not want to remove these cached actions when removing a location
  // Will revisit this once our cached actions season a little longer
  yield put(clearCachedActions(action.payload));
  if (locationId === action.payload) {
    yield put(unsetCurrentLocationId());
    history.push('/');
  }
};

export const locationsSaga = function* () {
  yield all([
    yield takeLatest(LocationsActionTypes.RequestCreateLocation, handleCreateLocation),
    yield takeLatest(LocationsActionTypes.RequestUpdateLocation, handleUpdateLocation),
    yield takeLatest(
      LocationsActionTypes.RequestSetLocationParent,
      handleSetLocationParent
    ),
    yield takeLatest(
      LocationsActionTypes.RequestSetPhoneTenantID,
      handleSetPhoneTenantId
    ),
    yield takeLatest(LocationsActionTypes.RemoveLocation, handleRemoveLocation),
  ]);
};
