import React from 'react';
import { CheckIcon, Modal, Text } from '@weave/design-system';
import { modalHeaderStyle, sectionsIncludedStyles } from './intake-form-card.component';

type Props = {
  multiConfirmationModalProps;
  isSoftwareOnlyCheck;
  handleMultiModalState;
};

export const IntakeFormSendMultModalComponent = ({
  multiConfirmationModalProps,
  isSoftwareOnlyCheck,
  handleMultiModalState,
}: Props) => {
  return (
    <Modal {...multiConfirmationModalProps}>
      <Modal.Header textAlign="left" css={modalHeaderStyle}>
        Send Multi Form
      </Modal.Header>
      <Modal.Body>
        <Text>
          This form includes the sections listed below. Are you sure you want to send this
          form?
        </Text>
        <Text>Sections included:</Text>
        <div>
          <div css={sectionsIncludedStyles}>
            <CheckIcon />
            <Text>Business Information</Text>
          </div>
          {!isSoftwareOnlyCheck && (
            <div css={sectionsIncludedStyles}>
              <CheckIcon />
              <Text>Porting Information</Text>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Actions
        primaryLabel="Yes, Send Form"
        onPrimaryClick={handleMultiModalState}
        secondaryLabel="Cancel"
        onSecondaryClick={multiConfirmationModalProps.onClose}
      ></Modal.Actions>
    </Modal>
  );
};
