import { isBefore, subDays } from 'date-fns';

// The number of days to cache handoff data in localStorage before clearing it up.
const CACHE_DAYS = 30;

type HandoffTypes = 'regular';

/**
 * Function that provides a uniform way to get a localStorage key to use when performing
 * CRUD operations with handoff data in localStorage.
 *
 * @param locationId Id of location to get the handoff data for
 * @param type The type of handoff (e.g. regular or upsell)
 * @returns
 */
export const getLocalStorageKey = (locationId: string, type: HandoffTypes = 'regular') =>
  `handoff:${type}:${locationId}`;

/**
 * Function used to clear any outdated handoff data that has been stored in localStorage.
 */
export const clearOutdatedCachedHandoffData = () => {
  const keys = Object.keys(localStorage).filter((key) => key.includes('handoff:'));

  keys.forEach((key) => {
    const item = localStorage.getItem(key);

    if (item) {
      const handoffData = JSON.parse(item);
      if (!handoffData.localStorageUpdatedAt) return;

      const dateOfData = new Date(handoffData.localStorageUpdatedAt);
      const cutOffDate = subDays(new Date(), CACHE_DAYS);

      if (isBefore(dateOfData, cutOffDate)) {
        localStorage.removeItem(key);
      }
    }
  });
};
