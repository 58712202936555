import React, { FormEvent } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  FormRow,
  MoneyField,
  useForm,
  Modal,
  Text,
  ModalControlModalProps,
  RadioField,
  NumberField,
  ContentLoader,
} from '@weave/design-system';
import {
  CreateNewPricingMerchantPlanTypes,
  CreateNewPricingModel,
  EditPricingModel,
  MerchantPlanTypeLabels,
  MerchantPlanTypes,
} from '../../../models/pricing.model';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewPricing,
  selectNewPricingLoading,
} from '../../../redux/actions/merchant/merchant.action';
import {
  getCurrencyValue,
  getPercentValue,
  getPricingName,
  standardPricing,
} from './pricing-helpers';
import { removeDecimals, useCurrencyValue } from '../../../helpers/currency';

interface PaymentPricingEditProps {
  modalProps: ModalControlModalProps;
  closeModal(): void;
  pricing?: EditPricingModel;
  editMode: boolean;
}

export const PaymentPricingEdit = ({
  modalProps,
  closeModal,
  pricing,
  editMode = true,
}: PaymentPricingEditProps) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectNewPricingLoading);

  const { formProps, getFieldProps, reset, values } = useForm({
    fields: {
      planName: {
        type: 'radio',
        value: getPricingName(pricing),
      },
      cpProcessingRate: {
        type: 'number',
        required: true,
        value: getPercentValue(pricing?.cardPresent?.rate, standardPricing.cardPresent),
        min: 1.25,
        max: 4.5,
      },
      cpTransactionCost: {
        type: 'money',
        required: true,
        value: getCurrencyValue(
          pricing?.cardPresent?.transactionCost,
          standardPricing.cardPresent
        ),
        min: 0.1,
        max: 0.5,
      },
      cnpProcessingRate: {
        type: 'number',
        required: true,
        value: getPercentValue(
          pricing?.cardNotPresent.rate,
          standardPricing.cardNotPresent
        ),
        min: 1.25,
        max: 5,
      },
      cnpTransactionCost: {
        type: 'money',
        required: true,
        value: getCurrencyValue(
          pricing?.cardNotPresent.transactionCost,
          standardPricing.cardNotPresent
        ),
        min: 0.1,
        max: 0.5,
      },
      mttpProcessingRate: {
        type: 'number',
        required: true,
        value: getPercentValue(
          pricing?.mobileTapToPay?.rate,
          standardPricing.mobileTapToPay
        ),
        min: 1.25,
        max: 5,
      },
      mttpTransactionCost: {
        type: 'money',
        required: true,
        value: getCurrencyValue(
          pricing?.mobileTapToPay?.transactionCost,
          standardPricing.mobileTapToPay
        ),
        min: 0.1,
        max: 0.5,
      },
      achProcessingRate: {
        type: 'number',
        required: true,
        value: getPercentValue(pricing?.ach?.rate, standardPricing.ach),
        min: 1,
        max: 2,
      },
      achTransactionCost: {
        type: 'money',
        required: true,
        value: getCurrencyValue(pricing?.ach?.transactionCost, standardPricing.ach),
        min: 1.75,
        max: 3,
      },
    },
  });

  const handlePricingModalClose = (event?: FormEvent<Element>) => {
    closeModal();
    reset();
  };
  const { value: cpTransactionCost, string: cpTransactionCostString } = useCurrencyValue(
    values?.cpTransactionCost
  );
  const { value: cnpTransactionCost, string: cnpTransactionCostString } =
    useCurrencyValue(values?.cnpTransactionCost);
  const { value: mttpTransactionCost, string: mttpTransactionCostString } =
    useCurrencyValue(values?.mttpTransactionCost);
  const { value: achTransactionCost, string: achTransactionCostString } =
    useCurrencyValue(values?.achTransactionCost);

  const handleEditPricingSave = (event?: FormEvent<Element>) => {
    if (values) {
      const newPricing: Omit<CreateNewPricingModel, 'locationId'> = {
        planName: CreateNewPricingMerchantPlanTypes[values.planName as MerchantPlanTypes],
        cardNotPresent: {
          rate: removeDecimals(values.cnpProcessingRate),
          transactionCost: cnpTransactionCost,
        },
      };
      if (values.planName === MerchantPlanTypes.Full) {
        newPricing.cardPresent = {
          rate: removeDecimals(values.cpProcessingRate),
          transactionCost: cpTransactionCost,
        };
      }
      newPricing.mobileTapToPay = {
        rate: removeDecimals(values.mttpProcessingRate),
        transactionCost: mttpTransactionCost,
      };
      newPricing.ach = {
        rate: removeDecimals(values.achProcessingRate),
        transactionCost: achTransactionCost,
      };
      dispatch(
        createNewPricing({ newPricing, closeModal: handlePricingModalClose, editMode })
      );
    }
  };
  return (
    <>
      <form {...formProps}>
        <Modal {...modalProps} maxWidth={400}>
          <Modal.Header>{editMode ? 'Edit' : 'Create'} Pricing</Modal.Header>
          <Modal.Body>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                label {
                  margin-bottom: 0px;
                }
              `}
            >
              <Text>Merchant Plan</Text>
              <div
                css={css`
                  margin-left: ${theme.spacing(2)};
                `}
              >
                <RadioField {...getFieldProps('planName')} name="planName">
                  <RadioField.Radio value={MerchantPlanTypes.Full}>
                    {MerchantPlanTypeLabels[MerchantPlanTypes.Full]}
                  </RadioField.Radio>
                  <RadioField.Radio value={MerchantPlanTypes.CardNotPresent}>
                    {MerchantPlanTypeLabels[MerchantPlanTypes.CardNotPresent]}
                  </RadioField.Radio>
                </RadioField>
              </div>
            </div>
            <div>
              {values.planName === MerchantPlanTypes.Full && (
                <>
                  <FormRow>
                    <NumberField
                      {...getFieldProps('cpProcessingRate')}
                      label="CP Processing Rate: %"
                    />
                  </FormRow>
                  <FormRow>
                    <MoneyField
                      {...getFieldProps('cpTransactionCost')}
                      label="CP Transaction Cost: $"
                      value={cpTransactionCostString}
                    />
                  </FormRow>
                </>
              )}
              <FormRow>
                <NumberField
                  {...getFieldProps('cnpProcessingRate')}
                  label="CNP Processing Rate: %"
                />
              </FormRow>
              <FormRow>
                <MoneyField
                  {...getFieldProps('cnpTransactionCost')}
                  label="CNP Transaction Cost: $"
                  value={cnpTransactionCostString}
                />
              </FormRow>
              <FormRow>
                <NumberField
                  {...getFieldProps('mttpProcessingRate')}
                  label="Mobile Tap to Pay Processing Rate: %"
                />
              </FormRow>
              <FormRow>
                <MoneyField
                  {...getFieldProps('mttpTransactionCost')}
                  label="Mobile Tap to Pay Transaction Cost: $"
                  value={mttpTransactionCostString}
                />
              </FormRow>
              <FormRow>
                <NumberField
                  {...getFieldProps('achProcessingRate')}
                  label="ACH Processing Rate: %"
                />
              </FormRow>
              <FormRow>
                <MoneyField
                  {...getFieldProps('achTransactionCost')}
                  label="ACH Transaction Cost: $"
                  value={achTransactionCostString}
                />
              </FormRow>
            </div>
          </Modal.Body>
          <Modal.Actions
            onSecondaryClick={handlePricingModalClose}
            onPrimaryClick={handleEditPricingSave}
            primaryLabel={'Save'}
            secondaryLabel={'Cancel'}
          />
          <ContentLoader
            show={loading}
            message="Saving pricing changes..."
            backgroundOpacity={0}
          />
        </Modal>
      </form>
    </>
  );
};
