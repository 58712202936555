import { HandoffTypeMetric } from '../handoff.constants';
import { DependencyDataKeys, Result } from '../handoff-metrics.types';
import { TaskIds, TaskStatus } from '../../../../../../onboarding.types';

const TEN_DLC_PASS_VALUE = 'Completed';

export const tenDlcStatusMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Status should return anything but "Unregistered"`;
  },
  dependencyDataKeys: [DependencyDataKeys.LocationTasks],
  expectedValue: TEN_DLC_PASS_VALUE,
  actualValueFn: (dependentData): string => {
    const locationTasksQueryResponse = dependentData[DependencyDataKeys.LocationTasks];
    const err = locationTasksQueryResponse?.error;
    if (err) return err.message;

    const modules = locationTasksQueryResponse?.data?.modules;

    if (!modules) return 'No onboarding modules data found';

    const allTasks = modules.map((module) => module.tasks).flat();

    const tenDlcTask = allTasks?.find((task) => task.id === TaskIds.tenDlc);

    if (!tenDlcTask) return 'No 10dlc task data found';

    const hasCompleted10Dlc = tenDlcTask?.status === TaskStatus.Complete;
    if (hasCompleted10Dlc) return TEN_DLC_PASS_VALUE;

    const taskStatusValue = tenDlcTask.status;

    return taskStatusValue!;
  },
  resultFn: function (dependentData) {
    const locationTasksQueryResponse = dependentData[DependencyDataKeys.LocationTasks];

    const err = locationTasksQueryResponse?.error;
    if (err) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    'Office does not have necessary information to fill out form',
    '10DLC form producing errors',
  ],
};
