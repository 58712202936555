import React from 'react';
import { Text } from '@weave/design-system';
import { contentStyles, textareaStyling } from './location-information-note.component';
import { HandoffSnapshot } from '../../../handoff-hub.types';

type Props = {
  locationInformationData: HandoffSnapshot;
};

export const LocationInformationUpsellNoteComponent = ({
  locationInformationData,
}: Props) => {
  return (
    <>
      <div css={contentStyles}>
        <Text>Please include any helpful notes and details below:</Text>
        <Text css={textareaStyling}>{locationInformationData.officeExperience}</Text>
      </div>
    </>
  );
};
