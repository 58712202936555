import React from 'react';
import { useSelector } from 'react-redux';

import {
  Delivery,
  SendBefore,
} from '../../../redux/actions/notifications-settings/notification-settings.types';
import { selectCurrentLocation } from '../../../redux/actions/location';
import { Page } from '../../shared';
import * as styles from './auto-reminders.styles';
import { ExternalLinkIcon, Text } from '@weave/design-system';
import { REACT_APP_API_URL } from 'config/app';

export interface CategorySetting {
  method: string;
  time: string;
  type: string;
  value: string;
  send_before: SendBefore;
  delivery: Delivery;
}

export const AutoReminders = () => {
  const location = useSelector(selectCurrentLocation);

  const baseUrl = `https://app.${
    REACT_APP_API_URL.includes('dev') ? 'weavedev.net' : 'getweave.com'
  }`;
  const autoMessagingV2Url = `${baseUrl}/${
    location?.LocationID ?? 'portal'
  }/messages/auto-messaging`;

  return (
    <Page className={styles.minWidth} title="Auto Reminders">
      <Text>Auto Reminders settings has been migrated to the Weave Portal.</Text>
      {location && (
        <a
          className={styles.autoReminderLinkStyles}
          href={autoMessagingV2Url}
          rel="noopener noreferrer"
          target="_blank"
        >
          Open Settings in Weave Portal
          <ExternalLinkIcon />
        </a>
      )}
    </Page>
  );
};
