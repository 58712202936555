import { UseQueryOptions, useQuery } from 'react-query';
import { fetchTrialHistory } from './apis';
import { GetTrialHistoryRequest, GetTrialHistoryResponse } from './types';

export const TrialSubscriptionQueryKeys = {
  baseQueryKey: ['trial-subscription'],
  getTrialHistory: (request: GetTrialHistoryRequest) => [
    ...TrialSubscriptionQueryKeys.baseQueryKey,
    'trial-history',
    request,
  ],
};

export const useGetTrialHistory = (
  request: GetTrialHistoryRequest,
  options?: UseQueryOptions<GetTrialHistoryResponse>
) => {
  return useQuery({
    queryKey: TrialSubscriptionQueryKeys.getTrialHistory(request),
    queryFn: async () => (await fetchTrialHistory(request)).data,
    retry: false,
    ...options,
    enabled: !!request.locationId && (options?.enabled ?? true),
  });
};
