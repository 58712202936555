import React from 'react';
import { useHistory } from 'react-router-dom';
import { routerPromptVerbiages } from '../customization-page-v2.constant';
import { RouterPrompt } from 'components/shared/router-prompt/router-prompt';
import { useCustomizationFlagV2Store } from '../providers/customization-flag-editable-provider';
import { useOnBeforeUnload } from 'helpers/useOnBeforeUnload';
import * as Paths from '../../../../constants/paths';

export const CustomizationFlagRouterPrompt = () => {
  const history = useHistory();
  const { loading, isCustomizationFlagEdited, saveCustomizationFlags } =
    useCustomizationFlagV2Store();

  useOnBeforeUnload({
    shouldCaptureEvent: isCustomizationFlagEdited,
    confirmationMessage: routerPromptVerbiages.confirmationMessage,
  });

  const shouldBlockNavigation = (location: Location) => {
    const isCustomizationFlagRoute = location.pathname.includes(
      Paths.OTHER.customization
    );
    const isNavigationBlocked = !isCustomizationFlagRoute && isCustomizationFlagEdited;
    return isNavigationBlocked;
  };

  return (
    <RouterPrompt
      title={routerPromptVerbiages.title}
      promptMessage={routerPromptVerbiages.promptMessage}
      primaryLabel={routerPromptVerbiages.primaryButtonLabel}
      secondaryLabel={routerPromptVerbiages.secondaryButtonLabel}
      onPrimaryClick={saveCustomizationFlags}
      navigate={(path) => history.push(path)}
      isLoading={loading}
      navigateOnSecondaryClick={true}
      when={isCustomizationFlagEdited}
      shouldBlockNavigation={shouldBlockNavigation}
    />
  );
};
