import { createAction, handleActions } from 'redux-actions';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { CustomAxios, getResponseData } from '../../axios';
import { MerchantModel } from '../../../models/merchant.model';
import { ClientSetting } from '../../../models/client-settings.model';
import { CreateNewPricingModel, PricingModel } from '../../../models/pricing.model';
import { selectCurrentLocationId, CurrentLocationActionTypes } from '../location';
import { Store } from '../../store/store.model';
import { createRequestSaga } from '@weave/alert-system';
import { CreateNewPricingAction, MerchantActionTypes } from './merchant.types';
import { AxiosResponse } from 'axios';

export const getMerchant = createAction('GET_MERCHANT');
export const getMerchantSuccess = createAction<MerchantModel>('GET_MERCHANT_SUCCESS');
export const getMerchantFailure = createAction<Error>('GET_MERCHANT_FAILURE');
export const clearMerchant = createAction('CLEAR_MERCHANT');
export const paymentUrlSuccess =
  createAction<{ url: string; stripeExpressPublicKey: string }>('PAYMENT_URL_SUCCESS');
export const getMerchantConnectLink = createAction('GET_MERCHANT_CONNECT_LINK');
export const getMerchantConnectLinkSuccess = createAction<string>(
  'GET_MERCHANT_CONNECT_LINK_SUCCESS'
);
export const getMerchantConnectLinkFailure = createAction(
  'GET_MERCHANT_CONNECT_LINK_FAILURE'
);
export const createNewPricing = createAction<{
  newPricing: Omit<CreateNewPricingModel, 'locationId'>;
  closeModal: () => void;
  editMode: boolean;
}>(MerchantActionTypes.CreateNewPricing);
export const createNewPricingSuccess = createAction(
  MerchantActionTypes.CreateNewPricingSuccess
);
export const createNewPricingFailure = createAction<Error>(
  MerchantActionTypes.CreateNewPricingFailure
);
export const getPricings = createAction(MerchantActionTypes.GetPricings);
export const getPricingsSuccess = createAction<PricingModel>(
  MerchantActionTypes.GetPricingsSuccess
);
export const getPricingsFailure = createAction<Error>(
  MerchantActionTypes.GetPricingsFailure
);

export const selectMerchant = (state: Store) => state.merchant.merchant;
export const selectMerchantLoading = (state: Store) => state.merchant.loading;
export const selectMerchantConnectLink = (state: Store) => state.merchant.connectLink;
export const selectPricings = (state: Store) => state.merchant.pricings;
export const selectPaymentUrl = (state: Store) => state.merchant.url;
export const selectStripeExpressPublicKey = (state: Store) =>
  state.merchant.stripeExpressPublicKey;
export const selectMerchantState = (state: Store) => state.merchant;

export const selectNewPricingLoading = (state: Store) =>
  state.merchant.createNewPricing.loading;

export const getPaymentUrl = function* () {
  const baseUrl = CustomAxios.getBaseUrl()?.replace('support/v1', '');
  let url = yield select(selectPaymentUrl);

  const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
    selectCurrentLocationId
  );

  if (!locationId) {
    console.warn('Tried to get payment url but the location is not set');
    return;
  }

  try {
    const { data }: AxiosResponse<ClientSetting[]> = yield call(
      CustomAxios.get,
      `${baseUrl}client-api/settings?set=client&key[]=paymentsUrl&key[]=stripeExpressPublicKey`
    );
    if (data) {
      let { value: paymentsUrl } = data.find((s) => s.key === 'paymentsUrl') ?? {};
      const { value: stripeExpressPublicKey } =
        data.find((s) => s.key === 'stripeExpressPublicKey') ?? {};
      url = paymentsUrl?.replace(/\/$/, '');

      yield put(paymentUrlSuccess({ url, stripeExpressPublicKey }));
    }
  } catch (error: any) {
    console.error("couldn't load payments URL \n", error);
  }

  return url;
};

export const handleGetMerchant = function* () {
  try {
    const paymentUrl = yield call(getPaymentUrl);

    if (!paymentUrl) {
      return;
    }

    const merchantResponse = yield call(CustomAxios.get, `${paymentUrl}/v1/merchant`);
    yield put(getMerchantSuccess(merchantResponse.data.data));
  } catch (error: any) {
    yield put(getMerchantFailure(error));
  }
};

export const handleGetMerchantConnectLink = function* () {
  try {
    const paymentUrl = yield call(getPaymentUrl);
    const response = yield call(CustomAxios.get, `${paymentUrl}/connect/link`);
    yield put(getMerchantConnectLinkSuccess(response.data.data.next));
  } catch (error: any) {
    console.error(error);
    yield put(getMerchantConnectLinkFailure());
  }
};

const handleLocationSet = function* () {
  yield put(getMerchant());
};

const handleUnsetLocation = function* () {
  yield put(clearMerchant());
};

interface LocationsPaymentsInfo {
  data: {
    links: { pricings: string };
  };
}
interface LocationsPricingsInfo {
  data: {
    links: { pricing: string[] };
  };
}

const getLocationId = function* (require = true) {
  const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
    selectCurrentLocationId
  );
  if (require && !locationId) throw new Error('Location info error: Location is not set');
  return locationId;
};

const getLocationsPaymentsUrl = function* () {
  const paymentUrl = yield call(getPaymentUrl);
  if (!paymentUrl) throw new Error('Location info error: Payments url is not set');
  const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
    selectCurrentLocationId
  );
  if (!locationId) throw new Error('Location info error: Location is not set');
  return `${paymentUrl}/locations/${locationId}`;
};

const getLocationsPaymentsInfo = function* () {
  const locationsPaymentsUrl = yield call(getLocationsPaymentsUrl);
  const { data } = yield call(CustomAxios.get, locationsPaymentsUrl);
  return data;
};

const handleGetPricings = createRequestSaga<CreateNewPricingAction>({
  key: MerchantActionTypes.GetPricings,
  displayErrors: true,
  onError: (err) => `Failed to get pricings. Please try reloading the page.`,
  saga: function* () {
    try {
      const { data: locationData }: LocationsPaymentsInfo = yield call(
        getLocationsPaymentsInfo
      );
      const pricingsUrl = locationData.links.pricings;
      const { data: pricingLinksResponse } = yield call(CustomAxios.get, pricingsUrl);
      const { data: pricingLinksData }: LocationsPricingsInfo = pricingLinksResponse;
      const pricingLinks = pricingLinksData.links.pricing;
      const results = yield all(
        pricingLinks.map((pricingLink) => call(CustomAxios.get, pricingLink))
      );
      const pricings = results.map(
        (response: AxiosResponse<{ data: PricingModel }>) => response.data.data
      );
      yield put(getPricingsSuccess(pricings));
    } catch (error: any) {
      yield put(getPricingsFailure(error));
      throw error;
    }
  },
});

const postNewPricing = function* (newPricing: CreateNewPricingModel) {
  const locationsPaymentsUrl = yield call(getLocationsPaymentsUrl);
  const postNewPricingResponse = yield call(
    CustomAxios.post,
    `${locationsPaymentsUrl}/pricings`,
    { data: newPricing }
  );
  return postNewPricingResponse;
};

const handleCreateNewPricing = createRequestSaga<CreateNewPricingAction>({
  key: MerchantActionTypes.CreateNewPricing,
  displayErrors: true,
  onError: (err) => {
    return err.message;
  },
  saga: function* (action) {
    try {
      const locationId = yield call(getLocationId);
      const { newPricing, closeModal } = action.payload;
      const pricing = { ...newPricing, locationId };
      yield call(postNewPricing, pricing);
      yield put(createNewPricingSuccess());
      yield put(getPricings());
      if (closeModal) closeModal();
      yield put(getMerchant());
    } catch (error: any) {
      yield put(createNewPricingFailure(error));
      let errMessage = '';
      const pricingMode = action.payload.editMode ? 'update' : 'create';
      if (error.isAxiosError && [401, 403].includes(error.response?.status || 0))
        errMessage = `You are not authorized to ${pricingMode} pricings.`;
      errMessage = `Failed to ${pricingMode} pricing. Please try again.`;
      throw new Error(errMessage);
    }
  },
});

export const merchantSaga = function* () {
  yield all([
    takeEvery(getMerchant.toString(), handleGetMerchant),
    takeEvery(getMerchantConnectLink.toString(), handleGetMerchantConnectLink),
    takeLatest(CurrentLocationActionTypes.CurrentLocationChanged, handleLocationSet),
    takeLatest(CurrentLocationActionTypes.UnsetCurrentLocation, handleUnsetLocation),
    takeLatest(createNewPricing.toString(), handleCreateNewPricing),
    takeLatest(getPricings.toString(), handleGetPricings),
  ]);
};

export type MerchantState = {
  merchant?: MerchantModel;
  error?: Error;
  url?: string;
  connectLink?: string;
  pricings: { list: PricingModel[]; error: string; loading: boolean };
  loading?: boolean;
  createNewPricing: { error: string; loading: boolean };
  stripeExpressPublicKey?: string;
};

const defaultState: MerchantState = {
  createNewPricing: { loading: false, error: '' },
  pricings: { list: [], error: '', loading: false },
};

export const merchantReducer = handleActions(
  {
    [clearMerchant.toString()]: () => Object.assign({}, defaultState),
    [getMerchant.toString()]: (state) => Object.assign({}, state, { loading: true }),
    [getMerchantSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { merchant: action.payload, loading: false }),
    [getMerchantFailure.toString()]: (state, action) =>
      Object.assign({}, state, { error: action.payload, merchant: null, loading: false }),
    [paymentUrlSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { ...action.payload }),
    [getMerchantConnectLinkSuccess.toString()]: (state, action) =>
      Object.assign({}, state, { connectLink: action.payload }),
    [getMerchantConnectLinkFailure.toString()]: (state) =>
      Object.assign({}, state, { connectLink: undefined }),
    [getPricings.toString()]: (state, action) =>
      Object.assign({}, state, {
        pricings: { ...state.pricings, loading: true, error: '' },
      }),
    [getPricingsSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        pricings: { ...state.pricings, list: action.payload, loading: false, error: '' },
      }),
    [getPricingsFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        pricings: { ...state.pricings, error: action.payload, loading: false },
      }),
    [createNewPricing.toString()]: (state, action) =>
      Object.assign({}, state, {
        createNewPricing: {
          ...state.createNewPricing,
          loading: true,
          error: '',
        },
      }),
    [createNewPricingSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        createNewPricing: {
          ...state.createNewPricing,
          loading: false,
          error: '',
        },
      }),
    [createNewPricingFailure.toString()]: (state, action) =>
      Object.assign({}, state, {
        createNewPricing: {
          ...state.createNewPricing,
          loading: false,
          error: action.payload,
        },
      }),
  },
  defaultState
);
