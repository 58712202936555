import React, { useState } from 'react';
import { css } from '@emotion/core';
import {
  Text,
  Heading,
  PrimaryButton,
  useForm,
  TextareaField,
  TextField,
  SecondaryButton,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';

import { ManualTemplate } from '../../../redux/actions/manual-templates/manual-templates.reducer';
import { standardizeTemplateTextTags } from '../template-tag-utils';

const templateItemStyles = css`
  display: grid;
  grid-template-columns: auto 90px;
  grid-template-rows: auto auto;
  padding: ${theme.spacing(4, 1, 0, 2)};
  margin-bottom: ${theme.spacing(3)};
  width: 100%;
  border-top: solid 1px ${theme.colors.gray300};
`;

const templateItemTitleStyles = (named: boolean) => css`
  grid-column: 1;
  grid-row: 1;
  color: ${named ? theme.colors.gray600 : theme.colors.error};
`;

const templateItemDescStyles = css`
  grid-column: 1;
  grid-row: 2;
  width: 100%;
`;

const templateItemEditButtonStyles = css`
  grid-column: 2;
  grid-row: 1/3;
  margin-left: ${theme.spacing(2)};
  width: 70px;
`;

const templateFieldStyles = css`
  margin-bottom: ${theme.spacing(3)};
`;

const cancelEditStyles = css`
  margin-top: ${theme.spacing(7)};
`;

interface ManualTemplateItemProps {
  template: ManualTemplate;
  saveTemplate: (newTemplate: ManualTemplate) => void;
}

export const ManualTemplateItem = ({
  template,
  saveTemplate,
}: ManualTemplateItemProps) => {
  const { Template, TypeDescription, Type } = template;
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const { getFieldProps, values, reset } = useForm({
    fields: {
      template: {
        type: 'text',
        value: Template,
      },
      typeDescription: {
        type: 'text',
        value: TypeDescription,
      },
      type: {
        type: 'text',
        value: Type,
      },
    },
  });

  const handleSave = async () => {
    const standardizedTemplateText = standardizeTemplateTextTags(values?.template || ''); // will convert to snake-case
    const newTemplate = {
      TypeDescription: values?.typeDescription || '',
      Template: standardizedTemplateText,
      Type: values?.type || '',
    };

    saveTemplate(newTemplate);
    setCanEdit(false);
    reset();
  };

  return (
    <article css={templateItemStyles}>
      {!canEdit && (
        <>
          <Heading level={2} css={templateItemTitleStyles(!!TypeDescription)}>
            {TypeDescription || 'UNNAMED TEMPLATE'}
          </Heading>

          <Text css={templateItemDescStyles} color={!!Template ? 'default' : 'error'}>
            {Template || 'BLANK TEMPLATE'}
          </Text>
        </>
      )}

      {!!canEdit && (
        <>
          <TextField
            css={templateFieldStyles}
            label="Template Name"
            {...getFieldProps('typeDescription')}
          />
          <TextareaField
            css={templateFieldStyles}
            label="Template Text"
            autoGrow={[1, 8]}
            {...getFieldProps('template')}
          />
          <TextField label="Template Type" {...getFieldProps('type')} />
        </>
      )}

      <PrimaryButton
        css={templateItemEditButtonStyles}
        onClick={() => {
          canEdit ? handleSave() : null;
          setCanEdit(!canEdit);
          reset();
        }}
      >
        {canEdit ? 'Save' : 'Edit'}
      </PrimaryButton>

      {!!canEdit && (
        <SecondaryButton
          css={[templateItemEditButtonStyles, cancelEditStyles]}
          onClick={() => {
            setCanEdit(false);
            reset();
          }}
        >
          Cancel
        </SecondaryButton>
      )}
    </article>
  );
};
