export enum Result {
  Pass = 'pass',
  Fail = 'fail',
  Error = 'error',
}

export type MetricResult = {
  testCriteria: string;
  expectedValue: number | string;
  actualValue: number | string;
  result: Result;
  section: string;
  subsection: string;
  exception: string;
};

export enum DependencyDataKeys {
  CallLogs = 'callLogs',
  MailboxGreetings = 'mailboxGreetings',
  OfficeHours = 'officeHours',
  PhoneDepartmentsSchedules = 'phoneDepartmentsSchedules',
  PhoneDevices = 'phoneDevices',
  Sms = 'sms',
  Users = 'users',
  MessagingRules = 'messagingRules',
  AutoMsgLocationSettings = 'autoMsgLocationSettings',
  AppointmentWritebacks = 'appointmentWritebacks',
  WritebacksStatusMappings = 'writebacksStatusMappings',
  AppointmentsStatusMappings = 'appointmentsStatusMappings',
  SyncappHealth = 'syncappHealth',
  ReviewsSettings = 'reviewsSettings',
  PortingData = 'portingData',
  FaxProviderSettings = 'faxProviderSettings',
  FaxHistory = 'faxHistory',
  ChildLocationData = 'childLocationData',
  DigitalForms = 'digitalForms',
  SubmittedDigitizationForms = 'submittedDigitizationForms',
  OnlineSchedulingHealth = 'onlineSchedulingHealth',
  CustomizationFlags = 'customizationFlag',
  LocationTasks = 'locationTasks',
  PaymentsSettings = 'paymentsSettings',
  SalesforceBundleSettings = 'salesforceBundleSettings',
}
