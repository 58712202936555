import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { Text, PrimaryButton, Heading } from '@weave/design-system';

interface Props {
  isSaveDisabled: boolean;
  isSaveButtonVisible: boolean;
  descriptionText: string;
  onSaveClick: () => void;
}

export const ProvisionLocationsHeader = ({
  isSaveButtonVisible,
  isSaveDisabled,
  descriptionText,
  onSaveClick,
}: Props) => {
  return (
    <header css={containerStyle}>
      <div>
        <Heading css={{ marginBottom: theme.spacing(1) }}>
          Provisioning and Location Hierarchy
        </Heading>
        <Text css={{ maxWidth: 700, margin: 0 }}>{descriptionText}</Text>
      </div>
      {isSaveButtonVisible && (
        <PrimaryButton
          css={{ maxWidth: 'fit-content' }}
          onClick={onSaveClick}
          disabled={isSaveDisabled}
        >
          Save and Provision Locations
        </PrimaryButton>
      )}
    </header>
  );
};

const containerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
});
