import { css } from '@emotion/core';
import { WeaveTheme, theme } from '@weave/theme-original';

export const sidebar = css`
  flex: 0 0 auto;
  width: 320px;
  background: ${theme.colors.white};
  box-shadow: ${theme.shadows.heavy};
  margin: 0;
  padding: ${theme.spacing(3)};
`;

export const sectionTitle = css`
  display: flex;
  margin: ${theme.spacing(3, 0, 2)};
`;

export const iconSmall = css`
  width: 16px;
  height: 16px;
`;

export const iconButtonSvgPrimary = css`
  margin-right: ${theme.spacing(-1)};

  svg {
    fill: ${theme.colors.weaveBlue};
  }

  &:hover:not(:disabled),
  &:focus {
    svg {
      fill: ${theme.colors.pressedBlue};
    }
  }
`;

export const clipboardDiv = css`
  display: flex;
  cursor: pointer;
  align-items: center;

  h2 {
    margin-bottom: 0;
  }
`;

export const labelInfoDiv = (theme: WeaveTheme, topSpacing = 1) => css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${theme.spacing(topSpacing)};

  > span,
  > div {
    flex: 1;
  }

  > div + span,
  > span:nth-of-type(2) {
    text-align: right;
  }

  h2 {
    flex: 1;
    margin: 0;
  }
`;

export const labelFlexRight = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button,
  > a {
    margin-right: ${theme.spacing(-1)};
  }
`;

export const labelFlex = css`
  display: flex;
  align-items: center;
`;

export const accountLinkStyle = css`
  color: ${theme.colors.weaveBlue};
  font-size: ${theme.fontSize(16)};
  text-align: left;

  &:hover {
    color: black;
  }
`;

export const multiLocationDropdown = css`
  display: flex;
  flex-direction: column;

  > p {
    margin: ${theme.spacing(2, 0, 1)};
  }
`;

export const multiLocationTextStyle = css`
  text-align: center;
  color: ${theme.colors.strawberry};
  padding: ${theme.spacing(0.5, 1)};
  margin-bottom: 0;
  border-radius: ${theme.borderRadius.small};
  background-color: rgba(255, 49, 162, 0.1);
`;

export const sectionIconStyle = css`
  cursor: pointer;
  &:hover {
    fill: ${theme.colors.pressedBlue};
  }
`;

export const buildingIconStyle = css`
  cursor: pointer;
  margin-right: ${theme.spacing(1)};

  &:hover {
    fill: ${theme.colors.gray500};
  }
`;

export const dropdownContainerStyle = css`
  margin-top: ${theme.spacing(1)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const iconPointerStyle = css`
  cursor: pointer;
`;

export const reportLabelInfo = css`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(1)};
`;

export const reportStyle = css`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing(2)};
`;

export const statusDot = (theme: WeaveTheme, color: 'success' | 'error') => css`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: ${theme.spacing(0, 0.5)};
  background-color: ${theme.colors[color]};
`;

export const healthTooltipStyle = css`
  max-width: 448px;

  > h2 {
    margin-bottom: ${theme.spacing(2)};
  }
`;

export const tooltipLabelInfo = css`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${theme.spacing(1)};
`;

export const labelHeading = css`
  flex: 1;
  text-transform: capitalize;
`;

export const labelReason = css`
  flex: 2;
`;

export const mLeftSpacing = (theme: WeaveTheme, spacing = 1) => css`
  margin-left: ${theme.spacing(spacing)};
`;

export const mTopSpacing = (theme: WeaveTheme, spacing = 1) => css`
  margin-top: ${theme.spacing(spacing)};
`;

export const locationStatusStyles = css`
  margin: ${theme.spacing(1)} 0 0 0;
`;

export const noChildrenMessageContainerStyles = css`
  display: flex;
  align-items: center;
  margin: ${theme.spacing(1)} 0;
  > p {
    margin: 0px ${theme.spacing(1)};
  }
`;
