import { CustomAxios, getResponseData } from '../../axios';
import { FaxHistory } from '../../../components/phones/fax-settings-container/models';
import { FetchFaxHistoryPayload } from './fax-history.actions';

const FAX_URL = '/support/v1/fax';

const buildQS = (params: FetchFaxHistoryPayload) =>
  Object.entries(params).reduce((str, [key, value], idx) => {
    if (!value.trim()) return str;
    return `${str}${idx > 0 ? '&' : ''}${key}=${value.trim()}`;
  }, '');

export const requestFaxHistory = (params: FetchFaxHistoryPayload) =>
  CustomAxios.get(`${FAX_URL}?${buildQS(params)}`).then<FaxHistory[]>(getResponseData);
