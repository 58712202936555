import React, { Dispatch, SetStateAction, useState } from 'react';

export const CustomizationFlagCountryCodeVerifiedContext = React.createContext<
  boolean | null
>(null);

export const CustomizationFlagCountryCodeVerifiedUpdaterContext =
  React.createContext<Dispatch<SetStateAction<boolean | null>> | null>(null);

export const CustomizationFlagCountryCodeVerifiedProvider = ({ children }) => {
  const [isCountryCodeVerified, setIsCountryCodeVerified] = useState<boolean | null>(
    null
  );
  return (
    <CustomizationFlagCountryCodeVerifiedUpdaterContext.Provider
      value={setIsCountryCodeVerified}
    >
      <CustomizationFlagCountryCodeVerifiedContext.Provider value={isCountryCodeVerified}>
        {children}
      </CustomizationFlagCountryCodeVerifiedContext.Provider>
    </CustomizationFlagCountryCodeVerifiedUpdaterContext.Provider>
  );
};

export const useIsCountryCodeVerified = () =>
  React.useContext(CustomizationFlagCountryCodeVerifiedContext);

export const useIsCountryCodeVerifiedUpdater = () => {
  const setIsCountryCodeVerified = React.useContext(
    CustomizationFlagCountryCodeVerifiedUpdaterContext
  );

  const updateIsCountryCodeVerified = React.useCallback(
    (isCountryCodeVerified: boolean | null) => {
      if (setIsCountryCodeVerified) setIsCountryCodeVerified(isCountryCodeVerified);
    },
    [setIsCountryCodeVerified]
  );

  return updateIsCountryCodeVerified;
};
