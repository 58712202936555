import { useState } from 'react';
import { portingSchemaApi } from '../../../apis/porting/porting.api';
import { PortInStatusByPortingDataIDResponse } from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import { useAlert } from '@weave/alert-system';

export const usePortOrderStatus = () => {
  const [portOrderStatus, setPortOrderStatus] = useState<
    PortInStatusByPortingDataIDResponse | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();

  const getPortOrderStatus = async (portingDataId: string) => {
    setIsLoading(true);
    try {
      const response = await portingSchemaApi.portInStatusByPortingDataId({
        portingDataId,
      });

      setPortOrderStatus(response);
    } catch (err) {
      alert.error('Failed to get port order status.');
    } finally {
      setIsLoading(false);
    }
  };

  const clearPortOrderStatus = () => {
    setPortOrderStatus(undefined);
  };

  return {
    portOrderStatus,
    isLoading,
    getPortOrderStatus,
    clearPortOrderStatus,
  };
};
