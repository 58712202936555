import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectStripeExpressPublicKey } from 'redux/actions/merchant/merchant.action';

export const useStripePromise = () => {
  const stripeExpressPublicKey = useSelector(selectStripeExpressPublicKey);

  const stripePromise = useMemo(
    () => (stripeExpressPublicKey ? loadStripe(stripeExpressPublicKey!) : null),
    [stripeExpressPublicKey]
  );

  return { stripePromise };
};
