import {
  REACT_APP_BASE_URL,
  REACT_APP_OKTA_CLIENT_ID,
  REACT_APP_OKTA_ISSUER,
} from './app.js';

const oktaIssuer =
  REACT_APP_OKTA_ISSUER.indexOf('weaveworkforce') !== -1
    ? REACT_APP_OKTA_ISSUER
    : `${REACT_APP_OKTA_ISSUER}/oauth2/default`;

export const AuthConfig = {
  Okta: {
    issuer: oktaIssuer,
    clientId: REACT_APP_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    // scopes: ['okta.users.read.self'],
  },
  Google: {
    domain: 'getweave.auth0.com',
    clientID: 'qaxIDSGsXuBypfF8d1qsFhMVQY0ZdCHQ',
    redirectUri: REACT_APP_BASE_URL,
    responseType: 'token id_token',
    scope: 'openid profile email',
  },
};
