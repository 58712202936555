import React, { useState, useEffect } from 'react';
import {
  ConfirmationModal,
  IconButton,
  Text,
  TextButton,
  TextField,
  UpdateIcon,
  DeleteIcon,
  useFormField,
  useModalControl,
  XIcon,
  KeyNames,
} from '@weave/design-system';

import { TerminalButton } from './terminal/terminal-button.component';
import {
  terminalTabCommandsContainer,
  terminalButtonsSection,
  terminalTabText,
  terminalActionsSection,
  xIconStyle,
  mBottomSpacing,
  alignCenter,
} from '../sync-app-card.styles';
import {
  GetSyncAppInfoPayload,
  TerminalTabProps,
  ConfirmModalType,
  SyncAppButtonInterface,
} from '../../sync-app.types';
import { copyStyles } from '../../../../helpers/utils';
import { getTerminalButtons } from '../sync-app-card.utils';

export const TerminalTab = (props: TerminalTabProps) => {
  const {
    sourceId,
    loading,
    socketOpen,
    terminalPermissions,
    terminalMessages = [],
    deleteSourceFunc,
    getSyncAppInfo,
    addToTerminalText,
    clearTerminalText,
    externalWindowDoc,
  } = props;
  const [confirmModalType, setConfirmModalType] = useState<ConfirmModalType | null>(null);
  const [terminalButtons, setTerminalButtons] = useState<SyncAppButtonInterface[]>([]);

  const fieldProps = useFormField({ type: 'text' });
  const { modalProps, triggerProps } = useModalControl();

  useEffect(() => {
    if (!props.connectionId) {
      props.getConnectionId();
    }
    const buttons = getTerminalButtons(terminalPermissions);
    setTerminalButtons(buttons);
    if (externalWindowDoc) {
      externalWindowDoc.head.innerHTML = '';
      copyStyles(document, externalWindowDoc);
    }
  }, []);

  const sendRequest = (rpcMethod: string, rpcParams: any = [], id?: string) => {
    const params: GetSyncAppInfoPayload = {
      sourceId,
      rpcMethod,
      rpcParams,
    };
    if (id) {
      params.id = id;
    }
    if (Array.isArray(rpcParams) && rpcParams.length > 0) {
      params.rpcParams = btoa(JSON.stringify(rpcParams[0]));
    }
    if (rpcMethod === 'RPCAdapter.SyncPatients') {
      addToTerminalText({ sourceId, terminalText: 'Patient sync started, please wait' });
    }
    getSyncAppInfo(params);
  };

  const closeModal = () => {
    modalProps.onClose();
    setConfirmModalType(null);
  };

  const showModal = (type: ConfirmModalType) => {
    setConfirmModalType(type);
    triggerProps.onClick();
  };

  const handleModalConfirmClick = () => {
    addToTerminalText({
      sourceId,
      terminalText: `${confirmModalType} action started ...`,
    });
    if (confirmModalType === ConfirmModalType.Purge) {
      sendRequest('RPCAdapter.Purge');
    } else if (confirmModalType === ConfirmModalType.Terminate) {
      const slug = props.slug.toUpperCase();
      sendRequest('OS.TerminateAdapter', [slug], '1');
    } else if (confirmModalType === ConfirmModalType.Restart) {
      sendRequest('RPCAdapter.Restart', [], '1');
    } else if (confirmModalType === ConfirmModalType.DeleteSource) {
      deleteSourceFunc({ sourceId });
    }
  };

  const handleClearTerminalText = () => {
    clearTerminalText(sourceId);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === KeyNames.Enter) {
      try {
        const parsedMessage = JSON.parse(fieldProps.value);
        const rpcMethod = parsedMessage.method || parsedMessage.rpcMethod;
        const rpcParams = parsedMessage.params || parsedMessage.rpcParams || [];
        if (rpcMethod) {
          sendRequest(rpcMethod, rpcParams, parsedMessage.id);
        } else {
          addToTerminalText({ sourceId, terminalText: 'Invalid command' });
        }
      } catch {
        addToTerminalText({ sourceId, terminalText: 'Invalid command' });
      }
    }
  };

  if (socketOpen) {
    return (
      <>
        <div css={terminalTabCommandsContainer}>
          <div css={terminalActionsSection}>
            <div css={terminalButtonsSection}>
              {terminalButtons.map((item, index) => (
                <TerminalButton
                  key={index}
                  loading={loading}
                  sendRequest={sendRequest}
                  {...item}
                  {...(item.confirmModalType && { showModal })}
                />
              ))}
            </div>
            <IconButton
              css={xIconStyle}
              label="Clear terminal"
              hoverLabelPlacement="left"
              disabled={terminalMessages.length === 0}
              showLabelOnHover
            >
              <XIcon onClick={handleClearTerminalText} />
            </IconButton>
          </div>
          {terminalPermissions.manageAdvancedPermission && (
            <>
              <TextField
                name="custom_cmd"
                label="Send Command"
                {...fieldProps}
                onKeyUp={handleEnterKeyPress}
                clearable
              />
              <Text as="span" color="light" css={mBottomSpacing(2)}>
                Press enter to send command
              </Text>
            </>
          )}
        </div>

        <Text color="light" css={terminalTabText}>
          {terminalMessages.map((message) => message).join('\n\n')}
        </Text>

        <ConfirmationModal
          show={modalProps.show}
          onClose={closeModal}
          onConfirm={handleModalConfirmClick}
          confirmLabel={confirmModalType || 'Confirm'}
          title="Confirm this action"
          message={`Are you sure you want to ${
            confirmModalType && confirmModalType.toLowerCase()
          } this sync app?`}
        />
      </>
    );
  } else {
    return (
      <div css={[terminalTabCommandsContainer, alignCenter]}>
        <TextButton
          onClick={props.getConnectionId}
          icon={UpdateIcon}
          css={mBottomSpacing(2)}
        >
          Retry
        </TextButton>
        {terminalPermissions.installAdvancedPermission && (
          <TextButton
            onClick={() => {
              showModal(ConfirmModalType.DeleteSource);
            }}
            icon={DeleteIcon}
            css={mBottomSpacing(2)}
          >
            Delete Source
          </TextButton>
        )}
        <ConfirmationModal
          show={modalProps.show}
          onClose={closeModal}
          onConfirm={handleModalConfirmClick}
          confirmLabel={confirmModalType || 'Confirm'}
          title="Confirm this action"
          message={`Are you sure you want to ${
            confirmModalType && confirmModalType.toLowerCase()
          } this sync app?`}
        />
      </div>
    );
  }
};
