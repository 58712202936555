import React from 'react';
import { css } from 'emotion';
import { theme } from '@weave/theme-original';

export enum DotStatus {
  Active = 'active',
  Inactive = 'inactive',
  Processing = 'processing',
}
interface DotStyleParams {
  size: string;
  isActive?: boolean;
  status?: DotStatus;
}
interface DotProps {
  isActive?: boolean;
  status?: DotStatus;
}

const dotColorStyle = {
  active: `background-color: ${theme.colors.success};`,
  inactive: `border: 1px solid ${theme.colors.error}`,
  processing: `background-color: ${theme.colors.warning};`,
};

const dot = ({ size, isActive, status }: DotStyleParams) => {
  if (!status || isActive === false)
    status = isActive ? DotStatus.Active : DotStatus.Inactive;
  const activeStyle = dotColorStyle[status];
  return css`
    cursor: unset;
    height: ${size};
    width: ${size};
    border-radius: 50%;
    display: inline-block;
    margin: 0 ${theme.fontSize(4)};
    ${activeStyle}
  `;
};

export const Dot = ({ isActive, status }: DotProps) => {
  return (
    <span
      className={dot({
        size: theme.fontSize(10),
        isActive,
        status,
      })}
    />
  );
};
