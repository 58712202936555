import React, { useState, useEffect } from 'react';
import { Text, PrimaryButton, Modal, useControlledField } from '@weave/design-system';
import { getListOfNotes, postListOfNote } from '../../apis/porting/porting.api';
import { e911NotesData } from '../../apis/porting/porting.types';
import { useAlert } from '@weave/alert-system';
import { DeleteIcon, TextareaField, ModalControlModalProps } from '@weave/design-system';
import {
  modalHeadingStyle,
  textStyle,
  timeStyle,
  scrollableNote,
  buttonStyle,
  deleteIconStyle,
  noNotesStyle,
} from './e911-dashboard.styles';
import { format } from 'date-fns';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';
import { TenantId } from './e911-dashboard.constant';

interface Props {
  notesData: any;
  title: string;
  showNote: boolean;
  modalProps: ModalControlModalProps;
  closeModal: boolean;
  getNotesCountValue: (number) => void;
}

const AddNotes = ({
  notesData,
  title,
  showNote,
  modalProps,
  closeModal,
  getNotesCountValue,
}: Props) => {
  const [e911NoteData, setE911NotData] = useState<e911NotesData[]>([]);
  const [noteValue, setNoteValue] = useState<string>('');
  const fieldProps = useControlledField({
    type: 'text',
    onChange: (value) => {
      setNoteValue(value);
    },
    value: noteValue,
  });

  const addNoteAlert = useAlert();

  const tenantID = TenantId;
  const tenantId = notesData?.cell?.row?.allCells?.find(
    (item) => item?.column?.Header === tenantID
  )?.value;

  const getListOfNoteData = async () => {
    try {
      const data = await getListOfNotes(tenantId);
      setE911NotData(data);
      getNotesCountValue(data?.length);
    } catch (error) {
      addNoteAlert.error(`${error}`);
    }
  };
  useEffect(() => {
    if (modalProps.show) {
      getListOfNoteData();
    }
  }, [modalProps.show]);

  const handleAddNote = async () => {
    const userEmail = localStorage?.getItem('user_email');
    const alertMessage =
      noteValue?.length < 3
        ? 'Please enter atleast 3 characters for note'
        : userEmail
        ? ''
        : 'User Email not found';
    if (userEmail && noteValue.length >= 3) {
      try {
        await postListOfNote(tenantId, noteValue, userEmail);
        getListOfNoteData();
        setNoteValue('');
        if (closeModal) {
          modalProps.onClose();
        }
        addNoteAlert.success('Added note successfully');
      } catch (error) {
        addNoteAlert.error(`${error}`);
      }
    } else {
      addNoteAlert.error(`${alertMessage}`);
    }
  };

  return (
    <>
      <Modal {...modalProps} onClose={modalProps.onClose}>
        <Modal.Header css={modalHeadingStyle}>
          {title}
          <DeleteIcon
            css={deleteIconStyle}
            onClick={modalProps.onClose}
            style={{ float: 'right', cursor: 'pointer' }}
          ></DeleteIcon>
        </Modal.Header>

        <Modal.Body>
          <Text as="div" style={{ marginTop: '5px' }}>
            <TextareaField
              name="Note"
              label="Text"
              {...fieldProps}
              helperText="Please enter atleast 3 characters"
            />
          </Text>

          <PrimaryButton onClick={handleAddNote} className={buttonStyle}>
            Add
          </PrimaryButton>

          {showNote && !e911NoteData?.length && (
            <Text className={noNotesStyle}>No notes available</Text>
          )}
          <Text as="div" className={scrollableNote}>
            {showNote &&
              e911NoteData?.map((data) => (
                <Text as="div" key={data?.id}>
                  <Text as="div" className={textStyle}>
                    <Text as="span"> {data?.addedBy}:</Text>
                    <Text as="span" style={{ fontWeight: 600 }}>
                      {data?.note}
                    </Text>
                  </Text>
                  <Text
                    className={timeStyle}
                    style={{
                      fontSize: `${weaveTheme.fontSize(14)}`,
                      color: `${weaveTheme.colors.gray[500]}`,
                    }}
                  >
                    {format(new Date(data?.createdAt), 'MM/dd/yyyy')}
                  </Text>
                </Text>
              ))}
          </Text>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNotes;
