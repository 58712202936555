import React from 'react';
import {
  EditIcon,
  ErrorIconSmall,
  IconButton,
  NakedButton,
  Text,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { cx } from 'emotion';

import * as styles from './auto-reminder-advanced-options.styles';
import { theme } from '@weave/theme-original';

interface ConsolidatedOptionInfo {
  excludeOptions: boolean;
  values: string[];
}

interface Props {
  key: string;
  info: ConsolidatedOptionInfo;
  index: number;
  toggleOptionTypeList: Function;
  viewOptionList: string;
  setRef: (key: string, refElem: HTMLDivElement | null) => void;
  optionKey: string;
  handleCheckBox: (type: string, value: string) => void;
}

export const AutoScrollDiv = (props: Props) => {
  const {
    optionKey,
    info,
    index,
    toggleOptionTypeList,
    viewOptionList,
    setRef,
    handleCheckBox,
  } = props;
  return (
    <div>
      <h4
        css={css`
          align-items: center;
          display: flex;
        `}
      >
        <NakedButton
          className={styles.editIcon}
          onClick={toggleOptionTypeList(optionKey)}
        >
          <EditIcon
            color="default"
            css={css`
              --size: 16px;
              height: var(--size);
              width: var(--size);
            `}
          />
        </NakedButton>{' '}
        {optionKey}{' '}
      </h4>
      <div
        className={cx(
          styles.optionValues,
          viewOptionList === optionKey ? '' : styles.hide
        )}
        ref={(refElem) => setRef(optionKey, refElem)}
      >
        {info &&
          !!info.values.length &&
          info.values.map((option, key) => (
            <div
              key={`${optionKey}-${index}-${key}-${option}`}
              className={styles.includeExclude(info!['excludeOptions'])}
            >
              <Text>
                {info!['excludeOptions'] ? '-' : '+'} {option}
                <IconButton
                  size="small"
                  onClick={() => handleCheckBox(viewOptionList, option)}
                  label=""
                  css={css`
                    display: inline-block;
                    margin-left: ${theme.spacing(0.5)};
                  `}
                >
                  <ErrorIconSmall
                    css={css`
                      color: ${theme.colors.error};
                    `}
                  />
                </IconButton>
              </Text>
            </div>
          ))}
      </div>
    </div>
  );
};
