import { ToggleType } from './toggleLoading';

const findById =
  (id: string, idKey = 'ID') =>
  (item: any) =>
    item[idKey] === id;

function wait<T>(result: T, time = 1000): Promise<T> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(result);
    }, time);
  });
}

export default class Storage<T> {
  key = 'mock-';
  initialValue: T[];
  idKey = 'ID';
  constructor(key: string, initialValue: T[], idKey = 'ID') {
    this.key = `mock-${key}`;
    this.idKey = idKey;
    this.initialValue = initialValue;
    if (!localStorage.getItem(this.key)) {
      this.saveEntity(initialValue);
    }
  }
  parseEntity = (): T[] => {
    const entity = localStorage.getItem(this.key);
    try {
      if (!entity) {
        return [...this.initialValue];
      }
      return JSON.parse(entity);
    } catch (e: any) {
      console.error(e);
      return this.initialValue;
    }
  };
  saveEntity = (entity: T[]) => {
    localStorage.setItem(this.key, JSON.stringify(entity));
  };
  getById = (id: string, idKey = this.idKey): Promise<T | undefined> => {
    const items = this.parseEntity();
    return wait<T | undefined>(items.find(findById(id, idKey)));
  };
  getAll = (): Promise<T[]> => {
    const items = this.parseEntity();
    return wait<T[]>(items);
  };
  update = async (
    id: string,
    item: Partial<T>,
    toggle?: ToggleType,
    idKey = this.idKey
  ) => {
    toggle && toggle(true);
    const items = this.parseEntity().map((entity: T) => {
      if (entity[idKey] === id) {
        return {
          ...entity,
          ...item,
        };
      }
      return entity;
    });
    this.saveEntity(items);
    const updatedItem = items.find((item) => item[idKey] === id);
    const updated = await wait<T>(updatedItem as T);
    toggle && toggle(false);
    return updated;
  };
  delete = async (id: string, idKey = this.idKey, toggle?: ToggleType) => {
    toggle && toggle(true);
    const items = this.parseEntity();
    const index = items.findIndex(findById(id, idKey));
    if (index > -1) {
      items.splice(index, 1);
    }
    this.saveEntity(items);
    await wait<string>(id);
    toggle && toggle(false);
    return id;
  };
  create = async (item: T, toggle?: ToggleType) => {
    toggle && toggle(true);
    const items = this.parseEntity();
    items.push(item);
    this.saveEntity(items);
    const created = await wait<T>(item);
    toggle && toggle(false);
    return created;
  };
  count = () => {
    const items = this.parseEntity();
    return items.length;
  };
  reset = () => {
    this.saveEntity(this.initialValue);
  };
}
