import { PrimaryButton, SpinningLoader, TextButton } from '@weave/design-system';
import {
  PhoneRegistrationStatus,
  PhoneRegistrationStatusDetails,
} from '@weave/schema-gen-ts/dist/schemas/tendlc/shared/v1/shared.pb';
import { useState } from 'react';

const REGISTRATION_STATUS_MAP: Record<
  PhoneRegistrationStatus,
  { disabled: boolean; text: string }
> = {
  [PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_UNSPECIFIED]: {
    disabled: true,
    text: 'Cannot Register',
  },
  [PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_REGISTERED]: {
    disabled: true,
    text: 'Already Registered',
  },
  [PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_REGISTERABLE_QUICKLY]: {
    disabled: false,
    text: 'Begin Registration',
  },
  [PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_REGISTERABLE_SLOWLY]: {
    disabled: false,
    text: 'Begin Registration',
  },
  [PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_UNREGISTERABLE]: {
    disabled: true,
    text: 'Cannot Register',
  },
  [PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_PENDING]: {
    disabled: true,
    text: 'Registration Pending',
  },
  [PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_FAILED]: {
    disabled: false,
    text: 'Retry Registration',
  },
  [PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_REJECTED]: {
    disabled: true,
    text: 'Cannot Register',
  },
  [PhoneRegistrationStatus.PHONE_REGISTRATION_STATUS_IN_PROGRESS]: {
    disabled: true,
    text: 'Registration In Progress',
  },
};

type RegisterButtonProps = {
  phoneRegistration: PhoneRegistrationStatusDetails;
  onClick: () => Promise<void>;
};

export const RegisterButton = ({ phoneRegistration, onClick }: RegisterButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { disabled, text } = REGISTRATION_STATUS_MAP[phoneRegistration.status];

  const handleClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <PrimaryButton
      disabled={disabled || isLoading}
      css={{ maxWidth: 120 }}
      size="small"
      onClick={handleClick}
    >
      {isLoading ? <SpinningLoader size="small" /> : text}
    </PrimaryButton>
  );
};
