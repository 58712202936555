import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import { theme } from '@weave/theme-original';
import {
  Heading,
  Text,
  PrimaryButton,
  TextLink,
  useFormField,
  FormRow,
  SwitchField,
} from '@weave/design-system';

import { useMerchant } from './use-merchant.hook';
import { Dot } from '../shared/atoms/Dot';
import { DisconnectStripeBtn } from './disconnect-stripe-btn';
import { ResumeStripePayoutBtn } from './resume-stripe-payout-btn';
import { useBnplStatus } from './hooks/use-bnpl-status';
import Loading from 'react-loading';
import SunbitLogo from './assets/SunBit-logo.png';
import { obpApi } from '../../apis/payments';
import { useSelector } from 'react-redux';
import { selectCurrentLocation } from '../../redux/actions/location';
import { useAlert } from '@weave/alert-system';

const activeMerchantWrapper = css`
  display: flex;
`;

const statusWrapper = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const actionBtnsWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > *:not(:first-child) {
    margin-left: ${theme.spacing(3)};
  }
`;

const titleRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(2)};
`;

const sunbitSignup = css`
  width: 100%;
`;

const sunbitLogo = css`
  width: ${theme.spacing(2.5)};
  margin-right: ${theme.spacing(1)};
`;

const payoutsPaymentsStatuses = css`
  display: flex;
  justify-content: space-between;
  box-shadow: ${theme.shadows.light};
  padding: 10px;
  background-color: #f5f5f5;
  min-width: 240px;
  border-radius: ${theme.borderRadius.medium};
`;

const accounts = css`
  margin-left: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  min-width: 240px;
  max-width: 300px;
  border-radius: ${theme.borderRadius.medium};
  overflow: hidden;
  padding-bottom: 5px;
  box-shadow: ${theme.shadows.light};
`;

const title = css`
  margin: -10px;
  margin-bottom: 5px;
  padding: 5px;
  background-color: ${theme.colors.weaveBlue};
`;

const subtitle = css`
  margin: 0;
`;

const statusesWrapper = css`
  display: flex;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  align-items: center;
  border-radius: ${theme.borderRadius.medium};
  padding: 10px 15px;
`;

const marginReset = css`
  margin: 0;
  &:not(:last-child) {
    margin-right: ${theme.spacing(2)};
  }
`;

const obpToggle = css`
  margin-top: ${theme.spacing(4)};
`;

const Loader: React.FC = () => {
  return (
    <Loading
      color={theme.colors.weaveBlue}
      type="spin"
      width={theme.spacing(1.5)}
      height={theme.spacing(1.5)}
      className={css`
        display: inline-block;
        margin: 0 ${theme.spacing(0.5)};
      `}
    />
  );
};

export const PaymentsStatusDisplay = () => {
  const alerts = useAlert();
  const [isLoadingOBP, setIsLoadingOBP] = useState(false);
  const { activeProcessor, chargesEnabled, payoutsEnabled, paymentsUrl } = useMerchant();
  const {
    showSignupLink,
    isLoading,
    sunbitSignupLink,
    onboardingStarted,
    sunbitDotStatus,
  } = useBnplStatus();
  const obpToggleProps = useFormField({
    type: 'switch',
  });

  const location = useSelector(selectCurrentLocation);
  const currentLocationId = location?.LocationID;

  const getFeatureLocationSettings = async () => {
    setIsLoadingOBP(true);
    try {
      const response = await obpApi.getPaymentFeatureSettings(
        paymentsUrl,
        currentLocationId
      );
      obpToggleProps.onChange({
        name: 'onlineBillPayEnabled',
        value: response?.settings?.onlineBillPayEnabled,
      });
    } catch (error) {
      alerts.error('Error getting feature settings');
    } finally {
      setIsLoadingOBP(false);
    }
  };

  useEffect(() => {
    getFeatureLocationSettings();
  }, [currentLocationId]);

  const handleObpToggleChange = async () => {
    setIsLoadingOBP(true);
    try {
      const response = await obpApi.setPaymentFeatureSettings(
        paymentsUrl,
        currentLocationId,
        !obpToggleProps.value
      );
      obpToggleProps.onChange({
        name: 'onlineBillPayEnabled',
        value: response?.settings?.onlineBillPayEnabled,
      });
    } catch (error) {
      alerts.error('Error setting Online Bill Pay toggle');
    } finally {
      setIsLoadingOBP(false);
    }
  };

  return (
    <div className={statusWrapper}>
      <div className={titleRow}>
        <Heading className={marginReset} level={2}>
          Active Merchant Account
        </Heading>
        <div className={actionBtnsWrapper}>
          {showSignupLink && (
            <TextLink href={sunbitSignupLink} target="_blank">
              <PrimaryButton className={sunbitSignup}>
                <img src={SunbitLogo} alt="Sunbit Logo" className={sunbitLogo} />
                {!onboardingStarted ? 'Activate Sunbit' : 'View Sunbit Status'}
              </PrimaryButton>
            </TextLink>
          )}
          <ResumeStripePayoutBtn />
          <DisconnectStripeBtn />
        </div>
      </div>
      <div className={activeMerchantWrapper}>
        <div className={payoutsPaymentsStatuses}>
          <div className={statusesWrapper}>
            <Text className={marginReset} weight="semibold">
              Payments
              <Dot isActive={chargesEnabled} />
            </Text>
            <Text className={marginReset} weight="semibold">
              Payouts
              <Dot isActive={payoutsEnabled} />
            </Text>
            <Text className={marginReset} weight="semibold">
              Sunbit BNPL
              {isLoading ? <Loader /> : <Dot status={sunbitDotStatus} />}
            </Text>
          </div>
        </div>
        <div className={accounts}>
          <Text
            color="white"
            textAlign="center"
            weight="semibold"
            size="medium"
            className={title}
          >
            Stripe Account ID
          </Text>
          <Text textAlign="center" className={subtitle}>
            {activeProcessor?.stripeId}
          </Text>
        </div>
        <div className={accounts}>
          <Text
            color="white"
            textAlign="center"
            weight="semibold"
            size="medium"
            className={title}
          >
            Stripe Account Type
          </Text>
          <Text textAlign="center" className={subtitle}>
            {activeProcessor?.stripeAccountType}
          </Text>
        </div>
      </div>
      <FormRow>
        <SwitchField
          {...obpToggleProps}
          className={obpToggle}
          name="obp-flag-switcher"
          label="Online Bill Pay"
          onChange={handleObpToggleChange}
          disabled={isLoadingOBP}
        />
        {isLoadingOBP && <Loader />}
      </FormRow>
    </div>
  );
};
