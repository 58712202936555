import { css } from 'emotion';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';

export const tableView = css`
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 4px;

  th {
    border: none;
    border-bottom: none !important;
    color: ${weaveTheme.colors.gray[500]};
    font-weight: normal;
    font-size: ${weaveTheme.fontSize(16)};
    padding-top: ${weaveTheme.spacing(1.2)};
    padding-bottom: ${weaveTheme.spacing(1.2)};
    padding-left: ${weaveTheme.spacing(2.3)};
  }
  th:last-child {
    border-radius: 0 10px 10px 0;
  }
  th:first-child {
    border-radius: 10px 0 0 10px;
  }
  tr {
    border: none;
  }
  td {
    border: none;
    padding-top: ${weaveTheme.spacing(2.3)};
    padding-bottom: ${weaveTheme.spacing(2)};
    padding-left: ${weaveTheme.spacing(2.3)};
    font-size: ${weaveTheme.fontSize(15)};
  }
  thead {
    border: none;
  }
  tbody {
    border: none;
  }
`;

export const tableHeadRow = css`
  background-color: ${weaveTheme.colors.gray[200]};
`;

export const divCenterAlign = css`
  margin: 0 auto;
  margin-top: ${weaveTheme.spacing(3.5)};
`;
export const noteStyles = css`
  cursor: pointer;
  padding: ${weaveTheme.spacing(0.5)} ${weaveTheme.spacing(3)};
  &:hover {
    background: ${weaveTheme.colors.gray[100]};
  }
`;
export const modalHeadingStyle = css`
  align-items: center;
  justify-content: center;
  margin-bottom: ${weaveTheme.spacing(3.2)};
`;
export const deleteIconStyle = css`
  margin-top: -20%;
  margin-right: ${weaveTheme.spacing(2.5)};
  margin-bottom: ${weaveTheme.spacing(2.5)};
  cursor: pointer;
`;

export const textStyle = css`
  display: inline-block;
  margin-left: ${weaveTheme.spacing(2)};
  margin-top: ${weaveTheme.spacing(1.2)};
`;

export const timeStyle = css`
  margin-left: ${weaveTheme.spacing(2)};
`;

export const scrollableNote = css`
  max-height: 200px;
  overflow-y: scroll;
`;
export const buttonStyle = css`
  margin-top: ${weaveTheme.spacing(2.5)};
`;

export const resetButton = css`
  margin-right: ${weaveTheme.spacing(2.4)};
  margin-top: ${weaveTheme.spacing(1.2)};
  width: 160px;
`;

export const applyButton = css`
  margin-right: ${weaveTheme.spacing(2.4)};
  margin-top: ${weaveTheme.spacing(1.2)};
  width: 160px;
`;

export const containerButtonStyle = css`
  display: flex;
  margin-bottom: ${weaveTheme.spacing(2.4)};
  margin-left: ${weaveTheme.spacing(5)};
`;

export const filterContainer = css`
  display: flex;
  margin-top: 3%;
  height: fit-content;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 4px;
  margin-left: ${weaveTheme.spacing(1.2)};
  border-radius: ${weaveTheme.spacing(1.2)};
`;

export const radioButtonContainer = css`
  display: flex;
  justify-content: space-evenly;
`;

export const containerDiv = css`
  margin-top: ${weaveTheme.spacing(1.2)};
`;
export const circle_container = css`
  width: 17px;
  height: 17px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: ${weaveTheme.spacing(-0.25)};
  right: ${weaveTheme.spacing(2)};
`;
export const circle_main = css`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid ${weaveTheme.colors.weaveBlue}; /* can alter thickness and colour of circle on this line */
  background-color: ${weaveTheme.colors.weaveBlue};
  margin: ${weaveTheme.spacing(0)};
  padding: ${weaveTheme.spacing(0)};
`;

export const circle_text_container = css`
  /* area constraints */
  width: 70%;
  height: 70%;
  max-width: 70%;
  max-height: 70%;
  margin: ${weaveTheme.spacing(0)};
  padding: ${weaveTheme.spacing(0)};

  /* some position nudging to center the text area */
  position: relative;

  top: 15%;

  /* preserve 3d prevents blurring sometimes caused by the text centering in the next class */
  transform-style: preserve-3d;
`;
export const circle_text = css`
  /* change font/size/etc here */
  font-size: small;
  text-align: center;
  color: white;
  font-weight: bold;
  /* vertical centering technique */
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
export const radioFieldStyle = css`
  label {
    width: 100%;
  }
  div {
    margin-bottom: ${weaveTheme.spacing(0)};
  }
`;

export const checkboxFieldStyle = css`
  display: grid !important;
  padding-left: ${weaveTheme.spacing(1.2)};
  margin-top: ${weaveTheme.spacing(2)};
  label {
    margin-top: ${weaveTheme.spacing(-4.5)};
  }
`;

export const secondaryField = css`
  width: 18%;
  padding-right: ${weaveTheme.spacing(0.5)};
`;

export const potalCodeField = css`
  width: 15%;
  padding-right: ${weaveTheme.spacing(0.5)};
`;

export const countryField = css`
  width: 11%;
`;

export const noNotesStyle = css`
  text-align: center;
  margin-top: ${weaveTheme.spacing(2.5)};
  margin-bottom: ${weaveTheme.spacing(0)};
`;

export const radioFieldSelectStyle = css`
  label {
    width: 100%;
  }
  div {
    margin-bottom: ${weaveTheme.spacing(0)};
    margin-left: ${weaveTheme.spacing(1)};
    margin-top: ${weaveTheme.spacing(2.4)};
  }
`;

export const mainContainer = css`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
`;

export const tableContainer = css`
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #dadada;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
`;

export const tableRow = css`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  border-bottom: 1px solid #dadada;
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const heading = css`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  background-color: ${weaveTheme.colors.gray[200]};
  color: ${weaveTheme.colors.gray[500]};
`;

export const rowItem = css`
  flex: 1;
  font-size: 14px;
  padding: 8px 0;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
  word-wrap: break-word;
  display: inline-block;
  overflow-x: scroll;
`;

export const textAreaFieldStyle = css`
  width: 100px;
`;
export const postalCodeLableStyle = css`
  width: 100px;
  label {
    padding: 0px;
  }
`;
export const deleteIconContainer = css`
  display: flex;
  justify-content: end;
`;

export const containerDivRadioField = css`
  margin-top: -7.5%;
  margin-left: 5%;
`;

export const textAreaFieldPostalStyle = css`
  width: 100px;

  label {
    top: ${weaveTheme.spacing(-0.8)};
  }
`;

export const textAreaFieldCountryStyle = css`
  width: 100px;
  label {
    left: ${weaveTheme.spacing(0.8)};
  }
`;
