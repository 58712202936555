import React from 'react';
import { css } from '@emotion/core';
import moment from 'moment';
import { formatCentsToCurrency } from '../../../helpers/currency';
import { theme } from '@weave/theme-original';
import { Text } from '@weave/design-system';

type CurrentPricingProps = {
  title: string;
  processingRate: number;
  transactionCost: number;
  date: string;
};

export const CurrentPricing = (props: CurrentPricingProps) => {
  return (
    <div
      css={css`
        &:not(:last-child) {
          margin-right: 30px;
        }
        div:nth-child(odd) {
          background-color: #f5f5f5;
        }
        border-radius: ${theme.borderRadius.medium};
        overflow: hidden;
        box-shadow: ${theme.shadows.heavy};
      `}
    >
      <Text
        css={css`
          background-color: ${theme.colors.weaveBlue};
          margin: 0px;
          padding: 10px;
          color: white;
        `}
        weight="bold"
        textAlign="center"
      >
        {props.title}
      </Text>
      <PricingRow label="Processing Rate" value={`${props.processingRate / 100}%`} />
      <PricingRow
        label="Transaction Cost"
        value={`${formatCentsToCurrency(props.transactionCost)}`}
      />
      <PricingRow label="Date" value={moment(props.date).format('MMM D, YYYY')} />
    </div>
  );
};

type PricingRowProps = {
  label: string;
  value: string;
};

const PricingRow = ({ label, value }: PricingRowProps) => (
  <div
    css={css`
      display: flex;
      padding: 15px;
      width: 300px;
      align-items: center;
    `}
  >
    <Text
      css={css`
        margin-bottom: 0px;
      `}
      size="medium"
      weight="semibold"
    >
      <strong>{label}</strong>
    </Text>
    <Text
      weight="medium"
      css={css`
        margin-bottom: 0px;
        margin-left: auto;
        color: ${label === 'Date' ? theme.colors.gray500 : theme.colors.gray600};
      `}
    >
      {value}
    </Text>
  </div>
);
