import React, { useState } from 'react';
import { css } from '@emotion/core';
import {
  useFormField,
  TextField,
  EditSimpleIcon,
  Heading,
  ValidatorFieldState,
} from '@weave/design-system';
import { theme } from '@weave/theme';

interface Props {
  name: string;
  editable?: boolean;
  onNameChange: (name: string, successCallback: () => void) => void;
}

export const LocationGroupName = ({ name, editable = false, onNameChange }: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const fieldProps = useFormField({
    type: 'text',
    value: name,
    required: true,
    validateOnChange: true,
    validator: ({ value }: ValidatorFieldState<'text'>) => {
      return value.length > 75 ? 'Must be less then or equal to 75 characters.' : '';
    },
  });

  const handleSaveClick = () => {
    if (name === fieldProps.value) {
      setIsEditMode(false);
      return;
    }

    onNameChange(fieldProps.value, () => setIsEditMode(false));
  };

  if (!editable) {
    return (
      <Heading level={2} css={headingStyle}>
        {fieldProps.value}
      </Heading>
    );
  }

  if (isEditMode) {
    return (
      <TextField
        css={{ width: 318 }}
        name="location-group-name"
        label="Location Group Name"
        actionText="Save"
        autoComplete="off"
        onActionClick={handleSaveClick}
        {...fieldProps}
      />
    );
  }

  return (
    <div css={containerStyle} onClick={() => setIsEditMode(true)}>
      <Heading level={2} css={headingStyle}>
        {fieldProps.value}
      </Heading>
      <EditSimpleIcon size={16} />
    </div>
  );
};

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: '2px',
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  gap: theme.spacing(1),
  cursor: 'pointer',
  color: theme.colors.neutral50,
  maxWidth: '70%',
});

const headingStyle = css({
  '&&': {
    color: theme.colors.neutral50,
  },
  margin: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
