import { OktaAuth } from '@okta/okta-auth-js';
import { WebAuth } from 'auth0-js';
import { AxiosRequestConfig } from 'axios';
import { Dispatch } from 'react';

export enum CoreACLs {
  UNKNOWN = 0,
  FEATUREFLAGREAD = 10,
  FEATUREFLAGWRITE = 11,
  FEATUREFLAGCREATE = 12,
  OUTAGEREPORT = 13,
  FEATURE_FLAG_SET_LOCATION = 15, // allows for setting feature flags at the location level
  FEATURE_FLAG_BULK_ADMIN = 16, // allows for bulk updating feature flag values such as all, percent or default
  USERDELETE = 20, // delete a user
  USERWRITE = 21, // modify other users
  APPRELEASEWRITE = 22, // modify Desktop Releases
  WEAVEACLMANAGE = 23, // Allows for the setting of roles meant specifically for Weave employees
  AUDITLOGREAD = 24, // allows user to query audit log
  WEAVEROLEACLREAD = 25,
  SYNCAPPINSTALLADVANCED = 203,
  ANAlYTICSADMIN = 204,
  SYNCAPPMANAGEADVANCED = 205,
  SYNCAPPMANAGE = 206,
  SYNCAPPBETAFEATURE = 207,
  WEAVEVERIFYPERMITTED = 208,
  CALLRECORDINGSETTINGWRITE = 400, // change the call recording setting
  CALLRECORDINGREAD = 401, // listen to call recordings
  CALLFOWARDINGSETTINGWRITE = 402, // change the call forwarding setting
  OFFICEHOURSSETTINGWRITE = 403, // change office hours
  VOICEMAILOVERRIDEWRITE = 404,
  PHONEDATACENTERWRITE = 406, // Update data centers
  BILLINGINFORMATIONREAD = 407, // read credit card information from twocp
  BILLINGINFORMATIONWRITE = 408, // update credit card information in twocp
  SIPSTATUSWRITE = 410, // Update SIP device status
  WRITELOCATIONS = 500, // create/update locations
  WRITEMULTILOCATIONCONFIGS = 501, // create parent/child location relationships
  AUTOMATEDNOTIFICATIONQUEUEWRITE = 600, // clear/reload sms queue
  CALLQUEUEWRITE = 416, // allows modification of call queues
  E911ADDRESDREADWRITE = 409, // manage e911 address info

  CUSTOMIZATION_FLAGS_WRITE = 14, // allows user to manage customization flags
  INTACCT_INVOICE_DELETE = 1100, // allows for deleting invoices generated by Intacct in WAM
}

export class AuthedUser {
  ACLS: { [locationName: string]: CoreACLs[] } = {};
  exp?: number;
  expBuffer?: number;
  type = '';
  username = '';
  user_id = '';
}

export class Auth0TokenPayload {
  atHash = '';
  aud = '';
  email = '';
  emailVerified?: boolean;
  exp?: number;
  familyName = '';
  givenName = '';
  iat?: number;
  iss = '';
  locale = '';
  name = '';
  nickname = '';
  nonce = '';
  picture = '';
  sub = '';
  updatedAt = '';
}

export class Auth0User {
  accessToken = '';
  appState = '';
  expiresIn?: number;
  idToken = '';
  idTokenPayload?: Auth0TokenPayload;
  refreshToken = '';
  scope = '';
  state = '';
  tokenType = '';
}

export class SignInUser {
  auth0?: Auth0User;
  username?: string;
  password?: string;
}

export interface AuthReducerState {
  token: string | null;
  user: AuthedUser;
  isRefreshing: boolean;
  isLoading: boolean;
}

export type GoogleAuthAction = {
  type: string;
  payload: GoogleAuthPayload;
};

export type OktaAuthAction = {
  type: string;
  payload: OktaAuthPayload;
};

export type GoogleAuthPayload = {
  credentials: SignInUser;
  redirectPath: string;
};

export type OktaAuthPayload = {
  tokens: {
    oktaIdToken: string;
    oktaAccessToken: string;
  };
  email: string;
  redirectPath: string;
};

export type AuthorizationPayload = {
  token: string;
  redirectPath: string;
  oktaAccessToken?: string | DecodedAccessToken;
};
export interface Authorization {
  loading: boolean;
  success: boolean;
  getLoading: boolean;
  getSuccess: boolean;
  error?: { [key: string]: string };
}

export type JwtPayload = {
  ACLS: { [key: string]: CoreACLs[] };
  aud: string[];
  exp: number;
  expBuffer: number;
  iat: number;
  jti: string;
  user_id?: string;
  username?: string;
};

export interface DecodedToken {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  sub: string;
}

export enum Scopes {
  openid = 'openid',
  roles = 'roles',
  email = 'email',
  profile = 'profile',
}
export interface DecodedAccessToken extends DecodedToken {
  cid: string;
  scp: Scopes;
  uid: string;
  roles: { locations: { name: string; roles: string[] }[] };
  Locations: string[];
  WeaveUserID?: string;
}

export const defaultState: Authorization = {
  loading: false,
  success: false,
  getLoading: false,
  getSuccess: false,
};

/* Auth Provider Types */
type signOut = (expired?: boolean) => void;

export type AuthContextValue = {
  signInMode: string;
  oktaAuth: OktaAuth;
  googleAuth: WebAuth;
  handleSignOut: signOut;
  setSignInMode: (mode: string) => void;
  handleGoogleAuthentication: (initRoute: string) => void;
};

export type UserAuthenticatedParams = {
  dispatch: Dispatch<any>;
  signOut: signOut;
  alerts: any;
  encodedWeaveToken: string;
  decodedWeaveToken: JwtPayload;
  config?: AxiosRequestConfig;
  oktaAccessToken?: DecodedAccessToken;
  redirectPath?: string;
};

export enum SignInMode {
  okta = 'okta',
  google = 'google',
}

export enum AuthStorage {
  okta_id_token = 'okta_id_token',
  okta_access_token = 'okta_access_token',
  weave_token = 'token',
  firebase_token = 'firebase-token',
  midway = 'midway',
  active_location = 'location-history',
  user_email = 'user_email',
  redirect_path = 'redirect_path',
}

export type AuthProviderProps = { oktaAuth: OktaAuth; googleAuth: WebAuth };
