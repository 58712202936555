import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { css } from '@emotion/core';

import {
  Text,
  PrimaryButton,
  AlertIcon,
  CheckIcon,
  Modal,
  ContentLoader,
  ModalControlModalProps,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { useAlert } from '@weave/alert-system';
import { AddressElement } from '@stripe/react-stripe-js';
import { StripeAddressElementOptions } from '@stripe/stripe-js';

import { CustomAxios } from '../../redux/axios';
import {
  selectPaymentUrl,
  getMerchant,
} from '../../redux/actions/merchant/merchant.action';
import { selectCurrentLocationId } from '../../redux/actions/location/current-location';
import { useAddressForm } from '../generic/address-autocomplete/use-address-form';
import { terminalLocation } from './queries/terminal-registration-address.query';

interface Props {
  modalProps: ModalControlModalProps;
  openModal: () => void;
  closeModal: () => void;
  hasTerminalLocation: boolean | undefined;
}

export const TerminalLocationAddress = ({
  modalProps,
  openModal,
  closeModal,
  hasTerminalLocation,
}: Props) => {
  const dispatch = useDispatch();
  const paymentsUrl = useSelector(selectPaymentUrl);
  const locationId = useSelector(selectCurrentLocationId);

  const { handleAddressChange, address, isValid, reset } = useAddressForm(null);
  const alerts = useAlert();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleTerminalAddressSubmit = async () => {
    setIsSubmitting(true);
    try {
      await CustomAxios.post(`${paymentsUrl}/v1/gql/q`, {
        query: terminalLocation,
        variables: {
          address: {
            locationId: locationId,
            addressLine1: address.line1,
            city: address.city,
            state: address.state,
            postalCode: address.postal,
          },
        },
      });
      setIsSubmitting(false);
      dispatch(getMerchant());
      closeModal();
      alerts.success({
        autoDismissAfter: 2000,
        message: `Business Address was successfully submitted`,
      });
    } catch {
      alerts.error({
        autoDismissAfter: 2000,
        message: `There was an issue submitting Business Address`,
      });
      setIsSubmitting(false);
    }
  };

  return (
    <section
      css={css`
        background-color: ${theme.colors.gray100};
        padding: ${theme.spacing(4)};
        margin-bottom: ${theme.spacing(4)};
        border-radius: ${theme.borderRadius.medium};
        max-width: 600px;
        text-align: center;
        border: 1px solid
          ${hasTerminalLocation ? theme.colors.success : theme.colors.error};
      `}
    >
      {hasTerminalLocation ? (
        <CheckIcon color="success" size={60} />
      ) : (
        <AlertIcon color="error" size={60} />
      )}
      <Text
        css={css`
          margin: ${theme.spacing(3)} 0 ${theme.spacing(3)} 0;
          font-size: ${theme.fontSize(18)};
        `}
      >
        {hasTerminalLocation
          ? `A terminal location address has already been submitted for this location.`
          : `This location does not have a complete terminal location address on file, as a result they may not be able to register new physical readers. Please add primary place of business for the terminal address.`}
      </Text>
      <PrimaryButton
        onClick={openModal}
        css={css`
          width: auto;
        `}
      >
        {`${hasTerminalLocation ? 'Update' : 'Add'} Terminal Address`}
      </PrimaryButton>
      <Modal
        {...modalProps}
        onClose={() => {
          reset();
          closeModal();
        }}
        title="Confirm this action"
        css={css`
          width: auto;
        `}
      >
        <Modal.Header>Terminal Location Address</Modal.Header>
        <Modal.Body
          css={css`
            overflow-y: visible;
          `}
        >
          {' '}
          <Text>Ask the customer for the primary business address.</Text>
          <AddressElement
            options={{
              mode: 'billing',
              defaultValues: { address: { country: 'US' } },
            }}
            onChange={handleAddressChange}
          />
        </Modal.Body>
        <Modal.Actions
          primaryLabel="Submit"
          disablePrimary={!isValid}
          onPrimaryClick={() => handleTerminalAddressSubmit()}
          secondaryLabel="Cancel"
          onSecondaryClick={() => {
            reset();
            closeModal();
          }}
        />
        <ContentLoader
          show={isSubmitting}
          message="Submitting Terminal location address..."
          backgroundOpacity={0}
        />
      </Modal>
    </section>
  );
};
