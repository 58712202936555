import React, { useEffect, useState } from 'react';
import {
  CaretDownIconSmall,
  CaretUpIconSmall,
  FlyoutMenuAction,
  Modal,
  MultiActionButton,
  PrimaryButton,
  SpinningLoader,
  Text,
  TextLink,
  useModalControl,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { IntakeFormLocationResponse, ProvisionerData } from '../api';
import { theme } from '@weave/theme-original';
import { onboardingIntakeFormApi } from '../../onboarding.api';
import { useMutation, useQuery } from 'react-query';
import { useAlert } from '@weave/alert-system';
import { IntakeFormTableComponent } from '../intake-form-table/intake-form-table.component';
import { CustomAxios } from '../../../../redux/axios';
import { CustomizationFlagStatesV2 } from '../../../../models/location-feature.model';
import { getCustomizationsFlagsByLocationIdURL } from 'redux/actions/customization/customization.action';
import { CustomizationFlagsLocationFeaturesResponse } from 'redux/actions/customization/customization.types';
import { IntakeFormSuccessModalComponent } from './intake-form-success-modal.component';
import { IntakeFormSingleLocationErrorModalComponent } from './intake-form-single-location-error-modal.component';
import { IntakeFormMultiLocationErrorModalComponent } from './intake-form-multi-location-error-modal.component';
import { IntakeFormSendMultModalComponent } from './intake-form-send-mult-modal.component';
import { IntakeFormSendFullFormModalComponent } from './intake-form-send-full-form-modal.component';

const getLocationWiseFeatures = async (
  locationIds: string[]
): Promise<{ locationId: string; isSoftwareOnly: boolean }[]> => {
  const response = await Promise.all(
    locationIds.map(async (locationId: string) => {
      const customizationFlagsLocationFeaturesURL =
        getCustomizationsFlagsByLocationIdURL(locationId);
      const response = await CustomAxios.get<CustomizationFlagsLocationFeaturesResponse>(
        customizationFlagsLocationFeaturesURL,
        { headers: { 'Location-Id': locationId } }
      );
      const features = response?.data?.features;
      const isSoftwareOnly = features?.some(
        (feature) =>
          feature.name === 'Phone Home Icon' &&
          feature.state === CustomizationFlagStatesV2.HIDE
      );
      return { locationId, isSoftwareOnly };
    })
  );
  return response;
};

type Props = {
  locationData: ProvisionerData;
  locationId: string;
  refreshData: () => void;
  refetchEmailData: boolean;
  provisionData: ProvisionerData[];
};

export const IntakeFormCardComponent = ({
  locationData,
  locationId,
  refreshData,
  refetchEmailData,
  provisionData,
}: Props) => {
  const alerts = useAlert();
  const [toggleCardDropdown, setToggleCardDropdown] = useState(true);
  const [toggleSeeMoreLocations, setToggleSeeMoreLocations] = useState(true);
  const isMultiLocation = provisionData.length > 1 || locationData.locations.length > 1;
  const { modalProps, triggerProps } = useModalControl();
  const {
    modalProps: sendWelcomeCallButtonResultModalProps,
    triggerProps: sendWelcomeCallButtonResultTriggerProps,
  } = useModalControl();
  const {
    modalProps: errorModalPropsSingleLocation,
    triggerProps: errorTriggerPropsSingleLocation,
  } = useModalControl();
  const {
    modalProps: errorModalPropsMultiLocation,
    triggerProps: errorTriggerPropsMultiLocation,
  } = useModalControl();
  const {
    modalProps: multiConfirmationModalProps,
    triggerProps: multiConfirmationTriggerProps,
  } = useModalControl();
  const {
    modalProps: fullFormConfirmationModalProps,
    triggerProps: fullFormConfirmationModalTriggerProps,
  } = useModalControl();

  // TODO: we should be calling this only if user click send intake-form button
  const locationIds = locationData.locations.map((location) => location.locationId);
  const { data: locationWiseFeatures } = useQuery({
    queryKey: ['location-wise-features', ...locationIds],
    queryFn: () => getLocationWiseFeatures(locationIds),
    onError: () => alerts.error('Error while fetching feature flags'),
  });

  const { data, mutate, isLoading } = useMutation(
    onboardingIntakeFormApi.sendWelcomeCallEmail,
    {
      mutationKey: locationData?.onboardingContactEmail,
      onSettled: (data, error) => {
        const onboardingErrors = data.locations.filter(
          (onboardingError: IntakeFormLocationResponse) =>
            onboardingError.onboardingError !== ''
        );

        if (onboardingErrors.length === 0) {
          alerts.success('Send Intake Form successfully sent');
          refreshData();
          sendWelcomeCallButtonResultTriggerProps.onClick();
          return;
        }

        if (isMultiLocation) {
          alerts.warning('Send Intake Form failed to send for some locations');
          refreshData();
          errorTriggerPropsMultiLocation.onClick();
        } else {
          alerts.error('Send Intake Form failed to send');
          errorTriggerPropsSingleLocation.onClick();
        }
      },

      onError: (error) => {
        alerts.error('Failed to send intake form');
        console.error(error);
        if (isMultiLocation) {
          errorTriggerPropsMultiLocation.onClick();
        } else {
          errorTriggerPropsSingleLocation.onClick();
        }
      },
    }
  );

  const handleToggleCardDropdown = () => {
    setToggleCardDropdown(!toggleCardDropdown);
  };

  const sendIntakeFormClickHandler = (isMultiForm: boolean) => {
    if (!locationWiseFeatures || !locationWiseFeatures?.length) {
      alerts.error('Error while fetching features');
      return;
    }
    mutate({
      onboardingContactEmail: locationData.onboardingContactEmail,
      locations: locationWiseFeatures.map(({ isSoftwareOnly, locationId }) => ({
        isMultiForm: isMultiForm,
        locationId,
        isSoftwareOnlyForm: isSoftwareOnly,
      })),
    });
  };

  const handleMultiModalState = () => {
    sendIntakeFormClickHandler(true);
    multiConfirmationModalProps.onClose();
  };

  const handleFullMudalState = () => {
    sendIntakeFormClickHandler(false);
    fullFormConfirmationModalProps.onClose();
  };

  const sendMultiFormActions: FlyoutMenuAction[] = [
    {
      label: 'Send Multi Form',
      onClick: () => {
        multiConfirmationTriggerProps.onClick();
      },
      trackingId: 'send-multi-form-multi-button-action',
    },
    {
      label: 'Send Full Form',
      onClick: () => fullFormConfirmationModalTriggerProps.onClick(),
      trackingId: 'send-full-form-multi-button-action',
    },
  ];
  const intakeFormResponseErrorResponse = data?.locations?.filter(
    (data) => data.onboardingError !== ''
  );
  const successfulFormsSentResponse = data?.locations?.filter(
    (response) => response.onboardingError === ''
  );
  const hasLocationErrorAndStartDate = locationData?.locations?.some(
    (data) => !data.provisionerError && !data.intakeFormStartedAt
  );

  const isSoftwareOnlyCheck = locationWiseFeatures?.some(
    (locationData) => locationData.isSoftwareOnly
  );

  return (
    <>
      {locationData && locationData.locations.length === 0 && (
        <Text weight="bold" css={emptyTableStyles}>
          There are no pending locations for onboarding
        </Text>
      )}
      <section css={formContainer} data-testid="send-welcome-call-section">
        <>
          <div css={headerContainer}>
            <div css={contactInfo}>
              <Text weight="semibold">Onboarding Point of Contact</Text>
              <Text>
                {locationData.onboardingContactFirstname}{' '}
                {locationData.onboardingContactLastname}
              </Text>
              <Text>{locationData.onboardingContactEmail}</Text>
              <TextLink
                css={contactInfoLink}
                {...triggerProps}
                data-testid="edit-contact-info-modal"
              >
                Need to edit contact info?
              </TextLink>
            </div>
            <div
              css={css`
                display: flex;
              `}
            >
              {isLoading && <SpinningLoader css={spinningLoaderStyles} />}
              <div>
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  {isMultiLocation && (
                    <PrimaryButton
                      css={sendButton}
                      size="large"
                      onClick={() => {
                        sendIntakeFormClickHandler(true);
                      }}
                      data-testId="send-intake-form-button"
                      disabled={isLoading || !hasLocationErrorAndStartDate}
                    >
                      Send Intake Form
                    </PrimaryButton>
                  )}

                  {!isMultiLocation && (
                    <MultiActionButton
                      actions={sendMultiFormActions}
                      label="Send Intake Form"
                      data-testId="single-location-send-intake-form-button"
                      onClick={() => {
                        sendIntakeFormClickHandler(false);
                      }}
                      css={multiActionButtonStyles}
                      disabled={isLoading || !hasLocationErrorAndStartDate}
                    />
                  )}
                </div>
              </div>
              {provisionData.length > 1 || locationData.locations.length > 1 ? (
                <div css={dropdownCaretStyles} onClick={handleToggleCardDropdown}>
                  {!toggleCardDropdown ? (
                    <CaretDownIconSmall color="light" />
                  ) : (
                    <CaretUpIconSmall color="light" />
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div>
            {provisionData.length > 1 || locationData.locations.length > 1 ? (
              toggleCardDropdown && (
                <IntakeFormTableComponent
                  data={locationData}
                  emailContact={locationData.onboardingContactEmail}
                  locationId={locationId}
                  refetchData={refetchEmailData}
                />
              )
            ) : (
              <IntakeFormTableComponent
                data={locationData}
                emailContact={locationData.onboardingContactEmail}
                locationId={locationId}
                refetchData={refetchEmailData}
              />
            )}
          </div>
        </>
      </section>
      <Modal {...modalProps}>
        <Modal.Header textAlign="left" css={modalHeaderStyle}>
          Updating Contact Information
        </Modal.Header>
        <Modal.Body css={modalBodyStyle}>
          <Text>
            In order to make updates to the Onboarding Point of Contact information please
            go to the account page in Salesforce. Once you make the changes there, refresh
            this page to see your changes.
          </Text>
        </Modal.Body>
        <Modal.Actions
          onPrimaryClick={modalProps.onClose}
          primaryLabel="Close"
        ></Modal.Actions>
      </Modal>
      <IntakeFormSuccessModalComponent
        sendWelcomeCallButtonResultModalProps={sendWelcomeCallButtonResultModalProps}
        locationData={locationData}
        setToggleSeeMoreLocations={setToggleSeeMoreLocations}
        toggleSeeMoreLocations={toggleSeeMoreLocations}
        handleSendIntakeFormData={data}
        successfulFormsSentResponse={successfulFormsSentResponse}
        isMultiLocation={isMultiLocation}
      />
      <IntakeFormSingleLocationErrorModalComponent
        errorModalPropsSingleLocation={errorModalPropsSingleLocation}
        intakeFormResponseErrorResponse={intakeFormResponseErrorResponse}
      />
      <IntakeFormMultiLocationErrorModalComponent
        errorModalPropsMultiLocation={errorModalPropsMultiLocation}
        locationData={locationData}
        handleSendIntakeFormData={data}
        intakeFormResponseErrorResponse={intakeFormResponseErrorResponse}
        successfulFormsSentResponse={successfulFormsSentResponse}
        setToggleSeeMoreLocations={setToggleSeeMoreLocations}
        toggleSeeMoreLocations={toggleSeeMoreLocations}
      />

      <IntakeFormSendMultModalComponent
        multiConfirmationModalProps={multiConfirmationModalProps}
        isSoftwareOnlyCheck={isSoftwareOnlyCheck}
        handleMultiModalState={handleMultiModalState}
      />
      <IntakeFormSendFullFormModalComponent
        fullFormConfirmationModalProps={fullFormConfirmationModalProps}
        isSoftwareOnlyCheck={isSoftwareOnlyCheck}
        handleFullMudalState={handleFullMudalState}
      />
    </>
  );
};

export const formContainer = css`
  border-radius: 10px;
  margin-bottom: ${theme.spacing(3)};
  box-shadow: ${theme.shadows.heavy};
`;

export const headerContainer = css`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(3)};
`;

export const contactInfo = css`
  display: flex;
  flex-direction: column;

  p {
    margin: ${theme.spacing(1)} 0;
  }

  p:nth-child(n + 2):nth-child(-n + 3) {
    margin: 0;
  }
`;
export const sendButton = css`
  max-width: 220px;
`;

export const multiActionButtonStyles = css`
  width: 270px;
`;

export const contactInfoLink = css`
  color: ${theme.colors.weaveBlue};
  text-align: start;
  cursor: pointer;
`;

export const emptyTableStyles = css`
  width: 100%;
  text-align: center;
  padding: ${theme.spacing(2, 0)};
`;

export const modalBodyStyle = css`
  text-align: left;
`;

export const dropdownCaretStyles = css`
  display: flex;
  cursor: pointer;
  margin-left: ${theme.spacing(3)};
`;

export const spinningLoaderStyles = css`
  margin-right: ${theme.spacing(8)};
`;

export const successModalContentStyle = css`
  margin: ${theme.spacing(0, 0, 2)};
  p:first-child {
    margin-top: ${theme.spacing(3)};
    font-weight: 600;
  }
`;

export const modalContentStyles = css`
  font-weight: 600;
  margin: ${theme.spacing(2, 0, 1)};
`;

export const sectionsIncludedStyles = css`
  display: flex;
  p {
    margin-bottom: ${theme.spacing(1)};
  }
  svg {
    margin-right: ${theme.spacing(2)};
  }
`;

export const modalHeaderStyle = css`
  font-size: ${theme.fontSize(24)};
`;

export const sentToEmailStyle = css`
  display: flex;
  p:first-child {
    margin-right: ${theme.spacing(5)};
  }
`;

export const displayStyles = css`
  display: flex;
  align-items: baseline;
`;

export const subTitleStyle = css`
  font-size: 20px;
  font-weight: bold;
`;

export const modalContentBodyStyles = css`
  margin-bottom: 0;
`;

export const caretColor = css`
  color: ${theme.colors.weaveBlue};
`;
