export type Timezone =
  | '-02:30'
  | '-03:00'
  | '-03:30'
  | '-04:00'
  | '-05:00'
  | '-06:00'
  | '-07:00'
  | '-08:00'
  | '-09:00'
  | '-10:00';

const dsTimezoneMapping = {
  '-02:30': 'NDT',
  '-03:00': 'ADT',
  '-04:00': 'EDT',
  '-05:00': 'CDT',
  '-06:00': 'MDT',
  '-07:00': 'PDT',
  '-08:00': 'AKDT',
  '-09:00': 'HDT',
};

const timezoneMapping = {
  '-03:30': 'NST',
  '-04:00': 'AST',
  '-05:00': 'EST',
  '-06:00': 'CST',
  '-07:00': 'MST',
  '-08:00': 'PST',
  '-09:00': 'AKST',
  '-10:00': 'HST',
};

const checkDaylightSavings = () => {
  const current = new Date();
  const janTz = new Date(current.getFullYear(), 1, 1).getTimezoneOffset();
  const currentTz = current.getTimezoneOffset();
  return Math.max(currentTz, janTz) !== current.getTimezoneOffset();
};

export const getTimezoneName = (timezone: Timezone) =>
  checkDaylightSavings() ? dsTimezoneMapping[timezone] : timezoneMapping[timezone];
