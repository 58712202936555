import React from 'react';
import { ExternalLinkIcon, TextLink } from '@weave/design-system';
import { theme } from '@weave/theme';

interface Props {
  locationId: string;
  iconOnly?: boolean;
  iconSize?: number;
}

export const IntakeFormLink = ({ locationId, iconSize, iconOnly = false }: Props) => {
  return (
    <TextLink
      css={{ display: 'flex', gap: theme.spacing(1) }}
      title="Open Intake Form"
      href={`/location/${locationId}/handoff/intake-form`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <ExternalLinkIcon size={iconSize} />
      {!iconOnly && 'Go to intake Form'}
    </TextLink>
  );
};
