import React, { useEffect, useState } from 'react';
import {
  ButtonBar,
  CheckboxField,
  Heading,
  PrimaryButton,
  Table,
  Text,
  useFormField,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import { RouteComponentProps } from 'react-router';
import { Page } from '../../shared/page/page.component';
import { useSelector } from 'react-redux';
import {
  selectCurrentLocationId,
  selectCurrentLocationParentId,
} from '../../../redux/actions/location';
import {
  deunifyApi,
  useGetDeunifyPreview,
  useGetDeunifyStatus,
} from 'apis/deunify/deunify.api';
import { useAlert } from '@weave/alert-system';
import { useQueryClient } from 'react-query';

export const Deunification: React.FC<RouteComponentProps> = () => {
  const { data } = useGetDeunifyPreview();
  const alerts = useAlert();
  const locationId = useSelector(selectCurrentLocationId);
  const queryClient = useQueryClient();
  const { data: deunifyStatus } = useGetDeunifyStatus();
  const renumberProp = useFormField({ type: 'checkbox' });

  const deunifyLocation = (shouldRenumber: boolean) =>
    deunifyApi
      .deunifyLocation({ renumber: shouldRenumber })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [locationId, 'getDeunifyStatus'] });
        alerts.success('Deunify Location successful!');
      })
      .catch(() => alerts.error('Deunify Location failed!'));

  const deunifyMedia = () =>
    deunifyApi
      .deunifyMedia()
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [locationId, 'getDeunifyStatus'] });
        alerts.success('Deunify Media successful!');
      })
      .catch(() => alerts.error('Deunify Media failed!'));

  const deunifyPhoneNumbersAndDevices = () =>
    deunifyApi
      .deunifyCommit()
      .then((res) => {
        queryClient.invalidateQueries({ queryKey: [locationId, 'getDeunifyStatus'] });
        res.data.isDeunified
          ? alerts.success('Migrate successful!')
          : alerts.error('Migrate failed!');
      })
      .catch(() => alerts.error('Migrate failed!'));

  const revertDeunifyPhoneNumbersAndDevices = () =>
    deunifyApi
      .revertDeunifyCommit()
      .then((res) => {
        queryClient.invalidateQueries({ queryKey: [locationId, 'getDeunifyStatus'] });
        res.data.isReverted
          ? alerts.success('Revert Migrate successful!')
          : alerts.error('Revert Migrate failed!');
      })
      .catch(() => alerts.error('Revert Migrate failed!'));

  const parentLocationId = useSelector(selectCurrentLocationParentId);

  const CustomTable = ({ header, data, id }) => (
    <Table
      colConfig={[
        {
          Header: header,
          accessor: (name) => {
            const isError = header === 'Error';
            return (
              <span css={{ color: isError ? theme.colors.error : 'inherit' }}>
                {name}
              </span>
            );
          },
          id: id,
        },
      ]}
      data={data}
    />
  );

  const tableConfigs = [
    { key: 'CALL_GROUPS', header: 'Call Groups', id: 'call group' },
    { key: 'DEVICES', header: 'Devices', id: 'device' },
    { key: 'EXTENSIONS', header: 'Extensions', id: 'extension' },
    { key: 'IVR_MENUS', header: 'Phone Trees', id: 'ivr menu' },
    { key: 'PHONE_NUMBERS', header: 'Phone Numbers', id: 'phone number' },
    { key: 'VM_BOXES', header: 'Voicemail Boxes', id: 'voicemail box' },
  ];

  if (!parentLocationId) {
    return <>No Parent Location</>;
  }

  return (
    <Page
      title={'Deunification'}
      subtitle={
        'The Deunification tool is used to migrate a single Unify child location from its shared phone tenant to its own stand-alone tenant. Using this tool can impact the user experience so please ensure proper approvals have been had and the customer is aware before proceeding.'
      }
    >
      <div css={{ width: '750px' }}>
        <Heading level={2} css={{ marginTop: theme.spacing(6) }}>
          Preview
        </Heading>
        <Text size="small">
          The preview section shows the names of the Call Groups, Devices, Extensions,
          Phone Trees, Phone Numbers, and Voicemail Boxes that will be migrated to the
          single-location phone tenant. Please review for accuracy to ensure the customer
          has what they need for their call experience after migration. If any item is
          missing please review location-id labels.
        </Text>
        <div css={{ fontStyle: 'italic' }}>
          <Text size="small">Notes:</Text>
          <Text size="small">
            * Voicemail boxes used in the child location routing (e.g. in a phone tree)
            and are not associated to devices or SIP profiles will not show in the
            preview, but the voicemail boxes will be copied during Step 1: Deunify
            Location.
          </Text>
          <Text size="small">
            * The tool will only deunify desk phone devices, not softphones or mobile
            apps.
          </Text>
        </div>
        {!!data?.errors?.length &&
          data.errors.map((error) => <Text color="error">{error}</Text>)}
        {!data?.errors?.length &&
          tableConfigs.map(
            (config) =>
              data?.usage && (
                <CustomTable
                  key={config.id}
                  header={config.header}
                  data={
                    data?.usage[config.key]?.names?.length
                      ? data?.usage[config.key]?.names
                      : [`No ${config.id} exists.`]
                  }
                  id={config.id}
                />
              )
          )}
        <Heading level={2} css={{ marginTop: theme.spacing(6) }}>
          Step 1: Deunify Location
        </Heading>
        <Text size="small">
          Deunifying the location will copy all phone configuration related to the source
          child-location and put it on a newly created destination phone tenant.
        </Text>
        <div css={{ fontStyle: 'italic' }}>
          <Text size="small">Notes:</Text>
          <Text size="small">
            * The newly created phone tenant will have mock phone numbers and devices
            until step 3 is complete.
          </Text>
          <Text size="small">
            * The newly created phone tenant will have the same location ID and slug as
            the source child location.
          </Text>
        </div>
        <CheckboxField
          css={{ marginBottom: theme.spacing(1) }}
          disabled={!!data?.errors?.length || deunifyStatus?.status !== 'ELIGIBLE'}
          {...renumberProp}
          name="renumber"
          label="Renumber device extensions to start with 1"
        />
        <Text
          color="light"
          size="small"
          css={css`
            margin-bottom: 0;
          `}
        >
          Change all device-related extensions, device names, SIP profiles, voicemail box
          names, and voicemail box numbers to start with 1. e.g. 201 will become 101.
        </Text>
        <div css={{ width: '250px', marginTop: theme.spacing(2) }}>
          <PrimaryButton
            onClick={() => deunifyLocation(renumberProp.value)}
            disabled={!!data?.errors?.length || deunifyStatus?.status !== 'ELIGIBLE'}
          >
            Deunify Location
          </PrimaryButton>
        </div>
        <Heading level={2} css={{ marginTop: theme.spacing(6) }}>
          Step 2: Deunify Media
        </Heading>
        <Text size="small">
          Deunifying the media will cause a copy of all media library files and voicemail
          box numbered greetings related to the source phone tenant to be added on a newly
          created destination phone tenant. If these files are in use in call routing they
          will continue to work.
        </Text>
        <Text size="small" css={{ fontStyle: 'italic' }}>
          Note: The Deunify Media button will be disabled if there are no media files in
          the Media Library or voicemail box numbered greetings.
        </Text>
        <div css={{ width: '250px', marginTop: theme.spacing(2) }}>
          <PrimaryButton
            onClick={deunifyMedia}
            disabled={!!data?.errors?.length || deunifyStatus?.status !== 'DEUNIFY_MEDIA'}
          >
            Deunify Media
          </PrimaryButton>
        </div>
        <Heading
          level={2}
          css={css`
            margin-top: ${theme.spacing(6)};
          `}
        >
          Step 3: Deunify Phone Numbers and Devices
        </Heading>
        <Text size="small">
          Deunifying phone numbers and devices will cause the customer's location to begin
          using the new phone tenant. When the "Migrate" button is clicked the mock phone
          numbers and devices created during step 1 will be replaced with the real phone
          numbers and MAC addresses on the new phone tenant.
        </Text>
        <Text size="small">
          If needed, you can click the "Revert" button which will reverse the change
          putting the real phone numbers and MAC addresses back on the source phone
          tenant.
        </Text>
        <div
          css={css`
            width: 300px;
          `}
        >
          <ButtonBar
            removeSpacing
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <PrimaryButton
              disabled={deunifyStatus?.status !== 'DEUNIFY_COMMIT'}
              onClick={deunifyPhoneNumbersAndDevices}
            >
              Migrate
            </PrimaryButton>
            <PrimaryButton
              disabled={deunifyStatus?.status !== 'REVERT_DEUNIFY_COMMIT'}
              onClick={revertDeunifyPhoneNumbersAndDevices}
              destructive
            >
              Revert
            </PrimaryButton>
          </ButtonBar>
        </div>
        <Heading
          level={2}
          css={css`
            margin-top: ${theme.spacing(6)};
          `}
        >
          Step 4: (Optional) Convert to Standalone Single Location
        </Heading>
        <Text size="small">
          After clicking the "Migrate" button in Step 3, the location will have had the
          Phone Tenant ID removed which will allow the Weave software to show phone
          configuration on the new tenant. If you wish to disassociate this location from
          the parent location so that this location becomes a standalone location, please
          use the Edit Parentage tool to remove the "Parent Location ID".
        </Text>
        <Text size="small" css={{ fontStyle: 'italic' }}>
          Note that after removing the "Parent Location ID" you will be unable to revert
          back to the shared phone tenant.
        </Text>
      </div>
    </Page>
  );
};
