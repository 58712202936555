import { CustomAxios, getResponseData } from '../../redux/axios';
import { ResumeStripePayoutAccountResponse } from './resume-stripe-payout.types';

export const resumeStripePayoutAccountApi = {
  baseUrl: 'commands/service/account',
  async resumeStripePayoutAccount(paymentsURL?: string, stripeAccountId?: string) {
    const response = await CustomAxios.patch(
      `${paymentsURL}/${this.baseUrl}/${stripeAccountId}/enable-payouts/true`
    );
    return getResponseData<ResumeStripePayoutAccountResponse>(response);
  },
};
