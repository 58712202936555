import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { useHistory } from 'react-router-dom';
import { SearchField, Text, PrimaryButton, useFormField } from '@weave/design-system';

import { PROVISION_LOCATIONS, injectParams } from '../../constants/paths';

interface Props {
  slug?: string;
  noDataAvailable?: boolean;
}

export const ProvisionSearch = ({ slug = '', noDataAvailable = false }: Props) => {
  const history = useHistory();
  const searchProps = useFormField({
    type: 'text',
    value: slug,
  });

  const handleSearchClick = () => {
    const url = injectParams(PROVISION_LOCATIONS, {
      slug: searchProps.value,
    });

    history.push(url);
  };

  return (
    <>
      <div css={containerStyle}>
        <SearchField
          css={{ width: 318 }}
          name="provision-search"
          placeholder="Search Slug"
          autoComplete="off"
          {...searchProps}
        />
        <PrimaryButton
          css={{ '&&': { width: 100 } }}
          disabled={!searchProps.value}
          onClick={handleSearchClick}
        >
          Search
        </PrimaryButton>
      </div>
      <div css={textContainer}>
        {!slug && (
          <Text as="span" color="light">
            Search a location to view more information
          </Text>
        )}
        {noDataAvailable && (
          <Text as="span" css={{ fontStyle: 'italic' }}>
            No locations found for searched slug: {slug}
          </Text>
        )}
      </div>
    </>
  );
};

const containerStyle = css({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(5),
});

const textContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
