import React, { useState } from 'react';
import { Heading, PrimaryButton, Tabs } from '@weave/design-system';
import { css } from '@emotion/core';
import { SnapshotMetricsCardComponent } from './snapshot-metrics-card.component';
import { HandoffMetric, HandoffSnapshot } from '../../../handoff-hub.types';
import { theme } from '@weave/theme-original';
import {
  HandoffTypes,
  DeprecatedHandoffTypes,
} from '../edit-handoff/handoff-metrics/handoff.constants';
import { LocationInformationNoteComponent } from './location-information-note.component';
import { LocationInformationUpsellNoteComponent } from './location-information-upsell-note.component';

type GroupedMetrics = {
  [SectionName: string]: {
    [subsectionName: string]: HandoffMetric[];
  };
};
const getGroupedMetricsBySection = (handoffData: HandoffSnapshot): GroupedMetrics =>
  handoffData.metrics.reduce((acc, metric) => {
    const sectionData = metric.section;
    const subsectionData = metric.subsection;

    return {
      ...acc,
      [sectionData]: {
        ...acc[sectionData],
        [subsectionData]: [...(acc[sectionData]?.[subsectionData] ?? []), metric],
      },
    };
  }, {});

interface State {
  activeTabIndex: number;
}

type Props = {
  snapshotData: HandoffSnapshot;
};

export const SnapshotViewComponent = ({ snapshotData }: Props) => {
  const groupedMetricsBySection = getGroupedMetricsBySection(snapshotData);

  return (
    <Tabs initialTab="Handoff Checklist">
      <Tabs.Bar>
        <Tabs.Tab id="Handoff Checklist" controls="checklist-panel">
          Handoff Checklist
        </Tabs.Tab>
        <Tabs.Tab id="Location Information" controls="location-panel">
          Location Information
        </Tabs.Tab>
      </Tabs.Bar>
      <Tabs.Panel id="checklist-panel" controller="Handoff Checklist">
        <div css={sectionContainer}>
          {Object.entries(groupedMetricsBySection).map(
            ([sectionName, subsectionMetrics]) => (
              <SnapshotMetricsCardComponent
                subsectionMetrics={subsectionMetrics}
                sectionName={sectionName}
                key={sectionName}
              />
            )
          )}
        </div>
      </Tabs.Panel>
      <Tabs.Panel
        id="location-panel"
        controller="Location Information"
        css={{
          marginTop: theme.spacing(2),
          borderBottom: `1px solid ${theme.colors.gray300}`,
        }}
      >
        {snapshotData && (
          <>
            {snapshotData.handoffType === HandoffTypes.OnboardingHandoff ||
            snapshotData.handoffType === DeprecatedHandoffTypes.OnboardingHandoffV1 ? (
              <LocationInformationNoteComponent locationInformationData={snapshotData} />
            ) : (
              <LocationInformationUpsellNoteComponent
                locationInformationData={snapshotData}
              />
            )}
          </>
        )}
      </Tabs.Panel>
    </Tabs>
  );
};

const sectionContainer = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
});
