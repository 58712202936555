import { createAction } from 'redux-actions';
import { get } from 'lodash';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import {
  CallSettingsSaveModel,
  ForwardingNumber,
} from '../../../components/phones/call-settings-container/models';
import { showSuccess, showError } from '@weave/alert-system';
export const callSettings = createAction('CALL_SETTINGS');
export const callSettingsSuccess = createAction('CALL_SETTINGS_SUCCESS');
export const callSettingsFailure = createAction<Error>('CALL_SETTINGS_FAILURE');
export const updateCallSettings = createAction('UPDATE_CALL_SETTINGS');
export const updateCallSettingsSuccess = createAction('UPDATE_CALL_SETTINGS_SUCCESS');
export const updateCallSettingsFailure = createAction<Error>(
  'UPDATE_CALL_SETTINGS_FAILURE'
);

export const fetchNumbersSuccess = createAction<ForwardingNumber>(
  'FETCH_NUMBERS_SUCCESS'
);
export const fetchForwardingStatusSuccess = createAction<ForwardingNumber>(
  'FETCH_FORWARDING_STATUS_SUCCESS'
);
export const fetchCallRecordingStatusSuccess = createAction<ForwardingNumber>(
  'FETCH_CALL_RECORDING_STATUS_SUCCESS'
);
export const fetchVoicemailOverrideStatusSuccess = createAction<ForwardingNumber>(
  'FETCH_VOICEMAIL_OVERRIDE_STATUS_SUCCESS'
);
export const fetchSmsNumbersSuccess = createAction<ForwardingNumber>(
  'FETCH_SMS_NUMBERS_SUCCESS'
);

export const addForwardingNumber = createAction<ForwardingNumber>(
  'ADD_FORWARDING_NUMBER'
);
export const addForwardingNumberSuccess = createAction<ForwardingNumber>(
  'ADD_FORWARDING_NUMBER_SUCCESS'
);
export const addForwardingNumberFailure = createAction<Error>(
  'ADD_FORWARDING_NUMBER_FAILURE'
);
export const updateForwardingNumber = createAction<ForwardingNumber>(
  'UPDATE_FORWARDING_NUMBER'
);
export const updateForwardingNumberSuccess = createAction<ForwardingNumber>(
  'UPDATE_FORWARDING_NUMBER_SUCCESS'
);
export const updateForwardingNumberFailure = createAction<Error>(
  'UPDATE_FORWARDING_NUMBER_FAILURE'
);
export const deleteForwardingNumber = createAction<ForwardingNumber>(
  'DELETE_FORWARDING_NUMBER'
);
export const deleteForwardingNumberSuccess = createAction(
  'DELETE_FORWARDING_NUMBER_SUCCESS'
);
export const deleteForwardingNumberFailure = createAction<Error>(
  'DELETE_FORWARDING_NUMBER_FAILURE'
);

export const handleCallSettings = function* (action) {
  const fetchForwardingNumbers = function* () {
    const response = yield call(CustomAxios.get, '/support/v1/forwarding');
    yield put(fetchNumbersSuccess(response.data.data));
  };

  const fetchForwardingStatus = function* () {
    const response = yield call(CustomAxios.get, '/support/v1/forwarding/enabled');
    yield put(fetchForwardingStatusSuccess(response.data.data));
  };

  const fetchCallRecordingSetting = function* () {
    const response = yield call(CustomAxios.get, '/support/v1/callrecording');
    yield put(fetchCallRecordingStatusSuccess(response.data.data.call_recording_enabled));
  };

  const fetchSmsNumbers = function* () {
    const response = yield call(CustomAxios.get, '/support/v1/phones/sms/numbers');
    yield put(fetchSmsNumbersSuccess(response.data.data));
  };

  try {
    yield all([
      call(fetchForwardingNumbers),
      call(fetchForwardingStatus),
      call(fetchCallRecordingSetting),
      call(fetchSmsNumbers),
    ]);

    yield put(callSettingsSuccess());

    if (get(action, 'payload.isRefresh')) {
      yield put(showSuccess('Data Refreshed from API'));
    }
  } catch (error: any) {
    yield put(callSettingsFailure(error));
    yield put(showError('Failed to get call settings'));
  }
};

export const handleUpdateCallSettings = function* (action) {
  const saveObject: CallSettingsSaveModel = action.payload;
  const updateCallRecordingStatus = function* () {
    if (typeof saveObject.callRecordingStatus !== 'undefined') {
      yield call(CustomAxios.put, `support/v1/callrecording`, {
        call_recording_enabled: saveObject.callRecordingStatus,
      });
    }
  };

  const updateForwardingStatus = function* () {
    if (saveObject.forwardingStatus && saveObject.forwardingStatus.id) {
      yield call(
        CustomAxios.post,
        `support/v1/forwarding/enabled/${saveObject.forwardingStatus.id}`,
        saveObject.forwardingStatus
      );
    }
  };

  const updateSmsNumber = function* () {
    if (saveObject.smsNumber) {
      yield call(
        CustomAxios.put,
        `support/v1/phones/sms/numbers/${saveObject.smsNumber.ID}`,
        saveObject.smsNumber
      );
    }
  };

  try {
    yield all([
      call(updateCallRecordingStatus),
      call(updateForwardingStatus),
      call(updateSmsNumber),
    ]);

    yield put(updateCallSettingsSuccess());
    yield put(showSuccess('Call settings saved!'));

    // Reload the data from the API
    yield put(callSettings({ isRefresh: false }));
  } catch (error: any) {
    yield put(callSettings({ isRefresh: false }));
    yield put(updateCallSettingsFailure(error));
    yield put(showError('Failed to save some call settings'));
  }
};

export const handleAddNumber = function* (action) {
  try {
    const response = yield call(
      CustomAxios.post,
      `support/v1/forwarding/`,
      action.payload
    );
    yield put(addForwardingNumberSuccess(response.data.data));
    yield put(showSuccess('Number added'));
  } catch (error: any) {
    yield put(addForwardingNumberFailure(error));
    yield put(showError('Failed to save number'));
  }
};

export const handleUpdateForwardingNumber = function* (action) {
  try {
    const forwardingNumber: ForwardingNumber = action.payload;
    yield call(
      CustomAxios.put,
      `support/v1/forwarding/${forwardingNumber.id}`,
      forwardingNumber
    );
    yield put(updateForwardingNumberSuccess(forwardingNumber));
    yield put(showSuccess('Number updated'));
  } catch (error: any) {
    yield put(updateForwardingNumberFailure(error));
    yield put(showError('Failed to update number'));
  }
};

export const handleDeleteForwardingNumber = function* (action) {
  try {
    const forwardingNumber: ForwardingNumber = action.payload;
    yield call(CustomAxios.delete, `support/v1/forwarding/${forwardingNumber.id}`);
    yield put(deleteForwardingNumberSuccess(forwardingNumber.id));
    yield put(showSuccess('Number deleted'));
  } catch (error: any) {
    yield put(deleteForwardingNumberFailure(error));
    yield put(showError('Failed to update number'));
  }
};

export const callSettingsSaga = function* () {
  yield all([
    takeEvery(callSettings.toString(), handleCallSettings),
    takeEvery(updateCallSettings.toString(), handleUpdateCallSettings),
    takeEvery(addForwardingNumber.toString(), handleAddNumber),
    takeEvery(updateForwardingNumber.toString(), handleUpdateForwardingNumber),
    takeEvery(deleteForwardingNumber.toString(), handleDeleteForwardingNumber),
  ]);
};
