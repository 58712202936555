import React from 'react';

import { ToggleSwitch } from '../../../shared/toggle-switch/toggle-switch.component';
import {
  Delivery,
  SendBefore,
} from '../../../../redux/actions/notifications-settings/notification-settings.types';

import * as styles from '../auto-reminders.styles';

export const templateTagExamples = {
  '{first name}': 'Thomas',
  '{first_name}': 'Thomas',
  '{last name}': 'Smith',
  '{last_name}': 'Smith',
  '{preferred name}': 'Thom',
  '{preferred_name}': 'Thom',
  '{date and time}': 'Thursday, April 9th, 2020 at 7 am',
  '{date_and_time}': 'Thursday, April 9th, 2020 at 7 am',
  '{appt_datetime}': 'Thursday, April 9th, 2020 at 7 am',
  '{day}': 'Monday',
  '{day of month}': '1st',
  '{day_of_month}': '1st',
  '{month}': 'January',
  '{time}': '7:00 AM',
  '{practice name}': 'devtesting',
  '{practice_name}': 'devtesting',
  '{practice phone}': '123 456 7890',
  '{practice_phone}': '123 456 7890',
  '{time early}': '6:45 AM',
  '{time_early}': '6:45 AM',
  '[{first name}]': 'Thomas, Troyleland, Ty-son, Jimmy, Richard and Robison',
  '[{first_name}]': 'Thomas, Troyleland, Ty-son, Jimmy, Richard and Robison',
  '[{preferred name}]': 'Thom, Troy, Ty, James, Richy and Robi',
  '[{preferred_name}]': 'Thom, Troy, Ty, James, Richy and Robi',
  '[{time}]': '7:00, 7:30, 7:45, 7:50 and 2:00',
  '{wellness form}': `https://book.getweave.com/a1bc2d3e-f456-78gh-ijkl-9mn0o12pqr34/wellness`,
  '{wellness_form}': `https://book.getweave.com/a1bc2d3e-f456-78gh-ijkl-9mn0o12pqr34/wellness`,
  '{pet name}': 'Barko',
  '{pet_name}': 'Barko',
  '[{pet name}]': 'Crookshank and Roofus',
  '[{pet_name}]': 'Crookshank and Roofus',
  '{service provider}': 'Dr. Weaver',
  '{service_provider}': 'Dr. Weaver',
  '{recall_href_url}': 'getweave.com',
  '{url_external_url}': 'getweave.com',
  '{url_location_id}': '1a2b3c4d-5e6f-7g8h-9i0j-1k2l3m4n5o6p',
  '{nonce}': 'asdf123xq',
};

export const formatNotificationTime = (delivery: Delivery, sendBefore: SendBefore) => {
  let value = Number(sendBefore.value);
  let beforOrAfter = '';
  let deliveryTime = '';
  if (value < 0) {
    value = value * -1;
    beforOrAfter = 'after';
  } else {
    beforOrAfter = 'before';
  }

  if (sendBefore.type !== 'hours') {
    deliveryTime = `${delivery.time}`;
  }
  return `${deliveryTime} ${value} ${sendBefore.type} ${beforOrAfter}`;
};

export const EnabledToggle = ({
  enabled,
  handleToggleSwitch = () => {
    return;
  },
  disabled = false,
}) => (
  <div className={styles.toggle}>
    <ToggleSwitch
      onChange={handleToggleSwitch}
      on={enabled}
      disabled={disabled}
      label={''}
    />
  </div>
);

export const replaceTagsWithExample = (text, templateTagExamples) => {
  const regexMatchPatterns = Object.keys(templateTagExamples)
    .map((tag) => tag.replace(/[.[\]\\]/g, '\\$&'))
    .join('|');
  const re = new RegExp(regexMatchPatterns, 'gi');
  return text.replace(re, (matched) => {
    return templateTagExamples[matched];
  });
};

export const sortByKey = (array, primaryKey, secondaryKey) => {
  return array.sort(function (a, b) {
    const nameComp = ('' + a[primaryKey]).localeCompare(b[primaryKey]);
    const idComp = ('' + a[secondaryKey]).localeCompare(b[secondaryKey]);
    return nameComp || idComp;
  });
};
