import React from 'react';
import { PrimaryButton } from '@weave/design-system';
import { Page } from 'components/shared';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { selectHasWeaveAcl } from 'redux/actions/auth/auth.selectors';
import { CoreACLs } from 'redux/actions/auth/auth.types';
import { Store } from 'redux/store/store.model';
import { CreateFlagModal } from './create-flag-modal.component';
import { FeatureFlagTable } from './feature-flag-table.component';

type FeatureFlagManagerPageProps = {};

export const FeatureFlagManagerPage = (props: FeatureFlagManagerPageProps) => {
  const canCreateFeatureFlags = useSelector((state: Store) =>
    selectHasWeaveAcl(state, CoreACLs.FEATUREFLAGCREATE)
  );

  const [showCreateModal, setShowCreateModal] = useState(false);

  const queryClient = useQueryClient();

  return (
    <Page
      title="Global Feature Flags"
      headerActions={
        canCreateFeatureFlags && (
          <PrimaryButton
            style={{ width: 'inherit' }}
            size="small"
            onClick={() => {
              setShowCreateModal(true);
            }}
          >
            Create Feature Flag
          </PrimaryButton>
        )
      }
    >
      <FeatureFlagTable />
      {showCreateModal && (
        <CreateFlagModal
          close={(flag) => {
            setShowCreateModal(false);
            queryClient.invalidateQueries({ queryKey: ['feature-flags'] });
          }}
        />
      )}
    </Page>
  );
};
