import React, { useEffect, useState } from 'react';
import {
  noteStyles,
  circle_container,
  circle_main,
  circle_text_container,
  circle_text,
} from './e911-dashboard.styles';
import { Text, useModalControl } from '@weave/design-system';
import { MoreIcon } from '@weave/design-system';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
} from 'reactstrap';
import { getListOfNotes } from '../../apis/porting/porting.api';
import AddNotes from './add-notes';
import { useAlert } from '@weave/alert-system';
interface Props {
  iconData: any;
  handleDropDownValueSelect: () => void;
  mutedValue: string;
  tenantIdValue: string;
}

const MoreIconComponent = ({
  iconData,
  handleDropDownValueSelect,
  mutedValue,
  tenantIdValue,
}: Props) => {
  const { modalProps: modalListNoteProps, triggerProps: triggeredListNoteProps } =
    useModalControl();
  const { modalProps: modalAddNoteProps, triggerProps: triggeredAddNoteProps } =
    useModalControl();
  const [muteValue, setMuteValue] = useState(!mutedValue ? 'Hide Tenant' : 'Show Tenant');
  const [notesCountValue, setNotesCountValue] = useState<number>();
  const [showTenantOpen, setShowTenantOpen] = useState<boolean>(false);
  const showTenantAlert = useAlert();
  const getListOfNoteData = async () => {
    try {
      const data = await getListOfNotes(tenantIdValue);
      setNotesCountValue(data?.length);
    } catch (error) {
      showTenantAlert.error(`${error}`);
    }
  };
  useEffect(() => {
    getListOfNoteData();
  }, []);

  const renderDropdownItem = (text: string) => {
    return (
      <>
        <DropdownItem onClick={() => handleDropDownValueSelect()}>
          <NavItem>
            <span>{text}</span>
          </NavItem>
        </DropdownItem>

        <Text as="div" {...triggeredListNoteProps} className={noteStyles}>
          List Notes
        </Text>

        <Text as="div" {...triggeredAddNoteProps} className={noteStyles}>
          Add Notes
        </Text>
      </>
    );
  };

  return (
    <Text as="span" style={{ cursor: 'pointer' }}>
      <Dropdown isOpen={showTenantOpen} toggle={() => setShowTenantOpen(!showTenantOpen)}>
        <DropdownToggle style={{ background: 'none', border: 'none' }}>
          <MoreIcon style={{ color: 'black' }}></MoreIcon>
          {notesCountValue && (
            <div className={circle_container}>
              <div className={circle_main}>
                <div
                  className={circle_text_container}
                  style={{ left: notesCountValue > 9 ? '3%' : '15%' }}
                >
                  <div
                    className={circle_text}
                    style={{ fontSize: notesCountValue > 99 ? 'x-small' : 'small' }}
                  >
                    {notesCountValue}
                  </div>
                </div>
              </div>
            </div>
          )}
        </DropdownToggle>
        <DropdownMenu> {renderDropdownItem(muteValue)}</DropdownMenu>
      </Dropdown>
      <AddNotes
        modalProps={modalListNoteProps}
        notesData={iconData}
        showNote={true}
        title="Notes"
        closeModal={false}
        getNotesCountValue={setNotesCountValue}
      />
      <AddNotes
        modalProps={modalAddNoteProps}
        notesData={iconData}
        showNote={false}
        title="Add Notes"
        closeModal={true}
        getNotesCountValue={setNotesCountValue}
      />
    </Text>
  );
};

export default MoreIconComponent;
