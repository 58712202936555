import React from 'react';
import { Modal, Text } from '@weave/design-system';
import {
  modalBodyStyle,
  modalContentBodyStyles,
  modalHeaderStyle,
  subTitleStyle,
} from './intake-form-card.component';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

type Props = {
  errorModalPropsSingleLocation;
  intakeFormResponseErrorResponse;
};

export const IntakeFormSingleLocationErrorModalComponent = ({
  errorModalPropsSingleLocation,
  intakeFormResponseErrorResponse,
}: Props) => {
  return (
    <Modal {...errorModalPropsSingleLocation} maxWidth={500}>
      <Modal.Header textAlign="left" css={modalHeaderStyle}>
        Error!
      </Modal.Header>
      <Modal.Body css={modalBodyStyle}>
        <Text>
          The intake form was unable to send. See the error message below for more
          information:
        </Text>
        <p css={subTitleStyle}>Intake Form Summary</p>
        {intakeFormResponseErrorResponse?.map((onboardResponse, index) => (
          <div key={index}>
            <p
              css={css`
                color: ${theme.colors.error};
                font-weight: bold;
              `}
            >
              Error: {onboardResponse.onboardingError}
            </p>
          </div>
        ))}
      </Modal.Body>
      <Modal.Actions
        onPrimaryClick={errorModalPropsSingleLocation.onClose}
        primaryLabel="Close"
      ></Modal.Actions>
    </Modal>
  );
};
