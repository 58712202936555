import React from 'react';
import { css } from '@emotion/core';
import { PrimaryButton, Text, useModalControl } from '@weave/design-system';

import { AssignLocationModal } from './assign-locations-modal';

interface Props {
  slug: string;
  disabled: boolean;
  dropdownOptions: string[];
  onAssignLocation: (selectedValue: string) => void;
}

export const AssignLocationsSection = ({
  slug,
  disabled,
  dropdownOptions,
  onAssignLocation,
}: Props) => {
  const { modalProps, openModal } = useModalControl();

  return (
    <div css={containerStyle}>
      <Text as="span" size="medium" css={{ fontStyle: 'italic' }}>
        Showing results for {slug}
      </Text>
      <PrimaryButton
        css={{ maxWidth: 'fit-content' }}
        disabled={disabled}
        onClick={openModal}
      >
        Assign Locations
      </PrimaryButton>
      <AssignLocationModal
        {...modalProps}
        dropdownOptions={dropdownOptions}
        onSubmit={onAssignLocation}
      />
    </div>
  );
};

const containerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});
