import React, { useState, useEffect } from 'react';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import { TableStyles } from '../../../styles/table-style';
import { theme } from '@weave/theme-original';
import {
  Heading,
  PrimaryButton,
  PlusIconSmall,
  DropdownField,
  useFormField,
  ButtonBar,
  IconButton,
  TrashIcon,
  TextField,
  useModalControl,
} from '@weave/design-system';
import { css } from '@emotion/core';
import ReactTable from 'react-table';
import { find, findIndex, get, toLower, uniq } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { GreetingUploadModal } from './greeting-upload-modal/greeting-upload-modal.component';
import { prettifyDatestring } from '../../../helpers/time';
import {
  Greeting,
  GreetingMap,
} from '../../../redux/actions/voice-mailboxes/voice-mailboxes.types';
import { Store } from '../../../redux/store/store.model';
import {
  deleteGreeting,
  getGreetingsForMailbox,
  getGreetingMaps,
  updateGreetings,
  uploadGreeting,
  uploadGreetingConversion,
} from '../../../redux/actions/voice-mailboxes';
import { ConfirmationModal } from '../../shared/confirmation-modal/confirmation-modal.component';
import { phoneMediaSelector } from '../../../redux/actions';
import { CachedAudioScrubber } from './greeting-upload-modal/cached-audio-scrubber';
import { REACT_APP_API_URL } from '../../../config/app';

interface Props {
  mailboxId: string;
  isGeneral: boolean;
}

interface UploadGreetingPayload {
  greetingFile: File | null;
  greetingName: string;
  mailboxId: string;
}

interface UploadGreetingConversionPayload {
  mediaId: string;
  mailboxId: string;
}

interface StateGreetingRow {
  greetingId: string;
  greetingName: string;
  greetingNumber: number;
  hasChanges: boolean;
}

export const nameInputStyles = css`
  label: nameInput;

  border: 1px solid ${theme.colors.gray100};
  border-radius: 4px;
  margin-right: 15px;
  padding: 10px;

  &:focus,
  &:active {
    outline: 0;
  }
`;

export const customTableStyles = css`
  .rt-table {
    overflow: visible !important;
  }

  .rt-tbody {
    overflow: visible !important;
  }

  .rt-td {
    overflow: visible !important;
  }
`;

export const MailboxGreetingsSection = ({ mailboxId, isGeneral }: Props) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hasFormChanges, setHasFormChanges] = useState(false);
  const [greetingIdPendingDeletion, setGreetingIdPendingDeletion] = useState('');
  const [greetingNamePendingDeletion, setGreetingNamePendingDeletion] = useState('');
  const [greetingRows, setGreetingRows] = useState<StateGreetingRow[]>([]);
  const { modalProps, triggerProps } = useModalControl();
  const dispatch = useDispatch();
  const { token, phoneMedia, loadingPhoneMedia, locationId } =
    useSelector(phoneMediaSelector);
  const {
    greetingMaps,
    greetings,
    loadingGreetingMaps,
    loadingGreetings,
    updatingGreetings,
  } = useSelector((store: Store) => ({
    greetings: store.voiceMailboxes.greetings,
    greetingMaps: store.voiceMailboxes.greetingMaps,
    loadingGreetings: store.voiceMailboxes.loadingGreetings,
    loadingGreetingMaps: store.voiceMailboxes.loadingGreetingMaps,
    updatingGreetings: store.voiceMailboxes.updatingGreetings,
  }));
  const alerts = useAlert();

  useEffect(() => {
    if (locationId) {
      // initial mount or change of location
      setHasFormChanges(false);
      setGreetingRows([]);
    }
  }, [locationId]);

  useEffect(() => {
    if (mailboxId) {
      // Active Mailbox was changed
      const payload = {
        activeMailboxId: mailboxId,
        isGeneral,
      };

      setHasFormChanges(false);
      setGreetingRows([]);
      dispatch(getGreetingsForMailbox(mailboxId));
      dispatch(getGreetingMaps(payload));
    }
  }, [mailboxId]);

  useEffect(() => {
    if (!loadingGreetings) {
      // New Greetings were fetched
      updateGreetingsInState();
    }
  }, [loadingGreetings]);

  const updateGreetingsInState = () => {
    const newGreetingRows: StateGreetingRow[] = [];

    greetings.forEach((greeting) => {
      newGreetingRows.push({
        greetingId: greeting.greetingId,
        greetingName: greeting.greetingName,
        greetingNumber: greeting.greetingNumber,
        hasChanges: false,
      });
    });

    setGreetingRows(newGreetingRows);
  };

  const onCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setGreetingIdPendingDeletion('');
    setGreetingNamePendingDeletion('');
  };

  const onDeleteGreeting = () => {
    dispatch(deleteGreeting(greetingIdPendingDeletion));
    onCloseDeleteModal();
  };

  const onSave = () => {
    const newGreetingRows: StateGreetingRow[] = [];
    const greetingsToUpdate: any = [];

    greetingRows.forEach((greeting) => {
      if (greeting.hasChanges) {
        greetingsToUpdate.push({
          greetingName: greeting.greetingName,
          greetingNumber: String(greeting.greetingNumber || 0),
          greetingId: greeting.greetingId,
          mailboxId,
        });
      }

      newGreetingRows.push({ ...greeting, hasChanges: false });
    });

    setHasFormChanges(false);
    setGreetingRows(newGreetingRows);
    dispatch(updateGreetings(greetingsToUpdate));
  };

  const onGreetingNumberChange = (greetingId: string, greetingNumber: number) => {
    const greetingIndex = findIndex(greetingRows, { greetingId });
    let oldGreetingNumber;
    const newGreetingRows = [...greetingRows];

    if (greetingIndex > -1) {
      oldGreetingNumber = greetingRows[greetingIndex].greetingNumber;
    }

    if (oldGreetingNumber === greetingNumber) {
      // Greeting number didn't change
      return;
    }

    const existingGreetingIndex = findIndex(newGreetingRows, { greetingNumber });

    if (existingGreetingIndex > -1) {
      // Greeting number was assigned to a different greeting - unassign the number from the existing greeting
      newGreetingRows[existingGreetingIndex] = {
        ...newGreetingRows[existingGreetingIndex],
        greetingNumber: 0,
      };
    }

    if (oldGreetingNumber > 0) {
      // The greeting we are changing had a different greeting number assigned to it
      const oldGreetingMapping = find(greetingMaps, {
        greetingNumber: oldGreetingNumber,
      });

      if (oldGreetingMapping) {
        alerts.warning(
          `You no longer have media assigned to ${get(
            oldGreetingMapping,
            'scheduleName'
          )} (${oldGreetingNumber}) schedule`
        );
      }
    }

    // Update the greeting being changed
    newGreetingRows[greetingIndex] = {
      ...newGreetingRows[greetingIndex],
      greetingNumber,
      hasChanges: true,
    };

    setGreetingRows(newGreetingRows);
    setHasFormChanges(true);
  };

  const onNameChange = (greetingId: string, greetingName: string) => {
    const newGreetingRows = [...greetingRows];
    const greetingIndex = findIndex(newGreetingRows, { greetingId });

    newGreetingRows[greetingIndex] = {
      ...newGreetingRows[greetingIndex],
      greetingName,
      hasChanges: true,
    };

    setGreetingRows(newGreetingRows);
    setHasFormChanges(true);
  };

  const renderDelete = (greetingId: string, greetingName: string) => {
    return (
      <IconButton
        label="Delete"
        onClick={() => {
          setDeleteModalOpen(true);
          setGreetingIdPendingDeletion(greetingId);
          setGreetingNamePendingDeletion(greetingName);
        }}
      >
        <TrashIcon color="light"></TrashIcon>
      </IconButton>
    );
  };

  return (
    <>
      <ConfirmationModal
        isOpen={deleteModalOpen}
        onConfirmClick={onDeleteGreeting}
        onRequestClose={onCloseDeleteModal}
        title={`Are you sure you want to delete the "${
          greetingNamePendingDeletion || 'unnamed'
        }" greeting?`}
      />
      <GreetingUploadModal
        activeMailboxId={mailboxId}
        locationId={locationId}
        phoneMedia={phoneMedia}
        modalProps={modalProps}
        uploadGreeting={(payload: UploadGreetingPayload) =>
          dispatch(uploadGreeting(payload))
        }
        uploadGreetingConversion={(payload: UploadGreetingConversionPayload) => {
          dispatch(uploadGreetingConversion(payload));
          dispatch(getGreetingsForMailbox(mailboxId));
        }}
      />
      <Heading
        css={(theme: WeaveTheme) =>
          css`
            margin-top: ${theme.spacing(4)};
          `
        }
      >
        Mailbox Greetings
      </Heading>
      <hr />
      <ButtonBar
        css={(theme: WeaveTheme) => css`
          margin-left: auto;
          width: 400px;
          padding-right: ${theme.spacing(4)};
        `}
      >
        <PrimaryButton
          size="small"
          disabled={!hasFormChanges && !updatingGreetings}
          onClick={onSave}
        >
          Save
        </PrimaryButton>
        <PrimaryButton
          size="small"
          css={css`
            margin-left: 10px;
          `}
          {...triggerProps}
        >
          Add Greeting
          <PlusIconSmall />
        </PrimaryButton>
      </ButtonBar>
      <div
        css={css`
          margin: 50px 0;
          padding-bottom: 100px;
        `}
      >
        <ReactTable
          columns={[
            {
              Header: 'Greeting Name',
              id: 'greetingName',
              accessor: (greeting: Greeting) => (
                <GreetingName greeting={greeting} onChange={onNameChange} />
              ),
              sortMethod: (a, b) => {
                const aValue = toLower(a.props.value);
                const bValue = toLower(b.props.value);

                if (aValue === bValue) {
                  return 0;
                }

                return aValue > bValue ? 1 : -1;
              },
            },
            {
              Header: `${isGeneral ? 'Schedule' : 'Status'} (Greeting #)`,
              id: 'schedule',
              sortable: false,
              accessor: (greeting: Greeting) => (
                <GreetingSelect
                  greeting={greeting}
                  greetingMaps={greetingMaps}
                  onChange={onGreetingNumberChange}
                />
              ),
            },
            {
              Header: 'Last Updated',
              id: 'updatedAt',
              accessor: (greeting: Greeting) =>
                prettifyDatestring(greeting.updatedAt ?? ''),
            },
            {
              Header: 'Audio',
              id: 'audio',
              sortable: false,
              accessor: (greeting: Greeting) => {
                const mediaPath = `${REACT_APP_API_URL}phone/voicemail-config/v2/${greeting.greetingId}/download-greeting`;

                return (
                  <>
                    <CachedAudioScrubber
                      filePath={mediaPath}
                      mediaId={greeting.mediaId}
                    />
                    <div
                      css={css`
                        margin-left: 16px;
                      `}
                    >
                      {renderDelete(greeting.greetingId, greeting.greetingName)}
                    </div>
                  </>
                );
              },
            },
          ]}
          data={greetings}
          showPagination={false}
          loading={loadingGreetings || loadingGreetingMaps || loadingPhoneMedia}
          pageSize={greetings.length || 5}
          css={[TableStyles, customTableStyles]}
        />
      </div>
    </>
  );
};

type GreetingNameProps = {
  greeting: Greeting;
  onChange: (greetingId: string, name: string) => void;
};

const GreetingName: React.FC<GreetingNameProps> = ({ greeting, onChange }) => {
  const field = useFormField(
    {
      value: greeting.greetingName,
      type: 'text',
    },
    [greeting.greetingName]
  );

  useEffect(() => {
    onChange(greeting.greetingId, field.value);
  }, [field.value]);

  return (
    <TextField
      {...field}
      placeholder="Enter greeting name"
      name={`name-${greeting.greetingId}`}
      label=""
    />
  );
};

type GreetingSelectProps = {
  greeting: Greeting;
  greetingMaps: GreetingMap[];
  onChange: (greetingId: string, value: number) => void;
};

const GreetingSelect: React.FC<GreetingSelectProps> = ({
  greeting,
  greetingMaps,
  onChange,
}) => {
  const field = useFormField(
    {
      type: 'dropdown',
      value: (greeting.greetingNumber ?? 0).toString(),
    },
    [greeting.greetingNumber]
  );

  useEffect(() => {
    if (field.value !== String(greeting.greetingNumber)) {
      onChange(greeting.greetingId, +field.value);
    }
  }, [field.value]);

  const greetingNumbers = greetingMaps.map((map) => map.greetingNumber);
  const greetingDisplays = uniq(greetingNumbers).map((num) => ({
    number: num,
    label:
      `${num}: ` +
      greetingMaps
        .filter((map) => map.greetingNumber === num)
        .map((map) => map.scheduleName)
        .join(', '),
  }));

  return (
    <DropdownField {...field} label="" placeholder="Schedule" name="mailbox">
      {greetingDisplays.map((map) => (
        <DropdownField.Option key={map.number} value={map.number.toString()}>
          {map.label}
        </DropdownField.Option>
      ))}
    </DropdownField>
  );
};
