import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { theme } from '@weave/theme-original';
import {
  BuildingIcon,
  CaretDownIconSmall,
  CaretUpIconSmall,
  EditIcon,
  ExternalLinkIcon,
  FamilyIcon,
  Heading,
  IconButton,
  IdIcon,
  Modal,
  NakedButton,
  Text,
} from '@weave/design-system';

import { CustomAxios } from '../../../redux/axios';
import { SidebarSyncApp } from '../sidebar-sync-app/sidebar-sync-app.component';
import { LocationModel, DataCenters } from '../../../redux/actions/location';
import { SyncAppMultiCount } from '../../sync-app/sync-app-settings/integrations/sync-app-multi-count.component';
import {
  LocationEdit,
  LocationParentageModal,
  LocationSearchContainer,
} from '../../location';
import {
  accountLinkStyle,
  buildingIconStyle,
  clipboardDiv,
  dropdownContainerStyle,
  iconButtonSvgPrimary,
  iconPointerStyle,
  iconSmall,
  labelFlex,
  labelFlexRight,
  labelInfoDiv,
  mLeftSpacing,
  multiLocationDropdown,
  multiLocationTextStyle,
  sectionIconStyle,
  sidebar,
  statusDot,
} from '../location-sidebar.styles';
import { LocationSearchState } from '../../../redux/actions/location/location-search';
import {
  TcrStatus,
  GetTCRBrandLockStatusResponse,
  LocationStatusTypes,
} from '../location-sidebar.types';
import { AxiosResponse } from 'axios';
import { LocationStatus, WarningAlert } from '../location-status/location-status';
import {
  TCRBrandFriendlyRegistrationStatus,
  getFriendlyRegistrationStatus,
  getLocationRegistrationStatus,
  getLocationTCRBrand,
  getTCRBrandLockStatus,
} from 'apis/tendlc';
import {
  TenDLCBizStatusInfoButton,
  TenDLCPhoneStatusInfoButton,
} from 'components/tendlc';
import { GetLocationRegistrationStatusResponse } from '@weave/schema-gen-ts/dist/schemas/tendlc/coordinator/v1/coordinator_service.pb';

interface Props {
  currentLocation: LocationModel | null;
  isMultiOfficeBeta: boolean;
  childrenAccounts: LocationSearchState['locationChildren'];
  setLocation: (locationId) => void;
  allowParentageEdit: boolean;
  hasDatasources: boolean;
  departmentsEnabled: boolean;
  softphonesEnabled: boolean;
}

interface State {
  nameCopied: boolean;
  childSlugNameCopied: { [slug: string]: boolean };
  slugCopied: boolean;
  locationIdCopied: boolean;
  parentIdCopied: boolean;
  isModalOpen: boolean;
  isParentageModalOpen: boolean;
  childIdsCopied: any;
  multiLocationDropdownOpen: boolean;
  parentLocationName: string;
  tcrBizStatus?: TCRBrandFriendlyRegistrationStatus;
  tcrPhoneStatus: GetLocationRegistrationStatusResponse;
  tcrFetching: boolean;
}

export class LocationSidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      nameCopied: false,
      childSlugNameCopied: {},
      slugCopied: false,
      locationIdCopied: false,
      parentIdCopied: false,
      isModalOpen: false,
      isParentageModalOpen: false,
      childIdsCopied: {},
      multiLocationDropdownOpen: false,
      parentLocationName: '',
      tcrBizStatus: undefined,
      tcrPhoneStatus: { registered: false, phoneNumbers: [], defaultSmsNumber: '' },
      tcrFetching: false,
    };
  }

  tcrMap = {
    TCR_BRAND_IDENTITY_STATUS_SELF_DECLARED: 'Unverified',
    TCR_BRAND_IDENTITY_STATUS_VERIFIED: 'Verified',
    TCR_BRAND_IDENTITY_STATUS_VETTED_VERIFIED: 'Verified',
    TCR_BRAND_IDENTITY_STATUS_UNSPECIFIED: 'Unverified',
    TCR_BRAND_IDENTITY_STATUS_UNVERIFIED: 'Unverified',
  };

  componentDidMount() {
    this.loadParentLocation();
    this.loadTcrStatuses();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentLocation?.ParentID !== prevProps.currentLocation?.ParentID) {
      this.loadParentLocation();
    }

    if (
      this.props.currentLocation?.LocationID !== prevProps.currentLocation?.LocationID
    ) {
      this.loadTcrStatuses();
    }
  }

  loadParentLocation = async () => {
    if (this.props.currentLocation && this.props.currentLocation.ParentID) {
      try {
        const response = await CustomAxios.get(
          `support/v1/locations/${this.props.currentLocation.ParentID}`
        );
        this.setState({ parentLocationName: response.data.data.Name });
      } catch {
        this.setState({ parentLocationName: '' });
      }
    }
  };

  loadTcrStatuses = async () => {
    if (this.props.currentLocation) {
      // Load Business Status
      try {
        this.setState({ tcrFetching: true });
        const locationTcrBrand = await getLocationTCRBrand({
          locationId: this.props.currentLocation.LocationID,
        });
        if (locationTcrBrand.tcrBrand.brandId) {
          const lockStatusResponse = await getTCRBrandLockStatus({
            brandId: locationTcrBrand.tcrBrand.brandId,
          });
          this.setState({
            tcrBizStatus: getFriendlyRegistrationStatus({
              locked: lockStatusResponse.locked ?? false,
              identityStatus: locationTcrBrand.tcrBrand?.identityStatus,
              country: locationTcrBrand.tcrBrand?.country,
              entityType: locationTcrBrand.tcrBrand?.entityType,
            }),
          });
        } else {
          this.setState({
            tcrBizStatus: TCRBrandFriendlyRegistrationStatus.UNREGISTERED,
          });
        }
      } catch {
        this.setState({ tcrBizStatus: undefined });
      }

      // Load Phone Status
      try {
        const response = await getLocationRegistrationStatus({
          locationId: this.props.currentLocation.LocationID,
        });
        if (response) {
          this.setState({
            tcrPhoneStatus: response,
          });
        }
      } catch {
        this.setState({
          tcrPhoneStatus: { registered: false, phoneNumbers: [], defaultSmsNumber: '' },
        });
      } finally {
        this.setState({ tcrFetching: false });
      }
    }
  };

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  toggleParentageModal = () => {
    this.setState({ isParentageModalOpen: !this.state.isParentageModalOpen });
  };

  toggleMultiLocationDropdownOpen = () => {
    this.setState({ multiLocationDropdownOpen: !this.state.multiLocationDropdownOpen });
  };

  copiedLocationNameText = () => {
    this.setState({ nameCopied: true });
    setTimeout(() => {
      this.setState({ nameCopied: false });
    }, 3000);
  };

  copiedChildSlugText = (slug: string) => {
    this.setState((prevState) => ({
      childSlugNameCopied: { ...prevState.childSlugNameCopied, [slug]: true },
    }));
    setTimeout(() => {
      this.setState((prevState) => ({
        childSlugNameCopied: { ...prevState.childSlugNameCopied, [slug]: false },
      }));
    }, 3000);
  };

  copiedSlugText = () => {
    this.setState({ slugCopied: true });
    setTimeout(() => {
      this.setState({ slugCopied: false });
    }, 3000);
  };

  copiedLocationIdText = () => {
    this.setState({ locationIdCopied: true });
    setTimeout(() => {
      this.setState({ locationIdCopied: false });
    }, 3000);
  };

  getHomerURL = (dataCenter: DataCenters): string => {
    if (dataCenter === DataCenters.CentralIA) {
      return 'https://homer-us-central1.weavephone.net/';
    } else if (dataCenter === DataCenters.EasternVA) {
      return 'https://homer-us-east4.weavephone.net/';
    } else if (dataCenter === DataCenters.PacificCA) {
      return 'https://homer-us-west2.weavephone.net/';
    }

    return '';
  };

  getLocationStatus = (
    locationId: string,
    phoneTenantId: string,
    parentId: string
  ): LocationStatusTypes | '' => {
    const hasChildren = this.props.childrenAccounts.length > 0;

    const isDataBetaParent = !parentId && !phoneTenantId && hasChildren;
    if (isDataBetaParent) return 'Data Beta Parent';

    const isDataBetaLocation = parentId && !phoneTenantId && !hasChildren;
    if (isDataBetaLocation) return 'Data Beta Location';

    const isSingleTenant = !parentId && !phoneTenantId && locationId && !hasChildren;
    if (isSingleTenant) return 'Single Tenant';

    const isUnifyChild = parentId && phoneTenantId && !isDataBetaLocation && !hasChildren;
    if (isUnifyChild) return 'Unify Child';

    const isUnifyParent = (!parentId && !!phoneTenantId) || hasChildren;
    if (isUnifyParent) return 'Unify Parent';

    return '';
  };

  getLocationWarnings = (): string[] => {
    if (this.props.currentLocation) {
      const { LocationID, PhoneTenantID, ParentID } = this.props.currentLocation;
      const locationStatus = this.getLocationStatus(
        LocationID,
        PhoneTenantID ?? '',
        ParentID ?? ''
      );
      if (locationStatus === 'Data Beta Parent') {
        const childHasPhoneTenantId = this.props.childrenAccounts.some(
          (child) => child.PhoneTenantID
        );

        return childHasPhoneTenantId ? ['A Unify Child configuration found'] : [];
      } else if (locationStatus === 'Unify Parent') {
        const warningMessage: string[] = [];
        const childPhoneTenantIdMissing = this.props.childrenAccounts.some(
          (child) => !child.PhoneTenantID
        );
        const hasMisMatchedPhoneTenantId = this.props.childrenAccounts.some(
          (child) => child.PhoneTenantID !== PhoneTenantID
        );

        if (childPhoneTenantIdMissing)
          warningMessage.push('PhoneTenantID missing in Unify child location');

        if (hasMisMatchedPhoneTenantId)
          warningMessage.push(
            'PhoneTenantID for child location should be same as parent'
          );

        return warningMessage;
      }
    }
    return [];
  };

  render() {
    if (!this.props.currentLocation) {
      return null;
    }

    const { LocationID, PhoneTenantID, ParentID, Slug, Name, DataCenter, Active } =
      this.props.currentLocation;
    const childrenAccounts = this.props.childrenAccounts.sort((a, b) =>
      a.Name.localeCompare(b.Name, undefined, { numeric: true, sensitivity: 'base' })
    );
    const homerUrl = this.getHomerURL(DataCenter);

    if (!LocationID) {
      return null;
    }

    return (
      <div css={sidebar}>
        <LocationSearchContainer />
        <div css={labelInfoDiv(theme, 3)}>
          <Heading level={2}>Location Info</Heading>
          {this.props.allowParentageEdit ? (
            <IconButton
              label="Edit Parentage"
              showLabelOnHover={true}
              css={sectionIconStyle}
              onClick={this.toggleParentageModal}
            >
              <IdIcon />
            </IconButton>
          ) : null}
          <IconButton
            label="Edit Location"
            onClick={this.toggleModal}
            css={iconButtonSvgPrimary}
          >
            <EditIcon />
          </IconButton>
        </div>
        <CopyToClipboard text={Name} onCopy={this.copiedLocationNameText}>
          <div css={clipboardDiv} title="Copy to clipboard">
            <BuildingIcon css={buildingIconStyle} />
            <Heading level={2} css={{ wordBreak: 'break-all' }}>
              {Name}
            </Heading>
          </div>
        </CopyToClipboard>
        {this.state.nameCopied && (
          <Text color="success" size="medium">
            Copied!
          </Text>
        )}
        <div css={labelInfoDiv(theme)}>
          <Text as="span" size="medium" color="light">
            Departments
          </Text>
          <div css={labelFlexRight}>
            <div
              css={statusDot(theme, this.props.departmentsEnabled ? 'success' : 'error')}
            />
            <Text as="span" size="medium">
              {this.props.departmentsEnabled ? 'Enabled' : 'Disabled'}
            </Text>
          </div>
        </div>
        {this.props.softphonesEnabled && (
          <div css={labelInfoDiv(theme)}>
            <Text as="span" size="medium" color="light">
              Softphones
            </Text>
            <div css={labelFlexRight}>
              <div css={statusDot(theme, 'success')} />
              <Text as="span" size="medium">
                Enabled
              </Text>
            </div>
          </div>
        )}
        {this.props.isMultiOfficeBeta ? (
          <React.Fragment>
            <hr />

            {ParentID && (
              <>
                <div css={labelInfoDiv(theme, 0)}>
                  <Text as="span" weight="bold" css={multiLocationTextStyle}>
                    Multi-Location
                  </Text>
                  <Text as="span" weight="bold" size="medium">
                    Child
                  </Text>
                </div>

                <LocationStatus
                  locationStatus={this.getLocationStatus(
                    LocationID,
                    PhoneTenantID ?? '',
                    ParentID
                  )}
                />

                <div css={labelInfoDiv}>
                  <Text as="span" size="medium" color="light">
                    Parent Account
                  </Text>
                </div>

                <NakedButton
                  css={accountLinkStyle}
                  onClick={() => {
                    this.props.setLocation(ParentID);
                  }}
                >
                  {this.state.parentLocationName || ParentID}
                </NakedButton>
              </>
            )}

            {!ParentID && (
              <React.Fragment>
                {' '}
                <div css={labelInfoDiv(theme, 0)}>
                  <Text weight="bold" css={multiLocationTextStyle}>
                    Multi-Location
                  </Text>
                  <Text as="span" weight="bold" size="medium" css={labelFlexRight}>
                    {childrenAccounts.length} Locations
                  </Text>
                </div>
                <LocationStatus
                  locationStatus={this.getLocationStatus(
                    LocationID,
                    PhoneTenantID ?? '',
                    ''
                  )}
                  hasChildren={childrenAccounts.length > 0}
                />
                <SyncAppMultiCount />
                {this.getLocationWarnings().map((warningMessage) => (
                  <WarningAlert title={warningMessage} />
                ))}
                <div
                  css={dropdownContainerStyle}
                  onClick={() => {
                    this.toggleMultiLocationDropdownOpen();
                  }}
                >
                  <div css={labelInfoDiv(theme, 0)}>
                    <FamilyIcon />

                    <Text as="span" weight="bold" css={mLeftSpacing}>
                      {Name}
                    </Text>
                  </div>
                  {!this.state.multiLocationDropdownOpen && (
                    <CaretDownIconSmall color="light" css={iconPointerStyle} />
                  )}
                  {this.state.multiLocationDropdownOpen && (
                    <CaretUpIconSmall color="light" css={iconPointerStyle} />
                  )}
                </div>
                {this.state.multiLocationDropdownOpen && (
                  <div css={multiLocationDropdown}>
                    <Text size="medium" color="light">
                      Parent Account
                    </Text>

                    <NakedButton
                      css={accountLinkStyle}
                      onClick={() => {
                        this.props.setLocation(LocationID);
                      }}
                    >
                      {Name}
                    </NakedButton>

                    <Text size="medium" color="light">
                      Child Accounts
                    </Text>

                    {childrenAccounts.map((child) => {
                      return (
                        <React.Fragment>
                          <NakedButton
                            css={accountLinkStyle}
                            onClick={() => {
                              this.props.setLocation(child.LocationID);
                            }}
                            key={child.LocationID}
                          >
                            {child.Name}
                          </NakedButton>
                          <CopyToClipboard
                            text={child.Slug}
                            onCopy={() => this.copiedChildSlugText(child.Slug)}
                          >
                            <div css={[iconPointerStyle, labelInfoDiv(theme)]}>
                              <Text as="span" size="medium">
                                {child.Slug}
                              </Text>
                            </div>
                          </CopyToClipboard>
                          {this.state.childSlugNameCopied[child.Slug] && (
                            <Text color="success" size="medium">
                              Copied!
                            </Text>
                          )}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <LocationStatus
            locationStatus={this.getLocationStatus(
              LocationID,
              PhoneTenantID ?? '',
              ParentID ?? ''
            )}
            hasChildren={childrenAccounts.length > 0}
          />
        )}
        <hr />
        <div css={labelInfoDiv(theme, 0)}>
          <Text as="span" size="medium" color="light">
            Status
          </Text>
          <div css={labelFlexRight}>
            <div css={statusDot(theme, Active ? 'success' : 'error')} />
            <Text as="span" size="medium">
              {Active ? 'Active' : 'Inactive'}
            </Text>
          </div>
        </div>
        <CopyToClipboard text={Slug} onCopy={this.copiedSlugText}>
          <div css={[iconPointerStyle, labelInfoDiv(theme)]}>
            <Text as="span" size="medium" color="light" title="Copy to clipboard">
              Slug
            </Text>
            <Text as="span" size="medium">
              {Slug}
            </Text>
          </div>
        </CopyToClipboard>
        {this.state.slugCopied && (
          <Text color="success" size="medium">
            Copied!
          </Text>
        )}
        <div css={labelInfoDiv}>
          <div css={labelFlex}>
            {homerUrl && (
              <IconButton
                label="Go to Homer portal"
                size="small"
                to={homerUrl}
                rel="noopener noreferrer"
                target="_blank"
                css={mLeftSpacing(theme, -1)}
              >
                <ExternalLinkIcon css={iconSmall} />
              </IconButton>
            )}
            <Text as="span" size="medium" color="light">
              Data Center
            </Text>
          </div>

          <Text as="span" size="medium">
            {DataCenter}
          </Text>
        </div>
        <CopyToClipboard text={LocationID} onCopy={this.copiedLocationIdText}>
          <div css={[iconPointerStyle, labelInfoDiv(theme)]}>
            <Text as="span" size="medium" color="light" title="Copy to clipboard">
              Location ID
            </Text>
            <Text as="span" size="medium">
              {LocationID}
            </Text>
          </div>
        </CopyToClipboard>
        {this.state.locationIdCopied && (
          <Text color="success" size="medium">
            Copied!
          </Text>
        )}
        <div css={[labelInfoDiv(theme)]}>
          <Text as="span" size="medium" color="light">
            Phone Tenant ID
          </Text>
          <Text as="span" size="medium">
            {PhoneTenantID || 'None'}
          </Text>
        </div>
        {this.state.tcrBizStatus && !this.state.tcrFetching && (
          <div css={[labelInfoDiv(theme)]}>
            <Text as="span" size="medium" color="light">
              10DLC Biz Status
            </Text>
            <Text as="span" size="medium">
              {`${this.state.tcrBizStatus}`}
            </Text>
            <TenDLCBizStatusInfoButton />
          </div>
        )}
        {this.state.tcrPhoneStatus && !this.state.tcrFetching && (
          <div css={[labelInfoDiv(theme)]}>
            <Text as="span" size="medium" color="light">
              10DLC Phone Status
            </Text>
            <Text size="medium" css={{ margin: 0 }}>
              {this.state.tcrBizStatus === TCRBrandFriendlyRegistrationStatus.CANADA ||
              this.state.tcrBizStatus ===
                TCRBrandFriendlyRegistrationStatus.SOLE_PROPRIETOR
                ? this.state.tcrBizStatus
                : `${
                    this.state.tcrPhoneStatus.registered ? 'Registered' : 'Unregistered'
                  }`}
            </Text>
            <TenDLCPhoneStatusInfoButton />
          </div>
        )}
        <hr />

        {!ParentID && <SidebarSyncApp />}

        <Modal show={this.state.isModalOpen} onClose={this.toggleModal} maxWidth={480}>
          <LocationEdit onClose={this.toggleModal} />
        </Modal>
        <Modal
          show={this.state.isParentageModalOpen}
          onClose={this.toggleParentageModal}
          maxWidth={480}
        >
          <LocationParentageModal
            locationID={LocationID}
            parentLocationID={ParentID ?? ''}
            phoneTenantID={PhoneTenantID ?? ''}
            onClose={this.toggleParentageModal}
            hasChildren={childrenAccounts.length > 0}
            getLocationStatus={this.getLocationStatus}
          />
        </Modal>
      </div>
    );
  }
}
