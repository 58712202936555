import React from 'react';
import { Route } from 'react-router-dom';
import { ONBOARDING } from '../../../constants/paths';

import { CrawlSwitch } from '../../../routers/crawl-switch';
import { HandoffHub } from './components/handoff-hub/handoff-hub';
import { OnboardingComponent } from '../onboarding-hub/onboarding-component';
import { IntakeFormComponentNew } from './components/intake-form-hub/intake-form-component';
import { PortingHub } from '../../porting-hub/porting-hub/porting-hub';
import { PortingHubContainer } from '../../porting-hub-container/porting-hub.container';
import { HandoffHubInfo } from './components/handoff-hub/handoff-hub-info';
import { AddEditHandoff } from './components/handoff-hub/edit-handoff/add-edit-handoff';

export const OnboardingHandoffRouter = () => {
  return (
    <CrawlSwitch>
      <Route path={ONBOARDING.onboarding} component={OnboardingComponent} />
      <Route path={ONBOARDING.intakeForm} component={IntakeFormComponentNew} />
      <Route path={ONBOARDING.porting} component={PortingHubContainer} />
      <Route path={ONBOARDING.portingV2} component={PortingHub} />
      <Route path={ONBOARDING.handoff} component={HandoffHub} exact />
      <Route path={ONBOARDING.createHandoff} component={AddEditHandoff} />
      <Route path={ONBOARDING.handoffHubInfo} component={HandoffHubInfo} />
    </CrawlSwitch>
  );
};
