import React, { useMemo } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { LocationGroupInfo } from '../../../apis/location-provision/types';
import { LocationGroupCard } from './location-group-card';

interface Props {
  list: LocationGroupInfo[];
  onSelect: (parentName: string, slugList: string[]) => void;
  onGroupNameChange: (
    oldName: string,
    newName: string,
    successCallback: () => void
  ) => void;
}

export const LocationGroupCards = ({ list, onSelect, onGroupNameChange }: Props) => {
  const { defaultGroup, locationGroups } = useMemo(() => {
    const [defaultGroup, ...locationGroups] = list;
    return { defaultGroup, locationGroups };
  }, [list]);

  return (
    <>
      <LocationGroupCard
        locationId={defaultGroup.locationId}
        name={defaultGroup.name}
        locations={defaultGroup.children}
        isSingleLocationGroup={defaultGroup.isSingleLocationGroup}
        isDefaultGroup
        onSelect={(slugList) => onSelect(defaultGroup.name, slugList)}
        onNameChange={(name, successCallback) =>
          onGroupNameChange(defaultGroup.name, name, successCallback)
        }
      />
      <div css={cardListContainerStyle}>
        {locationGroups.map((locationGroup) => (
          <LocationGroupCard
            locationId={locationGroup.locationId}
            name={locationGroup.name}
            locations={locationGroup.children}
            isSingleLocationGroup={locationGroup.isSingleLocationGroup}
            onSelect={(slugList) => onSelect(locationGroup.name, slugList)}
            onNameChange={(name, successCallback) =>
              onGroupNameChange(locationGroup.name, name, successCallback)
            }
          />
        ))}
      </div>
    </>
  );
};

const cardListContainerStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
});
