import { Reducer } from 'redux';
import { Store } from '../../../store/store.model';
import { LocationTypes } from '../locations';
import { VerticalEnum } from '../../../../models/vertical-enum';

// export interface LocationCreationResponseModel {
//   activated_packages_successfully?: boolean;
//   customization_flags_setup_successfully: boolean;
//   e911_setup_successfully: boolean;
//   location_created_successfully: boolean;
//   phone_data_tenant_created_successfully: boolean;
//   software_integration_setup_successfully: boolean;
//   temp_number_setup_successfully: boolean;
//   location_id: string;
// }

export interface LocationCreationResponseModel {
  activated_packages_error: string;
  activated_packages_successfully: boolean;
  customization_flags_error: string;
  customization_flags_setup_successfully: boolean;
  e911_setup_error: string;
  e911_setup_successfully: boolean;
  location_created_error: string;
  location_created_successfully: boolean;
  location_id: string;
  phone_data_tenant_created_error: string;
  phone_data_tenant_created_successfully: boolean;
  software_integration_error: string;
  software_integration_setup_successfully: boolean;
  temp_number_setup_error: string;
  temp_number_setup_successfully: boolean;
  user_created_error: string;
  user_created_successfully: boolean;
}

export interface ProvisionLocationResponseDTO {
  locationCreatedSuccessfully: boolean;
  phoneDataTenantCreatedSuccessfully: boolean;
  customizationFlagsSetSuccessfully: boolean;
  salesforceUpdatedSuccessfully: boolean;
  locationId: string;
  tenantId: string;
  locationProvisionError: string;
}
export type ProvisionLocationRequestDTO = {
  name: string;
  slug: string;
  officeEmail: string;
  timeZone: string;
  verticalId: VerticalEnum;
  callerNumber: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  locationParentId: string | null;
  locationType: LocationTypes;
  salesforceProducts: string[];
  provisionedFrom: string;
  salesforceAccountId: string;
};

export enum LocationStatusActionTypes {
  CreateLocationSuccess = 'CREATE_LOCATION_SUCCESS',
  CreateLocationFailure = 'CREATE_LOCATION_FAILURE',
  CreateLocationLoading = 'CREATE_LOCATION_LOADING',
  ResetLocationCreationStatus = 'RESET_LOCATION_CREATION_STATUS',
}

type Action<ActionType extends LocationStatusActionTypes, Payload = undefined> = {
  type: ActionType;
} & { payload: Payload };
export type CreateLocationSuccessAction = Action<
  LocationStatusActionTypes.CreateLocationSuccess,
  ProvisionLocationResponseDTO
>;
export type CreateLocationFailureAction =
  Action<LocationStatusActionTypes.CreateLocationFailure>;
export type CreateLocationLoadingAction =
  Action<LocationStatusActionTypes.CreateLocationLoading>;
export type ResetLocationCreationStatusAction =
  Action<LocationStatusActionTypes.ResetLocationCreationStatus>;

export type LocationStatusActions =
  | CreateLocationSuccessAction
  | CreateLocationFailureAction
  | CreateLocationLoadingAction
  | ResetLocationCreationStatusAction;

export type LocationStatusState = {
  loading: boolean;
  creationStatus: ProvisionLocationResponseDTO | null;
};

export const createLocationSuccess = (
  res: ProvisionLocationResponseDTO
): LocationStatusActions => ({
  type: LocationStatusActionTypes.CreateLocationSuccess,
  payload: res,
});
export const createLocationFailure = (): LocationStatusActions => ({
  type: LocationStatusActionTypes.CreateLocationFailure,
  payload: undefined,
});
export const createLocationLoading = (): LocationStatusActions => ({
  type: LocationStatusActionTypes.CreateLocationLoading,
  payload: undefined,
});
export const resetLocationCreationStatus = (): LocationStatusActions => ({
  type: LocationStatusActionTypes.ResetLocationCreationStatus,
  payload: undefined,
});

export const locationStatusReducer: Reducer<
  LocationStatusState,
  LocationStatusActions
> = (state = { loading: false, creationStatus: null }, action) => {
  switch (action.type) {
    case LocationStatusActionTypes.CreateLocationLoading:
      return { ...state, loading: true, creationStatus: null };
    case LocationStatusActionTypes.CreateLocationSuccess:
      return { ...state, loading: false, creationStatus: action.payload };
    case LocationStatusActionTypes.CreateLocationFailure:
      return {
        ...state,
        loading: false,
        creationStatus: {
          locationCreatedSuccessfully: false,
          phoneDataTenantCreatedSuccessfully: false,
          customizationFlagsSetSuccessfully: false,
          salesforceUpdatedSuccessfully: false,
          locationId: '',
          tenantId: '',
          locationProvisionError: '',
        },
      };
    case LocationStatusActionTypes.ResetLocationCreationStatus:
      return { ...state, loading: false, creationStatus: null };
    default:
      return state;
  }
};

export const selectLocationCreationStatus = (store: Store) => store.locationStatus;
