import { connect } from 'react-redux';

import { selectHasWeaveAcl } from '../../../redux/actions/auth/auth.selectors';
import { CoreACLs } from '../../../redux/actions/auth/auth.types';
import { CallRecords } from './call-records/call-records.component';
import { downloadCdr, downloadCdrALeg, getRecords } from '../../../redux/actions/records';
import {
  selectCurrentLocationId,
  selectCurrentLocationSlug,
} from '../../../redux/actions/location/current-location';

export const CallRecordsContainer = connect(
  (state: any) => ({
    isNextPageAvailable: state.records.isNextPageAvailable,
    loading: state.records.loading,
    locationId: selectCurrentLocationId(state),
    records: state.records.records,
    slug: selectCurrentLocationSlug(state),
    // only let tier 2 agents donwload A leg CDRs
    canDownloadCDRALeg: selectHasWeaveAcl(
      state,
      CoreACLs.AUTOMATEDNOTIFICATIONQUEUEWRITE
    ),
  }),
  (dispatch) => ({
    getRecords: (phoneNumber, start, end, limit, skip) => {
      dispatch(getRecords({ phoneNumber, start, end, limit, skip }));
    },
    downloadCDR: (channelId) => {
      dispatch(downloadCdr(channelId));
    },
    downloadCDRALeg: (channelId) => {
      dispatch(downloadCdrALeg(channelId));
    },
  })
)(CallRecords);
