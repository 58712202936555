import React from 'react';
import { PrimaryButton } from '@weave/design-system';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { HandoffTable } from './handoff-table';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../../../redux/actions/location';
import { useHistory } from 'react-router-dom';
import { injectParams, ONBOARDING } from '../../../../../constants/paths';
import { Page } from '../../../../shared';
import {
  useDeleteHandoffHubSnapshotMutation,
  useGetHandoffHubSnapshotsQuery,
} from '../../handoff-hub.queries';

export const HandoffHub = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const history = useHistory();

  const { data: snapshots, isFetching, refetch } = useGetHandoffHubSnapshotsQuery();
  const { mutate: deleteHandoffHubSnapshot, isLoading: isDeleting } =
    useDeleteHandoffHubSnapshotMutation();

  const handoffCreateHandoffUrl = injectParams(ONBOARDING.createHandoff, {
    id: locationId,
  });

  const openHandoffInfoPage = (snapshotId: string) => {
    const handoffHubInfoUrl = injectParams(ONBOARDING.handoffHubInfo, {
      id: locationId,
      snapshotId,
    });
    history.push(handoffHubInfoUrl);
  };

  const handleCreateButtonClicked = () => {
    history.push(handoffCreateHandoffUrl);
  };

  return (
    <Page
      css={mainContainer}
      title="Handoff Hub"
      subtitle={`View handoff notes below or create a new handoff note`}
      headerActions={
        <div css={buttonsBar}>
          <PrimaryButton
            size="large"
            css={createUpsellButton}
            onClick={handleCreateButtonClicked}
          >
            Create Handoff
          </PrimaryButton>
          <PrimaryButton
            size="large"
            css={refreshButton}
            disabled={isFetching}
            onClick={() => refetch()}
            data-testid="refreshSnapshots"
          >
            Refresh
          </PrimaryButton>
        </div>
      }
      loading={isFetching || isDeleting}
    >
      <HandoffTable
        snapshots={snapshots}
        onViewOrEditClick={openHandoffInfoPage}
        onDeleteClick={deleteHandoffHubSnapshot}
      />
    </Page>
  );
};

const mainContainer = css`
  padding: ${theme.spacing(4, 5)};
`;

const title = css`
  font-size: ${theme.fontSize(24)};
  flex-grow: 1;
`;

const titleContainer = css`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  margin-bottom: ${theme.spacing(3)};
  align-items: flex-start;
`;

const refreshButton = css`
  max-width: 119px;
`;

const createUpsellButton = css`
  max-width: 174px;
  margin-right: ${theme.spacing(2)};
`;

const buttonsBar = css`
  display: flex;
  justify-content: flex-end;
  flex-grow: 2;
`;
