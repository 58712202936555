import { useMemo } from 'react';
import {
  ProtoRouteParams,
  protosApiFactory,
  protosQueryFactory,
  replaceRouteParams,
} from './proto-utils';

type AnyObject = Record<string | number | symbol, any>;

export const protosHookFactory =
  <Paths extends AnyObject>(prefix?: string) =>
  <Path extends keyof Paths>(path: Path) =>
  (routeParams?: ProtoRouteParams<Path>) => {
    const url = useMemo(
      () => replaceRouteParams(String(path), routeParams),
      [JSON.stringify(routeParams)]
    );

    const methods = useMemo(
      () => protosApiFactory<Paths>(prefix)(path)(routeParams),
      [url]
    );
    return { methods, useQuery: protosQueryFactory(prefix)(url)(routeParams) };
  };
