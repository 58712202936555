import React, { useMemo } from 'react';
import {
  ButtonBar,
  PopoverDialog,
  PrimaryButton,
  SecondaryButton,
  Text,
  UsePopoverDialogResponse,
  useFormField,
  ChecklistField,
  CheckboxField,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import { SalesforceAccountProductBundle } from '../customization-page-v2.types';
import { useTableFilterValue, useTableFilterValueUpdater } from '../providers';
import { weaveProductBundles } from '../customization-page-v2.constant';
import { SOFTWARE_ONLY, getProductPackageBundleName } from '../customization-page.utils';
import { IntegrationPackagesEnum } from '../customization-page-v2.types';
import { BundlePackagesEnum } from '../customization-page-v2.types';

interface Props {
  popoverProps: UsePopoverDialogResponse;
  onApply: (filterValue: SalesforceAccountProductBundle | '') => void;
}

export const CustomizationPageTableFilters = ({ popoverProps, onApply }: Props) => {
  const tableFilterValue = useTableFilterValue();
  const updateTableFilterValue = useTableFilterValueUpdater();

  const { isSoftwareOnly, productBundle } = useMemo(() => {
    const isSoftwareOnly = tableFilterValue.includes(SOFTWARE_ONLY);
    const productBundle = isSoftwareOnly
      ? tableFilterValue.replace(`_${SOFTWARE_ONLY}_`, '_')
      : tableFilterValue;
    return { isSoftwareOnly, productBundle };
  }, [tableFilterValue]);

  const softwareOnlyCheckboxProps = useFormField(
    {
      type: 'checkbox',
      value: isSoftwareOnly,
    },
    [isSoftwareOnly]
  );
  const bundleCheckboxProps = useFormField(
    {
      type: 'checklist',
      minRequired: 0,
      maxAllowed: 1,
      value: productBundle ? [productBundle] : [],
    },
    [productBundle]
  );

  const { close, getDialogProps } = popoverProps;

  const getSalesForceBundlePackageName = () => {
    const bundleAndIntegrationPackageName = bundleCheckboxProps.value?.[0]?.split('_');
    if (bundleAndIntegrationPackageName?.length) {
      const bundlePackageName = bundleAndIntegrationPackageName.pop();
      const integrationPackageName = bundleAndIntegrationPackageName.pop();
      const isSoftwareOnly = softwareOnlyCheckboxProps.value;
      const salesforceProductBundleName = getProductPackageBundleName(
        integrationPackageName as IntegrationPackagesEnum,
        bundlePackageName as BundlePackagesEnum,
        isSoftwareOnly
      );
      return salesforceProductBundleName;
    }
    return '';
  };

  const updateSelectedBundleFilterValue = (
    bundlePackageName: SalesforceAccountProductBundle
  ) => {
    if (bundlePackageName) {
      updateTableFilterValue(bundlePackageName);
    }
  };

  const handleOnApply = () => {
    const salesforceProductBundleName = getSalesForceBundlePackageName();
    updateSelectedBundleFilterValue(
      salesforceProductBundleName as SalesforceAccountProductBundle
    );
    onApply(salesforceProductBundleName);
    close();
  };

  const handleOnClose = () => {
    close();
  };

  return (
    <PopoverDialog {...getDialogProps()} css={{ minWidth: 364 }}>
      <Text size="large" weight="bold" css={textLinkStyles}>
        Select Bundle
      </Text>
      <ChecklistField
        css={checkboxStyles}
        {...bundleCheckboxProps}
        name="bundles-selector"
        label=""
      >
        {weaveProductBundles.map((productBundle) => (
          <ChecklistField.Option key={productBundle.value} name={productBundle.value}>
            {productBundle.name}
          </ChecklistField.Option>
        ))}
      </ChecklistField>
      <Text size="large" weight="bold" css={textLinkStyles}>
        Other
      </Text>
      <CheckboxField
        {...softwareOnlyCheckboxProps}
        css={checkboxStyles}
        name="Software Only"
        label="Software Only"
      />

      <ButtonBar>
        <SecondaryButton onClick={handleOnClose}>Cancel</SecondaryButton>
        <PrimaryButton
          onClick={handleOnApply}
          disabled={bundleCheckboxProps?.value?.length > 1}
        >
          Apply
        </PrimaryButton>
      </ButtonBar>
    </PopoverDialog>
  );
};

const textLinkStyles = css`
  padding-left: ${theme.spacing(2)};
  margin: ${theme.spacing(1)} 0;
`;

const checkboxStyles = css`
  padding: ${theme.spacing(0, 2)};
`;
