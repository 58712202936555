import React from 'react';
import { Route } from 'react-router-dom';

import OrderNewNumber from './twilio/order-new-number/order-new-number.component';
import { CallBlockingContainer } from './call-blocking-container/call-blocking.container';
import { Device } from './devices/device.component';
import { Devices } from './devices/devices.component';
import { CallGroupEdit } from './call-groups/call-group-edit.component';
import { CallGroups } from './call-groups/call-groups.component';
import { CallSettingsContainer } from './call-settings-container/call-settings.container';
import { OfficeHours } from './office-hours/office-hours.component';
import { OfficeHoursContainer } from './office-hours/legacy/office-hours.container';
import { FaxSettings } from './fax-settings-container/fax-settings/fax-settings.component';
import { VoiceMailboxes } from './voice-mailboxes/voice-mailboxes.component';
import { MediaLibraryContainer } from './media-library-container/media-library.container';
import { PhoneTreeList } from './phone-tree/phone-tree-list.component';
import { HoldMusic } from './hold-music/hold-music.component';
import { PHONES } from '../../constants/paths';
import { Phone404 } from './phone-404.component';
import { TenantMigration } from './tenant-migration/tenant-migration.component';
import { UserMobileSupport } from '../users/user-mobile-support.component';
import { CrawlSwitch } from '../../routers/crawl-switch';
import { Deunification } from './deunification/deunification.component';

export const PhoneRouter = () => {
  return (
    <CrawlSwitch>
      <Route path={PHONES.officeHours} component={OfficeHours} />
      <Route path={PHONES.officeHoursLegacy} component={OfficeHoursContainer} />
      <Route exact path={PHONES.devices} component={Devices} />
      <Route path={PHONES.device} component={Device} />
      <Route exact path={PHONES.callGroups} component={CallGroups} />
      <Route path={PHONES.callGroup} component={CallGroupEdit} />
      <Route path={PHONES.callSettings} component={CallSettingsContainer} />
      <Route path={PHONES.faxSettings} component={FaxSettings} />
      <Route path={PHONES.orderNewNmber} component={OrderNewNumber} />
      <Route path={PHONES.callBlocking} component={CallBlockingContainer} />
      <Route path={PHONES.voiceMailboxes} component={VoiceMailboxes} />
      <Route path={PHONES.mediaLibrary} component={MediaLibraryContainer} />
      <Route path={PHONES.phoneTree} component={PhoneTreeList} />
      <Route path={PHONES.holdMusic} component={HoldMusic} />
      <Route path={PHONES.tenantMigration} component={TenantMigration} />
      <Route path={PHONES.deunification} component={Deunification} />
      <Route path={PHONES.userMobileSupport} component={UserMobileSupport} />
      <Route path={PHONES.root} component={Phone404} />
    </CrawlSwitch>
  );
};
