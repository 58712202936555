import React from 'react';
import {
  CaretDownIconSmall,
  CaretUpIconSmall,
  ConfirmBadgeIcon,
  Modal,
  Text,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  caretColor,
  displayStyles,
  modalBodyStyle,
  modalContentBodyStyles,
  modalHeaderStyle,
  sentToEmailStyle,
  subTitleStyle,
  successModalContentStyle,
} from './intake-form-card.component';

type Props = {
  sendWelcomeCallButtonResultModalProps;
  locationData;
  setToggleSeeMoreLocations;
  toggleSeeMoreLocations;
  handleSendIntakeFormData;
  successfulFormsSentResponse;
  isMultiLocation;
};

export const IntakeFormSuccessModalComponent = ({
  sendWelcomeCallButtonResultModalProps,
  locationData,
  toggleSeeMoreLocations,
  setToggleSeeMoreLocations,
  handleSendIntakeFormData,
  successfulFormsSentResponse,
  isMultiLocation,
}: Props) => {
  return (
    <>
      <Modal {...sendWelcomeCallButtonResultModalProps} maxWidth={500}>
        <Modal.Header textAlign="left" css={modalHeaderStyle}>
          Success!
        </Modal.Header>
        <Modal.Body css={modalBodyStyle}>
          <Text>
            The intake form has successfully been sent. Please follow up with the POC to
            complete the form.
          </Text>
          <div css={successModalContentStyle}>
            <p css={subTitleStyle}>Intake Form Summary</p>
            <div css={sentToEmailStyle}>
              <Text>Sent to</Text>
              <div>
                <p css={modalContentBodyStyles}>
                  {locationData.onboardingContactFirstname}{' '}
                  {locationData.onboardingContactLastname}
                </p>
                <p>{handleSendIntakeFormData?.variables?.onboardingContactEmail}</p>
              </div>
            </div>
            {isMultiLocation && (
              <>
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <ConfirmBadgeIcon
                    css={css`
                      color: ${theme.colors.success};
                      margin-right: ${theme.spacing(1)};
                    `}
                  />
                  <Text>Success ({successfulFormsSentResponse?.length})</Text>
                </div>
              </>
            )}
            <div>
              {isMultiLocation && (
                <div css={displayStyles}>
                  <p
                    css={css`
                      color: ${theme.colors.weaveBlue};
                      margin-right: ${theme.spacing(1)};
                    `}
                  >
                    See More
                  </p>
                  <div onClick={() => setToggleSeeMoreLocations(!toggleSeeMoreLocations)}>
                    {toggleSeeMoreLocations ? (
                      <CaretDownIconSmall css={caretColor} />
                    ) : (
                      <CaretUpIconSmall css={caretColor} />
                    )}
                  </div>
                </div>
              )}
              {isMultiLocation ? (
                <>
                  {!toggleSeeMoreLocations && (
                    <>
                      {handleSendIntakeFormData?.locations?.map((sendResponse, index) => (
                        <p key={index}>{sendResponse.name}</p>
                      ))}
                    </>
                  )}
                </>
              ) : (
                <>
                  {handleSendIntakeFormData?.locations?.map((sendResponse, index) => (
                    <p key={index}>{sendResponse.name}</p>
                  ))}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Actions
          onPrimaryClick={sendWelcomeCallButtonResultModalProps.onClose}
          primaryLabel="Close"
          data-testid="intakeFormButtonClose"
        ></Modal.Actions>
      </Modal>
    </>
  );
};
