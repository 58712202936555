import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@weave/design-system';

import { ManualTemplateItem } from './manual-template-item.component';
import {
  getManualTemplates,
  postManualTemplates,
} from '../../../redux/actions/manual-templates';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { ManualTemplate } from '../../../redux/actions/manual-templates/manual-templates.reducer';
import { Page } from '../../shared/page/page.component';

const templatesSelector = (state: any) => ({
  locationId: selectCurrentLocationId(state),
  templates: state.manualTemplates.data,
});

export const ManualTemplates = () => {
  const [cleanedTemplates, setCleanedTemplates] = useState<ManualTemplate[]>([]);
  const dispatch = useDispatch();
  const { locationId, templates } = useSelector(templatesSelector);

  useEffect(() => {
    setCleanedTemplates(
      templates.filter((template) => !!template.Type && template.Type !== 'missedcall')
    );
  }, [JSON.stringify(templates)]);

  useEffect(() => {
    dispatch(getManualTemplates());
  }, [locationId]);

  const handleSaveTemplate = async (newTemplate: ManualTemplate) => {
    const newTemplates = [...templates].map((template) => {
      if (
        template.Type.replaceAll('_', '-').replaceAll(' ', '-') ===
        newTemplate.Type.replaceAll('_', '-').replaceAll(' ', '-')
      ) {
        return newTemplate;
      }
      return template;
    });
    dispatch(postManualTemplates(newTemplates));
  };

  return (
    <>
      <Page title="Manual Templates">
        {cleanedTemplates?.map((template, index) => (
          <ManualTemplateItem
            key={`manual-template-${index}`}
            template={template}
            saveTemplate={handleSaveTemplate}
          />
        )) || <Text>No Templates Created</Text>}
      </Page>
    </>
  );
};
