import { ResetValidityModal } from './reset-validity-modal.component';
import { PrimaryButton, useModalControl } from '@weave/design-system';
import { CustomAxios } from 'redux/axios';
import { theme } from '@weave/theme';
import { css } from 'emotion';
import { useAlert } from '@weave/alert-system';

export const ResetPhoneValidityButton = () => {
  const { modalProps, openModal } = useModalControl();
  const alert = useAlert();

  const resetPhoneValidity = async (phoneNumber: string) => {
    try {
      await CustomAxios.post('/sms/number/v1/validity', {
        phoneNumber,
        valid: 'VALID_NUMBER_STATUS_VALID',
      });
      alert.success('Phone number sms validity reset');
    } catch {
      alert.error('Failed to reset phone number validity');
    }
  };

  return (
    <span
      className={css`
        margin-left: ${theme.spacing(1)};
      `}
    >
      <PrimaryButton onClick={openModal}>Reset Phone Number Validity</PrimaryButton>
      <ResetValidityModal
        modalProps={modalProps}
        resetPhoneValidity={resetPhoneValidity}
      />
    </span>
  );
};
