import React from 'react';
import { Chip, ExternalLinkIcon, NakedButton } from '@weave/design-system';
import { useGetLocation } from './user-search.api';
import { useDispatch } from 'react-redux';
import { setCurrentLocationId } from 'redux/actions/location';
import { theme } from '@weave/theme-original';

type UserLocationProps = {
  locationId: string;
};

export const UserLocation = ({ locationId, ...rest }: UserLocationProps) => {
  const location = useGetLocation(locationId);
  const dispatch = useDispatch();
  const onClickLocation = () => dispatch(setCurrentLocationId(locationId));
  return (
    <>
      {location.isFetched && location.data && (
        <NakedButton
          {...rest}
          onClick={onClickLocation}
          style={{
            background: theme.colors.gray200,
            borderRadius: theme.borderRadius.small,
            padding: '.25em .5em',
          }}
        >
          {location.data.Name}
          <ExternalLinkIcon
            color="light"
            style={{ height: '.8em', width: '.8em', marginLeft: '.1em' }}
          />
        </NakedButton>
      )}
    </>
  );
};
