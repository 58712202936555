import { css } from '@emotion/core';
import { Alert, Text } from '@weave/design-system';
import { theme } from '@weave/theme';
import React from 'react';

const CUSTOMIZATION_FLAG_NO_EDIT_ACCESS_ALERT_TITLE =
  'It looks like you do not have write permission to edit customization flags. Please contact help desk to request access.';

export const CustomizationFlagNoEditAccessAlert = () => {
  return (
    <div css={css({ margin: theme.spacing(2) })}>
      <Alert type="warning">
        <Text weight="bold" as="span">
          {CUSTOMIZATION_FLAG_NO_EDIT_ACCESS_ALERT_TITLE}
        </Text>
      </Alert>
    </div>
  );
};
