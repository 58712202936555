import { css } from '@emotion/core';
import { theme } from '@weave/theme';

export const SINGLE_LOCATION_GROUP_NAME = 'Single Locations';

export const CREATE_NEW_GROUP_OPTION = 'create_new_group';
export const DEFAULT_ASSIGN_LOCATIONS_DROPDOWN_OPTIONS = [
  {
    label: 'Create As Single Location(s)',
    value: SINGLE_LOCATION_GROUP_NAME,
  },
  {
    label: 'Create New Location Group',
    value: CREATE_NEW_GROUP_OPTION,
  },
];

// NOTE: Keeping this here as we can't able to apply theme to chip component as WAM using originalTheme provider
// Original theme of Chip is not accessible so we were using theme for this single component
export const chipVariantsTheme = {
  primary: css`
    background: ${theme.colors.primary10};
    border-radius: ${theme.borderRadius.small};
    color: ${theme.colors.primary50};
  `,
  critical: css`
    background: ${theme.colors.critical10};
    color: ${theme.colors.critical50};
  `,
  success: css`
    background: ${theme.colors.success10};
    color: ${theme.colors.success50};
  `,
  warn: css`
    background: ${theme.colors.warning5};
    color: ${theme.colors.warning80};
  `,
  eggplant: css`
    background: ${theme.colors.secondary.eggplant10};
    color: ${theme.colors.secondary.eggplant50};
  `,
  seaweed: css`
    background: ${theme.colors.secondary.seaweed10};
    color: ${theme.colors.secondary.seaweed50};
  `,
  disabled: css`
    background: ${theme.colors.neutral10};
    color: ${theme.colors.neutral30};
  `,
  interactive: css`
    border: 1px solid ${theme.colors.primary50};
    color: ${theme.colors.primary50};
  `,
  neutral: css`
    background: ${theme.colors.neutral10};
    color: ${theme.colors.neutral50};
  `,
  outline: css`
    border: 1px solid ${theme.colors.neutral50};
    color: ${theme.colors.neutral50};
  `,
  default: css`
    background: ${theme.colors.neutral5};
    color: ${theme.colors.neutral50};
  `,
  primaryDark: css`
    background: ${theme.colors.primary20};
    color: ${theme.colors.primary80};
  `,
  warningDark: css`
    background: ${theme.colors.warning50};
    color: ${theme.colors.warning80};
  `,
};
