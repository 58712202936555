import React, { useRef } from 'react';
import { showError, showSuccess } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import { FormRow, Modal, TextField, useForm } from '@weave/design-system';
import { css } from '@emotion/core';
import { CustomAxios } from '../../redux/axios';
import { useDispatch } from 'react-redux';
import { FeatureFlag } from './feature-flags.api';

type EditFlagModalProps = ModalContentProps & {
  show: boolean;
};

type ModalContentProps = {
  flag: FeatureFlag;
  close: Function;
};
const ModalContent = (props: ModalContentProps) => {
  const dispatch = useDispatch();

  const initDefaultDescription = useRef(props.flag.description);
  const { getFieldProps, formProps, values } = useForm({
    computeChangedValues: true,
    fields: {
      flagName: { type: 'text', required: true, value: props.flag.name },
      description: { type: 'text', required: true, value: props.flag.description },
    },
    onSubmit: function () {
      CustomAxios.patch(`/feature-flags/v1/flags/${props.flag.name}`, {
        description: values.description,
      })
        .then((res) => {
          dispatch(showSuccess('Flag description updated!'));
          props.close(res.data);
        })
        .catch((err) => {
          dispatch(showError('Error updating feature flag'));
        });
    },
  });

  return (
    <>
      <Modal.Header>Global Flag Update</Modal.Header>
      <Modal.Body>
        <form
          css={(theme: WeaveTheme) =>
            css`
              padding-top: ${theme.spacing(1)};
            `
          }
        >
          <FormRow>
            <TextField {...getFieldProps('flagName')} label="Name" disabled={true} />
          </FormRow>
          <FormRow>
            <TextField {...getFieldProps('description')} label="Description" />
          </FormRow>
        </form>
        <Modal.Actions
          // @ts-ignore It's the right type, Typescript just doesn't recognize that.
          onPrimaryClick={formProps.onSubmit}
          onSecondaryClick={() => {
            props.close();
          }}
          disablePrimary={initDefaultDescription.current === values.description}
          primaryLabel="Update description"
          secondaryLabel="Cancel"
        />
      </Modal.Body>
    </>
  );
};

export const EditFlagModal = ({ show, ...props }: EditFlagModalProps) => {
  return (
    <Modal
      show={show}
      onClose={() => {
        props.close();
      }}
    >
      <ModalContent {...props} />
    </Modal>
  );
};
