import { theme } from '@weave/theme-original';
import { CopyToClipboardButton } from 'components/shared/copy-to-clipboard/copy-to-clipboard-button';
import React from 'react';
import { UserLocations } from './user-locations.component';
import { UserLookupCard } from './user-lookup-card';
import { SearchUserByEmailResponse, SearchUserByIdResponse } from './user-search.api';

type UserLookupDetailsProps = {
  userData: SearchUserByIdResponse | SearchUserByEmailResponse[0];
};

export const UserLookupDetails = ({ userData }: UserLookupDetailsProps) => {
  return (
    <UserLookupCard
      css={{ marginBottom: theme.spacing(2) }}
      as="dl"
      aria-label="User Search Result"
    >
      {Object.entries(userData).map(
        ([key, value]) =>
          value && (
            <React.Fragment key={key}>
              <dt>{key}</dt>
              <dd>
                <CopyToClipboardButton>{value}</CopyToClipboardButton>
              </dd>
            </React.Fragment>
          )
      )}
      <dt>Locations</dt>
      <dd>
        <UserLocations userId={userData.UserID} />
      </dd>
    </UserLookupCard>
  );
};
