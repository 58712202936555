import React, { useEffect, useState } from 'react';
import { Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { TableStyles } from '../../../styles/table-style';
import { css } from '@emotion/core';
import { RouteComponentProps } from 'react-router';
import ReactTable from 'react-table';
import { Page } from '../../shared/page/page.component';
import { CopyMedia } from './copy-media.component';
import { TenantConfiguration } from './tenant-configuration.component';
import { useSelector } from 'react-redux';
import {
  LocationModel,
  selectCurrentLocationParentId,
} from '../../../redux/actions/location';
import { getLocation } from '../../../redux/actions/location/locations/api';
import { selectFeatureFlag } from 'redux/actions/feature-flags';
import { useGetMigrationEligibility } from 'apis/tenant-migration/tenant-migration.api';

export const TenantMigration: React.FC<RouteComponentProps> = (props) => {
  const deptFlag = useSelector((state) => selectFeatureFlag(state, 'departments'));
  const { data: migrationEligibility } = useGetMigrationEligibility();
  const hasSoftphone = !migrationEligibility?.isLocationEligible;
  const parentLocationId = useSelector(selectCurrentLocationParentId);
  const [parentLocation, setParentLocation] = useState<LocationModel>();
  useEffect(() => {
    if (parentLocationId) {
      getLocation(parentLocationId).then(setParentLocation);
    }
  }, [parentLocationId]);

  if (!parentLocation) {
    return <>No Parent Location</>;
  }

  return (
    <Page title={'Merge to Parent Tenant'}>
      {(deptFlag || hasSoftphone) && (
        <Text
          size="large"
          css={css`
            text-align: center;
            border: 1px solid ${theme.colors.warning};
            border-radius: ${theme.spacing(1)};
            padding: ${theme.spacing(1, 2)};
            margin: auto;
            background-color: rgba(255, 165, 31, 0.05);
          `}
        >
          {`Merge to Parent disabled. No locations with ${
            deptFlag ? 'departments' : 'softphone'
          } can be merged at this time.`}
        </Text>
      )}
      <SwitchEnabledStyles deptFlag={deptFlag || hasSoftphone}>
        <ReactTable
          columns={[
            {
              Header: 'Parent Tenant Location Id',
              id: 'slug',
              accessor: (info) => info?.LocationID,
            },
            { Header: 'Parent Tenant Name', id: 'name', accessor: (info) => info?.Name },
          ]}
          data={[parentLocation]}
          showPagination={false}
          loading={!parentLocation}
          pageSize={1}
          css={[
            TableStyles,
            css`
              .rt-tr-group:last-child {
                border-bottom: none !important ;
              }
            `,
          ]}
        />

        <CopyMedia />
        <TenantConfiguration />
      </SwitchEnabledStyles>
    </Page>
  );
};

export const SwitchEnabledStyles = (props) => {
  return (
    <div
      css={css`
        opacity: ${props.deptFlag ? '0.3' : '1'};
        pointer-events: ${props.deptFlag ? 'none' : 'auto'};
      `}
    >
      {props.children}
    </div>
  );
};
