import { createAction } from 'redux-actions';
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { CustomAxios, getErrorMessage, getResponseData } from '../../axios';
import { showError } from '@weave/alert-system';
import { selectCurrentLocationId } from '../location/current-location';
import { ResolvedPromise } from '../../store/store-utils';
import { LocationSettingsModel } from './auto-msg.types';

export const fetchDeliverySummary = createAction('FETCH_DELIVERY_SUMMARY');
export const fetchDeliverySummarySuccess = createAction('FETCH_DELIVERY_SUMMARY_SUCCESS');
export const fetchDeliverySummaryFailure = createAction('FETCH_DELIVERY_SUMMARY_FAILURE');

export const fetchQueuedTime = createAction('FETCH_QUEUED_TIME_SUMMARY');
export const fetchQueuedTimeSuccess = createAction('FETCH_QUEUED_TIME_SUCCESS');
export const fetchQueuedTimeFailure = createAction('FETCH_QUEUED_TIME_FAILURE');

export const autoMsgDeliveryReportsApi = {
  getDeliverySummary: () =>
    CustomAxios.get(
      `/support/v1/queue/delivery_summary_report?today=${new Date().toISOString()}`
    ).then(getResponseData),
  getLocationSettings: (locationId: string): Promise<LocationSettingsModel[]> =>
    CustomAxios.get(`/support/v1/locations/${locationId}/settings`).then(getResponseData),
};

export const handleFetchDeliverySummary = function* () {
  try {
    const response = yield call(autoMsgDeliveryReportsApi.getDeliverySummary);
    yield put(fetchDeliverySummarySuccess(response));
  } catch (error: any) {
    yield put(fetchDeliverySummaryFailure(error));
    yield put(showError(getErrorMessage(error)));
  }
};

export const handleFetchQueuedTime = function* () {
  try {
    const locationId: ReturnType<typeof selectCurrentLocationId> = yield select(
      selectCurrentLocationId
    );
    if (locationId) {
      const response: ResolvedPromise<
        ReturnType<typeof autoMsgDeliveryReportsApi.getLocationSettings>
      > = yield call(autoMsgDeliveryReportsApi.getLocationSettings, locationId);
      const formattedData = response.map((queueLog) => ({
        ...queueLog,
        value: JSON.parse(queueLog.value),
      }));
      yield put(fetchQueuedTimeSuccess(formattedData));
    }
  } catch (error: any) {
    yield put(fetchQueuedTimeFailure(error));
    yield put(showError(getErrorMessage(error)));
  }
};

export const saga = function* () {
  yield all([takeEvery(fetchDeliverySummary.toString(), handleFetchDeliverySummary)]);
  yield all([takeEvery(fetchQueuedTime.toString(), handleFetchQueuedTime)]);
};
