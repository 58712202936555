import { useMemo, useState } from 'react';
import { GetHierarchyBySlugResponse } from '@weave/schema-gen-ts/dist/schemas/location/v1/provision/location_provision_service.pb';

import {
  LocationGroupChildInfo,
  LocationGroupInfo,
  PartialLocationInfoMap,
} from '../../../apis/location-provision/types';
import {
  getLocationGroupsFromResponse,
  getNewGroupListWithLocationAssignment,
  getPageElementStatus,
} from '../helpers';

export const useProvisionLocationGroupList = () => {
  const [groupList, setGroupList] = useState<LocationGroupInfo[]>([]);

  const {
    groupNames,
    isSaveButtonDisabled,
    isRetryDisabled,
    isShowErrorBadge,
    isShowValidationFailureBadge,
    isAssignLocationButtonDisabled,
  } = useMemo(() => {
    const groupNames = groupList.reduce<string[]>((acc, group) => {
      if (!group.isSingleLocationGroup) acc.push(group.name);
      return acc;
    }, []);
    const {
      isSaveButtonDisabled,
      isRetryDisabled,
      isShowErrorBadge,
      isShowValidationFailureBadge,
      isAssignLocationButtonDisabled,
    } = getPageElementStatus(groupList);

    return {
      groupNames,
      isSaveButtonDisabled,
      isRetryDisabled,
      isShowErrorBadge,
      isShowValidationFailureBadge,
      isAssignLocationButtonDisabled,
    };
  }, [groupList]);

  const setLocationGroupsFromResponse = (res?: GetHierarchyBySlugResponse) => {
    setGroupList(getLocationGroupsFromResponse(res));
  };

  const updateLocationGroupName = (oldName: string, newName: string) => {
    setGroupList((list) =>
      list.map((locationGroup) =>
        locationGroup.name === oldName
          ? { ...locationGroup, name: newName }
          : locationGroup
      )
    );
  };

  const assignLocationToGroup = (
    selectedGroupName: string,
    selectedLocationMap: Map<string, string[]>
  ) => {
    setGroupList((existingGroups) =>
      getNewGroupListWithLocationAssignment({
        existingGroups,
        selectedGroupName,
        selectedLocationMap,
      })
    );
  };

  const updateGroupListLocationInfoBySlug = (locationInfoMap: PartialLocationInfoMap) => {
    setGroupList((list) =>
      list.map<LocationGroupInfo>((group) => ({
        ...group,
        children: group.children.map<LocationGroupChildInfo>((child) => ({
          ...child,
          ...locationInfoMap.get(child.slug),
        })),
      }))
    );
  };

  const updateGroupListLocationInfoByProvisionerId = (
    locationInfoMap: PartialLocationInfoMap
  ) => {
    setGroupList((list) =>
      list.map<LocationGroupInfo>((group) => {
        let parentLocationId = group.locationId;
        const children = group.children.map<LocationGroupChildInfo>((child) => {
          const newInfo = child.provisionerAuditId
            ? locationInfoMap.get(child.provisionerAuditId)
            : null;
          if (!parentLocationId && newInfo?.parentLocationId) {
            parentLocationId = newInfo.parentLocationId;
          }
          return { ...child, ...newInfo };
        });
        return { ...group, locationId: parentLocationId, children };
      })
    );
  };

  const resetProvisionerIdFromLocationInfo = () => {
    setGroupList((list) =>
      list.map((group) => ({
        ...group,
        children: group.children.map((child) => ({
          ...child,
          provisionerAuditId: '',
        })),
      }))
    );
  };

  return {
    groupList,
    groupNames,
    isSaveButtonDisabled,
    isRetryDisabled,
    isShowErrorBadge,
    isShowValidationFailureBadge,
    isAssignLocationButtonDisabled,
    updateGroupListLocationInfoByProvisionerId,
    resetProvisionerIdFromLocationInfo,
    updateGroupListLocationInfoBySlug,
    setLocationGroupsFromResponse,
    updateLocationGroupName,
    assignLocationToGroup,
  };
};
