import React from 'react';
import { useAlert } from '@weave/alert-system';
import { useQuery } from 'react-query';
import { PrimaryButton, Text, useForm, PhoneField } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { useEffect } from 'react';

import { Page } from 'components/shared';
import { portingApi } from '../../apis/porting/porting.api';

export const PortabilityCheck = () => {
  const alert = useAlert();
  const [isPortable, setIsPortable] = React.useState<boolean | undefined>(undefined);

  const { formProps, getFieldProps, values } = useForm({
    fields: {
      phoneNumber: {
        value: '',
        type: 'phone',
      },
    },
    onSubmit: (values) => {
      setIsPortable(undefined);
      if (values.phoneNumber) {
        refetch();
      }
    },
  });

  const { data, refetch, isSuccess, isLoading, isFetched } = useQuery(
    ['phone-number-portability', values.phoneNumber],
    () => {
      const phoneNumber = values.phoneNumber || '';

      return portingApi.validatePortability({
        phoneNumber,
      });
    },
    {
      enabled: false,
      retry: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: 1000 * 60,
      onError: () => {
        alert.error('Error checking portability');
      },
    }
  );

  useEffect(() => {
    if (!isFetched) return;

    if (data?.validationPassed) {
      setIsPortable(true);
    } else {
      setIsPortable(false);
    }
  }, [isSuccess]);

  return (
    <Page
      title="Phone Number Portability Check"
      subtitle="Check the portability of a phone number with multiple providers at once. Enter the phone number you wish to check portability and it will let you know if it is portable with at least one of our providers."
    >
      <form
        {...formProps}
        css={{
          display: 'flex',
          maxWidth: 600,
          'div:first-of-type': {
            flex: 1,
          },
        }}
      >
        <PhoneField
          {...getFieldProps('phoneNumber')}
          aria-label="Search Phone Number"
          label="Enter Phone Number"
        />
        <PrimaryButton
          id="portability-check-search-field"
          type="submit"
          disabled={isLoading}
          style={{ width: 'auto', marginLeft: theme.spacing(2) }}
        >
          Check Portability
        </PrimaryButton>
      </form>
      {isPortable !== undefined && (
        <div
          css={{
            'p:first-of-type': {
              color: isPortable ? theme.colors.success : theme.colors.error,
            },
          }}
        >
          <Text>
            {isPortable
              ? 'Phone Number is portable'
              : 'Number is non portable, unable to provide coverage'}
          </Text>
        </div>
      )}
    </Page>
  );
};
