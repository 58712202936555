import { DeviceModel } from '../../devices/devices.types';
import {
  all,
  call,
  select,
  CallEffect,
  AllEffect,
  SelectEffect,
} from 'redux-saga/effects';
import moment from 'moment';
import { isEmpty, isEqual } from 'lodash';

import { CustomAxios } from '../../../axios';
import { getFactorTestResults } from '../handoff-hub.action';
import { FactorLogicResult, FactorStatus, FactorTest } from '../handoff-hub.reducer';
import { OfficeHoursApi } from '../../../../components/phones/office-hours/legacy/interface';
import { handleGetGreetingsForAllMailboxes } from '../../voice-mailboxes/voice-mailboxes.action';
import { handleOfficeHours } from '../../office-hours/office-hours.action';
import { selectVoicemailGreetings } from '../../voice-mailboxes/voice-mailboxes.selectors';

const TOTAL_CALLS_PASS_AMOUNT = 10;
const INBOUND_CALLS_PASS_AMOUNT = 1;
const OUTBOUND_CALLS_PASS_AMOUNT = 1;
const PHONES_COUNT_PASS_AMOUNT = 1;
const VOICEMAIL_GREET_PASS_AMOUNT = 1;
const DEFAULT_HOURS: OfficeHoursApi = {
  mondayHoursStart: '08:00:00',
  mondayHoursStop: '17:00:00',
  mondayLunchHoursStart: '00:00:00',
  mondayLunchHoursStop: '00:00:00',
  mondayBreakHoursStart: '00:00:00',
  mondayBreakHoursStop: '00:00:00',
  tuesdayHoursStart: '08:00:00',
  tuesdayHoursStop: '17:00:00',
  tuesdayLunchHoursStart: '00:00:00',
  tuesdayLunchHoursStop: '00:00:00',
  tuesdayBreakHoursStart: '00:00:00',
  tuesdayBreakHoursStop: '00:00:00',
  wednesdayHoursStart: '08:00:00',
  wednesdayHoursStop: '17:00:00',
  wednesdayLunchHoursStart: '00:00:00',
  wednesdayLunchHoursStop: '00:00:00',
  wednesdayBreakHoursStart: '00:00:00',
  wednesdayBreakHoursStop: '00:00:00',
  thursdayHoursStart: '08:00:00',
  thursdayHoursStop: '17:00:00',
  thursdayLunchHoursStart: '00:00:00',
  thursdayLunchHoursStop: '00:00:00',
  thursdayBreakHoursStart: '00:00:00',
  thursdayBreakHoursStop: '00:00:00',
  fridayHoursStart: '08:00:00',
  fridayHoursStop: '17:00:00',
  fridayLunchHoursStart: '00:00:00',
  fridayLunchHoursStop: '00:00:00',
  fridayBreakHoursStart: '00:00:00',
  fridayBreakHoursStop: '00:00:00',
  saturdayHoursStart: '00:00:00',
  saturdayHoursStop: '00:00:00',
  saturdayLunchHoursStart: '00:00:00',
  saturdayLunchHoursStop: '00:00:00',
  saturdayBreakHoursStart: '00:00:00',
  saturdayBreakHoursStop: '00:00:00',
  sundayHoursStart: '00:00:00',
  sundayHoursStop: '00:00:00',
  sundayLunchHoursStart: '00:00:00',
  sundayLunchHoursStop: '00:00:00',
  sundayBreakHoursStart: '00:00:00',
  sundayBreakHoursStop: '00:00:00',
};
// handlePhonesRegisteredLogic
export const registeredPhonesCriteria = `Must have ${PHONES_COUNT_PASS_AMOUNT} phone(s) registered`;
// handlePhonesCustomizedLogic
export const voicemailCriteria = `Must have ${VOICEMAIL_GREET_PASS_AMOUNT} voicemail greeting(s) recorded`;
export const officeHoursCriteria = `Office Hours must not be the default`;
// handleCallsWorkingLogic
export const inboundCallsCriteria = `Must have received at least ${INBOUND_CALLS_PASS_AMOUNT} inbound call(s) in the last week`;
export const outboundCallsCriteria = `Must have made at least ${OUTBOUND_CALLS_PASS_AMOUNT} outbound call(s) in the last week`;
export const totalCallsCriteria = `Must have total of ${TOTAL_CALLS_PASS_AMOUNT} calls in the last week`;

export const getCallCounts = function* () {
  const response = yield call(CustomAxios.get, '/support/v1/calllogs', {
    params: {
      start: moment().subtract(1, 'week').startOf('day').toISOString(),
      end: moment().endOf('day').toISOString(),
    },
  });
  const calls = response.data.data || [];
  const callCounts = {
    inbound: 0,
    outbound: 0,
  };
  for (const phoneCall of calls) {
    const direction = phoneCall.Direction;
    callCounts[direction]++;
  }

  return callCounts;
};

export const checkCallsWorking = function* (): IterableIterator<
  FactorLogicResult | CallEffect
> {
  const tests: FactorTest[] = [];
  let factorStatus: FactorStatus = 'pass';
  let factorTest: FactorTest | undefined = undefined;

  const callCounts = yield call(getCallCounts);

  [factorTest, factorStatus] = getFactorTestResults(
    factorStatus,
    inboundCallsCriteria,
    // @ts-ignore
    callCounts.inbound,
    // @ts-ignore
    () => callCounts.inbound >= INBOUND_CALLS_PASS_AMOUNT
  );
  tests.push(factorTest);

  [factorTest, factorStatus] = getFactorTestResults(
    factorStatus,
    outboundCallsCriteria,
    // @ts-ignore
    callCounts.outbound,
    // @ts-ignore
    () => callCounts.outbound >= OUTBOUND_CALLS_PASS_AMOUNT
  );
  tests.push(factorTest);
  // @ts-ignore
  const total = callCounts.inbound + callCounts.outbound;

  [factorTest, factorStatus] = getFactorTestResults(
    factorStatus,
    totalCallsCriteria,
    total,
    () => total >= TOTAL_CALLS_PASS_AMOUNT
  );
  tests.push(factorTest);

  return { status: factorStatus, tests };
};

const getMailboxGreetings = function* () {
  yield call(handleGetGreetingsForAllMailboxes);
};

const getOfficeHrs = function* () {
  yield call(handleOfficeHours);
};

export const checkPhonesCustomized = function* (): IterableIterator<
  FactorLogicResult | CallEffect | AllEffect<CallEffect> | SelectEffect
> {
  const tests: FactorTest[] = [];
  let status: FactorStatus = '';
  let factorStatus: FactorStatus = 'pass';
  let name = '';
  let actual = '';
  let factorTest: FactorTest | undefined = undefined;

  yield all([call(getMailboxGreetings), call(getOfficeHrs)]);
  const greetings = yield select(selectVoicemailGreetings);
  const officeHours = yield select((store: any) => store.officeHours.data);

  [factorTest, factorStatus] = getFactorTestResults(
    factorStatus,
    voicemailCriteria,
    // @ts-ignore
    greetings.length,
    // @ts-ignore
    () => greetings.length >= VOICEMAIL_GREET_PASS_AMOUNT
  );
  tests.push(factorTest);

  name = officeHoursCriteria;
  actual = 'not set';
  status = 'fail';
  if (!isEmpty(officeHours) && !isEqual(officeHours, DEFAULT_HOURS)) {
    status = 'pass';
    actual = 'office hours set';
  }
  tests.push({ status, name, actual });
  if (status === 'fail') {
    factorStatus = status;
  }

  return { status: factorStatus, tests };
};

const getRegisteredPhonesCount = function* () {
  const response = yield call(CustomAxios.get, '/support/v1/phone/devices');
  const devices = response.data.data || [];

  return devices.reduce((count: number, device: DeviceModel) => {
    return device.registration?.addr ? ++count : count;
  }, 0);
};

export const checkPhonesRegistered = function* (): IterableIterator<
  FactorLogicResult | CallEffect
> {
  const tests: FactorTest[] = [];
  let factorStatus: FactorStatus = 'pass';
  let factorTest: FactorTest | undefined = undefined;

  const registeredPhonesCount = yield call(getRegisteredPhonesCount);

  [factorTest, factorStatus] = getFactorTestResults(
    factorStatus,
    registeredPhonesCriteria,
    // @ts-ignore
    registeredPhonesCount,
    // @ts-ignore
    () => registeredPhonesCount >= PHONES_COUNT_PASS_AMOUNT
  );
  tests.push(factorTest);

  return { status: factorStatus, tests };
};
