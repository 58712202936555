import { DownloadIcon, Table, TableColumnConfig } from '@weave/design-system';
import { UsageType } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/phone_number.pb';
import { PortingQueries } from 'apis/porting/porting.queries';
import { ExtendedRelationalPhoneNumber, NumberType } from 'apis/porting/porting.types';
import React, { FC, useMemo } from 'react';

interface PortOutTableProps {
  locationId: string;
  onTemplateDownload?: (numbersList: ExtendedRelationalPhoneNumber[]) => void;
}

export const PortOutTable: FC<PortOutTableProps> = ({
  locationId,
  onTemplateDownload,
}) => {
  const { data = [], isLoading } = PortingQueries.useGetPhoneNumbers({
    locationIds: [locationId],
  });

  const filteredTableData = useMemo(() => {
    return data?.filter(
      (phoneNumber) => phoneNumber.usageType !== UsageType.USAGE_TYPE_E911
    );
  }, [data]);

  const columnConfig = useMemo<TableColumnConfig<ExtendedRelationalPhoneNumber>[]>(() => {
    return [
      {
        id: 'phoneNumber',
        Header: 'Number',
        accessor: 'formattedPhoneNumber',
      },
      {
        id: 'type',
        Header: 'Number Type',
        accessor: 'type',
        cellRenderer: (type) => (type === NumberType.FAX ? 'Fax' : 'Voice/SMS'),
      },
    ];
  }, []);

  return (
    <Table
      isLoading={isLoading}
      colConfig={columnConfig}
      data={filteredTableData}
      bulkActions={[
        {
          label: 'Create CSR Template',
          Icon: () => <DownloadIcon />,
          onClick: (_, data) => {
            onTemplateDownload?.(data);
          },
        },
      ]}
      isPaginated
      isSelectable
    />
  );
};
