import { css } from '@emotion/core';
import { Modal, useModalControl, Text, SpinningLoader } from '@weave/design-system';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { CenteredSpinningLoader } from '../centered-spinning-loader';

interface RouterPromptProps {
  when: boolean;
  title: string;
  promptMessage: string;
  primaryLabel: string;
  secondaryLabel: string;
  isLoading?: boolean;
  navigateOnSecondaryClick?: boolean;
  onPrimaryClick: () => void;
  shouldBlockNavigation: (location: Location) => boolean;
  navigate: (path: string) => void;
  onSecondaryClick?: () => void;
}

interface PromptMessageType {
  promptMessage: string;
  isLoading: boolean;
}

export const RouterPrompt = ({
  when,
  title,
  promptMessage,
  primaryLabel,
  secondaryLabel,
  isLoading = false,
  navigateOnSecondaryClick = true,
  onPrimaryClick,
  shouldBlockNavigation,
  navigate,
  onSecondaryClick,
}: RouterPromptProps) => {
  const { modalProps, openModal, closeModal } = useModalControl();
  const [lastLocation, updateLastLocation] = useState<Location>();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const openPromptModal = (location) => {
    openModal();
    updateLastLocation(location);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      openPromptModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    if (lastLocation) {
      updateConfirmedNavigation(true);
      onPrimaryClick();
      closeModal();
    }
  };

  const handleOnModalClose = () => {
    if (navigateOnSecondaryClick && lastLocation) {
      updateConfirmedNavigation(true);
    } else if (onSecondaryClick) {
      onSecondaryClick();
    }
    closeModal();
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation && !isLoading) {
      navigate(lastLocation?.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation, isLoading]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Modal {...modalProps}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          {isLoading ? <CenteredSpinningLoader /> : <Text>{promptMessage}</Text>}
        </Modal.Body>
        <Modal.Actions
          onPrimaryClick={handleConfirmNavigationClick}
          onSecondaryClick={handleOnModalClose}
          primaryLabel={primaryLabel}
          secondaryLabel={secondaryLabel}
        />
      </Modal>
    </>
  );
};
