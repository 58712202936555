import React, { useMemo } from 'react';
import { css } from '@emotion/core';
import { getButtonContent } from './email-utils';
import { HtmlItem } from './html-item';
import { NotificationSetting } from '../../../recall-reminders/recall-settings.hook';

export type EmailTypes =
  | 'emailButton'
  | 'emailHeader'
  | 'emailMessage'
  | 'emailFooter'
  | 'emailImage';

export type HtmlContent = {
  type: EmailTypes;
  content: string;
  src?: string;
  alt?: string;
};

const container = css`
  position: relative;
`;

interface Props {
  settings: NotificationSetting;
  type: string;
  HTMLItems: HtmlContent[];
  setHTMLItems: (items: HtmlContent[]) => void;
}

export const EditEmailTemplate = ({ type, HTMLItems, setHTMLItems, settings }: Props) => {
  const buttonContent = useMemo(
    () => getButtonContent(HTMLItems, type),
    [HTMLItems.length, type]
  );

  const handleAddContent = (type, idx) => {
    const btnText = buttonContent || '(Disabled)';
    if ((type === 'emailButton' && btnText !== '(Disabled)') || type !== 'emailButton') {
      const content = type === 'emailButton' ? buttonContent : '';
      const element: HtmlContent = { type, content };
      const items = [...HTMLItems.slice(0, idx), element, ...HTMLItems.slice(idx)];
      setHTMLItems(items);
    }
  };

  const editContent = (idx, content) => {
    const items = [...HTMLItems];
    items[idx] = {
      ...items[idx],
      content,
    };
    setHTMLItems(items);
  };

  const removeItem = (idx) => {
    const items = HTMLItems.slice(0, idx).concat(HTMLItems.slice(idx + 1));
    setHTMLItems(items);
  };

  return (
    <div css={container}>
      {HTMLItems.map((item, idx) => (
        <HtmlItem
          key={`${item.type}-${idx}`}
          item={item}
          handleAddContent={(type) => handleAddContent(type, idx)}
          removeItem={() => removeItem(idx)}
          editContent={(e) => editContent(idx, e)}
          settings={settings}
        />
      ))}
    </div>
  );
};
