import { CustomAxios, getResponseData, serializeQueryParams } from '../../redux/axios';
import {
  CancelPortInOrderRequest,
  ClonePortingDataRequest,
  ClonePortingDataResponse,
  CreateSalesforcePortOrderRequest,
  CreateSalesforcePortOrderResponse,
  DeletePortOrderDocumentRequest,
  DeletePortOrderDocumentResponse,
  GetAvailableFOCDatesRequest,
  GetAvailableFOCDatesResponse,
  GetPortingValidationsByPortingDataIDRequest,
  GetPortingValidationsByPortingDataIDResponse,
  MediaType,
  PhoneBillDownloadPayload,
  PortCreationFileResponseData,
  PortingData,
  PortViewFileUploadResponse,
  UploadLOAMediaType,
  UploadLOAPayloadType,
  PortingRequest,
  ManagePhoneNumberResponse,
  InternalPortingNumberData,
  FOCDateResponse,
  e911NotesData,
  e911TenantData,
  E911TenantDashboard,
  e911AddressToBeSend,
  e911PhoneNumberDetials,
  childLocationDetails,
  loaStatus,
  userData,
  loaURL,
  LocationData,
  ListPhoneNumbersRequest,
  UpdatePortOrderDocumentRequest,
  UploadPortOrderDocumentRequest,
  UploadPortOrderDocumentResponse,
} from './porting.types';
import { AxiosRequestConfig } from 'axios';
import {
  ValidatePortabilityRequest,
  ValidatePortabilityResponse,
  PortingDataByLocationIDResponse,
  PortingDataUpdateRequest,
  PortingDataUpdateResponse,
  ProcessPortingRequestsByPhoneNumberRequest,
  ProcessPortingRequestsByPhoneNumberResponse,
  PortabilityCheckRequest,
  PortabilityCheckResponse,
  PortInCreateByPortingDataIDRequest,
  PortInCreateByPortingDataIDResponse,
  PortInStatusByPortingDataIDRequest,
  PortInStatusByPortingDataIDResponse,
  PortingDataBatchCreateRequest,
  PortingDataBatchCreateResponse,
  PortInSUPRequest,
  PortInSUPResponse,
  ReadPortingDataByIDResponse,
  ReadPortingDataByIDRequest,
  PortingDataDeleteRequest,
  GetPortOrderUrlResponse,
} from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import { debugTraceHeader, wamPortingTrace } from '../../redux/actions/porting/constants';

const SCHEMA_PORTING_PREFIX_URL = '/phone/porting/porting-data/v1';

////////////////////////////////// NEW SCHEMA ENDPOINTS //////////////////////////////////
const getPortOrderUrl = async (portingDataId: string) => {
  const response = await CustomAxios.get<GetPortOrderUrlResponse>(
    `${SCHEMA_PORTING_PREFIX_URL}/portin/${portingDataId}/port-order-url`
  );

  return getResponseData<GetPortOrderUrlResponse>(response);
};

const getPortingDataByLocationIds = async (locationId) => {
  const response = await CustomAxios.get<PortingDataByLocationIDResponse>(
    `${SCHEMA_PORTING_PREFIX_URL}/locations/list-port-orders?locationIds=${locationId}`
  );

  return getResponseData<PortingDataByLocationIDResponse>(response);
};

const getPortingDataById = async (req: ReadPortingDataByIDRequest) => {
  const response = await CustomAxios.get<ReadPortingDataByIDResponse>(
    `${SCHEMA_PORTING_PREFIX_URL}/${req.portingDataId}`
  );

  return getResponseData<ReadPortingDataByIDResponse>(response);
};

const deletePortingDataById = async (req: PortingDataDeleteRequest) =>
  CustomAxios.delete(`${SCHEMA_PORTING_PREFIX_URL}/${req.id}`);

const updatePortingDataById = async (req: PortingDataUpdateRequest) => {
  const response = await CustomAxios.put<PortingDataUpdateResponse>(
    `${SCHEMA_PORTING_PREFIX_URL}/${req.portingDataId}`,
    req
  );

  return getResponseData<PortingDataUpdateResponse>(response);
};

const createPortingData = async (req: PortingDataBatchCreateRequest) => {
  const response = await CustomAxios.post<PortingDataBatchCreateResponse>(
    `${SCHEMA_PORTING_PREFIX_URL}/create`,
    req
  );

  return getResponseData<PortingDataBatchCreateResponse>(response);
};

const processPortingRequestsByPhoneNumbers = async (
  req: ProcessPortingRequestsByPhoneNumberRequest
) => {
  const response = await CustomAxios.post<ProcessPortingRequestsByPhoneNumberResponse>(
    `${SCHEMA_PORTING_PREFIX_URL}/process-porting-requests`,
    req
  );

  return getResponseData<ProcessPortingRequestsByPhoneNumberResponse>(response);
};

const portInCreateByPortingDataId = async (req: PortInCreateByPortingDataIDRequest) => {
  const response = await CustomAxios.post<PortInCreateByPortingDataIDResponse>(
    `${SCHEMA_PORTING_PREFIX_URL}/portin/${req.portingDataId}/create`,
    req,
    {
      headers: { [debugTraceHeader]: wamPortingTrace },
    }
  );

  return getResponseData<PortInCreateByPortingDataIDResponse>(response);
};

const portInStatusByPortingDataId = async (req: PortInStatusByPortingDataIDRequest) => {
  const response = await CustomAxios.get<PortInStatusByPortingDataIDResponse>(
    `${SCHEMA_PORTING_PREFIX_URL}/portin/${req.portingDataId}/status?`
  );

  return getResponseData<PortInStatusByPortingDataIDResponse>(response);
};

const checkPortability = async (req: PortabilityCheckRequest) => {
  const response = await CustomAxios.post<PortabilityCheckResponse>(
    `${SCHEMA_PORTING_PREFIX_URL}/check-portability`,
    req
  );

  return getResponseData<PortabilityCheckResponse>(response);
};

const validatePortability = async (
  req: ValidatePortabilityRequest
): Promise<ValidatePortabilityResponse> => {
  const response = await CustomAxios.post<ValidatePortabilityResponse>(
    `${SCHEMA_PORTING_PREFIX_URL}/validate-portability`,
    req
  );

  return getResponseData<ValidatePortabilityResponse>(response);
};

const uploadPortOrderDocument = async (req: UploadPortOrderDocumentRequest) =>
  CustomAxios.post(
    `${SCHEMA_PORTING_PREFIX_URL}/upload-document`,
    req
  ).then<UploadPortOrderDocumentResponse>(getResponseData);

const updatePortOrderDocument = async (req: UpdatePortOrderDocumentRequest) =>
  CustomAxios.put(
    `${SCHEMA_PORTING_PREFIX_URL}/port-order/document`,
    req
  ).then<UploadPortOrderDocumentResponse>(getResponseData);

const portInSup = async (req: PortInSUPRequest): Promise<PortInSUPResponse> => {
  const response = await CustomAxios.patch<PortInSUPResponse>(
    `${SCHEMA_PORTING_PREFIX_URL}/portin/${req.portingDataId}/sup`,
    req
  );

  return getResponseData<PortInSUPResponse>(response);
};

const deletePortOrderDocument = (req: DeletePortOrderDocumentRequest) =>
  CustomAxios.delete(
    `${SCHEMA_PORTING_PREFIX_URL}/delete/port-order/${req.portingDataId}/media/${req.mediaDataId}`
  ).then<DeletePortOrderDocumentResponse>(getResponseData);

const createSFPortOrder = (request: CreateSalesforcePortOrderRequest) => {
  return CustomAxios.post(
    `${SCHEMA_PORTING_PREFIX_URL}/portin/${request.batchId}/salesforce/create`
  ).then((res) => getResponseData<CreateSalesforcePortOrderResponse>(res));
};

const cancelPortInOrder = (request: CancelPortInOrderRequest) =>
  CustomAxios.post(
    `${SCHEMA_PORTING_PREFIX_URL}/portin/${request.portingDataId}/cancel`,
    request
  ).then(getResponseData);

const clonePortInData = (request: ClonePortingDataRequest) =>
  CustomAxios.post(
    `${SCHEMA_PORTING_PREFIX_URL}/${request.portingDataId}/clone`,
    request
  ).then<ClonePortingDataResponse>(getResponseData);

const fetchAvailableFOCDates = (request: GetAvailableFOCDatesRequest) =>
  CustomAxios.get(
    `${SCHEMA_PORTING_PREFIX_URL}/portin/${request.portingDataId}/foc-dates`,
    {
      params: request,
    }
  ).then<GetAvailableFOCDatesResponse>(getResponseData);

const fetchPortingValidationData = (
  request: GetPortingValidationsByPortingDataIDRequest
) =>
  CustomAxios.get(
    `${SCHEMA_PORTING_PREFIX_URL}/portin/${request.portingDataId}/porting-validations`
  ).then<GetPortingValidationsByPortingDataIDResponse>(getResponseData);

export const portingSchemaApi = {
  getPortingDataById,
  deletePortingDataById,
  getPortingDataByLocationIds,
  updatePortingDataById,
  processPortingRequestsByPhoneNumbers,
  validatePortability,
  checkPortability,
  portInCreateByPortingDataId,
  portInStatusByPortingDataId,
  createPortingData,
  deletePortOrderDocument,
  uploadPortOrderDocument,
  portInSup,
  getPortOrderUrl,
  createSFPortOrder,
  cancelPortInOrder,
  clonePortInData,
  fetchAvailableFOCDates,
  fetchPortingValidationData,
  updatePortOrderDocument,
};

////////////////////////////////// OLD SCHEMA ENDPOINTS //////////////////////////////////
export const postPortingData = (body: PortingData): Promise<PortingData> =>
  CustomAxios.post('/support/v1/portingdata', body).then(getResponseData);

export const sendToSalesforce = (batchId: string): Promise<any> =>
  CustomAxios.post(
    `/support/v1/portingdata/portins/${batchId}/salesforce/create`,
    {}
  ).then(getResponseData);

export const uploadPhoneFile = (data: FormData) =>
  CustomAxios.post('/support/v1/media', data).then<PortCreationFileResponseData>(
    getResponseData
  );

export const deleteFile = (type: MediaType, mediaID: string) =>
  CustomAxios.delete(`/support/v1/media/${type}/${mediaID}`).then<unknown>(
    getResponseData
  );

//api for upload loa
export const portViewLoaUpload = (portingDataID: string, data: UploadLOAMediaType) => {
  const payloadData: UploadLOAPayloadType = {
    content_type: 'application/pdf',
    media_data: data,
  };
  return CustomAxios.put(
    `/support/v1/portingdata/${portingDataID}/loa`,
    payloadData
  ).then<PortViewFileUploadResponse>(getResponseData);
};

//api for upload bills and delete file from porting view page
export const portViewBillsUpload = (portingDataID: string, data: UploadLOAMediaType) => {
  const payloadData: UploadLOAPayloadType = {
    content_type: 'application/pdf',
    media_data: data,
  };
  return CustomAxios.post(
    `/support/v1/portingdata/${portingDataID}/media`,
    payloadData
  ).then<PortViewFileUploadResponse>(getResponseData);
};

export const portViewFileDelete = (portID: string, mediaID: string) =>
  CustomAxios.delete(`/support/v1/portingdata/${portID}/media/${mediaID}`).then<unknown>(
    getResponseData
  );

//download endpoints for view page
// No schema or monorail endpoint available for this
export const portViewLoaDownload = (portingID: string) =>
  CustomAxios.get(`/support/v1/portingdata/loa/${portingID}`, {
    responseType: 'blob',
  });

// No schema or monorail endpoint available for this
export const portViewBillDownload = (phoneBillMediaObj: PhoneBillDownloadPayload) => {
  const { location_id, customer_phone_bill_media_id, customer_phone_bill_media_type } =
    phoneBillMediaObj;
  return CustomAxios.get(
    `/support/v1/media/${location_id}/${customer_phone_bill_media_id}/${customer_phone_bill_media_type}`,
    {
      responseType: 'blob',
    }
  );
};

export const clonePortingData = (portingDataId: string): Promise<PortingData> =>
  CustomAxios.post(`/support/v1/portingdata/${portingDataId}/clone`).then<PortingData>(
    getResponseData
  );

export const updatePhoneNumbers = (body: PortingRequest[]): Promise<PortingRequest[]> => {
  return CustomAxios.put(`/support/v1/portingrequests/manage`, {
    portingRequests: body,
  })
    .then<ManagePhoneNumberResponse>(getResponseData)
    .then((data) => data.portingRequests);
};

export const cancelPortOrder = (portingDataId: string) =>
  CustomAxios.post(
    `/support/v1/portingdata/portins/${portingDataId}/cancel`
  ).then<PortingData>(getResponseData);

export const getPortingUrl = (portingDataId: string): Promise<string> =>
  CustomAxios.get(`/support/v1/portingdata/portins/${portingDataId}/portorderurl`).then(
    (data) => data?.data?.data?.port_order_url
  );

export const getPortingValidationData = (portingDataId: string): Promise<any> =>
  CustomAxios.get(`/support/v1/portingvalidations/portingdata/${portingDataId}`);

export const getInternalPortingNumberData = (
  locationID
): Promise<[InternalPortingNumberData]> => {
  const config: AxiosRequestConfig = {
    headers: {
      'Location-Id': locationID,
    },
  };
  return CustomAxios.get(`/support/v1/tenant/phonenumbers`, config).then(getResponseData);
};

export const getAvailableFOCDates = (portingDataId: string): Promise<string[]> => {
  return CustomAxios.get(
    `/support/v1/portingdata/portins/${portingDataId}/foc-dates`
  ).then((data) => data?.data?.data?.foc_dates);
};

export const sentFOCDate = (
  portingDataId: string,
  selectedFocDate: string,
  otherReasons: string,
  dropDownValue: string,
  adminEmail: string
) =>
  CustomAxios.patch(`phone/porting/porting-data/v1/portin/${portingDataId}/sup`, {
    foc_date: selectedFocDate,
    sup_reason: dropDownValue,
    comments: otherReasons,
    admin_email: adminEmail,
  }).then<FOCDateResponse>(getResponseData);

export const getE911MissingTenants = async (
  hiddenOffice: boolean,
  multiOffice: boolean
) => {
  const tenants = await CustomAxios.post<{ tenantsMissingE911Config: e911TenantData[] }>(
    `phone/e911/v1/e911-missing-tenants`,
    {
      include_muted_tenants: hiddenOffice,
      exclude_shared: multiOffice,
    }
  ).then((data) => data?.data?.tenantsMissingE911Config);

  // Enhance data with children slugs
  const children = await Promise.all(
    tenants.map((tenant) => getChildrenLocations(tenant.locationId))
  );

  return tenants.map((tenant, index) => ({
    ...tenant,
    childrenSlugs: children[index].map((child) => child.Slug),
  }));
};

export const getChildrenLocations = (locationId: string) =>
  CustomAxios.get<{ data: LocationData[] }>(
    `/support/v1/locations/${locationId}/children`
  ).then((data) => data.data.data);

export const postListOfNote = (
  tenantId: string,
  note: string,
  value: string
): Promise<string> =>
  CustomAxios.post(`phone/e911/v1/add-e911-dashboard-note`, {
    tenant_id: tenantId,
    note: note,
    added_by: value,
  }).then((data) => data?.data?.id);

export const getListOfNotes = (tenantId: string): Promise<e911NotesData[]> =>
  CustomAxios.post(`phone/e911/v1/list-e911-dashboard-notes`, {
    tenant_id: tenantId,
  }).then((data) => data?.data?.dashboardNotes);

export const muteTenant = (
  tenantId: string,
  attempts: number,
  mute: boolean
): Promise<E911TenantDashboard> =>
  CustomAxios.post(`phone/e911/v1/manage-tenant-e911-dashboard`, {
    tenant_id: tenantId,
    attempts: attempts,
    mute: mute,
  }).then((data) => data?.data?.tenantE911Dashboard);

export const getProvisionData = (tenantId: string) =>
  CustomAxios.post(`phone/e911/v1/list-tenant-addresses`, {
    tenant_id: tenantId,
  }).then((data) => data?.data);

export const postE911AddressProvisionData = (
  tenantId: string,
  sharedMultilocation: boolean,
  addressObject: e911AddressToBeSend,
  childLocationId: string
): Promise<e911PhoneNumberDetials> =>
  CustomAxios.post(`/phone/e911/v1/e911-provision`, {
    tenant_id: tenantId,
    is_shared: sharedMultilocation,
    address: addressObject,
    child_location_id: childLocationId,
  }).then((data) => data?.data?.phoneNumberDetail);

export const getChildLocationDetailsForMultiOffice = (
  locationId: string
): Promise<childLocationDetails> => {
  const config: AxiosRequestConfig = {
    headers: {
      'Location-Id': locationId,
    },
  };
  return CustomAxios.get(`/support/v1/location/${locationId}/children`, config).then(
    getResponseData
  );
};

export const postLoaData = (
  portingDataId: string,
  firstName: string,
  lastName: string,
  adminEmail
) =>
  CustomAxios.post(`/agreements/v1/sendloa`, {
    porting_data_id: portingDataId,
    first_name: firstName,
    last_name: lastName,
    admin_email: adminEmail,
  });

export const getLoaState = (portingDataId: string): Promise<loaStatus> =>
  CustomAxios.post(`agreements/v1/loa/status`, {
    porting_data_id: portingDataId,
  }).then((data) => data?.data);

export const getUserData = (email: string): Promise<userData> =>
  CustomAxios.get(`support/v1/users/?search=${email}`).then((res) => res?.data?.data[0]);

export const generateInternalLoa = (
  portingDataId: string,
  adminEmail: string
): Promise<loaURL> =>
  CustomAxios.post(`agreements/v1/loa/portin/internal/generate`, {
    porting_data_id: portingDataId,
    admin_email: adminEmail,
  }).then((data) => data?.data);

export const getPhoneNumbers = ({ locationIds }: ListPhoneNumbersRequest) => {
  return CustomAxios.get(`/phone/number-service/v2/list-numbers`, {
    params: {
      locationIds,
    },
    paramsSerializer: serializeQueryParams,
  });
};

export const portingApi = {
  postPortingData,
  uploadPhoneFile,
  deleteFile,
  portViewBillsUpload,
  portViewFileDelete,
  portViewLoaDownload,
  clonePortingData,
  updatePhoneNumbers,
  getInternalPortingNumberData,
  postLoaData,
  validatePortability,
};
//https://api.weavedev.net/support/v1/users/?search=kanak.tyagi@getweave.com
