import {
  GetHierarchyBySlugResponse,
  AccountProvisionRequest,
  AccountProvisionResponse,
  GetLocationsProvisioningStatusResponse,
  UnifyAccountProvisionRequestInfo,
  ProvisioningStatus,
} from '@weave/schema-gen-ts/dist/schemas/location/v1/provision/location_provision_service.pb';

import {
  LocationGroupChildInfo,
  LocationGroupInfo,
  PartialLocationInfoMap,
} from '../../apis/location-provision/types';

import { CREATE_NEW_GROUP_OPTION, SINGLE_LOCATION_GROUP_NAME } from './constants';

/**
 * Retrieves the list of Provision Location Groups from the response object obtained via a GET API call.
 *
 * @param {object} responseObject - The response object obtained from the GET API call.
 * @returns {Array} groups - An array containing Provision Location Groups based on the provided response object.
 */
export const getLocationGroupsFromResponse = (
  responseObject?: GetHierarchyBySlugResponse
): LocationGroupInfo[] => {
  const accounts = responseObject?.accounts ?? [];
  const groups = accounts.reduce<LocationGroupInfo[]>((acc, { children, parent }) => {
    if (!children?.length) return acc;

    acc.push({
      name: parent ? parent.name : SINGLE_LOCATION_GROUP_NAME,
      locationId: parent?.locationId,
      isSingleLocationGroup: !parent,
      // For child, BE will always send slug value so we were explicitly marking it as required here
      children: children?.map((child) => ({ ...child, slug: child.slug! })) ?? [],
    });
    return acc;
  }, []);
  return groups ?? [];
};

/**
 * Generates a new unique group name by appending a numerical suffix if the provided
 * groupName already exists in the array of groupNames.
 *
 * @param {string} groupName - The initial group name.
 * @param {string[]} groupNames - Array containing existing group names.
 * @returns {string} - A new unique group name.
 */
export const getNewGroupName = (groupName: string, groupNames: string[]): string => {
  const originalName = groupName;
  let suffix = 1;
  let newGroupName = groupName;

  while (groupNames.includes(newGroupName)) {
    newGroupName = `${originalName} (${suffix})`;
    suffix++;
  }

  return newGroupName;
};

export const getLocationStatusMapFromResponse = (
  response: AccountProvisionResponse
): {
  locationInfoMap: PartialLocationInfoMap;
  provisionerAuditIds: string[];
} => {
  const locationInfoMap: PartialLocationInfoMap = new Map();
  const provisionerAuditIds: string[] = [];
  response.status?.forEach(
    ({ slug, provisionerAuditId, preProvisionValidationError }) => {
      if (!slug) return;

      let provisionStatus: ProvisioningStatus | undefined;
      if (preProvisionValidationError) {
        provisionStatus = ProvisioningStatus.PROVISION_NO;
      } else if (provisionerAuditId) {
        provisionerAuditIds.push(provisionerAuditId);
        provisionStatus = ProvisioningStatus.PROVISION_INPROGRESS;
      }

      locationInfoMap.set(slug, {
        provisionerAuditId: provisionerAuditId,
        provisionStatus,
        preProvisionValidationError,
      });
    }
  );
  return { locationInfoMap, provisionerAuditIds };
};

export const getLocationInfoMapByProvisionerIdFromResponse = (
  response: GetLocationsProvisioningStatusResponse
): PartialLocationInfoMap => {
  const locationInfoMap: PartialLocationInfoMap = new Map();
  const resStatuses = response?.locationsProvisioningStatus ?? [];
  const PROCESSED_STATUSES = [
    ProvisioningStatus.PROVISION_YES,
    ProvisioningStatus.PROVISION_ERROR,
  ];

  resStatuses.forEach(
    ({
      provisionStatus,
      provisionerAuditId,
      provisioningError,
      locationId,
      parentLocationId,
    }) => {
      if (!provisionerAuditId) return;

      const isProcessed = PROCESSED_STATUSES.includes(provisionStatus);

      if (isProcessed) {
        locationInfoMap.set(provisionerAuditId, {
          provisionerAuditId: '',
          provisioningError,
          provisionStatus,
          locationId,
          parentLocationId,
        });
      }
    }
  );

  return locationInfoMap;
};

export const prepareProvisionLocationRequest = (
  groups: LocationGroupInfo[],
  isRetrying: boolean
): AccountProvisionRequest => {
  let singleAccountSlugs: string[] = [];
  const getSlugForProvisioning = (children: LocationGroupChildInfo[]): string[] => {
    return children.reduce<string[]>((acc, item) => {
      if (isRetrying && item.provisionStatus === ProvisioningStatus.PROVISION_ERROR)
        acc.push(item.slug);
      else if (
        !isRetrying &&
        item.provisionStatus === ProvisioningStatus.PROVISION_NO &&
        !item.preProvisionValidationError
      )
        acc.push(item.slug);
      return acc;
    }, []);
  };

  const unifyAccountList = groups.reduce<UnifyAccountProvisionRequestInfo[]>(
    (acc, group) => {
      if (!group.children.length) return acc;

      if (group.isSingleLocationGroup) {
        singleAccountSlugs = getSlugForProvisioning(group.children);
        return acc;
      }

      acc.push({
        accountsToProvision: getSlugForProvisioning(group.children),
        parentLocationId: group.locationId,
        parentName: group.name,
      });
      return acc;
    },
    []
  );

  const req: AccountProvisionRequest = {
    ...(!!singleAccountSlugs.length && {
      single: {
        accountsToProvision: singleAccountSlugs,
      },
    }),
    unify: unifyAccountList,
  };

  return req;
};

export const getPageElementStatus = (groups: LocationGroupInfo[]) => {
  let hasNoStatusWithoutValidationError = false;
  let hasValidationError = false;
  let hasErrorStatus = false;
  let hasProvisioningStatus = false;

  groups
    .map((group) => group.children)
    .flat()
    .forEach((location) => {
      if (
        !hasNoStatusWithoutValidationError &&
        location.provisionStatus === ProvisioningStatus.PROVISION_NO &&
        !location.preProvisionValidationError
      ) {
        hasNoStatusWithoutValidationError = true;
      }
      if (!hasValidationError && location.preProvisionValidationError) {
        hasValidationError = true;
      }
      if (
        !hasErrorStatus &&
        location.provisionStatus === ProvisioningStatus.PROVISION_ERROR
      ) {
        hasErrorStatus = true;
      }
      if (
        !hasProvisioningStatus &&
        (location.provisionStatus === ProvisioningStatus.PROVISION_INPROGRESS ||
          location.provisionStatus === ProvisioningStatus.PROVISION_SUBMITTED)
      ) {
        hasProvisioningStatus = true;
      }
    });

  return {
    isAssignLocationButtonDisabled:
      hasProvisioningStatus || !hasNoStatusWithoutValidationError,
    isSaveButtonDisabled: hasProvisioningStatus || !hasNoStatusWithoutValidationError,
    isRetryDisabled: hasProvisioningStatus,
    isShowValidationFailureBadge: hasValidationError,
    isShowErrorBadge: hasErrorStatus,
  };
};

type GetNewGroupListWithLocationAssignment = (data: {
  selectedGroupName: string;
  selectedLocationMap: Map<string, string[]>;
  existingGroups: LocationGroupInfo[];
}) => LocationGroupInfo[];

export const getNewGroupListWithLocationAssignment: GetNewGroupListWithLocationAssignment =
  ({ existingGroups, selectedGroupName, selectedLocationMap }) => {
    const groupNames: string[] = [];
    // remove location from existing group
    const newGroupList = existingGroups.map<LocationGroupInfo>((group) => {
      const slugsToExclude = selectedLocationMap.get(group.name) ?? [];
      groupNames.push(group.name);
      return {
        ...group,
        children: group.children.reduce<LocationGroupChildInfo[]>((acc, child) => {
          if (!slugsToExclude.includes(child.slug)) {
            acc.push({ ...child });
          }
          return acc;
        }, []),
      };
    });

    const selectedSlugList = Array.from(selectedLocationMap.values()).flat();
    const allLocations = existingGroups.flatMap((group) => group.children);
    const selectedLocations = allLocations.filter((location) =>
      selectedSlugList.includes(location.slug)
    );

    const selectedGroup = newGroupList.find((g) => g.name === selectedGroupName);

    if (selectedGroup) {
      selectedGroup.children.push(...selectedLocations);
    } else if (selectedGroupName === CREATE_NEW_GROUP_OPTION) {
      const firstLocationName = selectedLocations[0].name;
      const newGroupName = getNewGroupName(`${firstLocationName} Parent`, groupNames);
      newGroupList.push({
        name: newGroupName,
        locationId: '',
        isSingleLocationGroup: false,
        children: selectedLocations,
      });
    } else if (selectedGroupName === SINGLE_LOCATION_GROUP_NAME) {
      newGroupList.push({
        name: SINGLE_LOCATION_GROUP_NAME,
        locationId: '',
        isSingleLocationGroup: true,
        children: selectedLocations,
      });
    }

    return newGroupList;
  };
