import React from 'react';
import { Dot, Text, WarningBadgeIcon, useHoverLabel } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';

interface Props {
  name: string;
  isProcessing?: boolean;
  validationError?: string;
}

export const LocationName = ({ name, validationError, isProcessing }: Props) => {
  const { triggerProps, labelProps, HoverLabel } = useHoverLabel();
  if (!validationError) {
    return (
      <Text as="span" css={ellipsisText}>
        {name}
        {isProcessing && (
          <Dot
            pulsing
            color="primary"
            css={{ left: theme.spacing(0.5), top: theme.spacing(2) }}
          />
        )}
      </Text>
    );
  }

  return (
    <>
      <span {...triggerProps}>
        <WarningBadgeIcon size={16} css={{ marginRight: theme.spacing(1) }} />
        <HoverLabel {...labelProps}>{validationError}</HoverLabel>
      </span>
      <Text as="span" css={ellipsisText}>
        {name}
      </Text>
    </>
  );
};

const ellipsisText = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
