import { Appearance } from '@stripe/stripe-js';
import { theme } from '@weave/theme-original';

// Customizes Stripe Elements to match our existing TextFields.

export const appearance: Appearance = {
  theme: 'stripe',
  rules: {
    '.Label--floating': {
      fontSize: theme.fontSize(12),
    },
    '.Input': {
      border: `1px solid ${theme.colors.gray300}`,
      color: theme.colors.gray600,
    },
    '.Input:focus': {
      borderColor: 'var(--colorPrimary)',
      boxShadow: '0 0 0 1px var(--colorPrimary)',
    },
    '.Input--invalid': {
      borderColor: 'var(--colorDanger)',
      boxShadow: '0 0 0 1px var(--colorDanger)',
    },
  },
  variables: {
    colorText: theme.colors.gray500,
    colorPrimary: theme.colors.weaveBlue,
    colorDanger: theme.colors.error,
    fontSizeSm: theme.fontSize(16),
  },
};
