import { css } from '@emotion/core';
import {
  CheckboxField,
  Modal,
  ModalControlModalProps,
  useFormField,
  Text,
  Heading,
  Alert,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { ToggleState } from 'components/shared/multi-state-toggle/multi-state-toggle.component';
import { LocationFeatureV2 } from 'models/location-feature.model';
import React from 'react';

export type ModalData = { featureToToggle: LocationFeatureV2; newState: ToggleState };
interface Props extends ModalControlModalProps {
  onAccept: (featureToToggle: LocationFeatureV2, newState: ToggleState) => void;
  closeModal: () => void;
  modalData: ModalData;
}

export const WeaveVerifyEnableCustomizationFlagModal = ({
  onAccept,
  closeModal,
  modalData,
  ...modalProps
}: Props) => {
  const acceptProps = useFormField({
    type: 'checkbox',
    value: false,
  });

  const confirmationHandler = () => {
    onAccept(modalData.featureToToggle, modalData.newState);
    closeModal();
  };

  return (
    <Modal {...modalProps} maxWidth={800}>
      <Modal.Header>ARE YOU SURE ?</Modal.Header>
      <Modal.Body>
        <Text>
          <Alert
            type="warning"
            css={css`
              margin: ${theme.spacing(1, 0)};
            `}
          >
            PLEASE READ ALL OF THE FOLLOWING TERMS AND CONDITIONS RELATED TO THE
            SUBSCRIPTION FOR WEAVE INSURANCE VERIFICATION.
          </Alert>
          <Heading level={2}>You certify that</Heading>
          <ul css={ulCss}>
            <li>
              You are authorized to subscribe the Weave Insurance Verification Agreement
              on behalf of the customer account
            </li>
            <li>
              You certify that the customers have offically assigned/appointed you to
              carry out the Weave Insurance Verification subscription
            </li>
            <li>
              You acknowledge that as a Weave representative, we have recorded and
              conveyed all the necessary details of Weave Insurance verification to our
              customer regarding the subscription of the Weave Insurance Verification
              product.
            </li>
          </ul>
          <Heading level={2}>Next Steps</Heading>
          <ul css={ulCss}>
            <li>
              Acknowledge the above terms by checking the box below and clicking{' '}
              <b>"Confirm"</b> button
            </li>
            <li>
              The <b>Feature/Customization Flag</b> value will be visible to Show state.
              So once you see the Show state, make sure that you click the <b>Save</b>{' '}
              button at the top right corner to update the Feature/Customization state{' '}
              <br />
              <b>
                Note: In order to reflect your action, it's important to save the latest
                state of the Feature/Customization Flag
              </b>
            </li>
            <li>
              <b>Important Note:</b> Once the state of the Feature/Customization Flag is
              updated, you must make sure that a upsell opportunity is created in
              Salesforce to subscribe the service for the customer. Failure to do so will
              result in a customer using the service for free since no upsell opportunity
              is created in the Salesforce
            </li>
          </ul>
        </Text>
      </Modal.Body>

      <CheckboxField
        css={css`
          padding: ${theme.spacing(2, 4, 0)};
          box-shadow: 0px -2px 4px rgb(0, 0, 0, 0.2);
        `}
        name="accept"
        {...acceptProps}
        label={
          <span>
            By checking this box and clicking "Confirm", you acknowledge that the Weave
            Insurance Verification product for the purpose of verifying patient insurance
            eligibility will be available for the particular location
          </span>
        }
      />
      <Modal.Actions
        disablePrimary={!acceptProps.value}
        onPrimaryClick={confirmationHandler}
        primaryLabel="Confirm"
        primaryTrackingId="confirm-wiv-terms-and-conditions"
      />
    </Modal>
  );
};

const ulCss = css`
  margin: ${theme.spacing(1, 0)};
  > li {
    padding: ${theme.spacing(0.5, 0)};
  }
`;
