import { connect } from 'react-redux';

import { LocationFeatureV2 } from '../../models/location-feature.model';
import { selectHasWeaveAcl } from '../../redux/actions/auth/auth.selectors';
import {
  getLocationFeatures,
  getWamSyncStatus,
  setFeatures,
  setLocationFeatures,
  updateFeature,
} from '../../redux/actions/customization/customization.action';
import {
  selectCurrentLocation,
  selectCurrentLocationVerticalID,
} from '../../redux/actions/location';
import { CoreACLs } from '../../redux/actions/auth/auth.types';
import { showError } from '@weave/alert-system';
import { CustomizationPageV2 } from './customization-page-v2/customization-page-v2';

export const CustomizationPageContainer = connect(
  (state: any) => ({
    canEdit: selectHasWeaveAcl(state, CoreACLs.CUSTOMIZATION_FLAGS_WRITE),
    loading: state.customization.loading || state.customization.wamSyncStatusLoading,
    location: selectCurrentLocation(state),
    features: state.customization.features,
    initialFeatures: state.customization.initialFeatures,
    verticalId: selectCurrentLocationVerticalID(state),
    wamSyncStatus: state.customization.wamSyncStatus,
  }),
  (dispatch) => ({
    getLocationFeatures: (locationId: string) =>
      dispatch(getLocationFeatures(locationId)),
    getWamSyncStatus: (locationSlug: string) => dispatch(getWamSyncStatus(locationSlug)),
    setLocationFeatures: (
      locationId: string,
      features: LocationFeatureV2[],
      updatedFeatures: LocationFeatureV2[],
      locationSlug?: string
    ) =>
      dispatch(
        setLocationFeatures({
          locationId,
          features,
          updatedFeatures,
          locationSlug,
        })
      ),
    updateFeature: (feature, newState) => dispatch(updateFeature({ feature, newState })),
    setFeatures: (features) => dispatch(setFeatures(features)),
    showError: (message: string) => dispatch(showError(message)),
  })
)(CustomizationPageV2);
