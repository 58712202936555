import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { theme, WeaveTheme } from '@weave/theme-original';
import {
  Modal,
  PrimaryButton,
  useFormField,
  FormRow,
  Text,
  TextField,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { LocationSearchContainer } from '../location-search-container/location-search.container';
import { LocationSearchModel } from '../../../models/location-search.model';
import {
  requestSetLocationParent,
  requestSetPhoneTenantID,
} from '../../../redux/actions/location/locations';
import { LocationStatusTypes } from '../../location-sidebar-container/location-sidebar.types';
import { WarningAlert } from '../../location-sidebar-container/location-status/location-status';

interface Props {
  locationID: string;
  parentLocationID: string;
  phoneTenantID: string;
  hasChildren: boolean;
  onClose: () => void;
  getLocationStatus: (
    locationId: string,
    phoneTenantId: string,
    parentId: string
  ) => LocationStatusTypes | '';
}

const uuidRegExp =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;

const isValidUUID = (uuidString: string): boolean => {
  return uuidRegExp.test(uuidString);
};

export const LocationParentageModal = ({
  locationID,
  parentLocationID,
  phoneTenantID,
  hasChildren,
  onClose,
  getLocationStatus,
}: Props) => {
  const locationStatus = getLocationStatus(locationID, phoneTenantID, parentLocationID);
  const dispatch = useDispatch();
  const [newParent, setNewParent] = useState<LocationSearchModel>();
  const newPhoneTenantID = useFormField(
    {
      type: 'text',
      validateOnChange: true,
      value: phoneTenantID,
      validator: ({ value }) => {
        if (!isValidUUID(value)) {
          return 'Phone tenant ID must be valid';
        }
        return '';
      },
    },
    [phoneTenantID]
  );

  const parentLocationIdField = useFormField({ type: 'text', value: parentLocationID });
  const isUnifyParent = locationStatus === 'Unify Parent';
  const isDataBetaParent = locationStatus === 'Data Beta Parent';

  const isPhoneTenantIdUpdated = newPhoneTenantID.value.trim() !== phoneTenantID.trim();

  const isParentIdUpdated =
    !!newParent?.LocationID.trim() &&
    parentLocationID.trim() !== newParent?.LocationID.trim();

  const shouldClearParentAndPhoneTenant =
    parentLocationID && newPhoneTenantID.value.trim() === phoneTenantID.trim();

  const shouldOnlyClearPhoneTenantForUnifyParent =
    isUnifyParent && !isPhoneTenantIdUpdated && !(newParent || parentLocationID);

  const handleSubmit = () => {
    const shouldClearOnlyPhoneTenant = !isPhoneTenantIdUpdated && isUnifyParent;

    if (isParentIdUpdated) {
      dispatch(requestSetLocationParent(locationID, newParent?.LocationID ?? ''));
    }
    if (isPhoneTenantIdUpdated) {
      dispatch(requestSetPhoneTenantID(newPhoneTenantID.value ?? ''));
    }
    if (shouldClearParentAndPhoneTenant) {
      dispatch(requestSetLocationParent(locationID, ''));
      dispatch(requestSetPhoneTenantID(''));
    }
    if (shouldClearOnlyPhoneTenant) {
      dispatch(requestSetPhoneTenantID(''));
    }
    onClose();
  };

  const getPrimaryLabel = () => {
    const isSetParentAndTenant =
      !parentLocationID && newPhoneTenantID.value && !isUnifyParent;

    const isClearParentAndTenant = !!(
      parentLocationID && newPhoneTenantID.value.trim() === phoneTenantID.trim()
    );

    if (isSetParentAndTenant) return 'Set Parent & Tenant';
    if (shouldOnlyClearPhoneTenantForUnifyParent) return 'Clear Tenant';
    if (isClearParentAndTenant) return 'Clear Parent & Tenant';
    if (!shouldOnlyClearPhoneTenantForUnifyParent) return 'Update Tenant';
    if (!parentLocationID) return 'Set Parent';
    if (isPhoneTenantIdUpdated) return 'Update Tenant';

    return 'Clear Parent';
  };

  const shouldShowDestructiveButtonStyles =
    shouldClearParentAndPhoneTenant || shouldOnlyClearPhoneTenantForUnifyParent;

  const shouldDisableAction = !!newPhoneTenantID.error;

  const updatedLocationStatus = getLocationStatus(
    locationID,
    newPhoneTenantID.value,
    newParent?.LocationID ?? parentLocationIdField.value ?? ''
  );
  const isLocationStateUpdated =
    !!updatedLocationStatus && updatedLocationStatus !== locationStatus;

  return (
    <>
      <Modal.Header>Location Parentage</Modal.Header>
      <Text textAlign="center">(DO NOT USE UNLESS ABSOLUTELY SURE)</Text>
      <Modal.Body
        css={(theme: WeaveTheme) => css`
          padding-top: ${theme.spacing(1)};
          padding-left: ${theme.spacing(1)};
          min-height: 150px;
        `}
      >
        <Text>
          Current Status:{' '}
          <b css={getCurrentLocationStateTextStyle(isLocationStateUpdated)}>
            {locationStatus}
          </b>
        </Text>
        {isLocationStateUpdated && (
          <Text weight="bold">Updated State: {updatedLocationStatus}</Text>
        )}
        {!parentLocationID && !isUnifyParent && !isDataBetaParent ? (
          <div>
            <label htmlFor="ParentID">Set Parent Location:</label>
            <div>
              {!newParent ? (
                <LocationSearchContainer
                  css={css`
                    width: 100%;
                    margin-left: 1px;
                  `}
                  onSelect={(selection) => {
                    setNewParent(selection);
                  }}
                />
              ) : (
                <div>
                  Selected:{' '}
                  <strong>
                    {newParent.Name} - {newParent.LocationID}
                  </strong>
                  <div>
                    <PrimaryButton size="tiny" onClick={() => setNewParent(undefined)}>
                      Clear Selected
                    </PrimaryButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            {isUnifyParent && !hasChildren && (
              <WarningAlert title="No children assigned" />
            )}
          </>
        )}
        {!phoneTenantID ? (
          <div
            css={(theme: WeaveTheme) => css`
              margin-top: ${theme.spacing(2)};
            `}
          >
            <Text as="label">Set Phone Tenant ID:</Text>
            <FormRow>
              <TextField
                {...newPhoneTenantID}
                clearable
                label="Phone Tenant ID"
                placeholder=""
                name="tenantID"
              />
            </FormRow>
          </div>
        ) : (
          <div
            css={(theme: WeaveTheme) => css`
              margin-top: ${theme.spacing(2)};
            `}
          >
            {parentLocationID && (
              <FormRow>
                <TextField
                  {...parentLocationIdField}
                  label="Parent Location ID"
                  placeholder=""
                  name="parent-location-id"
                  disabled
                />
              </FormRow>
            )}
            <FormRow>
              <TextField
                {...newPhoneTenantID}
                label="Phone Tenant ID"
                placeholder=""
                name="tenantID"
              />
            </FormRow>
          </div>
        )}
      </Modal.Body>
      <Modal.Actions
        primaryLabel={getPrimaryLabel()}
        disablePrimary={shouldDisableAction}
        onPrimaryClick={handleSubmit}
        onSecondaryClick={onClose}
        destructive={shouldShowDestructiveButtonStyles}
      />
    </>
  );
};

const getCurrentLocationStateTextStyle = (isChanged: boolean) =>
  css`
    ${isChanged && `color: ${theme.colors.gray500}`};
  `;
