import { css } from '@emotion/core';
import { Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import axios from 'axios';
import { UserLookupCard } from './user-lookup-card';

type UserLookupErrorsProps = {
  error: unknown;
};

export const UserLookupErrors = ({ error, ...rest }: UserLookupErrorsProps) => {
  const message = String(error);
  let details = '';

  if (axios.isAxiosError(error)) {
    const data = error.response?.data?.error ?? error.response?.data;
    details = typeof data === 'object' ? JSON.stringify(data) : data;
  }

  return (
    <UserLookupCard
      {...rest}
      css={css`
        position: relative;
        padding-left: ${theme.spacing(4)};
        :after {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          background: ${theme.colors.error};
          width: ${theme.spacing(2)};
          height: 100%;
        }
      `}
    >
      <Text weight="bold">Error</Text>
      <Text>{message}</Text>
      {details && <Text>{details}</Text>}
    </UserLookupCard>
  );
};
