import React from 'react';
import { css } from '@emotion/core';
import { MarkIcon, Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { HandoffMetric } from '../../../handoff-hub.types';
import { Result } from '../edit-handoff/handoff-metrics/handoff-metrics.types';

type Props = {
  subsectionMetrics: {
    [subsectionName: string]: HandoffMetric[];
  };
  sectionName: string;
};

export const SnapshotMetricsCardComponent = ({
  sectionName,
  subsectionMetrics,
}: Props) => {
  return (
    <div css={sectionContainer}>
      <Text size="large" weight="bold">
        {sectionName}
      </Text>
      <div css={listContainerStyle}>
        {Object.entries(subsectionMetrics).map(([subsectionName, metrics]) => {
          const allMetricsPassed = metrics.every(
            (metric) => metric.result === Result.Pass
          );
          return (
            <div key={subsectionName} css={testCriteriaContainer}>
              <div css={subsectionTitle}>
                <MarkIcon color={allMetricsPassed ? 'success' : 'disabled'} />
                <Text size="medium" weight="semibold" css={{ margin: 0 }}>
                  {subsectionName}
                </Text>
              </div>
              <div css={[listContainerStyle, { gap: theme.spacing(0) }]}>
                {metrics.map((metric) => (
                  <div
                    css={[
                      metricsTestCriteriaStyles,
                      metric.result !== Result.Pass ? failedMetricSTyle : '',
                    ]}
                  >
                    <Text size="small">{metric.testCriteria}</Text>
                    <Text size="small">Actual: {metric.actualValue}</Text>
                    <Text size="small">{metric.exception}</Text>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const listContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
});

const subsectionTitle = css({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
});

const sectionContainer = css({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.shadows.light,
  borderRadius: theme.borderRadius.medium,
  padding: theme.spacing(2),
  border: `1px solid ${theme.colors.gray200}`,
});

const testCriteriaContainer = css`
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: ${theme.colors.gray200};
  }
`;

const metricsTestCriteriaStyles = css`
  display: flex;
  padding-left: ${theme.spacing(5)};
  gap: ${theme.spacing(2)};
  &:hover {
    background-color: ${theme.colors.gray300};
  }
  > p {
    padding: ${theme.spacing(0.5)};
    flex: 1 0 0;
    margin: 0;
  }
`;

const failedMetricSTyle = css`
  p {
    color: ${theme.colors.error};
  }
`;
