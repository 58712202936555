import {
  ClockIcon,
  ConfirmBadgeIcon,
  ErrorBadgeIcon,
  IconProps,
  LockIcon,
} from '@weave/design-system';
import { TCRBrandFriendlyRegistrationStatus } from 'apis/tendlc/types';

type BrandRegistrationStatusIconProps = {
  size: IconProps['size'];
  friendlyRegistrationStatus: TCRBrandFriendlyRegistrationStatus;
};

export const BrandRegistrationStatusIcon = ({
  size,
  friendlyRegistrationStatus,
}: BrandRegistrationStatusIconProps) => {
  switch (friendlyRegistrationStatus) {
    case TCRBrandFriendlyRegistrationStatus.UNREGISTERED:
      return <ErrorBadgeIcon color="error" size={size} />;
    case TCRBrandFriendlyRegistrationStatus.CANADA:
      return <ConfirmBadgeIcon color="success" size={size} />;
    case TCRBrandFriendlyRegistrationStatus.PENDING:
      return <ClockIcon color="warn" size={size} />;
    case TCRBrandFriendlyRegistrationStatus.SOLE_PROPRIETOR:
      return <ConfirmBadgeIcon color="success" size={size} />;
    case TCRBrandFriendlyRegistrationStatus.UNVERIFIED:
      return <ErrorBadgeIcon color="error" size={size} />;
    case TCRBrandFriendlyRegistrationStatus.VERIFIED:
      return <ConfirmBadgeIcon color="success" size={size} />;
    case TCRBrandFriendlyRegistrationStatus.LOCKED:
      return <LockIcon color="warn" size={size} />;
    default:
      return null;
  }
};
