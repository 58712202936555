import { useState } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { IconButton, Text, WarningBadgeIcon, XIcon } from '@weave/design-system';

interface Props {
  show?: boolean;
}

export const ValidationErrorBanner = ({ show = false }: Props) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible || !show) {
    return null;
  }

  return (
    <div css={containerStyle}>
      <WarningBadgeIcon color="warn" />
      <div css={{ flexGrow: 1 }}>
        <Text weight="bold" css={{ margin: 0 }}>
          Salesforce Validation Failure
        </Text>
        <Text size="medium" css={{ margin: 0 }}>
          Some locations below failed the Salesforce validation. Please go to salesforce
          to resolve this issue to be provision these accounts
        </Text>
      </div>
      <IconButton label="Close warning" onClick={() => setIsVisible(false)}>
        <XIcon size={16} />
      </IconButton>
    </div>
  );
};

const containerStyle = css({
  padding: theme.spacing(2),
  backgroundColor: theme.colors.warning5,
  borderRadius: theme.borderRadius.small,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
});
