import { useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { standardizeTemplateTextTags } from '../../../template-tag-utils';
import { getHTMLRendering } from './email-utils';

export const EmailPreview = ({ template, settings }) => {
  const documentExist = !!settings.template.document_id;
  const emailTemplate = useMemo(() => {
    return getHTMLRendering(standardizeTemplateTextTags(template)); // will convert to snake-case
  }, [template]);

  if (documentExist && !document.querySelector('.formLinkPreview')) {
    handleHTMLInsertion(emailTemplate);
  }

  return ReactHtmlParser(emailTemplate.innerHTML);
};

export const handleHTMLInsertion = (emailTemplate) => {
  const formLink = createFormLinkHTML();
  const emailFooter = emailTemplate?.querySelector('.emailFooter');
  const parentContainer = emailFooter?.parentElement;
  if (parentContainer && emailFooter) {
    parentContainer.insertBefore(formLink, emailFooter);
  }
};

export const createFormLinkHTML = () => {
  const p = document.createElement('p');
  p.innerHTML = `Please fill out this form:<br/><span style="color: blue; text-decoration: underline; cursor: pointer;">http://getw3v.com/123456</span><br/><br/>`;
  p.classList.add('formLinkPreview');
  return p;
};
