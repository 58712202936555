import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from '@weave/alert-system';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

import { IntakeForm, OnboardingTask, OperationStatus } from '../onboarding.types';
import { selectLocationChildren } from '../../../redux/actions/location/location-search';
import { onboardingIntakeFormApi, onboardingTasksApi } from '../onboarding.api';
import { Alert } from 'reactstrap';
import { Heading, PrimaryButton, SpinningLoader } from '@weave/design-system';
import { IntakeFormStatus } from './intake-form-status/intake-form-status';
import { selectCurrentLocationId } from '../../../redux/actions/location';
import { Page } from '../../shared';
import { TasksInfoTable } from './tasks-info-table';
import { useQuery } from 'react-query';
import { useDebugMode } from '../../debug/use-debug-mode';

export const OnboardingComponent = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const alerts = useAlert();
  const [intakeFormData, setIntakeFormData] = useState<IntakeForm>();
  const [operationsStatuses, setOperationsStatuses] = useState<OperationStatus[]>([]);
  const [fetchIntakeFormLoading, setFetchIntakeFormLoading] = useState(false);
  const isParentLocation = !!useSelector(selectLocationChildren).length;

  const { isDebugModeEnabled } = useDebugMode();

  const fetchIntakeFormData = async () => {
    setIntakeFormData(undefined);
    setOperationsStatuses([]);
    setFetchIntakeFormLoading(true);

    try {
      const data = await onboardingIntakeFormApi.getOnboardingIntakeForm();
      setIntakeFormData(data);

      const operationsResponse = await onboardingIntakeFormApi.getOperationsStatus();
      setOperationsStatuses(operationsResponse?.operations ?? []);
    } catch (error) {
      console.error(error);
      alerts.error('Error retrieving reimagine form data');
    } finally {
      setFetchIntakeFormLoading(false);
    }
  };

  const {
    data: modulesTasks,
    isLoading: modulesTasksIsLoading,
    refetch: refetchModulesTasks,
    isError: modulesTasksIsError,
    isSuccess: modulesTasksIsSuccess,
  } = useQuery(['onboarding-tasks', locationId], onboardingTasksApi.getModulesTasks, {
    enabled: !!locationId && isDebugModeEnabled,
  });

  useEffect(() => {
    if (locationId && !isParentLocation) {
      fetchOnboardingData();
    }
  }, [locationId]);

  useEffect(() => {
    if (modulesTasksIsError) {
      alerts.error('Error retrieving onboarding tasks');
    }
  }, [modulesTasksIsError]);

  const fetchOnboardingData = () => {
    fetchIntakeFormData();

    // Only fetch onboarding task data if debug mode is enabled. The reason for this is to
    // prevent calls to the /tasks endpoint for a location if users are just browsing the
    // onboarding page. Since calling that endpoint will add an entry to our DB table the
    // first time we call it for a location, we don't want to do that unless we need to in
    // order to not mess up the data for reporting purposes.
    if (isDebugModeEnabled) {
      refetchModulesTasks();
    }
  };

  const allUniqueTasks = useMemo(() => {
    const uniqueTasksMap: { [key: string]: OnboardingTask } = {};

    const allTasks = modulesTasks?.modules.map((module) => module.tasks).flat() ?? [];
    const uniqueTasks = Object.values(
      allTasks.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, uniqueTasksMap)
    );

    return uniqueTasks;
  }, [modulesTasks]);

  if (isParentLocation) {
    return (
      <Page title={`Onboarding Hub`}>
        <Alert color="warning">
          Onboarding information is only available for child locations. Switch to a child
          location to see onboarding data.
        </Alert>
      </Page>
    );
  }

  return (
    <Page
      title={`Onboarding Hub`}
      headerActions={
        <div>
          <PrimaryButton
            disabled={fetchIntakeFormLoading || modulesTasksIsLoading}
            color="blue"
            onClick={fetchOnboardingData}
          >
            Refresh
          </PrimaryButton>
        </div>
      }
    >
      <IntakeFormStatus
        intakeFormData={intakeFormData}
        fetchIntakeFormData={fetchIntakeFormData}
        fetchIntakeFormLoading={fetchIntakeFormLoading}
        operationsStatuses={operationsStatuses}
      />

      {isDebugModeEnabled && (
        <>
          <Heading>Onboarding Tasks</Heading>
          {modulesTasksIsLoading ? (
            <SpinningLoader />
          ) : (
            <TasksInfoTable taskData={allUniqueTasks} />
          )}
        </>
      )}

      <hr />

      <div css={onBoardingHubContainer}>
        {fetchIntakeFormLoading && <SpinningLoader />}
      </div>
    </Page>
  );
};

const onBoardingHubContainer = css`
  margin-top: ${theme.spacing(3)};
  text-align: center;
`;
