import React from 'react';
import { AlertInvertIcon, Text } from '@weave/design-system';
import {
  locationStatusStyles,
  noChildrenMessageContainerStyles,
} from '../location-sidebar.styles';
import { LocationStatusTypes } from '../location-sidebar.types';

type Props = {
  locationStatus: LocationStatusTypes | '';
  hasChildren?: boolean;
};

export const WarningAlert = ({ title = '' }) => (
  <>
    {title && (
      <div css={noChildrenMessageContainerStyles}>
        <AlertInvertIcon color="warn" />
        <Text>{title}</Text>
      </div>
    )}
  </>
);

export const LocationStatus = ({ locationStatus, hasChildren = false }: Props) => (
  <>
    {locationStatus && (
      <Text size="large" weight="bold" css={locationStatusStyles}>
        {locationStatus}
      </Text>
    )}
    {!hasChildren && locationStatus === 'Unify Parent' && (
      <WarningAlert title="No children assigned" />
    )}
  </>
);
