import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CustomizationPageContainer } from '../../components/customization-page-container/customization-page.container';
import { HomeContainer } from '../../components/home-container/home.container';
import { DesktopClientsContainer } from '../../components/desktop-clients/desktop-clients.container';
import { PrivateRoute } from '../private-route/private-route.component';
import { LocationDashboardContainer } from '../../components/location/location-dashboard-container/location-dashboard.container';
import { LocationCreateContainer } from '../../components/location/location-create-container/location-create.container';
import { MediaViewer } from '../../components/media-viewer';
import { UsersListContainer } from '../../components/users/users-list.container';
import { UserMobileSupport } from '../../components/users/user-mobile-support.component';
import { OnboardingContainer } from '../../components/onboarding-container/onboarding.container';
import { UserConfig } from '../../components/user-config/user-config.component';
import { e911Dashboard } from '../../components/e911-dashboard/e911-dashboard.component';
import { LocationCreateWizard } from '../../components/location/location-create-wizard/location-create-wizard.component';
import { LocationCreateDemoContainer } from '../../components/location/location-create-demo/location-create-demo.container';
import {
  SyncApps,
  SyncAppSettingsPage,
  MultiSyncAppSettings,
} from '../../components/sync-app';
import { PhonePages } from '../../components/phones/phone-pages.component';
import { AnalyticsPage } from '../../components/analytics/analytics-page.component';
import { SettingsPage } from '../../components/settings/settings-page.component';
import { HistoryPage } from '../../components/history-list-container/history-page.component';
import { AutoMessageDebugger } from '../../components/settings/auto-message-debugger/auto-message.debugger';
import { DesktopReleases } from '../../components/desktop-releases/desktop-releases';
import { AuditLogs } from '../../components/audit-logs';
import { selectCurrentLocationParentId } from '../../redux/actions/location';
import { selectWeaveUserAcls } from '../../redux/actions/auth/auth.selectors';
import { CrawlSwitch } from '../crawl-switch';
import { CoreACLs } from '../../redux/actions/auth/auth.types';
import * as Paths from '../../constants/paths';
import { UserLookUpPage } from 'components/user-look-up/user-lookup-page';
import { OnboardingAutomationContainer } from 'components/onboarding-automation-container';
import { FeatureFlagManagerPage } from 'components/feature-flags-manager/feature-flag-manager-page.component';
import { OnboardingHandoffRouter } from '../../components/onboarding/handoff-hub/onboarding-handoff-router';
import { CsmDashboardComponent } from '../../components/csm-dashboard/csm-dashboard.component';
import { PortabilityCheck } from '../../components/portabiliy-check/portability-check';
import { ProvisionLocationsPage } from '../../components/provision-locations/provision-locations.component';

interface Props {
  isAuthenticated: boolean;
}

export const Routes = ({ isAuthenticated }: Props) => {
  const hasParentId = useSelector(selectCurrentLocationParentId);
  const userAcls = useSelector(selectWeaveUserAcls) || [];
  const syncAppInstallAdvancedACL =
    userAcls.indexOf(CoreACLs.SYNCAPPINSTALLADVANCED) >= 0;

  return (
    <CrawlSwitch>
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact={true}
        path={'/'}
        component={HomeContainer}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.userConfig}
        component={UserConfig}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.portabilityCheck}
        component={PortabilityCheck}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.e911Dashboard}
        component={e911Dashboard}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.DesktopReleases}
        component={DesktopReleases}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact={true}
        path={Paths.LOCATION.newcustomer}
        component={LocationCreateContainer}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact={true}
        path={Paths.LOCATION.dev}
        component={LocationCreateContainer}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.LOCATION.demo}
        component={LocationCreateDemoContainer}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.LOCATION.create}
        component={LocationCreateWizard}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.dashboard}
        component={LocationDashboardContainer}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.PHONES.root}
        component={PhonePages}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.users}
        component={UsersListContainer}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.PHONES.userMobileSupport}
        component={UserMobileSupport}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.HISTORY.root}
        component={HistoryPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.SETTINGS.root}
        component={SettingsPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.media}
        component={MediaViewer}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.ANALYTICS.root}
        component={AnalyticsPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.customization}
        component={CustomizationPageContainer}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.desktopClients}
        component={DesktopClientsContainer}
      />
      {/*<PrivateRoute*/}
      {/*  isAuthenticated={isAuthenticated}*/}
      {/*  path={Paths.OTHER.onboarding}*/}
      {/*  component={OnboardingContainer}*/}
      {/*/>*/}
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.onboardingAutomation}
        component={OnboardingAutomationContainer}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated && !hasParentId}
        path={Paths.SYNC_APP.syncApps}
        component={SyncApps}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated && !hasParentId}
        path={Paths.SYNC_APP.multiSyncAppSettings}
        component={MultiSyncAppSettings}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated && !hasParentId && syncAppInstallAdvancedACL}
        path={Paths.SYNC_APP.syncAppSettings}
        component={SyncAppSettingsPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.autoMessageDubugger}
        component={AutoMessageDebugger}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.FEATURE_FLAGS}
        component={FeatureFlagManagerPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.auditLogs}
        component={AuditLogs}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.OTHER.auditLogsGlobal}
        component={AuditLogs}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.ONBOARDING.root}
        component={OnboardingHandoffRouter}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.USER_LOOKUP}
        component={UserLookUpPage}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.CSM_DASHBOARD}
        component={CsmDashboardComponent}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        path={Paths.PROVISION_LOCATIONS}
        component={ProvisionLocationsPage}
      />
      <Redirect to={'/'} />
    </CrawlSwitch>
  );
};
