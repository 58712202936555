import React, { useEffect, useState } from 'react';

import { WeaveTheme } from '@weave/theme-original';
import { ExternalLinkIcon, NakedButton, Text } from '@weave/design-system';
import { Page } from '../../shared/page/page.component';
import { css } from '@emotion/core';
import { useResource } from '../../shared/hooks/use-resource.hook';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { REACT_APP_API_URL } from 'config/app';

const sourceButton = (theme: WeaveTheme, isSelected) => css`
  text-align: left;
  ${isSelected
    ? `background-color: ${theme.colors.weaveBlue};`
    : `background-color: ${theme.colors.white};`}
  outline: none;
  border-radius: 4px;
  border: 2px solid ${theme.colors.weaveBlue};
  margin: 8px;
  padding: 8px;
  p {
    ${isSelected ? `color: ${theme.colors.white};` : `color: ${theme.colors.weaveBlue};`}
    margin: 0;
    padding: 0;
  }
`;

type SyncAppType = {
  SourceID: string;
  PracticeManagementSystem: string;
  SourceName: any;
};

type SyncAppsDataType = {
  SyncApps: SyncAppType[];
};

export const StatusMappingPage = () => {
  const locationID = useSelector(selectCurrentLocationId);
  const [sourceIDs, setSourceIDs] = useState<SyncAppType[]>([]);
  const [sourceID, setSourceID] = useState('');
  const { data } = useResource<SyncAppsDataType>({ url: `support/v1/syncapp/health` });

  useEffect(() => {
    if (data?.SyncApps?.length) {
      const findLocationIndex = data.SyncApps.findIndex(
        (item) => item.SourceID === locationID
      );
      const locationIndex = findLocationIndex === -1 ? 0 : findLocationIndex;
      setSourceID(data.SyncApps[locationIndex].SourceID);
      const sources = data.SyncApps.sort((a, b) =>
        ('' + a.SourceName).localeCompare(b.SourceName)
      );
      setSourceIDs(sources);
    }
  }, [data?.SyncApps]);

  const selectedSourceName = sourceIDs.find(
    (item) => item.SourceID === sourceID
  )?.SourceName;
  const redirectLink =
    `https://app.${
      REACT_APP_API_URL.includes('dev') ? 'weavedev.net' : 'getweave.com'
    }/${locationID}/data-sync` +
    (sourceID ? `/${sourceID}?name=${selectedSourceName ?? ''}&flag=false` : '');

  return (
    <Page title="Status Mapping">
      <div
        css={css`
          display: flex;
        `}
      >
        {sourceIDs.map((item) => {
          const isSelected = sourceID === item.SourceID;
          return (
            <NakedButton
              css={(theme) => sourceButton(theme, isSelected)}
              onClick={() => setSourceID(item.SourceID)}
              key={item.SourceID}
            >
              <Text>{item?.SourceName}</Text>
              <Text>{item?.PracticeManagementSystem}</Text>
            </NakedButton>
          );
        })}
      </div>
      <div
        css={(theme) => css`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: ${theme.spacing(2)};
        `}
      >
        <Text
          weight="bold"
          css={css`
            margin-top: 40px;
          `}
          size="large"
        >
          Status Mappings has been migrated to the Weave Portal.
        </Text>
        <a
          css={(theme) => css`
            color: ${theme.colors.weaveBlue};
            font-weight: bold;
            margin-top: 16px;

            svg {
              margin-left: 8px;
            }
          `}
          href={redirectLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          Open Data Mapping in Weave Portal
          <ExternalLinkIcon />
        </a>
      </div>
    </Page>
  );
};
