import { Action, createAction, handleActions } from 'redux-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { CustomAxios } from '../../axios';
import { showError, showSuccess } from '@weave/alert-system';
import {
  LocationFeatureV2,
  CustomizationFlagStatesV2,
} from '../../../models/location-feature.model';
import { createSelector } from 'reselect';
import { Store } from '../../store/store.model';
import { LegacySettings } from '../../../components/settings/reviews/helpers';
import { CustomizationFlagsLocationFeaturesResponse } from './customization.types';
import { AxiosResponse } from 'axios';
import { WamSyncStatusResponse } from '../../../apis/wam-sync/wam-sync.types';
import { WamSyncSchemaAPI } from '../../../apis/wam-sync/wam-sync.api';

export interface LocationFeaturesPayload {
  locationId: string;
  locationSlug?: string;
  features: LocationFeatureV2[];
  updatedFeatures: LocationFeatureV2[];
}

export const getCustomizationsFlagsByLocationIdURL = (locationId: string) =>
  `platform-location-feature/v1/location/${locationId}/features`;

export const getLocationFeatures = createAction<string>('GET_LOCATION_FEATURES');
export const setLocationFeatures = createAction<LocationFeaturesPayload>(
  'SET_LOCATION_FEATURES'
);
export const locationFeaturesSuccess = createAction<LocationFeatureV2[]>(
  'LOCATION_FEATURES_SUCCESS'
);
export const locationFeaturesFailure = createAction('LOCATION_FEATURES_FAILURE');
export const updateFeature = createAction<any>('UPDATE_FEATURE');
export const setFeatures = createAction<LocationFeatureV2[]>('SET_FEATURES');
export const setInitialLocationFeature = createAction('SET_INITIAL_LOCATION_FEATURE');
export const getWamSyncStatus = createAction<string>('GET_WAM_SYNC_STATUS');
export const setWamSyncStatus =
  createAction<WamSyncStatusResponse>('SET_WAM_SYNC_STATUS');
export const wamSyncStatusFailure = createAction('SET_WAM_SYNC_STATUS_FAILURE');

export const handleGetLocationFeatures = function* (action) {
  try {
    const locationId = action.payload;
    if (!locationId) {
      yield put(locationFeaturesFailure());
      return;
    }

    const customizationFlagsLocationFeatureURL =
      getCustomizationsFlagsByLocationIdURL(locationId);
    const response: AxiosResponse<CustomizationFlagsLocationFeaturesResponse> =
      yield call(CustomAxios.get, customizationFlagsLocationFeatureURL);
    const features = response?.data?.features ?? [];

    const filteredFeatures = features.filter(
      // We currently want to filter out any of the "Bulk Messaging *" features that include
      // the allotment number as part of the name (ex: "Bulk Messaging 1000") since those
      // are not currently supported and are causing confusion for the onboarders.
      (feature) => !feature.name.includes('Bulk Messaging ')
    );
    yield put(locationFeaturesSuccess(filteredFeatures));
    yield put(setInitialLocationFeature(filteredFeatures));
  } catch {
    yield put(locationFeaturesFailure());
    yield put(showError('Failed to get feature list'));
  }
};

export const handleSetLocationFeatures = function* (
  action: Action<LocationFeaturesPayload>
) {
  try {
    const { locationId, locationSlug, features, updatedFeatures } = action.payload;

    const formattedUpdatedFeatures = updatedFeatures.map((feature) => {
      return {
        featureEnum: feature.featureEnum,
        id: feature.id,
        state: feature.state,
        isFreeTrial: feature.isFreeTrial ?? false,
        quota: feature.quota,
      };
    });

    const url = getCustomizationsFlagsByLocationIdURL(locationId);

    yield call(CustomAxios.post, url, {
      features: formattedUpdatedFeatures,
    });
    yield put(locationFeaturesSuccess(features));
    yield put(setInitialLocationFeature(features));
    yield call(setReviewsActivation, features);
    if (locationSlug) {
      yield put(getWamSyncStatus(locationSlug));
    }
    yield put(showSuccess('Success! Changes successfully saved.'));
  } catch {
    yield put(locationFeaturesFailure());
    yield put(showError('Failed to save feature list'));
  }
};

const setReviewsActivation = async (features: LocationFeatureV2[]) => {
  const reviewsFeatureId = 'ff764a4b-31a0-482d-b0d2-72b4dad92385';
  const reviewFeature = features.find((feature) => feature.id === reviewsFeatureId);
  if (reviewFeature === undefined) {
    return;
  }
  reviewFeature.state === CustomizationFlagStatesV2.ACTIVE
    ? activateReviews()
    : deactivateReviews();
};

const activateReviews = async () => {
  const reviewSettings = await CustomAxios.get<{ data: LegacySettings }>(
    'support/v1/reviews/settings'
  );
  const accountStatus = reviewSettings.data.data.Status;
  if (accountStatus === '') {
    await CustomAxios.post('/support/v1/reviews/signup', { accepted: true });
  } else if (accountStatus === 'archived') {
    await CustomAxios.put('support/v1/reviews/accountStatus', { active: true });
  }
};

const deactivateReviews = async () => {
  await CustomAxios.put('support/v1/reviews/accountStatus', { active: false });
};

export const handleUpdateFeature = function* (action) {
  try {
    const { feature, newState } = action.payload;

    const state = yield select();

    const features = state.customization.features.map((currentFeature) => {
      if (currentFeature.id === feature.id) {
        return {
          ...currentFeature,
          state: newState.key,
          isFreeTrial: feature.isFreeTrial,
          quota: newState.quota !== undefined ? newState.quota : currentFeature.quota,
        };
      }
      return currentFeature;
    });

    yield put(locationFeaturesSuccess(features));
  } catch {
    yield put(showError('Failed to update feature in the feature list'));
  }
};

export const handleGetWamSyncStatus = function* (action: Action<string>) {
  const locationSlug = action.payload;
  try {
    const response: WamSyncStatusResponse = yield call(
      WamSyncSchemaAPI.getSyncStatusByLocationSlug,
      locationSlug
    );
    yield put(setWamSyncStatus(response));
  } catch (error) {
    yield put(wamSyncStatusFailure());
    console.error('Failed to get WAM sync status: ', error);
  }
};

export const customizationSaga = function* () {
  yield all([
    takeEvery(getLocationFeatures.toString(), handleGetLocationFeatures),
    takeEvery(setLocationFeatures.toString(), handleSetLocationFeatures),
    takeEvery(updateFeature.toString(), handleUpdateFeature),
    takeEvery(getWamSyncStatus.toString(), handleGetWamSyncStatus),
  ]);
};

const defaultState: CustomizationState = {
  loading: false,
  features: [],
  initialFeatures: [],
  wamSyncStatus: {
    customizationDetails: [],
  },
  wamSyncStatusLoading: false,
};
export const customizationReducer = handleActions(
  {
    [getLocationFeatures.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [setLocationFeatures.toString()]: (state) =>
      Object.assign({}, state, { loading: true }),
    [locationFeaturesSuccess.toString()]: (state, action) =>
      Object.assign({}, state, {
        loading: false,
        features: action.payload,
      }),
    [setInitialLocationFeature.toString()]: (state, action) =>
      Object.assign({}, state, {
        loading: false,
        initialFeatures: action.payload,
      }),
    [locationFeaturesFailure.toString()]: (state) =>
      Object.assign({}, state, { loading: false }),
    [setFeatures.toString()]: (state, action) =>
      Object.assign({}, state, { loading: false, features: action.payload }),
    [getWamSyncStatus.toString()]: (state) =>
      Object.assign({}, state, { wamSyncStatusLoading: true }),
    [setWamSyncStatus.toString()]: (state, action) =>
      Object.assign({}, state, {
        wamSyncStatus: action.payload,
        wamSyncStatusLoading: false,
      }),
    [wamSyncStatusFailure.toString()]: (state) =>
      Object.assign({}, state, { wamSyncStatusLoading: false, wamSyncStatus: null }),
  },
  defaultState
);

export type CustomizationState = {
  features: LocationFeatureV2[];
  loading: boolean;
  initialFeatures: LocationFeatureV2[];
  wamSyncStatus: WamSyncStatusResponse;
  wamSyncStatusLoading: boolean;
};

export const selectFeatures = ({ customization }: Store): LocationFeatureV2[] => {
  return customization.features;
};

export const selectInitialFeatures = ({ customization }: Store): LocationFeatureV2[] => {
  return customization.initialFeatures;
};

export const selectWamSyncStatus = ({ customization }: Store): WamSyncStatusResponse => {
  return customization.wamSyncStatus;
};

export const selectCustomizationFeature = createSelector(
  selectFeatures,
  (state, featureName: string) => featureName,
  (features, featureName): LocationFeatureV2 | undefined =>
    features.find(({ name }) => name === featureName)
);

export const selectSoftwarePlan = createSelector(selectFeatures, (features) => {
  const hasApptReminders = features.some(
    (feature) =>
      feature.name === 'Appt Reminders - automated' &&
      feature.state === CustomizationFlagStatesV2.ACTIVE
  );
  const hasPhoneAnalytics = features.some(
    (feature) =>
      feature.name === 'Phone Home Icon' &&
      feature.state === CustomizationFlagStatesV2.ACTIVE
  );
  const hasChat = features.some(
    (feature) =>
      feature.name === 'Chat' && feature.state === CustomizationFlagStatesV2.ACTIVE
  );

  if (hasApptReminders) {
    if (hasChat) {
      return hasPhoneAnalytics ? 'Plus' : 'Plus Software Only';
    }
  } else if (hasPhoneAnalytics) {
    return hasChat ? 'Core' : 'Phones Only';
  } else {
    return 'Core Software Only';
  }
  return '';
});

// PT-TODO: Find out if these customization flags are still the way to determine these
// office configurations. This is how it was being determined in the handoff hub but that
// was built many years ago.
export const selectOfficeConfigurations = createSelector(selectFeatures, (features) => {
  const isIntegrated = features.some(
    (feature) =>
      feature.name === 'Integrated' && feature.state === CustomizationFlagStatesV2.ACTIVE
  );

  const hasChat = features.some(
    (feature) =>
      feature.name === 'Chat' && feature.state === CustomizationFlagStatesV2.ACTIVE
  );

  const isPhonesOnly = !isIntegrated && !hasChat;

  const isSoftwareOnly = features.some(
    (feature) =>
      feature.name === 'Phone Home Icon' &&
      feature.state === CustomizationFlagStatesV2.HIDE
  );

  const hasPremiumFeatures = features.some(
    (feature) =>
      feature.name === 'Fax' && feature.state === CustomizationFlagStatesV2.ACTIVE
  );

  return {
    isIntegrated,
    isPhonesOnly,
    isSoftwareOnly,
    hasPremiumFeatures,
  };
});
export type OfficeConfigurations = ReturnType<typeof selectOfficeConfigurations>;
