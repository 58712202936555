import React from 'react';
import { Heading, NakedUl, Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { CopyToClipboardButton } from 'components/shared/copy-to-clipboard/copy-to-clipboard-button';
import { css } from '@emotion/core';

const STRIPE_TERMINAL_DOMAINS = [
  {
    domain: 'armada.stripe.com',
    exampleIp: '198.202.176.11',
  },
  {
    domain: 'gator.stripe.com',
    exampleIp: '198.202.176.211',
  },
  {
    domain: 'pool.ntp.org',
    exampleIp: '45.84.199.136',
  },
  {
    domain: 'time.android.com',
    exampleIp: '216.239.35.4',
  },
  // TODO: figure out the following
  // 'api.emms.bbpos.com', // Doesn't respond to pings
  // '*.terminal-events.stripe.com', // can't ping this one
];

type TerminalDebugComponentProps = {};

export function TerminalDebugComponent(props: TerminalDebugComponentProps) {
  return (
    <>
      <Heading level={2} style={{ paddingTop: theme.spacing(3) }}>
        Terminal Debug (Beta)
      </Heading>
      <DebugStep title="1. Stripe domains are allows">
        <Text>
          Make sure the terminals can connect to the internet and that the following
          domains are allowed by the network firewall.
        </Text>
        <NakedUl>
          {STRIPE_TERMINAL_DOMAINS.map((item) => {
            return (
              <DomainListItem
                key={item.domain}
                domain={item.domain}
                exampleIp={item.exampleIp}
              />
            );
          })}
          {
            <DomainListItem domain="api.emms.bbpos.com">
              <CommandAndResponse
                cmd="nslookup api.emms.bbpos.com"
                response={getNslookupResponse()}
              >
                This server doesn't respond to pings, so this just makes sure the domain
                can resolve to an IP address
              </CommandAndResponse>
            </DomainListItem>
          }
        </NakedUl>
      </DebugStep>
      <DebugStep title="2. Local IP address resolution works">
        <Text>
          The following should resolve to the localhost IP address and we should get 2
          replies from localhost.
        </Text>
        <PingCommandAndResponse
          domain="127-0-0-1.ls4h7e5k3mpsquj6kgqd.device.stripe-terminal-local-reader.net"
          exampleIp="127.0.0.1"
        />
      </DebugStep>
      <DebugStep title="3. Ping the terminal">
        <Text>
          Find a ping command in the above terminal table for the terminal you are
          troubleshooting. You should see a response similar to the following, but don't
          use the command in the example.
        </Text>
        <PingCommandAndResponse
          domain="192-168-0-193.ls4h7e5k3mpsquj6kgqd.device.stripe-terminal-local-reader.net"
          exampleIp="192.168.0.193"
          canCopy={false}
        />
      </DebugStep>
    </>
  );
}

// REUSED COMPONENTS

type DebugSectionProps = {
  title: string;
  children: React.ReactNode;
};

function DebugStep({ title, children }: DebugSectionProps) {
  return (
    <section
      css={css`
        code {
          display: block;
        }
        p {
          max-width: 750px;
        }
      `}
    >
      <Heading level={3} style={{ paddingTop: theme.spacing(3) }}>
        {title}
      </Heading>
      {children}
    </section>
  );
}

function DomainListItem({
  domain,
  exampleIp,
  children,
}: {
  domain: string;
  exampleIp?: string;
  children?: React.ReactNode;
}) {
  return (
    <li style={{ display: 'flex', flexDirection: 'column' }}>
      <Text weight="bold" style={{ margin: theme.spacing(2, 0, 0) }}>
        {domain}
      </Text>
      {exampleIp ? (
        <PingCommandAndResponse domain={domain} exampleIp={exampleIp} />
      ) : (
        children
      )}
    </li>
  );
}

function PingCommandAndResponse({
  domain,
  exampleIp,
  canCopy = true,
}: {
  domain: string;
  exampleIp: string;
  canCopy?: boolean;
}) {
  const cmd = `ping -n 2 ${domain}`;
  return (
    <CommandAndResponse
      cmd={cmd}
      response={generateSampleResponse(domain, exampleIp)}
      canCopy={canCopy}
    />
  );
}

function CommandAndResponse({
  cmd,
  response,
  canCopy = true,
  style,
  children,
  ...rest
}: {
  cmd: string;
  response: string;
  canCopy?: boolean;
  style?: React.CSSProperties;
  children?: string;
}) {
  return (
    <div>
      <div css={{ padding: theme.spacing(0, 2, 0) }}>
        <code style={{ display: 'flex', height: 48, alignItems: 'center' }}>
          {canCopy ? <CopyToClipboardButton>{cmd}</CopyToClipboardButton> : cmd}
        </code>
        <code
          style={{
            padding: theme.spacing(2),
            border: '1px solid black',
            backgroundColor: theme.colors.gray600,
            color: theme.colors.white,
            fontSize: theme.fontSize(12),
            display: 'block',
            maxWidth: '750px',
            whiteSpace: 'pre-wrap',
            ...style,
          }}
          {...rest}
        >
          {response}
        </code>
        <Text color="light">{children}</Text>
      </div>
    </div>
  );
}

// UTILS

function generateSampleResponse(domain: string, ipAddress: string) {
  const isLocalhost = ipAddress === '127.0.0.1';

  // generate 2 random numbers between 10 and 89
  const random1 = isLocalhost ? 1 : Math.floor(Math.random() * 80 + 10);
  const random2 = isLocalhost ? 1 : Math.floor(Math.random() * 80 + 10);

  // get min, max, and average
  const min = Math.min(random1, random2);
  const max = Math.max(random1, random2);
  const avg = Math.floor((random1 + random2) / 2);

  return `Pinging ${domain} [${ipAddress}] with 32 bytes of data:
  Reply from 192.168.0.193: bytes=32 time=${random1}ms TTL=128
  Reply from 192.168.0.193: bytes=32 time=${random2}ms TTL=128
  
  Ping statistics for 192.168.0.193:
  \tPackets: Sent = 2, Received = 2, Lost = 0 (0% loss),
  Approximate round trip times in milli-seconds:
  \tMinimum = ${min}ms, Maximum = ${max}ms, Average = ${avg}ms
  `;
}

function getNslookupResponse() {
  return `Server:  prl-local-ns-server.shared
Address:  10.211.55.1
              
Non-authoritative answer:
Name:    d-94c7psghwj.execute-api.us-west-2.amazonaws.com
Addresses:  52.11.200.187
100.21.25.230
Aliases:  api.emms.bbpos.com`;
}
