import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { AlertInvertIconSmall, Text } from '@weave/design-system';

interface Props {
  title: string;
  show: boolean;
}
export const CustomizationFlagWarningBanner = ({ title, show }: Props) => {
  if (!show) return null;
  return (
    <div css={customizationFlagWarningBannerStyles}>
      <AlertInvertIconSmall color="warn" />
      <Text css={customizationFlagWarningBannerText}>{title}</Text>
    </div>
  );
};

const customizationFlagWarningBannerStyles = css`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.warning5};
  border-radius: ${theme.borderRadius.small};
  border-color: ${theme.colors.warning5};
  padding: ${theme.spacing(2)};
  max-width: 640px;
`;

const customizationFlagWarningBannerText = css`
  margin: 0;
  margin-left: ${theme.spacing(2)};
  color: ${theme.colors.warning80} !important;
  word-wrap: break-word;
`;
