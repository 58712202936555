import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'reactstrap';
import {
  tableView,
  tableHeadRow,
  divCenterAlign,
  containerButtonStyle,
  resetButton,
  applyButton,
  filterContainer,
  radioButtonContainer,
  containerDiv,
} from './e911-dashboard.styles';
import {
  Text,
  CaretDownIconSmall,
  CaretUpIconSmall,
  CheckboxField,
  useFormField,
  TextButton,
  Heading,
  useControlledField,
  ContentLoader,
  RadioField,
  PrimaryButton,
} from '@weave/design-system';
import { getE911MissingTenants, muteTenant } from '../../apis/porting/porting.api';
import { e911TenantData } from '../../apis/porting/porting.types';
import { useTable, Column, useSortBy, usePagination } from 'react-table-7';
import { useAlert } from '@weave/alert-system';
import { format } from 'date-fns';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';
import { TenantId } from './e911-dashboard.constant';
import MoreIconComponent from './more-icon-component';
import RetryComponent from './retry-component';

type EnhancedE911TenantData = e911TenantData & { childrenSlugs: string[] };

export const e911Dashboard = () => {
  const [e911Tenants, setE911Tenants] = useState<EnhancedE911TenantData[]>([]);
  const [hiddenOfficeValue, setHiddenOfficeValue] = useState<string>('false');
  const [multiOfficeValue, setMultiOfficeValue] = useState<string>('true');
  const fieldPropsForHiddenOffice = useControlledField({
    type: 'radio',
    value: hiddenOfficeValue,
    onChange: (value) => {
      setHiddenOfficeValue(value);
    },
  });
  const fieldPropsForMultiOffice = useControlledField({
    type: 'radio',
    value: multiOfficeValue,
    onChange: (value) => {
      setMultiOfficeValue(value);
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [isValueApplied, setIsValueApplied] = useState<boolean>(false);
  const showTenantAlert = useAlert();

  const hiddenOffice = hiddenOfficeValue.toLowerCase() === 'true';
  const multiOffice = multiOfficeValue.toLowerCase() === 'true';

  const sortedDateArray = <T extends e911TenantData>(data: T[]): T[] => {
    return data?.sort((tenant1, tenant2) =>
      tenant2.createdAt.localeCompare(tenant1.createdAt)
    );
  };

  useEffect(() => {
    const getE911MissingTenantsData = async () => {
      try {
        setLoading(true);
        const data = await getE911MissingTenants(hiddenOffice, !multiOffice);
        const sortedArray = sortedDateArray(data);
        setE911Tenants(sortedArray);
      } catch (error) {
        showTenantAlert.error(`${error}`);
      } finally {
        setLoading(false);
      }
    };
    getE911MissingTenantsData();
  }, []);
  const COLUMNS: Column[] = [
    {
      Header: TenantId,
      accessor: 'id',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Slug',
      accessor: 'slug',
    },
    {
      Header: 'Children Slugs',
      accessor: 'childrenSlugs',
      Cell: ({ value }) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {value.map((slug) => (
            <span>{slug}</span>
          ))}
        </div>
      ),
    },
    {
      Header: 'Created date',
      accessor: 'createdAt',
      Cell: ({ value }) => format(new Date(value), 'MM/dd/yyyy'),
    },
    {
      Header: 'Multi-Office',
      accessor: 'isShared',
      sortType: (a, b) => {
        if (a.values['isShared'] === undefined) {
          a.values['isShared'] = false;
        } else if (b.values['isShared'] === undefined) {
          b.values['isShared'] = false;
        }
        if (a.values['isShared'] < b.values['isShared']) {
          return 1;
        } else if (a.values['isShared'] > b.values['isShared']) {
          return -1;
        } else {
          return 0;
        }
      },
      Cell: ({ value }) => {
        const fieldProps = useFormField({ type: 'checkbox' });
        return (
          <CheckboxField
            {...fieldProps}
            disabled={true}
            value={!!value}
            name="Unify"
            label=""
          />
        );
      },
    },
    {
      Header: 'No Of Attempts',
      accessor: 'tenantDashboard.attempts',
      Cell: ({ value }) => <span>{value || 0}</span>,
    },
    {
      Header: 'locationId',
      accessor: 'locationId',
      Cell: ({ value }) => <span style={{ display: 'none' }}></span>,
    },
    {
      Header: 'Retry',
      accessor: 'retry',
      Cell: (e) => {
        return <RetryComponent retryData={e} />;
      },
    },
    {
      Header: '',
      accessor: 'tenantDashboard',
      Cell: (e) => {
        let mutedValue;
        let tenantIdValue;
        let attemptsValue = 0;
        const getE911MissingTenantsData = async (
          multiOffice,
          hiddenOffice
        ): Promise<EnhancedE911TenantData[]> => {
          try {
            const data = await getE911MissingTenants(hiddenOffice, !multiOffice);
            return data;
          } catch (error) {
            showTenantAlert.error(`${error}`);
          }
          return [];
        };

        e?.cell?.row?.allCells?.some((item) => {
          if (item.column.Header) {
            return (
              (mutedValue = item?.row?.values?.tenantDashboard?.muted),
              (tenantIdValue = item?.row?.values?.id),
              (attemptsValue = item?.row?.values?.tenantDashboard?.attempts)
            );
          }
        });
        const handleDropDownValueSelect = async () => {
          try {
            const tenantE911Dashboard = await muteTenant(
              tenantIdValue,
              attemptsValue,
              !mutedValue
            );

            showTenantAlert.success(
              `${!mutedValue ? 'Hide Tenant Successful' : 'Show Tenant Successful'}`
            );

            if (tenantE911Dashboard?.muted && !hiddenOffice) {
              const showData = [...e911Tenants].filter(
                (item) => item.id !== tenantE911Dashboard?.tenantId
              );
              const sortedArray = sortedDateArray(showData);
              setE911Tenants(sortedArray);
            } else {
              const showData = await getE911MissingTenantsData(hiddenOffice, multiOffice);
              const sortedArray = sortedDateArray(showData);
              setE911Tenants(sortedArray);
            }
          } catch (error) {
            showTenantAlert.error(`${error}`);
          }
        };
        return (
          <MoreIconComponent
            iconData={e}
            handleDropDownValueSelect={handleDropDownValueSelect}
            mutedValue={mutedValue}
            tenantIdValue={tenantIdValue}
          />
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, [e911Tenants]);
  const data = useMemo(() => e911Tenants, [e911Tenants]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage: nextPageExists,
    canPreviousPage: previousPageExits,
    pageOptions,
    state,
    prepareRow,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  const handleFilterApply = async () => {
    setLoading(true);
    setIsValueApplied(true);

    try {
      const data = await getE911MissingTenants(hiddenOffice, !multiOffice);
      const sortedArray = sortedDateArray(data);
      setE911Tenants(sortedArray);
    } catch (error) {
      showTenantAlert.error(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = async () => {
    const hiddenOffice = false;
    const multiOffice = true;
    if (isValueApplied) {
      setLoading(true);
      try {
        const data = await getE911MissingTenants(hiddenOffice, !multiOffice);
        setHiddenOfficeValue('false');
        setMultiOfficeValue('true');
        const sortedArray = sortedDateArray(data);
        setE911Tenants(sortedArray);
        setLoading(false);
      } catch (error) {
        showTenantAlert.error(`${error}`);
      }
    } else {
      setHiddenOfficeValue('false');
      setMultiOfficeValue('true');
    }
    setIsValueApplied(false);
  };

  const handleDisable = (): boolean => {
    return multiOffice && !hiddenOffice;
  };

  return (
    <>
      <Text className={divCenterAlign}>
        <div style={{ display: 'flex' }}>
          <ContentLoader show={loading} />
          <div>
            <Heading
              level={1}
              style={{ textAlign: 'center', marginBottom: `${weaveTheme.spacing(2.3)}` }}
            >
              List of Tenants missing e911 config
            </Heading>
            <Table {...getTableProps()} className={tableView} style={{ width: '1300px' }}>
              <thead>
                {headerGroups.map((headerGroups) => (
                  <tr {...headerGroups.getHeaderGroupProps()} className={tableHeadRow}>
                    {headerGroups.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header') === 'locationId'
                          ? null
                          : column.render('Header')}
                        <Text as="span">
                          {column.isSorted &&
                            (column.isSortedDesc ? (
                              <CaretDownIconSmall></CaretDownIconSmall>
                            ) : (
                              <CaretUpIconSmall></CaretUpIconSmall>
                            ))}
                        </Text>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);

                  return (
                    <tr
                      {...row.getRowProps()}
                      style={
                        row?.values?.tenantDashboard?.muted
                          ? { color: '#dee2e6' }
                          : { background: 'none' }
                      }
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ marginRight: `${weaveTheme.spacing(1.2)}` }}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className={filterContainer}>
            <div className={containerDiv}>
              <div className={radioButtonContainer}>
                <RadioField
                  {...fieldPropsForHiddenOffice}
                  name="radio"
                  label="Hidden offices"
                >
                  <RadioField.Radio value="true">True</RadioField.Radio>
                  <RadioField.Radio value="false">False</RadioField.Radio>
                </RadioField>

                <RadioField
                  {...fieldPropsForMultiOffice}
                  name="radio"
                  label="Include Multi offices"
                >
                  <RadioField.Radio value="true">True</RadioField.Radio>
                  <RadioField.Radio value="false">False</RadioField.Radio>
                </RadioField>
              </div>
              <div className={containerButtonStyle}>
                <div className={resetButton}>
                  <PrimaryButton onClick={handleReset} disabled={handleDisable()}>
                    Reset
                  </PrimaryButton>
                </div>
                <div className={applyButton}>
                  <PrimaryButton onClick={handleFilterApply}>Apply</PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Text>
      <Text as="div" className={divCenterAlign}>
        {`Tenant(s) `}
        <strong>
          {pageIndex * pageSize +
            1 +
            '-' +
            Math.min(pageIndex * pageSize + pageSize, e911Tenants.length) +
            ' of ' +
            e911Tenants.length}
        </strong>
        <TextButton onClick={() => previousPage()} disabled={!previousPageExits}>
          Previous
        </TextButton>
        <TextButton onClick={() => nextPage()} disabled={!nextPageExists}>
          Next
        </TextButton>
      </Text>
    </>
  );
};
