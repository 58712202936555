import React from 'react';
import { footer, footerContent, noMargin, textColor } from './invoice-details-styles';
import { Text, TextLink } from '@weave/design-system';

export const InvoiceDetailsTemplateFooter = () => {
  return (
    <div css={footer}>
      <hr />
      <div css={footerContent}>
        <Text size="small" css={[textColor, noMargin]}>
          Billing questions? Please call <b>888-579-5668, Option. 2, Option. 3</b> <br />
          or email us at{' '}
          <TextLink to="mailto:billing@getweave.com" css={textColor} weight="bold">
            billing@getweave.com
          </TextLink>
        </Text>
        <span>
          <Text weight="bold" size="small" css={[textColor, noMargin]}>
            WEAVE COMMUNICATIONS, INC.
          </Text>
          <Text size="small" css={[textColor, noMargin]}>
            1331 W. Powell Way, Lehi, UT, 84043
          </Text>
        </span>
      </div>
    </div>
  );
};
