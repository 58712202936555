import React, { useReducer } from 'react';
import { useAlert } from '@weave/alert-system';
import { DropdownField, Heading, useControlledField } from '@weave/design-system';
import { css } from 'emotion';
import { find, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { MailboxSettingsSection } from './mailbox-settings-section';
import { MailboxGreetingsSection } from './mailbox-greetings-section';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { Page } from '../../shared/page/page.component';
import { useResources } from '../../../helpers/useResources';
import { Mailbox } from '../../../redux/actions/voice-mailboxes/voice-mailboxes.types';
import { CustomAxios } from '../../../redux/axios';

export enum MailboxUpdates {
  ActiveMailboxChange = 'activeMailboxChange',
  NameUpdate = 'name',
  EmailUpdate = 'email',
  PinUpdate = 'pin',
  MobileUpdate = 'mobile',
  PlayMessageDateUpdate = 'playMessageDate',
  ReceiveNotificationsUpdate = 'receiveNotifications',
}

type MailboxesState = {
  activeMailboxId: string;
  updatingName: boolean;
  updatingEmail: boolean;
  updatingMobile: boolean;
  updatingPin: boolean;
  updatingPlayMessageDate: boolean;
  updatingReceiveNotifications: boolean;
};

const initialState: MailboxesState = {
  activeMailboxId: '',
  updatingName: false,
  updatingEmail: false,
  updatingMobile: false,
  updatingPin: false,
  updatingPlayMessageDate: false,
  updatingReceiveNotifications: false,
};

function reducer(state: MailboxesState, action) {
  switch (action.type) {
    case MailboxUpdates.ActiveMailboxChange:
      return { ...state, activeMailboxId: action.payload };
    case MailboxUpdates.NameUpdate:
      return { ...state, updatingName: action.payload };
    case MailboxUpdates.EmailUpdate:
      return { ...state, updatingEmail: action.payload };
    case MailboxUpdates.MobileUpdate:
      return { ...state, updatingMobile: action.payload };
    case MailboxUpdates.PinUpdate:
      return { ...state, updatingPin: action.payload };
    case MailboxUpdates.PlayMessageDateUpdate:
      return { ...state, updatingPlayMessageDate: action.payload };
    case MailboxUpdates.ReceiveNotificationsUpdate:
      return { ...state, updatingReceiveNotifications: action.payload };
    default:
      throw new Error();
  }
}

export const VoiceMailboxes = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const locationId = useSelector(selectCurrentLocationId);
  const alerts = useAlert();

  const {
    data: mailboxes,
    loading: loadingMailboxes,
    setResources: setMailboxes,
  } = useResources<Mailbox[]>('support/v1/voicemail/mailboxes', {
    deps: [locationId],
    suppressFetch: !locationId,
    resourceLabel: 'voice mailboxes',
    sort: (newMailboxes: Mailbox[]) => {
      return orderBy(newMailboxes, ['number']);
    },
    onFetch: (newMailboxes: Mailbox[]) => {
      const generalMailbox = newMailboxes.find((mailbox) => mailbox.isGeneral);
      dispatch({
        type: MailboxUpdates.ActiveMailboxChange,
        payload: generalMailbox?.mailboxID ?? newMailboxes[0]?.mailboxID ?? '',
      });
    },
  });

  const mailBoxField = useControlledField({
    type: 'dropdown',
    value: state.activeMailboxId,
    onChange: (value) => {
      dispatch({
        type: MailboxUpdates.ActiveMailboxChange,
        payload: value,
      });
    },
  });

  const activeMailbox = find(mailboxes, { mailboxID: state.activeMailboxId });

  const onUpdateMailbox = async (newMailbox: Mailbox, type: MailboxUpdates) => {
    dispatch({ type, payload: true });

    const originalMailboxes = [...mailboxes];

    // Optimistically update the UI
    setMailboxes(
      mailboxes.reduce((newMailboxes: Mailbox[], currentMailbox: Mailbox) => {
        if (currentMailbox.mailboxID === newMailbox.mailboxID) {
          return [...newMailboxes, newMailbox];
        }

        return [...newMailboxes, currentMailbox];
      }, [])
    );

    try {
      await CustomAxios.put(
        `phone/voicemail-config/v1/mailbox/${newMailbox.mailboxID}`,
        newMailbox
      );
    } catch {
      alerts.error('Failed to update mailbox. Please try again.');
      // Rollback the optimistic update
      setMailboxes(originalMailboxes);
    }

    dispatch({ type, payload: false });
  };

  return (
    <Page title="Voice Mailboxes" loading={loadingMailboxes}>
      <Heading level={2}>Select Mailbox</Heading>
      <div
        className={css`
          margin: 8px 0 24px;
          max-width: 300px;
        `}
      >
        <DropdownField {...mailBoxField} label="Mailbox" name="mailbox-name">
          {mailboxes.map((mailbox) => (
            <DropdownField.Option key={mailbox.mailboxID} value={mailbox.mailboxID}>
              {mailbox.number} ({mailbox.name})
            </DropdownField.Option>
          ))}
        </DropdownField>
      </div>
      <MailboxSettingsSection
        mailbox={activeMailbox}
        updateMailbox={onUpdateMailbox}
        updatingName={state.updatingName}
        updatingEmail={state.updatingEmail}
        updatingPin={state.updatingPin}
        updatingMobile={state.updatingMobile}
        updatingPlayMessageDate={state.updatingPlayMessageDate}
        updatingReceiveNotifications={state.updatingReceiveNotifications}
      />
      <MailboxGreetingsSection
        mailboxId={activeMailbox?.mailboxID ?? ''}
        isGeneral={activeMailbox?.isGeneral ?? false}
      />
    </Page>
  );
};
