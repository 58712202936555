import { CustomizationFlagStatesV2 } from 'models/location-feature.model';

export enum IntegrationPackagesEnum {
  PLUS = 'PLUS',
  CORE = 'CORE',
}

export enum BundlePackagesEnum {
  ULTIMATE = 'ULTIMATE',
  ELITE = 'ELITE',
  PRO = 'PRO',
  ESSENTIALS = 'ESSENTIALS',
}

export enum SalesforceAccountProductBundle {
  PRODUCT_BUNDLE_WEAVE_PLUS_ULTIMATE = 'PRODUCT_BUNDLE_WEAVE_PLUS_ULTIMATE',
  PRODUCT_BUNDLE_WEAVE_PLUS_PRO = 'PRODUCT_BUNDLE_WEAVE_PLUS_PRO',
  PRODUCT_BUNDLE_WEAVE_PLUS_ELITE = 'PRODUCT_BUNDLE_WEAVE_PLUS_ELITE',
  PRODUCT_BUNDLE_WEAVE_PLUS_ESSENTIALS = 'PRODUCT_BUNDLE_WEAVE_PLUS_ESSENTIALS',
  PRODUCT_BUNDLE_WEAVE_CORE_ULTIMATE = 'PRODUCT_BUNDLE_WEAVE_CORE_ULTIMATE',
  PRODUCT_BUNDLE_WEAVE_CORE_PRO = 'PRODUCT_BUNDLE_WEAVE_CORE_PRO',
  PRODUCT_BUNDLE_WEAVE_CORE_ELITE = 'PRODUCT_BUNDLE_WEAVE_CORE_ELITE',
  PRODUCT_BUNDLE_WEAVE_CORE_ESSENTIALS = 'PRODUCT_BUNDLE_WEAVE_CORE_ESSENTIALS',
  PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ULTIMATE = 'PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ULTIMATE',
  PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ESSENTIALS = 'PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ESSENTIALS',
  PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_PRO = 'PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_PRO',
  PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ELITE = 'PRODUCT_BUNDLE_WEAVE_CORE_SOFTWARE_ONLY_ELITE',
  PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ULTIMATE = 'PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ULTIMATE',
  PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ESSENTIALS = 'PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ESSENTIALS',
  PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_PRO = 'PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_PRO',
  PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ELITE = 'PRODUCT_BUNDLE_WEAVE_PLUS_SOFTWARE_ONLY_ELITE',
}

export type CustomizationFlag = {
  name?: string;
  id?: string;
  state?: CustomizationFlagStatesV2;
};

export type ProhibitedFlags = {
  name?: string;
  id?: string;
};

export type CustomizationFlagsResponse = {
  activeFlags?: CustomizationFlag[];
  prohibitedFlags?: ProhibitedFlags[];
  countryCodeVerified?: boolean;
};

export type CustomizationFlagsRequest = {
  products?: SalesforceAccountProductBundle[];
  vertical?: number;
};
