import React from 'react';
import { PrimaryButton, useModalControl, ConfirmationModal } from '@weave/design-system';

type DeletePortingDataButtonProps = {
  onConfirm: () => void;
};

export const DeletePortingDataButton = ({ onConfirm }: DeletePortingDataButtonProps) => {
  const { triggerProps, modalProps } = useModalControl();

  return (
    <>
      <PrimaryButton size="tiny" destructive onClick={triggerProps.onClick}>
        Delete
      </PrimaryButton>

      <ConfirmationModal
        {...modalProps}
        title="Confirm Porting Data Deletion"
        message="This is a destructive action. Are you sure you want to do this?"
        onConfirm={onConfirm}
      ></ConfirmationModal>
    </>
  );
};
