import { connect } from 'react-redux';
import { FormikErrors, withFormik } from 'formik';
import { compose } from 'lodash/fp';

import {
  LocationForm,
  LocationFormProps,
} from '../location-form/location-form.component';
import {
  convertLocationToProvisionLocationDTO,
  CreateLocationDTO,
  LocationModelWithPhoneData,
  LocationTypes,
  mockLocationWithPhoneData,
  requestCreateLocation,
} from '../../../redux/actions/location/locations';
import {
  ProvisionLocationRequestDTO,
  resetLocationCreationStatus,
} from '../../../redux/actions/location/location-status';
import { Timezones } from '../../../models/timezones';
import { isPhoneNumber } from '../../../helpers/validators';
import { selectHasWeaveAcl } from '../../../redux/actions/auth/auth.selectors';
import { Store } from '../../../redux/store/store.model';
import { CoreACLs } from '../../../redux/actions/auth/auth.types';
import { VerticalEnum } from '../../../models/vertical-enum';

interface Props extends LocationFormProps {
  createLocation: (location: CreateLocationDTO) => void;
}

export const validateLocationCreateForm = function (values) {
  const errors: FormikErrors<LocationModelWithPhoneData> = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }
  if (!values.Slug) {
    errors.Slug = 'Required';
  }
  if (/[A-Z]/.test(values.Slug)) {
    errors.Slug = 'Slug may not contain capital letters';
  }

  if (/^[sp].*/.test(values.Slug)) {
    if (!values.SalesforceID) {
      errors.SalesforceID =
        'Salesforce account ID is mandatory if slug starts with s or p (SF account)';
    } else if (values.SalesforceID.length != 18) {
      errors.SalesforceID = 'Salesforce account ID must be 18 characters long';
    }
  }

  if (!values.Email) {
    errors.Email = 'Required';
  }

  if (values.createPhoneTenant) {
    if (!values.zip) {
      errors.zip = 'Required';
    }
    if (!values.city) {
      errors.city = 'Required';
    }
    if (!values.state) {
      errors.state = 'Required';
    }
    if (!values.street1) {
      errors.street1 = 'Required';
    }
    if (!isPhoneNumber(values.caller_number)) {
      errors.caller_number = 'Not a valid phone number';
    }
  }

  return errors;
};

export const LocationCreateContainer = compose(
  connect(
    (state: Store) => ({
      creationStatus: state.locationStatus.creationStatus,
      isNew: true,
      loading: state.locationStatus.loading,
      allowSetParent: selectHasWeaveAcl(state, CoreACLs.WRITEMULTILOCATIONCONFIGS),
    }),
    (dispatch, ownProps: any) => ({
      createLocation: (
        location: ProvisionLocationRequestDTO,
        hasPhoneTenant: boolean
      ) => {
        return dispatch(requestCreateLocation(location, hasPhoneTenant));
      },
      onClose: () => ownProps.history.push('/'),
      resetLocationCreationStatus: () => dispatch(resetLocationCreationStatus()),
    })
  ),
  withFormik({
    mapPropsToValues: () => {
      const location = mockLocationWithPhoneData({
        Timezone: Timezones[0].Value,
        VerticalID: 1,
        Active: true,
      });
      return {
        ...location,
        createPhoneTenant: true,
      };
    },
    validate: validateLocationCreateForm,
    handleSubmit: (values, formikBag) => {
      const provisionLocation: CreateLocationDTO = {
        ...values,
        Type:
          // The enum wants numbers but the field value is passing back the string
          // @ts-ignore
          values.Type === 'Regular'
            ? LocationTypes.Regular
            : // @ts-ignore
            values.Type === 'Dev'
            ? LocationTypes.Dev
            : // @ts-ignore
            values.Type === 'Demo'
            ? LocationTypes.Demo
            : values.Type,
        VerticalID: Number(values.VerticalID),
        ParentID: values.ParentID || null, //Has to be null, can't be empty string
      };
      const req = {
        ...convertLocationToProvisionLocationDTO(provisionLocation),
        salesforceProducts: [],
      };

      //TODO: Fix this type
      (formikBag.props as any).createLocation(req, values.createPhoneTenant);
      formikBag.resetForm();
    },
  })
)(LocationForm);
