import {
  Country,
  EntityType,
  TCRBrandIdentityStatus,
} from '@weave/schema-gen-ts/dist/schemas/tendlc/shared/v1/shared.pb';
import { TCRBrandFriendlyRegistrationStatus } from './types';

const PRIVATE_PROFIT_EXISTING_BRAND_IDENTITY_STATUS_MAP: Record<
  TCRBrandIdentityStatus,
  TCRBrandFriendlyRegistrationStatus
> = {
  [TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_SELF_DECLARED]:
    TCRBrandFriendlyRegistrationStatus.UNVERIFIED,
  [TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_UNSPECIFIED]:
    TCRBrandFriendlyRegistrationStatus.PENDING,
  [TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_UNVERIFIED]:
    TCRBrandFriendlyRegistrationStatus.UNVERIFIED,
  [TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VERIFIED]:
    TCRBrandFriendlyRegistrationStatus.VERIFIED,
  [TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VETTED_VERIFIED]:
    TCRBrandFriendlyRegistrationStatus.VERIFIED,
};

type GetFriendRegistrationStatusArgs = {
  entityType: EntityType;
  identityStatus: TCRBrandIdentityStatus;
  country: Country;
  locked: boolean;
};

export const getFriendlyRegistrationStatus = ({
  entityType,
  identityStatus,
  country,
  locked,
}: GetFriendRegistrationStatusArgs): TCRBrandFriendlyRegistrationStatus => {
  if (
    entityType === EntityType.ENTITY_TYPE_UNSPECIFIED &&
    country === Country.COUNTRY_UNSPECIFIED
  ) {
    return TCRBrandFriendlyRegistrationStatus.UNREGISTERED;
  }

  if (locked) {
    return TCRBrandFriendlyRegistrationStatus.LOCKED;
  }

  if (country === Country.COUNTRY_CA) {
    return TCRBrandFriendlyRegistrationStatus.CANADA;
  }

  if (entityType === EntityType.ENTITY_TYPE_SOLE_PROPRIETOR) {
    return TCRBrandFriendlyRegistrationStatus.SOLE_PROPRIETOR;
  }

  return PRIVATE_PROFIT_EXISTING_BRAND_IDENTITY_STATUS_MAP[identityStatus];
};
