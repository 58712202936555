import React from 'react';
import { useSelector } from 'react-redux';

import { CustomAxios } from '../../redux/axios';
import { selectPaymentUrl } from '../../redux/actions/merchant/merchant.action';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  Text,
  Heading,
  useModalControl,
  SpinningLoader,
  styles,
} from '@weave/design-system';
import { TerminalLocationAddress } from './terminal-location-address.component';
import { MerchantPlanTypes } from 'models/pricing.model';
import { MerchantModel } from 'models/merchant.model';
import { CopyToClipboardButton } from 'components/shared/copy-to-clipboard/copy-to-clipboard-button';

interface Props {
  merchant: MerchantModel;
  isMerchantLoading: boolean | undefined;
}

export interface StripeReader {
  id: string;
  object: string;
  device_sw_version: string;
  device_type: string;
  ip_address: string;
  label: string;
  location: string;
  serial_number: string;
  status: 'online' | 'offline';
}

export const TerminalList = ({ merchant, isMerchantLoading }: Props) => {
  const paymentsUrl = useSelector(selectPaymentUrl);
  const [readers, setReaders] = React.useState<StripeReader[]>([]);
  const stripeLocationId = merchant.terminalLocation;
  const hasTerminalLocation = merchant?.hasTerminalLocation;
  const planName = merchant?.planName;

  const {
    modalProps: terminalLocationAddressModal,
    openModal: openTerminalLocationAddressModal,
    closeModal: closeTerminalLocationAddressModal,
  } = useModalControl();

  const fetchConnectionToken = async () => {
    try {
      const response = await CustomAxios.post(
        `${paymentsUrl}/v1/terminal/connection-token`,
        {
          stripeId: stripeLocationId,
        }
      );

      return response.data.data.secret;
    } catch (error: any) {
      console.error('Fetch Connection Token failed', error);
      return '';
    }
  };

  const onUnexpectedReaderDisconnect = () => {
    console.error('connection to Stripe Terminal lost');
  };

  React.useEffect(() => {
    if (!stripeLocationId) {
      return;
    }
    // Stripe Terminal js sdk
    const terminal = (window as any).StripeTerminal.create({
      onFetchConnectionToken: fetchConnectionToken,
      onUnexpectedReaderDisconnect: onUnexpectedReaderDisconnect,
    });

    const config = { simulated: false, location: stripeLocationId };
    terminal.discoverReaders(config).then(
      ({ discoveredReaders }) => {
        setReaders(discoveredReaders || []);
      },
      () => {
        setReaders([]);
      }
    );
  }, [paymentsUrl, stripeLocationId]);

  if (isMerchantLoading) {
    return (
      <section
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <SpinningLoader />
      </section>
    );
  }

  if (planName === MerchantPlanTypes.TextToPay) {
    return (
      <Text weight="semibold" css={infoText}>
        This customer is a TextToPay customer. Stripe Terminal Readers are not available.
      </Text>
    );
  }

  return (
    <>
      <Heading level={2}>Terminal Location Address</Heading>
      <TerminalLocationAddress
        modalProps={terminalLocationAddressModal}
        openModal={openTerminalLocationAddressModal}
        closeModal={closeTerminalLocationAddressModal}
        hasTerminalLocation={hasTerminalLocation}
      />
      <Heading level={2}>Registered Terminals ({readers.length})</Heading>
      {readers.length ? (
        <table css={tableStyle}>
          <thead>
            <tr>
              <th>Serial Number</th>
              <th>Label</th>
              <th>IP Address</th>
              <th>Device Type</th>
              <th>Stripe ID</th>
              <th>Status</th>
              <th>Ping Cmd</th>
            </tr>
          </thead>
          <tbody>
            {readers.map((reader) => {
              // TODO: move this to a util function?
              const pingCmd = `ping -n 2 ${reader.ip_address.split('.').join('-')}.${
                reader['base_url']
              }`;
              return (
                <tr key={reader.id}>
                  <td>{reader.serial_number}</td>
                  <td>{reader.label}</td>
                  <td>{reader.ip_address}</td>
                  <td>
                    {reader.device_type} - {reader.device_sw_version}
                  </td>
                  <td>{reader.id}</td>
                  <td>
                    <span css={readerOnlineStatus(reader.status === 'online')}></span>
                  </td>
                  <td style={{ display: 'flex', alignItems: 'center' }}>
                    <CopyToClipboardButton copyText={pingCmd}>
                      <Text css={[{ display: 'inline', margin: 0 }, styles.truncate]}>
                        {pingCmd}
                      </Text>
                    </CopyToClipboardButton>{' '}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Text weight="semibold" css={infoText}>
          No registered terminals to show{' '}
        </Text>
      )}
    </>
  );
};

const infoText = css`
  font-size: ${theme.fontSize(18)};
  padding: 20px;
  background: ${theme.colors.gray200};
  width: fit-content;
  border: 1px solid ${theme.colors.gray400};
`;

const tableStyle = css`
  text-align: left;
  border-radius: 10px;
  overflow: hidden;
  width: fit-content;
  box-shadow: ${theme.shadows.light};

  td,
  th {
    padding: 16px 20px;
    white-space: nowrap;
  }
  th {
    color: white;
    background-color: ${theme.colors.gray600};
  }

  // style for the ping command
  td:last-of-type,
  th:last-of-type {
    max-width: 250px;
  }

  tr:nth-child(even) {
    background-color: #f5f5f5;
  }

  td:first-child {
    color: ${theme.colors.gray500};
    font-weight: 500;
  }

  td:not(:first-child) {
    border-left: 1px solid rgb(236, 236, 236);
  }
`;

const readerOnlineStatus = (isOnline: boolean) =>
  css`
    display: block;
    margin: auto;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background: ${isOnline ? theme.colors.success : theme.colors.error};
  `;
