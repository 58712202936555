import { combineReducers } from 'redux';
import { requestStateReducers } from '@weave/alert-system';

import { callForwardingReducer } from '../actions/call-forwarding';
import { callSettingsReducer } from '../actions/call-settings/call-settings.reducer';
import { dayOfWeekRemindersReducer } from '../actions/day-of-week-reminders';
import { departmentPhoneNumbersReducer } from '../actions/department-phone-numbers';
import { locationHistoryReducer } from '../actions/location/location-history/location-history.action';
import { autoMsgQueueReducer } from '../actions/auto-msg-queue';
import { userJobTitlesReducer } from '../actions/users/user-job-titles.reducer';
import { usersListReducer } from '../actions/users';
import userSelectReducer from '../actions/users/user-select.reducer';
import userAvailableJobTitlesReducer from '../actions/users/user-available-job-titles.reducer';
import userSearchReducer from '../actions/users/user-search.reducer';
import { historyReducer } from '../actions/history';
import { missedCallTextReducer } from '../actions/missed-call-text/missed-call-text.action';
import { onboardingTasksReducer } from '../actions/onboarding-tasks';
import { customizationReducer } from '../actions/customization/customization.action';
import { reviewsReducer } from '../actions/reviews';
import { userPhotoReducer } from '../actions/user-photo';
import { authReducer } from '../actions/auth/auth.reducer';
import { twilioReducer } from '../actions/twilio';
import { voiceMailboxesReducer } from '../actions/voice-mailboxes';
import { locationSearchReducer } from '../actions/location/location-search';
import { mediaReducer } from '../actions/media';
import { phoneMediaReducer } from '../actions/phone-media';
import { officeHoursReducer } from '../actions/office-hours';
import { locationAccessReducer } from '../actions/location/location-access/location-access.action';
import { locationsReducer } from '../actions/location/locations';
import { locationSettingsReducer } from '../actions/location/location-settings/location-settings.action';
import { manualTemplatesReducer } from '../actions/manual-templates';
import { recordsReducer } from '../actions/records';
import { notificationsSettingsReducer } from '../actions/notifications-settings/notifications-settings.reducer';
import { desktopClientsReducer } from '../actions/desktop-clients/desktop-clients.action';
import userRolesReducer from '../actions/users/user-roles.reducer';
import { featureFlagsReducer } from '../actions/feature-flags';
import { patientsReducer } from '../actions/patients/patients.action';
import { personReducer } from '../actions/patients/person.action';
import { appointmentsByPatientReducer } from '../actions/appointments/appointments-by-patient.action';
import { syncAppReducer } from '../actions/sync-app';
import { portingReducer } from '../actions/porting';
import { internalToolsReducer } from '../actions/internal-tools/internal-tools.reducer';
import { statusMappingSettingsReducer } from '../actions/status-mapping-settings/status-mapping-settings.action';
import { smsDetailsReducer } from '../actions/sms/sms-details';
import { smsMetadataReducer } from '../actions/sms/sms-metadata';
import { callBlockingReducer } from '../actions/call-blocking';
import { merchantReducer } from '../actions/merchant/merchant.action';
import { orderHistoryReducer } from '../actions/orders/order-history.action';
import { recallHistoryReducer } from '../actions/recall/recall-history.action';
import { emailHistoryReducer } from '../actions/emails/email-history.action';
import { handoffHubReducer } from '../actions/handoff-hub';
import { phoneNumbersReducer } from '../actions/phone-numbers';
import { userReducer } from '../actions/auth/user/user.reducer';
import { writebacksByApptReducer } from '../actions/writebacks/writebacks-by-appt';
import { notificationQueueReducer } from '../actions/notification-queue';
import { appointmentReducer } from '../actions/appointments/appointment';
import { eventReducer } from '../actions/events';
import { connectedUsersReducer } from '../actions/users/connected-users.reducer';
import { clientApiReducer } from '../actions/client-api';
import { connectRouter } from 'connected-react-router';
import { analyticsReducer } from '../actions/analytics/';
import { smartAlertsReducer } from '../actions/smart-alerts/smart-alerts.reducer';
import {
  faxHistoryReducer,
  faxSettingsReducer,
  smsHistoryReducer,
  syncAppSettingsReducer,
} from '../actions';
import { departmentReducer } from '../actions/department';
import { devicesReducer } from '../actions/devices';
import { fwdNumbersReducer } from '../actions/fwd-numbers';
import { currentLocationReducer } from '../actions/location/current-location';
import { locationStatusReducer } from '../actions/location/location-status';
import { ringtonesReducer } from '../actions/ringtones/ringtones.reducer';
import { notificationsReducer } from '../actions/recall/recall-types.action';
import { cachedActionsReducer } from '../actions/cached-actions';
import { firebaseTokenReducer } from '../actions/firebase-token';
import { outageAlertsReducer } from '../actions/outage-alerts';

export const rootReducerFactor = (history) =>
  combineReducers<any, any>({
    ...requestStateReducers,
    cachedActions: cachedActionsReducer,
    analytics: analyticsReducer,
    appointment: appointmentReducer,
    appointmentsByPatient: appointmentsByPatientReducer,
    auth: authReducer,
    autoMsgQueue: autoMsgQueueReducer,
    availableJobTitles: userAvailableJobTitlesReducer,
    availableRoles: userRolesReducer,
    callBlocking: callBlockingReducer,
    callForwarding: callForwardingReducer,
    callSettings: callSettingsReducer,
    clientApi: clientApiReducer,
    connectedUsers: connectedUsersReducer,
    currentLocationId: currentLocationReducer,
    customization: customizationReducer,
    dayOfWeek: dayOfWeekRemindersReducer,
    departments: departmentReducer,
    departmentPhoneNumbers: departmentPhoneNumbersReducer,
    desktopClients: desktopClientsReducer,
    devices: devicesReducer,
    emailHistory: emailHistoryReducer,
    event: eventReducer,
    faxHistory: faxHistoryReducer,
    faxSettings: faxSettingsReducer,
    featureFlags: featureFlagsReducer,
    fwdNumbers: fwdNumbersReducer,
    handoffHub: handoffHubReducer,
    history: historyReducer,
    internalTools: internalToolsReducer,
    jobTitles: userJobTitlesReducer,
    locationAccess: locationAccessReducer,
    locationHistory: locationHistoryReducer,
    locations: locationsReducer,
    locationSearch: locationSearchReducer,
    locationSettings: locationSettingsReducer,
    locationStatus: locationStatusReducer,
    manualTemplates: manualTemplatesReducer,
    media: mediaReducer,
    merchant: merchantReducer,
    missedCallText: missedCallTextReducer,
    notifications: notificationsReducer,
    notificationsSettings: notificationsSettingsReducer,
    notificationQueue: notificationQueueReducer,
    officeHours: officeHoursReducer,
    onboarding: onboardingTasksReducer,
    orderHistory: orderHistoryReducer,
    patients: patientsReducer,
    person: personReducer,
    phoneMedia: phoneMediaReducer,
    phoneNumbers: phoneNumbersReducer,
    porting: portingReducer,
    recallHistory: recallHistoryReducer,
    records: recordsReducer,
    reviews: reviewsReducer,
    ringtones: ringtonesReducer,
    router: connectRouter(history),
    smartAlerts: smartAlertsReducer,
    smsDetails: smsDetailsReducer,
    smsHistory: smsHistoryReducer,
    smsMetadata: smsMetadataReducer,
    statusMapping: statusMappingSettingsReducer,
    syncApp: syncAppReducer,
    syncAppSettings: syncAppSettingsReducer,
    twilio: twilioReducer,
    user: userReducer,
    userPhoto: userPhotoReducer,
    users: usersListReducer,
    userSearch: userSearchReducer,
    userSelect: userSelectReducer,
    voiceMailboxes: voiceMailboxesReducer,
    writebacksByAppt: writebacksByApptReducer,
    firebaseToken: firebaseTokenReducer,
    outageAlerts: outageAlertsReducer,
  });
