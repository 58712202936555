import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { cx } from 'emotion';
import * as styles from './patient-info.styles.js';
import { PatientsModel } from '../../../../models/patient.model';
import { PatientContactInfo } from './patient-contact-info.component';
import { UuidCopier } from '../uuid-copier';
import { PatientPrefences } from './patient-prefences.component';
import { TextLink } from '@weave/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSmsHistory, selectSmsHistory } from '../../../../redux/actions';
import { Store } from '../../../../redux/store/store.model';
import { selectHasFeatureFlagEnabled } from '../../../../redux/actions/feature-flags';

interface Props {
  person: PatientsModel;
  locationID: string;
}

export const PatientInfo = ({ person, locationID }: Props) => {
  const commxMigrationFlag = useSelector((state: Store) =>
    selectHasFeatureFlagEnabled(state, 'commx-migration')
  );
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState<string>('');

  const smsHistoryList = useSelector(selectSmsHistory);

  useEffect(() => {
    if (person.PersonID && locationID) {
      dispatch(
        fetchSmsHistory({
          limit: 1,
          skip: 0,
          location_id: locationID,
          person_ids: person.PersonID,
          commxMigrationFlag,
        })
      );
    }
  }, [person.PersonID, locationID]);

  useEffect(() => {
    if (smsHistoryList.length > 0) {
      setMobileNumber(smsHistoryList[0].personPhone.replace('+1', ''));
    }
  }, [smsHistoryList]);

  const modifiedAt = moment(person.modified_at).format('MMMM Do YYYY, h:mm:ss a');
  const createdAt = moment(person.created_at).format('MMMM Do YYYY, h:mm:ss a');
  let receiveSms = person.receive_sms ? 'Opted In' : 'Opted Out';
  if (person.receive_sms === null) {
    receiveSms = 'Preferences not enforced';
  }

  const commPreferencesUrl = `https://book2.getweave.com/${locationID}/preferences-portal/${mobileNumber}`;

  return (
    <div className={styles.flexStyle}>
      <div className={styles.infoBox}>
        <div className={cx(styles.flexStyle, styles.infoItem)}>
          <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>ID</strong>
          <p className={styles.rightP}>{person.patient_id}</p>
        </div>
        <div className={cx(styles.flexStyle, styles.infoItem)}>
          <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>Person ID</strong>
          <UuidCopier uuid={person?.PersonID} />
        </div>
        <div className={cx(styles.flexStyle, styles.infoItem)}>
          <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
            Location ID
          </strong>
          <UuidCopier uuid={person?.clientLocationID} />
        </div>
        <div className={cx(styles.flexStyle, styles.infoItem)}>
          <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
            Person Status
          </strong>
          <p className={styles.rightP}>{person.status}</p>
        </div>
        <div className={cx(styles.flexStyle, styles.infoItem)}>
          <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
            Modified At
          </strong>
          <p className={styles.rightP}>{modifiedAt}</p>
        </div>
        <div className={cx(styles.flexStyle, styles.infoItem)}>
          <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
            Created At
          </strong>
          <p className={styles.rightP}>{createdAt}</p>
        </div>
        <div className={cx(styles.flexStyle, styles.infoItem)}>
          <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
            PM Text Preference
          </strong>
          <p className={styles.rightP}>{receiveSms}</p>
        </div>
        {mobileNumber && (
          <div className={cx(styles.flexStyle, styles.infoItem)}>
            <strong className={cx(styles.infoBoxLeft, styles.rightStyle)}>
              SMS Preferences
            </strong>
            <TextLink className={styles.rightP} to={commPreferencesUrl}>
              Manage
            </TextLink>
          </div>
        )}
        <PatientPrefences personID={person?.PersonID} locationID={locationID} />
      </div>
      <div>
        {person.contactInfo && (
          <PatientContactInfo
            personID={person.PersonID}
            contactInfoList={person.contactInfo}
          />
        )}
      </div>
    </div>
  );
};
