import React from 'react';
import { SerializedStyles } from '@emotion/core';
import { Chip, useTooltip } from '@weave/design-system';
import { ProvisioningStatus } from '@weave/schema-gen-ts/dist/schemas/location/v1/provision/location_provision_service.pb';

import { chipVariantsTheme } from '../constants';

interface Props {
  status: ProvisioningStatus;
  tooltipText?: string;
}

const CHIP_CSS: Record<ProvisioningStatus, SerializedStyles> = {
  [ProvisioningStatus.PROVISION_YES]: chipVariantsTheme.success,
  [ProvisioningStatus.PROVISION_NO]: chipVariantsTheme.warn,
  [ProvisioningStatus.PROVISION_ERROR]: chipVariantsTheme.critical,
  [ProvisioningStatus.PROVISION_UNKNOWN]: chipVariantsTheme.neutral,
  [ProvisioningStatus.PROVISION_INPROGRESS]: chipVariantsTheme.primary,
  [ProvisioningStatus.PROVISION_SUBMITTED]: chipVariantsTheme.primary,
};

const STATUS_TEXT: Record<ProvisioningStatus, string> = {
  [ProvisioningStatus.PROVISION_YES]: 'Yes',
  [ProvisioningStatus.PROVISION_NO]: 'No',
  [ProvisioningStatus.PROVISION_ERROR]: 'Error',
  [ProvisioningStatus.PROVISION_UNKNOWN]: 'Unknown',
  [ProvisioningStatus.PROVISION_INPROGRESS]: 'Provisioning',
  [ProvisioningStatus.PROVISION_SUBMITTED]: 'Provisioning',
};

export const ProvisionStatusChip = ({ status, tooltipText }: Props) => {
  const { triggerProps, Tooltip, tooltipProps } = useTooltip();
  const chipCss = CHIP_CSS[status];
  const statusText = STATUS_TEXT[status];
  if (!statusText) return null;

  if (!tooltipText) {
    return <Chip css={{ '&&': chipCss }}>{statusText}</Chip>;
  }

  return (
    <span {...triggerProps}>
      <Chip css={{ '&&': chipCss }}>{statusText}</Chip>
      <Tooltip {...tooltipProps}>{tooltipText}</Tooltip>
    </span>
  );
};
