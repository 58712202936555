import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { billingApi } from './billing.api';
import { useAlert } from '@weave/alert-system';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from 'redux/actions/location';
import { AxiosResponse } from 'axios';
import {
  GetInvoiceDetailOrPdfRequest,
  GetInvoiceDetailOrPdfResponse,
} from '@weave/schema-gen-ts/dist/schemas/finance/finance_service_api.pb';

export const billingQueryKeys = {
  base: ['finance'],
  invoiceDetailOrPdf: ({
    locationId,
    invoiceId,
  }: {
    locationId?: string;
    invoiceId?: string;
  }) => [...billingQueryKeys.base, locationId, invoiceId],
};

export const useDeleteInvoiceByRecordNo = (
  options?: UseMutationOptions<AxiosResponse<any>, unknown, string, unknown>
) => {
  const alert = useAlert();
  const locationId = useSelector(selectCurrentLocationId);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: billingApi.deleteInvoice,
    mutationKey: ['delete-invoice', locationId],
    retry: false,
    ...options,
    onError: (...args) => {
      const errorMessage = 'Something went wrong while deleting invoice';
      alert.error(errorMessage);
      options?.onError?.(...args);
    },
    onSuccess: (...args) => {
      const invalidatedInvoicesAPIKey = [locationId, 'billing-invoice-list'];
      queryClient.invalidateQueries({ queryKey: invalidatedInvoicesAPIKey });
      options?.onSuccess?.(...args);
    },
  });
};

export const useGetInvoiceDetailOrPdf = (
  request: GetInvoiceDetailOrPdfRequest,
  options?: UseQueryOptions<GetInvoiceDetailOrPdfResponse>
) => {
  return useQuery({
    queryKey: billingQueryKeys.invoiceDetailOrPdf(request),
    queryFn: () => billingApi.getInvoiceDetailOrPdf(request),
    retry: false,
    ...options,
    enabled: !!(request.invoiceId && request.locationId) && (options?.enabled ?? true),
  });
};
