import fedHolidays from '@18f/us-federal-holidays';
import { getYear, format, parseISO } from 'date-fns';

const phoneNumberRegex = /^[1-9]\d{9}$/;
const alphaNumericRegex = /^[a-zA-Z0-9]*$/;

export const USMountainTZ = 'US/Mountain';

/**
 * Helper function used to get a JS Date object from a string. If a utc formatted string
 * (e.g. 2023-10-10T00:00:00Z) is passed, it will strip the time portion of it and use
 * just the date portion of it to create the new date. If not utc, then it just returns
 * the new date object.
 * The reason we strip the time portion of the utc format is because the time zone can
 * cause the day to be off by one when creating a new Date using javascript's native Date
 * object.
 *
 * @param date string
 * @returns JS Date object
 */
const getSanitizedDate = (date: string) => {
  const re = new RegExp(
    '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?(Z)?$'
  );
  const isUTCFormat = re.test(date);

  if (isUTCFormat) {
    // If it's a utc formatted string, strip the time portion of it and use just the date
    // portion of it to create the new date so that the time zone doesn't cause the day to
    // be off by one.
    return parseISO(date.substring(0, 10));
  }
  return new Date(date);
};

export const splitAndTrimString = (phoneNumbers = '', deliminator = ''): string[] =>
  phoneNumbers
    .split(deliminator)
    .filter((num) => num?.trim())
    .map((num) => num?.trim());

export const validatePhoneNumbersRegex = (phoneNumbers: string): boolean => {
  for (const number of splitAndTrimString(phoneNumbers, ',')) {
    if (!phoneNumberRegex.test(number)) {
      return false;
    }
  }
  return true;
};

export const getFormattedNumbers = (numbers: string): string =>
  numbers.replace(/\+1/g, '').replace(/[-()]/g, '').replace(/\s+/g, '');

export const isHoliday = (requestedDate: string): boolean => {
  const date = getSanitizedDate(requestedDate);
  const year = getYear(date).toString();
  return yearHolidays(year).includes(format(date, 'yyyy-MM-dd'));
};

export const yearHolidays = (year: string): string[] => {
  const start = new Date(`${year}-01-01`);
  const end = new Date(`${year}-12-31`);
  const holidays = fedHolidays.inRange(start, end);
  return holidays.map((holiday) => holiday.dateString);
};

export const isWeekend = (requestedDate: string): boolean => {
  const da = getSanitizedDate(requestedDate);
  const isSaturday = da.getDay() === 6;
  const isSunday = da.getDay() === 0;
  return isSaturday || isSunday;
};

/**
 * Helper function that takes in a date string in US format (MM/DD/YYYY) and returns
 * a date string in ISO format (YYYY/MM/DD).
 *
 * @param date A date string in US format (MM/DD/YYYY)
 * @returns string A date string in ISO format (YYYY/MM/DD)
 */
export const convertUsDateToIsoDateFormat = (date: string) => {
  if (date.length != 10) {
    console.error('incorrect date format: 1');
    return date;
  }

  const dateParts = date.split('/');
  if (dateParts.length != 3) {
    console.error('incorrect date format: 2');
    return date;
  }

  const [month, day, year] = dateParts;

  return `${year}/${month}/${day}`;
};

export const getUTCDateWithPortingTimeString = (date: string): string => {
  const parsedDate = new Date(date);

  // setting time to 15:30 UTC or 16:30 UTC because on provider side, weave provide porting time to be at 11:30 EST
  parsedDate.setHours(isDstObserved(parsedDate) ? 15 : 16);
  parsedDate.setMinutes(30);

  return format(parsedDate, `yyyy-MM-dd'T'HH:mm:ss'Z'`);
};

const stdTimezoneOffset = (date: Date) => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const isDstObserved = (date: Date) => {
  return date.getTimezoneOffset() < stdTimezoneOffset(date);
};

export const isAlphaNumericValue = (value: string): boolean =>
  alphaNumericRegex.test(value);

export const isEqualStringUnorderedArray = (
  values1: string[],
  values2: string[]
): boolean => {
  if (values1.length !== values2.length) {
    return false;
  }
  for (const val2 of values2) {
    if (!values1.includes(val2)) {
      return false;
    }
  }
  return true;
};

export const convertedString = (originalString: string): string => {
  const convertedString = originalString.replace(/([A-Z])/g, ' $1');
  const finalString = convertedString.charAt(0).toUpperCase() + convertedString.slice(1); // Replace capital letters with spaces followed by the letter
  return finalString;
};

export const convertToDateWithSpecificTime = (dateString) => {
  // Parse the input date string
  const parts = dateString.split('/');
  const month = parseInt(parts[0], 10) - 1; // Month is zero-based
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  // Create a new Date object
  // Set the time to 11:30 AM ET (Eastern Time)
  // returns date in  day month date year format
  const date = new Date(year, month, day, 16, 30);
  return date;
};
