import { css } from '@emotion/core';
import {
  Dot,
  EditIconSmall,
  FilterAltIcon,
  IconButton,
  SearchField,
  SortingValue,
  Table,
  TableColumnConfig,
  Text,
  UpdateIcon,
  useFormField,
  useModalControl,
  usePopoverDialog,
} from '@weave/design-system';
import { ToggleState } from 'components/shared/multi-state-toggle/multi-state-toggle.component';
import {
  CustomizationFlagStatesV2,
  LocationFeatureV2,
} from 'models/location-feature.model';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomizationFlagStatus } from '../components/customization-flag-status';
import { CustomizationPageTableFilters } from '../components/customization-page-table-filters';
import { useCustomizationFlagV2Store } from '../providers/customization-flag-editable-provider';

import {
  selectFeatures,
  selectInitialFeatures,
} from 'redux/actions/customization/customization.action';

import { theme } from '@weave/theme';
import { CustomizationFlagName } from '../components/customization-flag-name';
import { CustomizationFlagWarningBanner } from '../components/customization-flag-warning-banner';
import { LocationFeatureStates } from '../customization-page-v2.constant';
import {
  CustomizationFlag,
  CustomizationFlagsResponse,
  SalesforceAccountProductBundle,
} from '../customization-page-v2.types';
import {
  concatenateWithCommasAnd,
  getCustomizationFlagByBundlesRequest,
  getQuotaAmountText,
  isQuotaEnabledForFeature,
} from '../customization-page.utils';
import {
  useManageCountryCodeVerified,
  useManageProhibitedCustomizationFlags,
} from '../hooks';
import {
  useCustomizationBundleFlag,
  useCustomizationBundleFlagUpdater,
  useCustomizationProhibitedFlags,
  useIsCountryCodeVerified,
} from '../providers';
import { CustomizationOutOfSyncBadge } from '../components/customization-out-of-sync-badge';
import { selectFeatureFlag } from '../../../../redux/actions/feature-flags';
import { WamSyncFeatureFlags } from '../../../customization-page-container/customization.constant';
import { EditCustomizationQuotaModal } from '../components/edit-customization-quota-modal';

export const CustomizationPageTable = () => {
  const [columnSortingValues, setColumnSortingValues] = useState<SortingValue<string>[]>(
    []
  );
  const customizationFlagsData = useSelector(selectFeatures);
  const initialFeatures = useSelector(selectInitialFeatures);
  const showOutOfSyncAlertFF = useSelector((state) =>
    selectFeatureFlag(state, WamSyncFeatureFlags.SHOW_OUT_OF_SYNC_ALERT)
  );
  const bundleCustomizationFlags = useCustomizationBundleFlag();
  const updateBundleCustomizationFlags = useCustomizationBundleFlagUpdater();

  const popoverDialogProps = usePopoverDialog<HTMLButtonElement | HTMLAnchorElement>({
    placement: 'bottom-start',
  });
  const searchProps = useFormField({ type: 'text', value: '' });

  const isCountryCodeVerified = useIsCountryCodeVerified();
  const prohibitedCustomizationFlags = useCustomizationProhibitedFlags();

  const { manageProhibitedCustomizationFlags } = useManageProhibitedCustomizationFlags();
  const { manageCountryCodeVerified } = useManageCountryCodeVerified();

  const {
    loading,
    refetchCustomizationFlag,
    verticalId,
    getCustomizationsFlagsByBundles,
    isGetCustomizationsFlagsByBundlesLoading,
    tableData,
    setTableData,
    toggleCustomizationFlag,
    canEdit,
    updateFeatureQuota,
    featuresOutOfSyncMap,
    preventSyncedFeaturesChange,
  } = useCustomizationFlagV2Store();

  const [featureQuotaToUpdate, setFeatureQuotaToUpdate] =
    useState<LocationFeatureV2 | null>(null);

  const { modalProps: editQuotaModalProps, openModal: openEditQuotaModal } =
    useModalControl();

  const getIsProhibitedFlag = useCallback(
    (id: string) => {
      const isProhibitedFlag = !!prohibitedCustomizationFlags.find(
        (prohibitedCustomizationFlag) => prohibitedCustomizationFlag.id === id
      );
      return isProhibitedFlag && isCountryCodeVerified === false;
    },
    [prohibitedCustomizationFlags, isCountryCodeVerified]
  );

  const columns: TableColumnConfig<LocationFeatureV2>[] = useMemo(
    () => [
      {
        Header: 'Customization Flag',
        id: 'name',
        accessor: (locationFeature: LocationFeatureV2) => locationFeature,
        cellRenderer: (row: LocationFeatureV2) => (
          <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
            <CustomizationFlagName
              flagName={row.name}
              highlightCurrentFlagAsProhibited={getIsProhibitedFlag(row.id)}
              isProhibitedFlagsPresent={!!prohibitedCustomizationFlags.length}
              isDisabled={
                !canEdit ||
                (preventSyncedFeaturesChange && !featuresOutOfSyncMap[row.name])
              }
            />
            {showOutOfSyncAlertFF && featuresOutOfSyncMap[row.name] && (
              <CustomizationOutOfSyncBadge />
            )}
          </div>
        ),
        width: 500,
      },
      {
        Header: 'Status',
        id: 'flag-status',
        accessor: (locationFeature: LocationFeatureV2) => locationFeature,
        cellRenderer: (row: LocationFeatureV2) => {
          const isInitialFreeTrial = initialFeatures.find(
            (feature) => feature.id === row.id
          )?.isFreeTrial;

          const stateLabel = row.isFreeTrial
            ? CustomizationFlagStatesV2.TRIAL
            : row.state;

          const handleCustomizationToggle = (newState: ToggleState) => {
            if (row.isFreeTrial || (isInitialFreeTrial && !row.isFreeTrial)) {
              // managing state toggle for free customization flags
              // toggle allowed are Trial to Show and Show to Trial
              toggleCustomizationFlag(
                { ...row, isFreeTrial: !row.isFreeTrial },
                LocationFeatureStates[0]
              );
            } else {
              toggleCustomizationFlag(row, newState);
            }
          };

          return (
            <CustomizationFlagStatus
              currentState={row}
              label={stateLabel}
              onCustomizationToggle={(newState: ToggleState) =>
                handleCustomizationToggle(newState)
              }
              isDisabled={
                !canEdit ||
                (preventSyncedFeaturesChange && !featuresOutOfSyncMap[row.name])
              }
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Specifications',
        id: 'specification',
        accessor: (locationFeature: LocationFeatureV2) => locationFeature,
        cellRenderer: (row: LocationFeatureV2) =>
          isQuotaEnabledForFeature(row) && row.quota ? (
            <Text color="light" css={{ margin: 0 }}>
              {getQuotaAmountText(row.quota, row.featureEnum)}
            </Text>
          ) : null,
        disableSortBy: true,
        width: 150,
      },
      {
        Header: 'Actions',
        id: 'action',
        accessor: (locationFeature: LocationFeatureV2) => locationFeature,
        cellRenderer: (row: LocationFeatureV2) =>
          isQuotaEnabledForFeature(row) ? (
            <IconButton
              showLabelOnHover
              onClick={() => {
                setFeatureQuotaToUpdate(row);
                openEditQuotaModal();
              }}
              label={'Edit Specifications'}
              disabled={
                !canEdit ||
                (preventSyncedFeaturesChange && !featuresOutOfSyncMap[row.name])
              }
            >
              <EditIconSmall />
            </IconButton>
          ) : null,
        disableSortBy: true,
        width: 150,
      },
    ],
    [
      toggleCustomizationFlag,
      getIsProhibitedFlag,
      prohibitedCustomizationFlags,
      canEdit,
      featuresOutOfSyncMap,
      preventSyncedFeaturesChange,
      showOutOfSyncAlertFF,
    ]
  );

  const filterTableDataBySearch = (
    customizationFlags: LocationFeatureV2[],
    searchValue: string
  ) => {
    if (searchValue) {
      const filteredFlagsBySearch = customizationFlags.filter((customizationFlag) =>
        customizationFlag.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setTableData(filteredFlagsBySearch);
    } else {
      setTableData(customizationFlags);
    }
  };

  const getFilterTableDataByBundles = useCallback(
    (bundleCustomizationFlags: Array<CustomizationFlag | LocationFeatureV2>) => {
      return customizationFlagsData.filter((customizationFlag) =>
        bundleCustomizationFlags?.find(
          (bundleCustomizationFlag) => bundleCustomizationFlag.id === customizationFlag.id
        )
      );
    },
    [customizationFlagsData]
  );

  const getAndManageCustomizationFlagsByBundles = async (
    filterValue: SalesforceAccountProductBundle
  ) => {
    const request = getCustomizationFlagByBundlesRequest(filterValue, verticalId);

    const customizationFlagsDataByBundles: CustomizationFlagsResponse =
      await getCustomizationsFlagsByBundles(request);

    const { activeFlags, prohibitedFlags, countryCodeVerified } =
      customizationFlagsDataByBundles;

    if (activeFlags?.length) {
      const customizationFlagsByBundlesFilter = getFilterTableDataByBundles(activeFlags);
      updateBundleCustomizationFlags(customizationFlagsByBundlesFilter);
      filterTableDataBySearch(customizationFlagsByBundlesFilter, searchProps.value);
    }

    manageCountryCodeVerified(!!countryCodeVerified);
    manageProhibitedCustomizationFlags(prohibitedFlags ?? []);
  };

  const handleApplyChanges = (bundlePackageName: SalesforceAccountProductBundle) => {
    if (bundlePackageName) {
      getAndManageCustomizationFlagsByBundles(bundlePackageName);
    } else {
      setTableData(initialFeatures);
      updateBundleCustomizationFlags([]);
    }
  };

  const getWarningBannerTitle = () => {
    const prohibitedFlagsName = prohibitedCustomizationFlags.map(
      (prohibitedCustomizationFlag) => prohibitedCustomizationFlag.name ?? ''
    );
    const prohibitedFlagsToShowInBanner = concatenateWithCommasAnd(prohibitedFlagsName);
    const warningBannerText = `For Canadian locations, manually hide the ${prohibitedFlagsToShowInBanner}`;
    return warningBannerText;
  };

  const customizationFlagsTableData = useMemo<LocationFeatureV2[]>(() => {
    const { value: sortingOrderSelected } = columnSortingValues[0] ?? {};
    // if !sortingOrderSelected in the column then by default we are sorting it in ascending order
    const order = sortingOrderSelected === 'asc' || !sortingOrderSelected ? 1 : -1;
    const customizationData = [...tableData];

    // languageLocales is set to undefined because the application has no dependency on language locales
    const languageLocales = undefined;
    return customizationData.sort(
      (a, b) =>
        order *
        a.name.localeCompare(b.name, languageLocales, {
          numeric: true,
          sensitivity: 'base',
        })
    );
  }, [tableData, columnSortingValues]);

  useEffect(() => {
    if (bundleCustomizationFlags.length) {
      const customizationFlagDetailsByBundleFilter = getFilterTableDataByBundles(
        bundleCustomizationFlags
      );
      filterTableDataBySearch(customizationFlagDetailsByBundleFilter, searchProps.value);
    } else {
      filterTableDataBySearch(customizationFlagsData, searchProps.value);
    }
  }, [customizationFlagsData, searchProps.value, bundleCustomizationFlags]);

  const showWarningBanner = isCountryCodeVerified === false;
  const isProhibitedFlagsPresent =
    showWarningBanner && !!prohibitedCustomizationFlags.length;

  return (
    <div
      css={{
        overflowY: 'auto',
        maxHeight: '60vh',
      }}
    >
      <div css={customizationFlagTableContainer(isProhibitedFlagsPresent)}>
        <CustomizationFlagWarningBanner
          title={getWarningBannerTitle()}
          show={showWarningBanner}
        />
        <div css={customizationFlagTableActionContainer}>
          <div css={searchFieldStyle}>
            <SearchField name="search" width={500} {...searchProps} />
          </div>
          <IconButton
            label="Filter Customization Flag"
            {...popoverDialogProps.getTriggerProps()}
          >
            <FilterAltIcon />
            {!!bundleCustomizationFlags.length && (
              <Dot css={filterNotificationStyles} color="critical" />
            )}
          </IconButton>
          <IconButton
            label="Refresh Customization Flag"
            onClick={refetchCustomizationFlag}
          >
            <UpdateIcon />
          </IconButton>
        </div>
      </div>

      <CustomizationPageTableFilters
        popoverProps={popoverDialogProps}
        onApply={(bundleName) =>
          handleApplyChanges(bundleName as SalesforceAccountProductBundle)
        }
      />
      <Table
        isLoading={loading || isGetCustomizationsFlagsByBundlesLoading}
        colConfig={columns}
        data={customizationFlagsTableData}
        onSortChange={setColumnSortingValues}
        disableMultiSort
      ></Table>
      <EditCustomizationQuotaModal
        feature={featureQuotaToUpdate}
        onQuotaUpdate={(quota) =>
          !!featureQuotaToUpdate && updateFeatureQuota(featureQuotaToUpdate, quota)
        }
        {...editQuotaModalProps}
      />
    </div>
  );
};

const searchFieldStyle = css`
  width: 400px;
`;

const customizationFlagTableActionContainer = css`
  display: flex;
  justify-content: flex-end;
  padding: ${theme.spacing(1, 2)};
  grid-gap: ${theme.spacing(1)};
  align-items: center;
`;

const customizationFlagTableContainer = (isProhibitedFlagsPresent: boolean) => css`
  display: flex;
  justify-content: ${isProhibitedFlagsPresent ? 'space-between' : 'flex-end'};
  padding: ${theme.spacing(1, 2)};
  align-items: center;
`;

const filterNotificationStyles = css`
  top: 0;
  right: 0;
`;
