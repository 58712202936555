import React, { useState } from 'react';
import {
  FormRow,
  RadioField,
  DropdownField,
  OptionSwitchField,
  SwitchField,
  Text,
  TextareaField,
  TextField,
  useForm,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';

import { HandoffSnapshot } from '../../../../handoff-hub.types';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../../../../../redux/actions/location';
import { selectOfficeConfigurations } from '../../../../../../../redux/actions/customization/customization.action';
import { usersApi } from '../../../../../../../redux/actions/users/users.api';
import { FormComponentProps } from '../handoff-metrics/handoff.constants';
import { FooterButtons } from './footer-buttons';

export const LocationInformationForm = ({
  state,
  disableButtons,
  onBackClick,
  onSaveAndClose,
  onSubmitClick,
}: FormComponentProps) => {
  const locationId = useSelector(selectCurrentLocationId);
  const { isSoftwareOnly } = useSelector(selectOfficeConfigurations);
  const userEmailList = useQuery(
    [locationId, 'locationUsers'],
    async () => {
      return usersApi.getUsers(100);
    },
    {
      retry: false,
      refetchOnMount: 'always',
      select: (data) => data,
      onError: (err) => {
        console.error('Failed to retrieve users emails', err);
      },
    }
  );

  const { formProps, getFieldProps, isComplete, values } = useForm({
    fields: {
      pointOfContact: { type: 'dropdown', required: true, value: state.pointOfContact },
      reasonForPurchase: { type: 'text', required: true, value: state.reasonForPurchase },
      csmAccountManagerOverview: {
        type: 'optionswitch',
        required: true,
        value: state.isCsmCallScheduled,
      },
      csmCallNotes: {
        type: 'text',
        required: true,
        value: state.csmCallNotes,
      },
      disclaimerTypeSent: {
        type: 'dropdown',
        value: state.disclaimerTypeSent,
      },
      routerMakeAndModel: {
        type: 'text',
        value: state.routerMakeAndModel,
      },
      networkDecision: { type: 'text', value: state.networkDecision },
      billingNotes: { type: 'text', value: state.billingNotes, required: true },
      officeExperience: { type: 'text', value: state.officeExperience, required: true },
    },
    onSubmit: () => {
      onSubmitClick(getHandoffData());
    },
  });

  const getHandoffData = (): Partial<HandoffSnapshot> => ({
    pointOfContact: values.pointOfContact ?? '',
    csmCallNotes: values.csmCallNotes ?? '',
    isCsmCallScheduled: values.csmAccountManagerOverview ?? '',
    reasonForPurchase: values.reasonForPurchase ?? '',
    disclaimerTypeSent: values.disclaimerTypeSent ?? '',
    networkDecision: values.networkDecision ?? '',
    routerMakeAndModel: values.routerMakeAndModel ?? '',
    officeExperience: values.officeExperience ?? '',
    billingNotes: values.billingNotes ?? '',
  });

  const handleBackButtonClick = () => {
    onBackClick(getHandoffData());
  };

  const handleSaveAndCloseClick = () => {
    onSaveAndClose(getHandoffData());
  };

  const disableSubmit = !isComplete;

  return (
    <>
      <form {...formProps}>
        <div>
          <div>
            <Text css={titles} weight="bold">
              Location Information
            </Text>
            <Text color="light">
              Fill out the information below to add to your handoff note
            </Text>
          </div>

          <div css={formContainer}>
            <Text css={titles} weight="bold">
              Office Information
            </Text>
            {!userEmailList?.data?.length && (
              <p css={noPointOfContactStyle}>Please add a user to this location</p>
            )}
            <FormRow>
              <DropdownField
                {...getFieldProps('pointOfContact')}
                label="Point of Contact"
                disabled={!userEmailList?.data?.length}
              >
                {userEmailList?.data?.map((userData, index) => (
                  <DropdownField.Option value={userData.Username} key={index}>
                    {userData.Username}
                  </DropdownField.Option>
                ))}
              </DropdownField>
            </FormRow>
            <p css={routerTypeStyle}>
              NOTE: CSAT survey will be sent to this email if doing an onboarding handoff.
            </p>
            <FormRow>
              <TextField
                {...getFieldProps('reasonForPurchase')}
                label="Reason for Purchase"
                placeholder="i.e. appt reminders, reviews, etc."
              />
            </FormRow>
            <p css={routerTypeStyle}>i.e. appt reminders, reviews, etc.</p>

            <FormRow>
              <OptionSwitchField
                {...getFieldProps('csmAccountManagerOverview')}
                label="CSM Optimization Call"
              >
                <OptionSwitchField.Option value="Yes">Yes</OptionSwitchField.Option>
                <OptionSwitchField.Option value="No">No</OptionSwitchField.Option>
              </OptionSwitchField>
            </FormRow>
            <FormRow>
              <TextareaField
                {...getFieldProps('csmCallNotes')}
                label="CSM Optimization Call Notes"
              />
            </FormRow>

            {!isSoftwareOnly && (
              <>
                <Text css={titles} weight="bold">
                  Network Health Information
                </Text>
                <FormRow>
                  <DropdownField
                    {...getFieldProps('disclaimerTypeSent')}
                    label="Disclaimer Type Sent"
                  >
                    <DropdownField.Option value="Phone Conversation with It">
                      Phone Conversation with It
                    </DropdownField.Option>
                    <DropdownField.Option value="Talked with the office">
                      Talked with the office
                    </DropdownField.Option>
                    <DropdownField.Option value="Sent Email">
                      Sent Email
                    </DropdownField.Option>
                  </DropdownField>
                </FormRow>
                <FormRow>
                  <TextField
                    {...getFieldProps('routerMakeAndModel')}
                    label="Router Make and Model"
                  />
                </FormRow>
                <p css={routerTypeStyle} color="disabled">
                  i.e. Sonicwall, Netgear, Comcast
                </p>
                <Text>Explain the office's network setup below:</Text>
                <FormRow>
                  <TextareaField
                    {...getFieldProps('networkDecision')}
                    label="Placeholder text"
                  />
                </FormRow>
              </>
            )}
            <Text css={titles} weight="bold">
              Office Experience
            </Text>
            <Text>Billing notes:</Text>
            <FormRow>
              <TextareaField
                {...getFieldProps('billingNotes')}
                label="Placeholder text"
              />
            </FormRow>
            <Text>Please include any helpful notes and details below</Text>
            <FormRow>
              <TextareaField
                {...getFieldProps('officeExperience')}
                label="Placeholder text"
              />
            </FormRow>
          </div>
        </div>

        <FooterButtons
          disableSubmitButton={disableSubmit}
          disableMutateButtons={disableButtons}
          onBackButtonClick={handleBackButtonClick}
          onSaveAndCloseClick={handleSaveAndCloseClick}
        />
      </form>
    </>
  );
};

export const titles = css`
  font-size: ${theme.fontSize(16)};
`;

export const formContainer = css`
  padding-left: ${theme.spacing(1)};
`;

const routerTypeStyle = css`
  color: ${theme.colors.gray400};
  font-size: ${theme.fontSize(12)};
  margin: ${theme.spacing(-3, 0, 3, 0)};
`;

const noPointOfContactStyle = css`
  color: ${theme.colors.strawberry};
`;
