import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

/**
 * Formats a timestamp (sec) or date string
 * @param date Timestamp in sec or Date string
 */
export const formatDate = (
  date?: string | number | Date | Dayjs,
  formattedDate?: string,
  useOnlyUTC = false
): string => {
  if (!date) return '';

  let dateObj = typeof date === 'number' ? dayjs.unix(date) : dayjs(date);
  if (isNaN(dateObj.toDate().getTime())) return '';
  // adding this check to ignore DB default date (0001-01-01T00:00:00Z)
  if (dateObj.year() < 1900) return '';

  if (useOnlyUTC) dateObj = dateObj.utc();

  return dateObj.format(formattedDate);
};
