import _ from 'lodash';
import { Mailbox } from './voice-mailboxes.types';
import { CustomAxios, getResponseData } from '../../redux/axios';
import { useQueryWithSetter } from '../helpers/useQueryWithSetter';
import { QueryOptions } from 'react-query';
import {
  ListGreetingsRequest,
  ListGreetingsResponse,
} from '@weave/schema-gen-ts/dist/schemas/phone/voicemail-config/v2/voicemail_config_service.pb';
import { MailboxGreeting } from '@weave/schema-gen-ts/dist/schemas/phone/voicemail-config/v2/voicemail_config.pb';

export const voicemailBoxApi = {
  listMailboxes: (): Promise<Mailbox[]> =>
    CustomAxios.get(`support/v1/voicemail/mailboxes`).then(getResponseData),
  getMailbox: (id: string): Promise<Mailbox> =>
    CustomAxios.get(`support/v1/voicemail/mailboxes`)
      .then(getResponseData)
      .then((data) => data.find((mb: Mailbox) => mb.mailboxID === id)),
  updateMailbox: (id: string, mailbox: Partial<Mailbox>): Promise<Mailbox> =>
    CustomAxios.put(`support/v1/voicemail/mailbox/${id}`, mailbox).then(getResponseData),
  getMailboxGreetings: ({ mailboxId }: ListGreetingsRequest) => {
    return CustomAxios.get<ListGreetingsResponse>(
      `/phone/voicemail-config/v2/${mailboxId}/greetings`
    ).then((res) => {
      return res.data.greetings ?? [];
    });
  },
  getGreetingsForAllMailboxes: (): Promise<MailboxGreeting[]> => {
    return voicemailBoxApi.listMailboxes().then((mailboxes) => {
      return Promise.all(
        mailboxes.map(({ mailboxID }) =>
          voicemailBoxApi.getMailboxGreetings({ mailboxId: mailboxID })
        )
      ).then((mailboxGreetings) => {
        return mailboxGreetings.reduce((p, c) => [...p, ...c]);
      });
    });
  },
};

export const useQueryMailboxes = (options: QueryOptions<Mailbox[]> = {}) => {
  return useQueryWithSetter<Mailbox[]>(
    ['queryMailboxes'],
    voicemailBoxApi.listMailboxes,
    options
  );
};

export const useQueryGetMailboxGreetings = (
  mailboxId: string,
  options: QueryOptions<MailboxGreeting[]> = {}
) => {
  return useQueryWithSetter<MailboxGreeting[]>(
    ['mailboxGreetings', mailboxId],
    () => voicemailBoxApi.getMailboxGreetings({ mailboxId }),
    options
  );
};
