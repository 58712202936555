import React, { useState } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { Heading, Tabs } from '@weave/design-system';
import { EditSearch } from './edit-search';

const container = css`
  width: 100vw;
  height: calc(100vh - 52px);
  padding: ${theme.spacing(4)};
`;

export const UniversalSearch = () => {
  const [activeTab, setActiveTab] = useState<'desktop' | 'portal' | 'wam'>('desktop');

  return (
    <div css={container}>
      <div>
        <Heading level={2}>Universal Search</Heading>
        <Tabs
          initialTab="desktop"
          onChange={(id) => setActiveTab(id as typeof activeTab)}
        >
          <Tabs.Bar>
            <Tabs.Tab id="desktop" controls="desktop-panel">
              Desktop
            </Tabs.Tab>
            <Tabs.Tab id="portal" controls="portal-panel">
              Portal
            </Tabs.Tab>
            <Tabs.Tab id="wam" controls="wam-panel">
              WAM
            </Tabs.Tab>
          </Tabs.Bar>
          <EditSearch type={activeTab} />
        </Tabs>
      </div>
    </div>
  );
};
