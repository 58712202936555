import type { ListRelationalPhoneNumber } from '@weave/schema-gen-ts/dist/schemas/phone/number-service/v2/number_service.pb';
import type { UsageType } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/phone_number.pb';

export type {
  ListPhoneNumbersResponse,
  ListPhoneNumbersRequest,
} from '@weave/schema-gen-ts/dist/schemas/phone/number-service/v2/number_service.pb';

export interface ExtendedRelationalPhoneNumber extends ListRelationalPhoneNumber {
  formattedPhoneNumber: string;
  phoneNumber: string;
  type: NumberType;
  usageType: UsageType;
}

export type PortingRequest = {
  phone_number: string;
  port_type: 0;
  number_type: NumberType;
  requested_firm_order_commitment_date: string;
  id?: string;
  accepted_date?: string;
  carrier_history?: string;
  carrier_notes?: string;
  carrier_provisioned_date?: string;
  carrier_provisioned_date_set_by?: string;
  cnam_provisioned_by?: string;
  cnam_provisioned_date?: string;
  created_at?: string;
  desired_cnam?: string;
  emergency_desired?: string;
  emergency_provisioned_by?: string;
  emergency_provisioned_date?: string;
  firm_order_commitment_date?: string;
  pending_by?: string;
  pending_date?: string;
  phone_data_service_provisioned_date?: string;
  phone_data_service_provisioned_date_set_by?: string;
  port_order_number?: string;
  port_status?: string;
  porting_carrier?: string;
  porting_data_id?: string;
  porting_rejections?: null;
  porting_status?: number;
  porting_status_updated_at?: string;
  salesforce_batch_id?: string;
  submitted_by?: string;
  submitted_date?: string;
  updated_at?: string;
};

export enum NumberType {
  UNKNOWN = 0,
  FAX = 1,
  SMS = 2,
  PHONE = 3,
}

export enum MediaType {
  LOA = 'loa',
  phoneBill = 'phone_bill',
}

export enum RequestClient {
  WAM = 'WAM',
  adminPortal = 'admin-portal',
  desktop = 'desktop',
  mobile = 'mobile',
  dashboard = 'DASHBOARD',
}

export type MediaData = {
  id?: string;
  media_id: string;
  file_name: string;
  media_type: MediaType;
  porting_data_id?: string;
  created_at?: string;
  updated_at?: string;
  provider_document_id?: string;
};

export type PortingData = {
  authorized_user_first_name: string;
  authorized_user_last_name: string;
  service_street1: string;
  service_city: string;
  service_state: string;
  service_country: string;
  service_zip: string;
  company_name: string;
  phone_service_account_number: string;
  porting_requests: PortingRequest[];
  billing_phone_number: string;
  current_phone_service_provider: string;
  office_email: string;
  request_client: string;
  account_pin: string;
  request_asap_port: boolean;
  customer_phone_bill_media: MediaData[];
  no_bill_available: boolean;
  reason_bill_not_available: string;
  port_type: number;
};

export type PortGetData = PortingData & {
  agreement_id: string;
  created_at: string;
  created_by: string;
  current_account_type: string;
  customer_phone_bill_media_id: null;
  customer_phone_bill_media_type: null;
  desired_carrier: null;
  emergency_city: string;
  emergency_country: string;
  emergency_state: string;
  emergency_street1: string;
  emergency_street2: string;
  emergency_zip: string;
  id: string;
  location_id: string;
  other_services_using_phone_lines: string;
  phone_and_internet_bundled: string;
  port_order_number: string;
  port_order_url: string;
  provider: number;
  request_client: RequestClient;
  salesforce_port_order_name: string;
  service_house_number: string;
  service_street2: string;
  sip_peer_id: string;
  updated_at: string;
};

export type PortCreationFileResponseData = {
  ID: string;
  Type: MediaType;
  LocationID: string;
  Name: string;
  Path: string;
  MediaLocation: string;
  UserID: string;
  DeletedAt: string;
  PublicURL: string;
  CreatedAt: string;
  EscapedURL: string;
  SecureURL: string;
};

export type PortViewFileUploadResponse = {
  media_data: MediaData;
};

export type PhoneBillDownloadPayload = {
  customer_phone_bill_media_id: string;
  customer_phone_bill_media_type: MediaType;
  location_id: string;
};

export type UploadLOAMediaType = {
  porting_data_id: string;
  media_id: string;
  file_name: string;
  media_type: string;
};

export type UploadLOAPayloadType = {
  content_type: string;
  media_data: UploadLOAMediaType;
};

export type ValidationData = {
  id: string;
  active: boolean;
  name: string;
  error_code: number;
  reason: string;
};

export type ManagePhoneNumberResponse = {
  portingRequests: PortingRequest[];
};

export type NumberProvider = {
  provider: string;
};

export type InternalPortingNumberData = {
  E911Provider: NumberProvider;
  FaxProvider: NumberProvider;
  ID: string;
  InstructionSetID: string;
  IsFax: boolean;
  Labels: [];
  Name: string;
  Number: string;
  Provider: string;
  Ringtone: string;
  SMSProvider: NumberProvider;
  TenantID: string;
  VoiceProvider: NumberProvider;
};

export enum InternalNumberPortingType {
  PORTING_TYPE_UNKNOWN = 0,
  PORTING_TYPE_INTERNAL = 1,
  PORTING_TYPE_MAIN = 2,
}

export type FOCDateResponse = {
  status: string;
  requested_foc_date: string;
  auth_person_name: string;
  actual_foc_date?: string;
  service_address: object;
  billing_phone_number: string;
  provider_port_order_reference: string;
  account_id: string;
  losing_carrier_name: string;
  order_created_date: string;
  order_modified_date: string;
  port_type: number;
  port_order_number: string;
};
export type LocationData = {
  Active: boolean;
  LocationID: string;
  ParentID: string;
  PhoneTenantID: string;
  Name: string;
  Slug: string;
  Type: number;
  Vertical: number;
  VerticalID: number;
  VerticalTextID: number;
};

export type e911TenantData = {
  id: string;
  slug: string;
  name: string;
  createdAt: string;
  isShared?: boolean;
  locationId: string;
  tenant_dashboard?: E911TenantDashboard;
};

export type E911TenantDashboard = {
  tenantId?: string;
  attempts?: string;
  muted?: boolean;
  active?: boolean;
  createdAt?: string;
  updatedAt?: string;
};
export type e911NotesData = {
  id: string;
  note: string;
  tenantId: string;
  addedBy: string;
  createdAt: string;
};
export type e911Adrress = {
  id: string;
  tenantId: string;
  updatedAt: string;
  createdAt: string;
};

export type e911DashboardAddress = {
  city: string;
  country: string;
  name: string;
  postalCode: string;
  state: string;
  street: string;
  streetSecondary: string;
};

export type phoneNumberDetail = {
  countryCode: number;
  nationalNumber: string;
};

export type e911PhoneNumberDetials = {
  phoneNumber?: phoneNumberDetail;
  isNewNumber?: string;
};

export type e911AdressProvisionResponse = {
  phoneNumberDetail: e911PhoneNumberDetials;
};

export type e911RelAdrress = e911DashboardAddress & {
  id: string;
  tenantId: string;
  updatedAt: string;
};

export type e911ProviderDetails = {
  addressId: string;
  callGroupId: string;
  createdAt: string;
  e911Provider: string;
  id: string;
  phoneNumberId: string;
  updatedAt: string;
};

export type e911RelationalAddresses = {
  address: e911FullAddressInfo;
  e911Providers?: e911ProviderDetails;
};
export type retryAddressDetails = {
  relationalAddresses: e911RelationalAddresses;
};
export type e911FullAddressInfo = e911Adrress & e911DashboardAddress;

export type e911AddressToBeSend = {
  city: string;
  country: string;
  name: string;
  postal_code: string;
  state: string;
  street: string;
  street_secondary: string;
};
export type childLocationDetails = {
  Active: boolean;
  LocationID: string;
  Name: string;
  ParentID: string;
  PhoneTenantID: string;
  Slug: string;
  Type: number;
  Vertical: number;
  VerticalID: number;
  VerticalTextID: number;
};

export type signedDetailsType = {
  signDate: string;
  signedName: string;
};

export interface Address {
  shippingCity: string;
  shippingCountry: string;
  shippingCountryCode: string;
  shippingPostalCode: string;
  shippingState: string;
  shippingStreet: string;
  shippingStreet2: string;
  shippingStateCode: string;
  verified?: boolean;
}

export interface LoaDetails {
  companyName: string;
  billingTelephoneNumber: string;
  currentCarrier: string;
  serviceAddress: Address;
  billingAddress: Address;
  mainPhoneNumber: string;
  mainFaxNumber: string;
  otherPhoneNumbers: string[];
  otherFaxNumbers: string[];
  name?: string;
  signedDate?: string;
}

export type loaStatus = {
  adminEmail: string;
  createdAt: string;
  expirationDate: string;
  firstViewedAt: string;
  lastViewedAt: string;
  loaLinkGenerated: boolean;
  loaUrl: string;
  portingStatusWhenGenerated: string;
  signedDetails: null | signedDetailsType;
  documentMetadata?: {
    mediaId: string;
    finalData: LoaDetails;
    initialData: LoaDetails;
  };
};

export type userData = {
  FirstName: string;
  LastName: string;
  Type: string;
  UserID: string;
  Username: string;
};

export type renderItemValueType = {
  value:
    | string
    | boolean
    | null
    | signedDetailsType
    | { mediaId: string; finalData: LoaDetails; initialData: LoaDetails };
};

export type loaURL = {
  loaUrl: string;
};

export type {
  CreateSalesforcePortOrderRequest,
  CreateSalesforcePortOrderResponse,
  CancelPortInOrderRequest,
  ClonePortingDataRequest,
  ClonePortingDataResponse,
  DeletePortOrderDocumentRequest,
  DeletePortOrderDocumentResponse,
  GetAvailableFOCDatesRequest,
  GetAvailableFOCDatesResponse,
  GetPortingValidationsByPortingDataIDRequest,
  GetPortingValidationsByPortingDataIDResponse,
  UpdatePortOrderDocumentRequest,
  UpdatePortOrderDocumentResponse,
  UploadPortOrderDocumentRequest,
  UploadPortOrderDocumentResponse,
} from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';

export { MediaType as PortingMediaType } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';
