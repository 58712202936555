import { useAlert } from '@weave/alert-system';
import fileDownload from 'js-file-download';
import { useState } from 'react';

import { portViewLoaDownload } from '../../../apis/porting/porting.api';

export const useDownloadLoa = () => {
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();

  const downloadLoa = async (portOrderId: string, filename = 'loa.pdf') => {
    setIsLoading(true);

    try {
      const response = await portViewLoaDownload(portOrderId);
      const file = response.data;
      fileDownload(file, filename, 'application/pdf');
    } catch (err) {
      alert.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    downloadLoa,
    isLoading,
  };
};
