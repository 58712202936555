import { css } from '@emotion/core';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';

export const portOrderStyles: any = {};

portOrderStyles.portingHubText = () => css`
  padding-bottom: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #8c9496;
  font-weight: 300;
`;
portOrderStyles.loading = () => css`
  display: flex;
  justify-content: center;
`;
portOrderStyles.backButtonStyle = () => css`
  margin-bottom: 10px;
  width: 60px;
  &:hover {
    cursor: pointer;
  }
`;

portOrderStyles.errorMessage = () => css`
  color: ${weaveTheme.colors.error};
`;
portOrderStyles.container = () => css`
  margin-right: 30px;
  border-radius: 10px;
  border: 1px solid #dee2e3;
  margin-bottom: 20px;
`;
portOrderStyles.backgroundStyle = () => css`
  background-color: #f6f7f7;
  padding: 15px 25px;
  border-bottom: 1px solid #dee2e3;
  display: flex;
  justify-content: space-between;
`;
portOrderStyles.modalStyle = () => css`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;
portOrderStyles.portingInfoContainer = () => css`
  margin-left: 3px;
  margin-right: 9px;
`;
portOrderStyles.title = () => css`
  font-weight: bold;
  font-size: 16px;
`;

portOrderStyles.portingNumbers = () => css`
  border: 1px solid #b9c0c2;
  border-radius: 10px;
  padding: 25px;
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 16px;
  color: #272b2c;
`;

portOrderStyles.submitButtonStyle = () => css`
  margin: 10px auto;
  min-width: 200px;
  max-width: 300px;
`;

portOrderStyles.buttonContainers = () => css`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

portOrderStyles.streetContainer = () => css`
  color: ${weaveTheme.colors.gray200};
  display: flex;
  width: 500px;
  justify-content: space-between;
  margin-bottom: 5px;
`;

portOrderStyles.buttonContainersColor = () => css`
  background-color: ${weaveTheme.colors.gray100};
`;

portOrderStyles.errorStyle = () => css`
  margin-left: ${weaveTheme.spacing(31)};
  margin-top: ${weaveTheme.spacing(-3)};
  margin-bottom: ${weaveTheme.spacing(3)};
`;
