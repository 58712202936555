import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BillingRoutes } from './billing-routes';
import { selectHasBillingReadPermission } from './billing.helpers';
import { history } from '../../../redux/store/history';

export const BillingPage = () => {
  const hasBillingReadPermission = useSelector(selectHasBillingReadPermission);

  useEffect(() => {
    if (!hasBillingReadPermission) {
      history.replace(`/`);
    }
  }, [hasBillingReadPermission]);

  return <BillingRoutes />;
};
