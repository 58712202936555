import React, { useState } from 'react';
import { theme } from '@weave/theme';
import { WeaveTheme } from '@weave/theme-original';
import { PrimaryButton, SearchField, Text, useFormField } from '@weave/design-system';

import { css } from '@emotion/core';
import { FeatureFlagsEdit } from './feature-flags-edit.component';
import FeatureFlagModel from '../feature-flag.model';
import { OnOffIndicator } from '../../../shared/on-off-indicator/on-off-indicator.component';
import { SaveFeatureFlagModel } from '../../../../redux/actions/feature-flags';
import { Page } from '../../../shared';
import { FeatureFlagsEvents } from './feature-flag-events.component';

interface Props {
  canCreateFeatureFlags: boolean;
  canEditFeatureFlags: boolean;
  featureFlags: FeatureFlagModel[];
  locationId: string;
  saveFeatureFlags: (m: SaveFeatureFlagModel) => void;
}

export const FeatureFlags = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState('');

  const featureFlagSearchProps = useFormField({
    type: 'text',
    value: '',
  });

  const openEditModal = () => {
    setModalOpen(true);
  };

  const closeEditModal = (save = false, flagsToSave: FeatureFlagModel[]) => {
    if (props.canEditFeatureFlags && save) {
      props.saveFeatureFlags({
        updatedFlags: flagsToSave,
      });
    }

    setModalOpen(false);
  };

  const filteredFeatureFlags = featureFlagSearchProps.value
    ? props.featureFlags.filter(
        (flag) =>
          flag.DisplayName.toLowerCase().includes(
            featureFlagSearchProps.value.toLowerCase()
          ) ||
          flag.Name.toLowerCase().includes(featureFlagSearchProps.value.toLowerCase())
      )
    : props.featureFlags;

  return (
    <Page
      title="Feature Flags"
      headerActions={
        <div
          css={(theme: WeaveTheme) => css`
            align-items: center;
            display: flex;

            & button:not(:last-of-type) {
              margin-right: ${theme.spacing(1)};
            }
          `}
        >
          {props.canEditFeatureFlags && (
            <PrimaryButton onClick={openEditModal}>Edit</PrimaryButton>
          )}
        </div>
      }
    >
      <SearchField
        label="Search feature flags"
        name="featureFlagSearch"
        {...featureFlagSearchProps}
      />
      <div
        css={css`
          position: relative;
        `}
      >
        {filteredFeatureFlags.map((flag) => {
          return (
            <div
              key={flag.Name}
              css={css`
                border-bottom: 1px solid #f1f2f2;
                cursor: pointer;
              `}
              onClick={() => {
                setSelectedFlag(flag.Name);
              }}
            >
              <div
                css={{
                  padding: theme.spacing(2),
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <OnOffIndicator on={flag.Value} />
                <Text css={{ margin: `0 0 0 ${theme.spacing(2)}` }}>
                  {flag.DisplayName} [{flag.Name}]
                </Text>
              </div>
              {flag.Name === selectedFlag && (
                <FeatureFlagsEvents flagName={flag.Name} locationId={props.locationId} />
              )}
            </div>
          );
        })}
        {featureFlagSearchProps.value !== '' && filteredFeatureFlags.length === 0 && (
          <Text
            css={(theme: WeaveTheme) => css`
              margin-top: ${theme.spacing(1)};
            `}
          >
            No feature flags found for "{featureFlagSearchProps.value}"
          </Text>
        )}
      </div>
      <FeatureFlagsEdit
        modalOpen={modalOpen}
        handleCloseModal={closeEditModal}
        featureFlags={props.featureFlags}
      />
    </Page>
  );
};
