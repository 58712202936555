import { css } from '@emotion/core';
import { AlertIcon, ConfirmBadgeIcon, ErrorBadgeIcon } from '@weave/design-system';
import { theme } from '@weave/theme-original';

import { MetricResult, Result } from '../handoff-metrics/handoff-metrics.types';

type MetricResultsTableProps = {
  metricResults: MetricResult[];
};

export const MetricResultsTable = ({ metricResults }: MetricResultsTableProps) => {
  return (
    <table
      css={css`
        width: 100%;
        td {
          padding: 10px 8px;
        }
      `}
    >
      <thead
        css={css`
          border-bottom: 1px solid ${theme.colors.gray300};
          color: ${theme.colors.gray500};
          th {
            font-weight: 400;
          }
        `}
      >
        <tr>
          <th></th>
          <th>Test Criteria</th>
          <th>Actual</th>
        </tr>
      </thead>
      <tbody>
        {metricResults.map((metricResults, index) => (
          <tr key={index}>
            <td>
              {metricResults.result === Result.Pass ? (
                <ConfirmBadgeIcon
                  css={css`
                    color: ${theme.colors.success};
                  `}
                />
              ) : metricResults.result === Result.Fail ? (
                <ErrorBadgeIcon
                  css={css`
                    color: ${theme.colors.error};
                  `}
                />
              ) : (
                <AlertIcon
                  css={css`
                    color: ${theme.colors.warning};
                  `}
                />
              )}
            </td>
            <td>{metricResults.testCriteria}</td>
            <td>{metricResults.actualValue}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
