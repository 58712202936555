import React from 'react';
import { findIndex } from 'lodash';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';
import { Text, useHoverLabel } from '@weave/design-system';
import { LocationFeatureStates } from '../customization-page-v2.constant';
import {
  CustomizationFlagStatesV2,
  LocationFeatureV2,
} from 'models/location-feature.model';
import { ToggleState } from 'components/shared/multi-state-toggle/multi-state-toggle.component';
import {
  getCustomizationFlagStateBackgroundColor,
  getCustomizationFlagStateColor,
  getCustomizationFlagLabelByState,
} from '../customization-page.utils';
import { customizationPageTestIds } from '../customization-page-test-ids';

interface Props {
  label: CustomizationFlagStatesV2;
  onCustomizationToggle: (newState: ToggleState) => void;
  currentState: LocationFeatureV2;
  isDisabled?: boolean;
}

export const CustomizationFlagStatus = ({
  label,
  onCustomizationToggle,
  currentState,
  isDisabled = false,
}: Props) => {
  const {
    labelProps,
    triggerProps: hoverTriggerProps,
    HoverLabel,
  } = useHoverLabel({
    placement: 'left',
  });
  const getCurrentState = (feature: LocationFeatureV2) => {
    return (
      LocationFeatureStates.find((state) => state.key === feature.state) ||
      LocationFeatureStates[0]
    );
  };

  const toggleState = () => {
    if (!isDisabled) {
      const currentStateFeature = getCurrentState(currentState);

      let index = findIndex(
        LocationFeatureStates,
        (state) => state.key === currentStateFeature.key
      );

      index = index < LocationFeatureStates.length - 1 ? index + 1 : 0;
      onCustomizationToggle(LocationFeatureStates[index]);
    }
  };

  return (
    <>
      <div
        css={[stateStyles(isDisabled), getCustomizationFlagStateBackgroundColor(label)]}
        onClick={toggleState}
        {...hoverTriggerProps}
      >
        <Text
          size="medium"
          color={getCustomizationFlagStateColor(label)}
          css={textStyles}
          data-testid={customizationPageTestIds.customizationFlagStatus(label)}
        >
          {getCustomizationFlagLabelByState(label)}
        </Text>
      </div>
    </>
  );
};

const stateStyles = (isDisabled: boolean) => css`
  text-align: center;
  width: 80px;
  border-radius: ${theme.borderRadius.small};
  opacity: ${isDisabled ? 0.5 : 1};
  ${!isDisabled && 'cursor: pointer;'}
`;

const textStyles = css`
  padding: ${theme.spacing(1, 1.5)};
  margin: 0;
`;
