import React, { useState } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  ContentLoader,
  IconButton,
  PrintIcon,
  TrashIcon,
  useModalControl,
} from '@weave/design-system';
import { startCase } from 'lodash/fp';
import { ExtendedBillingInvoice } from '../billing.types';

import { formatNumber } from '../billing.helpers';
import { useSelector } from 'react-redux';
import { selectHasWeaveAcl } from 'redux/actions/auth/auth.selectors';
import { CoreACLs } from 'redux/actions/auth/auth.types';
import { Store } from 'redux/store/store.model';
import { DeleteInvoiceModal } from './delete-invoice-modal';
import { useDeleteInvoiceByRecordNo, useGetInvoiceDetailOrPdf } from '../billing-queries';
import { useAlert } from '@weave/alert-system';
import { InvoiceDetailsPrint } from '../invoice-details-page/invoice-details-print';

type Props = {
  invoices: ExtendedBillingInvoice[];
  locationId: string;
};

export const InvoiceHistoryTable = ({ locationId, invoices }: Props) => {
  const [selectedInvoiceRecordToDelete, setSelectedInvoiceRecordToDelete] =
    useState<string>('');
  const [selectedInvoiceIdForDownload, setSelectedInvoiceIdForDownload] = useState('');

  const alert = useAlert();
  const { triggerProps, modalProps } = useModalControl();
  const hasIntactInvoiceDeleteACL = useSelector((state: Store) =>
    selectHasWeaveAcl(state, CoreACLs.INTACCT_INVOICE_DELETE)
  );

  const { mutateAsync: deleteInvoiceByRecordNo, isLoading: isDeletingInvoice } =
    useDeleteInvoiceByRecordNo({
      onSuccess: () => {
        modalProps.onClose();
      },
    });

  const { data: invoiceDetailsOrPdf, isLoading: isInvoiceLoading } =
    useGetInvoiceDetailOrPdf(
      { locationId, invoiceId: selectedInvoiceIdForDownload },
      {
        onError: () => {
          alert.error('Error fetching invoice details.');
          setSelectedInvoiceIdForDownload('');
        },
      }
    );

  const handleDeleteInvoice = () => {
    !!selectedInvoiceRecordToDelete &&
      deleteInvoiceByRecordNo(selectedInvoiceRecordToDelete);
  };

  return (
    <div css={{ position: 'relative' }}>
      <ContentLoader show={isInvoiceLoading} />
      <table css={[sharedTableStyles, mainTableStyles]}>
        <thead>
          <tr>
            <th>Invoice Date</th>
            <th>Invoice Name</th>
            <th>Status</th>
            <th>Invoice Amount</th>
            <th>Credits Applied</th>
            <th>Payments Applied</th>
            <th>Balance</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(invoices ?? []).map((invoice) => (
            <React.Fragment key={invoice.recordNo + '-fragment'}>
              <tr
                key={invoice.recordNo}
                css={mainTableRow}
                data-test-id={`invoice-${invoice.id}`}
              >
                <td>{invoice.dueDate}</td>
                <td>{invoice.id}</td>
                <td>
                  <p css={invoiceStatusStyles(invoice.status)}>
                    {startCase(invoice.status)}
                  </p>
                </td>
                <td>{formatNumber(invoice.amount, invoice.currency)}</td>
                <td>{formatNumber(+(invoice.totalCredits ?? 0), invoice.currency)}</td>
                <td>{formatNumber(+(invoice.amountPaid ?? 0), invoice.currency)}</td>
                <td>{formatNumber(+(invoice.amountDue ?? 0), invoice.currency)}</td>
                <td>
                  <div css={invoiceActionsContainerStyles}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedInvoiceIdForDownload(invoice.id);
                      }}
                      label="Print Invoice"
                      showLabelOnHover={true}
                    >
                      <PrintIcon />
                    </IconButton>

                    {hasIntactInvoiceDeleteACL && (
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          setSelectedInvoiceRecordToDelete(invoice.recordNo);
                          triggerProps.onClick();
                        }}
                        label="Delete Invoice"
                        showLabelOnHover={true}
                        data-test-id={`${invoice.id}-delete-btn`}
                      >
                        <TrashIcon />
                      </IconButton>
                    )}
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <DeleteInvoiceModal
        modalProps={modalProps}
        onDeleteInvoice={handleDeleteInvoice}
        isLoading={isDeletingInvoice}
      />
      {!isInvoiceLoading && (
        <InvoiceDetailsPrint
          data={invoiceDetailsOrPdf}
          invoiceId={selectedInvoiceIdForDownload}
          showPrintDialog={!!selectedInvoiceIdForDownload}
          onPrintDialogClose={() => setSelectedInvoiceIdForDownload('')}
        />
      )}
    </div>
  );
};

const sharedTableStyles = css`
  width: 100%;

  th,
  td {
    text-align: left;
    padding: ${theme.spacing(2, 0)};
  }
`;

const horizontalLineStyles = css`
  content: '';
  border-bottom: 1px solid ${theme.colors.gray300};
  position: absolute;
  display: block;
  transform: translateX(-100%);
  bottom: 0;
  width: calc(100% - 36px);
  left: calc(100% - 18px);
`;

const mainTableStyles = css`
  & thead {
    position: relative;
  }
  & > thead::after {
    ${horizontalLineStyles}
  }

  & > thead th {
    font-size: ${theme.fontSize(14)};
    color: ${theme.colors.gray500};
    font-weight: initial;
  }

  --billing-table-side-padding: ${theme.spacing(3)};
  & > thead th:first-child {
    padding-left: var(--billing-table-side-padding);
  }
  & > thead th:last-child {
    padding-right: var(--billing-table-side-padding);
    width: 10%;
  }

  & > thead td:last-child {
    width: 10%;
  }
`;

const mainTableRow = css`
  position: relative;
  &:hover {
    background: ${theme.colors.gray300};
  }

  td:first-child {
    padding-left: var(--billing-table-side-padding);
  }
  td:last-child {
    padding-right: var(--billing-table-side-padding);
  }

  &::after {
    ${horizontalLineStyles};
  }
`;

const invoiceStatusStyles = (status: ExtendedBillingInvoice['status']) => css`
  background: ${status === 'paid' ? '#EDF9F7' : '#fdf2ed'};
  color: ${status === 'paid' ? theme.colors.success : theme.colors.error};
  border-radius: ${theme.borderRadius.small};
  display: inline;
  padding: ${theme.spacing(0.5, 1)};
  font-weight: bold;
`;

const nestedTableContainer = css`
  background-color: #32373e;

  max-height: 0;
  @keyframes expandRow {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }

  transition: transform 0.15s ease-in-out;
  animation: expandRow 0.15s;
`;

const nestedTableStyles = css`
  thead {
    border-bottom: 1px solid #5f6771;
  }

  td {
    padding: ${theme.spacing(2)};
    color: ${theme.colors.white};
  }

  th {
    font-size: ${theme.fontSize(14)};
    color: #a1a8b0;
    font-weight: initial;
  }

  --billing-nested-table-side-padding: ${theme.spacing(6)};
  th:first-child,
  td:first-child {
    padding-left: var(--billing-nested-table-side-padding);
  }
  th:last-child,
  td:last-child {
    padding-right: var(--billing-nested-table-side-padding);
  }

  tbody tr {
    position: relative;
  }
  tbody tr:not(:last-child)::after {
    content: '';
    border-bottom: 1px dashed #5f6771;
    position: absolute;
    display: block;
    bottom: 0;
    transform: translateX(-100%);
    width: calc(100% - 100px);
    left: calc(100% - 50px);
  }
`;

const invoiceActionsContainerStyles = css`
  display: flex;
`;

const spinnerContainer = css`
  text-align: center;
`;
