import React from 'react';
import {
  CaretDownIconSmall,
  CaretUpIconSmall,
  ConfirmBadgeIcon,
  ErrorBadgeIcon,
  Modal,
  Text,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  caretColor,
  displayStyles,
  modalContentBodyStyles,
  modalHeaderStyle,
  sentToEmailStyle,
  subTitleStyle,
} from './intake-form-card.component';

type Props = {
  errorModalPropsMultiLocation;
  locationData;
  handleSendIntakeFormData;
  intakeFormResponseErrorResponse;
  successfulFormsSentResponse;
  setToggleSeeMoreLocations;
  toggleSeeMoreLocations;
};

export const IntakeFormMultiLocationErrorModalComponent = ({
  errorModalPropsMultiLocation,
  locationData,
  handleSendIntakeFormData,
  intakeFormResponseErrorResponse,
  successfulFormsSentResponse,
  setToggleSeeMoreLocations,
  toggleSeeMoreLocations,
}: Props) => {
  return (
    <Modal {...errorModalPropsMultiLocation} maxWidth={500}>
      <Modal.Header textAlign="left" css={modalHeaderStyle}>
        Error Sending to Some Locations
      </Modal.Header>
      <Modal.Body>
        <Text>
          We were unable to successfully send the form to{' '}
          {intakeFormResponseErrorResponse?.length} locations. See the error message below
          for more information:
        </Text>
        <p css={subTitleStyle}>Intake Form Summary</p>
        <div css={sentToEmailStyle}>
          <Text>Sent to</Text>
          <div>
            <p css={modalContentBodyStyles}>
              {locationData.onboardingContactFirstname}{' '}
              {locationData.onboardingContactLastname}
            </p>
            <p>{handleSendIntakeFormData?.onboardingContactEmail}</p>
          </div>
        </div>
        <div
          css={css`
            display: flex;
          `}
        >
          <ErrorBadgeIcon
            css={css`
              color: ${theme.colors.error};
              margin-right: ${theme.spacing(1)};
            `}
          />
          <Text>Errors ({intakeFormResponseErrorResponse?.length})</Text>
        </div>
        {intakeFormResponseErrorResponse?.map((onboardResponse, index) => (
          <div key={index}>
            <p css={modalContentBodyStyles}>{onboardResponse.name}</p>
            <p
              css={css`
                color: ${theme.colors.error};
              `}
            >
              Error: {onboardResponse.onboardingError}
            </p>
          </div>
        ))}
        <div
          css={css`
            display: flex;
          `}
        >
          <ConfirmBadgeIcon
            css={css`
              color: ${theme.colors.success};
              margin-right: ${theme.spacing(1)};
            `}
          />
          <Text>Success ({successfulFormsSentResponse?.length})</Text>
        </div>
        <div>
          <div css={displayStyles}>
            <p
              css={css`
                color: ${theme.colors.weaveBlue};
                margin-right: ${theme.spacing(1)};
              `}
            >
              See More
            </p>
            <div onClick={() => setToggleSeeMoreLocations(!toggleSeeMoreLocations)}>
              {toggleSeeMoreLocations ? (
                <CaretDownIconSmall css={caretColor} />
              ) : (
                <CaretUpIconSmall css={caretColor} />
              )}
            </div>
          </div>
          {!toggleSeeMoreLocations && (
            <>
              {successfulFormsSentResponse?.map((sendResponse, index) => (
                <p key={index}>{sendResponse.name}</p>
              ))}
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Actions
        onPrimaryClick={errorModalPropsMultiLocation.onClose}
        primaryLabel="Close"
      ></Modal.Actions>
    </Modal>
  );
};
