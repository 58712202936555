import { createAction } from 'redux-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { showError } from '@weave/alert-system';
import fileDownload from 'js-file-download';
import { cloneDeep } from 'lodash';
import { DataCenters } from '../location/locations/locations.types';
import { selectCurrentLocationDataCenter } from '../location/current-location';
import { selectSplitPortingRequests } from './porting.selectors';
import { Provider } from '../../../components/porting-hub-container/porting-card/constants';
import { PortType, debugTraceHeader, wamPortingTrace } from './constants';
import { PortGetData } from 'apis/porting/porting.types';
import { PortStatus } from 'components/porting-hub-container/utils/port-constants';

export const getPortingData = createAction('GET_PORTING_DATA');
export const getPortingDataSuccess = createAction('GET_PORTING_DATA_SUCCESS');
export const getPortingDataFailure = createAction('GET_PORTING_DATA_FAILURE');

export const deletePortingRequest = createAction('DELETE_PORTING_REQUEST');
export const deletePortingRequestSuccess = createAction('DELETE_PORTING_REQUEST_SUCCESS');
export const deletePortingRequestFailure = createAction('DELETE_PORTING_REQUEST_FAILURE');

export const checkPortability = createAction('CHECK_PORTABILITY');
export const checkPortabilitySuccess = createAction('CHECK_PORTABILITY_SUCCESS');
export const checkPortabilityFailure = createAction('CHECK_PORTABILITY_FAILURE');
export const resetPortabilityCheck = createAction('RESET_PORTABILITY_CHECK');

export const submitSplitPortingRequests = createAction('SUBMIT_SPLIT_PORTING_REQUESTS');
export const submitSplitPortingRequestsSuccess = createAction(
  'SUBMIT_SPLIT_PORTING_REQUESTS_SUCCESS'
);
export const submitSplitPortingRequestsFailure = createAction(
  'SUBMIT_SPLIT_PORTING_REQUESTS_FAILURE'
);

export const clearSubmitPortingDetails = createAction('CLEAR_PORTING_SUBMIT_DETAILS');

export const getPortingStatus = createAction('GET_PORTING_STATUS');
export const getPortingStatusSuccess = createAction('GET_PORTING_STATUS_SUCCESS');
export const getPortingStatusFailure = createAction('GET_PORTING_STATUS_FAILURE');
export const clearPortingStatus = createAction('CLEAR_PORTING_STATUS');

export const submitPorting = createAction('SUBMIT_PORTING');
export const submitPortingSuccess = createAction('SUBMIT_PORTING_SUCCESS');
export const submitPortingFailure = createAction('SUBMIT_PORTING_FAILURE');
export const submitPortingFailureWithoutErrorMessage = createAction(
  'SUBMIT_PORTING_FAILURE_WITHOUT_ERROR_MESSAGE'
);

export const setPortingDetails = createAction('SET_PORTING_DETAILS');

export const updatePortingData = createAction('UPDATE_PORTING_DATA');
export const updatePortingDataSuccess = createAction('UPDATE_PORTING_DATA_SUCCESS');
export const updatePortingDataFailure = createAction('UPDATE_PORTING_DATA_FAILURE');

export const downloadBill = createAction('DOWNLOAD_BILL');
export const downloadBillDone = createAction('DOWNLOAD_BILL_DONE');
export const downloadLOA = createAction('DOWNLOAD_LOA');
export const downloadLOADone = createAction('DOWNLOAD_LOA_DONE');

export const validateData = createAction('VALIDATE_DATA');

const handleUpdatePortingData = function* (action) {
  try {
    const response = yield call(
      CustomAxios.put,
      `/support/v1/portingdata/${action.payload.id}`,
      action.payload
    );
    const port = response.data.data;
    yield put(updatePortingDataSuccess(port));
  } catch {
    yield put(showError('Could not save porting information'));
    yield put(updatePortingDataFailure());
  }
};

export const updatePortingDataSaga = function* () {
  yield takeLatest(updatePortingData.toString(), handleUpdatePortingData);
};

const createPortingDataObject = (lnpNumbers, port, hideBtn) => {
  const portingRequests: any = [];

  for (const num of lnpNumbers) {
    for (const portRequest of port.porting_requests) {
      const cleanPortRequestNumber = portRequest.phone_number
        .replace(/\D/g, '')
        .replace(/^1/, '');

      if (cleanPortRequestNumber === num) {
        portingRequests.push({
          hideBtn,
          phone_number: portRequest.phone_number,
          port_type: portRequest.port_type,
          number_type: portRequest.number_type,
          requested_firm_order_commitment_date:
            portRequest.requested_firm_order_commitment_date,
        });
      }
    }
  }

  return Object.assign({}, port, { porting_requests: portingRequests });
};

const handleCheckPortability = function* (action) {
  try {
    const portClone = cloneDeep(action.payload);
    const LNPNumbers: any = [];
    for (const req of portClone.porting_requests) {
      LNPNumbers.push(req.phone_number);
    }
    const provider = Provider.get(portClone.provider)?.toUpperCase();
    const LNPCheckResponse = yield call(
      CustomAxios.post,
      `/desktop/v1/portingdata/check-portability`,
      { phone_numbers: LNPNumbers, provider }
    );
    const portingDataObjects: any = [];
    const LNPResponse = LNPCheckResponse.data.data;
    if (LNPResponse.errors) {
      for (const error of LNPResponse.errors) {
        yield put(
          showError(
            `Check portability failed for ${error.phone_numbers}. Not Portable Reason ${error.description}`
          )
        );
      }
      yield put(checkPortabilityFailure());
    }

    if (LNPResponse.portability_errors) {
      for (const portability_error of LNPResponse.portability_errors) {
        yield put(
          showError(
            `Check portability failed for ${portability_error.phone_numbers}. Not Portable Reason ${portability_error.description}`
          )
        );
      }
      yield put(checkPortabilityFailure());
    }

    if (!LNPResponse.portable_numbers || LNPResponse.portable_numbers?.length === 0) {
      const numbersDetail =
        'Number(s): ' +
        LNPResponse.unsupported_rate_centers
          .flatMap((a_rate_center) => a_rate_center.phone_numbers)
          .join(', ');
      yield put(
        showError(
          `Numbers not eligible for porting. Port type: ${PortType.get(
            LNPResponse.port_type
          )} , ${numbersDetail}`
        )
      );
      yield put(checkPortabilityFailure());
    } else {
      if (LNPResponse.supported_toll_free_numbers?.length > 0) {
        portingDataObjects.push(
          createPortingDataObject(
            LNPResponse.supported_toll_free_numbers,
            portClone,
            true
          )
        );
      }
      if (LNPResponse.unsupported_toll_free_numbers?.length > 0) {
        portingDataObjects.push(
          createPortingDataObject(
            LNPResponse.unsupported_toll_free_numbers,
            portClone,
            false
          )
        );
      }
      if (LNPResponse.supported_losing_carriers?.length > 0) {
        for (const carrier of LNPResponse.supported_losing_carriers) {
          portingDataObjects.push(
            createPortingDataObject(carrier.phone_numbers, portClone, false)
          );
        }
      }
      if (LNPResponse.unsupported_losing_carriers?.length > 0) {
        let allNumbers = [];
        for (const carrier of LNPResponse.unsupported_losing_carriers) {
          allNumbers = allNumbers.concat(carrier.phone_numbers);
        }
        portingDataObjects.push(createPortingDataObject(allNumbers, portClone, false));
      }

      if (portingDataObjects.length === 1) {
        yield put(
          submitPorting({
            port: action.payload,
            hideBtn: portingDataObjects[0].porting_requests[0].hideBtn,
          })
        );
      } else {
        yield put(checkPortabilitySuccess(portingDataObjects));
      }
    }
  } catch {
    yield put(
      showError('Could not submit this port order. Failed to check portability.')
    );
    yield put(checkPortabilityFailure());
  }
};

export const checkPortabilitySaga = function* () {
  yield takeLatest(checkPortability.toString(), handleCheckPortability);
};

const handleSubmitSplitPortingRequests = function* (action) {
  const originalPortingRequest = cloneDeep(action.payload);
  try {
    const portingRequests: ReturnType<typeof selectSplitPortingRequests> = yield select(
      selectSplitPortingRequests
    );
    // set the original to status CANCELLED (set porting_status to 11)
    // updated in CPI-408 to set status to SPLIT_PORT(19)
    try {
      for (const portingRequest of originalPortingRequest.porting_requests) {
        portingRequest.porting_status = 19;
        portingRequest.port_order_number = 'No Port Order Number - Split Port';
      }
      yield call(
        CustomAxios.put,
        `/support/v1/portingdata/${originalPortingRequest.id}`,
        originalPortingRequest
      );
    } catch {
      yield put(showError('Did not set original porting request to cancelled.'));
    }

    const results: any = [];

    for (const portingRequest of portingRequests) {
      delete portingRequest.id;
      delete portingRequest.created_at;
      delete portingRequest.updated_at;

      if (!portingRequest.porting_requests[0].hideBtn) {
        portingRequest.billing_phone_number =
          portingRequest.porting_requests[0].phone_number;
      } else {
        delete portingRequest.billing_phone_number;
      }

      if (!portingRequest.service_house_number) {
        const streetSplit = portingRequest.service_street1.split(' ');
        portingRequest.service_house_number = streetSplit[0];
        streetSplit.shift();
        portingRequest.service_street1 = streetSplit.join(' ');
      }

      const dataCenter: ReturnType<typeof selectCurrentLocationDataCenter> = yield select(
        selectCurrentLocationDataCenter
      );

      if (dataCenter === DataCenters.CentralIA) {
        portingRequest.sip_peer_id = '549788';
      } else if (dataCenter === DataCenters.EasternVA) {
        portingRequest.sip_peer_id = '525692';
      } else if (dataCenter === DataCenters.PacificCA) {
        portingRequest.sip_peer_id = '539695';
      } else if (dataCenter === DataCenters.MountainUT) {
        portingRequest.sip_peer_id = '525694';
      } else if (dataCenter === DataCenters.US1Central1) {
        portingRequest.sip_peer_id = '549788';
      }
      let newPort = [{ id: 'no id set yet.' }];

      try {
        // create new portingData objects
        const res = yield call(
          CustomAxios.post,
          `/support/v1/portingdata`,
          portingRequest
        );
        newPort = res.data.data;

        // submit new portingData objects
        const response = yield call(
          CustomAxios.post,
          `/support/v1/portingdata/portins/${newPort[0].id}/create`,
          {},
          {
            headers: { [debugTraceHeader]: wamPortingTrace },
          }
        );
        const status = response.data.data; // submitPortingDetails
        // get the new portingData object after submission
        let newPortingDataResponse = yield call(
          CustomAxios.get,
          `/support/v1/portingdata`
        );
        let updatedPort;
        newPortingDataResponse = newPortingDataResponse.data.data;
        for (const portingData of newPortingDataResponse) {
          if (portingData.id === newPort[0].id) {
            updatedPort = portingData;
          }
        }

        results.push({ status, port: updatedPort });
      } catch (error) {
        if (error.response.status === 400) {
          yield put(validateData(originalPortingRequest));
        }
        if (error?.response?.data?.error) {
          yield put(showError(error?.response?.data?.error));
          yield put(submitPortingFailureWithoutErrorMessage());
        } else {
          results.push({
            status: {
              has_error: true,
              errors: [
                { description: 'Network request failed. Simply try resubmitting.' },
              ],
            },
            port: newPort[0],
          });
        }
      }
    }

    yield put(submitSplitPortingRequestsSuccess(results));
  } catch {
    yield put(showError('Could not split and submit porting requests.'));
    yield put(submitSplitPortingRequestsFailure());
  }
};

export const submitSplitPortingRequestsSaga = function* () {
  yield takeLatest(
    submitSplitPortingRequests.toString(),
    handleSubmitSplitPortingRequests
  );
};

const handleSubmitPorting = function* (action) {
  const portClone = cloneDeep(action.payload.port);
  try {
    if (!action.payload.hideBtn) {
      portClone.billing_phone_number = portClone.porting_requests[0].phone_number;
    } else {
      delete portClone.billing_phone_number;
    }

    if (!portClone.service_house_number) {
      const streetSplit = portClone.service_street1.split(' ');
      portClone.service_house_number = streetSplit[0];
      streetSplit.shift();
      portClone.service_street1 = streetSplit.join(' ');
    }

    const dataCenter: ReturnType<typeof selectCurrentLocationDataCenter> = yield select(
      selectCurrentLocationDataCenter
    );

    if (dataCenter === DataCenters.CentralIA) {
      portClone.sip_peer_id = '549788';
    } else if (dataCenter === DataCenters.EasternVA) {
      portClone.sip_peer_id = '525692';
    } else if (dataCenter === DataCenters.PacificCA) {
      portClone.sip_peer_id = '539695';
    } else if (dataCenter === DataCenters.MountainUT) {
      portClone.sip_peer_id = '525694';
    }

    const res = yield call(
      CustomAxios.put,
      `/support/v1/portingdata/${portClone.id}`,
      portClone
    );
    const updatedPort = res.data.data;
    const response = yield call(
      CustomAxios.post,
      `/support/v1/portingdata/portins/${updatedPort.id}/create`,
      {},
      {
        headers: { [debugTraceHeader]: wamPortingTrace },
      }
    );
    const status = response.data.data;
    yield put(submitPortingSuccess(status));
  } catch (error) {
    if (error.response.status === 400) {
      yield put(validateData(portClone));
    }
    if (error?.response?.data?.error) {
      yield put(showError(error?.response?.data?.error));
      yield put(submitPortingFailureWithoutErrorMessage());
    } else {
      yield put(submitPortingFailure());
    }
  }
};

const handleErrorCodeData = function* (action) {
  const response = yield call(
    CustomAxios.get,
    `/support/v1/portingdata/portins/${action.payload.id}/batch-status`
  );
  if (response?.data?.data?.progress_status === 'VALIDATION_FAILED') {
    action.payload.porting_requests[0].porting_status = PortStatus?.validationFailed;
    yield put(setPortingDetails(action.payload));
  }
};

export const errorCodeDataSaga = function* () {
  yield takeLatest(validateData.toString(), handleErrorCodeData);
};

export const submitPortingSaga = function* () {
  yield takeLatest(submitPorting.toString(), handleSubmitPorting);
};

const handleGetPortingData = function* () {
  try {
    const response = yield call(CustomAxios.get, '/support/v1/portingdata/');
    const ports: PortGetData[] = response.data.data;
    yield put(getPortingDataSuccess(ports || []));
  } catch {
    yield put(showError('Could not get porting data. Please try again.'));
    yield put(getPortingDataFailure());
  }
};

export const getPortingDataSaga = function* () {
  yield takeLatest(getPortingData.toString(), handleGetPortingData);
};

const handleDeletePortingRequest = function* (action) {
  try {
    const url = `/support/v1/portingrequests/${action.payload}`;
    yield call(CustomAxios.delete, url);
    yield put(deletePortingRequestSuccess());
    yield put(getPortingData());
  } catch (error: any) {
    yield put(deletePortingRequestFailure(error));
    yield put(showError('Error when canceling port request.'));
  }
};

export const deletePortingRequestSaga = function* () {
  yield takeLatest(deletePortingRequest.toString(), handleDeletePortingRequest);
};

const handleGetPortingStatus = function* (action) {
  try {
    const response = yield call(
      CustomAxios.get,
      `/support/v1/portingdata/portins/${action.payload.id}/batch-status`
    );
    const status = response.data.data;
    yield put(getPortingStatusSuccess(status));
  } catch {
    yield put(showError('Failed to get port status.'));
    yield put(getPortingStatusFailure());
  }
};

export const getPortingStatusSaga = function* () {
  yield takeLatest(getPortingStatus.toString(), handleGetPortingStatus);
};

const handleDownloadLOA = function* (action) {
  try {
    const { id, company_name } = action.payload;
    const response = yield call(CustomAxios.get, `/support/v1/portingdata/loa/${id}`, {
      responseType: 'blob',
    });
    const file = response.data;
    fileDownload(file, `${company_name}_loa.pdf`, 'application/pdf');
    yield put(downloadLOADone());
  } catch {
    yield put(showError('Could not download LOA. Please try again.'));
    yield put(downloadLOADone());
  }
};

export const downloadLOASaga = function* () {
  yield takeLatest(downloadLOA.toString(), handleDownloadLOA);
};

const handleDownloadBill = function* (action) {
  try {
    const {
      customer_phone_bill_media_id,
      location_id,
      customer_phone_bill_media_type,
      company_name,
    } = action.payload;
    const response = yield call(
      CustomAxios.get,
      `/support/v1/media/${location_id}/${customer_phone_bill_media_id}/${customer_phone_bill_media_type}`,
      { responseType: 'blob' }
    );
    const file = response.data;
    fileDownload(file, `${company_name}-phone-bill.pdf`, 'application/pdf');
    yield put(downloadBillDone());
  } catch {
    yield put(showError('Failed to download bill'));
    yield put(downloadBillDone());
  }
};

export const downloadBillSaga = function* () {
  yield takeLatest(downloadBill.toString(), handleDownloadBill);
};
