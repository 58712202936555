import {
  AddTerminalTextPayload,
  DeleteSourcePayload,
  DeleteSourceSuccessPayload,
  GetSyncAppInfoPayload,
  SyncAppInterface,
} from '../../../components/sync-app/sync-app.types';
import {
  HealthData,
  SyncAppActions,
  SyncAppDOVStatuses,
  SyncAppInfoError,
  SyncAppInfoSuccess,
  SyncAppReport,
} from './sync-app.actions';

export interface SyncAppState {
  loading: boolean;
  data: {
    LocationHealth?: string;
    SyncApps?: SyncAppInterface[];
  };
  loginCredentials: any;
  syncAppsInfo: {
    [key: string]: {
      [key: string]: any;
      terminalText: string[];
    };
  };
  reports: {
    [key: string]: { [key: string]: string }[];
  };
  dovStatuses?: Record<string, string>;
  error: Error | null;
}

const defaultState = {
  loading: false,
  data: {},
  loginCredentials: {},
  syncAppsInfo: {},
  reports: {},
  error: null,
};

export type SyncAppDispatchAction =
  | { type: SyncAppActions.GetHealth }
  | { type: SyncAppActions.GetHealthSuccess; payload: HealthData }
  | { type: SyncAppActions.GetHealthFailure }
  | { type: SyncAppActions.Create }
  | { type: SyncAppActions.CreateSuccess; payload: any }
  | { type: SyncAppActions.CreateFailure; payload: Error }
  | { type: SyncAppActions.ClearState }
  | { type: SyncAppActions.AddReport; payload: SyncAppReport }
  | { type: SyncAppActions.ClearReports }
  | { type: SyncAppActions.SetDOVStatuses; payload: SyncAppDOVStatuses }
  | { type: SyncAppActions.ResetDOVStatus; payload: string }
  | { type: SyncAppActions.ManuallyVerifyDOVStatus; payload: string }
  | { type: SyncAppActions.DeleteSource; payload: DeleteSourcePayload }
  | { type: SyncAppActions.DeleteSourceSuccess; payload: DeleteSourceSuccessPayload }
  | { type: SyncAppActions.GetInfo; payload: GetSyncAppInfoPayload }
  | { type: SyncAppActions.GetInfoSuccess; payload: SyncAppInfoSuccess }
  | { type: SyncAppActions.GetInfoFailure; payload: SyncAppInfoError }
  | { type: SyncAppActions.GetMultipleInfoFailure; payload: Error }
  | { type: SyncAppActions.ClearTerminalText; payload: string }
  | { type: SyncAppActions.AddToTerminalText; payload: AddTerminalTextPayload };

export const syncAppReducer = (
  state: SyncAppState = defaultState,
  action: SyncAppDispatchAction
) => {
  switch (action.type) {
    case SyncAppActions.GetHealth:
      return {
        ...state,
        loading: true,
        data: {},
        dovStatuses: {},
      };
    case SyncAppActions.GetHealthSuccess:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SyncAppActions.GetHealthFailure:
      return { ...state, loading: false };
    case SyncAppActions.GetMultipleInfoFailure:
      return { ...state, error: action.payload };
    case SyncAppActions.Create:
      return { ...state, loading: true };
    case SyncAppActions.CreateSuccess:
      return {
        ...state,
        loading: false,
        loginCredentials: action.payload.data,
      };
    case SyncAppActions.CreateFailure:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SyncAppActions.ClearState:
      return defaultState;
    case SyncAppActions.AddReport: {
      const { sourceId, report } = action.payload;
      return {
        ...state,
        reports: {
          ...state.reports,
          [sourceId]: report,
        },
      };
    }
    case SyncAppActions.ClearReports: {
      return {
        ...state,
        reports: {},
      };
    }
    case SyncAppActions.SetDOVStatuses: {
      return {
        ...state,
        dovStatuses: action.payload,
      };
    }
    case SyncAppActions.ResetDOVStatus: {
      return {
        ...state,
        dovStatuses: { ...state.dovStatuses, [action.payload]: 'NOT_VERIFIED' },
      };
    }
    case SyncAppActions.ManuallyVerifyDOVStatus: {
      return {
        ...state,
        dovStatuses: { ...state.dovStatuses, [action.payload]: 'VERIFIED' },
      };
    }
    case SyncAppActions.DeleteSource: {
      const { sourceId } = action.payload;
      return {
        ...state,
        syncAppsInfo: {
          ...state.syncAppsInfo,
          [sourceId]: {
            ...state.syncAppsInfo[sourceId],
            loading: true,
          },
        },
      };
    }
    case SyncAppActions.DeleteSourceSuccess: {
      const { sourceId } = action.payload;

      //Remove sourceID from temporary 'loading' array in state.syncAppsInfo
      delete state.syncAppsInfo[sourceId];

      //Remove the deleted sourceID from the main list of syncApps in state.data.syncApps
      const filteredSyncApp = state.data?.SyncApps?.filter(
        (syncApp) => syncApp.SourceID !== sourceId
      );
      return {
        ...state,
        data: {
          ...state.data,
          SyncApps: filteredSyncApp,
        },
        syncAppsInfo: {
          ...state.syncAppsInfo,
        },
      };
    }
    case SyncAppActions.GetInfo: {
      const { sourceId } = action.payload;
      return {
        ...state,
        syncAppsInfo: {
          ...state.syncAppsInfo,
          [sourceId]: {
            ...state.syncAppsInfo[sourceId],
            loading: true,
          },
        },
      };
    }
    case SyncAppActions.GetInfoSuccess: {
      const { data, sourceId, syncAppInfoKey } = action.payload;
      let change = { loading: false };
      if (syncAppInfoKey) {
        change = { ...change, [syncAppInfoKey]: data };
      }
      return {
        ...state,
        error: null,
        syncAppsInfo: {
          ...state.syncAppsInfo,
          [sourceId]: {
            ...state.syncAppsInfo[sourceId],
            ...change,
          },
        },
      };
    }
    case SyncAppActions.GetInfoFailure: {
      const { sourceId, error } = action.payload;
      return {
        ...state,
        error,
        syncAppsInfo: {
          ...state.syncAppsInfo,
          [sourceId]: {
            ...state.syncAppsInfo[sourceId],
            loading: false,
          },
        },
      };
    }
    case SyncAppActions.ClearTerminalText: {
      const sourceId = action.payload;
      return {
        ...state,
        syncAppsInfo: {
          ...state.syncAppsInfo,
          [sourceId]: {
            ...state.syncAppsInfo[sourceId],
            terminalText: [],
          },
        },
      };
    }
    case SyncAppActions.AddToTerminalText: {
      const { sourceId, terminalText } = action.payload;
      const { syncAppsInfo } = state;
      const terminalTextExist =
        syncAppsInfo[sourceId] && syncAppsInfo[sourceId].terminalText;

      return {
        ...state,
        syncAppsInfo: {
          ...syncAppsInfo,
          [sourceId]: {
            ...syncAppsInfo[sourceId],
            loading: false,
            terminalText: !terminalTextExist
              ? [terminalText]
              : [...syncAppsInfo[sourceId].terminalText, terminalText],
          },
        },
      };
    }
    default:
      return state;
  }
};
