import { ReactNode } from 'react';

type UserLookupCardProps = {
  as?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
};

export const UserLookupCard = ({ as, children, ...rest }: UserLookupCardProps) => {
  const Component = as || 'section';
  return (
    <Component
      css={(theme) => ({
        padding: theme.spacing(2),
        border: `1px solid`,
        borderColor: theme.colors.gray400,
        borderRadius: theme.borderRadius.medium,
        overflow: 'hidden',
      })}
      {...rest}
    >
      {children}
    </Component>
  );
};
