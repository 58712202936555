import { AxiosError } from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { ListRulesResponse } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-rules/v1/service.pb';
import {
  DigitiseFormsDetails,
  GetFormsResponse,
} from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { ListResponse as DepartmentListResponse } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/department.pb';

import { DeviceModel } from '../../../../../../../apis/devices';
import { AppointmentWritebackModel } from '../../../../../../../models/appointment.model';
import { SmsModel } from '../../../../../../../models/sms-history.model';
import { StatusMapping } from '../../../../../../../models/writebacks.model';
import { autoMsgDeliveryReportsApi } from '../../../../../../../redux/actions/auto-msg-queue/auto-msg-delivery-reports.action';
import { selectFeatureFlag } from '../../../../../../../redux/actions/feature-flags';
import {
  selectCurrentLocation,
  selectCurrentLocationParentId,
} from '../../../../../../../redux/actions/location';
import {
  MESSAGES_URL,
  OLD_MESSAGES_URL,
} from '../../../../../../../redux/actions/sms/api';
import { usersApi } from '../../../../../../../redux/actions/users/users.api';
import { CustomAxios, getResponseData } from '../../../../../../../redux/axios';
import CallRecordModel from '../../../../../../history-list-container/call-records-container/call-record.model';
import {
  NewOfficeHoursDataApi,
  OfficeHoursApi,
} from '../../../../../../phones/office-hours/legacy/interface';
import { Settings as ReviewsSettings } from '../../../../../../settings/reviews/helpers';
import { LocationHealth } from '../../../../../../sync-app/sync-app.types';
import { DependencyDataKeys } from './handoff-metrics.types';
import { PortGetData } from '../../../../../../../apis/porting/porting.types';
import { requestFaxHistory } from '../../../../../../../redux/actions/fax-history/api';
import { OnlineSchedulingHealthType } from './online-scheduling.typses';
import { MerchantModel } from '../../../../../../../models/merchant.model';
import { onboardingTasksApi } from '../../../../../onboarding.api';
import { voicemailBoxApi } from '../../../../../../../apis/voice-mailboxes/voice-mailboxes.api';

export const useDependentMetricData = () => {
  const currentLocation = useSelector(selectCurrentLocation);
  const locationId = currentLocation?.LocationID;
  const parentLocationId = useSelector(selectCurrentLocationParentId);
  const commxMigrationFlag = useSelector((state) =>
    selectFeatureFlag(state, 'commx-migration')
  );

  const queryConfig = {
    // cacheTime: hourCacheTime,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
    onError: (err: AxiosError) => {
      console.error(err);
    },
  };

  const callCountsQuery = useQuery(
    [locationId, DependencyDataKeys.CallLogs],
    async () => {
      // PT-TODO: create an api file for these endpoints
      const resp = await CustomAxios.get<{ data: CallRecordModel[] }>(
        '/support/v1/calllogs',
        {
          params: {
            // PT-TODO: replace moment lib with whichever one we are using these days
            start: moment().subtract(1, 'week').startOf('day').toISOString(),
            end: moment().endOf('day').toISOString(),
          },
        }
      );

      const calls = resp.data.data || [];
      return calls;
    },
    queryConfig
  );

  const mailboxGreetingsQuery = useQuery(
    [locationId, DependencyDataKeys.MailboxGreetings],
    async () => {
      const greetings = await voicemailBoxApi.getGreetingsForAllMailboxes();
      return greetings;
    },
    queryConfig
  );

  const departmentsQuery = useQuery([locationId, 'departments-v2'], () =>
    CustomAxios.get<DepartmentListResponse>(`/phone-exp/departments/v2`)
  );

  const reducedMainlineId = departmentsQuery.data?.data.departments?.find(
    (curr) => curr.mainLine
  )?.id;
  const phoneDepartmentsScheduleQuery = useQuery({
    queryKey: [
      locationId,
      DependencyDataKeys.PhoneDepartmentsSchedules,
      reducedMainlineId,
    ],
    queryFn: async () => {
      const response = await CustomAxios.get<NewOfficeHoursDataApi>(
        `portal/v1/phone/departments/${reducedMainlineId}/schedules`
      );
      const officeHours = response.data;
      return officeHours;
    },
    enabled: Boolean(reducedMainlineId),
    onError: console.error,
  });

  const officeHoursQuery = useQuery(
    [locationId, DependencyDataKeys.OfficeHours],
    async () => {
      // PT-TODO: create an api file for these endpoints
      const response = await CustomAxios.get<{ data: OfficeHoursApi }>(
        'support/v1/officehours'
      );
      const officeHours = response.data.data;

      return officeHours;
    },
    queryConfig
  );

  const phoneDevicesQuery = useQuery(
    [locationId, DependencyDataKeys.PhoneDevices],
    async () => {
      // PT-TODO: create an api file for these endpoints
      const response = await CustomAxios.get<{ data: DeviceModel[] }>(
        '/support/v1/phone/devices'
      );
      const devices = response.data.data || [];

      return devices;
    },
    queryConfig
  );

  const smsQuery = useQuery(
    [locationId, DependencyDataKeys.Sms],
    async () => {
      const url = commxMigrationFlag ? MESSAGES_URL : OLD_MESSAGES_URL;
      const params = {
        params: {
          limit: 200,
          start: dayjs().subtract(7, 'day').toISOString(),
          end: new Date().toISOString(),
          location_id: locationId,
        },
      };

      const responses = await CustomAxios.get<{ smsList: SmsModel[] }>(url, params);

      const textMessages = responses.data?.smsList || [];
      return textMessages;
    },
    queryConfig
  );

  const usersQuery = useQuery(
    [locationId, DependencyDataKeys.Users],
    async () => {
      const users = (await usersApi.getUsers(200)) || [];
      return users;
    },
    queryConfig
  );

  const messagingRulesQuery = useQuery(
    [locationId, DependencyDataKeys.MessagingRules],
    async () => {
      const response = await CustomAxios.get<ListRulesResponse>(
        `messaging/auto-rules/v1/rules?orgId=${locationId}&skipFilterValidation=true&summaryOnly=true`
      );

      return response.data.rules || [];
    },
    {
      // cacheTime: hourCacheTime,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!locationId,
      onError: (err: AxiosError) => {
        console.error(err);
      },
    }
  );

  const autoMsgLocationSettingsQuery = useQuery(
    [locationId, DependencyDataKeys.AutoMsgLocationSettings],
    async () => {
      const locationSettings = await autoMsgDeliveryReportsApi.getLocationSettings(
        locationId
      );

      return locationSettings;
    },
    queryConfig
  );

  const appointmentWritebacksQuery = useQuery(
    [locationId, DependencyDataKeys.AppointmentWritebacks],
    async () => {
      const resp = await CustomAxios.get<{ data: AppointmentWritebackModel[] }>(
        `/support/v1/appointments`,
        {
          params: {
            start: moment().subtract(1, 'week').format('YYYY-MM-DD'),
            end: moment().add(1, 'day').format('YYYY-MM-DD'),
          },
        }
      );

      const apptWritebacks = resp.data?.data || [];

      return apptWritebacks;
    },
    queryConfig
  );

  const writebacksStatusMappingsQuery = useQuery(
    [locationId, DependencyDataKeys.WritebacksStatusMappings],
    async () => {
      const resp = await CustomAxios.get<{ data: StatusMapping[] }>(
        `support/v1/notifications/status-mappings/writeback`,
        {
          // Multi singles - locations that have opted out of sharing data
          // (meaning they dont have a parent id sharing data)
          // Unify - locations that share data
          // Data Beta - locations that share data
          // We care to determine which locations share data down to the children for unify and data bata.
          // We can determine this from a ParentID

          headers: {
            'Location-Id': parentLocationId ? parentLocationId : locationId,
          },
        }
      );

      const statusMappingsSettings = resp.data?.data || [];

      return statusMappingsSettings;
    },
    queryConfig
  );

  const appointmentsStatusMappingsQuery = useQuery(
    [locationId, DependencyDataKeys.AppointmentsStatusMappings],
    async () => {
      const resp = await CustomAxios.get<{ data: StatusMapping[] }>(
        `support/v1/notifications/status-mappings/appointment`
      );

      const statusMappingsSettings = resp.data?.data || [];

      return statusMappingsSettings;
    },
    queryConfig
  );

  const syncappHealthQuery = useQuery(
    [locationId, DependencyDataKeys.SyncappHealth],
    async () => {
      const resp = await CustomAxios.get<{ data: LocationHealth }>(
        `support/v1/syncapp/health`,
        {
          // Multi singles - locations that have opted out of sharing data
          // (meaning they dont have a parent id sharing data)
          // Unify - locations that share data
          // Data Beta - locations that share data
          // We care to determine which locations share data down to the children for unify and data bata.
          // We can determine this from a ParentID
          headers: { 'Location-Id': parentLocationId ? parentLocationId : locationId },
        }
      );
      const data = resp?.data?.data ?? {};
      return data;
    },
    {
      // cacheTime: hourCacheTime,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err: AxiosError) => {
        console.error(err);
      },
    }
  );

  const reviewsSettingsQuery = useQuery(
    [locationId, DependencyDataKeys.ReviewsSettings],
    async () => {
      const resp = await CustomAxios.get<{ data: ReviewsSettings }>(
        'support/v1/reviews/settings'
      );
      const data = resp?.data?.data ?? {};

      return data;
    },
    queryConfig
  );

  const portingDataQuery = useQuery(
    [locationId, DependencyDataKeys.PortingData],
    async () => {
      const resp = await CustomAxios.get<{ data: PortGetData[] }>(
        `support/v1/portingdata`
      );

      return resp?.data?.data ?? [];
    },
    {
      retry: 3,
      refetchOnWindowFocus: false,
      enabled: true,
      onError: (err: AxiosError) => {
        console.error(err);
      },
    }
  );

  const faxHistoryQuery = useQuery(
    [locationId, DependencyDataKeys.FaxHistory],
    async () => {
      const params = {
        start: dayjs().subtract(7, 'day').toISOString(),
        end: new Date().toISOString(),
        fromdid: '',
        todid: '',
        limit: '26',
        skip: '',
        order: 'asc',
      };
      const data = await requestFaxHistory(params);

      return data ?? [];
    },
    queryConfig
  );

  const fetchFaxNumberDID = async () => {
    let response = await CustomAxios.get(`/support/v1/fax/did`).then(getResponseData);
    return response;
  };

  const faxProviderQuery = useQuery(
    [locationId, DependencyDataKeys.FaxProviderSettings],
    async () => {
      const faxNumber = await fetchFaxNumberDID();

      if (faxNumber.DID !== '') {
        const response = await CustomAxios.get(
          `support/v1/fax/providerdidinfo/${faxNumber['DID']}`
        );
        return response;
      }
      return;
    },
    queryConfig
  );

  const submittedDigitizationFormsQuery = useQuery(
    [locationId, DependencyDataKeys.SubmittedDigitizationForms],
    async () => {
      const resp = await CustomAxios.get<DigitiseFormsDetails>(
        `/forms/v1/digitization?companyId=${locationId}`
      );
      return resp?.data.data ?? [];
    },
    queryConfig
  );

  const digitalFormsQuery = useQuery(
    [locationId, DependencyDataKeys.DigitalForms],
    async () => {
      const response = await CustomAxios.get<GetFormsResponse>(
        `/forms-digital/v2/forms?company_ids=${locationId}&pageRequest.pageLimit=100&pageRequest.pageNumber=1&searchKey=`
      );
      const forms = response?.data?.data ?? [];
      return forms;
    },
    queryConfig
  );

  const onlineScheduleQuery = useQuery(
    [locationId, DependencyDataKeys.OnlineSchedulingHealth],
    async () => {
      const response = await CustomAxios.get<{ data: OnlineSchedulingHealthType }>(
        `schedule/api/v2/health`
      );
      const onlineSchedule = response?.data?.data;
      return onlineSchedule;
    },
    queryConfig
  );

  const customizationFeaturesQuery = useQuery(
    [locationId, DependencyDataKeys.CustomizationFlags],
    async () => {
      const resp = await CustomAxios.get(
        `platform-location-feature/v1/location/${locationId}/features`
      );
      return resp;
    },
    queryConfig
  );

  const useTaskQuery = useQuery(
    [locationId, DependencyDataKeys.LocationTasks],
    async () => {
      const resp = await onboardingTasksApi.getModulesTasks();
      return resp;
    },
    queryConfig
  );

  const usePaymentsQuery = useQuery(
    [locationId, DependencyDataKeys.PaymentsSettings],
    async () => {
      const resp = await CustomAxios.get<{ data: MerchantModel }>(`payments/v1/merchant`);

      const data = resp.data;
      return data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (err: AxiosError) => {
        console.error(err);
      },
    }
  );

  const { data: salesforceBundleQuery } = useQuery(
    [locationId, DependencyDataKeys.SalesforceBundleSettings],
    async () => {
      const resp = await CustomAxios.get(`/package-service/v1/salesforce-bundle-name`);
      return resp?.data;
    },
    {
      enabled: true,
      onError: (err: AxiosError) => {
        console.log(err);
      },
    }
  );

  return {
    [DependencyDataKeys.CallLogs]: callCountsQuery,
    [DependencyDataKeys.MailboxGreetings]: mailboxGreetingsQuery,
    [DependencyDataKeys.OfficeHours]: officeHoursQuery,
    [DependencyDataKeys.PhoneDepartmentsSchedules]: phoneDepartmentsScheduleQuery,
    [DependencyDataKeys.PhoneDevices]: phoneDevicesQuery,
    [DependencyDataKeys.Sms]: smsQuery,
    [DependencyDataKeys.Users]: usersQuery,
    [DependencyDataKeys.MessagingRules]: messagingRulesQuery,
    [DependencyDataKeys.AutoMsgLocationSettings]: autoMsgLocationSettingsQuery,
    [DependencyDataKeys.AppointmentWritebacks]: appointmentWritebacksQuery,
    [DependencyDataKeys.WritebacksStatusMappings]: writebacksStatusMappingsQuery,
    [DependencyDataKeys.AppointmentsStatusMappings]: appointmentsStatusMappingsQuery,
    [DependencyDataKeys.SyncappHealth]: syncappHealthQuery,
    [DependencyDataKeys.PortingData]: portingDataQuery,
    [DependencyDataKeys.ReviewsSettings]: reviewsSettingsQuery,
    [DependencyDataKeys.FaxHistory]: faxHistoryQuery,
    [DependencyDataKeys.FaxProviderSettings]: faxProviderQuery,
    [DependencyDataKeys.DigitalForms]: digitalFormsQuery,
    [DependencyDataKeys.SubmittedDigitizationForms]: submittedDigitizationFormsQuery,
    [DependencyDataKeys.OnlineSchedulingHealth]: onlineScheduleQuery,
    [DependencyDataKeys.LocationTasks]: useTaskQuery,
    [DependencyDataKeys.CustomizationFlags]: customizationFeaturesQuery,
    [DependencyDataKeys.SalesforceBundleSettings]: salesforceBundleQuery,
    [DependencyDataKeys.PaymentsSettings]: usePaymentsQuery,
  };
};
