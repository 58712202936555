import React from 'react';
import { css } from '@emotion/core';
import { Modal, Text } from '@weave/design-system';
import { MetricResult } from '../handoff-metrics/handoff-metrics.types';
import { MetricResultsTable } from './metric-results-table';

type FailedMetricsModalProps = {
  failedMetrics: MetricResult[];
  onClose: () => void;
};

export const FailedMetricsModal = ({
  failedMetrics,
  onClose,
}: FailedMetricsModalProps) => {
  return (
    <>
      <Modal.Header>Required Tests for Handoff</Modal.Header>
      <Modal.Body>
        <Text textAlign="center">
          The test criteria below has failed and is required for handoff. Please resolve
          the issues below and try again.
        </Text>

        <MetricResultsTable metricResults={failedMetrics} />
      </Modal.Body>

      <div css={buttonContainerStyles}>
        <Modal.Actions primaryLabel="Okay" onPrimaryClick={onClose} css={buttonStyles} />
      </div>
    </>
  );
};

const buttonContainerStyles = css`
  display: flex;
  justify-content: center;
`;

const buttonStyles = css`
  width: 250px !important;
  button {
    height: 48px;
  }
`;
