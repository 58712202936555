import React from 'react';
import {
  IconButton,
  InfoRoundIconSmall,
  Text,
  usePopoverDialog,
  PopoverDialog,
  TextLink,
} from '@weave/design-system';

export const TenDLCPhoneStatusInfoButton = () => {
  const { getTriggerProps, getDialogProps } = usePopoverDialog<
    HTMLButtonElement | HTMLAnchorElement
  >({
    placement: 'right',
  });

  return (
    <>
      <IconButton
        label="10DLC Phone Status Information"
        size="small"
        {...getTriggerProps()}
      >
        <InfoRoundIconSmall />
      </IconButton>
      <PopoverDialog {...getDialogProps()} css={{ maxWidth: '500px' }}>
        <Text>This status is based only on this location's default sms number.</Text>
        <Text>
          To register a phone number go to{' '}
          <TextLink to={`settings/tendlc`}>{'WAM > Settings > 10DLC'}</TextLink>.
        </Text>

        <Text css={{ margin: 0 }}>
          Registration means that the phone number is registered to a TCR campaign. This
          means that sms traffic on that phone number is recognized by the phone carriers.
        </Text>
      </PopoverDialog>
    </>
  );
};
