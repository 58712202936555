import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/core';

import * as styles from './auto-reminder-style';
import { useTemplates } from './auto-reminder-templates.hook';
import { theme } from '@weave/theme-original';
import {
  OptionSwitchField,
  PrimaryButton,
  Text,
  TextLink,
  useFormField,
  MessageTemplate,
} from '@weave/design-system';
import {
  replaceTagsWithExample,
  templateTagExamples,
} from '../auto-reminders-helpers/auto-reminder-helpers';
import { NotificationSetting } from '../../recall-reminders/recall-settings.hook';
import { DocumentSelect } from './document-select';
import { useTagLists } from '../tags-list';
import { standardizeTemplateTextTags } from '../../template-tag-utils';

const toggleOptsObj = {
  single: 'text',
  multi: 'multi_template_text',
};

export type Document = {
  id: string;
  name: string;
  type: DocumentType;
};

export type Language = 'Default' | 'English' | 'Spanish' | 'Unknown';

export type Template = {
  document_id?: string;
  document_type?: DocumentType;
  id: string;
  language: Language;
  multi?: string;
  single: string;
};

export enum DocumentType {
  unknown,
  form,
  packet,
}

type Props = {
  id: string;
  settings: NotificationSetting;
  historicTemplates?: Record<string, any>;
};

export const AutoReminderTemplates = ({
  id,
  settings,
  historicTemplates = {},
}: Props) => {
  const { templates, prepareTemplates, original, saveTemplates, updateTemplateDocument } =
    useTemplates(settings);
  const [isLoaded, setIsLoaded] = useState(false);
  const [templateLanguageIndex, setTemplateLanguageIndex] = useState(0);
  const templateTypeFieldProps = useFormField({ type: 'optionswitch', value: 'single' });

  const type = templateTypeFieldProps.value;
  const displayText = useMemo(
    () =>
      standardizeTemplateTextTags(
        !!templates?.length ? templates[templateLanguageIndex][type] : ''
      ),
    [isLoaded, templateLanguageIndex, type]
  ); // cannot add templates in the dependencies b/c it would update the `initialTemplate` being passed into MessageTemplate all the time

  useEffect(() => {
    if (!!templates.length) setIsLoaded(true); //needed for the initial load
  }, [templates]);

  const changesExist =
    !!original?.length &&
    (original[templateLanguageIndex][type] !== templates[templateLanguageIndex][type] ||
      original[templateLanguageIndex].document_id !==
        templates[templateLanguageIndex].document_id);
  const multiExists = !!templates?.length && !!templates[templateLanguageIndex].multi;
  const currentText =
    displayText && replaceTagsWithExample(displayText, templateTagExamples);

  const historicExists = 'type' in historicTemplates;
  const templateTitle = historicExists ? 'Removed Template' : 'Template';
  const historicTemplate =
    historicExists && `${toggleOptsObj[type]}` in historicTemplates
      ? replaceTagsWithExample(
          historicTemplates[toggleOptsObj[type]],
          templateTagExamples
        )
      : '';
  const historicText = historicTemplate === currentText ? '' : historicTemplate;

  const tags = useTagLists();
  const tagType = settings.type;
  const tagTypeMapping = {
    appointment: type === 'multi' ? 'multiTags' : 'singleTags',
    birthday: 'birthdayTags',
    order: 'eyewearTags',
    recall: 'recallTags',
    'save-the-date': 'saveTheDateTags',
    'schedule-reactivation': 'singleTags',
  };
  const reminderTags = tags[tagTypeMapping[tagType] ?? 'singleTags'];

  // @ts-ignore
  return (
    <>
      <div
        css={css`
          align-items: center;
          display: flex;
          justify-content: space-between;
        `}
      >
        <h3
          css={css`
            margin-bottom: 0;
          `}
        >
          Templates
        </h3>
        <PrimaryButton
          css={css`
            max-width: 150px;
          `}
          disabled={!changesExist}
          onClick={saveTemplates}
        >
          Save
        </PrimaryButton>
      </div>
      <hr />
      {multiExists && (
        <OptionSwitchField {...templateTypeFieldProps} label="" name="template-type">
          <OptionSwitchField.Option value="single">Individual</OptionSwitchField.Option>
          <OptionSwitchField.Option value="multi">Family</OptionSwitchField.Option>
        </OptionSwitchField>
      )}
      <div css={styles.settingContainer}>
        <div css={styles.langContainer}>
          <Text weight="bold">Languages</Text>
          {templates.map((template: Template, index) => {
            const selected = index === templateLanguageIndex ? styles.selectedTab : '';
            const language =
              template.language === 'Unknown' ? 'Default' : template.language;

            return (
              <TextLink
                css={[styles.langOpts, ...(selected ? [selected] : [])]}
                key={template.language}
                onClick={() => setTemplateLanguageIndex(index)}
              >
                {language}
              </TextLink>
            );
          })}
        </div>
        {(historicExists && templateLanguageIndex === 0) || !historicExists ? (
          <div css={styles.templateContainer}>
            <section
              css={css`
                margin-bottom: ${theme.spacing(4)};
              `}
            >
              <Text weight="bold">
                Form/Packet Link{' '}
                <Text as="span" color="light">
                  (optional)
                </Text>
              </Text>
              <DocumentSelect
                documentId={templates?.[templateLanguageIndex]?.document_id}
                templateLanguageIndex={templateLanguageIndex}
                updateTemplateDocument={updateTemplateDocument}
              />
            </section>
            <section
              css={css`
                margin-bottom: ${theme.spacing(3)};
              `}
            >
              <Text weight="bold">{templateTitle}</Text>
              {!historicExists ? (
                <MessageTemplate
                  initialTemplate={displayText}
                  tags={reminderTags}
                  onChange={(value) =>
                    prepareTemplates(templateLanguageIndex, type, value ?? '')
                  }
                >
                  <Text size="medium" weight="bold" css={headingLabel}>
                    Customize Your Message Below
                  </Text>
                  <MessageTemplate.Editor />
                  <Text size="medium" weight="bold" css={headingLabel}>
                    {'Drag & drop or click to add to your message'}
                  </Text>
                  <MessageTemplate.TagList />
                  <Text size="medium" weight="bold" css={headingLabel}>
                    Message Preview
                  </Text>
                  <MessageTemplate.Preview />
                </MessageTemplate>
              ) : historicText ? (
                <Text css={[styles.templateStyle, styles.removed]}>{historicText}</Text>
              ) : (
                <Text css={styles.noChanges}>No changes were made to this template</Text>
              )}
            </section>
          </div>
        ) : (
          <div css={styles.templateContainer}>
            <Text css={styles.noChanges}>No data available</Text>
          </div>
        )}
      </div>
    </>
  );
};

const headingLabel = css`
  margin: ${theme.spacing(2, 0)};
`;
