import React, { useEffect } from 'react';
import { theme } from '@weave/theme-original';
import { ChecklistField, Text, useFormField } from '@weave/design-system';
import { css } from '@emotion/core';
import { Modifier, ModifierKeys } from './edit-search';

const flags = [
  'Analytics Home Icon',
  'Appt Confirmations - writebacks',
  'Appt Reminders - automated',
  'Birthday Lists',
  'Birthday texts/emails - automated',
  'Chat',
  'Click-to-Call',
  'Collections List',
  'Custom Contacts',
  'Email',
  'Enterprise Analytics',
  'Eyewear Ready',
  'Fax',
  'Follow-ups List',
  'Forms',
  'Integrated',
  'List Home Icon',
  'Email Marketing',
  'Missed call text',
  'Overdue Patients List',
  'Payment Terminals',
  'Phone Home Icon',
  'Practice Analytics',
  'Quickfill',
  'Recall Reminders - automated',
  'Recovery Kit',
  'Reports - call',
  'Reports - recall',
  'Response Kit',
  'Reviews Home Icon',
  'Schedule/Events',
  'Weave Pay',
  'Web Assistant',
];

const checklistContainer = css`
  height: 82vh;
  overflow-y: auto;
  width: 450px;
  > div {
    margin: ${theme.spacing(2)};
  }
`;

type Props = {
  id: string;
  modifier: Modifier;
  updateModifier: (key: ModifierKeys, value: any) => void;
};

export const CustomizationFlags = ({ id, modifier, updateModifier }: Props) => {
  const customizationFlags = modifier?.[id]?.customizationFlags;

  const checklistProps = useFormField({ type: 'checklist', value: customizationFlags }, [
    id,
  ]);

  useEffect(() => {
    updateCustomizationFlags(checklistProps.value);
  }, [checklistProps?.value?.length]);

  const updateCustomizationFlags = (value) => {
    updateModifier('customizationFlags', value);
  };

  return (
    <div>
      <Text weight="bold">Customization Flags</Text>
      <ChecklistField
        {...checklistProps}
        css={checklistContainer}
        name="customization-flags"
        label=""
        labelPlacement="left"
      >
        {flags.map((item) => (
          <ChecklistField.Option name={item} key={item}>
            {item}
          </ChecklistField.Option>
        ))}
      </ChecklistField>
    </div>
  );
};
