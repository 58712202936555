import {
  AddTerminalTextPayload,
  DeleteSourcePayload,
  GetSyncAppInfoPayload,
  SyncAppInterface,
} from '../../../components/sync-app/sync-app.types';

export type HealthData = {
  LocationHealth?: string;
  SyncApps?: SyncAppInterface[];
};

export type SyncAppInfoError = { error: Error; sourceId: string };
export type SyncAppInfoSuccess = {
  sourceId: string;
  data: any;
  syncAppInfoKey?: string;
};

export type SyncAppReport = {
  sourceId: string;
  report: { [key: string]: string }[];
};

export type SyncAppDOVStatuses = Record<string, string>;
export type ResetDOVStatusPayload = {
  sourceId: string;
  onFinish: () => void;
};
export type ManuallyVerifyDOVStatusPayload = {
  sourceId: string;
  onFinish: () => void;
};

export enum SyncAppActions {
  GetHealth = 'GET_SYNC_APP_HEALTH',
  GetHealthSuccess = 'GET_SYNC_APP_HEALTH_SUCCESS',
  GetHealthFailure = 'GET_SYNC_APP_HEALTH_FAILURE',
  Create = 'CREATE_SYNC_APP',
  CreateSuccess = 'CREATE_SYNC_APP_SUCCESS',
  CreateFailure = 'CREATE_SYNC_APP_FAILURE',
  ClearState = 'CLEAR_SYNC_APP_STATE',
  AddReport = 'SET_SYNC_APP_REPORT',
  ClearReports = 'CLEAR_SYNC_APP_REPORTS',
  SetDOVStatuses = 'SET_DOV_STATUSES',
  RequestResetDOVStatus = 'REQUEST_RESET_DOV_STATUS',
  RequestManuallyVerifyDOVStatus = 'REQUEST_MANUALLY_VERIFY_DOV_STATUS',
  ResetDOVStatus = 'RESET_DOV_STATUS',
  ManuallyVerifyDOVStatus = 'MANUALLY_VERIFY_DOV_STATUS',
  DeleteSource = 'DELETE_SOURCE',
  DeleteSourceSuccess = 'DELETE_SOURCE_SUCCESS',
  GetInfo = 'GET_SYNC_APP_INFO',
  GetInfoSuccess = 'GET_SYNC_APP_INFO_SUCCESS',
  GetInfoFailure = 'GET_SYNC_APP_INFO_FAILURE',
  GetMultipleInfo = 'GET_SYNC_APP_MULTIPLE_INFO',
  GetMultipleInfoFailure = 'GET_SYNC_APP_MULTIPLE_INFO_FAILURE',
  ClearTerminalText = 'CLEAR_SYNC_APP_TERMINAL_TEXT',
  AddToTerminalText = 'ADD_SYNC_APP_TERMINAL_TEXT',
}

export type ResetDOVStatus = {
  type: SyncAppActions.RequestResetDOVStatus;
  payload: ResetDOVStatusPayload;
};

export type ManuallyVerifyDOVStatus = {
  type: SyncAppActions.RequestManuallyVerifyDOVStatus;
  payload: ManuallyVerifyDOVStatusPayload;
};

export const getSyncAppHealth = () => ({ type: SyncAppActions.GetHealth });
export const getSyncAppHealthSuccess = (payload: HealthData) => ({
  type: SyncAppActions.GetHealthSuccess,
  payload,
});
export const getSyncAppHealthFailure = () => ({ type: SyncAppActions.GetHealthFailure });

export const createSyncApp = () => ({ type: SyncAppActions.Create });
export const createSyncAppSuccess = (payload: any) => ({
  type: SyncAppActions.CreateSuccess,
  payload,
});
export const createSyncAppFailure = (payload: Error) => ({
  type: SyncAppActions.CreateFailure,
  payload,
});

export const clearSyncAppState = () => ({ type: SyncAppActions.ClearState });

export const addSyncAppReport = (payload: SyncAppReport) => ({
  type: SyncAppActions.AddReport,
  payload,
});
export const clearSyncAppReports = () => ({
  type: SyncAppActions.ClearReports,
});

export const setSyncAppsDOVStatus = (payload: SyncAppDOVStatuses) => ({
  type: SyncAppActions.SetDOVStatuses,
  payload,
});
export const resetSyncAppDOVStatus = (payload: ResetDOVStatusPayload) => ({
  type: SyncAppActions.RequestResetDOVStatus,
  payload,
});
export const manuallyVerifySyncAppDOVStatus = (
  payload: ManuallyVerifyDOVStatusPayload
) => ({
  type: SyncAppActions.RequestManuallyVerifyDOVStatus,
  payload,
});
export const resetSyncAppDOVStatusSuccess = (sourceId: string) => ({
  type: SyncAppActions.ResetDOVStatus,
  payload: sourceId,
});
export const manuallyVerifySyncAppDOVStatusSuccess = (sourceId: string) => ({
  type: SyncAppActions.ManuallyVerifyDOVStatus,
  payload: sourceId,
});

export const deleteSourceFunc = (payload: DeleteSourcePayload) => ({
  type: SyncAppActions.DeleteSource,
  payload,
});
export const deleteSourceSuccessFunc = (payload: DeleteSourcePayload) => ({
  type: SyncAppActions.DeleteSourceSuccess,
  payload,
});
export const getSyncAppInfo = (payload: GetSyncAppInfoPayload) => ({
  type: SyncAppActions.GetInfo,
  payload,
});
export const getSyncAppInfoSuccess = (payload: SyncAppInfoSuccess) => ({
  type: SyncAppActions.GetInfoSuccess,
  payload,
});
export const getSyncAppInfoFailure = (payload: SyncAppInfoError) => ({
  type: SyncAppActions.GetInfoFailure,
  payload,
});

export const getMultipleSyncAppInfo = (payload: GetSyncAppInfoPayload[]) => ({
  type: SyncAppActions.GetMultipleInfo,
  payload,
});
export const getMultipleSyncAppInfoFailure = (payload: Error) => ({
  type: SyncAppActions.GetMultipleInfoFailure,
  payload,
});

export const clearTerminalText = (payload: string) => ({
  type: SyncAppActions.ClearTerminalText,
  payload,
});
export const addToTerminalText = (payload: AddTerminalTextPayload) => ({
  type: SyncAppActions.AddToTerminalText,
  payload,
});
