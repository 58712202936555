import React from 'react';
import { PrimaryButton } from '@weave/design-system';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { css } from '@emotion/core';

import { SearchForm } from './history-list-search.component';
import { Endpoint } from '../../../redux/actions/history/endpoints';
import getTableConfig from '../table-configs';
import ServerDataTableComponent from '../../shared/server-data-table/server-data-table.component';
import { history } from '../../../redux/store/history';
import { Page } from '../../shared';
import { SETTINGS, HISTORY, injectParams } from '../../../constants/paths';
import { AutoReminderModalContainer } from '../../settings/auto-reminders/auto-reminder-modal-container/auto-reminder-modal.container';
import { parseDate } from '../../../redux/actions/smart-alerts/endpoints';

interface Props {
  data: any[];
  getData: (
    locationId: string,
    title: Endpoint,
    searchParams: any,
    formatter: (data: any) => any[]
  ) => void;
  loading: boolean;
  locationId: string;
  title: Endpoint;
  commxMigrationFlag: boolean;
}

interface State {
  reminderID: string;
  modalOpen: boolean;
  refreshing: boolean;
  searchText: string;
  startDate: Date;
  endDate: Date;
}

export class HistoryList extends React.Component<Props, State> {
  defaultPageSize = 25;

  constructor(props) {
    super(props);
    moment.locale('en');
    momentLocalizer();

    this.state = {
      reminderID: '',
      modalOpen: false,
      refreshing: false,
      searchText: '',
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: new Date(),
    };
  }

  // This same component is used for several pages and only the title prop will be different
  // So we need to reload the data if the location or the title changes
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.locationId !== prevProps.locationId ||
      this.props.title !== prevProps.title
    ) {
      this.setState(
        {
          searchText: '',
          startDate: moment().subtract(1, 'week').toDate(),
          endDate: new Date(),
        },
        this.refresh
      );
    }

    // The refresh is used to reset the page number in the ServerDataTable Component when the filter params change
    if (this.state.refreshing) {
      this.setState({ refreshing: false });
    }
  }

  refresh = (e?) => {
    if (e) {
      e.preventDefault();
    }

    // Setting this state variable forces the ServerDataTable to refetch the data with all the correct params
    this.setState({ refreshing: true });
  };

  handleSearchChange = (value) => {
    this.setState({ searchText: value });
  };

  handleStartDateChange = (value: Date) => {
    this.setState({ startDate: value });
  };

  handleEndDateChange = (value: Date) => {
    this.setState({ endDate: value });
  };

  loadData = (
    skip = 0,
    limit: number = this.defaultPageSize + 1,
    sortColumn = '',
    isSortDesc = false
  ) => {
    if (this.props.locationId && this.props.title) {
      const search = this.state.searchText.trim();
      const start = moment(this.state.startDate)
        .startOf('day')
        .format('YYYY-MM-DD')
        .trim();
      const end = moment(this.state.endDate).add(1, 'd').format('YYYY-MM-DD').trim();
      const isNum = /^\d+$/.test(search!);
      let title = this.props.title;
      const isSmsHistory =
        title === Endpoint.smsHistory || title === Endpoint.oldSmsHistory;
      const params = {
        limit,
        skip,
        order: (isSortDesc ? '-' : '') + sortColumn.toString().toLowerCase(),
        location_id: this.props.locationId,
        person_phone: (isNum && search) || undefined,
        body: (!isNum && search) || undefined,
        start: parseDate(start!, isSmsHistory),
        end: parseDate(end!, isSmsHistory),
        search: isSmsHistory ? undefined : this.state.searchText.trim() || undefined,
      };

      const formatter = (data: any): any[] => data.smsList;

      // Keep this migration flag separate to avoid affecting the other history pages
      if (isSmsHistory) {
        title = this.props.commxMigrationFlag
          ? Endpoint.smsHistory
          : Endpoint.oldSmsHistory;
      }
      this.props.getData(this.props.locationId, title, { params }, formatter);
    }
  };

  handleRowClick = (rowInfo, column) => {
    if (this.props.title === 'auto-msg-queue') {
      if (column.id === 'notification_setting_id') {
        const reminderId = rowInfo.original.notification_setting_id;
        history.push(
          injectParams(
            SETTINGS.autoReminders,
            { id: this.props.locationId },
            { reminderId }
          )
        );
      }
      if (column.id === 'patientId') {
        history.push(
          injectParams(
            HISTORY.patientDetails,
            { id: this.props.locationId },
            { personid: rowInfo.original.sms.personId }
          )
        );
      }
    }
    if (this.props.title === 'sms-history') {
      const uuid = rowInfo?.original?.id;
      if (uuid) {
        history.push(
          injectParams(HISTORY.smsDetails, { id: this.props.locationId }, { uuid })
        );
      }
    }
  };

  render() {
    const config = getTableConfig(this.props.title);
    const columns = config.columns;

    const defaultSortColumn = columns.find((column) => !!column.isDefaultSort);
    const sortColumnId = defaultSortColumn ? defaultSortColumn.id : columns[0].id;

    return (
      <Page
        title={config.title}
        subtitle={config.subTitle}
        headerActions={
          <div>
            {config.controlPanel}
            <PrimaryButton
              size="small"
              css={css`
                margin-left: 16px;
                width: auto;
              `}
              onClick={this.refresh}
            >
              Refresh Data
            </PrimaryButton>
          </div>
        }
      >
        {!!this.state.reminderID && (
          <AutoReminderModalContainer
            onRequestClose={() => this.setState({ ...this.state, reminderID: '' })}
            content="template"
            reminderId={this.state.reminderID}
          />
        )}
        <div>{config.chart}</div>
        <div className="history-list">
          <div className="datatable-library-container">
            {(!!config.options.searchFields.length || config.options.showDateRange) && (
              <SearchForm
                configOptions={config.options}
                searchText={this.state.searchText}
                handleSearchChange={this.handleSearchChange}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                handleStartDateChange={this.handleStartDateChange}
                handleEndDateChange={this.handleEndDateChange}
                refresh={this.refresh}
              />
            )}
            <ServerDataTableComponent
              columns={columns}
              data={this.props.data}
              sortable={true}
              defaultSortColumn={config.options?.hideSort ? '' : sortColumnId}
              loading={this.props.loading}
              loadData={this.loadData}
              resetPage={this.state.refreshing}
              getTdProps={(state, rowInfo, column) => ({
                onClick: () => this.handleRowClick(rowInfo, column),
              })}
            />
          </div>
        </div>
      </Page>
    );
  }
}
