import { PrimaryButton } from '@weave/design-system';
import React from 'react';
import { useCustomizationFlagV2Store } from '../providers/customization-flag-editable-provider';

export const EditCustomizationButton = () => {
  const { customizationFlagEditModalProps, disableBulkEditCustomization } =
    useCustomizationFlagV2Store();
  const { onClick } = customizationFlagEditModalProps.triggerProps;
  return (
    <PrimaryButton onClick={onClick} disabled={disableBulkEditCustomization}>
      Edit Customization
    </PrimaryButton>
  );
};
