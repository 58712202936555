import { css } from '@emotion/core';
import { FontColorType } from '@weave/design-system';
import { theme } from '@weave/theme';
import {
  CustomizationFlagStatesV2,
  LocationFeatureV2,
} from 'models/location-feature.model';
import { VerticalEnum } from 'models/vertical-enum';
import {
  BundlePackagesEnum,
  CustomizationFlagsRequest,
  IntegrationPackagesEnum,
  SalesforceAccountProductBundle,
} from './customization-page-v2.types';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';

export const getCustomizationFlagByBundlesRequest = (
  packageBundle: SalesforceAccountProductBundle,
  verticalId: VerticalEnum
): CustomizationFlagsRequest => {
  return {
    products: [packageBundle],
    vertical: verticalId,
  };
};

export const getCustomizationFlagStateColor = (
  state: CustomizationFlagStatesV2
): FontColorType => {
  switch (state) {
    case CustomizationFlagStatesV2.ACTIVE:
      return 'success';
    case CustomizationFlagStatesV2.HIDE:
      return 'error';
    default:
      return 'default';
  }
};

export const getCustomizationFlagStateBackgroundColor = (
  state: CustomizationFlagStatesV2
) => {
  switch (state) {
    case CustomizationFlagStatesV2.ACTIVE:
      return css`
        background-color: ${theme.colors.success5};
      `;
    case CustomizationFlagStatesV2.HIDE:
      return css`
        background-color: ${theme.colors.critical10};
      `;
    case CustomizationFlagStatesV2.TRIAL:
      return css`
        background-color: ${theme.colors.warning5};
      `;
    default:
      return css`
        background-color: ${theme.colors.neutral10};
      `;
  }
};

export const getCustomizationFlagLabelByState = (state: CustomizationFlagStatesV2) => {
  switch (state) {
    case CustomizationFlagStatesV2.ACTIVE:
      return 'Show';
    case CustomizationFlagStatesV2.HIDE:
      return 'Hide';
    case CustomizationFlagStatesV2.TRIAL:
      return 'Trial';
    default:
      return 'Promote';
  }
};

export const getIntegrationPackageName = (
  integrationPackage: IntegrationPackagesEnum
) => {
  switch (integrationPackage) {
    case IntegrationPackagesEnum.CORE:
      return IntegrationPackagesEnum.CORE;
    case IntegrationPackagesEnum.PLUS:
      return IntegrationPackagesEnum.PLUS;
  }
};

export const getBundlePackageName = (bundlePackage: BundlePackagesEnum) => {
  switch (bundlePackage) {
    case BundlePackagesEnum.ULTIMATE:
      return BundlePackagesEnum.ULTIMATE;
    case BundlePackagesEnum.ELITE:
      return BundlePackagesEnum.ELITE;
    case BundlePackagesEnum.ESSENTIALS:
      return BundlePackagesEnum.ESSENTIALS;
    case BundlePackagesEnum.PRO:
      return BundlePackagesEnum.PRO;
  }
};

export const SOFTWARE_ONLY = `SOFTWARE_ONLY`;

export const getProductPackageBundleName = (
  integrationPackage: IntegrationPackagesEnum,
  bundlePackage: BundlePackagesEnum,
  isSoftwareOnly?: boolean
): SalesforceAccountProductBundle | '' => {
  const productBundleBase = `PRODUCT_BUNDLE_WEAVE`;
  const productBundleIntegration = isSoftwareOnly
    ? `${integrationPackage}_${SOFTWARE_ONLY}`
    : integrationPackage;

  const productBundlePackage = bundlePackage;
  const productBundleName = `${productBundleBase}_${productBundleIntegration}_${productBundlePackage}`;

  const isProductBundleNameInSalesforceProductBundle = Object.values(
    SalesforceAccountProductBundle
  ).includes(productBundleName as SalesforceAccountProductBundle);

  if (isProductBundleNameInSalesforceProductBundle) {
    return productBundleName as SalesforceAccountProductBundle;
  }
  return '';
};

export const concatenateWithCommasAnd = (array: string[]) => {
  switch (array.length) {
    case 0:
      return '';
    case 1:
      return array[0];
    case 2:
      return `${array[0]} and ${array[1]}`;
    default:
      const lastElement = array.pop();
      const concatenatedString = array.join(', ');
      return `${concatenatedString} and ${lastElement}`;
  }
};

export const isQuotaEnabledForFeature = (feature: LocationFeatureV2) => {
  return [Feature.BULK_MESSAGING, Feature.EMAIL_MARKETING].includes(feature.featureEnum);
};

export const getQuotaTextByFeature = (featureEnum?: Feature) => {
  switch (featureEnum) {
    case Feature.EMAIL_MARKETING:
      return 'Emails';
    default:
      return 'Messages';
  }
};

export const getQuotaAmountText = (
  quota: string | number,
  featureEnum?: Feature
): string => {
  return quota
    ? `${Number(quota).toLocaleString('en-US', {
        maximumFractionDigits: 0,
      })} ${getQuotaTextByFeature(featureEnum)}`
    : '';
};
