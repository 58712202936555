import React, { useEffect } from 'react';
import {
  DropdownField,
  FormRow,
  Modal,
  ModalControlModalProps,
  PrimaryButton,
  TextareaField,
  TextField,
  useForm,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';

const FORM_OVERRIDE_REASONS = [
  'Chrome Issue',
  'Internet Speed Test',
  'Unable to Sign In',
  'Customer Firewalls',
  'Previous Weave User',
  'Form Not Sending',
  'Form Bug',
  'Email Not Received',
  'Satellite Loco',
  'SF issue',
  'Onboarding Manager',
  'Other',
];

export interface OverrideFormType {
  reason?: string;
  notes?: string;
  code?: string;
}

type Props = {
  modalProps: ModalControlModalProps;
  onProceed: (formData: OverrideFormType) => void;
};

export const CompleteFormModal = ({ modalProps, onProceed }: Props) => {
  const { formProps, getFieldProps, reset } = useForm({
    fields: {
      reason: {
        type: 'text',
        required: true,
      },
      notes: { type: 'text', required: true },
      code: {
        type: 'text',
        required: true,
      },
    },
    onSubmit: onProceed,
  });

  useEffect(() => {
    if (!modalProps.show) {
      reset();
    }
  }, [modalProps.show]);

  return (
    <Modal {...modalProps}>
      <Modal.Header>Override Form</Modal.Header>
      <Modal.Body>
        <form {...formProps} css={css({ padding: theme.spacing(1, 0) })}>
          <FormRow>
            <DropdownField {...getFieldProps('reason')} label="Reason">
              {FORM_OVERRIDE_REASONS.map((reason) => (
                <DropdownField.Option key={reason} value={reason}>
                  {reason}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
          <FormRow>
            <TextareaField {...getFieldProps('notes')} label="Notes" />
          </FormRow>
          <FormRow>
            <TextField {...getFieldProps('code')} label="Code" />
          </FormRow>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </form>
      </Modal.Body>
    </Modal>
  );
};
