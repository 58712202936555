import React from 'react';
import { css } from '@emotion/core';
import {
  Modal,
  ModalControlModalProps,
  SpinningLoader,
  Text,
} from '@weave/design-system';

interface DeleteInvoiceModalProps {
  modalProps: ModalControlModalProps;
  onDeleteInvoice: () => void;
  isLoading: boolean;
}

export const DeleteInvoiceModal = ({
  modalProps,
  onDeleteInvoice,
  isLoading,
}: DeleteInvoiceModalProps) => {
  return (
    <Modal {...modalProps}>
      <Modal.Header>Delete Invoice?</Modal.Header>
      <Modal.Body css={modalBodyStyles}>
        {isLoading && <SpinningLoader />}
        {!isLoading && (
          <>
            <Text textAlign="center">Are you sure you want to delete the invoice?</Text>
            <Text weight="bold">
              Note: Deleting an invoice will permanently remove it from the customer's
              billing invoice section in the weave portal. Please exercise caution and
              consider the consequences of this action before proceeding.
            </Text>
          </>
        )}
      </Modal.Body>
      <Modal.Actions
        destructive
        primaryLabel="Delete"
        onPrimaryClick={onDeleteInvoice}
        secondaryLabel="Cancel"
        onSecondaryClick={modalProps.onClose}
        disablePrimary={isLoading}
        disableSecondary={isLoading}
      />
    </Modal>
  );
};

const modalBodyStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});
