import { useEffect } from 'react';

interface UseOnBeforeUnloadParams {
  shouldCaptureEvent: boolean;
  confirmationMessage: string;
}

export const useOnBeforeUnload = ({
  shouldCaptureEvent,
  confirmationMessage,
}: UseOnBeforeUnloadParams) => {
  /**
   * @desc: This method will help us to capture the onBeforeUnload event which will help us capture
   *        events like tab close, tab reload and will help us to show user a confirmation message
   *        that they have unsaved changes within their current state of the application
   * @note The confirmation message set in the return value will not be visible since very modern browser
   *       has removed the support for showing custom message. Here its been passed in order to maintain
   *       a backward compatibility
   * @param e: BeforeUnloadEvent
   */
  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = confirmationMessage;
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      alert(confirmationMessage);
    }
  };

  useEffect(() => {
    if (shouldCaptureEvent) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      document.addEventListener('visibilitychange', handleVisibilityChange);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [shouldCaptureEvent]);
};
