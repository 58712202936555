import React, { useState, useEffect, useMemo } from 'react';
import {
  Text,
  Modal,
  useFormField,
  DatePickerField,
  ValidatorFieldState,
  DropdownField,
  TextareaField,
  useControlledField,
  ModalControlModalProps,
  ContentLoader,
} from '@weave/design-system';
import {
  convertUsDateToIsoDateFormat,
  getUTCDateWithPortingTimeString,
  isHoliday,
  isWeekend,
  getDateArray,
} from '../utils/porting-utils';
import { format } from 'date-fns';
import { portingSchemaApi } from '../../../apis/porting/porting.api';
import { useAlert } from '@weave/alert-system';
import { PortingData } from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import { PortStatus } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';
import { theme } from '@weave/theme';
import { PortingQueries } from 'apis/porting/porting.queries';
import { getParsedErrorMessage } from 'redux/axios';

interface Props extends ModalControlModalProps {
  minDate: string;
  port: PortingData;
  adminEmail: string;
  updatePortDetailsByFoc: (port) => void;
}

export const SupRequestModal = (props: Props) => {
  const alert = useAlert();
  const { minDate, port, adminEmail, updatePortDetailsByFoc, ...modalProps } = props;

  const { data: focDates = [], isLoading } = PortingQueries.useGetAvailableFOCDates(
    {
      portingDataId: port.id,
      provider: port.provider,
    },
    {
      enabled: modalProps.show,
      onError: (error) => {
        alert.error(`Failed to fetch FOC dates: ${getParsedErrorMessage(error) ?? ''}`);
      },
    }
  );

  const { absent, addedTimeDetailsForDates, availableDates, minDateFoc, maxDateFoc } =
    useMemo(() => {
      const availableDates = focDates.map((item) => item.split('T')[0]);
      const minDateFoc = availableDates?.length
        ? format(new Date(availableDates?.[0]), 'MM/dd/yyyy')
        : '';
      const maxDateFoc = availableDates?.length
        ? format(new Date(availableDates?.[availableDates?.length - 1]), 'MM/dd/yyyy')
        : '';

      return {
        addedTimeDetailsForDates: focDates?.[0]?.split('T')[1],
        availableDates,
        minDateFoc: minDateFoc,
        maxDateFoc: maxDateFoc,
        absent: availableDates?.length
          ? getDateArray(new Date(minDateFoc), new Date(maxDateFoc)).filter(
              (date) => !availableDates.includes(date)
            )
          : null,
      };
    }, [focDates]);

  const [requestPortDateError, setRequestPortDateError] = useState<string>('');
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const fieldSubReasonProps = useFormField({ type: 'dropdown' });
  const [otherReasons, setOtherReasonsField] = useState<string>('');
  const fieldPropsAddress = useControlledField({
    type: 'text',
    value: otherReasons,
    onChange: (value) => setOtherReasonsField(value),
  });

  const portingRequest = port.portingRequests?.[0];

  const requestedSupPortDate = useFormField(
    {
      type: 'datePicker',
      maxDate: maxDateFoc,
      minDate: minDateFoc,
      blackoutDates: [minDate, ...(absent ?? [])],

      validator: ({ value }: ValidatorFieldState<'datePicker'>) => {
        const formattedValue = convertUsDateToIsoDateFormat(value);
        const formattedMinDate = convertUsDateToIsoDateFormat(minDate);

        return formattedValue < formattedMinDate
          ? 'Requested port date cannot be set in the past'
          : '';
      },
    },
    [minDateFoc, maxDateFoc, absent]
  );

  const sendSelectedFocDate = async (
    portingDataId: string,
    selectedDate: string
  ): Promise<void> => {
    if (!portingRequest) return;

    const selectedFocDate = `${selectedDate}T${addedTimeDetailsForDates}`;
    try {
      const response = await portingSchemaApi.portInSup({
        portingDataId,
        focDate: selectedFocDate,
        comments: otherReasons,
        supReason: fieldSubReasonProps.value,
        adminEmail,
      });
      const status = response.status?.toLowerCase();

      alert.success('Raised SUP Successfully');
      if (response?.requestedFocDate)
        // Not sure why portingRequest properties are being directly mutated in this function
        // but I am keeping it the same for now as part of this refactor to avoid adding
        // any new bugs.
        portingRequest.requestedFirmOrderCommitmentDate = format(
          new Date(response?.requestedFocDate),
          'MM/dd/yy'
        );
      if (response?.actualFocDate) {
        portingRequest.firmOrderCommitmentDate = format(
          new Date(response?.actualFocDate),
          'MM/dd/yy'
        );
      }

      portingRequest.portingStatus = status as PortStatus;
      handlePortChange(port);
    } catch (error) {
      alert.error(`Failed raising SUP Request ${error}`);
    } finally {
      modalProps.onClose();
    }
  };

  const handlePortChange = (port: PortingData) => {
    updatePortDetailsByFoc(port);
  };

  useEffect(() => {
    if (requestedSupPortDate.value) {
      const portDate = getUTCDateWithPortingTimeString(requestedSupPortDate.value);
      if (isHoliday(portDate) || isWeekend(portDate)) {
        setRequestPortDateError(
          'Please select date which does not fall on weekend or holiday'
        );
      } else {
        setRequestPortDateError('');
        const requestSUPDate = format(new Date(requestedSupPortDate.value), 'yyyy-MM-dd');
        const isDateAvailable = availableDates.some((date) => {
          return date === requestSUPDate;
        });
        if (isDateAvailable && !!fieldSubReasonProps.value) {
          setDisableSubmitButton(isDateAvailable);
          if (fieldSubReasonProps.value !== 'Other') {
            setDisableSubmitButton(!isDateAvailable);
          } else {
            otherReasons.length >= 5 && setDisableSubmitButton(!isDateAvailable);
          }
          return;
        }
      }
    }
  }, [
    requestedSupPortDate.value,
    availableDates,
    otherReasons,
    fieldSubReasonProps.value,
  ]);

  const onSubmit = () => {
    const requestSUPDate = format(new Date(requestedSupPortDate.value), 'yyyy-MM-dd');
    sendSelectedFocDate(port.id ?? '', requestSUPDate);
  };

  return (
    <Modal {...modalProps}>
      <Modal.Header>SUP Request</Modal.Header>
      <Modal.Body
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
          padding: theme.spacing(2, 0),
          minHeight: 250,
        }}
      >
        <ContentLoader show={isLoading} />
        <Text>
          To change the FOC date (porting data) for this order, please select a new date
          below. Your request will be automatically submitted to the appropriate party
          provider.
        </Text>

        {!isLoading && (
          <DatePickerField
            {...requestedSupPortDate}
            name="requestedSupPortDate"
            label="Port Date"
          />
        )}

        <DropdownField name="sup_reason" label="Sup Reason" {...fieldSubReasonProps}>
          <DropdownField.Option value=" Missed Nationwide Install">
            Missed Nationwide Install
          </DropdownField.Option>
          <DropdownField.Option value="Phones Did Not Arrive">
            Phones Did Not Arrive
          </DropdownField.Option>
          <DropdownField.Option value="POC Not in Office">
            POC Not in Office
          </DropdownField.Option>
          <DropdownField.Option value="Office Not Ready To Port">
            Office Not Ready To Port
          </DropdownField.Option>
          <DropdownField.Option value="Phones Missing Power Cords">
            Phones Missing Power Cords
          </DropdownField.Option>
          <DropdownField.Option value="Install Rescheduled">
            Install Rescheduled
          </DropdownField.Option>
          <DropdownField.Option value="Missed Remote Install">
            Missed Remote Install
          </DropdownField.Option>
          <DropdownField.Option value="Network Audit Issue">
            Network Audit Issue
          </DropdownField.Option>
          <DropdownField.Option value="Numbers Tied To Internet">
            Numbers Tied To Internet
          </DropdownField.Option>
          <DropdownField.Option value="Numbers Tied To CC Machine">
            Numbers Tied To CC Machine
          </DropdownField.Option>
          <DropdownField.Option value="Numbers Tied To Security System">
            Numbers Tied To Security System
          </DropdownField.Option>
          <DropdownField.Option value="COVID">COVID</DropdownField.Option>
          <DropdownField.Option value="Doesn't Want Weave">
            Doesn't Want Weave
          </DropdownField.Option>
          <DropdownField.Option value="Porting Issue">Porting Issue</DropdownField.Option>
          <DropdownField.Option value=">Training Issue">
            Training Issue
          </DropdownField.Option>
          <DropdownField.Option value="Other">Other</DropdownField.Option>
        </DropdownField>
        {fieldSubReasonProps.value === 'Other' && (
          <TextareaField
            name="reasons"
            label="Other reason(s) if any:"
            helperText="Please enter atleast 5 characters"
            autoGrow={[1, 3]}
            {...fieldPropsAddress}
          />
        )}

        {requestPortDateError && <Text color="error">{requestPortDateError}</Text>}
      </Modal.Body>
      <Modal.Actions
        secondaryLabel="Cancel"
        onSecondaryClick={modalProps.onClose}
        primaryLabel="Submit"
        onPrimaryClick={onSubmit}
        disablePrimary={disableSubmitButton}
      />
    </Modal>
  );
};
