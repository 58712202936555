import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { KeyNames, SearchField, useFormField } from '@weave/design-system';
import { css } from '@emotion/core';
import { PortingData } from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';

interface Props {
  portOrders: PortingData[];
  setSearchPortResult: Dispatch<SetStateAction<PortingData[]>>;
}

export const SearchPortingData = (props: Props) => {
  const { portOrders, setSearchPortResult } = props;

  const searchFieldProps = useFormField({ type: 'text', value: '' });

  useEffect(() => {
    if (!searchFieldProps.value.length) {
      setSearchPortResult(portOrders);
    }
  }, [searchFieldProps]);

  const getSearchData = () => {
    if (!searchFieldProps.value.length) {
      setSearchPortResult(portOrders);
      return;
    }

    if (!(searchFieldProps?.value?.length >= 5)) {
      return;
    }

    let filteredPortList: PortingData[] = [];

    portOrders?.forEach((portOrder) => {
      // Check if the portingData id includes the search value
      if (portOrder?.id?.includes(searchFieldProps.value)) {
        filteredPortList.push(portOrder);
        return;
      }

      // Check if any porting request includes the search value
      if (
        portOrder?.portingRequests?.some(
          (portingRequest) =>
            portingRequest?.phoneNumber?.includes(searchFieldProps.value) ||
            portingRequest?.portOrderNumber?.includes(searchFieldProps.value)
        )
      ) {
        filteredPortList.push(portOrder);
      }
    });

    setSearchPortResult(filteredPortList);
  };

  searchFieldProps.onBlur = () => {
    getSearchData();
  };

  const handleSearchOnKeyUp = (e) => {
    if (e.key === KeyNames.Enter) {
      getSearchData();
    }
  };

  return (
    <div
      css={css`
        margin-right: 20px;
        width: 250px;
      `}
    >
      <SearchField
        name="search"
        label="Search"
        {...searchFieldProps}
        onKeyUp={handleSearchOnKeyUp}
        helperText="Please enter atleast 5 digit to search"
      />
    </div>
  );
};
