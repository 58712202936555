import React, { useState } from 'react';
import {
  containerButtonStyle,
  resetButton,
  radioFieldStyle,
  checkboxFieldStyle,
  deleteIconStyle,
  radioFieldSelectStyle,
  mainContainer,
  tableContainer,
  tableRow,
  heading,
  rowItem,
  textAreaFieldStyle,
  postalCodeLableStyle,
  deleteIconContainer,
  containerDivRadioField,
  textAreaFieldPostalStyle,
  textAreaFieldCountryStyle,
} from './e911-dashboard.styles';
import {
  CheckboxField,
  useFormField,
  useControlledField,
  RadioField,
  PrimaryButton,
  Modal,
  useModalControl,
  TextField,
  DeleteIcon,
  TextareaField,
  FormRow,
  useForm,
  FormFieldActionTypes,
  PostalCodeLocale,
  DropdownField,
  PostalCodeField,
  ContentLoader,
} from '@weave/design-system';
import {
  getProvisionData,
  postE911AddressProvisionData,
  getChildLocationDetailsForMultiOffice,
} from '../../apis/porting/porting.api';
import {
  e911FullAddressInfo,
  childLocationDetails,
  e911AddressToBeSend,
} from '../../apis/porting/porting.types';
import { useAlert } from '@weave/alert-system';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';
import { TenantId } from './e911-dashboard.constant';
import _ from 'lodash';
import DropDownChildLocationComponent from './DropDown-childlocation.component';
import { stateCodes, stateCodeType } from '../porting-hub/porting-card/state-codes';
import { CountryCodes } from '../porting-hub/utils/port-constants';
import { css } from '@emotion/core';
import { marginRight5 } from 'components/location/location-form/location-form.styles';

const RetryComponent = ({ retryData }) => {
  const { modalProps, triggerProps } = useModalControl();
  const showAddressAlert = useAlert();
  const fieldPropscheckbox = useFormField({ type: 'checkbox' });
  const [selectedChildLocation, setSelectedChildLocation] = useState<string[]>([]);
  const [radioFieldValue, setRadioFieldValue] = useState<string>('');
  const addressRadioFieldProps = useControlledField({
    type: 'radio',
    value: radioFieldValue,
    onChange: (value: string) => {
      setRadioFieldValue(value);
    },
  });

  const [addressField, setAddressField] = useState<string>('');
  const fieldPropsAddress = useControlledField({
    type: 'text',
    value: addressField,
    required: true,
    onChange: (value) => setAddressField(value),
  });
  const [streetField, setStreetField] = useState<string>('');
  const fieldPropsStreet = useControlledField({
    type: 'text',
    value: streetField,
    required: true,
    onChange: (value) => setStreetField(value),
  });
  const [secondaryField, setSecondaryField] = useState<string>('');
  const fieldPropsSecondary = useControlledField({
    type: 'text',
    value: secondaryField,
    onChange: (value) => setSecondaryField(value),
  });
  const [cityPropField, setCityPropField] = useState<string>('');
  const fieldPropsCity = useControlledField({
    type: 'text',
    value: cityPropField,
    required: true,
    onChange: (value) => setCityPropField(value),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<e911FullAddressInfo[]>([]);
  const [showAddresdField, setShowAddressField] = useState<boolean>(false);
  const [childLocationDetail, setChildLocationDetail] =
    useState<[childLocationDetails]>();
  const [currentSelectedChildLocation, setCurrentSelectedChildLocation] =
    useState<string>('');
  const [filteredStates, setFilteredStates] = useState<stateCodeType[]>([]);
  const tenantID = TenantId;
  const textAreaValue = radioFieldValue === 'true';

  const tenantId = retryData?.cell?.row?.allCells?.find(
    (item) => item?.column?.Header === tenantID
  )?.value;
  let sharedMultilocation;
  let locationId;
  retryData?.cell?.row?.allCells?.some((item) => {
    if (item.column.Header) {
      return (
        (sharedMultilocation = item?.row?.values?.isShared || false),
        (locationId = item?.row?.values.locationId)
      );
    }
  });
  const {
    formProps: portingAddressProps,
    getFieldProps,
    values,
  } = useForm({
    fields: {
      postalCode: {
        type: 'postalCode',
        required: true,
      },
      state: {
        type: 'dropdown',
        required: true,
      },
      country: {
        type: 'dropdown',
        required: true,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update) {
        switch (action.payload.name) {
          case 'country': {
            const filterStates = stateCodes.filter(
              (st: stateCodeType) => action.payload.value === st.country
            );
            setFilteredStates(filterStates);
            return {
              ...state,
              postalCode: {
                ...state.postalCode,
                locale:
                  action.payload.value === PostalCodeLocale.CA
                    ? PostalCodeLocale.CA
                    : PostalCodeLocale.US,
                value: '',
              },
              state: {
                ...state.state,
                value: '',
              },
            };
          }
          default:
            return null;
        }
      }
      return null;
    },
  });

  const { postalCode: postalCodeValue = '', state = '', country = '' } = values;
  const getChildLocationData = async () => {
    try {
      if (sharedMultilocation) {
        const data = await getChildLocationDetailsForMultiOffice(locationId);
        setChildLocationDetail([data]);
      }
    } catch (error) {
      showAddressAlert.error(`${error}`);
    }
  };

  const handleRetryClick = async () => {
    try {
      setLoading(true);
      getChildLocationData();
      const data = await getProvisionData(tenantId);
      setAddress(data?.relationalAddresses);
      setSelectedChildLocation(new Array<string>(data?.relationalAddresses?.length));
      triggerProps.onClick();
    } catch (error) {
      showAddressAlert.error(`${error}`);
    } finally {
      setLoading(false);
    }
  };
  const AddTextField = () => {
    setShowAddressField(true);
  };

  const setRadioChildLocationValueDetails = (
    radioValue: string,
    childLocationValueDetail: string
  ) => {
    const arr = [...selectedChildLocation];
    arr[radioValue] = childLocationValueDetail;
    setCurrentSelectedChildLocation(childLocationValueDetail);
    setSelectedChildLocation(arr);
  };

  const handleDisableButton = (): boolean => {
    const textFieldValues = !(
      addressField &&
      streetField &&
      cityPropField &&
      state &&
      country &&
      postalCodeValue
    );

    if (sharedMultilocation) {
      if (textAreaValue) {
        return textFieldValues || !currentSelectedChildLocation.length;
      }
      return !(radioFieldValue && selectedChildLocation[radioFieldValue]);
    } else {
      if (textAreaValue) {
        return textFieldValues;
      }
      return !radioFieldValue;
    }
  };

  const handleRetryE911Provision = async () => {
    setLoading(true);
    let addressData: e911AddressToBeSend;
    if (textAreaValue) {
      addressData = {
        city: cityPropField,
        country: country,
        postal_code: postalCodeValue,
        name: addressField,
        state: state,
        street: streetField,
        street_secondary: secondaryField,
      };
    } else {
      const selectedAddress: e911FullAddressInfo = address[radioFieldValue]?.address;
      addressData = {
        city: selectedAddress?.city,
        country: selectedAddress?.country,
        name: selectedAddress?.name,
        postal_code: selectedAddress?.postalCode,
        state: selectedAddress?.state,
        street: selectedAddress?.street,
        street_secondary: selectedAddress?.streetSecondary,
      };
    }
    const childLocationId = currentSelectedChildLocation;
    try {
      const response = await postE911AddressProvisionData(
        tenantId,
        sharedMultilocation,
        addressData,
        childLocationId
      );
      if (_.isEmpty(response)) {
        showAddressAlert.success('Please contact CPI team for further assistance');
      } else {
        if (response?.isNewNumber) {
          showAddressAlert.success(
            `E911 provisioned with new number ${response?.phoneNumber?.nationalNumber}`
          );
        } else {
          showAddressAlert.success(
            `E911 provisioned with existing number ${response?.phoneNumber?.nationalNumber}`
          );
        }
      }
      setAddressField('');
      setSecondaryField('');
      setStreetField('');
      setCityPropField('');
      setShowAddressField(false);
      modalProps.onClose();
    } catch (error) {
      let errorData = error?.response?.data?.match(/.*"cause":"(?<cause>.*?)"/);
      const errorCause = errorData?.groups?.cause;
      showAddressAlert.error(`Failed to provision E911 ${errorCause}`);
      modalProps.onClose();
    } finally {
      setLoading(false);
    }
  };
  const valueForMargin = childLocationDetail
    ? `${weaveTheme.spacing(0.25)}`
    : `${weaveTheme.spacing(2)}`;
  return (
    <>
      <div>
        <ContentLoader show={loading} />
        <PrimaryButton {...triggerProps} onClick={handleRetryClick}>
          Retry
        </PrimaryButton>
      </div>
      <Modal {...modalProps} maxWidth={1000} onClose={modalProps.onClose}>
        <Modal.Header>
          Addresses
          <div className={deleteIconContainer}>
            <DeleteIcon
              css={deleteIconStyle}
              onClick={modalProps.onClose}
              style={{ cursor: 'pointer' }}
            ></DeleteIcon>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={mainContainer}>
            <div className={tableContainer}>
              {address && (
                <div className={heading}>
                  <div
                    className={rowItem}
                    style={{ paddingLeft: `${weaveTheme.spacing(3.63)}` }}
                  >
                    Name
                  </div>
                  <div className={rowItem}>Street</div>
                  <div className={rowItem}>Secondary</div>
                  <div className={rowItem}>City</div>
                  <div className={rowItem}>Country</div>
                  <div className={rowItem}>State</div>
                  <div className={rowItem}>Postal Code</div>

                  {childLocationDetail && <div className={rowItem}>Child Location</div>}
                </div>
              )}
              {address?.map(
                (item, i) =>
                  item['e911Providers']?.length && (
                    <CheckboxField
                      {...fieldPropscheckbox}
                      disabled={true}
                      value={true}
                      name="Unify"
                      className={checkboxFieldStyle}
                      label={
                        <div className={tableRow}>
                          <div
                            className={rowItem}
                            style={{ paddingLeft: `${weaveTheme.spacing(2)}` }}
                          >
                            {item['address']?.name}
                          </div>
                          <div className={rowItem}>{item['address']?.street}</div>
                          <div className={rowItem}>
                            {item['address']?.streetSecondary}
                          </div>
                          <div className={rowItem}> {item['address']?.city}</div>
                          <div className={rowItem}> {item['address']?.country}</div>
                          <div className={rowItem}> {item['address']?.state}</div>
                          <div className={rowItem}> {item['address']?.postalCode}</div>

                          {childLocationDetail && <div className={rowItem}> </div>}
                        </div>
                      }
                    />
                  )
              )}

              <RadioField
                {...addressRadioFieldProps}
                name="radio"
                label=""
                className={radioFieldStyle}
              >
                {address?.map((item, i) =>
                  item['e911Providers']?.length ? (
                    <></>
                  ) : (
                    <div
                      style={{
                        marginLeft: `${weaveTheme.spacing(1)}`,
                        marginRight: `${weaveTheme.spacing(0.5)}`,
                      }}
                    >
                      <RadioField.Radio value={i.toString()}>
                        <div className={tableRow}>
                          <div className={rowItem}>{item['address']?.name}</div>
                          <div className={rowItem}>{item['address']?.street}</div>
                          <div className={rowItem}>
                            {item['address']?.streetSecondary}
                          </div>
                          <div className={rowItem}>{item['address']?.city}</div>
                          <div className={rowItem}>{item['address']?.country}</div>
                          <div className={rowItem}>{item['address']?.state}</div>
                          <div className={rowItem}>{item['address']?.postalCode}</div>

                          {childLocationDetail && (
                            <div className={rowItem} style={{ width: '20px' }}>
                              <DropDownChildLocationComponent
                                id={i.toString()}
                                style={true}
                                childLocationDetail={childLocationDetail}
                                setChildLocationDetails={
                                  setRadioChildLocationValueDetails
                                }
                              />
                            </div>
                          )}
                        </div>
                      </RadioField.Radio>
                    </div>
                  )
                )}
              </RadioField>

              {showAddresdField ? (
                <div
                  style={{ marginLeft: `${weaveTheme.spacing(-1.5)}`, cursor: 'pointer' }}
                >
                  <RadioField
                    {...addressRadioFieldProps}
                    name="radio"
                    label=""
                    className={radioFieldSelectStyle}
                  >
                    <RadioField.Radio value="true"> </RadioField.Radio>
                    <div></div>
                  </RadioField>

                  <div className={containerDivRadioField}>
                    <div className={tableRow}>
                      <div
                        className={rowItem}
                        style={{
                          width: '100px',
                          paddingRight: `${weaveTheme.spacing(0.6)}`,
                        }}
                      >
                        <TextareaField
                          name="name"
                          label="Name"
                          className={textAreaFieldStyle}
                          autoGrow={[1, 3]}
                          {...fieldPropsAddress}
                        />
                      </div>
                      <div
                        className={rowItem}
                        style={{ paddingRight: `${weaveTheme.spacing(0.6)}` }}
                      >
                        <TextareaField
                          name="street"
                          label="Street"
                          className={textAreaFieldStyle}
                          autoGrow={[1, 3]}
                          {...fieldPropsStreet}
                        />
                      </div>
                      <div
                        className={rowItem}
                        style={{ paddingRight: `${weaveTheme.spacing(0.6)}` }}
                      >
                        <TextareaField
                          name="secondary"
                          label="Secondary"
                          className={textAreaFieldStyle}
                          autoGrow={[1, 3]}
                          {...fieldPropsSecondary}
                        />
                      </div>
                      <div
                        className={rowItem}
                        style={{ paddingRight: `${weaveTheme.spacing(0.6)}` }}
                      >
                        <TextField
                          name="city"
                          label="City"
                          {...fieldPropsCity}
                          className={textAreaFieldStyle}
                        />
                      </div>
                      <form
                        {...portingAddressProps}
                        css={css`
                          display: flex;
                        `}
                      >
                        <div
                          className={rowItem}
                          style={{
                            marginRight: valueForMargin,
                          }}
                        >
                          <FormRow>
                            <DropdownField
                              {...getFieldProps('country')}
                              label="Country"
                              name="country"
                              className={textAreaFieldCountryStyle}
                            >
                              <DropdownField.Option value={CountryCodes.USA.Code}>
                                {CountryCodes.USA.Label}
                              </DropdownField.Option>
                              <DropdownField.Option value={CountryCodes.Canada.Code}>
                                {CountryCodes.Canada.Label}
                              </DropdownField.Option>
                            </DropdownField>
                          </FormRow>
                        </div>
                        <div
                          className={rowItem}
                          style={{
                            marginRight: valueForMargin,
                          }}
                        >
                          <DropdownField
                            {...getFieldProps('state')}
                            placeholder="State"
                            label="State"
                            name="state"
                            className={textAreaFieldStyle}
                          >
                            {filteredStates.map((state: stateCodeType) => (
                              <DropdownField.Option value={state.code} key={state.code}>
                                {state.label}
                              </DropdownField.Option>
                            ))}
                          </DropdownField>
                        </div>
                        <div
                          className={rowItem}
                          style={{
                            marginRight: valueForMargin,
                          }}
                        >
                          <FormRow>
                            <PostalCodeField
                              {...getFieldProps('postalCode')}
                              placeholder="Postal Code"
                              label="Postal Code"
                              name="postalCode"
                              className={textAreaFieldPostalStyle}
                            />
                          </FormRow>
                        </div>
                      </form>
                      {childLocationDetail && (
                        <div
                          className={rowItem}
                          style={{
                            width: '15px',
                            paddingRight: `${weaveTheme.spacing(1)}`,
                          }}
                        >
                          <DropDownChildLocationComponent
                            id="0"
                            childLocationDetail={childLocationDetail}
                            style={true}
                            setChildLocationDetails={setRadioChildLocationValueDetails}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {!showAddresdField && (
            <div className={containerButtonStyle}>
              <div className={resetButton}>
                <PrimaryButton onClick={AddTextField}>Add New Address</PrimaryButton>
              </div>
            </div>
          )}
          <ContentLoader show={loading} />
          <div className={containerButtonStyle} style={{ justifyContent: 'center' }}>
            <div className={resetButton} style={{ width: 'fit-content' }}>
              <PrimaryButton
                onClick={handleRetryE911Provision}
                disabled={handleDisableButton()}
              >
                E911 Provision
              </PrimaryButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RetryComponent;
