import { all } from 'redux-saga/effects';
import { blackListSaga } from '../actions/blacklist/blacklist.action';
import { callForwardingSaga } from '../actions/call-forwarding';
import { clientApiSaga } from '../actions/client-api';
import { dayOfWeekRemindersSaga } from '../actions/day-of-week-reminders';
import { departmentPhoneNumbersSaga } from '../actions/department-phone-numbers';
import { onboardingTasksSaga } from '../actions/onboarding-tasks';
import { userSelectSaga } from '../actions/users/user-search.action';
import { locationSearchSaga } from '../actions/location/location-search';
import { featureFlagsSaga } from '../actions/feature-flags';
import { authSaga } from '../actions/auth/auth.saga';
import { customizationSaga } from '../actions/customization/customization.action';
import { callSettingsSaga } from '../actions/call-settings/call-settings.action';
import { manualTemplatesSaga } from '../actions/manual-templates';
import { notificationsSettingsSaga } from '../actions/notifications-settings';
import { devicesSaga } from '../actions/devices/devices.actions';
import { usersSaga } from '../actions/users';
import { locationsSaga } from '../actions/location/locations';
import { currentLocationSaga } from '../actions/location/current-location';
import { locationAccessSaga } from '../actions/location/location-access/location-access.action';
import { officeHoursSaga } from '../actions/office-hours';
import { locationHistorySaga } from '../actions/location/location-history/location-history.action';
import { updateUserRolesSaga } from '../actions/users/user-roles.action';
import { autoMsgQueueSaga } from '../actions/auto-msg-queue';
import { missedCallTextSaga } from '../actions/missed-call-text/missed-call-text.action';
import { recordsSaga } from '../actions/records';
import { historySaga } from '../actions/history';
import { locationSettingsSaga } from '../actions/location/location-settings/location-settings.action';
import { twilioSaga } from '../actions/twilio';
import { desktopClientsSaga } from '../actions/desktop-clients/desktop-clients.action';
import { reviewsSaga } from '../actions/reviews';
import { userPhotoSaga } from '../actions/user-photo';
import { patientsSaga } from '../actions/patients/patients.action';
import { personSaga } from '../actions/patients/person.action';
import { appointmentsByPatientSaga } from '../actions/appointments/appointments-by-patient.action';
import { syncAppSaga } from '../actions/sync-app';
import { callBlockingSaga } from '../actions/call-blocking';
import { portingSaga } from '../actions/porting';
import { internalToolsSaga } from '../actions/internal-tools/internal-tools.saga';
import { statusMappingSettingsSaga } from '../actions/status-mapping-settings';
import { smsDetailsSaga } from '../actions/sms/sms-details';
import { smsMetadataSaga } from '../actions/sms/sms-metadata';
import { orderHistorySaga } from '../actions/orders/order-history.action';
import { recallHistorySaga } from '../actions/recall/recall-history.action';
import { emailHistorySaga } from '../actions/emails/email-history.action';
import { merchantSaga } from '../actions/merchant/merchant.action';
import { voiceMailboxesSaga } from '../actions/voice-mailboxes';
import { phoneMediaSaga } from '../actions/phone-media';
import { phoneNumbersSaga } from '../actions/phone-numbers';
import { handoffHubSaga } from '../actions/handoff-hub';
import { userSaga } from '../actions/auth/user/user.saga';
import { writebacksByApptSaga } from '../actions/writebacks/writebacks-by-appt';
import { notificationQueueSaga } from '../actions/notification-queue';
import { appointmentSaga } from '../actions/appointments/appointment';
import { pendoSaga } from '../actions/pendo';
import { eventSaga } from '../actions/events';
import { analyticsSaga } from '../actions/analytics';
import { smartAlertSaga } from '../actions/smart-alerts/smart-alerts.action';
import {
  faxHistorySaga,
  faxSettingsSaga,
  smsHistorySaga,
  syncAppSettingsSaga,
} from '../actions';
import { departmentsSaga } from '../actions/department';
import { ringtonesSaga } from '../actions/ringtones/ringtones.actions';
import { fwdNumbersSaga } from '../actions/fwd-numbers';
import { firebaseTokenSaga } from '../actions/firebase-token';
import { outageAlertsSaga } from '../actions/outage-alerts';

export function* rootSaga() {
  yield all([
    analyticsSaga(),
    appointmentSaga(),
    appointmentsByPatientSaga(),
    authSaga(),
    autoMsgQueueSaga(),
    blackListSaga(),
    callBlockingSaga(),
    callForwardingSaga(),
    callSettingsSaga(),
    clientApiSaga(),
    currentLocationSaga(),
    customizationSaga(),
    dayOfWeekRemindersSaga(),
    departmentPhoneNumbersSaga(),
    departmentsSaga(),
    desktopClientsSaga(),
    devicesSaga(),
    emailHistorySaga(),
    eventSaga(),
    faxHistorySaga(),
    faxSettingsSaga(),
    featureFlagsSaga(),
    fwdNumbersSaga(),
    handoffHubSaga(),
    historySaga(),
    internalToolsSaga(),
    locationAccessSaga(),
    locationHistorySaga(),
    locationSearchSaga(),
    locationSettingsSaga(),
    locationsSaga(),
    manualTemplatesSaga(),
    merchantSaga(),
    missedCallTextSaga(),
    notificationQueueSaga(),
    notificationsSettingsSaga(),
    officeHoursSaga(),
    onboardingTasksSaga(),
    orderHistorySaga(),
    patientsSaga(),
    personSaga(),
    pendoSaga(),
    phoneMediaSaga(),
    phoneNumbersSaga(),
    portingSaga(),
    recallHistorySaga(),
    recordsSaga(),
    reviewsSaga(),
    ringtonesSaga(),
    smartAlertSaga(),
    smsDetailsSaga(),
    smsHistorySaga(),
    smsMetadataSaga(),
    statusMappingSettingsSaga(),
    syncAppSaga(),
    syncAppSettingsSaga(),
    twilioSaga(),
    updateUserRolesSaga(),
    userPhotoSaga(),
    userSaga(),
    userSelectSaga(),
    usersSaga(),
    voiceMailboxesSaga(),
    writebacksByApptSaga(),
    firebaseTokenSaga(),
    outageAlertsSaga(),
  ]);
}
