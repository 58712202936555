import React from 'react';
import { Text } from '@weave/design-system';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { customizationPageTestIds } from '../customization-page-test-ids';

interface IProps {
  isSelected: boolean;
  onClick: () => void;
}
export const CustomizationFlagSoftwareOnlyOption = ({ isSelected, onClick }: IProps) => {
  return (
    <div
      css={softwareOnlyOptionStyles(isSelected)}
      onClick={onClick}
      data-testid={customizationPageTestIds.customizationFlagSoftwareOnlyOption}
    >
      <Text size="medium" weight={isSelected ? 'bold' : 'medium'}>
        Software Only
      </Text>
    </div>
  );
};

const softwareOnlyOptionStyles = (isSelected) => css`
  padding: ${theme.spacing(1, 3.5)};
  border: ${isSelected
    ? `2px solid ${theme.colors.weaveBlue}`
    : `1px solid ${theme.colors.gray400}`};
  border-radius: ${theme.borderRadius.small};
  width: fit-content;
  cursor: pointer;
  > p {
    margin: 0;
    color: ${isSelected ? theme.colors.weaveBlue : theme.colors.gray500};
  }
`;
