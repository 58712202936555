import React from 'react';
import { useHoverLabel, TextLink } from '@weave/design-system';

import { useClipboard } from './use-clipboard';

type CopyTextLinkProps = {
  label?: string;
  copyData: string;
};

export const CopyToClipboardLink = ({ label, copyData }: CopyTextLinkProps) => {
  const { copy, copied } = useClipboard();
  const { triggerProps, labelProps, HoverLabel } = useHoverLabel({
    placement: 'top',
  });

  return (
    <>
      <span
        //wrapper because textlink doesn't forward the ref
        {...triggerProps}
      >
        <TextLink size="large" onClick={() => copy(copyData)}>
          {label ?? 'Copy to clipboard'}
        </TextLink>
      </span>
      {copied && <HoverLabel {...labelProps}>Copied</HoverLabel>}
    </>
  );
};
