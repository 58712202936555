import React, { FocusEventHandler, useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/core';
import {
  PrimaryButton,
  DropdownField,
  TextField,
  useForm,
  FormRow,
  PostalCodeField,
  EmailField,
  Modal,
  ValidatorFieldState,
  useModalControl,
  ButtonBar,
  SecondaryButton,
  Text,
  useFormField,
  CheckboxField,
  DatePickerField,
  useControlledField,
  TextareaField,
  SpinningLoader,
  TrashIcon,
  TextLink,
  IconButton,
  FormFieldActionTypes,
  PostalCodeLocale,
} from '@weave/design-system';
import { stateCodes, stateCodeType } from '../porting-card/state-codes';
import { LoaderModal as Loader } from '../utils/loader.component';
import { MessageDialogue } from '../utils/message-dialogue.component';
import { portOrderStyles } from './port-order.styles';
import {
  portingApi as API,
  portViewBillDownload,
} from '../../../apis/porting/porting.api';
import {
  getUTCDateWithPortingTimeString,
  isHoliday,
  isWeekend,
  getFormattedNumbers,
  splitAndTrimString,
  validatePhoneNumbersRegex,
  isAlphaNumericValue,
  convertUsDateToIsoDateFormat,
} from '../utils/porting-utils';
import {
  MediaData,
  MediaType,
  NumberType,
  PortCreationFileResponseData,
  PortingData,
  PortingRequest,
  InternalNumberPortingType,
} from '../../../apis/porting/porting.types';
import { useAlert } from '@weave/alert-system';
import { PortOrderUploadComponent } from './port-order-upload.component';
import { format } from 'date-fns';
import { noop } from 'lodash';
import { CountryCodes } from '../utils/port-constants';
import { LocationModel } from '../../../redux/actions/location';
import fileDownload from 'js-file-download';
import { LoaderModal } from '../utils/loader.component';
import { theme } from '@weave/theme-original';
import { Page } from '../../shared';

interface Props {
  currentLocation: LocationModel | null;
  back: () => void;
  handleCreatePortOrder: (portOrder: PortingData) => void;
  setShowPortOrderForm: (showPortOrderForm: boolean) => void;
}

type SortedFiles = { accepted: File[]; rejected: File[]; duplicates: File[] };

type MessageModal = {
  header: string;
  body: string;
  success: boolean;
};

const successMessage = `Your port order information has been created.`;
const failMessage = `Please connect with team to check what went wrong.`;

export const PortOrder = ({
  currentLocation,
  back,
  handleCreatePortOrder,
  setShowPortOrderForm,
}: Props) => {
  const alert = useAlert();
  const [loaLoader, setLoaLoader] = useState<boolean>(false);
  const [billLoader, setBillLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [filteredStates, setFilteredStates] = useState<stateCodeType[]>([]);
  const [voiceNumbers, setVoiceNumbers] = useState<string>('');
  const [faxNumbers, setFaxNumbers] = useState<string>('');
  const [phoneNumbersError, setPhoneNumbersError] = useState<string>('');
  const [requestPortDateError, setRequestPortDateError] = useState<string>('');
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const {
    modalProps: portSubmissionModalProps,
    closeModal: closePortSubmissionModal,
    triggerProps: portSubmissionTriggerModalProps,
  } = useModalControl();
  const {
    modalProps: cancelConfirmationModalProps,
    closeModal: closeCancelModal,
    triggerProps: triggerCancelProps,
  } = useModalControl();
  const {
    modalProps: cancelInternalPorting,
    triggerProps: triggerCancelInternalPortingProps,
  } = useModalControl();
  const [messageModal, setMessageModal] = useState<MessageModal>({
    header: 'Thank You',
    body: successMessage,
    success: true,
  });
  const [portingData, setPortingData] = useState<PortingData>({} as PortingData);
  const [attachedLOA, setAttachedLOA] = useState<MediaData[]>([]);
  const [attachedBills, setAttachedBills] = useState<MediaData[]>([]);
  const [noBillAvailable, setNoBillAvailable] = useState<boolean>(false);
  const [customerAttachedFiles, setCustomerAttachedFiles] = useState<MediaData[]>([]);
  const [internalPortingValue, setInternalPortingValue] =
    useState<string>('PORTING_TYPE_MAIN');
  const [voiceNumberValues, setVoiceNumberValues] = useState<string[]>();
  const [faxNumberValues, setFaxNumberValues] = useState<string[]>();
  const [portingNumberProviderData, setPortingNumberProviderData] = useState<
    { number: string; provider: string; isFax: boolean; faxProvider: string }[]
  >([]);
  const [internalPortingNumberError, setInternalPortingNumberError] =
    useState<string>('');
  const [internalPortingFaxError, setInternalPortingFaxError] = useState<string>('');
  const [autoFillDetails, setAutoFillDetails] = useState<{
    companyName: string;
    serviceAddress: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
    authorizedUserFullName: string;
    accountNumber: string;
  }>({
    companyName: '',
    serviceAddress: '',
    city: '',
    postalCode: '',
    state: '',
    country: '',
    authorizedUserFullName: '',
    accountNumber: '',
  });
  const [uploadedFileUrl, setUploadedFileUrl] = useState<object>({});
  const [downloadLOALoading, setDownloadLOALoading] = useState<boolean>(false);
  const [duplicateFaxError, setDuplicateFaxError] = useState<boolean>(false);
  const [duplicatePhnError, setDuplicatePhnError] = useState<boolean>(false);
  const providerNumMap = new Map();
  const providerFaxMap = new Map();

  const checkNoBillField = useFormField({ type: 'checkbox' });
  const commentField = useFormField({ type: 'text' });

  const validationFailed = (providerNumMap) => {
    return providerNumMap.size !== 1;
  };

  const handleValidationError = (providerMap) => {
    let error = 'Please make sure all the numbers belong to the same provider but here';
    for (const [key, value] of providerMap) {
      error = error + `${value} belongs to provider ${key} `;
    }
    providerNumMap.size > 1 && setInternalPortingNumberError(error);
    providerFaxMap.size > 1 && setInternalPortingFaxError(error);
  };

  const isPortingTypeInternal = internalPortingValue === 'PORTING_TYPE_INTERNAL';

  const validateInternalPortingNumbers = (numValues, providersMap) => {
    if (numValues && isPortingTypeInternal) {
      portingNumberProviderData?.forEach((existingPortingNumbers) => {
        const mapData =
          existingPortingNumbers?.provider ||
          (existingPortingNumbers?.isFax === true && existingPortingNumbers?.faxProvider);

        if (numValues.indexOf(existingPortingNumbers.number) !== -1) {
          if (
            typeof existingPortingNumbers.provider === 'undefined' &&
            typeof existingPortingNumbers.faxProvider === 'undefined'
          ) {
            return;
          } else {
            if (providersMap.has(mapData)) {
              providersMap.set(
                mapData,
                providersMap.get(mapData) + ',' + existingPortingNumbers.number
              );
            } else {
              providersMap.set(
                existingPortingNumbers?.provider ||
                  (existingPortingNumbers.isFax === true &&
                    existingPortingNumbers?.faxProvider),
                existingPortingNumbers.number
              );
            }
          }
        }
      });
      if (validationFailed(providersMap)) {
        handleValidationError(providersMap);
      }
    }
  };

  const getAvailableProvider = (portingProvider) => {
    if (typeof portingProvider === 'undefined') {
      return;
    }
    let value = portingProvider?.includes('_to_')
      ? portingProvider?.split('_to_')[0]
      : portingProvider;
    value = value?.charAt(0)?.toUpperCase() + value?.slice(1);
    return value;
  };

  const getInternalPortingNumberData = async () => {
    const locationID = currentLocation?.ParentID
      ? currentLocation?.ParentID
      : currentLocation?.LocationID;
    const response = await API.getInternalPortingNumberData(locationID);
    const newPortingData = response?.map((portingData) => ({
      number: portingData.Number,
      provider: getAvailableProvider(
        portingData?.VoiceProvider?.provider ||
          portingData?.SMSProvider?.provider ||
          portingData?.E911Provider?.provider
      ),
      isFax: portingData.IsFax,
      faxProvider: getAvailableProvider(portingData?.FaxProvider?.provider),
    }));
    setPortingNumberProviderData(newPortingData);
  };

  useEffect(() => {
    validateInternalPortingNumbers(voiceNumberValues, providerNumMap);
    initValues();
  }, [voiceNumberValues, portingNumberProviderData]);

  useEffect(() => {
    validateInternalPortingNumbers(faxNumberValues, providerFaxMap);
    initValues();
  }, [faxNumberValues, portingNumberProviderData]);

  const findDuplicateValue = (arr1: string[], arr2: string[]): string[] => {
    return arr1?.filter((arr1Item) => arr2.includes(arr1Item));
  };

  useEffect(() => {
    const voiceNumberArray = voiceNumbers.split(',');
    if (faxNumberValues) {
      const result = findDuplicateValue(faxNumberValues, voiceNumberArray);
      setDuplicatePhnError(!!result?.length);
    }
    if (!voiceNumbers && isPortingTypeInternal) {
      providerNumMap.clear();
      initValues();
      setInternalPortingNumberError('');
      setVoiceNumberValues([]);
    }
  }, [voiceNumbers]);

  useEffect(() => {
    const faxNumberArray = faxNumbers.split(',');
    if (voiceNumberValues) {
      const result = findDuplicateValue(voiceNumberValues, faxNumberArray);
      setDuplicatePhnError(!!result?.length);
    }
    if (!faxNumbers && isPortingTypeInternal) {
      providerFaxMap.clear();
      initValues();
      setInternalPortingFaxError('');
      setFaxNumberValues([]);
    }
  }, [faxNumbers]);

  const today = new Date();
  const voiceNumbersFieldProps = useControlledField({
    type: 'text',
    value: voiceNumbers,
    onChange: (value) => {
      setVoiceNumbers(getFormattedNumbers(value)), setDuplicatePhnError(false);
    },
    validator: ({ value, active }: ValidatorFieldState<'text'>) => {
      if (!active) {
        if (!validatePhoneNumbersRegex(value)) {
          const msg = 'Please enter valid phone numbers';
          return msg;
        } else {
          setVoiceNumberValues([...value.split(',')]);
        }
        setPhoneNumbersError('');
        setInternalPortingNumberError('');
      }
      return '';
    },
  });
  const faxNumbersFieldProps = useControlledField({
    type: 'text',
    value: faxNumbers,
    onChange: (value) => {
      setFaxNumbers(getFormattedNumbers(value)), setDuplicateFaxError(false);
    },
    validator: ({ value, active }: ValidatorFieldState<'text'>) => {
      if (!active) {
        if (!validatePhoneNumbersRegex(value)) {
          const msg = 'Please enter valid fax numbers';
          return msg;
        } else {
          setFaxNumberValues([...value.split(',')]);
        }
        setPhoneNumbersError('');
        setInternalPortingFaxError('');
      }
      return '';
    },
  });

  const getIntialValues = () => {
    isPortingTypeInternal &&
      setAutoFillDetails({
        companyName: 'Weave',
        serviceAddress: '1331 W Powell Way',
        city: 'Lehi',
        country: 'US',
        state: 'UT',
        postalCode: '84043',
        accountNumber: voiceNumberValues
          ? voiceNumberValues?.[0]
          : faxNumberValues
          ? faxNumberValues?.[0]
          : '',
        authorizedUserFullName: 'Reid Dwiggins',
      });
  };

  const validateDataForInternalPortingCheckbox = (state) => {
    const cityValue =
      state?.city?.value?.toString()?.toLowerCase() !==
      autoFillDetails.city.toLowerCase();

    const companyNameValue =
      state?.companyName?.value?.toString()?.toLowerCase() !==
      autoFillDetails.companyName.toLowerCase();

    const postalCodeValue =
      state?.postalCode?.value?.toString()?.toLowerCase() !==
      autoFillDetails.postalCode.toLowerCase();

    const serviceAddressValue =
      state?.serviceAddress?.value.toString()?.toLowerCase() !==
      autoFillDetails.serviceAddress.toLowerCase();

    const stateValue =
      state?.state?.value?.toString()?.toLowerCase() !==
      autoFillDetails.state.toLowerCase();

    const authorizedUserFullNameValue =
      state?.authorizedUserFullName?.value.toString()?.toLowerCase() !==
      autoFillDetails.authorizedUserFullName.toLowerCase();

    const countryValue =
      state?.country?.value?.toString()?.toLowerCase() !==
      autoFillDetails.country.toLowerCase();

    const accountNumValue =
      state?.accountNumber?.value?.toString()?.toLowerCase() !==
      autoFillDetails.accountNumber.toLowerCase();

    return (
      cityValue ||
      companyNameValue ||
      postalCodeValue ||
      serviceAddressValue ||
      stateValue ||
      authorizedUserFullNameValue ||
      countryValue ||
      accountNumValue
    );
  };

  const minDate = format(new Date(), 'MM/dd/yyyy');
  const {
    formProps: portingFormProps,
    getFieldProps,
    seedValues,
    isComplete,
    values,
  } = useForm({
    fields: {
      companyName: {
        type: 'text',
        required: true,
      },
      serviceAddress: {
        type: 'text',
        required: true,
      },
      city: {
        type: 'text',
        required: true,
      },
      postalCode: {
        type: 'postalCode',
        required: true,
      },
      state: {
        type: 'dropdown',
        required: true,
      },
      country: {
        type: 'dropdown',
        required: true,
      },
      phoneProvider: {
        type: 'text',
        required: true,
      },
      accountNumber: {
        type: 'text',
        required: true,
      },
      authorizedUserFullName: {
        type: 'text',
        required: true,
      },
      userOfficeEmail: {
        type: 'email',
        required: true,
      },
      accountPin: {
        type: 'text',
      },
      internalPorting: {
        type: 'checkbox',
      },
      asapPort: {
        type: 'checkbox',
      },
      requestedPortDate: {
        type: 'datePicker',
        minDate: getUTCDateWithPortingTimeString(today.toISOString()),
        validator: ({ value }: ValidatorFieldState<'datePicker'>) => {
          const formattedValue = convertUsDateToIsoDateFormat(value);
          const formattedMinDate = convertUsDateToIsoDateFormat(minDate);

          return formattedValue < formattedMinDate
            ? 'Requested port date cannot be set in the past'
            : '';
        },
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Blur) {
        switch (action.payload.name) {
          case 'accountNumber': {
            if (typeof state.accountNumber.value === 'string') {
              if (!isAlphaNumericValue(state.accountNumber.value)) {
                return {
                  ...state,
                  accountNumber: {
                    ...state.accountNumber,
                    value: state.accountNumber.value.replace(/[^a-zA-Z0-9]/g, ''),
                  },
                };
              }
            }
          }
        }
      }
      if (action.type === FormFieldActionTypes.Update) {
        switch (action.payload.name) {
          case 'internalPorting': {
            if (action.payload.value && validateDataForInternalPortingCheckbox(state)) {
              action.payload.value = false;
              triggerCancelInternalPortingProps.onClick();
            }
            return {
              ...state,
              internalPorting: {
                ...state.internalPorting,
                value: action.payload.value,
              },
            };
          }
          case 'country': {
            const filterStates = stateCodes.filter(
              (st: any) => action.payload.value === st.country
            );
            setFilteredStates(filterStates);
            return {
              ...state,
              postalCode: {
                ...state.postalCode,
                locale:
                  action.payload.value === PostalCodeLocale.CA
                    ? PostalCodeLocale.CA
                    : PostalCodeLocale.US,
                value: '',
              },
              state: {
                ...state.state,
                value: '',
              },
            };
          }
          default:
            return null;
        }
      }
      return null;
    },
    onSubmit: (values) => handleSubmit(values),
  });
  const { country } = values;
  const checkCountryValue: boolean = country !== PostalCodeLocale.US;

  const {
    requestedPortDate: requestedPortDateVal,
    asapPort: asapPortVal,
    internalPorting: internalPortingCheckboxValue,
  } = values;
  useEffect(() => {
    if (requestedPortDateVal) {
      const portDate = getUTCDateWithPortingTimeString(requestedPortDateVal);
      if (isHoliday(portDate) || isWeekend(portDate)) {
        setRequestPortDateError(
          'Please select date which does not fall on weekend or holiday'
        );
      } else {
        setRequestPortDateError('');
      }
    }
  }, [requestedPortDateVal]);

  useEffect(() => {
    if (isPortingTypeInternal) {
      getInternalPortingNumberData();
      initValues();
    }
  }, [internalPortingValue]);

  useEffect(() => {
    setRequestPortDateError('');
  }, [asapPortVal]);

  useEffect(() => {
    internalPortingCheckboxValue
      ? setInternalPortingValue('PORTING_TYPE_INTERNAL')
      : setInternalPortingValue('PORTING_TYPE_MAIN');
  }, [internalPortingCheckboxValue]);

  useEffect(() => {
    window.scroll(0, 0);

    setError('');
    initValues();
    return () => {
      setShowPortOrderForm(false);
    };
  }, []);

  const closeModal = () => {
    closePortSubmissionModal();
    if (messageModal.success) {
      if (portingData && portingData !== ({} as PortingData)) {
        handleCreatePortOrder(portingData);
      } else {
        goBack();
      }
    }
  };
  const getPhoneProvider = () => {
    if (internalPortingNumberError || internalPortingFaxError) {
      return '';
    } else if (providerNumMap.size === 1) {
      return providerNumMap?.keys()?.next()?.value;
    } else if (providerFaxMap.size === 1) {
      return providerFaxMap?.keys()?.next()?.value;
    } else return '';
  };

  const getProviderFromMap = (providerMap) => {
    const telnyxPin = '0096';
    if (providerMap) {
      switch (providerMap?.keys()?.next()?.value?.toLowerCase()) {
        case 'bandwidth':
          return currentLocation?.ParentID
            ? currentLocation?.ParentID?.toString()?.slice(-4)
            : currentLocation?.LocationID?.toString()?.slice(-4);
        case 'telnyx':
          return telnyxPin;
      }
    }
    return '';
  };

  const getPinBasedOnProvider = () => {
    if (internalPortingNumberError || internalPortingFaxError) {
      return '';
    }
    let mapValue;
    if (providerNumMap.size === 1) {
      mapValue = providerNumMap;
    } else if (providerFaxMap.size === 1) {
      mapValue = providerFaxMap;
    } else {
      mapValue = '';
    }
    return getProviderFromMap(mapValue);
  };

  const getAccountNumber = () => {
    if (isPortingTypeInternal && (voiceNumbers || faxNumbers)) {
      let value;
      if (voiceNumberValues) {
        return (value = voiceNumberValues?.[0]);
      } else if (faxNumberValues) {
        return (value = faxNumberValues?.[0]);
      } else return (value = '');
    }
    return '';
  };

  const initValues = () => {
    getIntialValues();
    seedValues({
      companyName: autoFillDetails.companyName || '',
      serviceAddress: autoFillDetails.serviceAddress || '',
      city: autoFillDetails.city || '',
      postalCode: autoFillDetails.postalCode || '',
      phoneProvider: getPhoneProvider() || '',
      state: autoFillDetails.state || '',
      country: autoFillDetails.country || '',
      accountNumber: getAccountNumber(),
      authorizedUserFullName: autoFillDetails.authorizedUserFullName || '',
      accountPin: getPinBasedOnProvider(),
    });
  };

  const internalPortingValueCheck = () => {
    seedValues({
      internalPorting: true,
    });
  };
  const internalPortingValueUnCheck = () => {
    seedValues({
      internalPorting: false,
    });
  };

  const handleSubmit = async (values: any) => {
    if (isValidated(values)) {
      setSubmitLoading(true);
      const error: string = await submitPortingData(values);
      if (error) {
        setMessageModal({
          body: failMessage,
          header: 'Something Went Wrong',
          success: false,
        });
      } else {
        setMessageModal({
          header: 'Thank You',
          body: successMessage,
          success: true,
        });
      }
      setSubmitLoading(false);
      portSubmissionTriggerModalProps.onClick();
    } else {
      setSubmitLoading(false);
      setError('Some of the fields does not have proper values');
    }
  };

  const submitPortingData = async (values: any): Promise<string> => {
    try {
      const {
        companyName,
        serviceAddress,
        city,
        postalCode,
        state,
        country,
        phoneProvider: currentServiceProvider,
        accountNumber,
        authorizedUserFullName,
        userOfficeEmail,
        accountPin,
        asapPort,
        requestedPortDate,
      } = values;
      const [firstName, ...restName] = authorizedUserFullName.split(' ');
      const portingRequests: PortingRequest[] = [];
      const portDate =
        asapPort || !requestedPortDate
          ? ''
          : getUTCDateWithPortingTimeString(requestedPortDate);

      splitAndTrimString(faxNumbers, ',').forEach((number: string) => {
        portingRequests.push({
          phone_number: number?.trim(),
          port_type: 0,
          number_type: NumberType.FAX,
          requested_firm_order_commitment_date: portDate,
        });
      });

      const voiceNumArr = splitAndTrimString(voiceNumbers, ',');
      voiceNumArr.forEach((number: string) => {
        portingRequests.push({
          phone_number: number?.trim(),
          port_type: 0,
          number_type: NumberType.PHONE,
          requested_firm_order_commitment_date: portDate,
        });
      });

      const portingData = await API.postPortingData({
        authorized_user_first_name: firstName,
        authorized_user_last_name: restName.join(' '),
        service_street1: serviceAddress,
        service_city: city,
        service_state: state,
        service_country: country,
        service_zip: postalCode,
        company_name: companyName,
        phone_service_account_number: accountNumber,
        porting_requests: portingRequests,
        billing_phone_number: voiceNumArr[0] ?? '',
        current_phone_service_provider: currentServiceProvider,
        office_email: userOfficeEmail,
        request_client: 'WAM',
        account_pin: accountPin,
        request_asap_port: asapPort,
        customer_phone_bill_media: customerAttachedFiles,
        no_bill_available: noBillAvailable,
        reason_bill_not_available: noBillAvailable ? commentField.value : '',
        port_type:
          internalPortingValue === 'PORTING_TYPE_INTERNAL'
            ? InternalNumberPortingType.PORTING_TYPE_INTERNAL
            : InternalNumberPortingType.PORTING_TYPE_MAIN,
      });

      setPortingData(portingData[0]);
      return '';
    } catch (error) {
      console.error(error);
      return 'Could not submit porting numbers. Please try again later.';
    }
  };

  const isValidated = ({ asapPort, requestedPortDate }): boolean =>
    validatePhoneNumberFields() && validateRequestPortFields(asapPort, requestedPortDate);

  const validateRequestPortFields = (asapPort, requestedPortDate): boolean => {
    let validate = true;

    if (
      !asapPort &&
      !!requestedPortDate &&
      (isHoliday(getUTCDateWithPortingTimeString(requestedPortDate)) ||
        isWeekend(getUTCDateWithPortingTimeString(requestedPortDate)))
    ) {
      validate = false;
      setRequestPortDateError(
        'Please select date which does not fall on weekend or holiday'
      );
    } else {
      setRequestPortDateError('');
    }
    return validate;
  };

  const validatePhoneNumberFields = () => {
    let validate = true;
    if (voiceNumbers.trim() === '' && faxNumbers.trim() === '') {
      setPhoneNumbersError('Atleast one voice/fax number is required to be filled');
      validate = false;
    } else {
      setPhoneNumbersError('');
    }

    if (!validatePhoneNumbersRegex(voiceNumbers)) {
      validate = false;
    }

    if (!validatePhoneNumbersRegex(faxNumbers)) {
      validate = false;
    }

    return validate;
  };

  const goBack = () => {
    initValues();
    back();
  };

  const isFormComplete = isComplete && values.country && values.state;

  const enableSubmit =
    isFormComplete &&
    (attachedBills.length > 0 ||
      (noBillAvailable && (!checkCountryValue || commentField.value !== ''))) &&
    !duplicateFaxError &&
    !duplicatePhnError;

  const handleFileUpload = async (file: File, type: MediaType) => {
    if (file !== undefined) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);
        const data = await API.uploadPhoneFile(formData);
        setUploadedFileUrl(data);
        alert.success(`Successfully submitted file: ${file.name}`);
        return data;
      } catch {
        alert.error('Error uploading file.');
        return null;
      }
    }
    return null;
  };

  const isSameFileAlreadyUploaded = (file: File, currentFiles: MediaData[]) => {
    return currentFiles.some((item) => item.file_name === file.name);
  };

  const validateFile = (acceptedFiles: File[], currentFiles: MediaData[]) => {
    if (acceptedFiles.length > 0) {
      const sortedFiles = acceptedFiles.reduce(
        (acc, file) => {
          const isDuplicate = isSameFileAlreadyUploaded(file, currentFiles);
          if (file.size <= 3e6 && !isDuplicate) {
            return { ...acc, accepted: [...acc.accepted, file] };
          } else if (isDuplicate) {
            return { ...acc, duplicates: [...acc.duplicates, file] };
          } else {
            return { ...acc, rejected: [...acc.rejected, file] };
          }
        },
        { accepted: [], rejected: [], duplicates: [] } as SortedFiles
      );
      return sortedFiles;
    }
    return { accepted: [], rejected: [], duplicates: [] } as SortedFiles;
  };

  const showAlerts = (sortedFile: SortedFiles) => {
    sortedFile.rejected.forEach((file) => {
      alert.error(`Error uploading file: ${file.name}`);
    });
    sortedFile.duplicates.forEach((file) => {
      alert.error(
        `The file with name ${file.name} has been already uploaded. Please upload with different name.`
      );
    });
  };

  const onLoaDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length + attachedLOA.length > 1) {
        alert.error('You can add only one LOA file.');
        return;
      }
      setLoaLoader(true);
      const sortedFile = validateFile(acceptedFiles, attachedLOA);
      if (sortedFile.accepted.length > 0) {
        const responseData: PortCreationFileResponseData | null = await handleFileUpload(
          sortedFile?.accepted?.[0],
          MediaType.LOA
        );
        if (responseData?.ID) {
          setAttachedLOA([
            {
              media_id: responseData?.ID,
              file_name: responseData?.Name,
              media_type: MediaType.LOA,
            },
          ]);
        }
      }
      setLoaLoader(false);
      showAlerts(sortedFile);
    },
    [attachedLOA.length]
  );

  const onBillsDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length + attachedBills.length > 5) {
        alert.error('You can add a maximum of 5 previous bills');
        return;
      }
      setBillLoader(true);
      const sortedFile = validateFile(acceptedFiles, attachedBills);
      sortedFile.accepted.forEach(async (file) => {
        const responseData: PortCreationFileResponseData | null = await handleFileUpload(
          file,
          MediaType.phoneBill
        );
        if (responseData?.ID) {
          const acceptedFiles: MediaData[] = [];
          acceptedFiles.push({
            media_id: responseData?.ID,
            file_name: responseData?.Name,
            media_type: MediaType.phoneBill,
          });
          setAttachedBills((prevBills) => [...prevBills, ...acceptedFiles]);
        }
      });
      setBillLoader(false);
      showAlerts(sortedFile);
    },
    [attachedBills.length]
  );

  useEffect(() => {
    if (attachedBills.length === 0) {
      setNoBillAvailable(true);
    } else {
      setNoBillAvailable(false);
    }
    if (attachedLOA.length === 0 && isFormComplete) {
      setError('LOA is a required field.');
    }
    if (!checkCountryValue && isFormComplete) {
      setError('');
    } else if (
      ((!noBillAvailable && attachedBills.length === 0) ||
        (noBillAvailable && commentField.value === '')) &&
      isFormComplete
    ) {
      setError('Please upload bills or provide the reasons for not providing bill.');
    } else {
      setError('');
    }
    setCustomerAttachedFiles([...attachedBills, ...attachedLOA]);
  }, [attachedBills, attachedLOA, isFormComplete, noBillAvailable, commentField.value]);

  const removeLOA = (file: MediaData) => {
    const tempIndex = attachedLOA.findIndex((item) => item.media_id === file.media_id);
    attachedLOA.splice(tempIndex, 1);
    setAttachedLOA([...attachedLOA]);
  };

  const removeBills = (file: MediaData) => {
    const tempIndex = attachedBills.findIndex((item) => item.media_id === file.media_id);
    attachedBills.splice(tempIndex, 1);
    setAttachedBills([...attachedBills]);
  };

  const onRemoveFile = async (file: MediaData) => {
    try {
      await API.deleteFile(file.media_type, file.media_id);
      const tempIndex = customerAttachedFiles.findIndex(
        (item) => item.media_id === file.media_id
      );
      customerAttachedFiles.splice(tempIndex, 1);
      setCustomerAttachedFiles([...customerAttachedFiles]);
      if (file.media_type === MediaType.LOA) {
        removeLOA(file);
      } else {
        removeBills(file);
      }
    } catch {
      alert.error(`deletion failed: ${file.file_name}`);
    }
  };

  const onCancel = () => {
    customerAttachedFiles.forEach((item) => {
      onRemoveFile(item);
    });
    cancelConfirmationModalProps.onClose();
    goBack();
  };

  const onCancelInternalPorting = () => {
    setInternalPortingValue('PORTING_TYPE_INTERNAL');
    internalPortingValueCheck();
    cancelInternalPorting.onClose();
  };
  const setBackToMainPorting = () => {
    internalPortingValueUnCheck();
    cancelInternalPorting.onClose();
  };

  const handleDownloadBill = async (mediaObj, file_name) => {
    const { LocationID } = mediaObj;
    const payload = {
      customer_phone_bill_media_id: mediaObj.ID,
      customer_phone_bill_media_type: mediaObj.Type,
      location_id: LocationID,
    };
    setDownloadLOALoading(true);
    try {
      const response = await portViewBillDownload(payload);
      const file = response.data;
      fileDownload(file, file_name, 'application/pdf');
    } catch (err) {
      alert.error(err.message);
    } finally {
      setDownloadLOALoading(false);
    }
  };

  return (
    <Page
      title={`Port Order`}
      subtitle={`Create new port order by filling information below`}
    >
      <div>
        <div css={portOrderStyles.backButtonStyle}>
          <Text onClick={goBack} color="primary">
            {'< back'}
          </Text>
        </div>
      </div>
      <div css={portOrderStyles.container}>
        <div css={portOrderStyles.backgroundStyle}>
          <Text weight="bold" css={{ fontSize: '19px', marginBottom: '7px' }}>
            New Port Order
          </Text>
          <FormRow>
            <CheckboxField
              {...getFieldProps('internalPorting')}
              name="internalPorting"
              label="Internal Porting"
            />
          </FormRow>
        </div>
        <form
          {...portingFormProps}
          css={css`
            padding: 25px;
          `}
        >
          <div css={portOrderStyles.portingInfoContainer}>
            <Text css={portOrderStyles.title}>Porting Information</Text>
          </div>

          <div css={portOrderStyles.portingNumbers}>
            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                `}
              >
                Voice Numbers
              </Text>

              <FormRow>
                <TextField
                  {...voiceNumbersFieldProps}
                  name="voiceNumbers"
                  label="Phone Numbers"
                  helperText="Ex: +11234567890, +1 (987) 654-3456..."
                  css={css`
                    width: 230px;
                  `}
                />
                {duplicatePhnError && (
                  <Text color="error">Please remove the duplicate Number </Text>
                )}
              </FormRow>
            </div>
            {isPortingTypeInternal && internalPortingNumberError && (
              <Text css={portOrderStyles.errorStyle} color="error">
                {internalPortingNumberError}
              </Text>
            )}
            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                `}
              >
                Fax Numbers
              </Text>

              <FormRow>
                <TextField
                  {...faxNumbersFieldProps}
                  name="faxNumbers"
                  label="Fax Numbers"
                  helperText="Ex: +11234567890, +1 (987) 654-3456..."
                  css={css`
                    width: 230px;
                  `}
                />
                {duplicateFaxError && (
                  <Text color="error">Please remove the duplicate Number </Text>
                )}
              </FormRow>
            </div>
            {isPortingTypeInternal && internalPortingFaxError && (
              <Text css={portOrderStyles.errorStyle} color="error">
                {internalPortingFaxError}
              </Text>
            )}
            <Text css={portOrderStyles.errorMessage}>{phoneNumbersError}</Text>
            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                `}
              >
                ASAP Port
              </Text>

              <FormRow>
                <CheckboxField
                  {...getFieldProps('asapPort')}
                  name="asapPort"
                  label="Request ASAP Port"
                />
              </FormRow>
            </div>
            {!asapPortVal && (
              <div
                css={css`
                  display: flex;
                `}
              >
                <Text
                  css={css`
                    width: 250px;
                  `}
                >
                  Requested Port Date
                </Text>

                <FormRow>
                  <DatePickerField
                    {...getFieldProps('requestedPortDate')}
                    name="requestedPortDate"
                    label="Port Date"
                  />
                </FormRow>
              </div>
            )}

            <Text css={portOrderStyles.errorMessage}>{requestPortDateError}</Text>
          </div>

          <div css={portOrderStyles.portingInfoContainer}>
            <Text css={portOrderStyles.title}>Business Information</Text>
          </div>

          <div css={portOrderStyles.portingNumbers}>
            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                `}
              >
                Company Name
              </Text>

              <FormRow>
                <TextField
                  {...getFieldProps('companyName')}
                  label="Company Name"
                  css={css`
                    width: 230px;
                  `}
                  name="companyName"
                />
              </FormRow>
            </div>

            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                  margin-bottom: 10px;
                `}
              >
                Service Address
              </Text>
              <div>
                <div css={portOrderStyles.streetContainer}>
                  <FormRow>
                    <TextField
                      {...getFieldProps('serviceAddress')}
                      placeholder="Service Address"
                      label="Service Address"
                      css={css`
                        width: 230px;
                      `}
                      name="serviceAddress"
                    />
                  </FormRow>

                  <FormRow>
                    <TextField
                      {...getFieldProps('city')}
                      placeholder="City"
                      label="City"
                      css={css`
                        width: 230px;
                      `}
                      name="city"
                    />
                  </FormRow>
                </div>

                <FormRow>
                  <DropdownField
                    {...getFieldProps('country')}
                    label="Country"
                    name="country"
                    css={css`
                      width: 230px;
                    `}
                  >
                    <DropdownField.Option value={CountryCodes.USA.Code}>
                      {CountryCodes.USA.Label}
                    </DropdownField.Option>
                    <DropdownField.Option value={CountryCodes.Canada.Code}>
                      {CountryCodes.Canada.Label}
                    </DropdownField.Option>
                  </DropdownField>
                </FormRow>

                <div css={portOrderStyles.streetContainer}>
                  <DropdownField
                    {...getFieldProps('state')}
                    placeholder="State"
                    label="State"
                    name="state"
                    css={css`
                      width: 230px;
                    `}
                  >
                    {filteredStates.map((state: any) => (
                      <DropdownField.Option value={state.code} key={state.code}>
                        {state.label}
                      </DropdownField.Option>
                    ))}
                  </DropdownField>

                  <FormRow>
                    <PostalCodeField
                      {...getFieldProps('postalCode')}
                      placeholder="Postal Code"
                      label="Postal Code"
                      css={css`
                        width: 230px;
                      `}
                      name="postalCode"
                    />
                  </FormRow>
                </div>
              </div>
            </div>
          </div>

          <div css={portOrderStyles.portingInfoContainer}>
            <Text css={portOrderStyles.title}>Phone Information</Text>
          </div>

          <div css={portOrderStyles.portingNumbers}>
            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                `}
              >
                Authorized User
              </Text>

              <FormRow>
                <TextField
                  {...getFieldProps('authorizedUserFullName')}
                  placeholder="Full Name"
                  label="Full Name"
                  css={css`
                    width: 230px;
                  `}
                  name="authorizedUserFullName"
                />
              </FormRow>
            </div>

            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                `}
              >
                Account Number
              </Text>

              <FormRow>
                <TextField
                  {...getFieldProps('accountNumber')}
                  placeholder="Account Number"
                  label="Account Number"
                  css={css`
                    width: 230px;
                  `}
                  name="accountNumber"
                />
              </FormRow>
            </div>

            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                `}
              >
                User Office Email
              </Text>

              <FormRow>
                <EmailField
                  {...getFieldProps('userOfficeEmail')}
                  placeholder="Office Email"
                  label="Office Email"
                  css={css`
                    width: 230px;
                  `}
                  name="userOfficeEmail"
                />
              </FormRow>
            </div>

            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                `}
              >
                Provider
              </Text>

              <FormRow>
                <TextField
                  {...getFieldProps('phoneProvider')}
                  placeholder="Phone Provider"
                  label="Phone Provider"
                  css={css`
                    width: 230px;
                  `}
                  name="phoneProvider"
                />
              </FormRow>
            </div>
            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                `}
              >
                Account PIN
              </Text>

              <FormRow>
                <TextField
                  {...getFieldProps('accountPin')}
                  placeholder="Account PIN"
                  label="PIN"
                  css={css`
                    width: 230px;
                  `}
                  name="accountPin"
                />
              </FormRow>
            </div>

            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                `}
              >
                LOA
              </Text>
              <FormRow>
                {attachedLOA.length <= 0 && (
                  <PortOrderUploadComponent onFileDrop={onLoaDrop} />
                )}
                {attachedLOA.length > 0 && (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <Text
                      css={css`
                        padding-right: 10px;
                        margin: 0;
                        cursor: pointer;
                        color: #28caf4;
                      `}
                      onClick={() => {
                        handleDownloadBill(uploadedFileUrl, attachedLOA[0].file_name);
                      }}
                    >
                      {attachedLOA[0].file_name}
                    </Text>
                    <TrashIcon
                      onClick={() => onRemoveFile(attachedLOA[0])}
                      css={css`
                        cursor: pointer;
                      `}
                      color="light"
                    />
                  </div>
                )}
                {!!loaLoader && <SpinningLoader size="small" />}
              </FormRow>
            </div>

            <div
              css={css`
                display: flex;
              `}
            >
              <Text
                css={css`
                  width: 250px;
                `}
              >
                Previous Bill
              </Text>
              <FormRow>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                    `}
                  >
                    <PortOrderUploadComponent
                      onFileDrop={onBillsDrop}
                      canUploadMutipleFiles={true}
                    />
                    {!!billLoader && <SpinningLoader size="small" />}
                  </div>
                  {attachedBills.length > 0 && (
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 10px;
                      `}
                    >
                      {attachedBills.map((item, index) => {
                        return (
                          <div
                            key={item.file_name}
                            css={css`
                              display: flex;
                              align-items: center;
                            `}
                          >
                            <TextLink
                              css={css`
                                padding-right: 10px;
                                margin: 0;
                              `}
                            >
                              <div
                                css={css`
                                  display: flex;
                                `}
                              >
                                <Text
                                  css={css`
                                    color: #28caf4;
                                    margin: auto;
                                    cursor: pointer;
                                  `}
                                  onClick={() => {
                                    handleDownloadBill(uploadedFileUrl, item.file_name);
                                  }}
                                >
                                  {item.file_name}
                                </Text>
                                <FileButton
                                  key={item.file_name}
                                  file={item}
                                  onRemove={onRemoveFile}
                                ></FileButton>
                              </div>
                            </TextLink>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {attachedBills.length === 0 && (
                    <>
                      <CheckboxField
                        {...checkNoBillField}
                        name="noBillAvailable"
                        label="No Bill Available"
                        css={css`
                          margin-top: 10px;
                        `}
                      ></CheckboxField>

                      {!!checkNoBillField.value && (
                        <TextareaField
                          {...commentField}
                          name="noBillComments"
                          label="Why isn't there a bill?"
                          css={css`
                            margin-top: ${theme.spacing(3)};
                            height: 80px;
                          `}
                        ></TextareaField>
                      )}
                    </>
                  )}
                </div>
              </FormRow>
            </div>
          </div>

          <div css={portOrderStyles.buttonContainers}>
            <div css={portOrderStyles.errorMessage}>{error}</div>
            <ButtonBar css={portOrderStyles.buttonContainersColor}>
              <SecondaryButton
                onClick={() => {
                  triggerCancelProps.onClick();
                }}
                css={portOrderStyles.submitButtonStyle}
              >
                Cancel
              </SecondaryButton>

              <PrimaryButton
                css={portOrderStyles.submitButtonStyle}
                color="blue"
                size="large"
                type="submit"
                disabled={submitLoading || !enableSubmit}
              >
                Submit
              </PrimaryButton>
            </ButtonBar>
          </div>
        </form>
      </div>

      <Loader showLoading={submitLoading} message="Submitting..." />
      <LoaderModal showLoading={downloadLOALoading} message={'downloading...'} />

      <MessageDialogue
        header={messageModal.header}
        body={messageModal.body}
        messageModalProps={portSubmissionModalProps}
        closeMessageModal={closeModal}
        actionLabel="Okay"
        onActionLabelClick={closeModal}
      />

      <Modal {...cancelConfirmationModalProps}>
        <Modal.Header>Confirm Cancel</Modal.Header>
        <Modal.Body>
          <Text textAlign="center">
            Are you sure you want to cancel the porting request. All the current uploaded
            files will be deleted on this process.
          </Text>
        </Modal.Body>
        <ButtonBar>
          <SecondaryButton onClick={closeCancelModal}>No, do not cancel.</SecondaryButton>
          <PrimaryButton destructive onClick={onCancel}>
            Yes, cancel.
          </PrimaryButton>
        </ButtonBar>
      </Modal>
      <Modal {...cancelInternalPorting}>
        <Modal.Header>Confirm Cancel</Modal.Header>
        <Modal.Body>
          <Text textAlign="center">
            Checking Internal Port 'ON' would reset the Business and Phone information.
            Would you like to proceed?
          </Text>
        </Modal.Body>
        <ButtonBar>
          <SecondaryButton onClick={setBackToMainPorting}>
            No, do not remove.
          </SecondaryButton>
          <PrimaryButton destructive onClick={onCancelInternalPorting}>
            Yes, Remove.
          </PrimaryButton>
        </ButtonBar>
      </Modal>
    </Page>
  );
};

type FileProps = {
  file: MediaData;
  onRemove: (file: MediaData) => void;
  onDownload?: (file: MediaData) => void;
};

export const FileButton = ({ file, onRemove, onDownload = noop }: FileProps) => {
  return (
    <div
      css={css`
        margin: 5px 0;
        display: flex;
        align-items: center;
      `}
    >
      {onDownload !== noop && (
        <TextLink
          css={css`
            padding-right: 10px;
            margin: 0;
          `}
          onClick={() => onDownload(file)}
        >
          {file.file_name}
        </TextLink>
      )}
      <IconButton label="Delete file" size="small">
        <TrashIcon
          onClick={() => onRemove(file)}
          css={css`
            cursor: pointer;
          `}
          color="light"
        />
      </IconButton>
    </div>
  );
};
