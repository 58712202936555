import { useQueryWithSetter } from 'apis/helpers/useQueryWithSetter';
import { UseQueryOptions } from 'react-query';
import { CustomAxios, getResponseData } from '../../../src/redux/axios/axios';
import {
  MigrationEligibility,
  MediaMigrationStatusRes,
  NumberingMigrationStatusRes,
  TenantMigrationNumberingReq,
  UnifyStatusResponse,
} from './tenant-migration.types';

export const tenantMigrationApi = {
  getMediaStatus: () =>
    CustomAxios.get(
      `support/v1/phone/migrate/media/status`
    ).then<MediaMigrationStatusRes>(getResponseData),
  getNumberingStatus: () =>
    CustomAxios.get(
      `support/v1/phone/migrate/tenant/status`
    ).then<NumberingMigrationStatusRes>(getResponseData),
  migrateMedia: (locationId) =>
    CustomAxios.post(`support/v1/phone/migrate/media`, null, {
      headers: { ['X-Weave-Debug-Id']: locationId },
    }).then<unknown>(getResponseData),
  migrateNumbering: (map: TenantMigrationNumberingReq, locationId) =>
    CustomAxios.post(`support/v2/phone/migrate/tenant`, map, {
      headers: { ['X-Weave-Debug-Id']: locationId },
    }).then<unknown>(getResponseData),
  migratePhoneNumbersAndDevices: (locationId) =>
    CustomAxios.put(
      `phone-exp/unify/v1/migrate-phone-numbers-and-devices`,
      {},
      { headers: { ['X-Weave-Debug-Id']: locationId } }
    ),
  revertPhoneNumbersAndDevices: () =>
    CustomAxios.put(`phone-exp/unify/v1/revert-phone-numbers-and-devices`),
  checkMigrationEligibility: () =>
    CustomAxios.get(`phone-exp/unify/v1/is-eligible-to-unify`).then<MigrationEligibility>(
      getResponseData
    ),
  unifyStatus: () =>
    CustomAxios.get(`phone-exp/unify/v1/unify-status`).then<UnifyStatusResponse>(
      getResponseData
    ),
};

export const useGetMigrationEligibility = (
  options: UseQueryOptions<MigrationEligibility> = {}
) => {
  return useQueryWithSetter<MigrationEligibility>(
    'getEligibility',
    async () => {
      return await tenantMigrationApi.checkMigrationEligibility();
    },
    options
  );
};

export const mockTenantMigrationApi = {
  getMediaStatus: () =>
    //GET `support/v1/phone/migrate/media/status`
    Promise.resolve<MediaMigrationStatusRes>({
      srcCallRecordingCount: 8743,
      dstCallRecordingCount: 1762,
      srcMediaCount: 7645,
      dstMediaCount: 1543,
    }),
  getNumberingStatus: () =>
    //GET `support/v1/phone/migrate/tenant/status`
    Promise.resolve<NumberingMigrationStatusRes>({
      migrationCompleted: false,
      destinationTenantNumbering: [
        {
          number: 9000,
          extensionName: 'General VM Check',
          extensionInstructionSetName: 'General VM Check',
          voicemailBoxName: 'General',
        },
      ],
      sourceTenantNumbering: [
        {
          number: 101,
          extensionName: 'Phone 101',
          extensionInstructionSetName: 'Phone 101 Direct',
          voicemailBoxName: 'Phone 101',
        },
        {
          number: 104,
          extensionName: 'Phone 104',
          extensionInstructionSetName: 'Phone 104 Direct',
          voicemailBoxName: 'Phone 104',
        },
        {
          number: 9000,
          extensionName: 'General VM Check',
          extensionInstructionSetName: 'General VM Check',
          voicemailBoxName: 'General',
        },
        {
          number: 9002,
          extensionName: '',
          extensionInstructionSetName: '',
          voicemailBoxName: 'Emergency 1',
        },
      ],
    }),
  // POST `support/v1/phone/migrate/media`
  migrateMedia: () => Promise.resolve(),

  //POST `support/v1/phone/migrate/tenant`
  migrateNumbering: (map: TenantMigrationNumberingReq) => Promise.resolve(),
};

export const useGetUnifyStatus = (options: UseQueryOptions<UnifyStatusResponse> = {}) => {
  return useQueryWithSetter<UnifyStatusResponse>(
    'getUnifyStatus',
    async () => {
      return await tenantMigrationApi.unifyStatus();
    },
    options
  );
};
