import { formatCurrencyValue, formatPercentValue } from '../../../helpers/currency';
import {
  EditPricingModel,
  MerchantPlanTypes,
  PricingDetailsInput,
  PricingModel,
} from '../../../models/pricing.model';

export const standardPricing = {
  plan: { name: MerchantPlanTypes.Full },
  cardPresent: { rate: 240, transactionCost: 25 },
  cardNotPresent: { rate: 299, transactionCost: 30 },
  mobileTapToPay: { rate: 305, transactionCost: 35 },
  ach: { rate: 100, transactionCost: 175 },
};

export const getPercentValue = (
  rate: number | undefined,
  defaultPricing: PricingDetailsInput
) => {
  return rate ? formatPercentValue(rate) : formatPercentValue(defaultPricing.rate);
};

export const getPricingName = (pricing: EditPricingModel | undefined) => {
  let planName = pricing?.plan.name;
  if (planName === MerchantPlanTypes.MobileTapToPay)
    planName = MerchantPlanTypes.CardNotPresent;
  return planName ?? standardPricing.plan.name;
};

export const getCurrencyValue = (
  cost: number | undefined,
  defaultPricing: PricingDetailsInput
) => {
  return cost
    ? formatCurrencyValue(cost)
    : formatCurrencyValue(defaultPricing.transactionCost);
};
