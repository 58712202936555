import React, { useState } from 'react';
import { Field, Form, FormikProps } from 'formik';
import { css, cx } from 'emotion';
import { getVerticalList } from '../../../models/vertical-enum';
import { Timezones } from '../../../models/timezones';
import { StatesTypeahead } from '../../shared/state-select/state-select.component';
import { ToggleSwitch } from '../../shared/toggle-switch/toggle-switch.component';
import { InfoTooltip } from '../../shared/info-tooltip/info-tooltip.component';
import { Loader } from '../../shared/loader/loader.component';
import { history } from '../../../redux/store/history';
import {
  LocationTypes,
  ProvisionLocationResponseDTO,
} from '../../../redux/actions/location';
import { LocationAlert } from './location-alert.component';
import {
  columnSpacing,
  columnWidth,
  creationStatusButton,
  creationStatusContainer,
  creationStatusListItem,
  flex,
  formGroup,
  inlineFormSpacer,
  loader,
  marginRight5,
  validationError,
  warningText,
} from './location-form.styles';
import { OTHER, injectParams } from '../../../constants/paths';
import { LocationSearchContainer } from '../location-search-container/location-search.container';
import { ButtonBar, Modal, PrimaryButton, SecondaryButton } from '@weave/design-system';
import './location-form.component.css';
import { useLocation } from 'react-router-dom';

export interface LocationFormProps {
  creationStatus: ProvisionLocationResponseDTO;
  isNew: boolean;
  loading: boolean;
  allowSetParent: boolean;
  onClose: () => void;
  resetLocationCreationStatus: () => void;
}

export const LocationForm = (props: LocationFormProps & FormikProps<any>) => {
  const routeLocation = useLocation<{ state: any }>();
  const stateValues = routeLocation?.state?.state;
  const [tenantToggle, setTenantToggle] = useState(true);

  const statesTypeaheadOnChange = (selected) => {
    if (selected.length > 0 && selected[0]) {
      props.setFieldValue('state', selected[0].id);
      props.setFieldValue('country', selected[0].country);
      props.setFieldValue('Type', stateValues?.Type);
    }
  };

  const closeCreationStatusModal = () => {
    const locationId = props.creationStatus.locationId;
    if (locationId) {
      history.push(injectParams(OTHER.dashboard, { id: locationId }));
    } else {
      history.push('/');
    }
    props.resetLocationCreationStatus();
  };

  const renderButtons = () => {
    return (
      <div
        className={css`
          text-align: center;
        `}
      >
        <ButtonBar>
          <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" disabled={props.loading}>
            Save
          </PrimaryButton>
        </ButtonBar>
      </div>
    );
  };

  const handleToggleChange = () => {
    setTenantToggle(!tenantToggle);
  };

  return (
    <>
      <div className="location-form">
        <Form className={flex}>
          <div className={columnWidth}>
            <div css={formGroup}>
              <div className={flex}>
                <label className={marginRight5} htmlFor="Name">
                  Location Name
                </label>
                <InfoTooltip
                  body={
                    <div>
                      This field will update: <div>WAM: Location Name</div>{' '}
                      <div>Phone Data: Tenant Name</div>
                    </div>
                  }
                  tooltipPlacement="right"
                />
              </div>
              <Field type="text" id="Name" name="Name" className="form-control" />
              {props.touched.Name && props.errors.Name && (
                <div css={validationError}>{props.errors.Name}</div>
              )}
            </div>
            {props.isNew && (
              <div css={formGroup}>
                <label htmlFor="Slug">Slug</label>
                <Field type="text" id="Slug" name="Slug" className="form-control" />
                {props.touched.Slug && props.errors.Slug && (
                  <div css={validationError}>{props.errors.Slug}</div>
                )}
              </div>
            )}
            {props.isNew && (
              <div css={formGroup}>
                <label htmlFor="SalesforceID">SF Account ID</label>
                <Field
                  type="text"
                  id="SalesforceID"
                  name="SalesforceID"
                  className="form-control"
                />
                {props.touched.SalesforceID && props.errors.SalesforceID && (
                  <div css={validationError}>{props.errors.SalesforceID}</div>
                )}
              </div>
            )}
            <div css={formGroup}>
              <label htmlFor="Email">Office Email</label>
              <Field type="email" id="Email" name="Email" className="form-control" />
              {props.touched.Email && props.errors.Email && (
                <div css={validationError}>{props.errors.Email}</div>
              )}
            </div>
            {!props.isNew && (
              <div css={formGroup}>
                <div className={flex}>
                  <label className={marginRight5} htmlFor="Phone">
                    Outbound Caller Number
                  </label>
                  <InfoTooltip
                    body={
                      <div>
                        This field will update: <div>WAM: Phone Number</div>{' '}
                        <div>Phone Data: Caller Number</div>
                      </div>
                    }
                    tooltipPlacement="right"
                  />
                </div>
                <Field type="text" id="Phone" name="Phone" className="form-control" />
              </div>
            )}
            <div css={formGroup}>
              <div className={flex}>
                <label className={marginRight5} htmlFor="Timezone">
                  Timezone
                </label>
                <InfoTooltip
                  body={
                    <div>
                      This field will update: <div>WAM: Timezone</div>
                      <div>Phone Data: Timezone</div>
                    </div>
                  }
                  tooltipPlacement="right"
                />
              </div>
              <Field
                component="select"
                id="Timezone"
                name="Timezone"
                className="form-control"
              >
                {Timezones.map((timezone) => {
                  return (
                    <option key={timezone.ID} value={timezone.Value}>
                      {timezone.Value}
                    </option>
                  );
                })}
              </Field>
            </div>
            <div css={formGroup}>
              <label htmlFor="Type">Type</label>
              {stateValues.Type === LocationTypes.Regular ? (
                <Field component="select" id="Type" name="Type" className="form-control">
                  <option value="Regular">Regular</option>
                  <option value="Demo">Demo</option>
                  <option value="Dev">Dev</option>
                </Field>
              ) : (
                <Field component="select" id="Type" name="Type" className="form-control">
                  <option value="Dev">Dev</option>
                  <option value="Demo">Demo</option>
                  <option value="Regular">Regular</option>
                </Field>
              )}
            </div>
            <div css={formGroup}>
              <label htmlFor="VerticalID">Vertical</label>
              <Field
                component="select"
                id="VerticalID"
                name="VerticalID"
                className="form-control"
              >
                {getVerticalList().map((vertical) => (
                  <option value={vertical.key} key={vertical.key}>
                    {vertical.value}
                  </option>
                ))}
              </Field>
            </div>
            <div className={cx(flex, 'form-group')}>
              <div>
                <label htmlFor="Active">Active</label>
                <Field
                  id="Active"
                  name="Active"
                  className="form-control"
                  render={(field) => (
                    <ToggleSwitch
                      {...field}
                      label=""
                      on={props.values.Active}
                      onChange={() => {
                        props.setFieldValue('Active', !props.values.Active);
                      }}
                    />
                  )}
                />
              </div>
              {!props.values.Active && (
                <p className={warningText}>
                  <strong>WARNING:</strong> Marking this office inactive will prevent
                  users from using this location. Please be sure this effect is desirable
                  before saving.
                </p>
              )}
            </div>

            {props.allowSetParent && (
              <div>
                <label htmlFor="ParentID">
                  Parent Location (Optional - DO NOT USE UNLESS ABSOLUTELY SURE)
                </label>
                <Field name="ParentID">
                  {({ field, form }) =>
                    !field.value ? (
                      <LocationSearchContainer
                        onSelect={(selection) =>
                          form.setFieldValue('ParentID', selection.LocationID)
                        }
                      />
                    ) : (
                      <div>
                        <span>{field.value}</span>{' '}
                        <PrimaryButton
                          size="small"
                          destructive
                          color="error"
                          onClick={() => form.setFieldValue('ParentID', null)}
                        >
                          Clear Parent
                        </PrimaryButton>
                      </div>
                    )
                  }
                </Field>
              </div>
            )}

            {!props.isNew || (props.isNew && !props.values.createPhoneTenant)
              ? renderButtons()
              : null}
          </div>

          {props.isNew && (
            <div className={`${columnWidth} ${columnSpacing}`}>
              <div className={`form-group ${flex}`}>
                <Field
                  id="createPhoneTenant"
                  name="createPhoneTenant"
                  className={`form-control ${marginRight5}`}
                  render={(field) => (
                    <ToggleSwitch
                      {...field}
                      label=""
                      on={props.values.createPhoneTenant}
                      onChange={() => {
                        handleToggleChange();
                        props.setFieldValue(
                          'createPhoneTenant',
                          !props.values.createPhoneTenant
                        );
                      }}
                    />
                  )}
                />
                <label htmlFor="createPhoneTenant" className={inlineFormSpacer}>
                  Create Phone Tenant
                </label>
              </div>
              {props.values.createPhoneTenant && (
                <div>
                  <div css={formGroup}>
                    <label htmlFor="caller_number">Outbound Caller Number</label>
                    <Field
                      type="text"
                      id="caller_number"
                      name="caller_number"
                      className="form-control"
                    />
                    {props.touched.caller_number && props.errors.caller_number && (
                      <div css={validationError}>{props.errors.caller_number}</div>
                    )}
                  </div>
                  <div css={formGroup}>
                    <label htmlFor="street1">Street 1</label>
                    <Field
                      type="text"
                      id="street1"
                      name="street1"
                      className="form-control"
                    />
                    {props.touched.street1 && props.errors.street1 && (
                      <div css={validationError}>{props.errors.street1}</div>
                    )}
                  </div>
                  <div css={formGroup}>
                    <label htmlFor="street2">Street 2</label>
                    <Field
                      type="text"
                      id="street2"
                      name="street2"
                      className="form-control"
                    />
                  </div>
                  <div css={formGroup}>
                    <label htmlFor="city">City</label>
                    <Field type="text" id="city" name="city" className="form-control" />
                    {props.touched.city && props.errors.city && (
                      <div css={validationError}>{props.errors.city}</div>
                    )}
                  </div>
                  <div css={formGroup}>
                    <label htmlFor="state">State</label>
                    <StatesTypeahead onChange={statesTypeaheadOnChange} />
                    {props.touched.state && props.errors.state && (
                      <div css={validationError}>{props.errors.state}</div>
                    )}
                  </div>
                  <div css={formGroup}>
                    <label htmlFor="zip">Zip code</label>
                    <Field type="text" id="zip" name="zip" className="form-control" />
                    {props.touched.zip && props.errors.zip && (
                      <div css={validationError}>{props.errors.zip}</div>
                    )}
                  </div>
                  <div css={formGroup}>
                    <label htmlFor="country">Country</label>
                    <Field
                      type="text"
                      id="country"
                      name="country"
                      className="form-control"
                      disabled
                    />
                  </div>
                  {props.values.createPhoneTenant ? renderButtons() : null}
                </div>
              )}
            </div>
          )}
        </Form>
      </div>
      <Modal
        onClose={() => {
          closeCreationStatusModal();
        }}
        show={props.loading || !!props.creationStatus}
      >
        <Modal.Header>Creation Status</Modal.Header>
        <Modal.Body>
          {props.loading && (
            <div className={loader}>
              <Loader />
            </div>
          )}
          {props.creationStatus && (
            <div className={creationStatusContainer}>
              <div className={creationStatusListItem}>
                {props.creationStatus.locationCreatedSuccessfully ? (
                  <LocationAlert color="success">
                    Location created successfully.
                  </LocationAlert>
                ) : (
                  <LocationAlert color="danger">Location was not created.</LocationAlert>
                )}
              </div>
              <div className={creationStatusListItem}>
                {props.creationStatus.phoneDataTenantCreatedSuccessfully &&
                tenantToggle ? (
                  <LocationAlert color="success">
                    Phone tenant created successfully.
                  </LocationAlert>
                ) : (
                  <LocationAlert color="danger">
                    Phone tenant was not created.
                  </LocationAlert>
                )}
              </div>
              <div className={creationStatusListItem}>
                {props.creationStatus.customizationFlagsSetSuccessfully &&
                tenantToggle ? (
                  <LocationAlert color="success">
                    Customization flags setup successfully.
                  </LocationAlert>
                ) : (
                  <LocationAlert color="danger">
                    Customization flags were not setup.
                  </LocationAlert>
                )}
              </div>
              <div className={creationStatusListItem}>
                {props.creationStatus.salesforceUpdatedSuccessfully && tenantToggle ? (
                  <LocationAlert color="success">
                    Updated Salesforce successfully.
                  </LocationAlert>
                ) : (
                  <LocationAlert color="danger">
                    Updated Salesforce unsuccessfully.
                  </LocationAlert>
                )}
              </div>
              {props?.creationStatus?.locationProvisionError?.length >= 1 && (
                <div className={creationStatusListItem}>
                  <LocationAlert color="danger">
                    Provisioning error: {props?.creationStatus?.locationProvisionError}
                  </LocationAlert>
                </div>
              )}

              <PrimaryButton
                className={creationStatusButton}
                onClick={() => {
                  closeCreationStatusModal();
                }}
              >
                Okay
              </PrimaryButton>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
