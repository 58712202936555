import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  ContentLoader,
  DropdownField,
  Heading,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextField,
  useControlledField,
  useForm,
} from '@weave/design-system';

import { HandoffMetrics } from './handoff-metrics/handoff-metrics';
import {
  AllHandoffTypes,
  HandoffTypes,
  handoffTypesDisplayValuesMap,
} from './handoff-metrics/handoff.constants';
import { HandoffSnapshot } from '../../../handoff-hub.types';
import { getHandoffTypeConstData } from './handoff-metrics/handoff-metrics-helpers';
import { useDependentMetricData } from './handoff-metrics/use-dependent-metric-data';

type Props = {
  handoffType?: AllHandoffTypes;
  handoffNameInitialValue: string;
  handoffTypeData: ReturnType<typeof getHandoffTypeConstData>;
  dependentData: ReturnType<typeof useDependentMetricData>;
  onHandoffTypeChange: (handoffType: HandoffTypes) => void;
  onNextClick: (updates: Partial<HandoffSnapshot>) => void;
  onSaveAndClose: (updates: Partial<HandoffSnapshot>) => void;
  onCancelClick: () => void;
};

export const HandoffSnapshotMetrics = ({
  handoffType,
  handoffNameInitialValue,
  handoffTypeData,
  dependentData,
  onHandoffTypeChange,
  onNextClick,
  onSaveAndClose,
  onCancelClick,
}: Props) => {
  const { formProps, isComplete, values } = useForm({
    fields: {
      handoffName: {
        type: 'text',
        required: false,
        value: handoffNameInitialValue,
      },
    },
  });

  const handoffTypeFIeldProps = useControlledField({
    type: 'dropdown',
    required: true,
    onChange: (value) => {
      onHandoffTypeChange(value);
    },
    value: handoffType,
  });

  const getHandoffData = () => ({
    handoffName: values.handoffName,
  });

  const disabledButtons = !isComplete;

  return (
    <form {...formProps}>
      <div css={headerContainer}>
        <div>
          <Text weight="bold" css={textStyles}>
            Select Handoff Type
          </Text>
          <Text size="small">Choose handoff type to begin</Text>
        </div>
        <DropdownField
          {...handoffTypeFIeldProps}
          name="handoffType"
          label="Handoff Types"
          css={inputStyle}
        >
          {Object.values(HandoffTypes).map((handoffType) => (
            <DropdownField.Option value={handoffType} key={handoffType}>
              {handoffTypesDisplayValuesMap[handoffType]}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </div>
      {handoffType && (
        <>
          {/* hiding this for now because they asked for it to be removed but could return later */}
          {/*<div css={handoffNameContainer}>*/}
          {/*  <Text weight="bold">Enter Handoff Name</Text>*/}
          {/*  <TextField*/}
          {/*    label={handoffTypesDisplayValuesMap[handoffType]}*/}
          {/*    {...getFieldProps('handoffName')}*/}
          {/*    css={inputStyle}*/}
          {/*  />*/}
          {/*</div>*/}

          <div css={subHeaderContainer}>
            <Heading level={2}>Onboarding Handoff Checklist</Heading>
            <Text color="light">
              Ensure the account is ready for handoff by viewing the checklist below
            </Text>
          </div>

          <HandoffMetrics
            handoffTypeData={handoffTypeData}
            dependentData={dependentData}
          />
        </>
      )}
      <div css={buttonBar}>
        <SecondaryButton css={cancelButton} onClick={onCancelClick}>
          Cancel
        </SecondaryButton>

        <SecondaryButton
          css={saveButton}
          onClick={() => onSaveAndClose(getHandoffData())}
          disabled={disabledButtons}
        >
          Save and Close
        </SecondaryButton>

        <PrimaryButton
          css={nextButton}
          onClick={() => onNextClick(getHandoffData())}
          disabled={disabledButtons}
        >
          Next
        </PrimaryButton>
      </div>
    </form>
  );
};

const handoffNameContainer = css`
  padding-bottom: ${theme.spacing(3)};
`;

const inputStyle = css`
  max-width: 450px;
`;

const headerContainer = css`
  margin-bottom: ${theme.spacing(2)};
`;

const subHeaderContainer = css`
  padding: ${theme.spacing(3, 0)};
  border-top: solid 1px #d8d8d8;
`;

const headerStyle = css`
  font-size: ${theme.fontSize(20)};
  font-weight: 700;
  margin: 0;
`;

const buttonBar = css`
  display: flex;
  justify-content: flex-end;
`;

const cancelButton = css`
  max-width: 150px;
`;

const saveButton = css`
  max-width: 200px;
  margin-right: ${theme.spacing(1)};
  margin: ${theme.spacing(0, 1)};
`;

const nextButton = css`
  max-width: 100px;
`;

const textStyles = css`
  margin: ${theme.spacing(2, 0, 0, 0)};
`;
