import React from 'react';
import { VerticalEnum } from 'models/vertical-enum';
import { UseMutateAsyncFunction } from 'react-query';
import { ModalControlResponse } from '@weave/design-system';
import { LocationFeatureV2 } from 'models/location-feature.model';
import { ToggleState } from 'components/shared/multi-state-toggle/multi-state-toggle.component';
import {
  CustomizationFlagsRequest,
  CustomizationFlagsResponse,
} from '../customization-page-v2.types';

export type CustomizationFlagV2ContextType = {
  isCustomizationFlagEdited: boolean;
  saveCustomizationFlags: () => void;
  cancelSavingCustomizationFlags: () => void;
  verticalId: VerticalEnum;
  loading: boolean;
  refetchCustomizationFlag: () => void;
  customizationFlagEditModalProps: ModalControlResponse;
  canEdit: boolean;
  isGetCustomizationsFlagsByBundlesLoading: boolean;
  tableData: LocationFeatureV2[];
  initialFeatures: LocationFeatureV2[];
  setTableData: (tableData: LocationFeatureV2[]) => void;
  toggleCustomizationFlag: (
    featureToToggle: LocationFeatureV2,
    newState: ToggleState
  ) => void;
  updateFeatureQuota: (feature: LocationFeatureV2, quota: number) => void;
  getCustomizationsFlagsByBundles: UseMutateAsyncFunction<
    CustomizationFlagsResponse,
    any,
    CustomizationFlagsRequest,
    any
  >;
  featuresOutOfSyncMap: Record<string, boolean>;
  preventSyncedFeaturesChange: boolean;
  disableBulkEditCustomization: boolean;
};

export type CustomizationFlagV2ProviderType = {
  children: React.ReactNode;
  value: CustomizationFlagV2ContextType;
};

export const CustomizationFlagV2Context =
  React.createContext<CustomizationFlagV2ContextType>(
    {} as CustomizationFlagV2ContextType
  );

export const CustomizationFlagV2Provider = React.memo(
  ({ children, value }: CustomizationFlagV2ProviderType) => (
    <CustomizationFlagV2Context.Provider value={value}>
      {children}
    </CustomizationFlagV2Context.Provider>
  )
);

export const useCustomizationFlagV2Store = () =>
  React.useContext(CustomizationFlagV2Context);
