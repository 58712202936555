import { InterpolationWithTheme } from '@emotion/core';
import { Text, ConfirmBadgeIcon, ErrorBadgeIcon, Heading } from '@weave/design-system';
import { LocationTCRBrand } from '@weave/schema-gen-ts/dist/schemas/tendlc/shared/v1/shared.pb';
import { theme } from '@weave/theme';
import React, { ReactNode } from 'react';
import { BrandRegistrationStatusIcon } from './registration-status-icons.component';
import { getFriendlyRegistrationStatus } from 'apis/tendlc/utils';

const ICON_SIZE = 18;

type RegistrationStatusIndicatorProps = {
  icon: ReactNode;
  title: string;
  statusText: string;
  hideTitle?: boolean;
  unstyled?: boolean;
};

export const RegistrationStatusIndicator = ({
  icon,
  title,
  statusText,
  hideTitle,
  unstyled,
}: RegistrationStatusIndicatorProps) => {
  return (
    <div css={styles.wrapper(unstyled)}>
      {!hideTitle && <Text weight="bold">{title}</Text>}
      <span css={styles.indicator(unstyled)}>
        {icon}
        <Text size="medium">{statusText}</Text>
      </span>
    </div>
  );
};

const styles: Record<string, (unstyled?: boolean) => InterpolationWithTheme<any>> = {
  wrapper: (unstyled) => [
    {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      alignItems: 'center',

      '> p': {
        borderBottom: `1px solid ${theme.colors.neutral20}`,
        fontSize: theme.fontSize(18),
        margin: 0,
      },
    },
    !unstyled && {
      padding: theme.spacing(1.5, 2),
      backgroundColor: theme.colors.neutral5,
      borderRadius: theme.borderRadius.medium,
    },
  ],
  indicator: () => [
    {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      p: { margin: 0, lineHeight: 0 },
    },
  ],
} as const;

type BrandRegistrationStatusIndicatorProps = {
  locationTcrBrand: LocationTCRBrand;
  locked: boolean;
  iconSize?: number;
};

RegistrationStatusIndicator.Brand = ({
  locationTcrBrand,
  locked,
  iconSize,
}: BrandRegistrationStatusIndicatorProps) => {
  const { entityType, identityStatus, country } = locationTcrBrand.tcrBrand;
  const status = getFriendlyRegistrationStatus({
    entityType,
    identityStatus,
    country,
    locked,
  });

  return (
    <RegistrationStatusIndicator
      title="Business Status"
      icon={
        <BrandRegistrationStatusIcon
          size={iconSize ?? ICON_SIZE}
          friendlyRegistrationStatus={status}
        />
      }
      statusText={status}
    />
  );
};

type PhoneRegistrationStatusIndicatorProps = Pick<
  RegistrationStatusIndicatorProps,
  'hideTitle' | 'unstyled'
> & {
  registered: boolean;
  iconSize?: number;
};

RegistrationStatusIndicator.Phone = ({
  registered,
  hideTitle,
  unstyled,
  iconSize,
}: PhoneRegistrationStatusIndicatorProps) => {
  const statusText = registered ? 'Registered' : 'Unregistered';
  const icon = registered ? (
    <ConfirmBadgeIcon color="success" size={iconSize ?? ICON_SIZE} />
  ) : (
    <ErrorBadgeIcon color="error" size={iconSize ?? ICON_SIZE} />
  );
  return (
    <RegistrationStatusIndicator
      title="Phone Status"
      icon={icon}
      statusText={statusText}
      hideTitle={hideTitle}
      unstyled={unstyled}
    />
  );
};
