import { css } from '@emotion/core';
import { Heading } from '@weave/design-system';
import { theme } from '@weave/theme-original';

import { SubsectionState } from '../handoff-metrics/handoff-metrics';
import { MetricResultsTable } from './metric-results-table';

type MetricResultsModalProps = {
  subsection?: SubsectionState;
};

export const MetricResultsModal = ({ subsection }: MetricResultsModalProps) => {
  return (
    <section
      css={css`
        padding: ${theme.spacing(2, 5)};
      `}
    >
      <Heading
        textAlign="center"
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        {subsection?.name} Test Criteria
      </Heading>

      <MetricResultsTable metricResults={subsection?.metricResults ?? []} />
    </section>
  );
};
