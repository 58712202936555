import React from 'react';
import {
  IconButton,
  InfoRoundIconSmall,
  Text,
  usePopoverDialog,
  PopoverDialog,
} from '@weave/design-system';

export const TenDLCBizStatusInfoButton = () => {
  const { getDialogProps, getTriggerProps } = usePopoverDialog<
    HTMLButtonElement | HTMLAnchorElement
  >({ placement: 'right' });

  return (
    <>
      <IconButton
        label="10DLC Business Status Information"
        size="small"
        {...getTriggerProps()}
      >
        <InfoRoundIconSmall />
      </IconButton>
      <PopoverDialog {...getDialogProps()} css={{ maxWidth: '500px' }}>
        <Text>
          {
            'To register or verify a location go to Portal > Account > Business Information'
          }
        </Text>
        <Text>
          Registration means that location information has been sent to TCR. Verification
          means that the location is recognized by TCR and its messaging traffic should
          flow normally.
        </Text>
        <Text as="ul">
          <Text as="li">
            <Text as="span" weight="bold">
              Unregistered
            </Text>
            : registration process has not been attempted.
          </Text>
          <Text as="li">
            <Text as="span" weight="bold">
              Pending
            </Text>
            : registration has been initiated, and we are waiting on TCR to complete the
            verification of the information provided in the registration. This typically
            takes less than 5 minutes, but could take up to 24 hours.
          </Text>
          <Text as="li">
            <Text as="span" weight="bold">
              Unverified
            </Text>
            : registration has been attempted, but the location is not verified.
          </Text>
          <Text as="li">
            <Text as="span" weight="bold">
              Locked
            </Text>
            : registration has been attempted by the user too many times without success,
            resulting in a locked state. A Weave employee must complete the verification.
          </Text>
          <Text as="li">
            <Text as="span" weight="bold">
              Verified
            </Text>
            : location is successfully recognized by TCR.
          </Text>
          <Text as="li">
            <Text as="span" weight="bold">
              Sole proprietor
            </Text>
            : sole proprietor locations are not registered with TCR and may experience
            message deliverability issues; if the location has an EIN, they should not
            claim sole proprietor status.
          </Text>
          <Text as="li">
            <Text as="span" weight="bold">
              Canada
            </Text>
            : locations in Canada are not required to be registered.
          </Text>
        </Text>
      </PopoverDialog>
    </>
  );
};
