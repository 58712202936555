import React, { useMemo, useState } from 'react';
import { css } from '@emotion/core';
import {
  Heading,
  Text,
  ButtonBar,
  PrimaryButton,
  SecondaryButton,
  SearchField,
  useFormField,
  MultiselectField,
  useControlledField,
  Modal,
} from '@weave/design-system';
import { useTabFilter, Box, WeaveTheme } from '@weave/platform-ui-components';
import { useSelector } from 'react-redux';
import { DeviceModel } from '../../../../redux/actions/devices';
import {
  CallForwardingNumber,
  FwdNumberLabel,
} from '../../../../redux/actions/call-forwarding';
import { DevicesTable, DevicesTableRow } from './DevicesTable';
import { formatPhoneNumber } from '../../../../helpers/utils';
import { selectIsMultiLocationBeta } from '../../../../redux/actions/location';

type Props = {
  callGroupName: string;
  currentForwardingNums: string[];
  currentSipProfiles: string[];
  devices: DeviceModel[];
  forwardingNumbers: CallForwardingNumber[];
  locationMapper: {};
  isOpen: boolean;
  onRequestClose: () => void;
  onSave: (sipProfileIds: string[], fwdNumIds: string[]) => void;
};

const modalHeaderPanel = (theme: WeaveTheme) => css`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(2)};
`;

export const AssignDevicesModal = ({
  callGroupName,
  currentForwardingNums,
  currentSipProfiles,
  devices,
  forwardingNumbers,
  locationMapper,
  isOpen,
  onRequestClose,
  onSave,
}: Props) => {
  const { activeTab, TabFilter, Tab, getTabProps, filterProps } = useTabFilter('device');
  const [selectedSipProfiles, setSelectedSipProfiles] = useState<string[]>([]);
  const [selectedFwdNums, setSelectedFwdNums] = useState<string[]>([]);
  const [deviceFilters, setDeviceFilters] = useState<string[]>([]);
  const [mobileFilters, setMobileFilters] = useState<string[]>([]);
  const [fwdNumsFilters, setFwdNumsFilters] = useState<string[]>([]);
  const searchFieldProps = useFormField({ type: 'text' });
  const mobileProps = useFormField({ type: 'text' });
  const forwardingNumberProps = useFormField({ type: 'text' });
  const isMultiOfficeBeta = useSelector(selectIsMultiLocationBeta);

  const sortedDeskandSoftphoneDevices = useMemo(() => {
    const deskandSoftphoneDevices = devices.filter(
      (device) => device.type === 'desk_phone' || device.type === 'softphone'
    );
    return searchFieldProps?.value
      ? deskandSoftphoneDevices.filter((item) => {
          return (
            item.macAddress
              .toLowerCase()
              .includes(searchFieldProps.value.toLowerCase()) ||
            item.sipProfile?.name
              .toLowerCase()
              .includes(searchFieldProps.value.toLowerCase())
          );
        })
      : deskandSoftphoneDevices.sort((a, b) => {
          const aName = a?.sipProfile?.name || '';
          const bName = b?.sipProfile?.name || '';
          return aName.localeCompare(bName);
        });
  }, [devices, searchFieldProps.value]);

  const updatedMobileDevices = useMemo(() => {
    const mobileDevices = devices.filter((device) => device.type === 'mobile_app');

    return mobileProps?.value
      ? mobileDevices.filter((item) => {
          return (
            item.sipProfile?.name
              .toLowerCase()
              .includes(mobileProps.value.toLowerCase()) ||
            item.name.toLowerCase().includes(mobileProps.value.toLowerCase())
          );
        })
      : mobileDevices.sort((a, b) => {
          const aName = a?.name || '';
          const bName = b?.name || '';
          return aName.localeCompare(bName);
        });
  }, [devices, mobileProps.value]);

  const updatedForwardingNumbers = useMemo(() => {
    return forwardingNumberProps?.value
      ? forwardingNumbers.filter((item) => {
          return (
            item.name.toLowerCase().includes(forwardingNumberProps.value.toLowerCase()) ||
            formatPhoneNumber(item.number)
              .toLowerCase()
              .includes(forwardingNumberProps.value.toLowerCase())
          );
        })
      : forwardingNumbers.sort((a, b) => {
          const aName = a?.name || '';
          const bName = b?.name || '';
          return aName.localeCompare(bName);
        });
  }, [forwardingNumbers, forwardingNumberProps.value]);

  const onDeviceClick = (id: string) => {
    const index = selectedSipProfiles.indexOf(id);

    if (index > -1) {
      // Select the device
      const currentSelectedSipProfiles = [...selectedSipProfiles];
      currentSelectedSipProfiles.splice(index, 1);

      setSelectedSipProfiles(currentSelectedSipProfiles);
    } else {
      // Unselect the device
      setSelectedSipProfiles([...selectedSipProfiles, id]);
    }
  };

  const onFwdNumClick = (id: string) => {
    const index = selectedFwdNums.indexOf(id);

    if (index > -1) {
      // Select the forwarding number
      const currentSelectedFwdNums = [...selectedFwdNums];
      currentSelectedFwdNums.splice(index, 1);

      setSelectedFwdNums(currentSelectedFwdNums);
    } else {
      // Unselect the forwarding number
      setSelectedFwdNums([...selectedFwdNums, id]);
    }
  };

  const onClose = () => {
    setDeviceFilters([]);
    setMobileFilters([]);
    setFwdNumsFilters([]);
    setSelectedFwdNums([]);
    setSelectedSipProfiles([]);
    onRequestClose();
  };

  const listOfLocations: string[] = [];
  devices.forEach((device) => {
    if (
      device.labels &&
      device.labels[0] &&
      listOfLocations.indexOf(locationMapper[device.labels[0].value]) === -1
    ) {
      listOfLocations.push(locationMapper[device.labels[0].value]);
    }
  });

  const deviceFieldProps = useControlledField({
    type: 'multiselect',
    maxAllowed: 4,
    value: deviceFilters,
    onChange: (value: any) => {
      setDeviceFilters(value);
    },
  });

  const mobileFieldProps = useControlledField({
    type: 'multiselect',
    maxAllowed: 4,
    value: mobileFilters,
    onChange: (value: any) => {
      setMobileFilters(value);
    },
  });

  const fwdNumsFieldProps = useControlledField({
    type: 'multiselect',
    maxAllowed: 4,
    value: fwdNumsFilters,
    onChange: (value: any) => {
      setFwdNumsFilters(value);
    },
  });

  return (
    <Modal
      css={css`
        width: 600px !important;
        padding: 40px 24px;
      `}
      onClose={onClose}
      show={isOpen}
    >
      <Heading textAlign="center">Assign Devices to {callGroupName}</Heading>
      <Text
        css={css`
          margin: 16px 0 32px;
        `}
        size="large"
        textAlign="center"
        color="light"
      >
        Add an existing desk phone, mobile app, or forwarding number to the call group.
      </Text>
      <Box width="100%">
        <TabFilter
          {...filterProps}
          css={css`
            margin-bottom: 24px;
          `}
        >
          <Tab {...getTabProps('device')}>Device</Tab>
          <Tab {...getTabProps('mobile')}>Mobile App</Tab>
          <Tab {...getTabProps('forwarding')}>Forwarding #</Tab>
        </TabFilter>
        {activeTab === 'device' && (
          <>
            <div css={isMultiOfficeBeta ? modalHeaderPanel : null}>
              <SearchField
                label={`Search`}
                name="search"
                placeholder="Search"
                {...searchFieldProps}
                css={css`
                  margin-bottom: 2rem;
                `}
              />
              {isMultiOfficeBeta ? (
                <MultiselectField
                  {...deviceFieldProps}
                  label={
                    listOfLocations.length
                      ? 'Filter by location'
                      : 'No location available'
                  }
                  name="Location"
                  disabled={!listOfLocations.length}
                  css={css`
                    width: 20rem;
                  `}
                >
                  {listOfLocations.map((location) => (
                    <MultiselectField.Option key={location} value={location}>
                      {location}
                    </MultiselectField.Option>
                  ))}
                </MultiselectField>
              ) : null}
            </div>
            <DevicesTable
              col1Title="Device Name"
              col2Title={isMultiOfficeBeta ? 'Location' : undefined}
              col3Title="MAC Address"
            >
              {sortedDeskandSoftphoneDevices.map((device) => {
                const labels = device.labels;
                let allowedDevice = false; //Showing only filtered devices including search strings and location selection
                if (deviceFilters.length) {
                  deviceFilters.forEach((location) => {
                    if (
                      labels &&
                      labels[0] &&
                      locationMapper &&
                      location === locationMapper[labels[0].value]
                    )
                      allowedDevice = true;
                  });
                } else allowedDevice = true;
                if (
                  (device.type === 'desk_phone' || device.type === 'softphone') &&
                  allowedDevice
                ) {
                  return (
                    <DevicesTableRow
                      key={device.sipProfile?.ID ?? ''}
                      id={device.sipProfile?.ID ?? ''}
                      col1Value={device.sipProfile?.name ?? ''}
                      col2Value={
                        isMultiOfficeBeta
                          ? labels && labels[0] && locationMapper
                            ? locationMapper[labels[0].value]
                            : 'NA'
                          : null
                      }
                      col3Value={device.macAddress ?? '-'}
                      isSelectable={
                        currentSipProfiles.indexOf(device.sipProfile?.ID ?? '') < 0
                      }
                      isSelected={
                        selectedSipProfiles.indexOf(device.sipProfile?.ID ?? '') > -1
                      }
                      onClick={() => onDeviceClick(device.sipProfile?.ID ?? '')}
                    />
                  );
                }

                return null;
              })}
            </DevicesTable>
          </>
        )}
        {activeTab === 'mobile' && (
          <>
            <div css={isMultiOfficeBeta ? modalHeaderPanel : null}>
              <SearchField
                label="Search"
                name="search"
                placeholder="Search"
                {...mobileProps}
                css={css`
                  margin-bottom: 2rem;
                `}
              />

              {isMultiOfficeBeta ? (
                <MultiselectField
                  {...mobileFieldProps}
                  label={
                    listOfLocations.length
                      ? 'Filter by location'
                      : 'No location available'
                  }
                  name="Location"
                  disabled={!listOfLocations.length}
                  css={css`
                    width: 20rem;
                  `}
                >
                  {listOfLocations.map((location) => (
                    <MultiselectField.Option key={location} value={location}>
                      {location}
                    </MultiselectField.Option>
                  ))}
                </MultiselectField>
              ) : null}
            </div>
            <DevicesTable
              col1Title="Device Name"
              col2Title={isMultiOfficeBeta ? 'Location' : undefined}
              col3Title="SIP Name"
            >
              {updatedMobileDevices.map((device) => {
                const labels = device.labels;
                let allowedDevice = false; //Showing only filtered devices including search strings and location selection
                if (mobileFilters.length) {
                  mobileFilters.forEach((location) => {
                    if (
                      labels &&
                      labels[0] &&
                      locationMapper &&
                      location === locationMapper[labels[0].value]
                    )
                      allowedDevice = true;
                  });
                } else allowedDevice = true;
                if (device.type === 'mobile_app' && allowedDevice) {
                  return (
                    <DevicesTableRow
                      key={device.sipProfile?.ID ?? ''}
                      id={device.sipProfile?.ID ?? ''}
                      col1Value={device.name}
                      col2Value={
                        isMultiOfficeBeta
                          ? labels && labels[0] && locationMapper
                            ? locationMapper[labels[0].value]
                            : 'NA'
                          : null
                      }
                      col3Value={device.sipProfile?.name.substring(0, 20) ?? ''}
                      isSelectable={
                        currentSipProfiles.indexOf(device.sipProfile?.ID ?? '') < 0
                      }
                      isSelected={
                        selectedSipProfiles.indexOf(device.sipProfile?.ID ?? '') > -1
                      }
                      onClick={() => onDeviceClick(device.sipProfile?.ID ?? '')}
                    />
                  );
                }

                return null;
              })}
            </DevicesTable>
          </>
        )}
        {activeTab === 'forwarding' && (
          <>
            <div css={isMultiOfficeBeta ? modalHeaderPanel : null}>
              <SearchField
                label={`Search`}
                name="search"
                placeholder="Search"
                {...forwardingNumberProps}
                css={css`
                  margin-bottom: 2rem;
                `}
              />
              {isMultiOfficeBeta ? (
                <MultiselectField
                  {...fwdNumsFieldProps}
                  label={
                    listOfLocations.length
                      ? 'Filter by location'
                      : 'No location available'
                  }
                  name="Location"
                  disabled={!listOfLocations.length}
                  css={css`
                    width: 20rem;
                  `}
                >
                  {listOfLocations.map((location) => (
                    <MultiselectField.Option key={location} value={location}>
                      {location}
                    </MultiselectField.Option>
                  ))}
                </MultiselectField>
              ) : null}
            </div>
            <DevicesTable
              col1Title="Device Name"
              col2Title={isMultiOfficeBeta ? 'Location' : undefined}
              col3Title="Phone Number"
            >
              {updatedForwardingNumbers.map((fn) => {
                const label: FwdNumberLabel | undefined = fn.Labels?.at(0);
                let allowedDevice = false; //Showing only filtered devices including search strings and location selection

                if (fwdNumsFilters.length) {
                  fwdNumsFilters.forEach((location) => {
                    if (
                      label &&
                      locationMapper &&
                      location === locationMapper[label.Value]
                    )
                      allowedDevice = true;
                  });
                } else allowedDevice = true;
                if (allowedDevice) {
                  return (
                    <DevicesTableRow
                      key={fn.id}
                      id={fn.id}
                      col1Value={fn.name}
                      col2Value={
                        isMultiOfficeBeta
                          ? label && locationMapper
                            ? locationMapper[label.Value]
                            : 'NA'
                          : null
                      }
                      col3Value={formatPhoneNumber(fn.number)}
                      isSelectable={currentForwardingNums.indexOf(fn.id) < 0}
                      isSelected={selectedFwdNums.indexOf(fn.id) > -1}
                      onClick={() => onFwdNumClick(fn.id)}
                    />
                  );
                }
                return null;
              })}
            </DevicesTable>
          </>
        )}
      </Box>
      <ButtonBar>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton
          disabled={!selectedFwdNums.length && !selectedSipProfiles.length}
          onClick={() => {
            onSave(selectedSipProfiles, selectedFwdNums);
            onClose();
          }}
        >
          Assign Devices
        </PrimaryButton>
      </ButtonBar>
    </Modal>
  );
};
