import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/core';
import {
  TrashIcon,
  IconButton,
  Text,
  PrimaryButton,
  ButtonBar,
  SecondaryButton,
  EditIcon,
  Modal,
  DropdownField,
  useFormField,
  useModalControl,
  FormRow,
  CheckboxField,
  useForm,
  DatePickerField,
  ValidatorFieldState,
  PhoneField,
  PlusIcon,
} from '@weave/design-system';
import { Loader } from '../../shared/loader/loader.component';
import { chunk, cloneDeep, noop } from 'lodash';
import { SuccessSVG } from '../porting-hub/success-svg';
import { portingCardStyles } from './porting-card.styles';
import { Provider } from './constants';
import {
  getUTCDateWithPortingTimeString,
  isHoliday,
  isWeekend,
  USMountainTZ,
  isEqualStringUnorderedArray,
  convertToDateWithSpecificTime,
  convertUsDateToIsoDateFormat,
} from '../utils/porting-utils';
import { theme } from '@weave/theme-original';
import {
  getPortingUrl,
  sendToSalesforce,
  clonePortingData,
  cancelPortOrder,
  updatePhoneNumbers,
  getPortingValidationData,
  getAvailableFOCDates,
} from '../../../apis/porting/porting.api';
import { getErrorMessage } from 'redux/axios';
import {
  PortGetData,
  PortingData,
  MediaData,
  MediaType,
  NumberType,
  RequestClient,
  PortingRequest,
  ValidationData,
  InternalNumberPortingType,
} from 'apis/porting/porting.types';
import { format, differenceInMinutes, parseISO } from 'date-fns';
import { PortingCardOfficeInfo } from './porting-card-office-info.component';
import { PortingCardAttachments } from './port-card-attachments.component';
import { zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';
import { PortStatusMapping, PortStatus, CountryCodes } from '../utils/port-constants';
import { useAlert } from '@weave/alert-system';
import { MessageDialogue } from '../utils/message-dialogue.component';
import { iconButtonSvgPrimary } from 'components/location-sidebar-container/location-sidebar.styles';
import _ from 'lodash';
import { portingValidationError } from './error-code';
import { SupRequestModal } from './sup-request-modal';
import { Page } from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'redux/store/store.model';
import { getUser } from 'redux/actions/auth/user/user.action';
import dayjs from 'dayjs';

type MessageModal = {
  header: string;
  body: string;
  success: boolean;
  actionLabel: string;
  onClose: () => void;
  onActionLabelClick: () => void;
};

interface Props {
  port: PortGetData;
  submitPortingDetails: any;
  downloadLOALoading: boolean;
  splitPortingRequest: number;
  downloadBillLoading: boolean;
  submitPortingLoading: boolean;
  splitPortingRequestsResults: any;
  updatePortingDetailsLoading: boolean;
  deletePortingRequestLoading: boolean;
  back: () => void;
  downloadLOA: (port) => void;
  downloadBill: (port) => void;
  getStatusColor: (port) => string;
  checkPortability: (port) => void;
  setPortingDetails: (port) => void;
  resetPortabilityCheck: () => void;
  updatePortingDetails: (port) => void;
  clearSubmitPortingDetails: () => void;
  deletePortingRequest: (id: string) => void;
  submitSplitPortingRequests: (port) => void;
}

const cancellationTimeDiffInMin = 72 * 60 + 5; // represents minutes in 72h + 5min for safe play

export const PortingCard = (props: Props) => {
  const alert = useAlert();
  const [portOrderUrl, setPortOrderUrl] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [duplicateError, setDuplicateError] = useState<string>('');
  const [portingInfoEdit, setPortingInfoEdit] = useState<boolean>(false);
  const [phoneNumbersEdit, setPhoneNumbersEdit] = useState<boolean>(false);
  const [officeInfoEdit, setOfficeInfoEdit] = useState<boolean>(false);
  const [canEditAttachments, setCanEditAttachments] = useState<boolean>(false);
  const [portingInfoError, setPortingInfoError] = useState<string>('');
  const [portingRequestToCancel, setPortingRequestToCancel] = useState<any>();
  const [showCancelPortModal, setShowCancelPortModal] = useState<boolean>(false);
  const [showCancelLoading, setShowCancelLoading] = useState<boolean>(false);
  const [portingProviderError, setPortingProviderError] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showPhoneModal, setShowPhoneModal] = useState<boolean>(false);
  const [portingValidationData, setPortingValidationData] = useState<ValidationData[]>(
    []
  );
  const [documentError, setDocumentError] = useState<ValidationData[]>([]);
  const [phoneNumberError, setPhoneNumberError] = useState<ValidationData[]>([]);
  const [portingInformationError, setPortingInformationError] = useState<
    ValidationData[]
  >([]);
  const [officeInformationError, setOfficeInformationError] = useState<ValidationData[]>(
    []
  );
  const [missingRequestedPort, setMissingReqestedPort] = useState(false);
  const [missingPortingCompany, setMissingPortingCompany] = useState(false);
  const {
    modalProps: messageModalProps,
    closeModal,
    triggerProps: triggerModal,
  } = useModalControl();
  const {
    modalProps: cancelConfirmationModalProps,
    closeModal: closeCancelModal,
    triggerProps: triggerCancelProps,
  } = useModalControl();
  const [messagePortModal, setMessagePortModal] = useState<MessageModal>({
    header: '',
    body: '',
    success: true,
    actionLabel: 'Okay',
    onClose: () => {},
    onActionLabelClick: () => {},
  });
  const [focDates, setFocDates] = useState<string[]>([]);
  const [minDateFoc, setMinDateFoc] = useState<string>('');
  const [maxDateFoc, setMaxDateFoc] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [duplicateNumberError, setDuplicateNumberError] = useState<boolean>(false);
  const [removedDateInformation, setRemovedDateInformation] = useState<string>('');
  const dispatch = useDispatch();
  const username = useSelector((state: Store) => state.auth.user.username);

  useEffect(() => {
    dispatch(getUser({ username }));
  }, [username]);

  const {
    port,
    downloadLOALoading,
    downloadBillLoading,
    splitPortingRequest,
    submitPortingLoading,
    submitPortingDetails,
    splitPortingRequestsResults,
    deletePortingRequestLoading,
    updatePortingDetailsLoading,
    back,
    downloadLOA,
    getStatusColor,
    checkPortability,
    setPortingDetails,
    updatePortingDetails,
    deletePortingRequest,
    resetPortabilityCheck,
    clearSubmitPortingDetails,
    submitSplitPortingRequests,
  } = props;

  const existingNumberValues: PortingRequest[] = [];
  const existingFaxValue: PortingRequest[] = [];

  port?.porting_requests.forEach(
    (portingRequest) =>
      portingRequest.number_type === NumberType.PHONE &&
      existingNumberValues.push(portingRequest)
  );

  port?.porting_requests.forEach(
    (portingRequest) =>
      portingRequest.number_type === NumberType.FAX &&
      existingFaxValue.push(portingRequest)
  );

  const [newNumberField, setNewNumberField] =
    useState<PortingRequest[]>(existingNumberValues);
  const [newFaxField, setNewFaxField] = useState<PortingRequest[]>(existingFaxValue);

  // references Provider map from ./constants and hardcoded to 2 because same values are stored in backend
  const ProviderTelnyx = 2;
  const providerDropdownProps = useFormField({
    type: 'text',
    value: Provider.has(port.provider) ? port.provider?.toString() : '',
  });

  const getPhoneNumbers = (portingRequests: PortingRequest[]): string[] =>
    portingRequests.map((portingRequest) => portingRequest?.phone_number);

  const setDisableProperty = () => {
    if (duplicateNumberError) {
      return true;
    }
    const numberFieldLengthArray = newNumberField?.map(
      (data) => data?.phone_number.length
    );
    const faxFieldLengthArray = newFaxField?.map((data) => data?.phone_number.length);

    if (!faxFieldLengthArray.length && !numberFieldLengthArray.length) {
      return true;
    }

    if (
      !isEqualStringUnorderedArray(
        getPhoneNumbers(existingNumberValues),
        getPhoneNumbers(newNumberField)
      ) ||
      !isEqualStringUnorderedArray(
        getPhoneNumbers(existingFaxValue),
        getPhoneNumbers(newFaxField)
      )
    ) {
      if (
        numberFieldLengthArray?.some((el) => el < 10) ||
        faxFieldLengthArray?.some((el) => el < 10)
      )
        return true;
      else {
        return false;
      }
    }
    return true;
  };

  const getTypeOfPortingRequest = (): string => {
    switch (port?.port_type) {
      case InternalNumberPortingType.PORTING_TYPE_MAIN:
        return 'Main';
      case InternalNumberPortingType.PORTING_TYPE_INTERNAL:
        return 'Internal';
      default:
        return 'Unknown';
    }
  };

  useEffect(() => {
    const getPortingOrderUrl = async () => {
      const provider =
        port.provider !== 0 &&
        Provider.has(port?.provider) &&
        Provider.get(port?.provider);
      try {
        if (provider === 'Telnyx' && portingRequest?.port_order_number) {
          const result = await getPortingUrl(port?.id);
          setPortOrderUrl(result);
        } else {
          setPortOrderUrl(port?.port_order_url);
        }
      } catch (error) {
        alert.error(`Error fetching the port order URL ${error}`);
      }
    };

    getPortingOrderUrl();
  }, []);

  const findDuplicateValue = (arr1: string[], arr2: string[]): string[] => {
    return arr1?.filter((arr1Item) => arr2.includes(arr1Item));
  };
  useEffect(() => {
    const newPhnArray = newNumberField?.map((phnData) => {
      return phnData?.phone_number;
    });
    const newFaxArray = newFaxField?.map((phnData) => {
      return phnData?.phone_number;
    });
    const result = findDuplicateValue(newPhnArray, newFaxArray);
    setDuplicateNumberError(!!result?.length);
  }, [newNumberField]);
  useEffect(() => {
    const newPhnArray = newNumberField?.map((phnData) => {
      return phnData?.phone_number;
    });
    const newFaxArray = newFaxField?.map((phnData) => {
      return phnData?.phone_number;
    });
    const result = findDuplicateValue(newPhnArray, newFaxArray);
    setDuplicateNumberError(!!result?.length);
  }, [newFaxField]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [missingRequestedPort, missingPortingCompany]);

  const handleNewNumberAddition = (numberType: NumberType) => {
    setDuplicateError('');
    numberType === NumberType.PHONE
      ? setNewNumberField([
          ...newNumberField,
          {
            phone_number: '',
            port_type: 0,
            number_type: NumberType.PHONE,
            porting_data_id: port.id,
            requested_firm_order_commitment_date:
              port.porting_requests?.[0].requested_firm_order_commitment_date,
          },
        ])
      : setNewFaxField([
          ...newFaxField,
          {
            phone_number: '',
            port_type: 0,
            number_type: NumberType.FAX,
            porting_data_id: port.id,
            requested_firm_order_commitment_date:
              port.porting_requests?.[0].requested_firm_order_commitment_date,
          },
        ]);
  };

  const handleNumberDeletion = (findIndex, numberType) => {
    setDuplicateError('');
    numberType === NumberType.PHONE
      ? setNewNumberField(newNumberField.filter((numbers, index) => index !== findIndex))
      : setNewFaxField(newFaxField.filter((numbers, index) => index !== findIndex));
  };

  const handleNumberChange = (value, index, numberType) => {
    setDuplicateError('');
    const numberValue = value.value;
    const list = numberType === NumberType.PHONE ? [...newNumberField] : [...newFaxField];
    list[index].phone_number = numberValue;
    numberType === NumberType.PHONE ? setNewNumberField(list) : setNewFaxField(list);
  };

  const today = new Date();
  const minDate = format(new Date(), 'MM/dd/yyyy');
  const getFormattedDate = (date?: string): string => {
    return format(parseISO(date?.split('T')[0] ?? ''), 'MM/dd/yyyy');
  };

  const getAvailableFOCDate = async () => {
    const availableDatesForFoc = await getAvailableFOCDates(port.id);
    setRemovedDateInformation(availableDatesForFoc?.[0].split('T')[1]);
    const availableDates = availableDatesForFoc.map((item) => item.split('T')[0]);
    setFocDates(availableDates);
    setMinDateFoc(format(new Date(availableDates?.[0]), 'MM/dd/yyyy'));
    setMaxDateFoc(
      format(new Date(availableDates?.[availableDates?.length - 1]), 'MM/dd/yyyy')
    );
  };

  const getRequestedPortDate = (): string =>
    getFormattedDate(port?.porting_requests?.[0]?.requested_firm_order_commitment_date);

  const { getFieldProps } = useForm({
    fields: {
      asapPort: { type: 'checkbox', value: port.request_asap_port },
      phone: { type: 'phone' },
      requestedPortDate: {
        type: 'datePicker',
        minDate: getUTCDateWithPortingTimeString(today.toISOString()),
        value: port?.porting_requests?.[0]?.requested_firm_order_commitment_date
          ? getRequestedPortDate()
          : '',
        validator: ({ value }: ValidatorFieldState<'datePicker'>) => {
          const formattedValue = convertUsDateToIsoDateFormat(value);
          const formattedMinDate = convertUsDateToIsoDateFormat(minDate);

          return formattedValue < formattedMinDate
            ? 'Requested port date cannot be set in the past'
            : '';
        },
      },
    },
  });

  const requestPortDate = getFieldProps('requestedPortDate').value;

  useEffect(() => {
    setPortingProviderError('');
  }, [requestPortDate]);

  useEffect(() => {
    setDisableProperty();
  }, [newNumberField, newFaxField]);

  const filterPortingValidations = (
    portingValidationData: ValidationData[],
    minErrorCode: number,
    maxErrorCode: number
  ): ValidationData[] => {
    return portingValidationData?.filter((portingInformationData) => {
      return (
        portingInformationData.error_code >= minErrorCode &&
        portingInformationData.error_code <= maxErrorCode
      );
    });
  };

  const getValidationDataForPorting = async () => {
    try {
      const validationData = await getPortingValidationData(port?.id);
      const portingValidationData = validationData?.data?.data?.porting_validations;
      const porting_Information_Error = filterPortingValidations(
        portingValidationData,
        portingValidationError.porting_information_error.min_error_code,
        portingValidationError.porting_information_error.max_error_code
      );

      const office_Information_Error = filterPortingValidations(
        portingValidationData,
        portingValidationError.office_information_error.min_error_code,
        portingValidationError.office_information_error.max_error_code
      );
      const document_Error = filterPortingValidations(
        portingValidationData,
        portingValidationError.document_error.min_error_code,
        portingValidationError.document_error.max_error_code
      );

      const phone_Number_Error = filterPortingValidations(
        portingValidationData,
        portingValidationError.phone_information_error.min_error_code,
        portingValidationError.phone_information_error.max_error_code
      );

      setPortingValidationData(portingValidationData);
      setPortingInformationError(porting_Information_Error);
      setDocumentError(document_Error);
      setPhoneNumberError(phone_Number_Error);
      setOfficeInformationError(office_Information_Error);
    } catch (error) {
      setError(`Unable to fetch the validation data,please try again${error}`);
    }
  };

  useEffect(() => {
    if (port?.porting_requests?.[0].porting_status === PortStatus.validationFailed) {
      getValidationDataForPorting();
    }
  }, [port]);

  useEffect(() => {
    const portCopy = cloneDeep(port);
    const date = requestPortDate && convertToDateWithSpecificTime(requestPortDate);
    const dateInUTCZone =
      requestPortDate &&
      formatInTimeZone(date, 'America/New_York', 'yyyy-MM-dd') + 'T00:00:00Z';
    for (const portingRequest of portCopy.porting_requests) {
      portingRequest.requested_firm_order_commitment_date = dateInUTCZone;
    }
    setPortingDetails(portCopy);
  }, [requestPortDate]);

  useEffect(() => {
    if (canEditAttachments) {
      setError(validateMediaData(port.customer_phone_bill_media));
    }
  }, [port.customer_phone_bill_media]);

  useEffect(() => {
    window.scroll(0, 0);
    return () => {
      clearSubmitPortingDetails();
    };
  }, [port.id]);

  const updatePortProvider = () => {
    if (!port.provider) {
      port.provider = 0;
    }
    const portCopy = cloneDeep(port);
    portCopy.provider = Number.parseInt(providerDropdownProps.value);
    portCopy.porting_requests[0].porting_carrier = Provider.get(
      portCopy.provider
    )?.toUpperCase();
    setPortingDetails(portCopy);
    updatePortingDetails(portCopy);
  };

  const submit = () => {
    const err = validate();
    if (!err) {
      setError('');
      checkPortability(port);
    } else {
      setError(err);
    }
  };

  const validate = (): string => {
    if (!port?.porting_requests[0]?.requested_firm_order_commitment_date) {
      setMissingReqestedPort(true);
    }
    if (!port?.provider) {
      setMissingPortingCompany(true);
    }
    if (
      !(
        port?.porting_requests[0]?.requested_firm_order_commitment_date &&
        port?.company_name &&
        port?.authorized_user_first_name &&
        port?.phone_service_account_number &&
        port?.service_city &&
        port?.service_street1 &&
        port?.service_zip &&
        port?.service_state &&
        port?.provider
      )
    ) {
      return 'Oops! We were unable to submit your port. Please make sure fields highlighted field are not empty and resubmit.';
    }

    return validateMediaData(port.customer_phone_bill_media);
  };

  const validateMediaData = (mediaData: MediaData[]): string => {
    if (port?.service_country === CountryCodes.USA.Code) {
      return '';
    }
    if (
      (!mediaData ||
        mediaData.length === 0 ||
        mediaData?.findIndex((md: MediaData) => md.media_type === MediaType.phoneBill) ===
          -1) &&
      !port.reason_bill_not_available
    ) {
      return 'Either phone bill should be uploaded or reason of bill not available should be provided.';
    }

    return '';
  };

  const handleSendToSalesforce = async () => {
    setShowLoading(true);
    try {
      await sendToSalesforce(port.id);
      setShowLoading(false);
      setMessagePortModal({
        body: 'Successfully sent to salesforce',
        header: 'Success',
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } catch (error) {
      console.error(error);
      setShowLoading(false);
      setMessagePortModal({
        header: 'Failed',
        body: getErrorMessage(error),
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } finally {
      triggerModal.onClick();
    }
  };

  const handleDeletePortingRequest = (requestId) => {
    deletePortingRequest(requestId);
    setPortingRequestToCancel(undefined);
  };

  const handleDrawerToggle = (
    drawer: string,
    toggleState: boolean,
    disableToggle = false
  ) => {
    switch (drawer) {
      case 'porting':
        setPortingInfoEdit(!toggleState);
        break;
      case 'phone':
        setPhoneNumbersEdit(!toggleState);
        break;
      case 'office':
        setOfficeInfoEdit(!toggleState);
        break;
      case 'attachments':
        if (!disableToggle) setCanEditAttachments(!toggleState);
        else {
          alert.warning('Please complete the form and save.');
        }
        break;
      default:
        break;
    }
  };

  const portingRequest = port.porting_requests[0];
  const filterDidsNumber = port?.porting_requests?.filter(
    (portingNumber) => portingNumber.number_type === NumberType.PHONE
  );
  const filterFaxDidsNumber = port?.porting_requests?.filter(
    (portingNumber) => portingNumber.number_type === NumberType.FAX
  );
  const chunkedPhoneNumbers: PortingRequest[][] = chunk(filterDidsNumber, 9);
  const chunkedFaxNumbers: PortingRequest[][] = chunk(filterFaxDidsNumber, 9);
  const invalidCancellationPortStatuses = [
    PortStatus.cancelled,
    PortStatus.cancelPending,
    PortStatus.cancelSubmitted,
    PortStatus.requestedCancel,
    PortStatus.completed,
    PortStatus.splitPort,
    PortStatus.activationInProgress,
    PortStatus.validationInProgress,
  ];

  const invalidSendToSaleForceStatus = [PortStatus.completed, PortStatus.splitPort];

  const validSUPRequestPortStatuses = [PortStatus.requestedFOC];

  const showPortOrderCancelButton = (): boolean => {
    if (portingRequest.porting_status) {
      const portStatus = portingRequest.porting_status;

      if (invalidCancellationPortStatuses.includes(portStatus)) {
        return false;
      }
    }
    return true;
  };

  const showSendToSaleForceButton = (): boolean => {
    if (portingRequest.porting_status) {
      const portStatus = portingRequest.porting_status;

      if (invalidSendToSaleForceStatus.includes(portStatus)) {
        return false;
      }
    }
    return true;
  };

  function getBusinessDatesCount(startDate, endDate) {
    /*this function assign number to each date based on the day its occuring for
  eg sun - 0 to sat- 7 so the date we are passing is also considered in the count for eg 14-dec to 19 dec it will return 4 as the count  */
    let count = 0;
    const curDate = new Date(startDate.getTime());
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }

  const canCancelORRaiseSUPRequest = (status: string): boolean => {
    if (!port.porting_requests?.[0]?.firm_order_commitment_date) {
      return true;
    }

    const startDate = new Date();
    const endDate = new Date(port.porting_requests?.[0]?.firm_order_commitment_date!);

    const numOfDates = getBusinessDatesCount(startDate, endDate);

    if (numOfDates <= 4) {
      /*we are checking here with 4 beacuse the starting date is also included in the function */
      alert.error(
        `Cannot ${status} as FOC is within 3 business days. Please contact Porting team`
      );
      return false;
    }
    return true;
  };

  const handleCancelClick = () => {
    if (canCancelORRaiseSUPRequest('cancel')) {
      setShowCancelPortModal(true);
    }
  };

  const cancelPhoneChanges = () => {
    setNewNumberField(existingNumberValues);
    setNewFaxField(existingFaxValue);
    setShowPhoneModal(false);
  };

  const showSupRequestButton =
    portingRequest?.porting_status &&
    validSUPRequestPortStatuses.includes(portingRequest?.porting_status);

  const cancelPort = async () => {
    setShowCancelLoading(true);
    const successMsg =
      portingRequest.port_order_number &&
      !portingRequest.port_order_number.includes('Cancelled')
        ? 'Requested cancellation of port order'
        : 'Port order cancelled';

    try {
      await cancelPortOrder(port.id);
      setMessagePortModal({
        body: successMsg,
        header: 'Success',
        success: true,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: () => {
          closeModal();
          back();
        },
      });
    } catch (error) {
      console.error(error);
      setMessagePortModal({
        header: 'Failed',
        body: getErrorMessage(error),
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } finally {
      setShowCancelLoading(false);
      setShowCancelPortModal(false);
      triggerModal.onClick();
    }
  };

  const handleClonePortingData = async () => {
    setShowLoading(true);
    try {
      const clonedPortingData: PortingData = await clonePortingData(port.id);
      setMessagePortModal({
        body: 'Port order cloned. Open cloned port order',
        header: 'Success',
        success: true,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: (): void => {
          closeModal();
          setPortingDetails(clonedPortingData);
        },
      });
    } catch (error) {
      console.error(error);
      setMessagePortModal({
        header: 'Failed',
        body: getErrorMessage(error),
        success: false,
        actionLabel: 'Okay',
        onClose: closeModal,
        onActionLabelClick: closeModal,
      });
    } finally {
      setShowLoading(false);
      triggerModal.onClick();
    }
  };

  const handleUploadLoaClick = (order_id: string) => {
    downloadLOA(port);
    const url = `https://dashboard.bandwidth.com/portal/report/#orderdetail:%7B"a":5001811,"t":"PORT_IN","o":"${order_id}"%7D`;
    window.open(url, '_blank');
  };
  const submitButtonDisplay =
    portingInfoEdit ||
    officeInfoEdit ||
    phoneNumbersEdit ||
    portingValidationData?.length > 0;

  const getPhoneNumberList = (
    chunkedPhoneNumbers: any,
    numberType: NumberType,
    newPhoneNumberField: PortingRequest[]
  ) => {
    return (
      <div>
        {chunkedPhoneNumbers.map((phoneNumbers, ind) => (
          <div key={ind} className={portingCardStyles.phoneCont}>
            {phoneNumbers
              .filter((portingRequest) => portingRequest.number_type === numberType)
              .map(
                (request, index) =>
                  !phoneNumbersEdit && (
                    <Text
                      key={index}
                      css={css`
                        color: ${theme.colors.gray500};
                        margin-left: 10px;
                      `}
                    >
                      {request.phone_number}
                    </Text>
                  )
              )}
          </div>
        ))}
        {phoneNumbersEdit && (
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;

              margin-bottom: ${theme.spacing(1)};
              margin-left: ${theme.spacing(4)};
            `}
          >
            {newPhoneNumberField?.map((existingNumberField: PortingRequest, index) => (
              <div
                key={index}
                css={css`
                  display: flex;
                  margin-bottom: ${theme.spacing(2)};
                `}
              >
                <PhoneField
                  {...getFieldProps('phone')}
                  value={existingNumberField?.phone_number || ''}
                  onChange={(value) => handleNumberChange(value, index, numberType)}
                  label="Add a phone number"
                />
                <IconButton
                  label="Remove Phone Number"
                  hoverLabelPlacement="top"
                  showLabelOnHover
                  css={css`
                    height: 24px;
                    margin-left: ${theme.spacing(1)};
                    width: 24px;
                    margin-top: ${theme.spacing(1)};
                    svg {
                      fill: #ff6b59;
                    }
                  `}
                >
                  <TrashIcon
                    color="error"
                    onClick={() => handleNumberDeletion(index, numberType)}
                  />
                </IconButton>
                {newPhoneNumberField?.length - 1 === index && (
                  <IconButton
                    label="Create Sync App"
                    onClick={() => handleNewNumberAddition(numberType)}
                    css={iconButtonSvgPrimary}
                    disabled={existingNumberField?.phone_number.length <= 3}
                  >
                    <PlusIcon />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        )}
        {phoneNumbersEdit && !newPhoneNumberField.length && (
          <div
            style={{
              marginTop: `${theme.spacing(-2)}`,
              marginLeft: `${theme.spacing(6)}`,
            }}
          >
            <IconButton
              label="Create Sync App"
              onClick={() => handleNewNumberAddition(numberType)}
              css={iconButtonSvgPrimary}
            >
              <PlusIcon />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const voicePhoneNumbersList = useMemo(
    () => getPhoneNumberList(chunkedPhoneNumbers, NumberType.PHONE, newNumberField),
    [chunkedPhoneNumbers]
  );
  const faxPhoneNumbersList = useMemo(
    () => getPhoneNumberList(chunkedFaxNumbers, NumberType.FAX, newFaxField),
    [chunkedPhoneNumbers]
  );

  const appendPortingRequests = (
    newNumberField: PortingRequest[],
    portCopy,
    portingRequests: PortingRequest[],
    number_type: NumberType
  ) =>
    newNumberField.map((numberField) =>
      numberField === portCopy.billing_phone_number
        ? [...portingRequests].concat(portCopy.porting_requests)
        : [...portingRequests].concat(numberField)
    );

  const handlePhoneNumberUpdate = async () => {
    const portCopy = cloneDeep(port);
    const portingRequests: PortingRequest[] = [];
    const newNumberdata = appendPortingRequests(
      newNumberField,
      portCopy,
      portingRequests,
      NumberType.PHONE
    );
    const newFaxData = appendPortingRequests(
      newFaxField,
      portCopy,
      portingRequests,
      NumberType.FAX
    );

    try {
      const resp: PortingRequest[] = await updatePhoneNumbers(
        [...newNumberdata, ...newFaxData].flat()
      );
      portCopy.porting_requests = resp;
      setPortingDetails(portCopy);
      setPhoneNumbersEdit((prevState) => !prevState);
      setDuplicateError('');
    } catch (error) {
      setDuplicateError(error?.response?.data?.error);
    }
  };

  const handleModalOpening = () => {
    const showModal = setDisableProperty();
    setShowPhoneModal(!showModal);
  };

  const updatePortDetialsByFoc = (port: PortGetData) => {
    setPortingDetails(port);
  };

  const handleSUPRequestClick = () => {
    if (canCancelORRaiseSUPRequest('request SUP')) {
      try {
        getAvailableFOCDate();
      } catch (error) {
        alert.error(`Error while getting available foc dates ${error}`);
      }
      setVisible(true);
      triggerCancelProps.onClick();
    }
  };

  const getStatusForPorting = () => {
    const filteredPortRequest: PortingRequest[] = port?.porting_requests.filter(
      (request) => request?.porting_status !== PortStatus.delete
    ); /* if the status is deleted don't show it, show the status of next porting_request */

    return filteredPortRequest[0]?.porting_status
      ? PortStatusMapping[filteredPortRequest[0]?.porting_status]
      : 'Draft';
  };

  return (
    <Page title={`Port Order:`} subtitle={`${portingRequest.port_order_number ?? ''}`}>
      {updatePortingDetailsLoading && (
        <div className={portingCardStyles.loading}>
          <Loader />
        </div>
      )}
      <div>
        <div className={portingCardStyles.backButtonStyle} onClick={back}>
          {'< back'}
        </div>

        <div className={portingCardStyles.displayStyle}>
          <Text
            className={portingCardStyles.portingHubText}
            css={css`
              margin-bottom: ${theme.spacing(0)};
            `}
          >
            View, edit, or submit information for this port order below
          </Text>
          <div className={portingCardStyles.requestContainerButtons}>
            <div className={portingCardStyles.supRequest}>
              {showSupRequestButton && (
                <PrimaryButton size="large" color="blue" onClick={handleSUPRequestClick}>
                  SUP Request
                </PrimaryButton>
              )}
            </div>
            <div className={portingCardStyles.cancellationRequest}>
              {showPortOrderCancelButton() && (
                <PrimaryButton size="large" destructive onClick={handleCancelClick}>
                  Cancellation Request
                </PrimaryButton>
              )}
            </div>
          </div>
        </div>
        <div className={portingCardStyles.errorMessage}>{error}</div>
        <div className={portingCardStyles.container}>
          <div className={portingCardStyles.backgroundStyle}>
            <div className={portingCardStyles.flexCenter}>
              <div
                css={css`
                  margin-right: 15px;
                `}
              >
                <div
                  css={css`
                    padding: ${theme.spacing(1.5)};
                    border: 1px solid ${getStatusColor(portingRequest)};
                    background-color: ${getStatusColor(portingRequest)};
                    border-radius: 50%;
                  `}
                />
              </div>
              <div
                css={css`
                  width: 100%;
                `}
              >
                <div className={portingCardStyles.flexContainer}>
                  <div>{getStatusForPorting()}</div>
                  <div>
                    Requested Port Date:{' '}
                    {portingRequest?.requested_firm_order_commitment_date &&
                      getRequestedPortDate()}
                  </div>
                </div>

                <div className={portingCardStyles.portOrderType}>
                  <div>Port Order # {portingRequest.port_order_number}</div>
                  <div>
                    {portingRequest.number_type === NumberType.PHONE
                      ? 'Voice/SMS'
                      : 'Fax'}{' '}
                    Port
                  </div>
                </div>

                <div
                  css={css`
                    text-align: right;
                    margin-top: 5px;
                  `}
                >
                  <Text>
                    Created Date:{' '}
                    {portingRequest.created_at &&
                      format(new Date(portingRequest.created_at), 'MM/dd/yy')}
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div
            css={css`
              padding: 25px;
            `}
          >
            <div className={portingCardStyles.portingInfoContainer}>
              <div className={portingCardStyles.title}>Porting Information</div>
              {!portingRequest.port_order_number && (
                <div>
                  <EditIcon
                    css={css`
                      color: ${!portingInfoEdit && theme.colors.weaveBlue};
                    `}
                    onClick={() => handleDrawerToggle('porting', portingInfoEdit)}
                  />
                </div>
              )}
            </div>
            {portingInformationError?.map((data) => (
              <Text color="error" key={data.id}>
                {data.reason}
              </Text>
            ))}

            <div className={portingCardStyles.requestedPortDate}>
              <Text
                css={css`
                  ${portingInfoEdit ? 'margin-bottom: 12px;' : 'margin-bottom: 0px;'};
                `}
              >
                Porting Data Id
              </Text>
              <Text
                css={css`
                  color: ${theme.colors.gray500};
                  ${portingInfoEdit ? 'margin-bottom: 12px;' : 'margin-bottom: 0px;'};
                `}
              >
                {port.id}
              </Text>

              <div>Request Client</div>
              <div
                css={css`
                  color: ${theme.colors.gray500};
                `}
              >
                {port.request_client}
              </div>

              {!!port.request_asap_port && (
                <>
                  <div>ASAP Port</div>
                  <FormRow
                    css={css`
                      margin-bottom: 0;
                    `}
                  >
                    <CheckboxField
                      {...getFieldProps('asapPort')}
                      name="asapPort"
                      label={
                        <Text
                          css={css`
                            margin-bottom: 0;
                            color: ${theme.colors.gray500};
                          `}
                        >
                          Request ASAP Port
                        </Text>
                      }
                      disabled
                    />
                  </FormRow>
                </>
              )}

              <div
                css={css`
                  color: ${missingRequestedPort && !portingInfoEdit
                    ? theme.colors.errorPressed
                    : null};
                `}
              >
                Requested Port Date
              </div>
              <div
                css={css`
                  color: ${theme.colors.gray500};
                `}
              >
                {portingInfoEdit && (
                  <FormRow>
                    <DatePickerField
                      {...getFieldProps('requestedPortDate')}
                      name="requestedPortDate"
                      label="Port Date"
                    />
                  </FormRow>
                )}
                {portingRequest?.requested_firm_order_commitment_date &&
                  !portingInfoEdit &&
                  getRequestedPortDate()}
              </div>

              {port.porting_requests?.[0]?.firm_order_commitment_date && (
                <>
                  <Text
                    css={css`
                      margin-bottom: ${theme.spacing(0)};
                    `}
                  >
                    Actual FOC Date
                  </Text>
                  <Text
                    css={css`
                      color: ${theme.colors.gray500};
                      margin-bottom: ${theme.spacing(0)};
                    `}
                  >
                    {port.porting_requests?.[0]?.firm_order_commitment_date &&
                      !portingInfoEdit &&
                      getFormattedDate(
                        port.porting_requests?.[0]?.firm_order_commitment_date
                      )}
                  </Text>
                </>
              )}

              <div
                css={css`
                  color: ${missingPortingCompany && !portingInfoEdit
                    ? theme.colors.errorPressed
                    : null};
                `}
              >
                Porting Company
              </div>
              {portingInfoEdit ? (
                <DropdownField
                  {...providerDropdownProps}
                  label="Provider"
                  name="provider"
                  css={css`
                    width: 200px;
                  `}
                >
                  <DropdownField.Option value="1">{Provider.get(1)}</DropdownField.Option>
                  <DropdownField.Option value="2">{Provider.get(2)}</DropdownField.Option>
                </DropdownField>
              ) : (
                <div
                  css={css`
                    color: ${theme.colors.gray500};
                  `}
                >
                  {port.provider !== 0 &&
                    Provider.has(port.provider) &&
                    Provider.get(port.provider)}
                </div>
              )}

              <div>Porting Order URL</div>
              <div
                css={css`
                  color: ${theme.colors.gray500};
                `}
              >
                <a href={portOrderUrl} target="_blank" style={{ color: '#37CFF1' }}>
                  {portOrderUrl}
                </a>
              </div>
              <>
                <Text
                  css={css`
                    margin-bottom: ${portingInfoEdit ? '12px' : '0px'};
                  `}
                >
                  Port Type
                </Text>
                <Text
                  css={css`
                    color: ${theme.colors.gray500};
                    margin-bottom: ${portingInfoEdit ? '12px' : '0px'};
                  `}
                >
                  {port?.port_type && getTypeOfPortingRequest()}
                </Text>
              </>
              {portingInfoEdit && (
                <div
                  css={css`
                    width: 200px;
                    margin: 10px auto;
                  `}
                >
                  <PrimaryButton
                    className={portingCardStyles.savePortCont}
                    color="blue"
                    size="large"
                    onClick={() => {
                      if (
                        !!portingRequest?.requested_firm_order_commitment_date &&
                        (isHoliday(
                          portingRequest?.requested_firm_order_commitment_date
                        ) ||
                          isWeekend(portingRequest?.requested_firm_order_commitment_date))
                      ) {
                        setPortingInfoError(
                          'Date cannot be a weekend or a major holiday.'
                        );
                      } else {
                        setPortingInfoError('');
                        setPortingProviderError('');
                        setPortingInfoEdit(true);
                        updatePortProvider();
                      }
                    }}
                  >
                    Save
                  </PrimaryButton>
                  {portingInfoError && (
                    <div
                      css={css`
                        margin-top: 10px;
                        color: red;
                      `}
                    >
                      {portingInfoError}
                    </div>
                  )}
                  {!!portingProviderError && (
                    <Text
                      css={css`
                        margin-top: 10px;
                      `}
                      color="error"
                    >
                      {portingProviderError}
                    </Text>
                  )}
                </div>
              )}
            </div>

            <div className={portingCardStyles.portingInfoContainer}>
              <div
                css={css`
                  font-weight: bold;
                  font-size: 16px;
                `}
              >
                Phone Numbers
              </div>
              {!portingRequest.port_order_number && (
                <div>
                  <EditIcon
                    css={css`
                      color: ${!phoneNumbersEdit && theme.colors.weaveBlue};
                    `}
                    onClick={() => {
                      handleDrawerToggle('phone', phoneNumbersEdit);
                      handleModalOpening();
                    }}
                  />
                </div>
              )}
            </div>
            {phoneNumberError?.map((data) => (
              <Text color="error" key={data.id}>
                {data.reason}
              </Text>
            ))}
            <div className={portingCardStyles.dids}>
              <div
                css={css`
                  display: flex;
                  margin-top: 30px;
                `}
              >
                <div
                  css={css`
                    margin-bottom: ${theme.spacing(2.5)};
                    margin-right: ${theme.spacing(phoneNumbersEdit ? 3.2 : 9.2)};
                  `}
                >
                  DID(s)
                </div>
                <div>{voicePhoneNumbersList}</div>
              </div>
              <div
                css={css`
                  display: flex;
                  margin-top: ${theme.spacing(2)};
                `}
              >
                <div
                  css={css`
                    flex: 0 0 auto;
                    margin-bottom: 10px;
                    ${phoneNumbersEdit ? 'margin-right: 0px;' : 'margin-right: 47px;'};
                  `}
                >
                  Fax DID(s)
                </div>
                <div>{faxPhoneNumbersList}</div>
              </div>
              <Text
                css={css`
                  text-align: center;
                `}
                color="error"
              >
                {duplicateError}
              </Text>
              {duplicateNumberError && (
                <Text color="error">Please remove the duplicate Number </Text>
              )}
              {phoneNumbersEdit && (
                <div className={portingCardStyles.saveOfficeCont}>
                  <PrimaryButton
                    className={portingCardStyles.saveOffice}
                    color="blue"
                    size="large"
                    onClick={handlePhoneNumberUpdate}
                    disabled={setDisableProperty()}
                  >
                    Save
                  </PrimaryButton>
                </div>
              )}
            </div>

            <PortingCardOfficeInfo
              port={port}
              officeInfoEdit={officeInfoEdit}
              setOfficeInfoEdit={setOfficeInfoEdit}
              handleDrawerToggle={handleDrawerToggle}
              setPortingDetails={setPortingDetails}
              updatePortingDetails={updatePortingDetails}
              officeInformationError={officeInformationError}
            />

            <PortingCardAttachments
              port={port}
              canEditAttachments={canEditAttachments}
              setCanEditAttachments={setCanEditAttachments}
              handleDrawerToggle={handleDrawerToggle}
              updatePortingDetails={updatePortingDetails}
              documentError={documentError}
            />

            {!portingRequest.port_order_number && (
              <div className={portingCardStyles.nextStepContainer}>
                <div className={portingCardStyles.title}>Next Steps</div>
              </div>
            )}

            {!portingRequest.port_order_number && (
              <div className={portingCardStyles.whatisnext}>
                <div>1. Review port information</div>
                <div>2. Submit port request</div>
              </div>
            )}

            <div className={portingCardStyles.errorText}>{error}</div>
            {portingValidationData?.length > 0 && (
              <Text color="error"> Please fix the above validation errors</Text>
            )}
            <div className={portingCardStyles.buttonContainers}>
              {!portingRequest.port_order_number && (
                <>
                  <div className={portingCardStyles.portingCardButtonStyleContainer}>
                    <div className={portingCardStyles.portingCardButtonStyle}>
                      <PrimaryButton
                        className={portingCardStyles.submitButtonStyle}
                        disabled={submitButtonDisplay}
                        color="blue"
                        size="large"
                        onClick={submit}
                        css={css`
                          width: 250px;
                        `}
                      >
                        Submit
                      </PrimaryButton>
                    </div>
                  </div>
                </>
              )}
              {showSendToSaleForceButton() && (
                <div className={portingCardStyles.portingCardButtonStyleContainer}>
                  <div className={portingCardStyles.portingCardButtonStyle}>
                    <PrimaryButton
                      className={portingCardStyles.submitButtonStyle}
                      disabled={portingInfoEdit || officeInfoEdit || phoneNumbersEdit}
                      color="blue"
                      size="large"
                      onClick={handleSendToSalesforce}
                    >
                      Send to Salesforce
                    </PrimaryButton>
                  </div>
                </div>
              )}
              <div className={portingCardStyles.buttonContainersDownload}>
                <div
                  className={portingCardStyles.downloadLoaButton}
                  onClick={handleClonePortingData}
                >
                  Clone
                </div>
              </div>
            </div>
          </div>
        </div>

        <MessageDialogue
          header={messagePortModal.header}
          body={messagePortModal.body}
          actionLabel={messagePortModal.actionLabel}
          messageModalProps={messageModalProps}
          closeMessageModal={messagePortModal.onClose}
          onActionLabelClick={messagePortModal.onActionLabelClick}
        />

        <Modal
          onClose={noop}
          show={
            downloadLOALoading ||
            downloadBillLoading ||
            submitPortingLoading ||
            showLoading ||
            false
          }
          className={portingCardStyles.divWidth}
        >
          <div>
            <div className={portingCardStyles.loading}>
              <Loader size="large" />
            </div>
            <div className={portingCardStyles.modalStyle}>
              {downloadLOALoading || downloadBillLoading
                ? 'Downloading...'
                : 'Submitting...'}
            </div>
          </div>
        </Modal>

        <Modal
          onClose={() => resetPortabilityCheck()}
          css={css`
            padding: ${theme.spacing(2)};
          `}
          show={splitPortingRequest !== -1}
          className={portingCardStyles.divWidth}
        >
          <div>
            <div className={portingCardStyles.lnpText}>
              Due to the complexities of porting, this port request needs to be split.
            </div>

            <div className={portingCardStyles.lnpText}>
              It will be split into {splitPortingRequest} porting requests.
            </div>
            <div className={portingCardStyles.loading}>
              <SecondaryButton
                className={portingCardStyles.lnpButton}
                color="gray"
                onClick={() => resetPortabilityCheck()}
              >
                Cancel
              </SecondaryButton>

              <PrimaryButton
                className={portingCardStyles.lnpButton}
                color="blue"
                onClick={() => submitSplitPortingRequests(port)}
              >
                Split & Submit All
              </PrimaryButton>
            </div>
          </div>
        </Modal>

        <Modal
          onClose={() => {
            resetPortabilityCheck();
            back();
          }}
          css={css`
            padding: ${theme.spacing(2)};
          `}
          show={splitPortingRequestsResults.length > 0}
          className={portingCardStyles.divWidth}
        >
          <div>
            <div className={portingCardStyles.portingResultsTitle}>Porting Results</div>
            {splitPortingRequestsResults.map((result, index) => {
              if (result?.status?.has_error) {
                return (
                  <div
                    className={`${portingCardStyles.splitError} ${
                      index % 2 === 1 ? portingCardStyles.gray : portingCardStyles.white
                    }`}
                    key={index}
                  >
                    <div
                      css={css`
                        margin-right: 20px;
                        color: red;
                      `}
                    >
                      Failed
                    </div>
                    <div
                      className={portingCardStyles.uploadLoaButtonSplitFail}
                      onClick={() => {
                        back();
                        resetPortabilityCheck();
                        setPortingDetails(result?.port);
                      }}
                    >
                      View Port Order
                    </div>
                    <ul>
                      {result?.status?.errors?.map((error, index) => (
                        <li key={index}>{error.description}</li>
                      ))}
                    </ul>
                  </div>
                );
              } else {
                return (
                  <div
                    className={`${portingCardStyles.splitSuccess} ${
                      index % 2 === 1 ? portingCardStyles.gray : portingCardStyles.white
                    }`}
                  >
                    <div
                      css={css`
                        margin-right: 20px;
                      `}
                    >
                      Success
                    </div>
                    <div
                      className={portingCardStyles.uploadLoaButtonSplit}
                      onClick={() => {
                        back();
                        resetPortabilityCheck();
                        setPortingDetails(result?.port);
                      }}
                    >
                      View Port Order
                    </div>

                    {port.provider !== ProviderTelnyx &&
                      Provider.get(ProviderTelnyx) === 'Telnyx' && (
                        <div
                          className={portingCardStyles.uploadLoaButtonSplit}
                          onClick={() => handleUploadLoaClick(result?.status?.order_id)}
                        >
                          Upload LOA
                        </div>
                      )}
                  </div>
                );
              }
            })}

            <PrimaryButton
              className={portingCardStyles.gotItButton}
              color="blue"
              size="large"
              onClick={() => {
                resetPortabilityCheck();
                back();
              }}
            >
              Got it
            </PrimaryButton>
          </div>
        </Modal>

        <Modal
          css={css`
            width: 450px;
            text-align: center;
            padding: ${theme.spacing(2)};
          `}
          show={!!portingRequestToCancel || deletePortingRequestLoading}
          onClose={() => setPortingRequestToCancel(undefined)}
        >
          {!deletePortingRequestLoading ? (
            <div>
              <h1
                css={css`
                  text-align: center;
                  margin-bottom: 16px;
                `}
              >
                Are you sure you want to remove the following phone number from this port
                request?
              </h1>
              {portingRequestToCancel && (
                <Text>{portingRequestToCancel?.phone_number}</Text>
              )}
              <PrimaryButton
                onClick={() => handleDeletePortingRequest(portingRequestToCancel?.id)}
              >
                Confirm
              </PrimaryButton>
            </div>
          ) : (
            <div
              css={css`
                padding: 100px;
              `}
            >
              <Loader />
            </div>
          )}
        </Modal>

        <Modal
          css={css`
            padding: ${theme.spacing(2)};
          `}
          show={showCancelPortModal}
          onClose={() => setShowCancelPortModal(false)}
        >
          {!showCancelLoading ? (
            <div>
              <h1
                css={css`
                  text-align: center;
                  margin-bottom: 16px;
                `}
              >
                Are you sure you want to cancel?
              </h1>
              <ButtonBar>
                <PrimaryButton onClick={() => cancelPort()}>Yes, cancel.</PrimaryButton>

                <SecondaryButton onClick={() => setShowCancelPortModal(false)}>
                  No, do not cancel.
                </SecondaryButton>
              </ButtonBar>
            </div>
          ) : (
            <div
              css={css`
                padding: 20px;
              `}
            >
              <div className={portingCardStyles.loading}>
                <Loader size="large" />
              </div>
              <Text
                css={css`
                  font-weight: bold;
                  font-size: ${theme.fontSize(1.5)}
                  text-align: center;
                  margin-top: 20px;
                `}
              >
                Cancelling...
              </Text>
            </div>
          )}
        </Modal>

        <Modal
          onClose={() => {
            clearSubmitPortingDetails();
            if (submitPortingDetails && !submitPortingDetails?.has_error) {
              back();
            }
          }}
          show={!!submitPortingDetails || false}
          css={css`
            padding: ${theme.spacing(2)};
          `}
          className={portingCardStyles.divWidth}
        >
          <div>
            {submitPortingDetails && submitPortingDetails?.has_error && (
              <div>
                <div className={portingCardStyles.fixErrorReason}>
                  Porting request failed for the following reasons:
                </div>
                {submitPortingDetails.errors.map((error, index) => (
                  <div key={index}>{error.description}</div>
                ))}
                <div className={portingCardStyles.fixErrorTitle}>
                  Fix these errors and resubmit.
                </div>
                <PrimaryButton
                  className={portingCardStyles.errorOkayButton}
                  color="blue"
                  size="large"
                  onClick={() => clearSubmitPortingDetails()}
                >
                  Okay
                </PrimaryButton>
              </div>
            )}
            {submitPortingDetails && !submitPortingDetails?.has_error && (
              <div>
                <div className={portingCardStyles.loading}>
                  <SuccessSVG />
                </div>
                <div className={portingCardStyles.successSubmit}>
                  Port Successfully Submitted!
                </div>
                {port.provider !== ProviderTelnyx &&
                  Provider.get(ProviderTelnyx) === 'Telnyx' && (
                    <div
                      className={portingCardStyles.uploadLoaButton}
                      onClick={() => handleUploadLoaClick(submitPortingDetails?.order_id)}
                    >
                      Upload LOA
                    </div>
                  )}
                <PrimaryButton
                  className={portingCardStyles.gotItButton}
                  color="blue"
                  size="large"
                  onClick={() => {
                    clearSubmitPortingDetails();
                    back();
                  }}
                >
                  Got it
                </PrimaryButton>
              </div>
            )}
          </div>
        </Modal>
        <Modal
          css={css`
            padding: ${theme.spacing(2)};
          `}
          show={showPhoneModal}
          onClose={() => setShowPhoneModal(false)}
        >
          {showPhoneModal ? (
            <div>
              <h1
                css={css`
                  text-align: center;
                  margin-bottom: 16px;
                `}
              >
                Are you sure you want to discard the changes?
              </h1>
              <ButtonBar>
                <PrimaryButton onClick={() => cancelPhoneChanges()}>Yes</PrimaryButton>

                <SecondaryButton
                  onClick={() => {
                    setShowPhoneModal(false);
                    setPhoneNumbersEdit(true);
                  }}
                >
                  No
                </SecondaryButton>
              </ButtonBar>
            </div>
          ) : (
            <div
              css={css`
                padding: ${theme.spacing(2)};
              `}
            >
              <div className={portingCardStyles.loading}>
                <Loader size="large" />
              </div>
              <Text
                css={css`
                  font-weight: ${theme.font.weight.bold};
                  font-size: ${theme.fontSize(24)};
                  text-align: center;
                  margin-top: ${theme.spacing(2)};
                `}
              >
                Cancelling...
              </Text>
            </div>
          )}
        </Modal>
        {visible && minDateFoc && maxDateFoc && (
          <SupRequestModal
            minDateFoc={minDateFoc}
            maxDateFoc={maxDateFoc}
            cancelConfirmationModalProps={cancelConfirmationModalProps}
            closeCancelModal={closeCancelModal}
            minDate={minDate}
            focDates={focDates}
            portingDataId={port.id}
            removedDateInformation={removedDateInformation}
            port={port}
            adminEmail={username}
            updatePortDetialsByFoc={updatePortDetialsByFoc}
          />
        )}
      </div>
    </Page>
  );
};
