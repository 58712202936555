import { JwtPayload } from './auth.types';
import jwtDecode from 'jwt-decode';

export const setupPendo = (token: string) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  const user: JwtPayload = jwtDecode(token);
  // Credentials might be different depending on how the user signed in. If they
  // signed in using their google account then the visitorId should be set using
  // the email property in the auth0 object else just use the username property
  // from credentials.
  const visitorId = user.user_id;
  if (window.hasOwnProperty('pendo')) {
    window['pendo'].initialize({
      apiKey: '47498371-c902-413f-490b-6d4d28ab0f23',
      visitor: {
        id: visitorId,
      },
      account: {
        id: 'weave',
      },
      excludeAllText: true,
    });
  }
};
