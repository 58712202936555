import React from 'react';
import moment from 'moment';
import { css } from 'emotion';
import { SearchIcon, Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';

import { dateTimeFormat } from '../../helpers/utils';
import { InteractiveCellText } from './interactive-cell-text/interactive-cell-text.component';
import { AutoMsgQueueControlContainer } from './control-panels/auto-msg-queue-control/auto-msg-queue-control.container';
import { AutoMsgQueueChart } from './charts/auto-msg-queue-chart.component';
import { formatCentsToCurrency } from '../../helpers/currency';
import { friendlyStatusMap } from '../shared/charts/map-friendly-statuses';
import { SmsHistoryItem } from './history-list/sms-history-item/sms-history.item';
import { SyncappWriteback } from '../sync-app/sync-app.types';
interface RowConfiguration {
  id: string;
  Header: string | JSX.Element;
  accessor: string | (() => string);
  isDefaultSort?: boolean;
  style?: any;
  Cell?: any;
  sortable: boolean;
}

interface TableConfiguration {
  title: string;
  subTitle?: string;
  options: {
    searchFields: string[];
    showDateRange: boolean;
    hideSort?: boolean;
  };
  columns: RowConfiguration[];
  controlPanel?: React.Component;
  chart?: React.Component;
}

const actionableText = css`
  color: ${theme.colors.weaveBlue} !important;
  cursor: pointer;
  margin: auto 0;
`;

const syncappWritebackMessageColumn = css`
  word-break: break-all;
  white-space: normal;
  padding-right: 10px;
`;

const Searchable = (title) => {
  return (
    <span>
      {' '}
      <SearchIcon /> {title}{' '}
    </span>
  );
};

export const formatDate = (seconds: string | number): string => {
  if (!seconds) {
    return '';
  }

  return moment(seconds, ['X', 'YYYY-MM-DDTHH:mm:ssZ']).format(dateTimeFormat);
};

const tableConfigurations = {
  'auto-msg-queue': {
    title: 'Auto Message Queue',
    controlPanel: <AutoMsgQueueControlContainer />,
    chart: <AutoMsgQueueChart />,
    options: {
      showDateRange: false,
      searchFields: [],
    },
    columns: [
      {
        Header: 'Notifiable Type',
        accessor: 'notifiable_type',
        width: 110,
      },
      {
        Header: 'Method',
        accessor: 'delivery_method',
        width: 80,
      },
      {
        Header: 'Status',
        accessor: (data: any) => friendlyStatusMap[data.status] || data.status,
        width: 150,
      },
      {
        id: 'deliver_at',
        Header: 'Deliver At',
        accessor: (data: any) => formatDate(data.deliver_at),
      },
      {
        id: 'delivered_at',
        Header: 'Delivered At',
        accessor: (data: any) => formatDate(data.delivered_at),
      },
      {
        id: 'notifiable_id',
        Header: 'Type ID',
        width: 80,
        accessor: (data: any) => `${data?.notifiable_id?.substring(0, 4)}...`,
      },
      {
        id: 'notification_setting_id',
        Header: 'Reminder ID',
        width: 100,
        accessor: (data: any) => (
          <Text className={actionableText}>
            {data?.notification_setting_id?.substring(0, 4)}...
          </Text>
        ),
      },
      {
        id: 'patient_id',
        Header: 'Person ID',
        width: 80,
        accessor: (data: any) => (
          <Text className={actionableText}>{data?.patient_id?.substring(0, 4)}...</Text>
        ),
      },
      {
        id: 'id',
        Header: 'ID',
        width: 80,
        accessor: (data: any) => `${data?.id?.substring(0, 4)}...`,
      },
    ],
  },
  patients: {
    title: 'Patients',
    options: {
      searchFields: ['Patient ID', 'Household ID'],
      showDateRange: true,
    },
    columns: [
      {
        Header: 'Patient ID',
        accessor: 'patient_id',
      },
      {
        Header: 'Household ID',
        accessor: 'household_id',
      },
      {
        id: 'created_at',
        Header: 'Created At',
        accessor: (data: any) => formatDate(data.created_at),
        isDefaultSort: true,
      },
      {
        id: 'modified_at',
        Header: 'Modified At',
        accessor: (data: any) => formatDate(data.modified_at),
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
  },
  'sms-history': {
    title: 'SMS History',
    options: {
      searchFields: ['Body'],
      showDateRange: true,
      hideSort: true,
    },
    columns: [
      {
        id: 'id',
        Cell: (data) => {
          const { original: smsData } = data;
          return <SmsHistoryItem smsData={smsData} />;
        },
      },
    ],
  },
  'appointment-history': {
    title: 'Appointment History',
    options: {
      showDateRange: false,
      searchFields: [],
    },
    columns: [
      {
        Header: 'Appointment ID',
        accessor: 'appointment_id',
      },
      {
        id: 'apt_datetime',
        Header: 'Appointment Date',
        accessor: (data: any) => formatDate(data.apt_datetime),
      },
      {
        id: 'modified_at',
        Header: 'Modified At',
        accessor: (data: any) => formatDate(data.modified_at),
      },
      {
        Header: 'Appointment Status',
        accessor: 'apt_status',
      },
      {
        Header: 'Patient ID',
        accessor: (data: any) => {
          return data.Patient ? data.Patient.patient_id : '';
        },
      },
    ],
  },
  'email-history': {
    title: 'Email History',
    options: {
      searchFields: [
        'Patient ID',
        'Provider',
        'Provider Message ID',
        'From Email',
        'To Email',
        'Subject',
        'Type',
        'Status',
      ],
      showDateRange: true,
    },
    columns: [
      {
        Header: 'Patient ID',
        accessor: 'PatientID',
      },
      {
        Header: 'Provider',
        accessor: 'Provider',
      },
      {
        Header: 'Provider Message ID',
        accessor: 'ProviderMessageID',
      },
      {
        Header: 'From Email',
        accessor: 'FromEmail',
        style: { whiteSpace: 'unset' },
      },
      {
        Header: 'To Email',
        accessor: 'ToEmail',
        style: { whiteSpace: 'unset' },
      },
      {
        Header: 'Subject',
        accessor: 'Subject',
        style: { whiteSpace: 'unset' },
      },
      {
        Header: 'Type',
        accessor: 'Type',
      },
      {
        Header: 'Status',
        accessor: 'Status',
      },
      {
        id: 'CreatedAt',
        Header: 'Created At',
        accessor: (data: any) => formatDate(data.CreatedAt),
        isDefaultSort: true,
      },
      {
        id: 'ModifiedAt',
        Header: 'Modified At',
        accessor: (data: any) => formatDate(data.ModifiedAt),
      },
    ],
  },
  logs: {
    title: 'Logs',
    options: {
      searchFields: ['Set', 'Key'],
      showDateRange: true,
    },
    columns: [
      {
        Header: 'Set',
        accessor: 'Set',
      },
      {
        Header: 'Key',
        accessor: 'Key',
      },
      {
        Header: 'Value',
        accessor: 'Value',
      },
      {
        id: 'created_at',
        Header: 'Created At',
        accessor: (data: any) => formatDate(data.created_at),
        isDefaultSort: true,
      },
    ],
  },
  'recall-events': {
    title: 'Recall Events',
    options: {
      searchFields: [],
      showDateRange: true,
    },
    columns: [
      {
        Header: 'Type',
        accessor: 'EventType',
      },
      {
        Header: 'Description',
        accessor: 'Description',
        style: { whiteSpace: 'unset' },
      },
      {
        id: 'EventTime',
        Header: 'Event Time',
        accessor: (data: any) =>
          data.EventTime ? formatDate(data.EventTime.seconds) : '',
      },
      {
        Header: 'Event Id',
        accessor: 'EventID',
      },
      {
        Header: 'Person Id',
        accessor: 'PersonID',
      },
    ],
  },
  'order-events': {
    title: 'Order Events',
    options: {
      searchFields: [],
      showDateRange: true,
    },
    columns: [
      {
        Header: 'Type',
        accessor: 'EventType',
      },
      {
        Header: 'Sub Type',
        accessor: 'EventSubType',
      },
      {
        id: 'EventTime',
        Header: 'Event Time',
        accessor: (data: any) =>
          data.EventTime ? formatDate(data.EventTime.seconds) : '',
      },
      {
        Header: 'Status',
        accessor: 'Description',
      },
      {
        Header: 'Event Id',
        accessor: 'EventID',
      },
      {
        Header: 'Person Id',
        accessor: 'PersonID',
      },
    ],
  },
  'reviews-list': {
    title: 'Reviews List',
    options: {
      searchFields: [],
      showDateRange: false,
    },
    columns: [
      {
        Header: 'Rating',
        accessor: 'Rating',
      },
      {
        Header: 'Description',
        accessor: 'Review',
        style: { whiteSpace: 'unset' },
      },
      {
        Header: 'Author',
        accessor: 'Author',
      },
      {
        Header: 'Review Site',
        accessor: 'Site',
      },
      {
        id: 'DatePublished',
        Header: 'Date Published',
        accessor: (data: any) =>
          data.DatePublished ? formatDate(data.DatePublished.seconds) : '',
      },
      {
        id: 'DateRead',
        Header: 'Date Read',
        accessor: (data: any) => (data.DateRead ? formatDate(data.DateRead.seconds) : ''),
      },
    ],
  },
  'appointment-confirmation-writebacks': {
    title: 'Appointment Confirmation Writebacks',
    options: {
      showDateRange: false,
      searchFields: [],
    },
    subTitle:
      "Note: This data shows writeback attempts to a customer's pms via the sync-app for appointment confirmations from the data-service database",
    columns: [
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Writeback Status',
        accessor: 'writeback_status',
      },
      {
        Header: 'Description',
        accessor: 'writeback_status_description',
        style: { padding: 'unset' },
        Cell: (props) => <InteractiveCellText text={props.value} />,
      },
      {
        id: 'apt_datetime',
        Header: 'Appt. Date',
        accessor: (data: any) => formatDate(data.apt_datetime),
      },
      {
        id: 'writeback_modified_at',
        Header: 'Modified At',
        accessor: (data: any) => formatDate(data.writeback_modified_at),
      },
      {
        Header: 'Source Type',
        accessor: 'source_type',
      },
      {
        Header: 'Source Id',
        accessor: 'source_id',
      },
      {
        Header: 'Appt Id',
        accessor: 'apt_id',
      },
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Patient Id',
        accessor: 'patient_id',
      },
    ],
  },
  'sync-app-writebacks': {
    title: 'Sync-App Writebacks',
    options: {
      showDateRange: false,
      searchFields: [],
    },
    subTitle:
      "Note: This data shows writeback attempts to a customer's pms via the sync-app from the sync-app-writebacks database",
    columns: [
      {
        id: 'sourceId',
        Header: 'SourceID',
        accessor: (data: SyncappWriteback) => data.sourceId,
        width: 350,
      },
      {
        id: 'RPCMethod',
        Header: 'RPCMethod',
        accessor: (data: SyncappWriteback) => data.rpcMethod,
        width: 200,
      },
      {
        id: 'objectId',
        Header: 'ObjectID',
        accessor: (data: SyncappWriteback) => data.objectId ?? '',
        width: 350,
      },
      {
        id: 'outcome',
        Header: 'Outcome',
        accessor: (data: SyncappWriteback) => data.outcome,
        width: 125,
      },
      {
        id: 'message',
        Header: 'Message',
        accessor: (data: SyncappWriteback) => (
          <Text className={syncappWritebackMessageColumn}>{data.outcomeMessage}</Text>
        ),
      },
      {
        id: 'createdAt',
        Header: 'CreatedAt',
        accessor: (data: SyncappWriteback) =>
          formatDate(data.createdAt),
        width: 175,
      },
    ],
  },
  invoices: {
    title: 'Invoices',
    options: {
      searchFields: [
        'Person Name',
        'Patient Id',
        'Peson Id',
        'Billed Amount',
        'Paid Amount',
        'Weave Payment ID',
        'Invoice ID',
      ],
      showDateRange: true,
    },
    subTitle:
      'Note: This data shows created payment requests. It does not reflect whether the request was sent.',
    columns: [
      {
        Header: 'Person Name',
        accessor: 'personName',
        sortable: false,
      },
      {
        Header: 'Patient Id',
        accessor: 'pmId',
        sortable: false,
      },
      {
        Header: 'Person Mobile Number',
        accessor: 'personMobileNumber',
        sortable: false,
      },
      {
        Header: 'Person ID',
        accessor: 'personId',
      },
      {
        id: 'billedAmount',
        Header: 'Billed Amount',
        accessor: (data: any) =>
          data.billedAmount ? formatCentsToCurrency(data.billedAmount) : '',
      },
      {
        id: 'billedAt',
        Header: 'Billed Date',
        accessor: (data: any) => (data.billedAt ? formatDate(data.billedAt) : ''),
        isDefaultSort: true,
      },
      {
        id: 'paidAmount',
        Header: 'Paid Amount',
        accessor: (data: any) =>
          data.payment && data.payment.paidAmount
            ? formatCentsToCurrency(data.payment.paidAmount)
            : '',
      },
      {
        id: 'paidAt',
        Header: 'Paid Date',
        accessor: (data: any) =>
          data.payment && data.payment.paidAt ? formatDate(data.payment.paidAt) : '',
      },
      {
        id: 'hasAttachment',
        Header: 'Has Attachment',
        accessor: (data: any) => (data.hasAttachment ? 'Yes' : 'No'),
      },
      {
        id: 'paymentId',
        Header: 'Weave Payment ID',
        accessor: (data: any) => (data.payment ? data.payment.paymentId : ''),
      },
      {
        Header: 'Invoice ID',
        accessor: 'id',
      },
      {
        id: 'isActive',
        Header: 'Active',
        accessor: (data: any) => (data.isActive ? 'Active' : 'Inactive'),
        sortable: false,
      },
      {
        id: 'link',
        Header: 'Payment Link',
        accessor: (data: any) =>
          data.links ? (
            <a href={data.links.payment} target="_blank" rel="noreferrer">
              Link
            </a>
          ) : (
            ''
          ),
        sortable: false,
      },
    ],
  },
  'client-locations': {
    title: 'Client Locations',
    options: {
      searchFields: [],
      showDateRange: false,
    },
    columns: [
      {
        Header: 'Client Location ID',
        accessor: 'clientLocationID',
      },
      {
        Header: 'External ID',
        accessor: 'externalID',
      },
      {
        id: 'Name',
        Header: 'name',
        accessor: (data: any) => {
          const shortName = data.shortName ? `(${data.shortName})` : '';
          return `${data.name} ${shortName}`;
        },
      },
      {
        id: 'Address',
        Header: 'Address',
        accessor: (data: any) => {
          return `${data.address ? data.address : ''}
${data.address2 ? data.address2 : ''}
${data.city ? data.city + ',' : ''} ${data.state ? data.state : ''}    ${data.postCode ? data.postCode : ''
            }`;
        },
      },
      {
        Header: 'External Active',
        accessor: 'externalActive',
      },
      {
        Header: 'Source ID',
        accessor: 'sourceID',
      },
      {
        id: 'createdAt',
        Header: 'Created At',
        accessor: (data: any) => (data.createdAt ? formatDate(data.createdAt) : ''),
      },
      {
        id: 'modifiedAt',
        Header: 'Modified At',
        accessor: (data: any) => (data.modifiedAt ? formatDate(data.modifiedAt) : ''),
      },
    ],
  },
};

const getTableConfig: (pageTitle: string) => TableConfiguration = (pageTitle: string) => {
  const table: TableConfiguration = tableConfigurations[pageTitle];
  const searchFields = table.options.searchFields || [];
  table.columns = table.columns.map((item: RowConfiguration) => {
    return {
      ...item,
      id:
        item.id ||
        (typeof item.accessor === 'string'
          ? item.accessor
          : typeof item.Header === 'string'
            ? item.Header
            : ''),
      Header: searchFields.includes(String(item.Header))
        ? Searchable(item.Header)
        : item.Header,
      filterMethod: (filter, row) =>
        row[filter.id].startsWith(filter.value) || row[filter.id].endsWith(filter.value),
    };
  });

  return table;
};

export default getTableConfig;
