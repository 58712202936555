import { Heading, Text, TextLink } from '@weave/design-system';
import React from 'react';
import { formatNumber, formatSubscriberInvoiceDateForPrint } from '../../billing.helpers';
import { GetInvoiceDetailOrPdfResponse } from '../../billing.types';
import {
  billingContact,
  disclaimer,
  noMargin,
  page,
  pageBody,
  pdfErrorMessage,
  tableStyles,
  textAlignRight,
  textColor,
  textGreeting,
  totalTextStyles,
} from './invoice-details-styles';

type Props = {
  invoiceDetails?: GetInvoiceDetailOrPdfResponse['invoice'];
};

export const InvoiceDetailsTemplateContent = ({ invoiceDetails }: Props) => {
  const paymentLink = `https://intacct.tpro3.com/payinvoice/406C4FD56A636169E0530200007FD461/${invoiceDetails?.invoice?.customerId}/${invoiceDetails?.invoice?.id}`;

  const currency = invoiceDetails?.invoice?.currency ?? 'USD';

  return (
    <div css={[page, pageBody]}>
      <div css={billingContact}>
        <Heading css={textColor}>
          Customer ID: {invoiceDetails?.invoice?.customerId}
        </Heading>
        <hr />
        <Text weight="bold" css={[textColor, noMargin]}>
          Bill To:
        </Text>
        <Text css={[textColor, noMargin]}>
          {invoiceDetails?.invoice?.billingContact?.companyName ?? ''}
        </Text>
        <Text css={[textColor, noMargin]}>
          {invoiceDetails?.invoice?.billingContact?.address1 ?? ''}
        </Text>
        <Text css={[textColor, noMargin]}>
          {invoiceDetails?.invoice?.billingContact?.address2 ?? ''}
        </Text>
        <Text css={[textColor, noMargin]}>
          {invoiceDetails?.invoice?.billingContact?.city ?? ''}
        </Text>
        <Text css={[textColor, noMargin]}>
          {invoiceDetails?.invoice?.billingContact?.state ?? ''}
        </Text>
        <Text css={[textColor, noMargin]}>
          {invoiceDetails?.invoice?.billingContact?.postalCode ?? ''}
        </Text>
      </div>
      <div css={disclaimer}>
        <Text weight="bold" color="error" css={pdfErrorMessage}>
          PLEASE DO NOT MAKE PAYMENT BY CHECK. AMOUNTS BELOW ARE STATED IN {currency}.
        </Text>
        <Text weight="bold" css={textColor}>
          Your total amount due is{' '}
          {formatNumber(+(invoiceDetails?.invoice?.amountDue ?? ''), currency)}
        </Text>
        <Text weight="bold" css={textColor}>
          If this amount exceeds zero dollars, please follow this link to update the
          payment information on file:
        </Text>
        <TextLink to={paymentLink} css={textColor}>
          {paymentLink}
        </TextLink>
      </div>
      <table css={tableStyles}>
        <thead>
          <tr>
            <th>AMOUNT PAID</th>
            <th>AMOUNT DUE</th>
            <th>PAYMENT TERMS</th>
            <th>DUE DATE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {invoiceDetails?.invoice?.amountPaid &&
                formatNumber(+invoiceDetails?.invoice.amountPaid, currency)}
            </td>
            <td>
              <b>
                {invoiceDetails?.invoice?.amountDue &&
                  formatNumber(+invoiceDetails?.invoice.amountDue, currency)}
              </b>
            </td>
            <td>Due on receipt</td>
            <td>
              {formatSubscriberInvoiceDateForPrint(invoiceDetails?.invoice?.dueDate)}
            </td>
          </tr>
        </tbody>
      </table>

      <table css={tableStyles}>
        <thead>
          <tr>
            <th>ITEM #</th>
            <th>PRODUCT</th>
            <th>DESCRIPTION</th>
            <th>QTY</th>
            <th>UNIT PRICE</th>
            <th>EXTENDED PRICE</th>
          </tr>
        </thead>
        <tbody>
          {invoiceDetails?.lineItems?.map((lineItem) => (
            <tr key={lineItem.id}>
              <td>{lineItem.id}</td>
              <td>{lineItem.code}</td>
              <td>{lineItem.description}</td>
              <td>{lineItem.quantity}</td>
              <td>{formatNumber(+(lineItem.unitPrice ?? 0), currency)}</td>
              <td>{formatNumber(+(lineItem.amount ?? 0), currency)}</td>
            </tr>
          ))}
          <tr className="billing--totals-row">
            <td colSpan={5} css={[totalTextStyles, textAlignRight]}>
              TOTAL
            </td>
            <td css={totalTextStyles}>
              {formatNumber(+(invoiceDetails?.invoice?.amount ?? ''), currency)}{' '}
              {currency}
            </td>
          </tr>
        </tbody>
      </table>
      <Text css={[textGreeting, textColor]}>
        FROM ALL OF US AT WEAVE, <b>THANK YOU</b>
      </Text>
    </div>
  );
};
