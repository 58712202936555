import { bnplApi, BnplStatus } from 'apis/payments';
import { DotStatus } from 'components/shared/atoms/Dot';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from 'redux/actions/location';
import { selectPaymentUrl } from 'redux/actions/merchant/merchant.action';

export const useBnplStatus = () => {
  const paymentsUrl = useSelector(selectPaymentUrl);
  const locationId = useSelector(selectCurrentLocationId);

  const { data, error, isLoading } = useQuery(
    ['bnpl', locationId, 'status'],
    () => bnplApi.merchantStatus(paymentsUrl),
    { enabled: !!paymentsUrl }
  );

  const { status, url } = data || {};

  const notSignedup = !!error;
  const sunbitActivated = status && status === BnplStatus.Activated;
  const onboardingDeclined = status && status === BnplStatus.Declined;
  const onboardingStarted = !notSignedup && !sunbitActivated;
  const showSignupLink = !sunbitActivated && !isLoading;
  const sunbitSignupLink = `https://app.getweave.com/admin/${locationId}/payments/buy-now-pay-later`;

  const sunbitDotStatus = sunbitActivated
    ? DotStatus.Active
    : onboardingStarted
    ? DotStatus.Processing
    : DotStatus.Inactive;

  return {
    status,
    url,
    error,
    isLoading,
    sunbitActivated,
    onboardingStarted,
    onboardingDeclined,
    showSignupLink,
    sunbitSignupLink,
    sunbitDotStatus,
  };
};
