import React from 'react';
import { CheckIcon, Modal } from '@weave/design-system';
import {
  modalContentStyles,
  modalHeaderStyle,
  sectionsIncludedStyles,
} from './intake-form-card.component';

type Props = {
  fullFormConfirmationModalProps;
  handleFullMudalState;
  isSoftwareOnlyCheck;
};
export const IntakeFormSendFullFormModalComponent = ({
  fullFormConfirmationModalProps,
  handleFullMudalState,
  isSoftwareOnlyCheck,
}: Props) => {
  return (
    <Modal {...fullFormConfirmationModalProps}>
      <Modal.Header textAlign="left" css={modalHeaderStyle}>
        Send Full Form
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            This form includes the sections listed below. Are you sure you want to send
            this form?
          </p>
          <p css={modalContentStyles}>Sections included:</p>
          <div>
            <div css={sectionsIncludedStyles}>
              <CheckIcon />
              <p>Business Information</p>
            </div>
            {!isSoftwareOnlyCheck && (
              <div css={sectionsIncludedStyles}>
                <CheckIcon />
                <p>Porting Information</p>
              </div>
            )}
            <div css={sectionsIncludedStyles}>
              <CheckIcon />
              <p>Software Setup</p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Actions
        primaryLabel="Yes, Send Form"
        onPrimaryClick={handleFullMudalState}
        secondaryLabel="Cancel"
        onSecondaryClick={fullFormConfirmationModalProps.onClose}
      ></Modal.Actions>
    </Modal>
  );
};
