import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { SalesforceAccountProductBundle } from '../customization-page-v2.types';
import { LocationFeatureV2 } from 'models/location-feature.model';

export const CustomizationFlagBundleFlagContext = React.createContext<
  LocationFeatureV2[]
>([]);

export const CustomizationFlagBundleFlagUpdaterContext = React.createContext<Dispatch<
  SetStateAction<LocationFeatureV2[]>
> | null>(null);

export const CustomizationFlagBundleFlagProvider = ({ children }) => {
  const [bundleCustomizationFlags, setBundleCustomizationFlags] = useState<
    LocationFeatureV2[]
  >([]);
  return (
    <CustomizationFlagBundleFlagUpdaterContext.Provider
      value={setBundleCustomizationFlags}
    >
      <CustomizationFlagBundleFlagContext.Provider value={bundleCustomizationFlags}>
        {children}
      </CustomizationFlagBundleFlagContext.Provider>
    </CustomizationFlagBundleFlagUpdaterContext.Provider>
  );
};

export const useCustomizationBundleFlag = () =>
  React.useContext(CustomizationFlagBundleFlagContext);

export const useCustomizationBundleFlagUpdater = () => {
  const setBundleCustomizationFlags = React.useContext(
    CustomizationFlagBundleFlagUpdaterContext
  );

  const updateBundleCustomizationFlags = React.useCallback(
    (bundleCustomizationFlag: LocationFeatureV2[]) => {
      if (setBundleCustomizationFlags)
        setBundleCustomizationFlags(bundleCustomizationFlag);
    },
    [setBundleCustomizationFlags]
  );

  return updateBundleCustomizationFlags;
};
