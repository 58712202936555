import { CustomAxios, getResponseData } from '../../../redux/axios';
import { HandoffMetric, HandoffSnapshot, SnapshotMetaData } from './handoff-hub.types';
import { mockPromise } from '../../../tools/mock-promise';
import {
  mockCreateSnapshotData,
  mockHandoffSnapShotResponse,
  mockUpdateHandoffSnapshot,
  mockUpdateSnapshotMetrics,
} from './handoff-snapshot.mocks';

const baseUrl = '/insys/onboarding/v1/handoff-hub/handoff';

export const getHandoffHubSnapshots = () => {
  return CustomAxios.get(baseUrl).then<HandoffSnapshot[]>(
    (resp) => resp.data?.handoffSnapshots ?? []
  );
  // return mockPromise<HandoffSnapshot[]>({ data: mockHandoffSnapShotResponse().data });
};

export const getHandoffHubSnapshotById = (id: string) => {
  return CustomAxios.get(`${baseUrl}/${id}`).then<HandoffSnapshot>(
    (resp) => resp.data?.handoffSnapshot
  );
};

export const createHandoffHubSnapShot = () => {
  return CustomAxios.post(baseUrl).then<{ id: string; locationId: string }>(
    getResponseData
  );
  // return mockPromise<any>({ data: mockCreateSnapshotData });
};

export const updateHandoffSnapshot = (snapshot: Partial<HandoffSnapshot>, id: string) => {
  // Remove createdAt and updatedAt fields from the data since it shouldn't be included in
  // the update data.
  const { createdAt, updatedAt, ...handoffData } = snapshot;

  return CustomAxios.put(`${baseUrl}/${id}`, { handoffSnapshot: handoffData }).then(
    getResponseData
  );
  // return mockPromise<any>({ data: mockUpdateHandoffSnapshot() });
};

export const submitHandoffHub = (
  request: { handoffSubmittedBy: string; locationId: string },
  id: string
) => CustomAxios.post(`${baseUrl}/${id}/submit`, request);

export const deleteHandoffHubSnapShot = (id: string) => {
  return CustomAxios.delete(`${baseUrl}/${id}`);
};

export const handoffHubApi = {
  getHandoffHubSnapshotById,
  getHandoffHubSnapshots,
  createHandoffHubSnapShot,
  updateHandoffSnapshot,
  deleteHandoffHubSnapShot,
  submitHandoffHub,
};
