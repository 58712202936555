import { useAlert } from '@weave/alert-system';
import { useState } from 'react';
import { portingSchemaApi } from '../../../apis/porting/porting.api';
import { Provider } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';

export const useDeletePortingData = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const alert = useAlert();

  const deletePortingData = async (portingDataId: string, onSuccess?: () => void) => {
    setIsDeleting(true);
    try {
      // first fetch the port order data
      const portOrderResp = await portingSchemaApi.getPortingDataById({
        portingDataId,
      });

      // then update the port order company name to bypass BE deletion restrictions
      await portingSchemaApi.updatePortingDataById({
        portingDataId,
        portingData: {
          ...portOrderResp.portingData,
          // the BE only allows deleting a port order if the company name is 'weave-testing'
          companyName: 'weave-testing',
          // It was mentioned that the provider needs to be set to 'PROVIDER_MOCK' to delete
          // the port order but need to confirm if this is actually the case.
          provider: Provider.PROVIDER_MOCK,
        },
      });

      // then delete the port order
      await portingSchemaApi.deletePortingDataById({
        id: portingDataId,
      });

      alert.success('Porting data deleted successfully.');
      onSuccess?.();
    } catch (err) {
      alert.error('Failed to delete porting data.');
    } finally {
      setIsDeleting(false);
    }
  };

  return {
    isDeleting,
    deletePortingData,
  };
};
