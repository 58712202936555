import { UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { selectCurrentLocationId } from '../../../redux/actions/location';
import { handoffHubApi } from './handoff-hub-snapshot.api';
import { useAlert } from '@weave/alert-system';
import { HandoffSnapshot } from './handoff-hub.types';
import { Store } from '../../../redux/store/store.model';

const queryKeys = {
  handoffHubSnapshots: (locationId: string) => [locationId, 'handoffHubSnapshots'],
  handoffHubSnapshot: (locationId: string, snapshotId: string) => [
    locationId,
    'handoffHubSnapshot',
    snapshotId,
  ],
};

export const useGetHandoffHubSnapshotsQuery = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const alerts = useAlert();
  return useQuery({
    queryKey: queryKeys.handoffHubSnapshots(locationId),
    queryFn: handoffHubApi.getHandoffHubSnapshots,
    retry: false,
    refetchOnMount: false,
    onError(err) {
      alerts.error('Error getting Handoff Hub notes');
      console.log('Get HandoffHub Snapshots Error', err);
    },
  });
};
export const useGetHandoffHubSnapshotByIdQuery = (snapshotId: string) => {
  const locationId = useSelector(selectCurrentLocationId);
  const alerts = useAlert();
  return useQuery({
    queryKey: queryKeys.handoffHubSnapshot(locationId, snapshotId),
    queryFn: () => handoffHubApi.getHandoffHubSnapshotById(snapshotId),
    retry: false,
    refetchOnMount: false,
    enabled: !!snapshotId && !!locationId,
    onError(err) {
      alerts.error('Error getting Handoff Hub note');
      console.log('Get HandoffHub Snapshot Error', err);
    },
  });
};

export const useDeleteHandoffHubSnapshotMutation = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const queryKey = queryKeys.handoffHubSnapshots(locationId);

  return useMutation({
    mutationFn: async (snapshotId: string) => {
      await handoffHubApi.deleteHandoffHubSnapShot(snapshotId);
      return snapshotId;
    },
    onSuccess(snapshotId) {
      alerts.success('Handoff Hub note deleted');
      queryClient.setQueryData<HandoffSnapshot[]>(queryKey, (list) =>
        (list ?? []).filter((s) => s.id !== snapshotId)
      );
      queryClient.invalidateQueries(queryKeys.handoffHubSnapshot(locationId, snapshotId));
    },
    onError(err) {
      alerts.error('Error deleting Handoff Hub note');
      console.log('Delete HandoffHub Snapshot Error', err);
    },
  });
};

export const useCreateOrUpdateHandoffHubSnapshotMutation = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const queryKey = queryKeys.handoffHubSnapshots(locationId);
  const username = useSelector((state: Store) => state.auth.user.username);

  return useMutation({
    mutationFn: async ({
      snapshot,
      isSubmit = false,
    }: {
      snapshot: Partial<HandoffSnapshot>;
      isSubmit?: boolean;
    }) => {
      if (!snapshot.id) {
        // For first time, when we don't have snapshot id, we need to create a new snapshot and update it
        const newSnapshot = await handoffHubApi.createHandoffHubSnapShot();
        snapshot.id = newSnapshot.id;

        await handoffHubApi.updateHandoffSnapshot(snapshot, snapshot.id);
      } else {
        // For Edit case, update snapshot
        snapshot.metrics = isSubmit ? snapshot.metrics : [];
        await handoffHubApi.updateHandoffSnapshot(snapshot, snapshot.id);

        // if it is submit, update submittedBy field
        if (isSubmit) {
          // setting this field just to have snapshot updated with submittedBy and submittedAt on client side without making another API call
          snapshot.handoffSubmittedBy = username;
          snapshot.submittedAt = new Date().toISOString();
          await handoffHubApi.submitHandoffHub(
            {
              handoffSubmittedBy: username,
              locationId,
            },
            snapshot.id
          );
        }
      }
      return snapshot;
    },
    onSuccess(snapshot) {
      alerts.success('Handoff Hub note saved');
      queryClient.setQueryData<HandoffSnapshot[]>(queryKey, (list = []) => {
        const snapshotIndex = list.findIndex((s) => s.id === snapshot.id);
        if (snapshotIndex > -1) {
          list[snapshotIndex] = snapshot as HandoffSnapshot;
        } else {
          list.unshift(snapshot as HandoffSnapshot);
        }
        return list;
      });
      queryClient.invalidateQueries(
        queryKeys.handoffHubSnapshot(locationId, snapshot.id ?? '')
      );
    },
    onError(err) {
      alerts.error('Error saving Handoff Hub note');
      console.log('CreateOrUpdate HandoffHub Snapshot Error', err);
    },
  });
};
