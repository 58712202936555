import { HandoffTypeMetric } from '../handoff.constants';
import { DependencyDataKeys, Result } from '../handoff-metrics.types';

const CSV_UPLOAD_VALUE = 'True';

export const csvUploadMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return 'CSV should have been uploaded';
  },
  dependencyDataKeys: [DependencyDataKeys.SyncappHealth],
  expectedValue: CSV_UPLOAD_VALUE,
  actualValueFn: (dependentData) => {
    const syncAppData = dependentData[DependencyDataKeys.SyncappHealth];

    const err = syncAppData?.error;
    if (err) return err.message;

    const csvUploadData = syncAppData?.data?.SyncApps;

    const hasCSVUpload = csvUploadData?.some(
      (csv) => csv.PracticeManagementSystem === 'CSV'
    );

    if (!csvUploadData) return 'no data';

    if (!hasCSVUpload) return 'False';

    return 'True';
  },
  resultFn: function (dependentData) {
    const syncAppData = dependentData[DependencyDataKeys.SyncappHealth];

    const err = syncAppData?.error;

    if (err) return Result.Fail;

    const actualValue = this.actualValueFn(dependentData);

    if (actualValue === this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    'Office is working with PMS to provide a workable CSV file',
    'Office is planning to upload CSV file soon.',
    'Office is not interested in uploading a CSV file.',
  ],
};
