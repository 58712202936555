import { css } from '@emotion/core';
import React, { FC, useEffect, useRef } from 'react';
import { GetInvoiceDetailOrPdfResponse } from '../billing.types';
import { InvoiceDetailsTemplate } from './invoice-details-template/invoice-details-template';
import printJS from 'print-js';
import { useReactToPrint } from 'react-to-print';

interface Props {
  showPrintDialog: boolean;
  invoiceId?: string;
  onPrintDialogClose: () => void;
  data?: GetInvoiceDetailOrPdfResponse;
}

export const InvoiceDetailsPrint: FC<Props> = ({
  data,
  invoiceId,
  onPrintDialogClose,
  showPrintDialog,
}) => {
  const { invoice: invoiceData, invoicePdf } = data || {
    invoice: null,
    invoicePdf: null,
  };

  const documentTitle = invoiceData
    ? `${invoiceData?.invoice?.billingContact?.companyName}-${invoiceData?.invoice?.id}`
    : invoiceId ?? `Invoice_${Date.now()}`;

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle,
    onAfterPrint: onPrintDialogClose,
  });

  useEffect(() => {
    if (!showPrintDialog) {
      return;
    }
    if (invoicePdf) {
      // Used print-js library for base64 pdf printing. Using object or iframe for pdf rendering was creating blank pdf
      printJS({
        printable: invoicePdf,
        type: 'pdf',
        base64: true,
        documentTitle,
        onPrintDialogClose,
      });
    } else if (invoiceData) {
      // react-to-print will print invoice from template
      handlePrint?.();
    }
  }, [documentTitle, handlePrint, invoicePdf, invoiceData, showPrintDialog]);

  if (!invoiceData) {
    return null;
  }

  return (
    <div
      css={css`
        /* Keep this hidden so that it will not show up on the page. react-to-print will
        select the child div using the ref to display in the print dialogue. */
        display: none;
      `}
    >
      <div ref={componentRef}>
        <InvoiceDetailsTemplate invoiceDetails={invoiceData} />
      </div>
    </div>
  );
};
