import React from 'react';
import {
  Table,
  TableColumnConfig,
  TrashIcon,
  UpdateIcon,
  EditIcon,
  ListIcon,
} from '@weave/design-system';
import { useMemo, useState } from 'react';
import { BatchSetFlagModal } from './batch-set-flag-modal.component';
import { DeleteFlagModal } from './delete-flag-modal.component';
import { EditFlagModal } from './edit-flag-modal.component';
import { FeatureFlagLocationsModal } from './flag-locations-modal.component';
import { useFeatureFlagList, FeatureFlag } from './feature-flags.api';
import { FeatureFlagState } from './feature-flag-state.component';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { selectWeaveUserAcls } from 'redux/actions/auth/auth.selectors';
import { CoreACLs } from 'redux/actions/auth/auth.types';

type FeatureFlagTableProps = {};

export const FeatureFlagTable = (props: FeatureFlagTableProps) => {
  const ffList = useFeatureFlagList();

  const [flags, setFlags] = useState<FeatureFlag[]>([]);
  const [selectedFlag, setSelectedFlag] = useState<FeatureFlag>({
    name: '',
    description: '',
    defaultValue: false,
    id: '',
  });

  // show modal values
  const [showBatchSetModal, setShowBatchSetModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLocationsModal, setShowLocationsModal] = useState(false);

  const hasBulkFfEditACL =
    useSelector(selectWeaveUserAcls).indexOf(CoreACLs.FEATURE_FLAG_BULK_ADMIN) >= 0;
  const canDeleteFeatureFlags = hasBulkFfEditACL;

  const canEditFeatureFlags =
    useSelector(selectWeaveUserAcls).indexOf(CoreACLs.FEATUREFLAGWRITE) >= 0;
  const columns = useMemo(
    (): TableColumnConfig<FeatureFlag>[] => [
      {
        id: 'default-value',
        Header: 'Default Value',
        accessor: (row) => row?.defaultValue,
        cellRenderer: (value) => <FeatureFlagState state={value} />,
        maxWidth: 170,
        cellAlign: 'center',
        sortType: (rowA, rowB, columnId) =>
          rowA.values[columnId] === rowB.values[columnId]
            ? 0
            : rowA.values[columnId]
            ? 1
            : -1,
      },
      {
        id: 'name',
        Header: 'Name',
        accessor: (row) => row?.name ?? '',
        cellConfig: {
          element: 'Text',
          weight: 'semibold',
          css: {
            margin: 0,
          },
        },
        sortType: 'caseInsensitive',
        maxWidth: 300,
      },
      {
        id: 'description',
        Header: 'Description',
        accessor: (row) => row?.description ?? '',
        sortType: 'caseInsensitive',
      },
    ],
    []
  );

  const onFeatureFlagDelete = () => {
    if (ffList?.refetch) {
      ffList.refetch();
    } else {
      setFlags(
        flags.filter((flag) => {
          return flag.name !== selectedFlag.name;
        })
      );
    }
    setShowDeleteModal(false);
  };

  return (
    <>
      <Table
        wrapperStyle={css`
          display: block;
        `}
        data={ffList.data?.flags || flags}
        colConfig={columns}
        hasGlobalSearch
        isPaginated
        // fullHeight
        clientPaginationConfig={{
          defaultRowsPerPage: 20,
          rowsPerPageOptions: [20, 40, 60, 100],
        }}
        styleConfig={{
          rows: [
            {
              style: css`
                padding: 0.5em 1em;
              `,
            },
          ],
        }}
        rowActions={{
          actions: [
            {
              label: 'Locations Set',
              onClick: (row) => {
                setSelectedFlag(row);
                setShowLocationsModal(true);
              },
              Icon: ListIcon,
            },
            {
              label: 'Edit Flag',
              onClick: (row) => {
                setSelectedFlag(row);
                setShowEditModal(true);
              },
              Icon: EditIcon,
              disabled: !canEditFeatureFlags,
            },
            {
              label: 'Batch Set',
              onClick: (row) => {
                setSelectedFlag(row);
                setShowBatchSetModal(true);
              },
              Icon: UpdateIcon,
              disabled: !hasBulkFfEditACL,
            },
            {
              label: 'Delete',
              onClick: (row) => {
                setSelectedFlag(row);
                setShowDeleteModal(true);
              },
              Icon: TrashIcon,
              disabled: !canDeleteFeatureFlags,
            },
          ],
        }}
      />
      {showBatchSetModal && (
        <BatchSetFlagModal
          flagName={selectedFlag.name ?? ''}
          defaultValue={selectedFlag.defaultValue}
          close={(newDefaultValue) => {
            // if a new default value has been set from setAll then update that flag to reflect it.
            if (newDefaultValue !== undefined) {
              const flagCopy = [...flags];

              for (let i = flagCopy.length - 1; i >= 0; i--) {
                if (flagCopy[i].name === selectedFlag.name) {
                  flagCopy[i].defaultValue = newDefaultValue;
                  break;
                }
              }

              setFlags(flagCopy);
            }

            setShowBatchSetModal(false);
          }}
        />
      )}
      <EditFlagModal
        show={showEditModal}
        flag={selectedFlag}
        close={() => {
          setShowEditModal(false);
        }}
      />
      {showDeleteModal && (
        <DeleteFlagModal
          flagName={selectedFlag.name ?? ''}
          close={() => setShowDeleteModal(false)}
          onDelete={onFeatureFlagDelete}
        />
      )}
      <FeatureFlagLocationsModal
        show={showLocationsModal}
        flagName={selectedFlag.name ?? ''}
        close={() => setShowLocationsModal(false)}
      />
    </>
  );
};
