import React from 'react';
import { css } from 'emotion';

import { Template } from '../../../../redux/actions/notifications-settings/notification-settings.types';
import { OptionSwitchField, useFormField } from '@weave/design-system';
import { theme } from '@weave/theme-original';

const optionStyles = css`
  margin-bottom: ${theme.spacing(2)};
`;

interface Props {
  type: string;
  template: Template;
  updateSendTo: (template: Template) => void;
}

export const EditReminderSendToType = ({ type, template, updateSendTo }: Props) => {
  const switchFieldProps = useFormField({ type: 'optionswitch', value: template.type }, [
    template.type,
  ]);

  const optionTypes =
    type === 'order' ? ['Received', 'Dispensed'] : ['confirmed', 'unconfirmed', 'all'];

  const handleChange = (e) => {
    updateSendTo({
      ...template,
      type: e.target.value,
    });
  };

  return (
    <>
      <OptionSwitchField
        {...switchFieldProps}
        name="sendTo"
        label="Send To:"
        className={optionStyles}
        onChange={(e) => handleChange(e)}
      >
        {optionTypes.map((type) => (
          <OptionSwitchField.Option key={`send-to-type-${type}`} value={type}>
            {type}
          </OptionSwitchField.Option>
        ))}
      </OptionSwitchField>
    </>
  );
};
