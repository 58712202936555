import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ContentLoader,
  Modal,
  PrimaryButton,
  useModalControl,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { useSelector } from 'react-redux';

import { Page } from '../../../../../shared';
import { injectParams, ONBOARDING } from '../../../../../../constants/paths';
import {
  selectCurrentLocationId,
  selectCurrentLocationSlug,
} from '../../../../../../redux/actions/location';
import { HandoffSnapshotMetrics } from './handoff-snapshot-metrics';
import { HandoffSnapshot } from '../../../handoff-hub.types';
import { useSnapshotState } from './use-edit-handoff-state';
import { HandoffTypes } from './handoff-metrics/handoff.constants';
import { useHandoffMetrics } from './handoff-metrics/use-handoff-metrics';
import {
  getAllMetricResults,
  getConcatenatedMetricName,
  groupFailedMetrics,
} from './handoff-metrics/handoff-metrics-helpers';
import {
  ExceptionsModal,
  SelectedExceptionsMap,
} from './modals/exceptions-modal/exceptions-modal';
import { FailedMetricsModal } from './modals/failed-metrics-modal';
import { Store } from '../../../../../../redux/store/store.model';
import { useCreateOrUpdateHandoffHubSnapshotMutation } from 'components/onboarding/handoff-hub/handoff-hub.queries';
import { useDebugMode } from '../../../../../debug/use-debug-mode';
import { useAlert } from '@weave/alert-system';
import {
  onboardingHistoryApi,
  onboardingProgressApi,
} from '../../.././../onboarding.api';
import {
  OnboardingEvent,
  OnboardingProgressStatus,
} from '@weave/schema-gen-ts/dist/shared/insys/activation/onboarding.pb';

enum HandoffViews {
  MetricsView,
  LocationInformationView,
}

interface Props {
  handoffSnapshot?: HandoffSnapshot;
}

export const AddEditHandoff = (props: Props) => {
  const alert = useAlert();
  const locationId = useSelector(selectCurrentLocationId);
  const locationSlug = useSelector(selectCurrentLocationSlug);

  const [currentView, setCurrentView] = useState<HandoffViews>(HandoffViews.MetricsView);
  const rootHandoffHubUrl = injectParams(ONBOARDING.handoff, { id: locationId });
  const username = useSelector((state: Store) => state.auth.user.username);
  const history = useHistory();

  const { mutate: createOrUpdateSnapshotMutation, isLoading: isUpdating } =
    useCreateOrUpdateHandoffHubSnapshotMutation();

  const { snapshot, setSnapshot } = useSnapshotState(props.handoffSnapshot);
  const { handoffTypeData, dependentData, refetch } = useHandoffMetrics(
    snapshot.handoffType ?? HandoffTypes.OnboardingHandoff
  );

  const exceptionsModalControl = useModalControl();
  const failedHandoffModalControl = useModalControl();

  const { isDebugModeEnabled } = useDebugMode();

  const handleHandoffTypeChange = (handoffType: HandoffTypes) => {
    setSnapshot((state) => ({ ...state, handoffType }));
  };
  const createOrUpdateSnapshot = (
    updates: Partial<HandoffSnapshot>,
    onSuccess: (snapshot: Partial<HandoffSnapshot>) => Promise<void> | void,
    isSubmit = false
  ): void => {
    const newSnapshot = { ...snapshot, ...updates };
    createOrUpdateSnapshotMutation(
      { snapshot: newSnapshot, isSubmit },
      {
        onSuccess: () => {
          setSnapshot(newSnapshot);
          onSuccess(newSnapshot);
        },
      }
    );
  };

  const handleNextClick = (updates: Partial<HandoffSnapshot>) =>
    createOrUpdateSnapshot(updates, () =>
      setCurrentView(HandoffViews.LocationInformationView)
    );
  const handleBackClick = (updates: Partial<HandoffSnapshot>) =>
    createOrUpdateSnapshot(updates, () => setCurrentView(HandoffViews.MetricsView));
  const handleSaveAndCloseClick = (updates: Partial<HandoffSnapshot>) =>
    createOrUpdateSnapshot(updates, () => history.push(rootHandoffHubUrl));

  const handleSubmitHandoffSuccess = async (snapshot: Partial<HandoffSnapshot>) => {
    if (snapshot.handoffType !== HandoffTypes.OnboardingHandoff) {
      history.push(rootHandoffHubUrl);
      return;
    }

    try {
      const res =
        await onboardingProgressApi.getLatestOnboardingProgressStatusByLocationId(
          locationId
        );
      const isSelfActivationLocation =
        !!res.onboardingProgressStatus &&
        res.onboardingProgressStatus !==
          OnboardingProgressStatus.ONBOARDING_PROGRESS_STATUS_UNSPECIFIED;

      if (isSelfActivationLocation) {
        await onboardingHistoryApi.publishOnboardingEvent({
          slug: locationSlug,
          locationId,
          onboardingEvent: OnboardingEvent.ONBOARDING_EVENT_HANDOFF_COMPLETED,
        });
      }
    } catch (error) {
      alert.error('Error getting latest onboarding progress status');
      console.error('Error getting latest onboarding progress status', error);
    }

    history.push(rootHandoffHubUrl);
  };

  const handleSubmitClick = async (updates: Partial<HandoffSnapshot>) => {
    const metricResults = getMetricResults();
    const groupedFailedMetrics = groupFailedMetrics(metricResults, handoffTypeData);

    if (groupedFailedMetrics.withNoExceptions.length && !isDebugModeEnabled) {
      // If there is at least one failed metric with no exceptions available, save the
      // data and show the failed metrics modal.
      createOrUpdateSnapshot(updates, failedHandoffModalControl.openModal);
    } else if (groupedFailedMetrics.withExceptions.length) {
      // If there is at least one failed metric with exceptions available, save the
      // current data and show the exceptions modal.
      createOrUpdateSnapshot(updates, exceptionsModalControl.openModal);
    } else {
      // No failed metrics so submit handoff with all required data.
      const newSnapshot = {
        ...updates,
        metrics: metricResults,
      };

      createOrUpdateSnapshot(newSnapshot, handleSubmitHandoffSuccess, true);
    }
  };

  const handleSubmitWithExceptions = async (
    selectedExceptionsMap: SelectedExceptionsMap
  ) => {
    const metricResults = getMetricResults();

    const updatedMetricResults = metricResults.map((metricResult) => {
      const concatenatedName = getConcatenatedMetricName(metricResult);
      return { ...metricResult, exception: selectedExceptionsMap[concatenatedName] };
    });

    const newSnapshot = {
      ...snapshot,
      metrics: updatedMetricResults,
    };
    createOrUpdateSnapshot(newSnapshot, handleSubmitHandoffSuccess, true);
  };

  const handleCancelClick = () => {
    history.goBack();
  };

  const getMetricResults = () => {
    const metricResults = getAllMetricResults(handoffTypeData, dependentData);
    return metricResults;
  };

  const title = `${snapshot.id ? 'Edit' : 'Create'} Handoff`;

  return (
    <Page
      title={title}
      breadcrumbs={[
        { url: rootHandoffHubUrl, title: 'Handoff Hub' },
        { url: '', title },
      ]}
      headerActions={
        <>
          {currentView === HandoffViews.MetricsView && (
            <PrimaryButton
              size="large"
              css={refreshButtonStyle}
              onClick={refetch}
              data-testid="refreshSnapshots"
            >
              Refresh
            </PrimaryButton>
          )}
        </>
      }
    >
      <div css={mainContainer}>
        <ContentLoader show={isUpdating} />

        {currentView === HandoffViews.MetricsView && (
          <HandoffSnapshotMetrics
            handoffNameInitialValue={snapshot.handoffName ?? ''}
            handoffType={snapshot.handoffType}
            handoffTypeData={handoffTypeData}
            dependentData={dependentData}
            onHandoffTypeChange={handleHandoffTypeChange}
            onNextClick={handleNextClick}
            onCancelClick={handleCancelClick}
            onSaveAndClose={handleSaveAndCloseClick}
          />
        )}

        {currentView === HandoffViews.LocationInformationView && (
          // Load the form component that was listed in the const file for the current
          // handoff type.
          // NOTE: The props are listed in the const file as well.
          <handoffTypeData.FormComponent
            state={snapshot}
            disableButtons={isUpdating}
            onBackClick={handleBackClick}
            onSaveAndClose={handleSaveAndCloseClick}
            onSubmitClick={handleSubmitClick}
          />
        )}
      </div>

      <Modal {...exceptionsModalControl.modalProps} maxWidth={850}>
        <ExceptionsModal
          failedMetricsWithExceptions={
            groupFailedMetrics(getMetricResults(), handoffTypeData).withExceptions
          }
          isLoading={isUpdating}
          onSubmit={handleSubmitWithExceptions}
          onClose={exceptionsModalControl.closeModal}
        />
      </Modal>

      <Modal {...failedHandoffModalControl.modalProps} maxWidth={625}>
        <FailedMetricsModal
          failedMetrics={
            groupFailedMetrics(getMetricResults(), handoffTypeData).withNoExceptions
          }
          onClose={failedHandoffModalControl.closeModal}
        />
      </Modal>
    </Page>
  );
};

const mainContainer = css`
  padding: ${theme.spacing(0, 1)};
  width: 700px;
  position: relative;
`;

const refreshButtonStyle = css`
  max-width: 119px;
`;
