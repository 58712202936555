import { connect } from 'react-redux';
import { FormikBag, FormikErrors, withFormik } from 'formik';
import { compose } from 'lodash/fp';

import {
  LocationDemoForm,
  LocationFormProps,
} from '../location-form/location-demo-form.component';
import {
  convertLocationToProvisionLocationDTO,
  LocationModelWithPhoneData,
  LocationTypes,
  mockLocationWithPhoneData,
  requestCreateLocation,
} from '../../../redux/actions/location/locations';
import { setCurrentLocationId } from '../../../redux/actions/location/current-location';
import {
  ProvisionLocationRequestDTO,
  resetLocationCreationStatus,
} from '../../../redux/actions/location/location-status';
import { Store } from '../../../redux/store/store.model';
import { VerticalEnum } from '../../../models/vertical-enum';

interface Props extends LocationFormProps {
  createLocation: (location: LocationModelWithPhoneData) => void;
}

export const validateLocationCreateForm = function (values) {
  const errors: FormikErrors<LocationModelWithPhoneData> = {};

  if (!values.Name) {
    errors.Name = 'Required';
  }
  if (!values.Slug) {
    errors.Slug = 'Required';
  }
  if (/[A-Z]/.test(values.Slug)) {
    errors.Slug = 'Slug may not contain capital letters';
  }
  if (!values.Email) {
    errors.Email = 'Required';
  }

  return errors;
};

export const LocationCreateDemoContainer = compose(
  connect(
    (state: Store) => ({
      creationStatus: state.locationStatus.creationStatus,
      loading: state.locationStatus.loading,
    }),
    (dispatch, ownProps: any) => ({
      createLocation: (
        location: ProvisionLocationRequestDTO,
        createPhoneTenant: boolean
      ) => dispatch(requestCreateLocation(location, createPhoneTenant)),
      onClose: () => ownProps.history.push('/'),
      resetLocationCreationStatus: () => dispatch(resetLocationCreationStatus()),
      setCurrentLocationId: (locationId: string) =>
        dispatch(setCurrentLocationId(locationId)),
    })
  ),
  withFormik({
    mapPropsToValues: () => {
      const location = mockLocationWithPhoneData({
        Timezone: 'US/Mountain',
        Type: LocationTypes.Demo,
        VerticalID: VerticalEnum.Dental,
        Active: true,
      });
      return location;
    },
    validate: validateLocationCreateForm,
    handleSubmit: (values, formikBag) => {
      const req: ProvisionLocationRequestDTO = {
        ...convertLocationToProvisionLocationDTO(values),
        verticalId: Number(values.VerticalID),
        callerNumber: values.caller_number,
        street1: '2000 Ashton Blvd',
        street2: 'Suite 100',
        city: 'Lehi',
        state: 'UT',
        zip: '84043',
        country: 'US',
        salesforceProducts:
          values.VerticalID === VerticalEnum.Other
            ? ['wvWEAVE', 'wvimplementation', 'SIP-T53-PSU']
            : ['wvWEAVE', 'wvimplementation', 'SIP-T53-PSU'],
      };

      //TODO: Fix this type
      (formikBag.props as any).createLocation(req, true);
      formikBag.resetForm();
    },
  })
)(LocationDemoForm);
