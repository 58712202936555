import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { SalesforceAccountProductBundle } from '../customization-page-v2.types';

export const CustomizationFlagTableFilterContext = React.createContext<
  SalesforceAccountProductBundle | ''
>('');

export const CustomizationFlagTableFilterUpdaterContext = React.createContext<Dispatch<
  SetStateAction<SalesforceAccountProductBundle | ''>
> | null>(null);

export const CustomizationFlagTableFilterProvider = ({ children }) => {
  const [tableFilterValue, setTableFilterValue] = useState<
    SalesforceAccountProductBundle | ''
  >('');
  return (
    <CustomizationFlagTableFilterUpdaterContext.Provider value={setTableFilterValue}>
      <CustomizationFlagTableFilterContext.Provider value={tableFilterValue}>
        {children}
      </CustomizationFlagTableFilterContext.Provider>
    </CustomizationFlagTableFilterUpdaterContext.Provider>
  );
};

export const useTableFilterValue = () =>
  React.useContext(CustomizationFlagTableFilterContext);

export const useTableFilterValueUpdater = () => {
  const setTableFilterValue = React.useContext(
    CustomizationFlagTableFilterUpdaterContext
  );

  const updateTableFilterValue = React.useCallback(
    (filterValue: SalesforceAccountProductBundle | '') => {
      if (setTableFilterValue) setTableFilterValue(filterValue);
    },
    [setTableFilterValue]
  );

  return updateTableFilterValue;
};
