import { css } from '@emotion/core';
import { useAlert } from '@weave/alert-system';
import {
  CheckboxField,
  EmailField,
  FormRow,
  PrimaryButton,
  TextField,
  useForm,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { AuthStorage } from '../../redux/actions/auth/auth.types';
import { Page } from '../shared/page/page.component';

// NOTE: THIS PAGE IS FOR INTERNAL USE BY APP-INFRA TEAM ONLY
export const OnboardingAutomationContainer = () => {
  const alerts = useAlert();
  const { getFieldProps, values, isComplete, reset } = useForm({
    fields: {
      email: {
        type: 'email',
        required: true,
        value: localStorage.getItem(AuthStorage.user_email) ?? '',
      },
      suffix: { type: 'text', required: true },
      numberOfChildren: { type: 'number', required: true },
      dontCreateQuote: {
        type: 'checkbox',
      },
      dontSignQuote: {
        type: 'checkbox',
      },
    },
  });

  const handleSubmit = () => {
    try {
      fetch(`https://api.weavedev.net/insys/automation/v1/salesforce/setup/async`, {
        method: 'POST',
        body: JSON.stringify(values),
      });
      reset();
      alerts.info('Successful. You will receive an email in a few minutes');
    } catch (error) {
      console.log(error);
      alerts.error('Error occurred');
    }
  };

  return (
    <Page title="Onboarding Automation">
      <form>
        <FormRow>
          <TextField {...getFieldProps('email')} label="Email" />
        </FormRow>

        <FormRow>
          <TextField {...getFieldProps('suffix')} label="Suffix" />
        </FormRow>

        <FormRow>
          <EmailField {...getFieldProps('numberOfChildren')} label="Number of Children" />
        </FormRow>
        <FormRow>
          <CheckboxField
            {...getFieldProps('dontCreateQuote')}
            label="Don't Create Quote"
            css={css({ fontWeight: theme.font.weight.bold })}
          />
        </FormRow>
        <FormRow>
          <CheckboxField
            {...getFieldProps('dontSignQuote')}
            label="Don't Sign Quote"
            css={css({ fontWeight: theme.font.weight.bold })}
          />
        </FormRow>
        <PrimaryButton onClick={handleSubmit} disabled={!isComplete}>
          Submit
        </PrimaryButton>
      </form>
    </Page>
  );
};
