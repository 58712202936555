// coalesce returns the first non-empty value
const coalesce = (properties, errorMessage) => {
  const notEmptyProperties = properties.filter((property) => property !== undefined);
  if (notEmptyProperties.length === 0) {
    throw new Error(errorMessage);
  }
  return notEmptyProperties[0];
};

// the env object doesn't get loaded for tests so initialize it here
// if it's empty
if (window.env === undefined) {
  window.env = {};
}

// See README.md for instructions on using ENV vars
export const REACT_APP_BASE_URL = coalesce(
  [window.env.REACT_APP_BASE_URL, process.env.REACT_APP_BASE_URL],
  'Failed to resolve dynamic auth redirect url...'
);

export const REACT_APP_API_URL = coalesce(
  [window.env.REACT_APP_API_URL, process.env.REACT_APP_API_URL],
  'Failed to resolve dynamic api prefix...'
);
export const isDevEnv = () => {
  const isDev =
    REACT_APP_API_URL &&
    REACT_APP_API_URL.includes &&
    REACT_APP_API_URL.includes('weavedev.net');
  return isDev;
};

export const REACT_APP_ANALYTICS_URL = coalesce(
  [window.env.REACT_APP_ANALYTICS_URL, process.env.REACT_APP_ANALYTICS_URL],
  'Failed to resolve dynamic api prefix...'
);

export const REACT_APP_SYNCAPP_SOCKET_URL = coalesce(
  [window.env.REACT_APP_SYNCAPP_SOCKET_URL, process.env.REACT_APP_SYNCAPP_SOCKET_URL],
  'Failed to resolve dynamic syncapp socket url...'
);

export const REACT_APP_FF_V0_URL = coalesce(
  [window.env.REACT_APP_FF_V0_URL, process.env.REACT_APP_FF_V0_URL],
  'Failed to resolve my env var...'
);

export const REACT_APP_FIREBASE_TOKEN_URL = coalesce(
  [window.env.REACT_APP_FIREBASE_TOKEN_URL, process.env.REACT_APP_FIREBASE_TOKEN_URL],
  'Failed to resolve dynamic env REACT_APP_FIREBASE_TOKEN_URL.'
);

export const REACT_APP_TWOCP_PUBLIC_KEY_ACH = coalesce(
  [window.env.REACT_APP_TWOCP_PUBLIC_KEY_ACH, process.env.REACT_APP_TWOCP_PUBLIC_KEY_ACH],
  'Failed to resolve dynamic env REACT_APP_TWOCP_PUBLIC_KEY_ACH.'
);

export const REACT_APP_TWOCP_PUBLIC_KEY_CC = coalesce(
  [window.env.REACT_APP_TWOCP_PUBLIC_KEY_CC, process.env.REACT_APP_TWOCP_PUBLIC_KEY_CC],
  'Failed to resolve dynamic env REACT_APP_TWOCP_PUBLIC_KEY_CC.'
);
export const REACT_APP_OKTA_CLIENT_ID = coalesce(
  [window.env.REACT_APP_OKTA_CLIENT_ID, process.env.REACT_APP_OKTA_CLIENT_ID],
  'Failed to resolve dynamic env REACT_APP_OKTA_CLIENT_ID.'
);

export const REACT_APP_OKTA_ISSUER = coalesce(
  [window.env.REACT_APP_OKTA_ISSUER, process.env.REACT_APP_OKTA_ISSUER],
  'Failed to resolve dynamic env REACT_APP_OKTA_ISSUER.'
);
