import React, { useEffect, useState } from 'react';
import { DropdownField, useFormField, useControlledField } from '@weave/design-system';
import { textAreaFieldCountryStyle } from './e911-dashboard.styles';
import { childLocationDetails } from '../../apis/porting/porting.types';

interface Props {
  id: string;
  childLocationDetail: childLocationDetails[];
  style?: boolean;
  setChildLocationDetails: (radioID: string, childLocDetail: string) => void;
}

const DropDownChildLocationComponent = ({
  id,
  childLocationDetail,
  setChildLocationDetails,
  style,
}: Props) => {
  const [dropdownValue, setDropdownValue] = useState<string>('');
  const childLocationValue = useControlledField({
    type: 'dropdown',
    value: dropdownValue,
    onChange: (value) => {
      return setDropdownValue(value), setChildLocationDetails(id, value);
    },
  });

  return (
    <div key={id}>
      <DropdownField
        name="child_location"
        label="location"
        {...childLocationValue}
        className={`${style ? textAreaFieldCountryStyle : ''} `}
      >
        {childLocationDetail?.flat()?.map((childLocationData, i) => (
          <DropdownField.Option
            value={childLocationData?.LocationID}
            key={childLocationData?.LocationID}
          >
            {childLocationData?.Name}
          </DropdownField.Option>
        ))}
      </DropdownField>
    </div>
  );
};

export default DropDownChildLocationComponent;
