import React from 'react';
import { useEffect, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { css, cx } from 'emotion';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { LocationSearchModel } from '../../../../models/location-search.model';
import { UserModel } from '../../../../models/user.model';
import { setCurrentLocationId } from '../../../../redux/actions/location';
import { useDispatch } from 'react-redux';
import { weaveTheme } from '@weave/theme-original/dist/weaveTheme';

const typeaheadContainer = css`
  display: inline-block;
  margin-left: -3px;
  min-width: 271px;
  > .form-control {
    font-size: 10px;
  }
  input.rbt-input-hint {
    display: none;
  }
  .rbt-input-main {
    color: ${weaveTheme.colors.gray500};
    height: 40px;
    width: 100%;
    font-size: 13px;
    padding-left: 15px;
    border: 1px solid ${weaveTheme.colors.gray300};
    border-radius: 5px;
    transition: border-color 0.3s ease-in;
    :focus {
      box-shadow: none;
      border-color: ${weaveTheme.colors.weaveBlue};
    }
  }
`;

enum SearchTypes {
  Location = 'location',
  User = 'user',
  UserLocations = 'userLocations',
}

interface Props {
  loading: boolean;
  locations: LocationSearchModel[];
  userLocations: any;
  userSearchResults: UserModel[];
  className?: any;
  onSelect?: (location: LocationSearchModel) => void;
  locationSearch: (query: string) => void;
  userSearch: (props: { state: string }) => void;
  userLocationsSearch: (userId: string) => void;
}

export const LocationSearch = (props: Props) => {
  let typeahead: any;
  const dispatch = useDispatch();
  const [searchType, setSearchType] = useState<SearchTypes>(SearchTypes.Location);
  const [searchQuery, setSearchQuery] = useState('');

  const setLocation = (locationId: string) => dispatch(setCurrentLocationId(locationId));

  useEffect(() => {
    searchQueryChanged();
  }, [searchQuery]);

  useEffect(() => {
    userSearchResultsChanged();
  }, [props.userSearchResults]);

  // The logic for selecting a user in the typeahead dropdown menu is different than
  // selecting a location. Since no location data is available yet, what will be done
  // instead is to set the full text of the selected username email as the input text
  // in the typeahead input component and trigger the appropriate events to simulate a
  // search using the full email.
  const handleUserSelected = (selectedUser: any[]) => {
    const username = selectedUser[0].Username;

    typeahead.getInstance().clear();
    typeahead.getInstance().setState({ text: username });
    setSearchQuery(username);
    typeahead.getInstance().blur();
    typeahead.getInstance().focus();
  };

  const handleSelection = (selections: any[]) => {
    const selection = selections[0];
    if (!selection) {
      return;
    }

    if (props.onSelect) {
      props.onSelect(selection);
    } else {
      setLocation(selection.LocationID);
    }

    typeahead.getInstance().clear();
  };

  const multiLocationSearch = () => {
    const userId = props.userSearchResults[0].UserID;
    props.userLocationsSearch(userId);
  };

  const handleSearch = (currentSearchType: SearchTypes) => {
    if (!searchQuery) {
      return;
    }

    if (currentSearchType === SearchTypes.Location) {
      props.locationSearch(searchQuery);
    } else if (currentSearchType === SearchTypes.User) {
      props.userSearch({ state: searchQuery });
    } else if (currentSearchType === SearchTypes.UserLocations) {
      multiLocationSearch();
    }
  };

  const searchQueryChanged = () => {
    let type = searchType;

    if (searchQuery.includes('@')) {
      type = SearchTypes.User;
    } else {
      type = SearchTypes.Location;
    }

    if (type !== searchType) {
      setSearchType(type);
    }

    handleSearch(type);
  };

  const userSearchResultsChanged = () => {
    // When searching by user, if only one option is left, then set the searchType
    // as "userLocations" to search all locations associated with the user.
    if (props.userSearchResults.length === 1) {
      setSearchType(SearchTypes.UserLocations);
      handleSearch(SearchTypes.UserLocations);
    }
  };

  const typeaheadConfigurations = {
    locationSearch: {
      useCache: true,
      labelKey: (option: LocationSearchModel) =>
        `${option.Name} (${option.Slug}) - (${option.LocationID})`,
      filterBy: () => true,
      onChange: handleSelection,
      options: props.locations,
    },
    userSearch: {
      useCache: false,
      // Need to do exact match filtering when matching data from user search results.
      // The goal is to narrow down to only one user suggestion but without custom filtering
      // this might never happen since searching for "query@email.com" could match
      // "query@email.com" but also "name.query@email.com".
      filterBy: (option, props) => option.Username.startsWith(props.text),
      labelKey: (option) => `${option.FirstName} ${option.LastName} - ${option.Username}`,
      onChange: handleUserSelected,
      options: props.userSearchResults,
    },
    userLocationsSearch: {
      useCache: false,
      labelKey: (option) => `${option.Username} - ${option.Name}`,
      onChange: handleSelection,
      options: props.userLocations,
      renderMenuItemChildren: (option, props, index) => {
        return (
          <div>
            <div>
              {option.Name} - ({option.Slug})
            </div>
            <div style={{ fontSize: '10px' }}>{option.Username}</div>
          </div>
        );
      },
    },
  };

  return (
    <AsyncTypeahead
      id="locationSearchBox"
      placeholder="Location name, ID, slug or phone"
      className={cx(typeaheadContainer, props.className)}
      ref={(ref: any) => (typeahead = ref)}
      highlightOnlyResult={true}
      isLoading={!!props.loading}
      onSearch={setSearchQuery}
      // Spread any additional props from the appropriate typeahead config determined by
      // the current searchType.
      {...typeaheadConfigurations[`${searchType}Search`]}
      autoFocus={true}
      emptyLabel={`No matches found.`}
    />
  );
};
