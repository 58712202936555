import { createAction } from 'redux-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CustomAxios } from '../../axios';
import { Endpoint, getEndpoint } from '../history/endpoints';
import { showError } from '@weave/alert-system';
import fileDownload from 'react-file-download';

export const downloadCdr = createAction('DOWNLOAD_CDR');
export const downloadCdrALeg = createAction('DOWNLOAD_CDR_A_LEG');
export const getRecords = createAction('GET_RECORDS');
export const getRecordsSuccess = createAction('GET_RECORDS_SUCCESS');
export const setLoading = createAction('SET_RECORDS_LOADING');
export const setIsNextPageAvailable = createAction('SET_IS_NEXT_PAGE_AVAILABLE');

export const getRecordsSaga = function* () {
  const handleGetRecords = function* (action) {
    yield put(setLoading(true));
    const { phoneNumber, start, end, limit, skip } = action.payload;
    try {
      const response = yield call(CustomAxios.get, getEndpoint(Endpoint.callRecords), {
        params: {
          start,
          end,
          number: phoneNumber,
          limit: limit + 1, // get an extra record to see if a "Next" page is available
          skip,
        },
      });
      const data = response.data.data;
      if (data && data.length === limit + 1) {
        yield put(setIsNextPageAvailable(true));
        data.pop();
      } else {
        yield put(setIsNextPageAvailable(false));
      }
      yield put(getRecordsSuccess(data || []));
      yield put(setLoading(false));
    } catch {
      yield put(showError('Could not get Call Records'));
    }
  };

  yield takeEvery(getRecords.toString(), handleGetRecords);
};

export const downloadCdrSaga = function* () {
  const handleDownloadCdr = function* (action) {
    const channelId = action.payload;
    yield put(setLoading(true));
    try {
      const response = yield call(CustomAxios.get, `/support/v1/cdrs/${channelId}`);
      const data = response.data.data;
      if (data) {
        fileDownload(data, `${channelId}-cdr.json`);
        yield put(setLoading(false));
      } else {
        yield put(showError('CDR had no data to download.'));
        yield put(setLoading(false));
      }
    } catch {
      yield put(showError('Could not download CDR'));
      yield put(setLoading(false));
    }
  };

  yield takeEvery(downloadCdr.toString(), handleDownloadCdr);
};

export const downloadCdrALegSaga = function* () {
  const handleDownloadCdrALeg = function* (action) {
    const channelId = action.payload;
    yield put(setLoading(true));
    try {
      const response = yield call(CustomAxios.get, `/support/v1/cdrs/${channelId}/aleg`, {
        responseType: 'blob',
      });
      const data = response.data;
      if (data) {
        fileDownload(data, `${channelId}-cdr-aleg.json`);
        yield put(setLoading(false));
      } else {
        yield put(showError('CDR had no data to download.'));
        yield put(setLoading(false));
      }
    } catch {
      yield put(showError('Could not download CDR A Leg'));
      yield put(setLoading(false));
    }
  };

  yield takeEvery(downloadCdrALeg.toString(), handleDownloadCdrALeg);
};
