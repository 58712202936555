import React from 'react';
import { css } from 'emotion';
import { theme } from '@weave/theme-original';
import { Elements } from '@stripe/react-stripe-js';

import { TerminalList } from './terminal-list.component';
import { Page } from '../shared/page/page.component';
import { useMerchant } from './use-merchant.hook';
import { PaymentsOnboardingLink } from './payments-onboarding-link.component';
import { PaymentsStatusDisplay } from './payments-settings-display.component';
import { PaymentPricingDisplay } from './pricing/payment-pricing.component';
import { appearance } from './utils/stripe-appearance';
import { useStripePromise } from './hooks/use-stripe-promise';
import { TerminalDebugComponent } from './terminal-debug-component';

const content = css`
  & > * {
    margin: 8px 0;
  }
`;

const lineBreak = css`
  margin: 30px 0px;
  border-color: ${theme.colors.gray400};
`;

export const PaymentPage = () => {
  const { loading, activeProcessor, merchant } = useMerchant();
  const { stripePromise } = useStripePromise();

  const showOnboardingLink = !!(
    !activeProcessor || activeProcessor.stripeAccountType !== 'EXPRESS'
  );

  // merchant hasn't loaded
  if (merchant === undefined) {
    return null;
  }
  return (
    <Page title="Payment Settings">
      <div className={content}>
        {showOnboardingLink && <PaymentsOnboardingLink />}
        {activeProcessor && (
          <>
            <PaymentsStatusDisplay />
            {/* We don't charge a fee or allow terminals on Standard accounts, so this data should only show for Express */}
            {activeProcessor.stripeAccountType === 'EXPRESS' && (
              <Elements
                stripe={stripePromise}
                options={{
                  mode: 'setup',
                  currency: 'usd',
                  setupFutureUsage: 'off_session',
                  onBehalfOf: activeProcessor?.stripeId,
                  appearance: appearance,
                }}
              >
                <hr className={lineBreak} />
                <PaymentPricingDisplay />
                <hr className={lineBreak} />
                <TerminalList merchant={merchant} isMerchantLoading={loading} />
                <TerminalDebugComponent />
              </Elements>
            )}
          </>
        )}
      </div>
    </Page>
  );
};
