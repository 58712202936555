import React, { useState } from 'react';
import { useAlert } from '@weave/alert-system';
import { WeaveTheme } from '@weave/theme-original';
import {
  Heading,
  Text,
  PhoneField,
  useFormField,
  PrimaryButton,
} from '@weave/design-system';
import { css } from '@emotion/core';
import { CustomAxios } from '../../../redux/axios';

export const PhoneNumberLookup = () => {
  const phoneFieldProps = useFormField({
    required: true,
    type: 'phone',
  });
  const [showItem, setShowItem] = useState('');
  const alerts = useAlert();

  const handleLookupPhoneNumber = async () => {
    try {
      const response = await CustomAxios.get(
        `support/v1/phone/numbers/lookup/${phoneFieldProps.value}`
      );
      setShowItem(JSON.stringify(response.data.result, undefined, 4));
    } catch {
      alerts.error('Failed!');
    }
  };

  return (
    <>
      <Heading>Phone Number Lookup</Heading>
      <Text color="light">
        Phone number lookups pull caller ID name and current carrier information. Commonly
        used for verifying phone number ownership and status.
      </Text>
      <PhoneField
        name="phone"
        label="Phone Number"
        format="standard"
        {...phoneFieldProps}
        clearable={false}
        css={(theme: WeaveTheme) => css`
          width: 300px;
          margin-top: ${theme.spacing(3)};
        `}
      />
      <div
        css={css`
          width: 160px;
        `}
      >
        <PrimaryButton
          disabled={!!phoneFieldProps.error}
          onClick={handleLookupPhoneNumber}
          css={(theme: WeaveTheme) => css`
            margin: ${theme.spacing(2)} 0;
          `}
        >
          Lookup
        </PrimaryButton>
      </div>
      {showItem && <pre>{showItem}</pre>}
    </>
  );
};
