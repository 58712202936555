import React from 'react';
import { css } from '@emotion/core';
import { Dot, Text } from '@weave/design-system';
import { theme } from '@weave/theme';
import { customizationPageTestIds } from '../customization-page-test-ids';

interface Props {
  flagName: string;
  highlightCurrentFlagAsProhibited: boolean;
  isProhibitedFlagsPresent: boolean;
  isDisabled?: boolean;
}

export const CustomizationFlagName = React.memo(
  ({
    flagName,
    highlightCurrentFlagAsProhibited,
    isProhibitedFlagsPresent,
    isDisabled,
  }: Props) => {
    const dataTestId = customizationPageTestIds.customizationFlagName(
      flagName.replaceAll(' ', '-')
    );

    return (
      <div css={customizationFlagNameStyle}>
        {highlightCurrentFlagAsProhibited && isProhibitedFlagsPresent && (
          <Dot
            data-testid={
              customizationPageTestIds.customizationFlagStatusProhibitedFlagWarningDot
            }
            color="warning"
            css={dotStyles}
          />
        )}
        <Text
          css={customizationFlagText(
            highlightCurrentFlagAsProhibited,
            isProhibitedFlagsPresent
          )}
          color={isDisabled ? 'light' : 'default'}
          data-testid={dataTestId}
        >
          {flagName}
        </Text>
      </div>
    );
  }
);

const customizationFlagNameStyle = css`
  display: flex;
  align-items: center;
`;

const dotStyles = css`
  position: relative !important;
`;

const getMarginForCustomizationFlagText = (
  highlightCurrentFlagAsProhibited: boolean,
  isProhibitedFlagsPresent: boolean
) => {
  if (highlightCurrentFlagAsProhibited) {
    return theme.spacing(1.5);
  } else if (isProhibitedFlagsPresent) {
    return theme.spacing(3);
  }
  return 0;
};

const customizationFlagText = (
  highlightCurrentFlagAsProhibited: boolean,
  isProhibitedFlagsPresent: boolean
) => css`
  margin: 0;
  margin-left: ${getMarginForCustomizationFlagText(
    highlightCurrentFlagAsProhibited,
    isProhibitedFlagsPresent
  )};
`;
