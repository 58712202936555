import { useMemo } from 'react';
import { TagType } from '@weave/design-system';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { useResource } from '../../shared/hooks';
import { LocationModel, selectCurrentLocation } from '../../../redux/actions/location';
import { tagVariationsList } from '../template-tag-utils';
import { getTimezoneName, Timezone } from '../../../helpers/timezones';

// Customer Name
const firstNameTag: TagType = {
  label: 'First Name',
  key: tagVariationsList['{first_name}'],
  value: 'John',
};
const firstNamesTag: TagType = {
  label: 'First Name',
  key: tagVariationsList['{first_name}'].map((tag) => `[${tag}]`),
  value: 'Jonathan, Sherlock',
};
const lastNameTag: TagType = {
  label: 'Last Name',
  key: tagVariationsList['{last_name}'],
  value: 'Smith',
};
const preferredNameTag: TagType = {
  label: 'Preferred Name',
  key: tagVariationsList['{preferred_name}'],
  value: 'John',
};
const preferredNamesTag: TagType = {
  label: 'Preferred Name',
  key: tagVariationsList['{preferred_name}'].map((tag) => `[${tag}]`),
  value: 'Jon, Sherly',
};
const petNameTag: TagType = {
  label: 'Pet Name',
  key: tagVariationsList['{pet_name}'],
  value: 'Fluffy',
};
const petsNameTag: TagType = {
  label: "Pets' Names",
  key: tagVariationsList['{pet_name}'].map((tag) => `[${tag}]`),
  value: 'Fluffy, Crookshanks',
};

// Practice Info
const practiceNameTag = (location: LocationModel): TagType => ({
  label: 'Practice Name',
  key: tagVariationsList['{practice_name}'],
  value: location?.Name ?? '(Your office name)',
});
const practicePhoneTag = (location: LocationModel): TagType => ({
  label: 'Practice Phone',
  key: tagVariationsList['{practice_phone}'],
  value: location?.Phone ?? '555-555-5555',
});
const serviceProviderTag: TagType = {
  label: 'Service Provider',
  key: tagVariationsList['{service_provider}'],
  value: 'Dr. Weaver',
};
const locationTag: TagType = {
  label: 'Location',
  key: tagVariationsList['{location}'],
  value: 'Sample Office',
};

// Date and Times
const dateTimeTag = (fullDate: string, time: string): TagType => ({
  label: 'Date & Time',
  key: tagVariationsList['{appt_datetime}'],
  value: `${fullDate} at ${time}`,
});
const dateTag = (date: string): TagType => ({
  label: 'Date',
  key: tagVariationsList['{date}'],
  value: date,
});
const dateTimeEarlyTag = (date: string): TagType => ({
  label: 'Date & Time Early',
  key: tagVariationsList['{date_and_time_early}'],
  value: date,
});
const dateTimeWithTimezoneTag = (date: string): TagType => ({
  label: 'Date & Time With Timezone',
  key: tagVariationsList['{date_and_time_with_timezone}'],
  value: date,
});
const dayTag = (day: string): TagType => ({
  label: 'Day',
  key: tagVariationsList['{day}'],
  value: `${day}`,
});
const dayOfMonthTag = (dayOfMonth: string): TagType => ({
  label: 'Day of Month',
  key: tagVariationsList['{day_of_month}'],
  value: `${dayOfMonth}`,
});
const daysOverdueTag: TagType = {
  label: 'Days Overdue',
  key: tagVariationsList['{days_overdue}'],
  value: '10 days overdue',
};
const monthTag = (month: string): TagType => ({
  label: 'Month',
  key: tagVariationsList['{month}'],
  value: `${month}`,
});
const monthDayTag = (month: string, day: string): TagType => ({
  label: 'Month & Day',
  key: tagVariationsList['{month_and_day}'],
  value: `${month} ${day}`,
});
const timeTag = (time: string): TagType => ({
  label: 'Time',
  key: tagVariationsList['{time}'],
  value: `${time}`,
});
const timesTag: TagType = {
  label: 'Times',
  key: tagVariationsList['{time}'].map((key) => `[${key}]`),
  value: '7:00 AM, 7:15 AM, 7:25 AM',
};
const timeEarlyTag = (timeEarly: string): TagType => ({
  label: 'Time Early',
  key: tagVariationsList['{time_early}'],
  value: `${timeEarly}`,
});
const timeWithTimezoneTag = (formattedTimeWithTimezone: string): TagType => ({
  label: 'Time & Timezone',
  key: tagVariationsList['{time_with_timezone}'],
  value: formattedTimeWithTimezone,
});

// Other
const wellnessFormTag = (wellnessFormUrl: string): TagType => ({
  label: 'Wellness Form',
  key: tagVariationsList['{wellness_form}'],
  value: `${wellnessFormUrl}`,
});
const vaccineTag: TagType = {
  label: 'Vaccine',
  key: tagVariationsList['{vaccine}'],
  value: 'Rabies',
};
const scheduleLinkTag = (scheduleLink: string): TagType => ({
  label: 'Schedule Link',
  key: tagVariationsList['{schedule_link}'],
  value: `${scheduleLink}`,
});
const orderDatetimeTag = (date: string): TagType => ({
  label: 'Order Datetime',
  key: tagVariationsList['{order_datetime}'],
  value: date,
});
const orderTypeTag: TagType = {
  label: 'Order Type',
  key: tagVariationsList['{order_type}'],
  value: 'glasses',
};
const orderTypeTitleTag: TagType = {
  label: 'Order Type Title',
  key: tagVariationsList['{order_type_title}'],
  value: 'Glasses',
};

export const useTagLists = () => {
  const location = useSelector(selectCurrentLocation);

  const { data: isVet } = useResource<{ data: boolean }>({
    url: '/notifications/pet-templatable',
  });

  return useMemo(() => {
    const day = dayjs().format('dddd');
    const date = dayjs().format('MMM D, YYYY');
    const month = dayjs().format('MMM');
    const dayOfMonth = dayjs().format('D');
    const fullDate = dayjs().format('dddd, MMMM D');
    const time = dayjs().format('h:mm a');
    const timeEarly = dayjs().subtract(15, 'minute').format('h:mm a');
    const timezone = dayjs().format('Z');
    const formattedTimezoneName = getTimezoneName(timezone as Timezone);
    const formattedTimeWithTimezone = time.toLowerCase() + ' ' + formattedTimezoneName;
    const scheduleLink = `https://book.getweave.com/${location?.LocationID}/schedule`;
    const wellnessFormUrl = `https://book.getweave.com/${location?.LocationID}/wellness`;
    const singleTags: TagType[] = [
      firstNameTag,
      lastNameTag,
      preferredNameTag,
      practiceNameTag(location),
      practicePhoneTag(location),
      serviceProviderTag,
      locationTag,
      dateTimeTag(fullDate, time),
      dayTag(day),
      dayOfMonthTag(dayOfMonth),
      monthTag(month),
      monthDayTag(month, dayOfMonth),
      dateTimeWithTimezoneTag(
        `${day}, ${month} ${dayOfMonth} at ${time} ${formattedTimezoneName}`
      ),
      dateTimeEarlyTag(`${day}, ${month} ${dayOfMonth} at ${timeEarly}`),
      timeTag(time),
      timeEarlyTag(timeEarly),
      timeWithTimezoneTag(formattedTimeWithTimezone),
      wellnessFormTag(wellnessFormUrl),
    ];
    const multiTags: TagType[] = [
      firstNameTag,
      firstNamesTag,
      lastNameTag,
      preferredNameTag,
      preferredNamesTag,
      practiceNameTag(location),
      practicePhoneTag(location),
      serviceProviderTag,
      locationTag,
      dateTimeTag(fullDate, time),
      dayTag(day),
      dayOfMonthTag(dayOfMonth),
      monthTag(month),
      monthDayTag(month, dayOfMonth),
      dateTimeWithTimezoneTag(
        `${day}, ${month} ${dayOfMonth} at ${time} ${formattedTimezoneName}`
      ),
      dateTimeEarlyTag(`${day}, ${month} ${dayOfMonth} at ${timeEarly}`),
      timeTag(time),
      timeEarlyTag(timeEarly),
      timesTag,
      timeWithTimezoneTag(formattedTimeWithTimezone),
      wellnessFormTag(wellnessFormUrl),
    ];
    const saveTheDateTags: TagType[] = [
      firstNameTag,
      lastNameTag,
      preferredNameTag,
      practiceNameTag(location),
      practicePhoneTag(location),
      serviceProviderTag,
      locationTag,
      dateTimeTag(fullDate, time),
      dayTag(day),
      dayOfMonthTag(dayOfMonth),
      monthTag(month),
      monthDayTag(month, dayOfMonth),
      dateTimeWithTimezoneTag(
        `${day}, ${month} ${dayOfMonth} at ${time} ${formattedTimezoneName}`
      ),
      dateTimeEarlyTag(`${day}, ${month} ${dayOfMonth} at ${timeEarly}`),
      timeTag(time),
      timeEarlyTag(timeEarly),
      timeWithTimezoneTag(formattedTimeWithTimezone),
    ];
    const birthdayTags: TagType[] = [
      firstNameTag,
      lastNameTag,
      preferredNameTag,
      practiceNameTag(location),
      dateTimeTag(fullDate, time),
      dayTag(day),
      dayOfMonthTag(dayOfMonth),
      monthTag(month),
      monthDayTag(month, dayOfMonth),
      timeTag(time),
      timeEarlyTag(timeEarly),
    ];
    const recallTags: TagType[] = [
      firstNameTag,
      lastNameTag,
      preferredNameTag,
      practiceNameTag(location),
      practicePhoneTag(location),
      daysOverdueTag,
      dateTimeTag(fullDate, time),
      dayTag(day),
      dayOfMonthTag(dayOfMonth),
      monthTag(month),
      monthDayTag(month, dayOfMonth),
      dateTimeWithTimezoneTag(
        `${day}, ${month} ${dayOfMonth} at ${time} ${formattedTimezoneName}`
      ),
      dateTimeEarlyTag(`${day}, ${month} ${dayOfMonth} at ${timeEarly}`),
      timeTag(time),
      timeEarlyTag(timeEarly),
      timeWithTimezoneTag(formattedTimeWithTimezone),
    ];
    const recallTagsLink: TagType[] = [
      firstNameTag,
      lastNameTag,
      preferredNameTag,
      practiceNameTag(location),
      practicePhoneTag(location),
      daysOverdueTag,
      dateTimeTag(fullDate, time),
      dayTag(day),
      dayOfMonthTag(dayOfMonth),
      monthTag(month),
      monthDayTag(month, dayOfMonth),
      dateTimeWithTimezoneTag(
        `${day}, ${month} ${dayOfMonth} at ${time} ${formattedTimezoneName}`
      ),
      dateTimeEarlyTag(`${day}, ${month} ${dayOfMonth} at ${timeEarly}`),
      timeTag(time),
      timeEarlyTag(timeEarly),
      scheduleLinkTag(scheduleLink),
      timeWithTimezoneTag(formattedTimeWithTimezone),
    ];
    const eyewearTags: TagType[] = [
      firstNameTag,
      lastNameTag,
      preferredNameTag,
      practiceNameTag(location),
      dateTimeTag(fullDate, time),
      dayTag(day),
      dayOfMonthTag(dayOfMonth),
      monthTag(month),
      monthDayTag(month, dayOfMonth),
      dateTimeWithTimezoneTag(
        `${day}, ${month} ${dayOfMonth} at ${time} ${formattedTimezoneName}`
      ),
      dateTimeEarlyTag(`${day}, ${month} ${dayOfMonth} at ${timeEarly}`),
      timeTag(time),
      timeEarlyTag(timeEarly),
      timeWithTimezoneTag(formattedTimeWithTimezone),
      orderDatetimeTag(
        `${day}, ${month} ${dayOfMonth} at ${time} ${formattedTimezoneName}`
      ),
      orderTypeTag,
      orderTypeTitleTag,
    ];
    const massMessageTags: TagType[] = [
      firstNameTag,
      preferredNameTag,
      practiceNameTag(location),
      dateTag(date),
      timeTag(time),
      dateTimeTag(fullDate, time),
      dateTimeWithTimezoneTag(
        `${day}, ${month} ${dayOfMonth} at ${time} ${formattedTimezoneName}`
      ),
      timeWithTimezoneTag(formattedTimeWithTimezone),
      wellnessFormTag(wellnessFormUrl),
    ];
    if (isVet) {
      singleTags.push(petNameTag);
      singleTags.push(petsNameTag);
      recallTags.push(petNameTag);
      recallTags.push(petsNameTag);
      recallTags.push(vaccineTag);
      recallTagsLink.push(petNameTag);
      recallTagsLink.push(vaccineTag);
      multiTags.push(petNameTag);
      multiTags.push(petsNameTag);
      saveTheDateTags.push(petNameTag);
    }
    return {
      singleTags,
      multiTags,
      saveTheDateTags,
      birthdayTags,
      recallTags,
      recallTagsLink,
      eyewearTags,
      massMessageTags,
    };
  }, [location?.Name, location?.LocationID, location?.Phone, isVet]);
};
