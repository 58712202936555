import { css } from '@emotion/core';
import { ErrorBadgeIcon, ErrorIconSmall, Text } from '@weave/design-system';
import { theme } from '@weave/theme';
import { FC } from 'react';

export const CustomizationOutOfSyncBadge: FC = () => {
  return (
    <div css={badgeStyles}>
      <ErrorBadgeIcon color="error" size={14} />
      <Text css={{ margin: 0 }} size="small" color="error">
        Out of sync
      </Text>
    </div>
  );
};

const badgeStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  backgroundColor: theme.colors.critical10,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.borderRadius.small,
});
