import { css } from '@emotion/core';
import {
  Text,
  InfoIcon,
  Modal,
  IconButton,
  XIconSmall,
  useModalControl,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import React from 'react';
import { BillingAccount } from '../billing.types';

type Props = {
  account: BillingAccount;
};

export const AccountDetails = ({ account }: Props) => {
  return (
    <dl css={dlStyle}>
      <Text as="dt" weight="bold">
        Company Name
      </Text>
      <Text as="dd">{account.subscriberName}</Text>
      <Text as="dt" weight="bold">
        Name
      </Text>
      <Text as="dd">
        {account.primaryContact.firstName} {account.primaryContact.lastName}
      </Text>
      <Text as="dt" weight="bold">
        Email Address
      </Text>
      <Text as="dd">{account.primaryContact.email}</Text>
      <Text as="dt" weight="bold">
        Address
      </Text>
      <Text as="dd">
        {account.primaryContact.address1} {account.primaryContact.address2} <br />
        {account.primaryContact.city}, {account.primaryContact.state}
        {account.primaryContact.postalCode}
      </Text>
      <Text as="dt" weight="bold">
        Phone
      </Text>
      <Text as="dd">{account.primaryContact.phone}</Text>
    </dl>
  );
};

const dlStyle = css`
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: ${theme.spacing(0, 5, 7, 0)};

  --dt-width: 180px;

  dt {
    width: var(--dt-width);
    padding-right: ${theme.spacing(3)};
    margin: ${theme.spacing(2, 0)};
  }

  dd {
    width: calc(100% - var(--dt-width));
    word-break: break-word;
    margin: ${theme.spacing(2, 0)};
  }

  @media only screen and (min-width: 1400px) {
    flex-basis: 40%;
    margin-bottom: 0;
  }
`;
