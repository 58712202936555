import { MailboxGreeting } from '@weave/schema-gen-ts/dist/schemas/phone/voicemail-config/v2/voicemail_config.pb';

export interface Mailbox {
  mailboxID: string;
  locationID: string;
  isGeneral: boolean;
  name: string;
  sendNotification: boolean;
  email: string[];
  mobileNumber: string[];
  attachFile: boolean;
  pin: string;
  number: string;
  playMsgDate: boolean;
  createdAt: string;
  updatedAt: string;
}
export interface Greeting extends MailboxGreeting {}

export interface GreetingMap {
  greetingNumber: number;
  scheduleName: string;
}

export interface VoiceMailboxesState {
  activeMailboxId: string;
  greetings: Greeting[];
  greetingMaps: GreetingMap[];
  loadingGreetings: boolean;
  loadingGreetingMaps: boolean;
  mailboxes: Mailbox[];
  updatingGreetings: boolean;
  allMailboxGreetings: Greeting[];
  allMailboxGreetingsLoading: boolean;
}

export enum VoicemailBoxesActionTypes {
  RequestListMailboxes = 'REQUEST_LIST_MAILBOXES',
  RequestUpdateMailbox = 'REQUEST_UPDATE_MAILBOX',

  ClearGreetings = 'CLEAR_GREETINGS',
  DeleteGreeting = 'DELETE_GREETING',
  DeleteGreetingSuccess = 'DELETE_GREETING_SUCCESS',
  DeleteGreetingFailure = 'DELETE_GREETING_FAILURE',
  GetGreetingMaps = 'GET_GREETING_MAPS',
  GetGreetingMapsSuccess = 'GET_GREETING_MAPS_SUCCESS',
  GetGreetingMapsFailure = 'GET_GREETING_MAPS_FAILURE',
  GetMailboxesSuccess = 'GET_MAILBOXES_SUCCESS',
  GetMailboxesFailure = 'GET_MAILBOXES_FAILURE',
  GetGreetingsForMailbox = 'GET_GREETINGS_FOR_MAILBOX',
  GetGreetingsForMailboxSuccess = 'GET_GREETINGS_FOR_MAILBOX_SUCCESS',
  GetGreetingsForMailboxFailure = 'GET_GREETINGS_FOR_MAILBOX_FAILURE',
  UpdateActiveMailboxId = 'UPDATE_ACTIVE_MAILBOX_ID',
  UpdateMailbox = 'UPDATE_MAILBOX',
  UpdateGreetings = 'UPDATE_GREETINGS',
  UpdateGreetingSuccess = 'UPDATE_GREETING_SUCCESS',
  UpdateGreetingFailure = 'UPDATE_GREETING_FAILURE',
  GetGreetingsForAllMailboxes = 'GET_GREETINGS_FOR_ALL_MAILBOXES',
  GetGreetingsForAllMailboxesSuccess = 'GET_GREETINGS_FOR_ALL_MAILBOXES_SUCCESS',
  GetGreetingsForAllMailboxesFailure = 'GET_GREETINGS_FOR_ALL_MAILBOXES_FAILURE',
  UploadGreeting = 'UPLOAD_GREETING',
  UploadGreetingSuccess = 'UPLOAD_GREETING_SUCCESS',
  UploadGreetingFailure = 'UPLOAD_GREETING_FAILURE',
  UploadGreetingConversion = 'UPLOAD_GREETING_CONVERSION',
  UploadGreetingConversionSuccess = 'UPLOAD_GREETING_CONVERSION_SUCCESS',
  UploadGreetingConversionFailure = 'UPLOAD_GREETING_CONVERSION_FAILURE',
}

export type RequestListMailboxesAction = {
  type: VoicemailBoxesActionTypes.RequestListMailboxes;
  payload: undefined;
};

export type RequestUpdateMailboxAction = {
  type: VoicemailBoxesActionTypes.RequestUpdateMailbox;
  payload: Mailbox;
};

export type UpdateMailboxAction = {
  type: VoicemailBoxesActionTypes.UpdateMailbox;
  payload: Mailbox;
};

export type VoicemailBoxesActions =
  | RequestListMailboxesAction
  | RequestUpdateMailboxAction
  | UpdateMailboxAction;
