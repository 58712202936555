import { HandoffTypeMetric } from '../handoff.constants';
import { DependencyDataKeys, Result } from '../handoff-metrics.types';

export const TOTAL_DIGITIZED_FORMS = 1;
export const TOTAL_DIGITAL_FORMS_SUBMITTED = 1;
export const HAS_DIGITAL_FORMS_SUBMITTED = 'True';
export const TOTAL_DIGITAL_FORMS_SENT = 1;
export const HAS_DIGITAL_FORMS_DIGITIZED = 'True';

export const digitalFormsDigitizedFormMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `Digital form submissions should be greater than ${this.expectedValue}`;
  },
  dependencyDataKeys: [DependencyDataKeys.SubmittedDigitizationForms],
  expectedValue: TOTAL_DIGITAL_FORMS_SUBMITTED,
  actualValueFn: (dependentData) => {
    const submittedDigitizationFormsQuery =
      dependentData[DependencyDataKeys.SubmittedDigitizationForms];
    const err = submittedDigitizationFormsQuery?.error;
    if (err) return err.message;
    const submissions = submittedDigitizationFormsQuery?.data;

    let count = 0;
    submissions?.forEach((submission) => {
      submission.files?.forEach(() => {
        count += 1;
      });
    });

    return count;
  },
  resultFn: function (dependentData) {
    const submittedDigitizationFormsQuery =
      dependentData[DependencyDataKeys.SubmittedDigitizationForms];
    const err = submittedDigitizationFormsQuery?.error;
    if (err) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);

    if (actualValue >= this.expectedValue) return Result.Pass;
    return Result.Error;
  },
  exceptions: [
    `Office after multiple attempts did not upload forms`,
    `Self implemented`,
    'Office still gathering forms to be digitized',
  ],
};

export const digitalFormsCountMetric: HandoffTypeMetric = {
  testCriteriaFn: function () {
    return `At least ${this.expectedValue} digital form(s) should be created`;
  },
  dependencyDataKeys: [DependencyDataKeys.DigitalForms],
  expectedValue: TOTAL_DIGITIZED_FORMS,
  actualValueFn: (dependentData) => {
    const digitalFormsQuery = dependentData[DependencyDataKeys.DigitalForms];
    const err = digitalFormsQuery?.error;
    if (err) return err.message;

    const digitalForms = digitalFormsQuery?.data;
    if (!digitalForms) return 'no data';

    return digitalForms.length;
  },
  resultFn: function (dependentData) {
    const digitalFormsQuery = dependentData[DependencyDataKeys.DigitalForms];
    const err = digitalFormsQuery?.error;
    if (err) return Result.Error;

    const actualValue = this.actualValueFn(dependentData);
    if (actualValue >= this.expectedValue) return Result.Pass;

    return Result.Fail;
  },
  exceptions: [
    `Office after multiple attempts did not upload forms`,
    `Self implemented`,
    'Office still gathering forms to be digitized',
    'Office just recently submitted forms, still being built',
  ],
};
