import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAlert } from '@weave/alert-system';
import { useResources } from '../../../helpers/useResources';
import { selectCurrentLocationId } from '../../../redux/actions/location/current-location';
import { Page } from '../../shared';
import { Settings } from './helpers/types';
import { ReviewsSettings, Setup } from './components';
import { AccountStatus, ExtendedAccountStatus } from './helpers';
import { CustomizationFlagStatesV2 } from '../../../models/location-feature.model';
import { getCustomizationsFlagsByLocationIdURL } from 'redux/actions/customization/customization.action';
import { CustomizationFlagsLocationFeaturesResponse } from 'redux/actions/customization/customization.types';

export const Reviews = () => {
  const locationId = useSelector(selectCurrentLocationId);

  const {
    data: settingsData,
    loading: settingsLoading,
    refresh: settingsRefresh,
  } = useResources<Settings | {}>('support/v1/reviews/settings', {
    deps: [locationId],
    suppressFetch: !locationId,
    resourceLabel: 'settings',
  });
  const settings = settingsData as Settings;

  const customizationFlagsLocationFeaturesURL =
    getCustomizationsFlagsByLocationIdURL(locationId);

  const {
    data: featuresData,
    loading: featuresLoading,
    refresh: featuresRefresh,
  } = useResources<CustomizationFlagsLocationFeaturesResponse>(
    customizationFlagsLocationFeaturesURL,
    {
      deps: [locationId],
      suppressFetch: !locationId,
      resourceLabel: 'features',
    }
  );

  const alerts = useAlert();
  const loading = settingsLoading || featuresLoading;
  const reviewsFeatureId = 'ff764a4b-31a0-482d-b0d2-72b4dad92385';
  const reviewFeature = featuresData?.features?.find(
    (feature) => feature.id === reviewsFeatureId
  );
  const isReviewsFeatureOn = reviewFeature?.state === CustomizationFlagStatesV2.ACTIVE;
  const isSettingsDataEmpty = Object.keys(settings).length === 0;
  const isReviewsSetup =
    !isSettingsDataEmpty && settings.accountStatus === AccountStatus.Active;

  const getExtendedAccountStatus = () => {
    if (isSettingsDataEmpty) {
      return ExtendedAccountStatus.NotSetup;
    }

    const populatedSettingsData = settings;
    if (populatedSettingsData.accountStatus === null) {
      return ExtendedAccountStatus.Error;
    }

    if (populatedSettingsData.accountStatus === AccountStatus.Active) {
      return ExtendedAccountStatus.Active;
    }

    return ExtendedAccountStatus.Archived;
  };

  const refresh = () => {
    settingsRefresh();
    featuresRefresh();
  };

  const checkErrors = (prop: any, message: string) => {
    if (prop === null) {
      alerts.error(message);
    }
  };

  useEffect(() => {
    if (isSettingsDataEmpty) {
      return;
    }
    checkErrors(settings.accountStatus, 'Error fetching review account status!');
    checkErrors(settings.autoSend, 'Error fetching auto send preference!');
    checkErrors(settings.autoSendDelay, 'Error fetching auto send delay preference!');
    checkErrors(settings.mappings, 'Error fetching review site mappings!');
    checkErrors(
      settings.notificaitonSettingID,
      'Error fetching notification setting ID!'
    );
    checkErrors(settings.siteOrder, 'Error fetching site order!');
    checkErrors(settings.solicitationURLs, 'Error fetching solicitation URLs!');
    checkErrors(settings.template, 'Error fetching solicitation template!');
  }, [isSettingsDataEmpty, settings]);

  return (
    <Page title="Reviews" loading={loading}>
      {!loading && (!isReviewsFeatureOn || !isReviewsSetup) && (
        <Setup
          onSetup={refresh}
          accountStatus={getExtendedAccountStatus()}
          isReviewsFeatureOn={isReviewsFeatureOn}
        />
      )}
      {!loading && isReviewsFeatureOn && isReviewsSetup && (
        <ReviewsSettings settings={settings} />
      )}
    </Page>
  );
};
