import { all } from 'redux-saga/effects';
import {
  downloadBillSaga,
  downloadLOASaga,
  getPortingDataSaga,
  getPortingStatusSaga,
  submitPortingSaga,
  updatePortingDataSaga,
  checkPortabilitySaga,
  submitSplitPortingRequestsSaga,
  deletePortingRequestSaga,
  errorCodeDataSaga,
} from './porting.action';

export const portingSaga = function* () {
  yield all([
    getPortingDataSaga(),
    downloadBillSaga(),
    downloadLOASaga(),
    getPortingStatusSaga(),
    submitPortingSaga(),
    updatePortingDataSaga(),
    checkPortabilitySaga(),
    submitSplitPortingRequestsSaga(),
    deletePortingRequestSaga(),
    errorCodeDataSaga(),
  ]);
};

export {
  getPortingData,
  downloadBill,
  downloadLOA,
  getPortingStatus,
  clearPortingStatus,
  submitPorting,
  updatePortingData,
  setPortingDetails,
  clearSubmitPortingDetails,
  checkPortability,
  resetPortabilityCheck,
  submitSplitPortingRequests,
  deletePortingRequest,
} from './porting.action';
export { portingReducer } from './porting.reducer';
