import { css } from '@emotion/core';
import { ErrorBadgeIcon, Text, useModalControl } from '@weave/design-system';
import { theme } from '@weave/theme';
import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import * as Paths from '../../constants/paths';
import { WamSyncStatusResponse } from '../../apis/wam-sync/wam-sync.types';

export const WamSyncAlert: FC<{
  wamSyncStatus: WamSyncStatusResponse;
  locationId: string;
}> = ({ wamSyncStatus, locationId }) => {
  const locationModalControls = useModalControl();
  const history = useHistory();

  const isCustomizationPath = useMemo(() => {
    return (
      history.location.pathname ===
      Paths.injectParams(Paths.OTHER.customization, { id: locationId })
    );
  }, [history.location.pathname, locationId]);

  const handleNavigateToCustomization = (locationId: string) => {
    history.push(Paths.injectParams(Paths.OTHER.customization, { id: locationId }));
    locationModalControls.closeModal();
  };

  if (!wamSyncStatus?.customizationDetails?.length) {
    return null;
  }

  return (
    <>
      <div css={alertBannerStyles}>
        <ErrorBadgeIcon color="error" />
        <Text size="medium" css={{ margin: 0 }}>
          Some customizations are out of sync.{' '}
          {!isCustomizationPath && (
            <>
              Fix it on the{' '}
              <u
                css={{ cursor: 'pointer' }}
                onClick={() => handleNavigateToCustomization(locationId)}
              >
                Customization page
              </u>
            </>
          )}
        </Text>
      </div>
    </>
  );
};

const alertBannerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  border: `1px solid ${theme.colors.critical50}`,
  borderRadius: theme.borderRadius.medium,
  backgroundColor: theme.colors.critical10,
});
