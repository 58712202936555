import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { Loader } from '../../shared/loader/loader.component';
import { LocationModel } from '../../../redux/actions/location';
import { formatDateFromUnixTimestamp } from '../../../helpers/utils';
import { Table } from 'reactstrap';
import { PortingCard } from '../porting-card/porting-card.component';
import { SuccessSVG } from './success-svg';
import { PhoneNumberLookup } from './phone-number-lookup';
import { portingHubStyles } from './porting-hub.styles';
import moment from 'moment';
import {
  PrimaryButton,
  SecondaryButton,
  Modal,
  Text,
  useHint,
} from '@weave/design-system';
import { Provider } from '../porting-card/constants';
import { PortOrder } from '../port-order/port-order.component';
import { SalesforcePortOrderForm } from './salesforce-port-order-form.component';
import { PortStatus, PortStatusMapping } from '../utils/port-constants';
import { NumberType, PortGetData, PortingRequest } from 'apis/porting/porting.types';
import { SearchPortingData } from './search-porting-data.component';
import { theme } from '@weave/theme-original';
import { Page } from '../../shared';
import { useHistory } from 'react-router-dom';
import { ONBOARDING, injectParams } from '../../../constants/paths';
import { useDebugMode } from '../../../components/debug/use-debug-mode';

interface Props {
  ports: any[];
  loading: boolean;
  portingDetails: any;
  currentPortStatus: any;
  submitPortingDetails: any;
  portStatusLoading: boolean;
  splitPortingRequest: number;
  downloadLOALoading: boolean;
  downloadBillLoading: boolean;
  submitPortingLoading: boolean;
  splitPortingRequestsResults: any;
  updatePortingDetailsLoading: boolean;
  deletePortingRequestLoading: boolean;
  currentLocation: LocationModel | null;
  getPortingData: () => void;
  downloadLOA: (port) => void;
  downloadBill: (port) => void;
  submitPorting: (port) => void;
  clearPortingStatus: () => void;
  getPortingStatus: (port) => void;
  checkPortability: (port) => void;
  resetPortabilityCheck: () => void;
  updatePortingData: (port) => void;
  setPortingDetails: (port) => void;
  clearSubmitPortingDetails: () => void;
  deletePortingRequest: (id: string) => void;
  submitSplitPortingRequests: (port) => void;
}

export const PortingHub = (props: Props) => {
  const {
    ports,
    loading,
    portingDetails,
    currentLocation,
    currentPortStatus,
    portStatusLoading,
    downloadLOALoading,
    downloadBillLoading,
    splitPortingRequest,
    submitPortingDetails,
    submitPortingLoading,
    updatePortingDetailsLoading,
    splitPortingRequestsResults,
    deletePortingRequestLoading,
    downloadLOA,
    downloadBill,
    getPortingData,
    checkPortability,
    getPortingStatus,
    setPortingDetails,
    updatePortingData,
    clearPortingStatus,
    deletePortingRequest,
    resetPortabilityCheck,
    clearSubmitPortingDetails,
    submitSplitPortingRequests,
  } = props;

  const { isDebugModeEnabled } = useDebugMode();
  const [currentPortStatusProvider, setCurrentPortStatusProvider] = useState<string>('');
  const [showPortOrderForm, setShowPortOrderForm] = useState<boolean>(false);
  const [searchPortResults, setSearchPortResults] = useState<PortGetData[]>([]);

  useEffect(() => {
    getPortingData();
    setPortingDetails(null);
    if (portingDetails) {
      const resyncedPort = ports.find((port) => port.id === portingDetails.id);
      /*
        then reset the portingDetails data with the synced version of it but only if it
        has porting_request data.
      */
      if (resyncedPort?.porting_requests?.length) {
        setPortingDetails(resyncedPort);
      } else {
        setPortingDetails(null);
      }
    }
  }, []);

  const getStatusColor = (portingRequest) => {
    if (portingRequest.porting_status) {
      const status: number = portingRequest.porting_status;
      if (
        [PortStatus.accepted, PortStatus.requestedFOC, PortStatus.completed].includes(
          status
        )
      ) {
        return '#2BD53C';
      } else if (
        [
          PortStatus.exception,
          PortStatus.rejected,
          PortStatus.validationFailed,
          PortStatus.invalidNumbers,
        ].includes(status)
      ) {
        return '#FF6B59';
      }
    }
    return '#FFB92E';
  };

  const showReviewButtonStatus = [
    PortStatus.unknown,
    PortStatus.draft,
    PortStatus.validationFailed,
  ];

  const handlePortOrderBackClick = (): void => {
    setPortingDetails(null);
    getPortingData();
    setShowPortOrderForm(false);
  };

  useEffect(() => {
    setSearchPortResults(ports);
  }, [ports]);

  const history = useHistory();
  const goToV2 = () => {
    history.push(injectParams(ONBOARDING.portingV2, { id: currentLocation?.LocationID }));
  };

  if (!currentLocation) {
    return null;
  }
  if (loading) {
    return (
      <div className={portingHubStyles.loading}>
        <Loader />
      </div>
    );
  }
  if (portingDetails) {
    return (
      <PortingCard
        port={portingDetails}
        back={handlePortOrderBackClick}
        splitPortingRequest={splitPortingRequest}
        updatePortingDetails={updatePortingData}
        updatePortingDetailsLoading={updatePortingDetailsLoading}
        downloadLOALoading={downloadLOALoading}
        downloadBillLoading={downloadBillLoading}
        submitPortingLoading={submitPortingLoading}
        downloadBill={downloadBill}
        downloadLOA={downloadLOA}
        setPortingDetails={setPortingDetails}
        submitPortingDetails={submitPortingDetails}
        clearSubmitPortingDetails={clearSubmitPortingDetails}
        checkPortability={checkPortability}
        resetPortabilityCheck={resetPortabilityCheck}
        submitSplitPortingRequests={submitSplitPortingRequests}
        splitPortingRequestsResults={splitPortingRequestsResults}
        deletePortingRequest={deletePortingRequest}
        deletePortingRequestLoading={deletePortingRequestLoading}
        getStatusColor={getStatusColor}
      />
    );
  }

  const handleCreatePortOrder = (portingData) => {
    setShowPortOrderForm(false);
    setPortingDetails(portingData);
  };

  const handleCheckStatus = (port) => {
    const provider: number = port?.provider || 0;
    if (provider === 0 || !Provider.has(provider)) {
      setCurrentPortStatusProvider('Provider');
    } else {
      setCurrentPortStatusProvider(Provider.get(provider) || 'Provider');
    }
    getPortingStatus(port);
  };

  const getStatusForPorting = (portingRequests) => {
    const filteredPortRequest: PortingRequest[] = portingRequests.filter(
      (request) => request?.porting_status !== PortStatus.delete
    ); /* if the status is deleted don't show it, show the status of next porting_request */

    return filteredPortRequest[0]?.porting_status
      ? PortStatusMapping[filteredPortRequest[0]?.porting_status]
      : 'Draft';
  };

  return showPortOrderForm ? (
    <PortOrder
      currentLocation={currentLocation}
      back={handlePortOrderBackClick}
      handleCreatePortOrder={handleCreatePortOrder}
      setShowPortOrderForm={(show) => setShowPortOrderForm(show)}
    />
  ) : (
    <Page
      title={`Port Orders`}
      headerActions={
        <div className={portingHubStyles.portContainerButtons}>
          <div className={portingHubStyles.newPortOrder}>
            <PrimaryButton color="blue" onClick={() => setShowPortOrderForm(true)}>
              New Port Order
            </PrimaryButton>
          </div>
          <div className={portingHubStyles.portRefresh}>
            <PrimaryButton color="blue" onClick={getPortingData}>
              Refresh
            </PrimaryButton>
          </div>
          {isDebugModeEnabled && (
            <div className={portingHubStyles.portRefresh}>
              <PrimaryButton color="blue" onClick={goToV2}>
                Go To V2
              </PrimaryButton>
            </div>
          )}
        </div>
      }
      subtitle="View all port orders for this location below"
    >
      <div className={portingHubStyles.portContainer}>
        <SalesforcePortOrderForm getPortingData={getPortingData} />
        <SearchPortingData ports={ports} setSearchPortResult={setSearchPortResults} />
      </div>
      <div
        css={css`
          margin-right: 20px;
        `}
      >
        {/*// @ts-ignore */}
        <Table borderless="true" className={portingHubStyles.tableView}>
          <thead>
            <tr className={portingHubStyles.tableHeadRow}>
              <th
                css={css`
                  width: 375px;
                `}
              >
                Port Order Number
              </th>
              <th>Created Date</th>
              <th>Port Type</th>
              <th>Requested Date</th>
              <th>FOC Date</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>

          <tbody className={portingHubStyles.tableBody}>
            {searchPortResults?.map((port, index) => {
              const portingRequest = port?.porting_requests?.[0];
              if (!portingRequest) {
                return null;
              }

              return (
                <tr key={index}>
                  {/* Port Order Number */}
                  <td
                    css={css`
                      width: 375px;
                      margin-top: ${theme.spacing(2)};
                    `}
                  >
                    <div
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {portingRequest.port_order_number}
                    </div>
                  </td>

                  {/* Created Date */}
                  <td>
                    <div
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {portingRequest.created_at &&
                        moment(portingRequest.created_at).utc().format('MM/DD/YY')}
                    </div>
                  </td>

                  {/* Port Type */}
                  <td>
                    <div
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {portingRequest.number_type === NumberType.PHONE
                        ? 'Voice/SMS'
                        : 'Fax'}
                    </div>
                  </td>

                  {/* Requested Date */}
                  <td>
                    <div
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {portingRequest.requested_firm_order_commitment_date
                        ? moment(portingRequest.requested_firm_order_commitment_date)
                            .utc()
                            .format('MM/DD/YY')
                        : ''}
                    </div>
                  </td>

                  {/*FOC Date */}
                  <td>
                    <div
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {portingRequest.firm_order_commitment_date
                        ? moment(portingRequest.firm_order_commitment_date)
                            .utc()
                            .format('MM/DD/YY')
                        : ''}
                    </div>
                  </td>

                  {/* Status */}
                  <td className={portingHubStyles.dotColumn}>
                    <div
                      css={(theme) => css`
                        width: 8px;
                        height: 8px;
                        margin: ${theme.spacing(1)};
                        border: 1px solid ${getStatusColor(portingRequest)};
                        background-color: ${getStatusColor(portingRequest)};
                        border-radius: 50%;
                      `}
                    />
                    <p
                      css={css`
                        margin-top: ${theme.spacing(2)};
                      `}
                    >
                      {getStatusForPorting(port?.porting_requests)}
                    </p>
                  </td>

                  {/* Actions */}
                  <td>
                    <div className={portingHubStyles.tableActions}>
                      {portingRequest.porting_status &&
                      !showReviewButtonStatus.includes(portingRequest.porting_status) ? (
                        <>
                          {portingRequest.porting_status !== PortStatus.cancelled && (
                            <div className={portingHubStyles.portContainerButtons}>
                              <div className={portingHubStyles.newPortOrder}>
                                <PrimaryButton
                                  color="blue"
                                  onClick={() => handleCheckStatus(port)}
                                >
                                  Check Status
                                </PrimaryButton>
                              </div>
                            </div>
                          )}
                          <div
                            className={portingHubStyles.portRefresh}
                            css={css`
                              text-align: center;
                              cursor: pointer;
                              margin-top: ${theme.spacing(2)};
                            `}
                            onClick={() => {
                              setPortingDetails(port);
                            }}
                          >
                            View Info
                          </div>
                        </>
                      ) : (
                        <div className={portingHubStyles.portContainerButtons}>
                          <div className={portingHubStyles.newPortOrder}>
                            <SecondaryButton
                              className={portingHubStyles.reviewButton}
                              color="gray"
                              onClick={() => {
                                setPortingDetails(port);
                              }}
                            >
                              Review
                            </SecondaryButton>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {!searchPortResults.length && (
        <Text
          css={css`
            text-align: center;
          `}
        >
          No Result Found
        </Text>
      )}

      {ports?.length === 0 && (
        <div
          css={css`
            text-align: center;
            margin-bottom: 14px;
          `}
        >
          There are no active ports for this location.
        </div>
      )}
      <PhoneNumberLookup />
      <Modal
        onClose={clearPortingStatus}
        css={(theme) =>
          css`
            padding: ${theme.spacing(2)};
          `
        }
        show={portStatusLoading || (!portStatusLoading && !!currentPortStatus) || false}
      >
        {portStatusLoading ? (
          <div>
            <div className={portingHubStyles.loading}>
              <Loader size="large" />
            </div>
            <div className={portingHubStyles.pingingBandwidth}>
              Pinging {currentPortStatusProvider}...
            </div>
          </div>
        ) : (
          <div>
            <div className={portingHubStyles.loading}>
              <SuccessSVG />
            </div>
            <div className={portingHubStyles.statusComplete}>Status Check Complete!</div>
            {currentPortStatus && (
              <div className={portingHubStyles.progress}>
                {currentPortStatus.progress_status}
              </div>
            )}

            {currentPortStatus && (
              <div className={portingHubStyles.portStatusStyle}>
                {currentPortStatus.order_histories &&
                  currentPortStatus.order_histories.map((orderHistory, index) => {
                    if (
                      index === currentPortStatus.order_histories?.length - 1 ||
                      index === currentPortStatus.order_histories?.length - 2
                    ) {
                      return (
                        <div
                          key={index}
                          css={css`
                            margin-bottom: 10px;
                          `}
                        >
                          <span
                            css={css`
                              font-weight: bold;
                            `}
                          >
                            {formatDateFromUnixTimestamp(
                              orderHistory.order_date,
                              'MM/dd/yy'
                            )}
                            :
                          </span>{' '}
                          {orderHistory.note}
                        </div>
                      );
                    }
                    return null;
                  })}

                {currentPortStatus.order_notes &&
                  currentPortStatus.order_notes.map((orderNote, index) => {
                    if (
                      index === currentPortStatus.order_notes?.length - 1 ||
                      index === currentPortStatus.order_notes?.length - 2
                    ) {
                      return (
                        <div
                          key={index}
                          css={css`
                            margin-bottom: 10px;
                          `}
                        >
                          <span
                            css={css`
                              font-weight: bold;
                            `}
                          >
                            {formatDateFromUnixTimestamp(
                              orderNote.last_date_modifier,
                              'MM/dd/yy'
                            )}
                            :
                          </span>{' '}
                          {orderNote.description}
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
            )}
            <PrimaryButton
              className={portingHubStyles.gotItButton}
              color="blue"
              size="large"
              onClick={() => {
                clearPortingStatus();
              }}
            >
              Got it
            </PrimaryButton>
          </div>
        )}
      </Modal>
    </Page>
  );
};
