import React from 'react';
import { css, cx } from 'emotion';
import {
  Delivery,
  SendBefore,
  SendBeforeTimePeriod,
} from '../../../../redux/actions/notifications-settings/notification-settings.types';
import { FilterToggle } from '../../../shared/filter-toggle/filter-toggle.component';
import { NotificationTypes } from 'models/recall-types.models';

const grid = css`
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: 1fr 3fr 3fr 1fr 2fr;
`;

const hide = css`
  visibility: hidden;
`;

const center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  type: string;
  send_before: SendBefore;
  delivery: Delivery;
  updateSendBefore: (send_before: SendBefore) => void;
  updateDelivery: (delivery: Delivery) => void;
}

export class EditReminderDateTime extends React.Component<Props> {
  state = {
    beforeOrAfter: this.props.send_before.value >= 0 ? 'before' : 'after',
  };

  changeSendBefore = (event) => {
    const send_before = { ...this.props.send_before };
    const value =
      this.state.beforeOrAfter === 'before'
        ? Math.abs(event.target.value)
        : Math.abs(event.target.value) * -1;

    send_before.value = value;
    this.props.updateSendBefore(send_before);
  };

  handleTimePeriod = (time: SendBeforeTimePeriod) => () => {
    const send_before = { ...this.props.send_before };
    send_before.type = time;
    this.props.updateSendBefore(send_before);
  };

  setTimeBeforeOrAfter = (beforeOrAfter) => () => {
    this.setState({ beforeOrAfter });
    const send_before = { ...this.props.send_before };
    const value =
      beforeOrAfter === 'before'
        ? Math.abs(send_before.value)
        : Math.abs(send_before.value) * -1;

    send_before.value = value;
    this.props.updateSendBefore(send_before);
  };

  handleTimeChange = (event) => {
    const delivery = { ...this.props.delivery };
    const [hours, minutes] = event.target.value.split(':');
    const time =
      hours > 12 ? `${Number(hours) - 12}:${minutes}PM` : `${hours}:${minutes}AM`;
    delivery.time = time;
    this.props.updateDelivery(delivery);
  };
  formatTime = (timeString) => {
    const time = timeString.substr(0, timeString.length - 2);
    const meridiem = timeString.substr(-2);

    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(':');

    if (meridiem === 'PM') {
      hours = Number(hours) + 12;
    }
    return `${hours}:${minutes}`;
  };

  render() {
    const { send_before, delivery } = this.props;
    const formattedTime = this.formatTime(delivery.time);

    const beforeAfterButtons = [
      {
        displayText: 'before',
        clickFunction: this.setTimeBeforeOrAfter('before'),
      },
      {
        displayText: 'after',
        clickFunction: this.setTimeBeforeOrAfter('after'),
      },
    ];

    let hourDaysButtons = [
      {
        displayText: 'hours',
        clickFunction: this.handleTimePeriod('hours'),
      },
      {
        displayText: 'days',
        clickFunction: this.handleTimePeriod('days'),
      },
    ];

    const weeksMonthsButtons = [
      {
        displayText: 'weeks',
        clickFunction: this.handleTimePeriod('weeks'),
      },
      {
        displayText: 'months',
        clickFunction: this.handleTimePeriod('months'),
      },
    ];

    if (
      this.props.type === NotificationTypes['schedule-reactivation'] ||
      this.props.type === NotificationTypes.order
    ) {
      hourDaysButtons = [
        ...hourDaysButtons,
        { displayText: 'weeks', clickFunction: this.handleTimePeriod('weeks') },
      ];
    }

    const timePeriod =
      this.props.type === 'recall' ? weeksMonthsButtons : hourDaysButtons;

    return (
      <React.Fragment>
        <span>Send Time:</span>
        <div className={grid}>
          <input
            className={css`
              min-width: 45px;
            `}
            type="number"
            name="quantity"
            min="-100"
            max="100"
            value={Math.abs(send_before.value)}
            onChange={this.changeSendBefore}
          />
          <FilterToggle
            filterButtons={timePeriod}
            activeFilterButton={send_before.type}
          />
          {this.props.type !== 'order' && (
            <FilterToggle
              filterButtons={beforeAfterButtons}
              activeFilterButton={this.state.beforeOrAfter}
            />
          )}
          <span className={cx(center, send_before.type !== 'hours' ? '' : hide)}>at</span>
          <input
            className={send_before.type !== 'hours' ? '' : hide}
            type="time"
            name="usr_time"
            defaultValue={formattedTime}
            disabled={send_before.type === 'hours'}
            onChange={this.handleTimeChange}
          />
        </div>
        <br />
      </React.Fragment>
    );
  }
}
