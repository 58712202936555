import { debounce } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

export const useDebouncedValue = <T>(value: T, time_in_ms: number) => {
  const oldValue = useRef(value);

  const debouncedSetter = useCallback(
    debounce((newValue) => (oldValue.current = newValue), time_in_ms, {
      leading: true,
      trailing: true,
    }),
    []
  );

  useEffect(() => {
    debouncedSetter(value);
  }, [value]);

  return oldValue.current;
};
