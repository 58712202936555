import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { LocationGroupChildInfo } from '../../../apis/location-provision/types';
import { LocationGroupName } from './location-group-name';
import { LocationsTable } from '../locations-table';
import { IntakeFormLink } from '../intake-form-link';

type Props = {
  name: string;
  locationId?: string;
  locations: LocationGroupChildInfo[];
  isDefaultGroup?: boolean;
  isSingleLocationGroup?: boolean;
  onSelect: (slugList: string[]) => void;
  onNameChange: (name: string, successCallback: () => void) => void;
};

export const LocationGroupCard = ({
  name,
  locationId,
  locations,
  onSelect,
  onNameChange,
  isDefaultGroup = false,
  isSingleLocationGroup = false,
}: Props) => {
  return (
    <div css={containerStyle(isDefaultGroup)}>
      <div css={headingGroup}>
        <LocationGroupName
          name={name}
          editable={!isSingleLocationGroup && !locationId}
          onNameChange={onNameChange}
        />
        {locationId && !isSingleLocationGroup && (
          <IntakeFormLink locationId={locationId} />
        )}
      </div>
      <LocationsTable
        locations={locations}
        isSingleLocationTable={isSingleLocationGroup}
        onSelect={onSelect}
      />
    </div>
  );
};

const containerStyle = (isDefaultGroup: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    border: `1px ${isDefaultGroup ? 'solid' : 'dashed'} ${theme.colors.neutral30}`,
    borderRadius: theme.borderRadius.medium,
    width: isDefaultGroup ? '100%' : `calc(50% - ${theme.spacing(1.5)})`,
  });

const headingGroup = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
