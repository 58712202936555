//if you happen to change any mappings, please make same changes in PortStatusMapping constant and PortStatus enum. These should have same constant values.
export const PortStatusMapping = {
  0: 'Unknown',
  1: 'Draft',
  2: 'Pending',
  3: 'Submitted',
  4: 'Rejected', // red
  5: 'Rejected Resolved',
  6: 'Resubmitted',
  7: 'Sup Pending',
  8: 'Sup Submitted',
  9: 'Cancel Pending',
  10: 'Cancel Submitted',
  11: 'Cancelled', // no dot color, just italics this status
  12: 'Accepted', // green
  13: 'Completed', // green
  14: 'Pending Documents',
  15: 'Exception', //red
  16: 'Requested SUP',
  17: 'Requested Cancel',
  18: 'FOC', // green
  19: 'Split Port',
  20: 'Archived',
  21: 'Deleted',
  22: 'Activation In Progress',
  23: 'Validation Failed',
  24: 'Validation In Progress',
  25: 'Invalid Numbers'
};

export enum PortStatus {
  unknown = 0,
  draft = 1,
  pending = 2,
  submitted = 3,
  rejected = 4,
  rejectedResolved = 5,
  resubmitted = 6,
  supPending = 7,
  subSubmiited = 8,
  cancelPending = 9,
  cancelSubmitted = 10,
  cancelled = 11,
  accepted = 12,
  completed = 13,
  pendingDocuments = 14,
  exception = 15,
  requestSUP = 16,
  requestedCancel = 17,
  requestedFOC = 18,
  splitPort = 19,
  archived = 20,
  delete = 21,
  activationInProgress = 22,
  validationFailed = 23,
  validationInProgress = 24,
  invalidNumbers = 25,
}

export const CountryCodes = {
  USA: {Code: 'US', Label: 'USA'},
  Canada: {Code: 'CA', Label: 'Canada'},
};
