import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { Text } from '@weave/design-system';

interface Props {
  on: boolean;
}

export function OnOffIndicator({ on, ...rest }: Props) {
  return (
    <Text
      css={css`
        background-color: ${on ? theme.colors.success : theme.colors.disabled};
        display: inline-block;
        border-radius: 999px;
        padding: ${theme.spacing(0.5, 1)};
        margin: 0;
      `}
      color={on ? 'white' : 'disabled'}
      textAlign="center"
    >
      {on ? 'ON' : 'OFF'}
    </Text>
  );
}
