import React, { useCallback, useEffect, useState } from 'react';
import { Filters, Location } from './audit.types';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { container } from './styles/index';
import { AuditResults } from './audit.results';
import { AuditContext } from './audit.context';
import { AuditFilters } from './audit-filters';
import { AuditFilterDrawer } from './audit-filter-drawer';
import { AuditQueries } from './audit.queries';
import { selectCurrentLocation } from 'redux/actions/location';
import { useInfiniteQuery, useQuery } from 'react-query';
import { DownloadIcon, ListIcon, PrimaryButton, Text } from '@weave/design-system';

const hourCacheTime = 3600000;

export const AuditLogs = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<Filters>();
  const [pageSize, setPageSize] = useState<number>(0);
  const [userSearch, setUserSearch] = useState<string>('');
  const [locationSearch, setLocationSearch] = useState<string>('');
  const [maxAuditsHit, setMaxAuditsHit] = useState<boolean>(false);
  const [isGlobalView, setIsGlobalView] = useState<boolean>(false);
  const [queriesStatus, setQueriesStatus] = useState<
    Record<
      | 'action_query'
      | 'resource_query'
      | 'location_query'
      | 'location_user_query'
      | 'user_query'
      | 'event_query',
      boolean
    >
  >({
    action_query: false,
    resource_query: false,
    location_query: false,
    location_user_query: false,
    user_query: false,
    event_query: false,
  });
  const currentLocation = useSelector(selectCurrentLocation);

  const handleComponentCleanUp = () => {
    setUserSearch('');
    setLocationSearch('');
    setFilters(undefined);
    setMaxAuditsHit(false);
    setQueriesStatus({
      event_query: false,
      action_query: false,
      resource_query: false,
      location_query: false,
      location_user_query: false,
      user_query: false,
    });
  };

  const actionQuery = useQuery('actions', AuditQueries.queries.actions, {
    cacheTime: hourCacheTime,
    retry: false,
    enabled: queriesStatus['action_query'],
    onError: (err) => {
      console.error(err);
    },
  });
  const resourceQuery = useQuery('resources', AuditQueries.queries.resources, {
    cacheTime: hourCacheTime,
    retry: false,
    enabled: queriesStatus['resource_query'],
    onError: (err) => {
      console.error(err);
    },
  });
  const locationQuery = useQuery(
    ['locations', locationSearch],
    AuditQueries.queries.locations,
    {
      cacheTime: hourCacheTime,
      retry: false,
      enabled: queriesStatus['location_query'],
      onError: (err) => {
        console.error(err);
      },
    }
  );
  const locationUserQuery = useQuery(
    'location_users',
    AuditQueries.queries.locationUsers,
    {
      cacheTime: hourCacheTime,
      retry: false,
      enabled: queriesStatus['location_user_query'],
      onError: (err) => {
        console.error(err);
      },
    }
  );
  const userQuery = useQuery(['users', userSearch], AuditQueries.queries.users, {
    cacheTime: hourCacheTime,
    retry: false,
    enabled: queriesStatus['user_query'],
    onError: (err) => {
      console.error(err);
    },
  });
  const eventQuery = useInfiniteQuery(
    ['event_query', filters],
    AuditQueries.queries.events,
    {
      getNextPageParam: (lastPage) =>
        lastPage.paginationKey !== '' ? lastPage.paginationKey : undefined,
      enabled: queriesStatus['event_query'],
      retry: false,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const handlePaginationOnScroll = (e) => {
    if (maxAuditsHit || eventQuery.isFetchingNextPage) return;
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      if (eventQuery.status === 'success') {
        const nextPageKey = eventQuery.data?.pages.at(-1)?.paginationKey ?? '';
        eventQuery.fetchNextPage({ pageParam: { paginationKey: nextPageKey } });
      } else {
        setMaxAuditsHit(true);
        eventQuery.fetchPreviousPage();
      }
    }
  };

  const setInitialFilters = async (locationId: string, initialFilters?: Filters) => {
    if (initialFilters) {
      setFilters(initialFilters);
      return;
    }
    const childLocations: Location[] | [] = await AuditQueries.queries.childLocations(
      locationId
    );
    if (childLocations.length === 0) {
      setFilters({
        ...filters,
        Location: [{ id: currentLocation.LocationID, display: currentLocation.Name }],
      });
    } else {
      const initialLocationFilters = childLocations.map((child) => {
        return { id: child.LocationID, display: child.Name };
      });
      initialLocationFilters.push({
        id: currentLocation.LocationID,
        display: currentLocation.Name,
      });
      setFilters({ ...filters, Location: initialLocationFilters });
    }
  };

  const checkIfGlobalView = useCallback(() => {
    setIsGlobalView(history.location.pathname.includes('/audits'));
  }, [history.location.pathname]);

  useEffect(() => {
    checkIfGlobalView();
    if (maxAuditsHit) setMaxAuditsHit(false);
    if (pageSize !== 0) setPageSize(0);
    if (eventQuery.status === 'success') {
      let count = 0;
      eventQuery.data.pages.forEach((p) => {
        count += p.event.length;
        if (p.event.length < 25) setMaxAuditsHit(true);
        setPageSize(count);
      });
    }
    /* Keeping history of last used filters */
    return () => {
      if (filters && !isGlobalView) {
        window.localStorage.setItem('audits_filter_history', JSON.stringify(filters));
      }
    };
  }, [eventQuery.data, checkIfGlobalView]);

  /* Handling initial filters that should be loaded */
  useEffect(() => {
    if (isGlobalView) {
      window.localStorage.removeItem('audits_filter_history');
      setFilters(undefined);
    } else {
      const pastFilters = window.localStorage.getItem('audits_filter_history');
      if (pastFilters && currentLocation) {
        const initialFilters = JSON.parse(pastFilters);
        if (currentLocation.LocationID === initialFilters?.Location?.[0]?.id) {
          setInitialFilters(currentLocation.LocationID, initialFilters);
          return;
        }
        window.localStorage.removeItem('audits_filter_history');
      }
      setInitialFilters(currentLocation?.LocationID);
    }
  }, [isGlobalView]);

  /* turning on the queries we need */
  useEffect(() => {
    setQueriesStatus({
      ...queriesStatus,
      event_query: true,
      action_query: true,
      resource_query: true,
    });

    return () => {
      handleComponentCleanUp();
    };
  }, []);

  return (
    <AuditContext.Provider
      value={{
        filters,
        userQuery,
        eventQuery,
        actionQuery,
        isGlobalView,
        resourceQuery,
        queriesStatus,
        locationQuery,
        locationUserQuery,
        setFilters,
        setUserSearch,
        setLocationSearch,
        setQueriesStatus,
      }}
    >
      <div
        style={isGlobalView ? { maxHeight: '900px' } : {}}
        css={container}
        onScroll={(e) => handlePaginationOnScroll(e)}
      >
        {filters && <AuditFilters />}
        <section className="action-bar divider">
          <Text weight="bold">{`${pageSize} Results`}</Text>
          <div className="action-items">
            <ListIcon style={{ paddingTop: '3px' }} className="action-icon" />
            <AuditFilterDrawer />
            <PrimaryButton
              disabled={true}
              className="csv-button"
              onClick={() => alert('need to still wire this up')}
            >
              <DownloadIcon size={24} /> Download CSV
            </PrimaryButton>
          </div>
        </section>
        <AuditResults />
      </div>
    </AuditContext.Provider>
  );
};
