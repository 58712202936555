import { css } from '@emotion/core';
import {
  EditIcon,
  PrimaryButton,
  TextField,
  useForm,
  Text,
  FormFieldActionTypes,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import {
  PortingData,
  PortingDataUpdateRequest,
  PortingValidation,
} from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import { cloneDeep, set } from 'lodash';
import React, { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { portingCardStyles } from './porting-card.styles';
import { stateCodes } from './state-codes';
import { isAlphaNumericValue } from '../utils/porting-utils';
import {
  CountryCodes,
  countryOptions,
} from '../../generic/address-autocomplete/country-codes';

interface Props {
  currentPortOrder: PortingData;
  officeInfoEdit: boolean;
  setOfficeInfoEdit: React.Dispatch<React.SetStateAction<boolean>>;
  handleDrawerToggle: (drawer: string, toggleState: boolean) => void;
  updatePortingData: (req: PortingDataUpdateRequest) => void;
  officeInformationError?: PortingValidation[];
}

type StateType = typeof stateCodes[0];

const getSelectedState = (stateCode?: string): StateType | undefined => {
  if (!stateCode || !stateCodes.length) {
    return undefined;
  }
  return stateCodes.find((state) => state.code.toLowerCase() === stateCode.toLowerCase());
};

export const PortingCardOfficeInfo = (props: Props) => {
  const {
    currentPortOrder,
    officeInfoEdit,
    setOfficeInfoEdit,
    handleDrawerToggle,
    updatePortingData,
    officeInformationError,
  } = props;

  const [invalidState, setInvalidState] = useState<boolean>(false);

  const [selectedState, setSelectedState] = useState<StateType | undefined>(
    getSelectedState(currentPortOrder.serviceState)
  );

  const portingRequest = currentPortOrder?.portingRequests?.[0];
  const {
    getFieldProps,
    values: fields,
    formProps,
    isComplete,
  } = useForm({
    fields: {
      businessName: {
        type: 'text',
        value: currentPortOrder.companyName,
        required: true,
      },
      officeEmail: {
        type: 'email',
        value: currentPortOrder?.officeEmail,
        required: true,
      },
      authorizedUserFirstName: {
        type: 'text',
        value: currentPortOrder.authorizedUserFirstName,
        required: true,
      },
      authorizedUserLastName: {
        type: 'text',
        value: currentPortOrder.authorizedUserLastName,
      },
      accountNumber: {
        type: 'text',
        value: currentPortOrder.phoneServiceAccountNumber,
        required: true,
      },
      pin: {
        type: 'text',
        value: currentPortOrder.accountPin,
      },
      houseNumber: {
        type: 'text',
        value: currentPortOrder.serviceHouseNumber,
        required: true,
      },
      street: {
        type: 'text',
        value: currentPortOrder.serviceStreet1,
      },
      street2: {
        type: 'text',
        value: currentPortOrder.serviceStreet2,
      },
      city: {
        type: 'text',
        value: currentPortOrder.serviceCity,
        required: true,
      },
      zip: {
        type: 'text',
        value: currentPortOrder.serviceZip,
        required: true,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Blur) {
        switch (action.payload.name) {
          case 'accountNumber': {
            if (typeof state.accountNumber.value === 'string') {
              if (!isAlphaNumericValue(state.accountNumber.value)) {
                return {
                  ...state,
                  accountNumber: {
                    ...state.accountNumber,
                    value: state.accountNumber.value.replace(/[^a-zA-Z0-9]/g, ''),
                  },
                };
              }
            }
          }
        }
      }
      return state;
    },
  });

  const handleUpdatePortingDetails = () => {
    const portCopy = cloneDeep(currentPortOrder);
    portCopy.companyName = fields.businessName ?? '';
    portCopy.officeEmail = fields.officeEmail ?? '';
    portCopy.authorizedUserFirstName = fields.authorizedUserFirstName ?? '';
    portCopy.authorizedUserLastName = fields.authorizedUserLastName ?? '';
    portCopy.phoneServiceAccountNumber = fields.accountNumber ?? '';
    portCopy.accountPin = fields.pin ?? '';
    portCopy.serviceHouseNumber = fields.houseNumber ?? '';
    portCopy.serviceStreet1 = fields.street ?? '';
    portCopy.serviceStreet2 = fields.street2 ?? '';
    portCopy.serviceZip = fields.zip ?? '';
    portCopy.serviceCity = fields.city ?? '';
    portCopy.serviceState = selectedState?.code ?? '';
    updatePortingData({ portingData: portCopy, portingDataId: portCopy.id });
  };

  const getCountryName = (countryName) => {
    if (countryName.toUpperCase() === CountryCodes.Canada) {
      return countryOptions[0].label;
    }
    if (countryName.toUpperCase() === CountryCodes.USA) {
      return countryOptions[1].label;
    }
    return 'No Country found';
  };

  return (
    <div>
      <form {...formProps}>
        <div>
          <div className={portingCardStyles.portingInfoContainer}>
            <div className={portingCardStyles.title}>Office Information</div>
            {!portingRequest?.portOrderNumber && (
              <div>
                <EditIcon
                  css={css`
                    color: ${!officeInfoEdit && theme.colors.weaveBlue};
                  `}
                  onClick={() => handleDrawerToggle('office', officeInfoEdit)}
                />
              </div>
            )}
          </div>
          {officeInformationError?.map((data) => (
            <Text color="error" key={data.id}>
              {data.reason}
            </Text>
          ))}
          <div className={portingCardStyles.officeCont}>
            {!officeInfoEdit && (
              <>
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={css`
                      width: 150px;
                      margin-bottom: 10px;
                    `}
                  >
                    Business Name
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 200px;
                    `}
                  >
                    {currentPortOrder.companyName}
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={css`
                      width: 150px;
                      margin-bottom: 10px;
                    `}
                  >
                    Account Number
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 200px;
                    `}
                  >
                    {currentPortOrder.phoneServiceAccountNumber}
                  </div>
                  <div
                    css={css`
                      width: 150px;
                    `}
                  >
                    PIN
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 150px;
                    `}
                  >
                    {currentPortOrder.accountPin || 'N/A'}
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={css`
                      width: 150px;
                      margin-bottom: 10px;
                    `}
                  >
                    Authorized User
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 200px;
                    `}
                  >
                    {currentPortOrder.authorizedUserFirstName}{' '}
                    {currentPortOrder.authorizedUserLastName}
                  </div>
                  <div
                    css={css`
                      width: 150px;
                    `}
                  >
                    Office Email
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 150px;
                    `}
                  >
                    {currentPortOrder?.officeEmail}
                  </div>
                </div>

                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={css`
                      width: 150px;
                      margin-bottom: 10px;
                    `}
                  >
                    Service Address
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 200px;
                    `}
                  >
                    <div>
                      {currentPortOrder.serviceHouseNumber}{' '}
                      {currentPortOrder.serviceStreet1} {currentPortOrder.serviceStreet2}
                    </div>
                    <div>
                      {currentPortOrder.serviceCity}, {currentPortOrder.serviceState}{' '}
                      {currentPortOrder.serviceZip}
                    </div>
                  </div>

                  <div
                    css={css`
                      width: 150px;
                    `}
                  >
                    Country
                  </div>
                  <div
                    css={css`
                      color: ${theme.colors.gray500};
                      width: 150px;
                    `}
                  >
                    {currentPortOrder?.serviceCountry &&
                      getCountryName(currentPortOrder.serviceCountry)}
                  </div>
                </div>
              </>
            )}
            {officeInfoEdit && (
              <>
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <div
                    css={css`
                      width: 250px;
                      margin-bottom: 10px;
                    `}
                  >
                    Account Information
                  </div>
                  <div>
                    <div className={portingCardStyles.streetContainer}>
                      <div>
                        <TextField
                          {...getFieldProps('businessName')}
                          label="Business Name"
                          name="businessName"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('officeEmail')}
                          label="Office Email"
                          name="officeEmail"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>

                    <div className={portingCardStyles.streetContainer}>
                      <div>
                        <TextField
                          {...getFieldProps('authorizedUserFirstName')}
                          label="Authorized User First Name"
                          name="authorizedUserFirstName"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('authorizedUserLastName')}
                          label="Authorized User Last Name"
                          name="authorizedUserLastName"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>

                    <div className={portingCardStyles.streetContainer}>
                      <div>
                        <TextField
                          {...getFieldProps('accountNumber')}
                          label="Account Number"
                          name="accountNumber"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('pin')}
                          label="PIN"
                          name="pin"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    margin-top: 30px;
                  `}
                >
                  <div
                    css={css`
                      width: 250px;
                      margin-bottom: 10px;
                    `}
                  >
                    Service Address
                  </div>
                  <div>
                    <div className={portingCardStyles.streetContainer}>
                      <div>
                        <TextField
                          {...getFieldProps('houseNumber')}
                          label="House Number"
                          name="houseNumber"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('street')}
                          label="Street"
                          name="street"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>

                    <div className={portingCardStyles.streetContainer}>
                      <div>
                        <TextField
                          {...getFieldProps('street2')}
                          label="Apt/Ste #"
                          name="street2"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('city')}
                          label="City"
                          name="city"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>

                    <div className={portingCardStyles.typeaheadCont}>
                      <div
                        css={css`
                          width: 230px;
                        `}
                      >
                        <Typeahead
                          className={portingCardStyles.typeaheadStyles}
                          options={stateCodes}
                          id={currentPortOrder.id}
                          defaultSelected={selectedState ? [selectedState] : []}
                          placeholder="State"
                          highlightOnlyResult
                          isInvalid={invalidState}
                          onChange={(selected) => {
                            const state = selected[0];
                            if (state) {
                              setInvalidState(false);
                              setSelectedState(state);
                            }
                          }}
                          onInputChange={(text) => {
                            const state = stateCodes.find(
                              (element) =>
                                text.trim().toLowerCase() === element.label.toLowerCase()
                            );
                            if (state) {
                              setInvalidState(false);
                              setSelectedState(state);
                            } else {
                              setInvalidState(true);
                            }
                          }}
                        />
                      </div>
                      <div>
                        <TextField
                          {...getFieldProps('zip')}
                          label="Zip Code"
                          name="zip"
                          css={css`
                            width: 230px;
                            background-color: white;
                          `}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={portingCardStyles.saveOfficeCont}>
                  <PrimaryButton
                    className={portingCardStyles.saveOffice}
                    color="blue"
                    size="large"
                    onClick={() => {
                      setOfficeInfoEdit(false);
                      handleUpdatePortingDetails();
                    }}
                    disabled={!isComplete}
                  >
                    Save
                  </PrimaryButton>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
