/*------------------------------- Intake Form Data Types -------------------------------*/
export type OnboardingStepProgress = {
  displayId: string;
  name: string;
  startedAt: string | null;
  completedAt: string | null;
  section: string;
  task: string;
  lastModifiedAt: string | null;
  lastModifiedBy: string;
};

export type OnboardingSectionProgress = {
  name: string;
  totalSteps: number | null;
  stepsCompleted: number | null;
  startedAt: string | null;
  completedAt: string | null;
  lastModifiedAt: string | null;
};

export type OnboardingContact = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createWeaveUser: boolean;
  addWeaveAdminRole: boolean;
};

export type OnboardingAddress = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postal: string;
  verified: boolean | null;
};

export type OfficeDayHours = {
  startTime: string;
  endTime: string;
  isOpen: boolean | null;
};

export type phoneBillObject = {
  fileName: string;
  mediaId: string;
};

export enum UserAnswer {
  Yes = 'yes',
  No = 'no',
  NotSure = 'not-sure',
  Unanswered = '',
}

export enum ShippingMethod {
  standard = 'standard',
  twoDay = 'two-day',
  overnight = 'overnight',
}

export type IntakeForm = {
  // What to show
  hideScreeningQuestions: boolean;
  officeConfiguration: string[] | null;
  salesforceOpportunityId: string;
  salesforceSchedulingContactId: string;
  // Screening
  isOfficeUnderConstruction: boolean | null;
  hasInternetAndComputers: boolean | null;
  willTransferPhoneNumbers: boolean | null;
  skipNetworkTest: boolean | null;
  // Progress
  formStartedAt: string | null;
  formCompletedAt: string | null;
  formOverrideReason?: string;
  formOverrideNotes?: string;
  formOverrideBy?: string;
  stepProgress: OnboardingStepProgress[] | null;
  sectionProgress: OnboardingSectionProgress[] | null;
  // Form Questions
  contacts: OnboardingContact[] | null;
  userContactEmail: string;
  businessOwnerContactEmail: string;
  primaryContactEmail: string;
  hasItContact: boolean | null;
  itContactEmail: string;
  businessName: string;
  businessPhone: string;
  businessAddress: OnboardingAddress | null;
  e911SameAsBusinessAddress: boolean | null;
  e911Address: OnboardingAddress | null;
  sundayHours: OfficeDayHours | null;
  mondayHours: OfficeDayHours | null;
  tuesdayHours: OfficeDayHours | null;
  wednesdayHours: OfficeDayHours | null;
  thursdayHours: OfficeDayHours | null;
  fridayHours: OfficeDayHours | null;
  saturdayHours: OfficeDayHours | null;
  yealinkT53Qty: number | null;
  yealinkT54Qty: number | null;
  cordlessW60pQty: number | null;
  phoneQtyCorrect: boolean | null;
  numPhonesMounted: number | null;
  shippingMethod: ShippingMethod | '';
  shippingAddressSameAsBusiness: boolean | null;
  shippingAddress: OnboardingAddress | null;
  isAtBusinessLocation: boolean | null;
  allWorkstationsEthernetConnected: UserAnswer;
  numWorkstationsMissingEthernet: number | null;
  hasPhonesAwayFromWorkstations: UserAnswer;
  numEthernetPortsNeedInstalled: number | null;
  routerName: string;
  routerModel: string;
  routerContactEmail: string;
  networkTestTimedOut: boolean | null;
  networkTestPing: string;
  networkTestJitter: string;
  networkTestDownload: string;
  networkTestUpload: string;
  networkTestFailedDisclaimer: boolean | null;
  phoneProviderBillImageData: phoneBillObject[] | null;
  phoneProviderName: string;
  phoneProviderAccountNumber: string;
  phoneProviderAuthorizedUser: string;
  phoneProviderServiceAddress: OnboardingAddress | null;
  phoneProviderInfoCorrect: boolean | null;
  phoneAndInternetBundled: UserAnswer;
  otherServicesUsingPhoneLines: UserAnswer;
  phoneNumbers: string[] | null;
  phoneNumbersCorrect: boolean | null;
  faxNumbers: string[] | null;
  faxNumbersCorrect: boolean | null;
  estimatedPortDate: string;
  acceptedPhonePortLoa: boolean;
  portingBatchId: string;
  softwareLatestDownloadAt: string | null;
  integrationName: string;
  networkAuditId: string;
  debug: {
    skipSalesforceAction: boolean | null;
  } | null;
  isFormManuallyCompleted: boolean;
};

export type CompleteIntakeFormRequestData = {
  isFormManuallyCompleted: boolean;
  formOverrideReason: string;
  formOverrideNotes: string;
  formOverrideBy: string;
};

/*----------------------------- Operation Status Data Types ----------------------------*/
export type OperationStatus = {
  id: number;
  name: string;
  status: 'In Progress' | 'Completed';
};

/*---------------------------------- Tasks Data Types ----------------------------------*/
export enum TaskStatus {
  InComplete = 'INCOMPLETE',
  Complete = 'COMPLETE',
}

export type ModulesTasksResponse = {
  locationId: string;
  modules: OnboardingModule[];
};

type OnboardingModule = {
  id: number;
  moduleName: string;
  information: string;
  description: string;
  tasks: OnboardingTask[];
};

export type OnboardingTask = {
  id: number;
  taskName: string;
  taskTitle: string;
  status: string;
  information: string;
  description: string;
  imageUrl: string;
};

export enum TaskIds {
  tenDlc = 1,
  stripeSetup = 2,
  reviewsSetup = 3,
  userInvite = 4,
}
