import React from 'react';
import { Text } from '@weave/design-system';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { HandoffSnapshot } from '../../../handoff-hub.types';

type Props = {
  locationInformationData: HandoffSnapshot;
};

export const LocationInformationNoteComponent = ({ locationInformationData }: Props) => {
  return (
    <>
      <div>
        <p css={headerTitleStyle}>Location Information</p>
        <div>
          <>
            <p css={sectionTitleStyle}>Office Information</p>
            <div css={contentStyles}>
              <Text>Point of contact email</Text>
              <Text>{locationInformationData.pointOfContact}</Text>
            </div>
            <div css={contentStyles}>
              <Text>Reason for purchase</Text>
              <Text>{locationInformationData.reasonForPurchase}</Text>
            </div>
          </>
          <div css={contentStyles}>
            <Text>CSM Optimization Call</Text>
            <Text>{locationInformationData.isCsmCallScheduled}</Text>
          </div>
          <div css={contentStyles}>
            <Text>CSM Optimization Call Notes</Text>
            <Text>{locationInformationData.csmCallNotes}</Text>
          </div>
        </div>
      </div>
      <div>
        <>
          <p css={sectionTitleStyle}>Network Health Information</p>
          <div css={contentStyles}>
            <Text>Router type</Text>
            <Text>{locationInformationData.routerType}</Text>
          </div>
          <div css={contentStyles}>
            <Text>Disclaimer type sent</Text>
            <Text>{locationInformationData.disclaimerTypeSent}</Text>
          </div>
          <div css={contentStyles}>
            <Text>Router make and model</Text>
            <Text>{locationInformationData.routerMakeAndModel}</Text>
          </div>
          <div css={contentStyles}>
            <Text>Explain the office’s network decision below:</Text>
            <Text css={textareaStyling}>{locationInformationData.networkDecision}</Text>
          </div>
        </>
      </div>
      <div>
        <p css={sectionTitleStyle}>Office Experience</p>
        <div css={contentStyles}>
          <Text>Billing notes:</Text>
          <Text css={textareaStyling}>{locationInformationData.billingNotes}</Text>
        </div>
        <div css={contentStyles}>
          <Text>Please include any helpful notes and details below:</Text>
          <Text css={textareaStyling}>{locationInformationData.officeExperience}</Text>
        </div>
      </div>
    </>
  );
};

export const contentStyles = css`
  display: flex;
  justify-content: space-between;

  p {
    margin-bottom: ${theme.spacing(2)};
    font-weight: 600;
  }

  p:first-child {
    width: 310px;
  }
  p:last-child {
    width: 300px;
  }
`;

export const textareaStyling = css`
  white-space: pre-line;
`;

export const headerTitleStyle = css`
  font-size: ${theme.fontSize(20)};
  margin-bottom: ${theme.spacing(3)};
  font-weight: 700;
`;

export const sectionTitleStyle = css`
  font-size: ${theme.fontSize(18)};
  font-weight: 700;
`;
