import { FeatureSettingResponse } from './obp.types';
import { CustomAxios, getResponseData } from '../../redux/axios';

export const obpApi = {
  baseUrl: 'views/service/locations',
  async getPaymentFeatureSettings(paymentsURL?: string, locationId?: string) {
    const response = await CustomAxios.get(
      `${paymentsURL}/${this.baseUrl}/${locationId}/feature-settings`
    );
    return getResponseData<FeatureSettingResponse>(response);
  },
  async setPaymentFeatureSettings(
    paymentsURL?: string,
    locationId?: string,
    obpAuthEnabledValue?: boolean
  ) {
    await CustomAxios.post(
      `${paymentsURL}/${this.baseUrl}/${locationId}/feature-settings`,
      {
        settingName: 'ONLINE_BILL_PAY_FEATURE_ENABLED',
        enabled: !!obpAuthEnabledValue,
      }
    );
    const response = await this.getPaymentFeatureSettings(paymentsURL, locationId);
    return response;
  },
};
