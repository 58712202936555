import React, { useState } from 'react';
import { css } from '@emotion/core';
import {
  Heading,
  Modal,
  PrimaryButton,
  SpinningLoader,
  Text,
  useModalControl,
} from '@weave/design-system';
import { IntakeForm, OperationStatus } from '../../onboarding.types';
import { format } from 'date-fns';
import { useAlert } from '@weave/alert-system';
import { CompleteFormModal, OverrideFormType } from './complete-form-modal';
import { onboardingIntakeFormApi } from '../../onboarding.api';
import { REACT_APP_API_URL } from '../../../../config/app';
import { theme } from '@weave/theme-original';
import { AuthStorage } from '../../../../redux/actions/auth/auth.types';

// We require a code to complete the intake form so that not just anybody can complete the
// form. However, it's not a huge deal if they do so we are just using this code as a way
// to not make it easy to complete. Security through obscurity.
const DUMMY_CODE = "let's do this";

const getNetworkAuditRunUrl = (auditId: string) => {
  const shouldUseProductionEndpoint = REACT_APP_API_URL.includes('api.weaveconnect.com');

  return shouldUseProductionEndpoint
    ? `https://network-audit.gke1-west3.wsf-prod-1.wstack.net/audits/${auditId}/runs`
    : `https://network-audit.gke1-west4.wsf-dev-0.wstack.net/audits/${auditId}/runs`;
};

type IntakeFormStatusProps = {
  intakeFormData?: IntakeForm;
  operationsStatuses: OperationStatus[];
  fetchIntakeFormLoading: boolean;
  fetchIntakeFormData: () => void;
};

export const IntakeFormStatus = ({
  intakeFormData,
  fetchIntakeFormData,
  fetchIntakeFormLoading,
  operationsStatuses,
}: IntakeFormStatusProps) => {
  const { modalProps, triggerProps } = useModalControl();
  const [completeFormLoading, setCompleteFormLoading] = useState(false);
  const alerts = useAlert();

  const loading = fetchIntakeFormLoading || completeFormLoading;

  const formatDate = (date: string) => format(new Date(date), 'yyyy-MM-dd h:mm:ss');

  const completeForm = async (formData: OverrideFormType) => {
    if (!intakeFormData) return;

    setCompleteFormLoading(true);
    try {
      await onboardingIntakeFormApi.completeOnboardingIntakeForm({
        isFormManuallyCompleted: true,
        formOverrideReason: formData.reason ?? '',
        formOverrideNotes: formData.notes ?? '',
        formOverrideBy: localStorage.getItem(AuthStorage.user_email) ?? '',
      });
      alerts.success('Form completed successfully');
    } catch (error: any) {
      console.error(error);
      alerts.error('Error completing form');
    } finally {
      setCompleteFormLoading(false);
      fetchIntakeFormData();
    }
  };

  const handleCodeProvided = (formData: OverrideFormType) => {
    modalProps.onClose();

    if (formData.code === DUMMY_CODE) {
      completeForm(formData);
    } else {
      alerts.error('Incorrect code provided');
    }
  };

  if (!intakeFormData) {
    return <Heading>No Reimagine Form Data</Heading>;
  }

  const { formStartedAt, formCompletedAt, isFormManuallyCompleted } = intakeFormData;

  return (
    <>
      <section css={sectionStyles}>
        <Heading>Reimagine Form Status</Heading>
        <div css={statusRowItems}>
          <Text>Form started at: &nbsp;</Text>
          <Text color="light">
            {formStartedAt ? formatDate(formStartedAt) : 'Form Not Started'}
          </Text>
        </div>

        <div css={statusRowItems}>
          <Text>Form completed at: &nbsp;</Text>
          <Text color="light">
            {formCompletedAt ? formatDate(formCompletedAt) : 'Form Not Complete'}
          </Text>
        </div>

        {!formCompletedAt && (
          <PrimaryButton
            {...triggerProps}
            css={buttonStyles}
            color="blue"
            disabled={loading}
          >
            Complete Form
          </PrimaryButton>
        )}

        {isFormManuallyCompleted && (
          <Text css={formManuallyCompleteBanner}>
            Attention: Reimagine Form - Director Override
          </Text>
        )}
      </section>

      <section css={sectionStyles}>
        <Heading level={2}>Section Progress</Heading>
        <table
          css={css`
            width: 100%;
            tr {
              border: 1px ${theme.colors.gray300} solid;
            }
            tr:nth-child(even) {
              background-color: ${theme.colors.gray300};
            }
            td {
              word-wrap: break-word;
            }
          `}
        >
          <thead>
            <tr>
              <th>Section Name</th>
              <th>Started At</th>
              <th>Last Modified</th>
              <th>Completed At</th>
            </tr>
          </thead>
          <tbody>
            {intakeFormData.sectionProgress?.map(
              ({ name, startedAt, lastModifiedAt, completedAt }) => (
                <tr key={name}>
                  <td>{name}</td>
                  <td>{startedAt ? formatDate(startedAt) : '-'}</td>
                  <td>{lastModifiedAt ? formatDate(lastModifiedAt) : '-'}</td>
                  <td>{completedAt ? formatDate(completedAt) : '-'}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </section>

      <section css={sectionStyles}>
        <Heading level={2}>Operations Statuses</Heading>
        <table
          css={css`
            width: 100%;
            table-layout: fixed;
            tr {
              border: 1px ${theme.colors.gray300} solid;
            }
            tr:nth-child(even) {
              background-color: ${theme.colors.gray300};
            }
            td {
              word-wrap: break-word;
            }
          `}
        >
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {operationsStatuses.map(({ name, id, status }) => (
              <tr key={name}>
                <td>{id}</td>
                <td>{name}</td>
                <td>{status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <section css={sectionStyles}>
        <Heading>Network Audit</Heading>
        {intakeFormData.networkAuditId ? (
          <div
            css={css`
              display: flex;
            `}
          >
            <a
              href={getNetworkAuditRunUrl(intakeFormData.networkAuditId)}
              target="_blank"
              rel="noreferrer"
            >
              LINK
            </a>
            <Text>&nbsp;to network audit results</Text>
          </div>
        ) : (
          <Text>No Network Audit Id</Text>
        )}
      </section>

      {loading && (
        <div css={loadingContainer}>
          <SpinningLoader />
        </div>
      )}

      <CompleteFormModal modalProps={modalProps} onProceed={handleCodeProvided} />
    </>
  );
};

const sectionStyles = css`
  margin-bottom: ${theme.spacing(5)};
`;

const statusRowItems = css`
  display: flex;
`;

const buttonStyles = css`
  width: 250px !important;
`;

const loadingContainer = css`
  display: flex;
  justify-content: center;
`;

const formManuallyCompleteBanner = css`
  font-weight: 800 !important;
  font-size: 1.2rem;
  background: ${theme.colors.error};
  color: ${theme.colors.white} !important;
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(2)};
  text-align: center;
`;
