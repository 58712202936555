import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import {
  NakedButton,
  useFlyoutMenu,
  FlyoutMenuAction,
  PlusIcon,
  TrashIcon,
  PrimaryButton,
  MessageTemplate,
  Text,
} from '@weave/design-system';
import React, { Fragment, useState } from 'react';
import { type } from '../../../../history-list-container/sms-details-container/sms-details/sms-list.styles';
import { standardizeTemplateTextTags } from '../../../template-tag-utils';
import { useTagLists } from '../../tags-list';
import { EmailTypes, HtmlContent } from './edit-email-template';
import { NotificationSetting } from '../../../recall-reminders/recall-settings.hook';

const container = css`
  width: 90%;
`;

const sectionContainer = css`
  display: flex;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const trash = css`
  margin: auto;
  outline: none;
`;

const buttonStyle = css`
  margin: 16px;
  color: ${theme.colors.weaveBlue};
  font-weight: bold;
`;

const flyOutContainer = css`
  width: max-content;
  position: relative;
  margin: ${theme.spacing(5)} auto;
`;

const flyOutMenuStyle = css`
  width: 100%;
  box-shadow: ${theme.shadows.heavy};
  top: 0;
  left: 100%;
  z-index: 10;
`;

const headingLabel = css`
  margin: ${theme.spacing(2, 0)};
`;

const emailBtnContainer = css`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const anchor = css`
  width: 50%;
  text-decoration: unset !important;
`;

const emailBtn = css`
  width: 100%;
`;

const contentMap = {
  emailHeader: 'Header',
  emailMessage: 'Message',
  emailFooter: 'Footer',
  emailButton: 'Button',
};

interface Props {
  item: HtmlContent;
  handleAddContent: (type: EmailTypes) => void;
  settings: NotificationSetting;
  editContent: (template: string) => void;
  removeItem: () => void;
}

export const HtmlItem = ({
  item,
  handleAddContent,
  editContent,
  removeItem,
  settings,
}: Props) => {
  const { active, FlyoutMenu, flyoutProps, triggerProps } = useFlyoutMenu();

  const actions: FlyoutMenuAction[] = [
    {
      Icon: PlusIcon,
      label: 'Header',
      onClick: () => handleAddContent('emailHeader'),
    },
    {
      Icon: PlusIcon,
      label: 'Message',
      onClick: () => handleAddContent('emailMessage'),
    },
    {
      Icon: PlusIcon,
      label: 'Footer',
      onClick: () => handleAddContent('emailFooter'),
    },
    {
      Icon: PlusIcon,
      label: 'Button',
      onClick: () => handleAddContent('emailButton'),
    },
  ];

  const isTextField =
    item.type === 'emailHeader' ||
    item.type === 'emailMessage' ||
    item.type === 'emailFooter';

  if (item.type === 'emailImage') return null;

  const tags = useTagLists();
  const tagType = settings.type;
  const tagTypeMapping = {
    appointment: type === 'multi' ? 'multiTags' : 'singleTags',
    birthday: 'birthdayTags',
    order: 'eyewearTags',
    recall: 'recallTags',
    'save-the-date': 'saveTheDateTags',
    'schedule-reactivation': 'singleTags',
  };
  const reminderTags = tags[tagTypeMapping[tagType] ?? 'singleTags'];
  const [initialTemplate] = useState(standardizeTemplateTextTags(item.content));

  return (
    <Fragment>
      <div css={sectionContainer}>
        <div css={container}>
          {isTextField && (
            <MessageTemplate
              initialTemplate={initialTemplate}
              tags={reminderTags}
              onChange={(template) => editContent(template)}
            >
              <Text size="medium" weight="bold" css={headingLabel}>
                Customize {contentMap[item.type]}
              </Text>
              <MessageTemplate.Editor></MessageTemplate.Editor>
              <Text size="medium" weight="bold" css={headingLabel}>
                {'Drag & drop or click to add to the '} {contentMap[item.type]}
              </Text>
              <MessageTemplate.TagList></MessageTemplate.TagList>
              <Text size="medium" weight="bold" css={headingLabel}>
                Preview {contentMap[item.type]}
              </Text>
              <MessageTemplate.Preview></MessageTemplate.Preview>
            </MessageTemplate>
          )}

          {item.type === 'emailButton' && (
            <div css={emailBtnContainer}>
              <a
                css={anchor}
                href="https://{url_external_url}/desktop/v1/unauthed/action/{url_location_id}/{nonce}"
              >
                <PrimaryButton css={emailBtn}>{item.content}</PrimaryButton>
              </a>
            </div>
          )}
        </div>
        <NakedButton css={trash} onClick={removeItem}>
          <TrashIcon />
        </NakedButton>
      </div>
      <div css={flyOutContainer}>
        <NakedButton css={buttonStyle} {...triggerProps}>
          Add Content Field
        </NakedButton>
        {active && (
          <FlyoutMenu css={flyOutMenuStyle} {...flyoutProps} actions={actions} />
        )}
      </div>
    </Fragment>
  );
};
