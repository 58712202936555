import { NakedUl, Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import React from 'react';
import { UserLocation } from './user-location.component';
import { useGetUserLocationData } from './user-search.api';

type UserLocationsProps = {
  userId: string;
};

export const UserLocations = ({ userId }: UserLocationsProps) => {
  const userLocations = useGetUserLocationData(userId);
  return (
    <>
      {userLocations.data?.Locations.length !== 0 ? (
        <NakedUl
          css={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            '>li': {
              marginRight: theme.spacing(1),
              marginTop: theme.spacing(1),
            },
          }}
        >
          {userLocations.isFetched &&
            userLocations.data?.Locations.map((location) => (
              <li>
                <UserLocation locationId={location.ID} />
              </li>
            ))}
        </NakedUl>
      ) : (
        'No locations'
      )}
    </>
  );
};
