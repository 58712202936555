import { Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';

type FeatureFlagStateProps = {
  state: boolean;
};
export const FeatureFlagState = ({ state }: FeatureFlagStateProps) => (
  <Text
    color={state ? 'white' : 'disabled'}
    css={{
      padding: '.25em .5em',
      borderRadius: 999,
      lineHeight: 0,
      margin: 0,
      background: state ? theme.colors.success : theme.colors.disabled,
    }}
  >
    {state ? 'ON' : 'OFF'}
  </Text>
);
