import { css } from 'emotion';
import {
  Modal,
  PrimaryButton,
  useForm,
  FormRow,
  ModalControlModalProps,
  PhoneField,
  SecondaryButton,
} from '@weave/design-system';
import { CustomAxios, getResponseData } from 'redux/axios';
import { useAlert } from '@weave/alert-system';
import { theme } from '@weave/theme';

interface Props {
  modalProps: ModalControlModalProps;
  resetPhoneValidity: (phone: string) => void;
}

export const ResetValidityModal = ({ modalProps, resetPhoneValidity }: Props) => {
  const alert = useAlert();
  const { formProps, getFieldProps, reset, values, getErrors } = useForm({
    fields: {
      phone: { type: 'phone' },
    },
    onSubmit: (vals) => {
      if (vals.phone) {
        resetPhoneValidity(vals.phone);
        reset();
      }
    },
  });

  const getValidity = (phoneNumber: string) =>
    CustomAxios.get(`/sms/number/v1/validity?phoneNumber=${phoneNumber}`)
      .then(getResponseData)
      .then((data) => data?.valid)
      .catch((err) => {
        if (err.response.status === 400) {
          return 'VALID_NUMBER_STATUS_INVALID';
        }
        return 'VALID_NUMBER_STATUS_UNSPECIFIED';
      });

  // const addPhone = (phone: string) => {
  //   const validInput = getErrors().phone === undefined;
  //   if (validInput) {
  //     setPhones([...phones, phone]);
  //     reset();
  //   }
  // };

  const getPhoneValidity = async (phone: string) => {
    const validInput = getErrors().phone === undefined;
    if (validInput) {
      const validity = await getValidity(phone);
      if (validity === 'VALID_NUMBER_STATUS_VALID') {
        alert.success('Valid SMS Phone Number');
      } else {
        alert.error('Invalid SMS Phone Number');
      }
    }
  };

  return (
    <Modal {...modalProps}>
      <Modal.Header>Phone Number to Reset Validity</Modal.Header>
      <Modal.Body>
        <div
          className={css`
            margin-top: ${theme.spacing(1)};
          `}
        >
          {/* <div> */}
          {/*   <ul */}
          {/*     className={css` */}
          {/*       padding: 0; */}
          {/*     `} */}
          {/*   > */}
          {/*     {phones.map((phone) => ( */}
          {/*       <li */}
          {/*         key={phone} */}
          {/*         className={css` */}
          {/*           display: flex; */}
          {/*           justify-content: space-between; */}
          {/*           align-items: center; */}
          {/*         `} */}
          {/*       > */}
          {/*         <Text */}
          {/*           className={css` */}
          {/*             margin-bottom: 0; */}
          {/*             padding-right: ${theme.spacing(1)}; */}
          {/*           `} */}
          {/*         > */}
          {/*           {phone} */}
          {/*         </Text> */}
          {/*         <IconButton */}
          {/*           label="" */}
          {/*           onClick={() => setPhones(phones.filter((p) => p !== phone))} */}
          {/*           size="small" */}
          {/*         > */}
          {/*           <XIcon size={14} color="error" /> */}
          {/*         </IconButton> */}
          {/*       </li> */}
          {/*     ))} */}
          {/*   </ul> */}
          {/* </div> */}
          <form {...formProps}>
            <FormRow>
              <PhoneField
                {...getFieldProps('phone')}
                label="Phone Number"
                name="phone"
                placeholder="Phone Number"
              />
            </FormRow>
            <FormRow>
              <SecondaryButton
                onClick={() => {
                  if (values.phone) {
                    getPhoneValidity(values?.phone);
                  }
                }}
              >
                Get Validity
              </SecondaryButton>
              {/* <SecondaryButton */}
              {/*   onClick={() => { */}
              {/*     if (values.phone) { */}
              {/*       addPhone(values?.phone); */}
              {/*     } */}
              {/*   }} */}
              {/* > */}
              {/*   Add Phone Number */}
              {/* </SecondaryButton> */}
            </FormRow>
            <FormRow>
              <PrimaryButton type="submit" disabled={!values.phone}>
                Submit
              </PrimaryButton>
            </FormRow>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
