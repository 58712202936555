import React from 'react';
import { EditCustomizationButton } from './edit-customization-button';
import { css } from '@emotion/core';
import { SaveCustomizationButtons } from './save-customization-buttons';
import { useCustomizationFlagV2Store } from '../providers/customization-flag-editable-provider';

export const CustomizationPageHeaderAction = () => {
  const { isCustomizationFlagEdited, canEdit, loading } = useCustomizationFlagV2Store();
  return (
    <>
      {canEdit && !loading && (
        <div css={customizationPageHeaderActionContainer}>
          {!isCustomizationFlagEdited && <EditCustomizationButton />}
          {isCustomizationFlagEdited && <SaveCustomizationButtons />}
        </div>
      )}
    </>
  );
};

const customizationPageHeaderActionContainer = css`
  display: flex;
`;
