import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCurrentLocationId,
  selectCurrentLocationParentId,
} from '../../../../../redux/actions/location';
import { useAlert } from '@weave/alert-system';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { onboardingIntakeFormApi } from '../../../onboarding.api';
import { OnboardingLocationStatus } from '../../../intake-form-hub/api';
import { PrimaryButton, SpinningLoader, Text, TextLink } from '@weave/design-system';
import { IntakeFormCardComponent } from '../../../intake-form-hub/intake-form-card/intake-form-card.component';
import {
  bannerStyle,
  errorStateMessage,
  loaderStyles,
  mainContainer,
  refreshButton,
  textLinkStyles,
  title,
  titleContainer,
} from '../../../intake-form-hub/intake-form.component';
import { Page } from '../../../../shared';
import { css } from '@emotion/core';

export const IntakeFormComponentNew = () => {
  const locationId = useSelector(selectCurrentLocationId);
  const parentLocationId = useSelector(selectCurrentLocationParentId);
  const [refetchNotifier, setRefetchNotifier] = useState(false);
  const alertRefresh = useAlert();
  const history = useHistory();

  const provisionerStatusQuery = useQuery(
    [locationId, 'provisionerData'],
    async () => {
      return onboardingIntakeFormApi.getProvisionerStatus(locationId);
    },
    {
      enabled: !!locationId,
      select: (data: OnboardingLocationStatus) => data.onboardingLocationStatus,
      retry: false,
      onError: (err) => {
        console.error('PROVISION STATUS:', err);
      },
    }
  );

  const handleRedirectToParentAccount = () => {
    history.push(`/location/${parentLocationId}/handoff/intake-form`);
  };

  const handleRefreshData = () => {
    provisionerStatusQuery.refetch();
    setRefetchNotifier(!refetchNotifier);
    alertRefresh.success('Refreshing data');
  };

  if (provisionerStatusQuery.isLoading || !locationId) {
    return <SpinningLoader css={loaderStyles} />;
  }

  if (provisionerStatusQuery.isError) {
    return (
      <Text css={errorStateMessage}>
        There was an error retrieving this locations data
      </Text>
    );
  }

  if (!provisionerStatusQuery.data?.length) {
    return <Text css={errorStateMessage}>There are no locations to provision</Text>;
  }

  if (parentLocationId) {
    return (
      <Page title="Onboarding Intake Form">
        <Text css={bannerStyle}>
          You need to go to the parent account page in order to send the intake form and
          view data.{' '}
          <TextLink css={textLinkStyles} onClick={handleRedirectToParentAccount}>
            Click here
          </TextLink>{' '}
          to go to the parent account.
        </Text>
      </Page>
    );
  }

  return (
    <>
      <Page
        css={mainContainer}
        title="Onboarding Intake Form"
        headerActions={
          <div
            css={css`
              display: flex;
            `}
          >
            <PrimaryButton
              size="large"
              onClick={handleRefreshData}
              data-testid={`refresh-intake-data`}
            >
              Refresh
            </PrimaryButton>
          </div>
        }
      >
        {provisionerStatusQuery.data.map((locationData, index) => (
          <IntakeFormCardComponent
            key={index}
            provisionData={provisionerStatusQuery.data}
            locationData={locationData}
            locationId={locationId}
            refreshData={handleRefreshData}
            refetchEmailData={refetchNotifier}
          />
        ))}
      </Page>
    </>
  );
};
