import { ReactComponent as DentalIcon } from '../../images/dental-vertical.svg';
import { ReactComponent as OptometryIcon } from '../../images/optometry-vertical.svg';
import { ReactComponent as WellnessIcon } from '../../images/wellness-vertical.svg';
import { ReactComponent as WeaveIcon } from '../../images/wv-icon-logo.svg';
import { ReactComponent as PremiumIcon } from '../../images/premium-feature.svg';
import { ReactComponent as SoftwareOnlyIcon } from '../../images/software-only.svg';
import {
  AutomatedMessagesIcon,
  StarIcon,
  PhoneIcon,
  PrintIcon,
  PayIcon,
  AnalyticsIcon,
  CellPhoneIcon,
} from '@weave/design-system';

export enum SubPackageNames {
  premium = 'premium',
  softwareOnly = 'softwareOnly',
  noCSV = 'noCSV',
  withDataSync = 'withDataSync',
}

export enum PackageNames {
  // Legacy
  dental = 'dental',
  optometry = 'optometry',
  wellness = 'wellness',
  weaveCore = 'weaveCore',
  starterPromo = 'starterPromo',

  // New
  weaveAttract = 'weaveAttract',
  weaveEngage = 'weaveEngage',
  weaveCommunicate = 'weaveCommunicate',

  // Addons
  fax = 'fax',
  analytics = 'analytics',
  payments = 'payments',
}

export enum IconNames {
  noCSV = 'noCSV',
  plus = 'plus',
}

type Feature =
  | 'Analytics Home Icon'
  | 'Appt Confirmations - writebacks'
  | 'Appt Reminders - automated'
  | 'Birthday Lists'
  | 'Birthday texts/emails - automated'
  | 'Chat'
  | 'Click-to-Call'
  | 'Collections List'
  | 'Custom Contacts'
  | 'Email'
  | 'Enterprise Analytics'
  | 'Eyewear Ready'
  | 'Fax'
  | 'Follow-ups List'
  | 'Forms'
  | 'Integrated'
  | 'List Home Icon'
  | 'Email Marketing'
  | 'Missed call text'
  | 'Overdue Patients List'
  | 'Payment Terminals'
  | 'Phone Home Icon'
  | 'Practice Analytics'
  | 'Quickfill'
  | 'Recall Reminders - automated'
  | 'Recovery Kit'
  | 'Reports - call'
  | 'Reports - recall'
  | 'Response Kit'
  | 'Reviews Home Icon'
  | 'Schedule/Events'
  | 'Weave Pay'
  | 'Web Assistant';

export interface SubPackage {
  name: keyof typeof SubPackageNames;
  label: string;
  packageEffect: 'add' | 'subtract';
  icon: React.FunctionComponent | keyof typeof IconNames;
  features: Feature[];
}

export interface Package {
  name: keyof typeof PackageNames;
  label: string;
  icon: React.FunctionComponent;
  baseFeatures: Feature[];
  subPackages?: SubPackage[];
  versionType: 'legacy' | 'new';
}

/*------------------------------ REUSED SUB PACKAGES -----------------------------------*/
const premiumSubPackage: SubPackage = {
  name: SubPackageNames.premium,
  label: 'Premium',
  packageEffect: 'add',
  icon: PremiumIcon,
  features: ['Fax', 'Missed call text', 'Reviews Home Icon'],
};

const softwareOnlySubPackage: SubPackage = {
  name: SubPackageNames.softwareOnly,
  label: 'Software Only',
  packageEffect: 'subtract',
  icon: SoftwareOnlyIcon,
  features: ['Missed call text', 'Click-to-Call', 'Reports - call', 'Phone Home Icon'],
};

/*-------------------------------- LEGACY PACKAGES -------------------------------------*/

// Dental Package
const dentalPackage: Package = {
  name: PackageNames.dental,
  label: 'Dental',
  icon: DentalIcon,
  versionType: 'legacy',
  baseFeatures: [
    'Analytics Home Icon',
    'Appt Confirmations - writebacks',
    'Appt Reminders - automated',
    'Birthday Lists',
    'Birthday texts/emails - automated',
    'Chat',
    'Click-to-Call',
    'Collections List',
    'Custom Contacts',
    'Email',
    'Follow-ups List',
    'Integrated',
    'List Home Icon',
    'Email Marketing',
    'Overdue Patients List',
    'Payment Terminals',
    'Phone Home Icon',
    'Quickfill',
    'Recall Reminders - automated',
    'Reports - call',
    'Reports - recall',
    'Response Kit',
    'Schedule/Events',
    'Weave Pay',
  ],
  subPackages: [premiumSubPackage, softwareOnlySubPackage],
};

// Optometry Package
const optometryPackage: Package = {
  name: PackageNames.optometry,
  label: 'Optometry',
  icon: OptometryIcon,
  versionType: 'legacy',
  baseFeatures: [
    'Analytics Home Icon',
    'Appt Confirmations - writebacks',
    'Appt Reminders - automated',
    'Birthday Lists',
    'Birthday texts/emails - automated',
    'Chat',
    'Click-to-Call',
    'Collections List',
    'Custom Contacts',
    'Email',
    'Eyewear Ready',
    'Follow-ups List',
    'Integrated',
    'List Home Icon',
    'Email Marketing',
    'Overdue Patients List',
    'Payment Terminals',
    'Phone Home Icon',
    'Quickfill',
    'Recall Reminders - automated',
    'Reports - call',
    'Reports - recall',
    'Response Kit',
    'Schedule/Events',
    'Weave Pay',
  ],
  subPackages: [premiumSubPackage, softwareOnlySubPackage],
};

// Wellness Package
const wellnessPackage: Package = {
  name: PackageNames.wellness,
  label: 'Health & Beauty',
  icon: WellnessIcon,
  versionType: 'legacy',
  baseFeatures: [
    'Analytics Home Icon',
    'Appt Confirmations - writebacks',
    'Appt Reminders - automated',
    'Birthday Lists',
    'Birthday texts/emails - automated',
    'Chat',
    'Click-to-Call',
    'Collections List',
    'Custom Contacts',
    'Email',
    'Follow-ups List',
    'Integrated',
    'List Home Icon',
    'Email Marketing',
    'Overdue Patients List',
    'Payment Terminals',
    'Phone Home Icon',
    'Quickfill',
    'Recall Reminders - automated',
    'Reports - call',
    'Reports - recall',
    'Response Kit',
    'Schedule/Events',
    'Weave Pay',
  ],
  subPackages: [premiumSubPackage, softwareOnlySubPackage],
};

// Weave Core Package
const weaveCorePackage: Package = {
  name: PackageNames.weaveCore,
  label: 'Weave Core',
  icon: WeaveIcon,
  versionType: 'legacy',
  baseFeatures: [
    'Analytics Home Icon',
    'Chat',
    'Click-to-Call',
    'Custom Contacts',
    'Email',
    'Follow-ups List',
    'List Home Icon',
    'Email Marketing',
    'Payment Terminals',
    'Phone Home Icon',
    'Quickfill',
    'Reports - call',
    'Response Kit',
    'Weave Pay',
  ],
  subPackages: [
    premiumSubPackage,
    {
      name: SubPackageNames.noCSV,
      label: 'no CSV',
      packageEffect: 'subtract',
      icon: IconNames.noCSV,
      features: ['Follow-ups List', 'List Home Icon'],
    },
  ],
};

// Starter Promo Package
const starterPromoPackage: Package = {
  name: PackageNames.starterPromo,
  label: 'Starter Promo',
  icon: CellPhoneIcon,
  versionType: 'legacy',
  baseFeatures: [
    'Chat',
    'Click-to-Call',
    'Custom Contacts',
    'Payment Terminals',
    'Phone Home Icon',
    'Response Kit',
    'Weave Pay',
  ],
  subPackages: [
    {
      name: SubPackageNames.withDataSync,
      label: 'With Data Sync',
      packageEffect: 'add',
      icon: IconNames.plus,
      features: ['Integrated', 'Schedule/Events'],
    },
  ],
};

/*---------------------------------- NEW PACKAGES --------------------------------------*/
const weaveCommunicatePackage: Package = {
  name: PackageNames.weaveCommunicate,
  label: 'Weave Communicate',
  icon: PhoneIcon,
  versionType: 'new',
  baseFeatures: [
    'Analytics Home Icon',
    'Birthday Lists',
    'Chat',
    'Click-to-Call',
    'Custom Contacts',
    'Email',
    'Email Marketing',
    'List Home Icon',
    'Missed call text',
    'Phone Home Icon',
    'Recovery Kit',
    'Reports - call',
    'Response Kit',
    'Follow-ups List',
    'Weave Pay',
  ],
};

const weaveEngagePackage: Package = {
  name: PackageNames.weaveEngage,
  label: 'Weave Engage',
  icon: AutomatedMessagesIcon,
  versionType: 'new',
  baseFeatures: [
    'Analytics Home Icon',
    'Appt Confirmations - writebacks',
    'Appt Reminders - automated',
    'Birthday Lists',
    'Birthday texts/emails - automated',
    'Chat',
    'Collections List',
    'Custom Contacts',
    'Integrated',
    'List Home Icon',
    'Overdue Patients List',
    'Quickfill',
    'Recall Reminders - automated',
    'Recovery Kit',
    'Reports - recall',
    'Response Kit',
    'Schedule/Events',
    'Weave Pay',
    'Web Assistant',
  ],
};

const weaveAttractPackage: Package = {
  name: PackageNames.weaveAttract,
  label: 'Weave Attract',
  icon: StarIcon,
  versionType: 'new',
  baseFeatures: [
    'Birthday Lists',
    'Chat',
    'Custom Contacts',
    'Forms',
    'Reviews Home Icon',
    'Weave Pay',
    'Web Assistant',
  ],
};

/*------------------------------------ NEW ADDONS --------------------------------------*/
const faxAddon: Package = {
  name: PackageNames.fax,
  label: 'Fax',
  icon: PrintIcon,
  versionType: 'new',
  baseFeatures: ['Fax'],
};

const paymentsAddon: Package = {
  name: PackageNames.payments,
  label: 'Payments',
  icon: PayIcon,
  versionType: 'new',
  baseFeatures: ['Payment Terminals'],
};

const analyticsAddon: Package = {
  name: PackageNames.analytics,
  label: 'Analytics',
  icon: AnalyticsIcon,
  versionType: 'new',
  baseFeatures: ['Enterprise Analytics', 'Practice Analytics'],
};

export const legacyPackages: Package[] = [
  dentalPackage,
  optometryPackage,
  wellnessPackage,
  weaveCorePackage,
  starterPromoPackage,
];

export const packages: Package[] = [
  weaveCommunicatePackage,
  weaveEngagePackage,
  weaveAttractPackage,
];

export const addons: Package[] = [faxAddon, paymentsAddon, analyticsAddon];

export enum WamSyncFeatureFlags {
  SHOW_OUT_OF_SYNC_ALERT = 'wam-out-of-sync-alert',
  PREVENT_SYNCED_CUSTOMIZATION_CHANGE = 'wam-prevent-synced-customization-change',
}
