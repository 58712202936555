import { useState, useCallback } from 'react';

export const useClipboard = () => {
  const [copied, setCopied] = useState(false);
  const [copyError, setCopyError] = useState<string>();

  const execCopy = useCallback((text: string) => {
    // navigator.clipboard has 94% support among browsers, this is adequate for Internal use with WAM
    if (!navigator.clipboard) {
      const message = 'Clipboard is not supported in this browser.';
      console.error(message);
      setCopyError(message);
      return;
    }

    navigator.clipboard
      .writeText(text)
      .then(() => setCopied(true))
      .catch((error) => {
        console.log('Unexpected copy error', error);
        setCopyError(String(error));
      });
  }, []);

  const clearState = () => {
    setCopied(false);
  };

  return { copy: execCopy, copied, copyError, clearState };
};
