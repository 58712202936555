import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Text, ContentLoader, Heading } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { Modal } from '../shared/modal/modal.component';
import { CopyToClipboardLink } from '../shared/copy-to-clipboard/copy-to-clipboard-link';
import { useConnectStripeLink } from './hooks/use-connect-stripe';

const modalBody = css`
  overflow-wrap: break-word;
`;

const lineBreak = css`
  margin: 16px 0;
  border-color: ${theme.colors.gray400};
`;

const merchantLink = css`
  cursor: pointer;
  color: #007bff !important;
  margin-left: 10px;
`;

export const PaymentsOnboardingLink = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { connectLink } = useConnectStripeLink();

  const getMerchantLink = () => setModalOpen(true);

  return (
    <>
      <Heading level={2}>Stripe Merchant Onboarding</Heading>
      <a onClick={getMerchantLink} css={merchantLink}>
        <span>Get Express Onboarding Link</span>
      </a>
      <hr css={lineBreak} />
      <Modal
        isOpen={isModalOpen}
        title="Merchant Onboarding Link"
        onRequestClose={() => setModalOpen(false)}
        size="small"
      >
        {connectLink && (
          <div css={modalBody}>
            <CopyToClipboardLink label="Copy link to clipboard" copyData={connectLink} />
            <Text size="small" color="light" css={{ marginTop: 16 }}>
              {connectLink}
            </Text>
          </div>
        )}
        {!connectLink && <ContentLoader />}
      </Modal>
    </>
  );
};
