import React, { useState } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { ContentLoader, Modal, Text } from '@weave/design-system';
import { ExceptionItem } from './exception-item';
import { GroupedFailedMetrics } from '../../handoff-metrics/handoff-metrics-helpers';
import { MetricResult } from '../../handoff-metrics/handoff-metrics.types';

// This is an object with a concatenation of the metric's section, subsection and
// testCriteria strings (joined with a "|") as the key name and the selected exception
// string as the value.
export type SelectedExceptionsMap = {
  [concatenatedName: string]: string;
};

type Props = {
  failedMetricsWithExceptions: GroupedFailedMetrics['withExceptions'];
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (selectedMetricExceptionsMap: SelectedExceptionsMap) => void;
};

export const ExceptionsModal = ({
  failedMetricsWithExceptions,
  isLoading,
  onClose,
  onSubmit,
}: Props) => {
  const [selectedMetricExceptionsMap, setSelectedMetricExceptionsMap] =
    useState<SelectedExceptionsMap>({});

  const reducedMetricsSubsectionData = failedMetricsWithExceptions.reduce<{
    [subsectionName: string]: { metricResult: MetricResult; exceptions: string[] }[];
  }>((acc, metricData) => {
    const subsection = metricData.metricResult.subsection;

    return {
      ...acc,
      [subsection]: [...(acc[subsection] ?? []), metricData],
    };
  }, {});

  const handleExceptionSelected = (concatenatedName: string, exception: string) =>
    setSelectedMetricExceptionsMap({
      ...selectedMetricExceptionsMap,
      [concatenatedName]: exception,
    });
  const numberOfSetExceptions = Object.values(selectedMetricExceptionsMap).reduce(
    (acc, curr) => (!!curr ? acc + 1 : acc),
    0
  );

  const disableSubmitButton =
    failedMetricsWithExceptions.length !== numberOfSetExceptions;

  return (
    <>
      <Modal.Header css={modalHeader}>Add Onboarding Exceptions</Modal.Header>
      <Modal.Body>
        <ContentLoader show={isLoading} />
        <Text css={modalHeaderContent}>
          Some onboarding features are not completed. Please review the items below and
          assist the office in completing their setup or select the applicable reason in
          the drop downs below.
        </Text>

        <div css={modalExceptionContainer}>
          {Object.entries(reducedMetricsSubsectionData).map(
            ([subsectionName, failedMetrics]) => (
              <ExceptionItem
                key={subsectionName}
                failedMetrics={failedMetrics}
                failedSubsectionName={subsectionName}
                onExceptionSelected={handleExceptionSelected}
              />
            )
          )}
        </div>
      </Modal.Body>
      <Modal.Actions
        secondaryLabel="Cancel"
        primaryLabel="Submit Handoff Note"
        onSecondaryClick={onClose}
        onPrimaryClick={() => onSubmit(selectedMetricExceptionsMap)}
        disablePrimary={disableSubmitButton || isLoading}
        disableSecondary={isLoading}
        css={buttonStyles}
      />
    </>
  );
};

const modalExceptionContainer = css`
  margin-top: ${theme.spacing(5)};
`;

const modalHeader = css`
  font-size: ${theme.fontSize(24)};
  padding-top: ${theme.spacing(2)};
`;

const modalHeaderContent = css`
  margin: 0;
  text-align: center;
`;

const buttonStyles = css`
  width: 215px;
  margin-bottom: ${theme.spacing(2)};
`;
